import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import axiosInstance from '../../../../../Services/axiosCommon';
import Dasboard from '../../../../../Components/Icons/Dasboard';
import { BackButton } from '../../../../../Components/Utils/Buttons';
import NuDate from '../../../../../Components/Utils/NuDate';
import Breadcrumb from '../../../../../Components/Utils/Breadcrumb';

const ViewTeam = () => {
    async function getTeamData() {
        await axiosInstance
          .get(`/Team/?page=1&per_page=10`)
          .then(async (res) => {
            console.log(res?.data?.data);
            // (await res?.data?.data) && setTeamData(res?.data?.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      const location = useLocation();
      const { data } = location.state;
    
    
    
        const column = [
    
          {
            name: "Name",
            selector: row =>
              <div className="flex items-center gap-5">
                {/* <img className="w-6 h-6 rounded-[50%] mr-2" src={row.img}/> */}
                {/* <NuAvatarGroup userdetails={[{ name: row.firstName, imageUrl: `${row.imageUrl}` }]} /> */}
                {row.firstName} {row.lastName}
              </div>
    
    
            //   cell: (row) => <Link to={`/profile/view/${row.id}`}>{row.id}</Link>,
          },
          {
            name: "Position",
            selector: row => row.position,
            //   selector: (row) => <p className="capitalize">{row.name}</p>,
            //   sortable: true,
            //   grow: 2,
          },
          {
            name: 'Join Date',
            selector: (row) => (
              <p className='capitalize'>
                {' '}
                <NuDate value={row.joinDate} format='DD-MMM-yyyy' />
              </p>
            ),
            sortable: true,
            //  grow:2,
          },
          // {
          //   name: "Join Date",
          //   selector:row =>row.joinDate,
          // //   selector: (row) => (row.gender == 0 ? "Male" : "Female"),
          // },
          {
            name: "Mobile",
            selector: row => row.mobile,
            //   selector: (row) => AgeCalculator(row.dob),
            //   sortable: true,
          },
          {
            name: "Email",
            selector: row => row.email,
    
          },
          {
            name: "Loaction",
            selector: (row) => row.location,
          },
          {
            name: "Status",
            selector: row => row.status ?
              <div className="text-xs font-semibold text-[#39D42B] bg-[#E7F9E7] rounded-xl py-1 px-2">Active</div> :
              <div className="text-xs font-semibold text-[#FF4466] bg-[#FFEFF2] rounded-xl py-1 px-2">Inactive</div>
          },
          // {
          //   cell: (row) => (
          //     <ViewMore data={row}
          //       TeamId={data.teamId}
          //     // getData={getData} 
          //     />
          //   ),
          //   allowOverflow: true,
          //   button: true,
          //   width: "25px",
          // },
        ];
        return (
          <>
            <div className=' w-full h-[100%] px-4 py-2'>
              <div className=' w-full mx-auto h-[100%] rounded-md overflow-hidden bg-white'>
                <div className='w-full mb-2 flex justify-between items-start'>
                <Breadcrumb items={[{title:(
                 <Link to='/dashboard'><Dasboard
                  color='gray' width={10} height={10} /> </Link> 
                )},{title:(<p className=' text-[12px] '>Teams</p>)},{title:(<p className=' text-[12px] '>View</p>)}]} />
                  <BackButton />
                  {/* <CreateMember
                    open={open}
                    // onCreate={onCreate}
                    getData={getMember}
                    onCancel={() => {
                      setOpen(false);
                    }}
                  /> */}
                </div>
                {/* <DataTables column={column} datas={data.memberList} /> */}
              </div>
            </div>
          </>
        )
      }
    
    
      const data = [
        {
          id: 1,
          name: 'sam',
          position: 'Designer',
          joinDate: '09/03/2023',
          Mobile: '98765423647',
          Email: 'sam12345@gmail.com',
          location: 'Kanniyakumari',
          status: 'Active'
        },
        {
          id: 2,
          //   img:image,
          name: 'sanjay',
          position: 'Designer',
          joinDate: '09/03/2023',
          Mobile: '98765423647',
          Email: 'sam12345@gmail.com',
          location: 'Kanniyakumari',
          status: 'Active'
        },
        {
          id: 3,
          name: 'sanjay',
          position: 'Designer',
          joinDate: '09/03/2023',
          Mobile: '98765423647',
          Email: 'sam12345@gmail.com',
          location: 'Kanniyakumari',
          status: 'Active'
        }
      ]
export default ViewTeam