// import React, { useEffect, useMemo, useState } from "react";
// import {
//   BackButton,
//   CancelButton,
//   CreateButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
// import {
//   NuDataTime,
//   NuDate,
//   NuInput,
//   NuInputWithError,
//   NuMobileNumber,
//   NuTextArea,
//   NuUpload,
// } from "../../../../Components/Utils/Input";
// import FormikSelectTwo, {
//   FormikSelectWithAdd,
//   SelectProductWithError,
//   SelectWithError,
//   TwoNEW,
// } from "../../../../Components/Utils/Select";
// import Dashboard from "../../../../Components/Icons/Dasboard";
// import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useFormik } from "formik";
// import UseAddData from "../../../../Components/Hooks/useAddData";
// import * as yup from "yup";
// import useAddFormData from "../../../../Components/Hooks/useAddFormData";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import Delete from "../../../../Components/Icons/Delete";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import useFetchById from "../../../../Components/Hooks/useFetchById";
// import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
// import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
// import { useToast } from "../../../../Components/Others/ToastServices";
// import DropDownAddMetaData, {
//   DropDownAddUnitWithError,
// } from "../../../../Components/Utils/selectAddMetaData";
// import MultiSelect from "../../../../Components/Utils/MultiSelect";
// import axiosInstance from "../../../../Services/axiosCommon";
// import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
// import DropDownAddSubCategory from "../../../../Components/Utils/selectAddSubCategory";
// import DropDownAdd from "../../../../Components/Utils/selectAdd";
// import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
// import EditIcon from "../../../../Components/Icons/Edit";
// import UseProductDropDownNew from "../../../../Components/Hooks/useProductSearch";
// import { use } from "i18next";
// import UseCustomerDropDownNew from "../../../../Components/Hooks/useCustomerSearch";
// import Select from "react-select";
// import UseSupplierDropDownNew from "../../../../Components/Hooks/useSupplierSearch";
// import DropDownAddCurrency from "../../../../Components/Utils/selectAddCurrencyDropDown";
// import UseFetchCurrency from "../../../../Components/Hooks/useFetchCurrencyData";
// import axios from "axios";
// const filetype = [
//   "docx",
//   "doc",
//   "xlsx",
//   "xls",
//   "pdf",
//   "txt",
//   "pptx",
//   "mp4",
//   "mov",
//   "wmv",
//   "avi",
//   "flv",
//   "mkv",
// ];

import { useLocation, useParams } from "react-router-dom";
import { useToast } from "../../../../../../Components/Others/ToastServices";
import UseDropDown from "../../../../../../Components/Hooks/useDropDown";
import { useEffect, useState } from "react";
import useAddFormData from "../../../../../../Components/Hooks/useAddFormData";
import UseSupplierDropDownNew from "../../../../../../Components/Hooks/useSupplierSearch";
import UseFetchCurrency from "../../../../../../Components/Hooks/useFetchCurrencyData";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import { filetype } from "../../Tasks/Components/viewFiles";
import { useFormik } from "formik";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo, { TwoNEW } from "../../../../../../Components/Utils/Select";
import { NuDataTime, NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
import DropDownAddCurrency from "../../../../../../Components/Utils/selectAddCurrencyDropDown";
import DropDownAddMetaData from "../../../../../../Components/Utils/selectAddMetaData";
import * as yup from "yup";
import Attachement from "../../../../../../Components/Utils/CustomAttrachmentupload";

function CreatePaymentRequest() {
   const location = useLocation();
   const {officeId,projectId} =useParams();

  const toast = useToast();
  const currentDate = new Date().toISOString().split("T")[0];

  const { data: PR } = UseDropDown("/PR/drop-down");

 
  const [image, setImage] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [items, setItems] = useState([]);
  
  const [searchSupplier, setSearchSupplier] = useState("");

  const { data: vendors, setDataSupplier } = UseSupplierDropDownNew(
    "/vendors/drop-down",
    searchSupplier
  );
  let { value: currencyOptions, getData: refCurrency } = UseFetchCurrency(
    "/currency/drop-down"
    
  );
 

  
  const { value: ModeOfPayment, getData: refModeOfPayment } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ModeOfPayment_invoicesIncoming"
  );
  const { value: priorityRFQ, getData: refpriorityRFQ } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "priority_RFQ"
  );
  const { prId , prRef , ref  , Currency , poId, poref ,totalAmount ,bankCharges , modeOfPayment ,invoiceInId} = location.state || {};
 

  const [createNew, setCreateNew] = useState({
   bankCharges:bankCharges|| "",
    comments: "",
    currency: Currency || "",
    prId: prId || "",
    prRef: prRef || "",

    poId: poId || "",
    poref: poref || "",
    ref:ref||"",
    invoiceId: invoiceInId || "",
    totalAmount: "",
    invoiceAmount:totalAmount || "",
    modeOfPayment: modeOfPayment ||"",
    paidOn:"" ,
    priority: "",
    projectId:projectId

  });

  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
       
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
   
    setImage(tempArr);
  }

  const removeImages = (value) => {
    
    let List = selectedFile.filter((item) => item?.name !== value);
   
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImage(tempArr);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
            prId: yup.string().required("YPR is requred!"),
            // poId: yup.string().required("PO is requred!"),
            // invoiceId: yup.string().required("Invoice Incoming is requred!"),
      
        }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        productList: items,
      };
      formData.append("json_data", JSON.stringify(valueWithItems));
      // formData.append('attachments', image.raw);
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      addFormData("/payment-request/", formData, resetForm);
      // setLoading(false)
    },
  });

  

  const [Invoice, setInvoice] = useState([]);


const getInvoice = () => {
  if (formik?.values?.poId) {
    axiosInstance
      .get(`/payment-request/invoice-drop-down/${formik?.values?.poId}`)
      .then((res) => {
       
        let tempData = res.data.map((item) => ({
          value: item.value,
          label: item.label, 
          invoiceAmount: item.invoiceAmount, 
        }));
        setInvoice(tempData);
      })
      .catch((err) => {
        console.error("Error fetching invoices", err);
      });
  }
};
  
const [polist, setPolist] = useState([]);
const [supplierName, setSupName] = useState(null);
const [currency, setCurrency] = useState(null);

const getPo = (prId) => {
  if (prId !== "") {
    axiosInstance
      .get(`/payment-request/PO-drop-down/${prId}`)
      .then((res) => {
        const data = res.data.map((item) => ({
          label: item.label, 
          value: item.value, 
          vendorName: item.vendorName, 
          currency: item.currency, 
        }));

        setPolist(data);
        
           const selectedPo = data.find((po) => po.value === formik.values.poId);
        
        if (selectedPo) {
          setSupName(selectedPo.vendorName);
          setCurrency(selectedPo.currency);
          
                formik.setFieldValue("currency", selectedPo.currency);
        }  })
      .catch((err) => {
        console.error("Error fetching PO data", err);
      });
  }
};


  
   
const [PRList, setPRList] = useState([]); 
const [sortedPRList, setSortedPRList] = useState([]); 
const [oldPRList, setOldPRList] = useState([]);
const [customerName, setCustomerName] = useState(""); 
const [search, setSearch] = useState("");


useEffect(() => {
   const fetchPRDropdown = async () => {
    try {
      const response = await axiosInstance.get(`/payment-request/PR-drop-down?search=${search}`);
      if (response.data) {
        const options = response.data.map((item) => ({
          label: item.label,
          value: item.value,
          customerName: item.customerName,
          
        }));
        
        setPRList(options);
      }
    } catch (error) {
      console.error("Error fetching PR dropdown options:", error);
    }
  };

  
  const timeoutId = setTimeout(() => {
    fetchPRDropdown();
  }, 500);

  return () => clearTimeout(timeoutId); 
}, [search]); 

useEffect(() => {
  if (PRList) {
    const union = Array.from(
      new Map([...PRList, ...oldPRList].map((item) => [item.value, item])).values()
    );
    const sortedData = union.sort((a, b) => a.label.localeCompare(b.label));
    setSortedPRList(sortedData);
  }
}, [PRList, oldPRList]);

useEffect(() => {
  if (formik.values.prId) {
    const selectedOption = sortedPRList.find((option) => option.value === formik.values.prId);

    if (selectedOption) {
      setOldPRList((prevOldData) => {
        const exists = prevOldData.some((item) => item.value === selectedOption.value);
        if (!exists) {
          return [...prevOldData, selectedOption];
        }
        return prevOldData;
      });

      setCustomerName(selectedOption.customerName || "N/A");
    }

    setSearch("");
  }
}, [formik.values.prId, sortedPRList]);





useEffect(() => {
   
    if (formik.values.prId) {
      getPo(formik.values.prId);
    }
    if (formik.values.poId) {
      getInvoice(formik.values.poId);
    }
  }, [formik.values.poId,formik?.values?.prId]);


  useEffect(() => {
    if (polist?.length === 1) {
           formik.setFieldValue("poId", polist[0].value);
    }
  }, [polist, formik]);

  useEffect(() => {
    if (Invoice?.length === 1) {
          formik.setFieldValue("invoiceId", Invoice[0].value);
      formik.setFieldValue("invoiceAmount", Invoice[0].invoiceAmount); 
    }
  }, [Invoice, formik]);
  

  useEffect(() => {
    const invoiceAmount = parseFloat(formik.values.invoiceAmount) || 0; 
    const bankCharges = parseFloat(formik.values.bankCharges) || 0;
    const total = invoiceAmount + bankCharges;
    formik.setFieldValue('totalAmount', total.toFixed(2)); 
  }, [formik.values.invoiceAmount, formik.values.bankCharges]);
  

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <div>
          {/* <Breadcrumb
            items={[
           
              {
                name: "Payment Request",
                link: `/project/procurement/payment-request/${officeId}/${projectId}`,
              },

              { name: "Create" },
            ]}
          /> */}
        </div>
        {/* <div className="flex gap-4"> */}
        <BackButton />

        {/* </div> */}
      </div>
      <div className="w-full h-[calc(100vh-145px)] overflow-y-auto ">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto pb-4 bg-white rounded-md  pt-2">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
            {!location.state && (
            <TwoNEW
        type="text"
        label="PR"
        placeholder="PR"
        Options={PR} 
        formik={formik}
        name="prId"
        width="w-full md:w-1/2 lg:w-1/3"
        isRequired={true}
        setSearch={setSearch}
      />)}
        {location.state && (

<NuInput
type="text"
label="PR "
placeholder="PR"
formik={formik}
name="prRef"
width="w-full md:w-1/2 lg:w-1/3"
disabled={true}

/>
          )}
              {/* <FormikSelectTwo
                type="text"
                label="Purchase Order"
                placeholder="Purchase Order"
                Options={polist}
                formik={formik}
                name="poId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              /> */}
              {/* <FormikSelectTwo
  type="text"
  label="Invoice Incoming"
  placeholder="Invoice Incoming"
  Options={Invoice} 
  formik={formik}
  name="invoiceId"
  width="w-full md:w-1/2 lg:w-1/3"
  isRequired={true}

/> */}

   {currency ? (
              <NuInput
                type="text"
                label="Currency "
                placeholder="Currency"
                formik={formik}
                name="currency"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
               
              />):(
                            <DropDownAddCurrency
                label="Currency"
                placeholder="Choose"
                Options={currencyOptions}
                formik={formik}
                name="currency"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCurrency}
              />)}
            
         

<NuInput
  type="number"
  label="Invoice Amount"
  placeholder="Invoice Amount"
  formik={formik}
  name="invoiceAmount"
  width="w-full md:w-1/2 lg:w-1/3"
  // disabled={true} 
/>
     <NuInput
                type="number"
                label="Bank Charges"
                placeholder="Bank Charges"
                formik={formik}
                name="bankCharges"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="number"
                label="Total Amount"
                placeholder="Total Amount"
                formik={formik}
                name="totalAmount"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              />
              <DropDownAddMetaData
                label="Mode of Payment"
                placeholder="Choose"
                Options={ModeOfPayment}
                formik={formik}
                name="modeOfPayment"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refModeOfPayment}
                displayProperty={"Mode of Payment"}
                propertyName={"ModeOfPayment_invoicesIncoming"}
              />
            
              <NuDataTime
                label="Paid On"
                placeholder="DD/MM/YYYY"
                formik={formik}
                name="paidOn"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               <DropDownAddMetaData
              label="Priority"
              placeholder="Choose"
              Options={priorityRFQ}
              formik={formik}
              name="priority"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refpriorityRFQ}
              displayProperty={"Priority"}
              propertyName={"priority_RFQ"}
            />
             

              <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                width="w-full md:w-1/2 "
              />

              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Payment Request Attachement :
                </p>
              </div>
              <Attachement
                handleChange={handleImageChange}
                preview={image || ""}
                width="w-full"
                label="Attach"
                multiple={true}
                removeImages={removeImages}
              />
         {formik.values.prId  && customerName && (
  <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
    <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
      Customer Name
    </p>
    <p className="w-1/2 py-3 pl-5 text-xs text-[#606279]">
      {customerName}
    </p>
  </div>
)}

              {formik.values.poId && supplierName &&(
              <>
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Supplier Name
              </p>
              <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                {supplierName}
              </p>
            </div>
            </>)}
           
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreatePaymentRequest;
