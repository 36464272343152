import React, { lazy } from "react";
import { Route } from "react-router-dom";
import MainAttendance from "../Views/Web/Project/SubMenu/Timesheet/Attendance/MainAttendance";
import AttendanceIndex from "../Views/Web/Project/SubMenu/Timesheet/Attendance/AttendanceIndex";
import TimesheetsIndex from "../Views/Web/Project/SubMenu/Timesheet/Timesheets/TimesheetsIndex";
import MainTimesheets from "../Views/Web/Project/SubMenu/Timesheet/Timesheets/MainTimesheets";
import MainTimesheet from "../Views/Web/Project/SubMenu/Timesheet/MainTimesheet";
import MainTeam from "../Views/Web/Project/SubMenu/Timesheet/Team/MainTeam";
import Index from "../Views/Web/Project/SubMenu/Timesheet/Team/Index";

function ProjectTimeRoutes() {
  return (
    <Route path="hr" element={<MainTimesheet />}>
      <Route path="attendance/:officeId/:projectId" element={<MainAttendance />}>
        <Route index element={<AttendanceIndex />} />
        {/* <Route path="create" element={<NoteCreate />} />
        <Route path="edit/:attendanceEditId" element={<NoteEdit />} />
        <Route path="view/:attendanceViewId" element={<NotesView />} /> */}
      </Route>
      <Route path="timesheets/:officeId/:projectId" element={<MainTimesheets />}>
        <Route index element={<TimesheetsIndex />} />
      </Route>
      <Route path="team/:officeId/:projectId" element={<MainTeam />}>
        <Route index element={<Index />} />
        {/* <Route path="create" element={<NoteCreate />} />
        <Route path="edit/:attendanceEditId" element={<NoteEdit />} />
        <Route path="view/:attendanceViewId" element={<NotesView />} /> */}
      </Route>
    </Route>
  );
}

export default ProjectTimeRoutes;
