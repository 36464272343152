// import React, { useEffect, useState } from "react";
// import SearchBar from "../../../../../../Components/Utils/SearchBar";
// import { Link, useLocation, useParams } from "react-router-dom";
// import axios from "../../../../../../Services/axiosCommon";
// import { useFormik } from "formik";
// import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
// import Pagination from "../../../../../../Components/Utils/Pagenation";
// import ViewMore from "./ViewMore";
// import NuDate from "../../../../../../Components/Utils/NuDate";
// import NuAmount from "../../../../../../Components/Utils/NuAmount";
// import * as yup from 'yup';
// import { GetAccess } from "../../../../../../Components/Utils/roles";
// import { NuSelect } from "../../../../../../Components/Utils/Input";
// import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
// import CustomDataTable from "../../../../../../Components/Others/CustomDataTable";
// import axiosInstance from "../../../../../../Services/axiosCommon";
// import UseGetDataSearchByPro from "../../../../../../Components/Hooks/useFetchDataByPRO";
// import { useToast } from "../../../../../../Components/Others/ToastServices";
// import useFetchMDIndex from "../../../../../../Components/Hooks/useFetchMDIndex";
// import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
// import FormikSelectTwo from "../../../../../../Components/Utils/Select";



// function Others(id) {
//   const { projectId } = useParams();
//   const toast = useToast();
//   const location = useLocation();
//   const {officeId , estmationId} = useParams();
//   console.log(officeId,estmationId,"estimationId");
//   const {value:Category ,getData:refOthersCategory} = useFetchMataData(`/dropdown/dropdown-details/`,'OthersCategory')
//   const {value:Division ,getData:refOtherDivision} = useFetchMataData(`/dropdown/dropdown-details/`,'OtherDivision')
//   const {value:Unit ,getData:refOtherUnit} = useFetchMataData(`/dropdown/dropdown-details/`,'OtherUnit')
//   const currentPage= location.state?.currentPage
//   const searchQuery= location.state?.searchQuery
//   const [page, setPage] = useState(currentPage||1)
//   const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
//   const [filter, setFilter] = useState(false);
//   const [showFilterDialog, setShowFilterDialog] = useState(false);
//   // const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
//   // const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
//   // const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)

//   const [filterProps, setFilterProps] = useState({
//     category:"",
//     division:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   });
//   const [params, setParams] = useState({
//     page: page,
//     per_page: pageCount,
//     search:searchQuery||"",
//     category:"",
//     division:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   });
//   const clearFilter = ()=> {
//     setParams({...params, category:"",
//       division:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   })
//   formik.values.category=""
//   formik.values.division=""
//   formik.values.brand=""
//   formik.values.manufacturer=""
//   formik.values.underPromotion=""
//   formik.values.unit=""


//       setFilter(false)
//   }

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: filterProps,
//     validateOnChange: false,
//     validateOnBlur: false,
//     validationSchema: yup.object({
//       // email: yup.string().required('Email is required!'),
//     }),
//     onSubmit: async (value, { resetForm }) => {
//       console.log("ffff",value);
//       setParams({...params,category:value.category, division:value.division,brand:value.brand,manufacturer:value.manufacturer,underPromotion:value.underPromotion,unit:value.unit})
//       closeFilterDialog();
//       setFilter(true)
//       setFilterProps({
//         category:"",
//         division:"",
//         brand:"",
//         manufacturer:"",
//         underPromotion:"",
//         unit:""
//       });
//     }
//   })

//   const { data, totalCount, getData,dataLoading } = UseGetDataSearchByPro( `/bidding/${officeId}/details`,params);
//   const defaultHeader = [
//     {
//       title: "Serial Number",
//       width: "5%",
//       isLink: true,
//       // id: "serial_number",
//       // link: `/main/${officeId}/bidding/bids/view/`,  
//       field: "serial_number",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Activity",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "activity",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Category",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "category",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     // {
//     //   title: "Bid Due Date",
//     //   type:"date",
//     //   width: "15%",
//     //   field: "dueDate",
//     //   textAlign: "center",
//     //   rowAlign: "center",
//     // },
//     {
//       title: "Amount",
//       width: "10%",
//       // type:"date",
//       field: "amount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Division",
//       width: "10%",
//       field: "division",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Quantity",
//       width: "5%",
//       field: "quantity",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Action",
//       width: "5%",
//       field: "action",
//       textAlign: "center",
//       rowAlign: "center",
//       contents:{
//         // fetcher:getData,
//         // id:"biddingId",
//         removeValue:"serial_number",
//         url:`/others/`,
//         moduleName:"Others",
//         from:`/project/costing/others/${officeId}/${projectId}`,
//         editLink:`/project/costing/others/${officeId}/${projectId}/edit/`,
//         viewLink:`/project/costing/others/${officeId}/${projectId}/view/`,
//         // projectLink:`boq`
//       }
//     },
//   ];
//   const availableHeaders = [
//     {
//       title: "serial_number",
//       width: "5%",
//       isLink: true,
//       // id: "serial_number",
//       // link: `/main/${officeId}/bidding/bids/view/`,  
//       field: "serial_number",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Activity",
//       width: "10%",
//       isLink: true,
//       // id: "project_name",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "activity",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Category",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,    
//       field: "category",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Amount",
//       // type:"date",
//       width: "15%",
//       field: "amount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Division",
//       width: "10%",
//       type:"date",
//       field: "division",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Quantity",
//       width: "10%",
//       field: "quantity",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     // {
//     //   title: "Status",
//     //   width: "5%",
//     //   field: "status",
//     //   textAlign: "center",
//     //   rowAlign: "center",
//     // },
//     {
//       title: "Action",
//       width: "5%",
//       field: "action",
//       textAlign: "center",
//       rowAlign: "center",
//       contents:{
//         // fetcher:getData,
//         // id:"biddingId",
//         removeValue:"serial_number",
//         url:`/others/`,
//         moduleName:"Others",
//         from:`/project/costing/others/${officeId}/${projectId}`,
//         editLink:`/project/costing/others/${officeId}/${projectId}/edit/`,
//         viewLink:`/project/costing/others/${officeId}/${projectId}/view/`,
//         // projectLink:`boq`
//       }
//     },
//   ];

//   useEffect(()=>{
//     setParams({...params,page:page,per_page:pageCount})
//    },[page,pageCount])

//     const closeFilterDialog = () => {
//       setShowFilterDialog(false);
//     };
//        const [showImportModal, setShowImportModal] = useState(false);

//     const handleImportClick = () => {
//       setShowImportModal(true);
//     };
  
//     const closeModal = () => { 
//       setShowImportModal(false);
//     };


//     const [showEdit, setShowEdit] = useState(false)
//     const [showDelete, setShowDelete] = useState(false)
//     const [listofNumbers, setListofNumbers] = useState([])
//     const [fullList, setFullList] = useState([])
  
//     const selectOneFunction = (value) =>{ 
//       let NewId;
//       if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
//         NewId = listofNumbers.filter(Temp=> Temp !== value);
//         setListofNumbers(NewId)
//       }else{
//         setListofNumbers([...listofNumbers,value])
//       }
//     }
  
//     const selectMultipleFunction = (e) =>{ 
//   console.log(e.target.checked);
//   if(e.target.checked){
//     setListofNumbers(fullList)
//   }else{
//     setListofNumbers([])
//   }
  
//     }

//     const handleMultiDelete = (data) => {
//        axiosInstance.delete(`items/multi-delete`,{
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         data: {"ids" : data},
//       })
//        .then(res => {
//         if (res.data?.status === true) {
//           if(res?.data?.not_deleted_ids.length<=0){
//             toast.open({
//               type: "success",
//               message: "Success",
//               description: res.data?.msg || "Deleted Successfully."
//             });
//             getData();
//             setShowDelete(false)
//           }else{
//             toast.open({
//               type: "warning",
//               message: "Success",
//               description: `${res.data?.msg} \n Some Products has dependenies`|| "Deleted Successfully."
//             });
//             getData();
//             setShowDelete(false)
//           }

//         } else if (res.data?.status === false && res.data?.error) {
//           toast.open({
//             type: "error",
//             message: "Error",
//             description: res.data.error || "Delete UnSuccessfull"
//           });
//         } else {
//           toast.open({
//             type: "warning",
//             message: "Warning",
//             description: res.data?.msg || "Warning."
//           });
//         }
//       })
//       .catch(err => {
//         console.error(err);
//         toast.open({
//           type: "error",
//           message: "Error",
//           description: "Network Error."
//         });
//       });
//     }
  
//     useEffect(()=>{
//       let tempArr = []
//       data && data.forEach(i=>{
//         console.log("I",i?.productId);
//         tempArr.push(i?.productId)
//       });
//       setFullList(tempArr)
//     },[data])
//   return (
//     <div className='w-full h-full px-4 py-2'>
//       <div className='w-full flex justify-between items-center'>
//       <Breadcrumb
//           items={[
            
//             { name: "Project" },
//             { name: "Others" },
//           ]} />
//         <div className=' flex gap-2'>
//         <SearchBar
//               handleChange={(e) =>{
//                 setParams({ ...params, search: e.target.value, page:1 });
//                 setPage(1)
//               }}
//               value={params.search||searchQuery}
//             />
//              <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
//           {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
//           {/* {GetRole() && <ImportButton handleClick={handleImportClick} />} */}
//           {/* {GetAccess("items_create") && */}
//           <Link to={`/project/costing/others/${officeId}/${projectId}/create`}><CreateButton label='Others' /></Link>
//           {/* // } */}
//         </div>
//       </div>
//       <CustomDataTable 
//       fetcher={getData}
//         data={data} 
//         availableHeaders={availableHeaders} 
//         defaultHeader={defaultHeader}
//         bodyHeight='max-h-[calc(100vh-260px)]'
//         height='h-[calc(100vh-145px)]'
//         total={totalCount}
//         pageIndex={page}
//         setPageIndex={setPage}
//         dataCount={pageCount}
//         setDataCount={setPageCount}
//         title='Others List' 
//         storageName="othersTable"
//         selectOneFunction={selectOneFunction}
//         setShowDelete={setShowDelete}
//         showDelete={showDelete}
//         setShowEdit={setShowEdit}
//         showEdit={showEdit}
//         listofNumbers={listofNumbers}
//         hasMultiEdit={true}
//         selectMultipleFunction={selectMultipleFunction}
//         fullList={fullList}
//         getById="biddingId"
//         showFieldFalse={false}
//         editAccess="items_edit"      
//         deteleAccess="items_delete"
//         dataLoading={dataLoading}   
//         searchQuery={params?.search!=""?params?.search:""}
//         />
//        {showFilterDialog && (
             
             
//              <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
//                <div className="animate-scale-in min-w-[50%] max-w-[70%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
//                  <h2 className="text-xl mb-4">Filter Others</h2>
//                  <form onSubmit={formik.handleSubmit}> 
//                     <div className="flex justify-start flex-wrap mb-4">
//                     <FormikSelectTwo
//                   label="Category"
//                   Options={Category||[]}
//                   formik={formik}
//                   placeholder="Choose"
//                   name="category"
//                   width="w-full"
//                   getFunc={refOthersCategory}
//                   displayProperty={"Category"}
//                   propertyName={"OthersCategory"}
//                   isRequired={true}
//                 /> 

//                 <FormikSelectTwo
//                   label="Division"
//                   Options={Division||[]}
//                   formik={formik}
//                   placeholder="Choose"
//                   name="division"
//                   width="w-full"
//                   getFunc={refOtherDivision}
//                   displayProperty={"Division"}
//                   propertyName={"OtherDivision"}
//                   isRequired={true}
//                 /> 

// <FormikSelectTwo
//                   label="Unit"
//                   Options={Unit||[]}
//                   formik={formik}
//                   placeholder="Choose"
//                   name="unit"
//                   width="w-full"
//                   getFunc={refOtherUnit}
//                   displayProperty={"Unit"}
//                   propertyName={"OtherUnit"}
//                   isRequired={true}
//                 />
            
//             {/* <button
//                type="submit"
//               // size="large"
//               className="w-[95%] bg-[#048178] text-white  mx-auto py-1 border-none outline-none rounded-md"
//             >
//               Filter 
//             </button> */}
//                     </div>
//                    <div className="flex justify-end gap-2">
//                      <CancelButton handleClick={closeFilterDialog} />
//                      <SubmitButton name='Apply' />
//                    </div>
//                   </form> 
//                </div>
//              </div>
//            )}
                  
//     </div>
//   )
// }

// export default Others;






import React, { useEffect, useState } from "react";
import axios from "../../../../../../Services/axiosCommon";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import { Button, Modal,  Select } from "antd";
import { useFormik } from 'formik';
import * as yup from "yup";
import { useNumber } from "../../../../../../Context/ProjectIdContext";
import CostingLayout from "../CostingLayout";
import ViewMore from "./ViewMore";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import { GetAccess } from "../../../../../../Components/Utils/roles";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import { NuSelect } from "../../../../../../Components/Utils/Input";



function Others(id) {
  const { officeId,projectId } = useParams();
  const {value:Category} = useFetchMataData(`/Metadata/category`)
  const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const {value:Division} = useFetchMataData(`/Metadata/division`)

  // const location = useLocation();
  // const projectTask = location?.state?.projectTask || "";
  // const { number, updateNumber } = useNumber();
  const [percentOpen,setPercentOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([])
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter,setIsFilter]= useState(false);
  const [notFirst,setNotFirst]=useState(false)
  const [filterData,setFilterData]= useState({
    division: "",
    category: "",
    unit: ""
  });
  const [afterFilter,setAfterFilter]= useState({})
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/others/${projectId}/details?page=${1}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
        .then((res) => {
          setData(res.data?.data);
        setTotalProject(res.data?.total_records)

        })
        .catch((err) => console.log(err));
    } else {
      getTask(firstProject);
    }
  }

  function SearchPageDate(){
    axios
    .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
    .then((res) => {
      setData(res.data?.data);
      setTotalProject(res.data?.total_records)

    }).catch((err) => console.log(err))
  }



  function getTask() {
    axios
      .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchQuery == "" && notFirst) {
      getTask();
    }
  }, [searchQuery]);

  useEffect(() => {
    getTask();
  }, []);

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let MyTaskCreate = userAccess
    ? userAccess.some(
        (permission) => permission.permissionName == "MyTask_create"
      )
    : false;

  // useEffect(() => {
  //   // console.log("jj",projectNum);
  //   if (projectTask) {
  //     setFirstProject(projectTask);
  //     // console.log("gf", firstProject);
  //   }
  // }, [projectTask]);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({

    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axios
      .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{division:`${value.division}`,category:`${value.category}`,unit:`${value.unit}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });

  function FilterPage(){
    axios
      .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{status:`${afterFilter.status}`,SDF:`${afterFilter.SDF}`,SDT:`${afterFilter.SDT}`,EDF:`${afterFilter.EDF}`,EDT:`${afterFilter.EDT}`,priority:`${afterFilter.priority}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)

      }).catch(err=>console.log(err))
  }


  function ClearFilter(){
    formik.resetForm({})
    getTask(firstProject);
    setIsFilter(false)
  }

  useEffect(()=>{
   if(isFilter){
    FilterPage();
   }else if(searchQuery !=""){
      SearchPageDate();
    }else{
    getTask(firstProject)
    }
  },[pageIndex,dataCount])

  console.log("zzzz",firstProject);

  return (
    <>
      <CostingLayout
        setOpen={setOpen}
        setSearchQuery={setSearchQuery}
        SearchData={SearchData}
        isFilter={isFilter}
        setFilterOpen={setFilterOpen}
        ClearFilter={ClearFilter}
        CreateAccess={GetAccess()}
        ImportAccess={GetAccess()}
        officeId={officeId}
        projectId={projectId}
      >
        <div className=" w-full h-[calc(100vh-180px)] rounded-md overflow-y-auto">
          <table
            className="table-auto w-full px-3"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <thead className=" w-full bg-white p-3 mt-4">
              <tr className="text-xs border-b  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} ${
                      header?.isLeft ? "text-left" : "text-center"
                    }  px-2 font-medium text-xs 2xl:text-sm text-[#4F4768]`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {data.map((item, i) => (
                <tr className=" w-full " key={i}>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    {item?.serial_number || "#"}
                  </td>
                  <td className="py-2  text-xs text-left font-normal text-mainColor-400 truncate">
                  <Link className='hover:text-baseColor-primary text-mainColor-400 truncate'  to={`/project/costing/others/${officeId}/${projectId}/view/${item?.id}`} state={{data:item}}>  {item?.activity || "-"}</Link>
                
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                  {/* <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/project/costing/others/${officeId}/${projectId}/view/${item?.id}`} state={{data:item}}>  */}
                   {item?.category || "-"}
                   {/* </Link> */}
                   
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                  <NuAmount value={item?.amount||0} />
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    {item?.division || "-"}
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    {item?.quantity || "-"}
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    <div className=" w-full flex justify-center items-center">
                      <ViewMore
                        id={item?.id}
                        getData={getTask}
                        data={item}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className=" h-6 my-2">
          <Pagination
            total={totalTasks}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            dataCount={dataCount}
            setDataCount={setDataCount}
          />
        </div>
      </CostingLayout>
      <Modal
        open={filterOpen}
        title={"Filter Others"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-col flex-wrap">
               <NuSelect
                  label="Category"
                  options={Category||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full"
                  isRequired={true}
                /> 

                <NuSelect
                  label="Division"
                  options={Division||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full"
                  isRequired={true}
                />

                <NuSelect
                  label="Unit"
                  options={Unit||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="unit"
                  width="w-full"
                  isRequired={true}
                />
            
            <button
               type="submit"
              // size="large"
              className="w-[95%] bg-[#119DEE] text-white  mx-auto py-1 border-none outline-none rounded-md"
            >
              Filter 
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default Others;

const header = [
  {
    headone: "ID",
    width: "w-[3%]",
    // isLeft: true,
  }, 
  {
    headone: "Activity",
    width: "w-[26%]",
    isLeft: true,
  },
  {
    headone: "Category",
    width: "w-[13%]",
    // isLeft: true,
  },
  {
    headone: "Amount",
    width: "w-[13%]",
  },
  {
    headone: "Division",
    width: "w-[10%]",
  },
  {
    headone: "Quantity",
    width: "w-[10%]",
  },
  {
    headone: "Action",
    width: "w-[5%]",
    isLeft: true,
  },
];

