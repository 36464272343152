import { useEffect, useState } from "react";
import { Button, Modal, notification } from "antd"
import { useFormik } from "formik";
import axios from "../../../../../../Services/axiosCommon";
import { NuInput, NuInputfile, NuTextArea } from "../../../../../../Components/Utils/Input";
const CreatePercentage = ({ open, onCancel, taskId, getData,list,projectId,data }) =>{
    const [api, contextHolder] = notification.useNotification();
    // console.log("##",taskId);
    const [createNew, setCreateNew] = useState({
        taskCompletion:'',
        taskId:"",
        // subTaskId:"",
        cmt: "",
        // files: "",
    })


    useEffect(()=>{
        setCreateNew({
            taskCompletion:data,
        })
    },[])

    const [image, setImage] = useState({ preview: "", raw: "" });
  
    const handleChangeImg = (e) => {
      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: yup.object({
            
        // }),
        onSubmit: async (value,{resetForm}) => {
            let completion = {
                taskCompletion:value.taskCompletion
            }
            let comments = {
                taskId:taskId,
                cmt:value.cmt,
            }
              axios
               .put(`/Task/${taskId}/task-completions`, completion)
               .then((res) => {
                if (res.status === 201 || res?.data?.status == true) {
                    if(value.cmt||image.raw){
                        AddTaskComment(comments,resetForm)
                    }
                    // AddTaskComment(resetForm)
                    getData(projectId);
                    api.success({
                        message: `Task Persentage Updated successfully`,
                        // description:'',
                        placement: 'bottomRight',
                    });
                    onCancel();
                } else {
                    api.error({
                        message: `Task Persentage Update failed`,
                        description: res.data.msg || 'Network error',
                        placement: 'bottomRight',
                    });
                }
            })
               .catch((err) => {
                api.error({
                  message: `Task Persentage Update failed`,
                  description: err?.response?.data.msg || 'Network error',
                  placement: 'bottomRight',
              });
               })
        }
    })


    function AddTaskComment(value,resetForm){
        let formData = new FormData();
        formData.append("data", JSON.stringify(value));
        formData.append("files", image.raw);
        axios.post("/Comment/task", formData).then((res) => {
          if (res.status === 201 || res.status === 200) {
            // console.log(res);
            resetForm();
            setImage({ preview: "", raw: "" });
            // getComments();
            //   onCancel();
            api.success({
              message: `Comment Add successfully`,
              // description:'',
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Comment Add failed`,
              description: res.data.msg | "Network error",
              placement: "bottomRight",
            });
          }
        }).catch(err=>{
            console.log(err);
        });
    }

function onClose(){
  formik.resetForm()
  onCancel()
}


    return(
        <Modal open={open} title='Task Percentage' className="my-modal" width={'40%'} centered onCancel={onClose} footer={[]}>
            {contextHolder}
          <form onSubmit={formik.handleSubmit}>
          <NuInput              type="number"
  label='Task Percentage' placeholder='0' formik={formik} name='taskCompletion' width='w-full' max={100} />
          <NuTextArea label='Comment' placeholder='Enter Comment' formik={formik} name='cmt' width='w-full' />
          <NuInputfile label='Upload' placeholder='Select File' handleChange={handleChangeImg}  name='cmt' width='w-full' />
          <div className=' w-full flex justify-end items-center mt-2'>
            <Button onClick={onClose} className=' mr-4'>
              Cancel
            </Button>
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
            </div>
            </form>
        </Modal>
    )
}

export default CreatePercentage;