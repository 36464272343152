import React, { useState } from 'react'
import UseGetData from '../../../Components/Hooks/useFetchData';
import { Link, useLocation } from 'react-router-dom';
import Pagination from '../../../Components/Utils/Pagenation';
import OfficeCard from './OfficeCard';

function OfficeIndex() {
    const location = useLocation();
    const currentPage= location.state?.currentPage
    const [page, setPage] = useState(currentPage||1)
    const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
    const {data, totalCount , getData} = UseGetData(page, pageCount, `/company_branch/`);

    console.log("wwww",data);
    
  return (
    <div className=" w-full h-full">

    <div className=" w-full h-[calc(100vh-170px)] overflow-y-auto p-3">
    <div className=" grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
    {data &&
      data.map((item) => {
        return (
          <>
            {" "}
            <Link
              className=" text-gray-800 hover:text-gray-800 "
              to={`/main/${item.branchId}/dashboard`}
            >
              <OfficeCard
                Name={item?.branch_name}
                id={item?.branchId}
                address={item?.country}
                status={item?.status}
                city={item?.city}
              />
            </Link>{" "}
          </>
        );
      })}
  </div>
    </div>
  <div className=" w-full h-[30px] ">
    <Pagination
      total={totalCount}
      pageIndex={page}
      setPageIndex={setPage}
      dataCount={pageCount}
      setDataCount={setPageCount}
    />
  </div>
</div>
  )
}

export default OfficeIndex;