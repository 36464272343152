import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function CategoryOthers() {
    return (
        <SettingsMetaData propertyName={"OthersCategory"} viewPropertyName={"Category"} />
      );
}

export default CategoryOthers
