import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function EquipmentDivision() {
    return (
        <SettingsMetaData propertyName={"EquipmentDivision"} viewPropertyName={"Division"} />
      );
}

export default EquipmentDivision
