import React from "react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../../../Components/Utils/Buttons";
import Dasboard from "../../../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import { notification } from "antd";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { NuInput, NuLabel, NuSelect, NuTextArea } from "../../../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";

function EditOthers() {
  // const {value:Category} = useFetchMataData(`/Metadata/category`)
  // const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  // const {value:Division} = useFetchMataData(`/Metadata/division`)
  const { projectId,officeId } = useParams();

  const {value:Category ,getData:refOthersCategory} = useFetchMataData(`/dropdown/dropdown-details/`,'OthersCategory')
  const {value:Division ,getData:refOtherDivision} = useFetchMataData(`/dropdown/dropdown-details/`,'OtherDivision')
  const {value:Unit ,getData:refOtherUnit} = useFetchMataData(`/dropdown/dropdown-details/`,'OtherUnit')
 
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  console.log("cc",data);
  const [api, contextHolder] = notification.useNotification();
  // const [projectList,setProjectList] =useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    projectId: "",
    activity: "",
    category: "",
    division: "",
    amount: "",
    unit: "",
    quantity: "",
    comments: ""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      data
        ? axios
            .put(`/others/${data?.id}`, value)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/others/", value)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });


  // async function getProject() {
  //   await axios
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {

  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       (await res?.data?.data) &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);

  //       // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
  //       // console.log('====',res?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // useEffect(() => {
  //   getProject()
  // },[])


  useEffect(() => {
    setCreateNew({
      projectId:data?.projectId||"",
      activity:data?.activity||"",
      category:data?.category||"",
      division:data?.division||"",
      unit:data?.unit||"",
      quantity:data?.quantity||"",
      amount:data?.amount||"",
      comments:data?.comments||""
    })
  },[data])

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
      <Breadcrumb 
      items={[

        { name: "Budget" },
           
        {
          name: "Others",
          link: `/project/costing/others/${officeId}/${projectId}`
        },

        { name: "Edit" },
      ]} 
            />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[85%] rounded-[6px] bg-white mt-2"
          // style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Others" />
              {/* <BackButton /> */}
            </div>
            <div className="flex flex-wrap justify-start">

            {/* <NuSelect
                  label="Project"
                  options={projectList||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="projectId"
                  width="w-full md:w-1/3"
                  isRequired={true}
                /> */}
                
              <NuInput
                           type="text"

                 label="Activity"
                 formik={formik}
                 placeholder="Activity"
                 name="activity"
                 width="w-full md:w-1/3"
                 isRequired={true}
                /> 

<FormikSelectTwo
                  label="Category"
                  Options={Category||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full md:w-1/3"
                  getFunc={refOthersCategory}
                  displayProperty={"Category"}
                  propertyName={"OthersCategory"}
                  isRequired={true}
                /> 

                <FormikSelectTwo
                  label="Division"
                  Options={Division||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full md:w-1/3"
                  getFunc={refOtherDivision}
                  displayProperty={"Division"}
                  propertyName={"OtherDivision"}
                  isRequired={true}
                /> 

                <NuInput
                             type="number"

                 label="Amount"
                 formik={formik}
                 placeholder="Amount"
                 name="amount"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />

<FormikSelectTwo
                  label="Unit"
                  Options={Unit||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="unit"
                  width="w-full md:w-1/3"
                  getFunc={refOtherUnit}
                  displayProperty={"Unit"}
                  propertyName={"OtherUnit"}
                  isRequired={true}
                />
 
                <NuInput
                             type="number"

                 label="Quantity"
                 formik={formik}
                 placeholder="Quantity"
                 name="quantity"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />      

                <NuTextArea
                  label="Comments"
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full md:w-2/3"
                />


            </div>
            <div className="flex justify-between items-center">
              <div className="w-1/2">

              </div>

              <div
                className=" mr-2 rounded-md"
                style={{ border: "1px solid #C5C5C5" }}
              >

              </div>
              <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
            </div>

            
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditOthers;