import React, { useEffect, useRef } from 'react';
import { TextMediumBase, TextMediumSM } from '../Text/MediumText';

const Modal = ({ show, onClose, children, width="w-11/12", height="h-[95vh]" }) => {
    const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[999]">
      <div ref={modalRef} className={`bg-white ${width} ${height}  rounded-lg overflow-hidden`}>
        <div className='px-[3%] mx-auto flex justify-between pt-5'>
            <TextMediumBase content={"Attachment View"} />
            <div className='w-6 h-6 rounded-md bg-baseColor-secondary cursor-pointer' onClick={onClose}>
                <p className='text-white text-xs text-center py-1'>X</p>
            </div>
        </div>
        <div className="m-4 overflow-y-scroll h-full flex justify-center ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
