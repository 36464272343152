import React from 'react'
import NuDate from '../../../../Components/Utils/NuDate';

function DelayedActivities({data=[]}) {
  return (
    <div className="w-full bg-white h-[45vh] p-2 rounded-lg" style={{ border: "1px solid #E9E8EC" }}>
    <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px] py-1">Delayed Activities</p>
            <div className=" w-full overflow-y-scroll h-[calc(45vh-40px)]">
              <table className="overflow-hidden text-center  text-xs border-collapse w-full overflow-y-scroll">
                <thead className="border-b  rounded-md overflow-hidden font-medium  text-xs">
                  <tr
                    className=""
                    style={{
                      borderTop: "1px solid #E9E8EC ",
                      borderBottom: "1px solid #E9E8EC ",
                    }}
                  >
                    <th
                      scope="col"
                      className="sticky py-2 w-[40%] font-semibold text-[#6C6580] text-xs text-start pl-2 "
                    >
                      Activity
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[20%] font-semibold text-[#6C6580] text-left text-xs"
                    >
                      Start Date
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[20%] font-semibold text-[#6C6580] text-left text-xs"
                    >
                      End Date
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[20%] font-semibold text-[#6C6580] text-left text-xs"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className=" px-2 ">
                  {data &&
                    data.map((Item, Index) => {
                      return (
                        <tr
                          key={Index}
                          className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-10"
                          style={{ borderBottom: "1px solid #E9E8EC " }}
                        >
                                                    <td className="pl-2 text-left text-[#4F4768] l w-[40%] h-full  text-xs">
                            <div className=" w-full h-full items-center line-clamp-2">
                            {Item?.taskName || "-"}
                            </div>
                          </td>
                          <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                            <div className=" w-full h-full items-center line-clamp-2">
                            {Item?.startDate && <NuDate value={Item?.startDate} format="DD-MMM-YYYY" /> || "-"}
                            </div>
                          </td>
                          <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                            <div className=" w-full h-full items-center line-clamp-2">
                            {Item?.endDate && <NuDate value={Item?.endDate} format="DD-MMM-YYYY" /> || "-"}
                            </div>
                          </td>
                          <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                            <div className=" w-full h-full items-center ltext-center ine-clamp-2">
                            {Item?.taskStatus || "-"}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
    </div>
  )
}

export default DelayedActivities