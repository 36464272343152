import { Link, Outlet, useLocation, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { GetAccess } from "../../../Components/Utils/roles";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import Dashboard from "../Dashboard";
import SearchBar from "../../../Components/Utils/SearchBar";
import { BackButton, ClearFilterButton, CreateButton, FilterButton, ImportButton } from "../../../Components/Utils/Buttons";
import Bidding from "../../../Components/Icons/Bidding";



const BidLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        isFilter=false,
        newfilter=false,
        neededSearch=false,
        searchQuery="",
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        // officeId,
        projectId,
        onCreateClick,
        searchValue="",
        searchFun=()=>{},
    }
) =>{
    let {officeId,estmationId} =useParams()
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/boq`){
            setModule("BOQ")
        }else if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/material`){
            setModule("Material")
        }else if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/manpower`){
            setModule("Manpower")
        }else if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/equipment`){
            setModule("Equipment")
        }else if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/others`){
            setModule("Others")
        }else if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/profit-margin`){
            setModule("Profit Margin")
        }else if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/attachments`){
            setModule("Attachments")
        }else if(pathname == `/main/${officeId}/bidding/estimation/${estmationId}/tc`){
            setModule("T & C")
        }
    },[pathname])

    let LinkItem = [
        {
            label:"BOQ",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/boq`,
            access:GetAccess
            (),
        },
        {
            label:"Material",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/material`,
            access:GetAccess(),
        },
        {
            label:"Manpower",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/manpower`,
            access:GetAccess(),
        },
        {
            label:"Equip.",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/equipment`,
            access:GetAccess(),
        },
        {
            label:"Others",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/others`,
            access:GetAccess(),
        },
        {
            label:"Profit Marg.",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/profit-margin`,
            access:GetAccess(),
        },
        {
            label:"Att.",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/attachments`,
            access:GetAccess(),
        },
        {
            label:"T & C",
            link:`/main/${officeId}/bidding/estimation/${estmationId}/tc`,
            access:GetAccess(),
        },
    ]
    
    return(
        <div className=" w-full h-full py-2 px-4 overflow-hidden">
            
            <div className="md:flex justify-between w-full ">
            <div className=" w-full h-8 flex items-center gap-x-1 pt-2">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                             <Link to={item?.link} className={`mx-1 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                                
                                // <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #048178"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
            {/* <Breadcrumb
          items={[
            { name: "Bidding" ,
              link: `/main/${officeId}/bidding/bids`,

             },
            { name: "Estimation" },
            { name: `${module}`}



          ]} /> */}
                <div className=" flex justify-end items-center gap-2 ">
            <form className="flex items-center gap-2 mx-0" onSubmit={SearchData}>
            {neededSearch &&    <SearchBar handleChange={searchFun}  value={searchValue}/>}
           
              </form>
              {newfilter && (
  <>
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <ClearFilterButton handleClick={ClearFilter} />} </>)}
       
  {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}
              {CreateAccess && <CreateButton handleClick={onCreateClick} label={"Title "}/>}

              {/* {CreateAccess && <CreateButton handleClick={onCreateClick} label={module == "BOQ" || module == "Material" || module == "T & C" || module == "Manpower" || module == "Equipment" || module == "Others" || module == "profit-margin" ? `${module} Title` : module }/>} */}
                  <BackButton/>
                  <Link to={`/main/${officeId}/bidding/bids`}>
                  <button
      className="px-1 md:px-3 h-6 md:h-8 cursor-pointer text-xs text-white bg-baseColor-primary  rounded-md flex items-center"
    //   style={{ border: "1px solid #ffc64d" }}
    >
      <span className="md:mr-2 flex justify-center items-center">
        <Bidding color="white"/>
      </span>
      Bid
      </button>
      </Link>
                </div>
            </div>
   
           {/* <div className=" rounded-md mt-2 px-1 pt-0.5"> */}
      {/* <div className="w-[100%] h-[calc(100vh-190px)]  mt-4">
 */}
           
           
            <div className="bg-white w-full max-h-[calc(100vh-120px)] overflow-hidden rounded-md mt-4">
               {children} 
            </div>
            </div>
            
            // </div>
        // </div>
    )
}


export default BidLayout;