import React from "react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../../../Components/Utils/Buttons";
// import Attachement from "./Attachement";
import * as yup from "yup";
import { Modal, notification } from "antd";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import axiosInstance from "../../../../../../Services/axiosCommon";
import { NuInput, NuSelect } from "../../../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";



function EditManpowerPro({open, getData, onCancel, data,id,projectId }) {
  // const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const {value:Role ,getData:refManPowerRole} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerRole')

  const {value:Unit ,getData:refManPowerUnit} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerUnit')
    const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    description: "",
    unit: "",
    budget_quantity: "",
    actual_quantity: "",
    budget_Price: "",
    actual_Price: "",
    amount: "",
    Planned_days:"",
    actual_days:"",
  });
  useEffect(() => {
    data&&setCreateNew({
    description: data?.description || "",
    unit: data?.unit || "",
    budget_quantity: data?.budget_quantity || "",
    actual_quantity: data?.actual_quantity || "",
    budget_Price: data?.budget_Price || "",
    actual_Price: data?.actual_Price || "",
    amount: data?.amount    || "",
    Planned_days: data?.Planned_days    ||  "",
    actual_days: data?.actual_days    || "",
    role: data?.role    || "",

    // estimationId: data?.estimationId || "",
    // IT_Id: data?.IT_Id || ""
    })
  },[data])
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema:yup.object({
            description:yup.string().required("Description Name is Requried")
        }),
        onSubmit: (value,{resetForm}) => {
          axiosInstance.put(`/pro_manpower/update/${projectId}/${id}/${data?.id}`, value)
          .then((res) => {
              console.log("res",res.data.status);
              if (res.data.status) {
                getData(id);
                resetForm();
                api.success({
                  message: `Material Item Update successfully`,
                  description: res?.data?.msg || "Added In The Activity",
                  placement: "bottomRight",
                });
                onCancel();
              } else {
                api.error({
                  message: `Material Item Upadate failed`,
                  description: res?.data?.msg || "Network error",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              api.error({
                message: `failed`,
                description: err?.data?.msg || "Sever error (or) Network error",
                placement: "bottomRight",
              });
            });
        },
      });
      useEffect(() => {
        const totalPrice = formik?.values?.actual_quantity * formik?.values?.actual_Price
        formik.setFieldValue("amount", totalPrice);
      }, [formik?.values?.actual_quantity,formik?.values?.actual_Price]);
      return (
        <>
        {contextHolder}
        <Modal
          open={open}
          title={"Edit Manpower Item"}
          width={"50%"}
          className="my-modal"
          centered
          footer={[]}
          onCancel={onCancel}
        >
        <form onSubmit={formik.handleSubmit} >
          <div className=" w-full flex flex-wrap">
             <NuInput
                          type="text"

                  label="Description"
                  placeholder="Description"
                  formik={formik}
                  name="description"
                  width="w-full"
                  isRequired={true}
              />
              <FormikSelectTwo
              label="Unit"
              Options={ Unit || [] }
              formik={formik}
              placeholder="Choose"
              name="unit"
              width="w-full md:w-1/3"
              getFunc={refManPowerUnit}
              displayProperty={"Unit"}
              propertyName={"ManPowerUnit"}
              />
               <FormikSelectTwo
                  label="Role"
                  Options={Role}
                  formik={formik}
                  placeholder="Choose"
                  name="role"
                  width="w-full md:w-1/3"
                  getFunc={refManPowerRole}
                  displayProperty={"Role"}
                  propertyName={"ManPowerRole"}
                />
                      <NuInput
                                   type="number"

                label="Planned Days"
                placeholder="Planned Days "
                formik={formik}
                name="Planned_days"
                width="w-full md:w-1/3"
              />
               <NuInput
                            type="number"

                label="Actual Days"
                placeholder="Actual Days "
                formik={formik}
                name="actual_days"
                width="w-full md:w-1/3"
              />
               <NuInput
                            type="number"

              label="Budget Quantity"
              placeholder="Budget Quantity"
              formik={formik}
              name="budget_quantity"
              width="w-full md:w-1/3"
            />
            <NuInput
                         type="number"

              label="Budget Price"
              placeholder="Budget Price"
              formik={formik}
              name="budget_Price"
              width="w-full md:w-1/3"
            />
            <NuInput
                         type="number"

              label="Actual Quantity"
              placeholder="Actual Quantity"
              formik={formik}
              name="actual_quantity"
              width="w-full md:w-1/3"
            />
            <NuInput
                         type="number"

              label="Actual Price"
              placeholder="Actual Price"
              formik={formik}
              name="actual_Price"
              width="w-full md:w-1/3"
            />           
            <NuInput
                         type="number"

              label="Actual Total Price"
              placeholder="Actual Total Price"
              formik={formik}
              name="amount"
              width="w-full md:w-1/3"
              disable={true}
            />
                <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
                  <CancelButton handleClick={onCancel} />
                  <SubmitButton isLoading={isLoading} />
                </div>
          </div>
          </form>
        </Modal>
        </>
      )
    }

export default EditManpowerPro
