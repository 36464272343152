import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Status from "./Status";

const PaymentRequest = () =>{
    return(
        <>
        <TextMediumSM content="Payment Request" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         </div>
         </>
    )
}

export default PaymentRequest;