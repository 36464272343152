import React, { useState } from 'react';

const Due = () => {
   const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
   
  
    const [params, setParams] = useState({
      month: currentMonth,
      year: currentYear,
    });

     const [selectedMonth, setSelectedMonth] = useState(currentMonth);
      const [selectedYear, setSelectedYear] = useState(currentYear);
    
      const handleMonthChange = (e) => {
        const newMonth = e.target.value;
        setParams({ ...params, month: newMonth });
        setSelectedMonth(newMonth);
      };
    
      const handleYearChange = (e) => {
        const newYear = e.target.value;
        setParams({ ...params, year: newYear });
        setSelectedYear(newYear);
      };
    
      const months = [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 },
      ];
      
    

  const dummyData = [
    {
      item: 'Invoice # 3278499',
      payReceive: 'Pay',
      toFrom: 'Fedex',
      amount: 'SAR 23,434.00',
      dueDate: '15-Sep-23',
      daysElapsed: -25,
    },
    {
      item: 'Invoice # 3278499',
      payReceive: 'Receive',
      toFrom: 'Fedex',
      amount: 'SAR 23,434.00',
      dueDate: '15-Sep-23',
      daysElapsed: -25,
    },
    {
      item: 'Invoice # 3278499',
      payReceive: 'Receive',
      toFrom: 'Fedex',
      amount: 'SAR 23,434.00',
      dueDate: '15-Sep-23',
      daysElapsed: -25,
    },
    {
      item: 'Invoice # 3278499',
      payReceive: 'Pay',
      toFrom: 'Fedex',
      amount: 'SAR 23,434.00',
      dueDate: '15-Sep-23',
      daysElapsed: -25,
    },
  ];

  return (
    <div className="bg-white p-4 rounded-lg drop-shadow-lg h-[55vh] 2xl:h-[45vh] w-full">
      <div className="flex justify-between items-center">
        <h2 className="font-semibold text-[#4F4768] text-sm">Due</h2>
        <div className="text-center text-xs text-[#304463] flex space-x-2">
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="border rounded-md p-1 text-xs text-[#ACACAC] font-normal"
          >
            {[...Array(10)].map((_, index) => (
              <option key={index} value={currentYear - 5 + index}>
                {currentYear - 5 + index}
              </option>
            ))}
          </select>
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="border rounded-md p-1 text-xs text-[#ACACAC] font-normal"
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
      </div>
<div className='flex justify-center text-center mb-2 gap-x-4'>
        <div className="flex  flex-col items-center">
        <span className=' text-[#4F4768] text-xs font-medium'>SAR 23,434.00</span>

          <span className="bg-green-500 rounded-md w-32 h-2.5 mr-2"></span>
        </div>
        <div className="flex  flex-col items-center">
        <span className='text-[#4F4768] text-xs font-medium'>SAR 23,434.00</span>

          <span className="bg-red-500 rounded-md w-32 h-2.5 mr-2"></span>
        </div>
        </div>
      <table className="w-full">
        <thead>
          <tr className='border-b'>
            <th className="text-left font-normal text-sm">Item</th>
            <th className="text-left font-normal text-sm">Pay/Receive</th>
            <th className="text-left font-normal text-sm">To/From</th>
            <th className="text-left font-normal text-sm">Amount</th>
            <th className="text-left font-normal text-sm">Due Date</th>
            <th className="text-left font-normal text-sm">Days Elapsed</th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map((item, index) => (
            <tr key={index} className='border-b'>
              <td className='text-[#4F4768] text-xs py-2 font-normal'>{item.item}</td>
              <td className={`text-xs py-2 font-normal ${item.payReceive === 'Pay' ? 'text-red-500' : 'text-green-500'}`}>{item.payReceive}</td>
              <td className='text-[#4F4768] text-xs py-2 font-normal'>{item.toFrom}</td>
              <td className='text-[#4F4768] text-xs py-2 font-normal'>{item.amount}</td>
              <td className='text-[#4F4768] text-xs py-2 font-normal'>{item.dueDate}</td>
              <td className={`text-xs py-2 font-normal ${item.daysElapsed < 0 ? 'text-red-500' : 'text-green-500'}`}>
                {item.daysElapsed}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Due;
