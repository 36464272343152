import React from "react";

function Timesheet({ color = "#999999", width=21, height=21 }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <path d="M70.9,20H29.1c-4.3,0-7.8,3.5-7.8,7.8v44.3c0,4.3,3.5,7.8,7.8,7.8h41.7c4.3,0,7.8-3.5,7.8-7.8V27.8 C78.7,23.5,75.2,20,70.9,20z M68.3,67c0,1.4-1.2,2.6-2.6,2.6H34.3c-1.4,0-2.6-1.2-2.6-2.6v-2.6c0-1.4,1.2-2.6,2.6-2.6h31.3 c1.4,0,2.6,1.2,2.6,2.6V67z M40.9,50l1.4-1.4c0.4-0.4,1-0.4,1.4,0l3.6,3.6l9-9c0.4-0.4,1-0.4,1.4,0l1.4,1.4c0.3,0.4,0.3,1.1,0,1.4 L48.8,56.5c-0.4,0.4-0.9,0.6-1.4,0.6c-0.5,0-1-0.2-1.4-0.6l-5-5C40.5,51,40.5,50.4,40.9,50z M68.3,35.7c0,1.4-1.2,2.6-2.6,2.6H34.3 c-1.4,0-2.6-1.2-2.6-2.6V33c0-1.4,1.2-2.6,2.6-2.6h31.3c1.4,0,2.6,1.2,2.6,2.6V35.7z" />
      </g>
    </svg>
  );
}

export default Timesheet;
