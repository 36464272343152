import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';

const ViewAgents = () => {
  // const { patientsViewId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  const pageIndex = location.state?.pageIndex;
  const searchQuery = location.state?.searchQuery;
  
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
         items={
          [
            {
              name: "Shipping",
              link: "/shipping/agents"
            },  
          {
            name: "Shipping Agent",
            link: "/shipping/agents"
          },
          {
            name: "View Agents"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/shipping/agents'} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
          <Link to={`/shipping/agents/edit/${data?.agentId}`} state={{data: data, from:`/shipping/agents/view/${data.agentId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll p-3 mt-4">
      <NormalHeading title={data?.companyName || '-'} />
    <div className='flex'> 
         <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
            {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Agent ID</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279] '>{data?.agentId || '-'}</p>
                  </div> */}
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Agent Type</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.type || '-'}</p>
                  </div>
                  {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Company Name</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.companyName || '-'}</p>
                  </div> */}
                   <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Phone</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.phone?.display || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Email</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] break-words'>{data?.email || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Website</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.website || '-'}</p>
                  </div>
                 
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Ratings</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.ratings || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person </p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279] '>{data?.cpName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Email</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279] break-words'>{data?.cpEmail || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Phone</p>
                    <p className='w-1/2 py-2  pl-5 text-xs  text-[#606279] '>{data?.cpPhone?.display || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Designation</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.cpDesignation || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Vat Number</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.vatNum || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>CR Number</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.crNum || '-'}</p>
                  </div>
                 
                 
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
           
                  
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Country</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279] '>{data?.country || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>State</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.state || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>City</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.city || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Street</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.streetName || '-'}</p>
                  </div>
           
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Postal Code</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279] '>{data?.postalCode || '-'}</p>
                  </div>
                 
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipping Address</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.revisitReq ?'Yes':'No'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Billing Address</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.billingAddress || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279] '>{data?.status || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>comments</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279] '>{data?.comments || '-'}</p>
                  </div>
                 
          </div>
        </div>
      </div>
              
          </div>
          
      </div>
  
  )
};
export default ViewAgents;