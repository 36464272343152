import React from 'react'

function ViewMaterialItems({ item, data, open, onCancel }) {
    const displayItem = item || data;
  return (
    <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Item Name</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.item || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Unit</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.unit || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Quantity</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.quantity || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Unit Price</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.unitPrice ? new Intl.NumberFormat('en-US').format(displayItem.unitPrice) : "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Amount</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.totalPrice ? new Intl.NumberFormat('en-US').format(displayItem.totalPrice) : "-"}</p>
      </div>
    </div>
  )
}

export default ViewMaterialItems;