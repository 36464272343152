import React from 'react'
import NuDate from '../../../../Components/Utils/NuDate'
import { Progress } from 'antd';
import NuAmount from '../../../../Components/Utils/NuAmount';
import { ActivatePill, CancelPill, CompletePill, HoldPill, OtherPill, ProgressPill, RejectedPill, StartPill } from '../../../../Components/Utils/StatusPills';
import { TextSM } from '../../../../Components/Text/NormalText';
import { TextMediumSM } from '../../../../Components/Text/MediumText';

function Details({projStatus="",projName="",projScope="",projId="",getProjectManager="",startDate="",endDate="",projectCompletions="",taskPercentage="",projectBudget=""}) {

      function DateDiff(createDate, expiryDate) {
        let startDate = new Date(createDate);
        let endDate = new Date(expiryDate);
        let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return diffDays == 0 ? diffDays + 1 : diffDays + 1;
      }
      function DateRemaining(createDate, expiryDate) {
        let startDate = "";
        let dateNow = new Date();
        let startDateTemp = new Date(createDate);
        let endDateTemp = new Date(expiryDate);
        if (dateNow.getTime() >= endDateTemp.getTime()) {
          return "0";
        } else {
          if (dateNow.getTime() < startDateTemp.getTime()) {
            startDate = startDateTemp;
          } else {
            startDate = dateNow;
          }
          let endDate = new Date(expiryDate);
          let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          return diffDays == 0 ? diffDays + 1 : diffDays + 1;
        }
      }
    
      function DateWorked(createDate, expiryDate) {
        return (
          DateDiff(createDate, expiryDate) - DateRemaining(createDate, expiryDate)
        );
      }
  return (
    <div
    className="w-full min-h-[45vh] p-2 rounded-lg bg-white"
    style={{ border: "1px solid #E9E8EC" }}
  >
    <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px] ">Details</p>
    <table className=' w-full'>
        <tbody>
            <tr>
                <td className='w-[20%]'>
                    <p className='font-semibold text-[#6C6580] text-xs '>Name</p>
                </td>
                <td className=' w-[80%]'>
                    <p className=' text-xs'>: {projName||""}</p>
                </td>
            </tr>
            <tr className=' w-full '>
                <td className='w-[20%]'>
                    <p className='font-semibold text-[#6C6580] text-xs '>Status</p>
                </td>
                <td className=' w-[80%]'>  
                <div className="flex items-center gap-1 overflow-hidden">
                : {projStatus &&
              projStatus != "" &&
              (projStatus.toLocaleLowerCase() == "active" ? (
                <ActivatePill />
              ) : projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start" ? (
                <StartPill />
              ) : projStatus.toLocaleLowerCase() == "in-progress" ||
                projStatus.toLocaleLowerCase() == "in progress" ? (
                <ProgressPill />
              ) : projStatus.toLocaleLowerCase() == "on-hold" ||
                projStatus.toLocaleLowerCase() == "on hold" ? (
                <HoldPill />
              ) : projStatus.toLocaleLowerCase() == "cancel" ||
                projStatus.toLocaleLowerCase() == "canceled" ? (
                <CancelPill />
              ) : projStatus.toLocaleLowerCase() == "reject" ||
                projStatus.toLocaleLowerCase() == "rejected" ? (
                <RejectedPill />
              ) : projStatus.toLocaleLowerCase() == "completed" ||
                projStatus.toLocaleLowerCase() == "complete" ? (
                <CompletePill />
              ) : (
                <OtherPill text={projStatus} />
              ))}
          </div>

                </td>
            </tr>
            <tr>
                <td className='w-[20%]'>
                    <p className='font-semibold text-[#6C6580] text-xs '>Lead By</p>
                </td>
                <td className=' w-[80%]'>
                    <div className=' text-xs'> 
                    <p className=" text-textColor-300  text-[12px]">
                     : {(getProjectManager &&
                        getProjectManager.map((item, idx) => {
                          // console.log("ff",item ,idx);
                          return (
                            <span
                              className=""
                              key={idx}
                            >{`${item.firstName}  `}</span>
                          );
                        })) ||
                        "-"}{" "}
                    </p>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div
          className="w-full flex justify-between items-center flex-wrap gap-2 my-2  p-2"
          style={{ borderTop: "1px solid #E9E8EC",borderBottom: "1px solid #E9E8EC", }}
        >
          <div className=" w-[58%]  flex gap-2 flex-wrap items-center">
            <div className=" w-[45%]">
              <TextSM text={"Start Date" || "-"} />
              <div className=" text-mainColor-500 text-xs   rounded-[60px] ">
                {(startDate != "None" && (
                  <NuDate value={startDate} format="DD-MMM-YY" />
                )) ||
                  "-"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM text={"Due Date" || "-"} />
              <div className=" text-mainColor-500 text-xs   rounded-[60px] ">
                {(endDate != "None" && (
                  <NuDate value={endDate} format="DD-MMM-YY" />
                )) ||
                  "-"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM text={"Duration" || "-"} />
            </div>
            <div className=" w-[45%] flex items-center">
              <div className="  px-2 pb-[3px] font-medium text-[12px] bg-[#119DEE] flex justify-center items-center rounded text-white">
                {DateDiff(startDate, endDate)}{" "}
                {DateDiff(startDate, endDate) >= 1 ? "days" : "day"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM text={"Worked" || "-"} />
              <div className=" text-mainColor-500 text-xs   rounded-[60px] ">
                {projStatus?.toLocaleLowerCase() == "yet-to-start" ||
                projStatus?.toLocaleLowerCase() == "yet to start"||
                projStatus?.toLocaleLowerCase() == "on-hold" ||
                projStatus?.toLocaleLowerCase() == "on hold" ||
                projStatus?.toLocaleLowerCase() == "reject" ||
                projStatus?.toLocaleLowerCase() == "rejected"
                  ? " 0 "
                  : DateWorked(startDate, endDate) || 0}
                {projStatus?.toLocaleLowerCase() == "yet-to-start" ||
                projStatus?.toLocaleLowerCase() == "yet to start" ||
                projStatus?.toLocaleLowerCase() == "on-hold" ||
                projStatus?.toLocaleLowerCase() == "on hold" ||
                projStatus?.toLocaleLowerCase() == "reject" ||
                projStatus?.toLocaleLowerCase() == "rejected"
                  ? " day"
                  : DateWorked(startDate, endDate) >= 2
                  ? " days"
                  : " day"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM text={"Remaining" || "-"} />
              <div className=" text-mainColor-500 text-xs  rounded-[60px] ">
                {projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start"
                  ? " 0 "
                  : DateRemaining(startDate, endDate) || 0}
                {projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start"
                  ? " day"
                  : DateRemaining(startDate, endDate) >= 2
                  ? " days"
                  : " day"}
              </div>
            </div>
          </div>
          <div className=" w-[38%] flex flex-col justify-center items-center">
            <div className=" lg:w-20 lg:h-20 mx-auto text-center">
              <Progress
                type="circle"
                size={80}
                strokeWidth={10}
                format={(percent) => (
                  <p
                    className={` ${
                      projectCompletions?.projectlevel > 95
                        ? "text-[#87d068]"
                        : projectCompletions?.projectlevel > 50
                        ? "text-[#108ee9]"
                        : "text-[#D2BD00]"
                    } text-2xl font-semibold`}
                  >
                    {percent}
                  </p>
                )}
                percent={Math.floor(projectCompletions?.projectlevel) || 0}
                strokeColor={
                  projectCompletions?.projectlevel > 95
                    ? {
                        "0%": "#87d068",
                        "100%": "#87d068",
                      }
                    : projectCompletions?.projectlevel > 50
                    ? {
                        "0%": "#108ee9",
                        "100%": "#108ee9",
                      }
                    : {
                        "0%": "#D2BD00",
                        "100%": "#D2BD00",
                      }
                }
              />
            </div>
            <div className=" my-1">
              <TextMediumSM text={"Progress"} />
            </div>
          </div>
    </div>
    <div className=" mb-2 py-2 px-2 w-full flex justify-between items-center "  style={{borderBottom: "1px solid #E9E8EC", }}>
          <p className="text-xs font-medium text-[#171A21] w-[33.33%]">
            Task :{" "}
            <span className=" text-mainColor-500">
              {projectCompletions?.totalTask || 0}
            </span>
          </p>
          <p className="text-xs font-medium text-[#171A21] w-[33.33%] text-center">
            Open :{" "}
            <span className=" text-mainColor-500">
              {projectCompletions?.totalTask -
                projectCompletions?.totalCompletions || 0}
            </span>
          </p>
          <p className="text-xs font-medium text-[#171A21] w-[33.33%] text-right">
            Closed :{" "}
            <span className=" text-mainColor-500">
              {projectCompletions?.totalCompletions || 0}
            </span>
          </p>
        </div>

        <div className=" mb-2 py-2 px-2 w-full flex justify-between items-center "  style={{borderBottom: "1px solid #E9E8EC", }}>
          <div className="text-xs font-medium text-[#171A21] w-[33.33%]">
            <p>Awarded:</p>
            <p><NuAmount value={projectBudget[0]?.costAwarded||0}/></p>
          </div>
          <div className="text-xs font-medium text-[#171A21] w-[33.33%] text-center">
            <p>Butget:</p>
            <p><NuAmount value={projectBudget[0]?.budgetCost||0}/></p>
          </div>
          <div className="text-xs font-medium text-[#171A21] w-[33.33%] text-right">
            <p>Project Cost:</p>
            <p><NuAmount value={projectBudget[0]?.projectCost||0}/></p>
          </div>
        </div>
  </div>
  )
}

export default Details