import React from 'react'
import  { useEffect, useState } from "react";
import axios from "../../../../../../Services/axiosCommon";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import { Button, Modal,  Select } from "antd";
import { useFormik } from 'formik';
import * as yup from "yup";
import { useNumber } from "../../../../../../Context/ProjectIdContext";
import CostingLayout from "../CostingLayout";
// import ViewMore from "./ViewMore";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import { GetAccess } from "../../../../../../Components/Utils/roles";
import { NuSelect } from "../../../../../../Components/Utils/Input";
import ViewMoreEuipment from './ViewMoreEquipment';

function Equipmentindex() {
    const { officeId,projectId } = useParams();
    const {value:Category} = useFetchMataData(`/Metadata/category`)
    const {value:Division} = useFetchMataData(`/Metadata/division`)
   
    // const location = useLocation();
    // const projectTask = location?.state?.projectTask || "";
    // const { number, updateNumber } = useNumber();
    const [percentOpen,setPercentOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [dataCount, setDataCount] = useState(20);
    const [totalTasks, setTotalProject] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [projectList, setProjectList] = useState([]);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState([])
    const [priority, setPriority] = useState([]);
    const [firstProject, setFirstProject] = useState("");
    const [isFilter,setIsFilter]= useState(false);
    const [notFirst,setNotFirst]=useState(false)
    const [filterData,setFilterData]= useState({
      category:"",
      division:"",
      item:"",
      
    });
    const [afterFilter,setAfterFilter]= useState({})
    const [screenSize, setScreenSize] = useState({
      width: window.innerWidth,
    });
  
  
    useEffect(() => {
      const handleResize = () => {
        setScreenSize({
          width: window.innerWidth,
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
  
  
    const getStatus = () =>{
      axios.get(`/Metadata/taskstatus`).then(res=>{
          let data = res.data?.data?.keyValues;
          let tempData = [];
          data.forEach((item) => {
            let tempObject = { value: item, label: item };
            tempData.push(tempObject);
          });
          res.data?.data?.keyValues && setStatus(tempData);
      }).catch(error=>{
          console.log("dddd");
      })
  }
  
  const getPriority = () =>{
    axios.get(`/Metadata/taskpriority`).then(res=>{
        let data = res.data?.data?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.keyValues && setPriority(tempData);
    }).catch(error=>{
        console.log("dddd");
    })
  }
  const [activity,setActivity] =useState([])
  
  async function getActivity() {
    await axios
      .get(`/Activity/${officeId}/drop-down`)
      .then(async (res) => {
  
        let list = res?.data?.data || [];
        let finalData = [];
        (await res?.data?.data) &&
          list.map((item, idx) => {
            finalData.push({
              value: item.value,
              label: item.label,
            });
          });
          setActivity(finalData);
      })
      .catch((error) => {
        console.log(error);
      });
  }
    useEffect(() => {
      getActivity()
      getStatus()
      getPriority();
    }, []);
  
    function SearchData(e) {
      setNotFirst(true)
      e.preventDefault();
      if (searchQuery != "") {
        axios
          .get(`/material/${projectId}/details?page=${1}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
          .then((res) => {
            setData(res.data?.data);
          setTotalProject(res.data?.total_records)
  
          })
          .catch((err) => console.log(err));
      } else {
        getTask(firstProject);
      }
    }
  
    function SearchPageDate(){
      axios
      .get(`/material/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)
  
      }).catch((err) => console.log(err))
    }
  
    function handleProjectChange(e) {
      setFirstProject(e);
      // updateNumber(e)
    }
  
    function getTask() {
      axios
        .get(`/material/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`)
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.total_records)
  
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    useEffect(() => {
      if (searchQuery == "" && notFirst) {
        getTask();
      }
    }, [searchQuery]);
  
  
  
    // const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    // let MyTaskCreate = userAccess
    //   ? userAccess.some(
    //       (permission) => permission.permissionName == "MyTask_create"
    //     )
    //   : false;
  
    // useEffect(() => {
    //   // console.log("jj",projectNum);
    //   if (projectTask) {
    //     setFirstProject(projectTask);
    //     // console.log("gf", firstProject);
    //   }
    // }, [projectTask]);
  
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterData,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        mobileNo: yup
          .string()
          // .required("Mobile Number is required")
          .matches(
            /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Enter Valid Mobile Number"
          )
          .min(10, "Invalid Mobile Number")
          .max(10, "Invalid Mobile Number"),
        // email: yup.string().required("Email is required"),
      }),
      onSubmit: async (value, { resetForm }) => {
        setAfterFilter(value)
        setIsFilter(true)
        axios
        .get(`/material/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{item:`${value.item}`,division:`${value.division}`,category:`${value.category}`}})
        .then((res) => {
          setFilterOpen(false)
          setData(res.data?.data);
          setTotalProject(res.data?.total_records)
  
        })
        .catch((error) => {
          console.log(error);
        });
      },
    });
  
    function FilterPage(){
      axios
        .get(`/material/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{item:`${afterFilter.item}`,division:`${afterFilter.division}`,category:`${afterFilter.category}`}})
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.total_records)
  
        }).catch(err=>console.log(err))
    }
  
  
    function ClearFilter(){
      formik.resetForm({})
      getTask(firstProject);
      setIsFilter(false)
    }
  
    useEffect(()=>{
     if(isFilter){
      FilterPage();
     }else if(searchQuery !=""){
        SearchPageDate();
      }else{
      getTask()
      }
    },[pageIndex,dataCount])
  
  
    return (
      <>
      <CostingLayout 
        setOpen={setOpen}
        setSearchQuery={setSearchQuery}
        SearchData={SearchData}
        isFilter={isFilter}
        setFilterOpen={setFilterOpen}
        ClearFilter={ClearFilter}
        CreateAccess={GetAccess()}
        ImportAccess={GetAccess()}
        officeId={officeId}
        projectId={projectId}
          >
                      <div className=" w-full h-[calc(100vh-180px)] rounded-md overflow-y-auto">
              <table
                className="table-auto w-full px-3"
                style={{ border: "none", borderCollapse: "collapse" }}
              >
                <thead className=" w-full bg-white p-3 mt-4">
                  <tr className="text-xs border-b  font-medium  py-3">
                    {header.map((header, i) => (
                      <th
                        className={`py-3 ${header.width} ${header?.isLeft?"text-left":"text-center"} font-medium text-xs 2xl:text-sm text-[#4F4768]`}
                        key={i}
                        style={{ border: "none", borderCollapse: "collapse" }}
                      >
                        {header.headone}
                      </th>
                    ))}
                  </tr>
                </thead> 
                <tbody className="">
                  {data && data.map((item, i) => (
                                      <tr className=" w-full " key={i}>
                                      <td className="py-2  text-xs text-center font-normal text-mainColor-400">{item?.serial_number||"#"}</td>
                                      {/* <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                                        <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/costing/material/view/${id}`} state={{data:item}}>{item?.projectName||""}</Link>
                                        </td> */}
                                      <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                                      <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/project/costing/material/${officeId}/${projectId}/view/${item?.materialId}`} state={{data:item}}>{item?.item||"-"}</Link>
                                      </td>
                                      <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                                        {item?.unit||"-"}
                                      </td>
                                      <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                                      {/* <NuAmount value={item?.quantity||0} /> */}
                                      {item?.quantity||0}
                                        {/* {item?.today||"0.0"} */}
                                      </td>
                                      <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                                      {/* <NuAmount value={item?.division||0} /> */}
                                      {item?.division||"-"}
                                        {/* {item?.previous||"0.0"} */}
                                      </td>
                                      <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                                      <NuAmount value={item?.amount||0} />
  
                                        {/* {item?.difference||"0.0"} */}
                                      </td>
                                      <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                                        <div className=" w-full flex justify-center items-center">
                                        <ViewMoreEuipment
                                          id={item?.materialId}
                                          getData={getTask}
                                          data={item}
                                         />
                                        </div>
                                      </td>
                                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        
        <div className=" h-6 my-2">
          <Pagination
            total={totalTasks}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            dataCount={dataCount}
            setDataCount={setDataCount}
          />
        </div>
      </CostingLayout>
  
  
        <Modal
          open={filterOpen}
          title={"Filter My Tasks"}
          width={"50%"}
          className="my-modal"
          centered
          onCancel={()=>setFilterOpen(false)}
          footer={[]}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex flex-wrap">
            <NuSelect
                    label="Item"
                    options={activity||[]}
                    formik={formik}
                    placeholder="Choose"
                    name="item"
                    width="w-1/2"
                    isRequired={true}
                  />
  
                  <NuSelect
                    label="Division"
                    options={Division||[]}
                    formik={formik}
                    placeholder="Choose"
                    name="division"
                    width="w-1/2"
                    isRequired={true}
                  />
  
                  <NuSelect
                    label="Category"
                    options={Category||[]}
                    formik={formik}
                    placeholder="Choose"
                    name="category"
                    width="w-1/2"
                    isRequired={true}
                  />
         
         <button
                 type="submit"
                // size="large"
                className="w-[95%] bg-[#119DEE] text-white  mx-auto py-1 border-none outline-none rounded-md"
              >
                Filter 
              </button>
            </div>
          </form>
        </Modal>
      </>
    );
}

export default Equipmentindex;
const header = [
    {
      headone: "ID",
      width: "w-[3%]",
      // isLeft: true,
    }, 
    // {
    //   headone: "Project",
    //   width: "w-[26%]",
    //   isLeft: true,
    // },
    {
      headone: "Item",
      width: "w-[51%]",
      isLeft: true,
  
    },
    {
      headone: "Unit",
      width: "w-[5%]",
      isLeft: true,
  
    },
    {
      headone: "Quantity",
      width: "w-[10%]",
      isLeft: true,
  
    },
    {
      headone: "Division",
      width: "w-[10%]",
      isLeft: true,
  
    },
    {
      headone: "Amount",
      width: "w-[10%]",
      isLeft: true,
  
    },
    {
      headone: "Action",
      width: "w-[5%]",
      // isLeft: true,
    },
  ];
  