// import { useEffect, useState } from "react"
// import { Link, useLocation, useParams } from "react-router-dom"
// import { GetAccess } from "../../../Components/Utils/roles"
// import Breadcrumb from "../../../Components/Utils/Breadcrumb"
// import SearchBar from "../../../Components/Utils/SearchBar"
// import { CreateButton, FilterButton, ImportButton } from "../../../Components/Utils/Buttons"

import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import Breadcrumb from "../../../../../Components/Utils/Breadcrumb"
import SearchBar from "../../../../../Components/Utils/SearchBar"
import { CreateButton, FilterButton, ImportButton } from "../../../../../Components/Utils/Buttons"
import { GetAccess } from "../../../../../Components/Utils/roles"

const SalesProjectLayout = (
    {
        getData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        onCreateClick,
        searchFun=()=>{},
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        searchValue="",
    }
) =>{
    let {pathname} = useLocation()
    let {officeId,projectId} = useParams()
    
    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/project/sales/customer/${officeId}/${projectId}`){
            setModule("Customer")
        }
        else if(pathname == `/project/sales/quote/${officeId}/${projectId}`){
            setModule("Quote")
        }else if(pathname == `/project/sales/sales-order/${officeId}/${projectId}`){
            setModule("Sales Order")
        }
        else if(pathname == `/project/sales/invoice_incoming/${officeId}/${projectId}`){
            setModule("Invoices Incoming")
        }else if(pathname == `/project/sales/invoice_outgoing/${officeId}/${projectId}`){
            setModule("Invoices Outgoing")
        }
    },[pathname])

    let LinkItem = [
        // {
        //     label:"Customer",
        //     link:`/project/sales/customer/${officeId}/${projectId}`,
        //     access:GetAccess(),
        // },
        // {
        //     label:"Quote",
        //     link:`/project/sales/quote/${officeId}/${projectId}`,
        //     access:GetAccess(),
        // },
        // {
        //     label:"Sales Order",
        //     link:`/project/sales/sales-order/${officeId}/${projectId}`,
        //     access:true,
        // },
        {
            label:"Invoices Incoming",
            link:`/project/sales/invoice_incoming/${officeId}/${projectId}`,
            access:GetAccess(),
        },
       
        {
            label:"Invoices Outgoing",
            link:`/project/sales/invoice_outgoing/${officeId}/${projectId}`,
            access:true,
        },
       
       
          ]
    
    return(
        <div className=" w-full  h-full pt-2 px-4 overflow-hidden">
            <div className="">
            <div className="md:flex justify-between w-full ">
            {/* <Breadcrumb items={[
               
                {
                    name:"Sales"
                },
                { name: `${module}` },

            ]} /> */}

            <div className=" w-full  flex items-center gap-x-1 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                                    // <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                                <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>

                                     )
                         }
                        </>
                    )
                })
            }
        </div>
           

            <div className=" flex justify-end items-center gap-3 ">
            <form className="flex items-center gap-3 mx-0" onSubmit={getData}>
            <SearchBar handleChange={searchFun}  value={searchValue}/>

            </form>
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <FilterButton label='Clear Filter'  handleClick={ClearFilter} />} 
               {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}

              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
            
            <div className=" w-full h-[calc(100vh-140px)]  ">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default SalesProjectLayout;