import React from "react";

function HeadCount({ data , totalCount = 0 }) {
  return (
    <div
      className="w-full h-[45vh] p-2 rounded-lg bg-white"
      style={{ border: "1px solid #E9E8EC" }}
    >
      <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px]">
        Head Count : {data.length||0}
      </p>
      <div className=" w-full overflow-y-scroll h-[calc(45vh-40px)]">
        <table className="overflow-hidden text-center  text-xs border-collapse w-full overflow-y-scroll">
          <thead className="border-b  rounded-md overflow-hidden font-medium  text-xs">
            <tr
              className=""
              style={{
                borderTop: "1px solid #E9E8EC ",
                borderBottom: "1px solid #E9E8EC ",
              }}
            >
              <th
                scope="col"
                className="sticky py-2 w-[70%] font-semibold text-[#6C6580] text-xs text-start pl-2 "
              >
                Job Role
              </th>
              <th
                scope="col"
                className="sticky py-2 w-[30%] font-semibold text-[#6C6580] text-left text-xs"
              >
                Count
              </th>
              {/* <th
                scope="col"
                className="sticky py-2 w-[55%] font-semibold text-[#6C6580]  text-left text-xs"
              >
                Employees
              </th> */}
            </tr>
          </thead>
          <tbody className=" px-2 ">
            {data &&
              data.map((Item, Index) => {
                console.log("vvvv",Item?.designation);
                return (
                  <tr
                    key={Index}
                    className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-10"
                    style={{ borderBottom: "1px solid #E9E8EC " }}
                  >
                    <td className="pl-2 text-left text-[#4F4768] l w-[70%] h-full  text-xs">
                      <div className=" w-full h-full items-center ine-clamp-2">
                        {Item?.designation || "-"}
                      </div>
                    </td>
                    <td className=" text-[#4F4768] w-[30%] text-xs text-left">{Item?.count}</td>
                    {/* <td className=" text-[#4F4768] w-[55%] text-xs "></td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HeadCount;
