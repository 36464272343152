import { Modal, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInput, NuSelect } from '../../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../../Components/Utils/Select';

const CreateTeam = ({ open, getData, onCancel, data, getCountData, projectId }) => {
    console.log("data",data,projectId);
    const [api, contextHolder] = notification.useNotification();
    // const [projectOpt,setProject] = useState();
    const [projects, setProjects] = useState([])
    const [memberList, setMemberList] = useState([])
    const [createNew, setCreateNew] = useState({
      teamName: "",
      leadPerson: "",
      projectId: ""
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema:yup.object({
        teamName:yup.string().required("Enter Team Name"),
        leadPerson:yup.string().required("Please Select Lead Person"),
        projectId:yup.string().required("Please Select Project")
      }),
      onSubmit: (value,{resetForm}) => {
  
        data?
        axiosInstance
        .put(`/Team/${data?.teamId}`, value)
        .then((res) => {
          if (res.status === 201 || res.data.status == true) {
            setCreateNew({ memberList: [] });
            getData();
            getCountData();
            //   getCount();
            resetForm()
            console.log('countData', res?.data);
  
            api.success({
              message: `Team Updated successfully`,
  
              description: res?.data?.msg || "Added In The Team",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Team Updation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Team Updation failed`,
            description: err?.response?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        })
        :
        axiosInstance
          .post("/Team/", value)
          .then((res) => {
            console.log("name", res);
  
            if (res.status === 201 || res.data.status == true) {
              setCreateNew({ memberList: [] });
              getData();
              getCountData();
              //   getCount();
              resetForm()
              console.log('countData', res?.data);
  
              api.success({
                message: `Team Creation successfully`,
  
                description: res?.data?.msg || "Added In The Team",
                placement: "bottomRight",
              });
              onCancel();
            } else {
              api.error({
                message: `Team Creation failed`,
                description: res?.data?.msg || "Network error",
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            api.error({
              message: `Team Creation failed`,
              description: err?.response?.data?.msg || "Sever error (or) Network error",
              placement: "bottomRight",
            });
          });
      },
    });
  
    useEffect(() => {
      axiosInstance.get(`/Team/getUserProjectId`).then((res) => {
        let list = res?.data?.data || [];
        if (list.length > 0) {
          const defaultProject = list[0]; // Automatically select the first project
          setProjects([{ value: defaultProject.ProjectId, label: defaultProject.ProjectName }]);
          setCreateNew((prev) => ({
            ...prev,
            projectId: defaultProject.ProjectId,
          }));
          getMemberList(defaultProject.ProjectId);
        }
      });
    }, []);
  
    useEffect(() => {
      if(formik.values.projectId !=""){
        getMemberList(formik.values.projectId)
      }
    },[formik.values.projectId])
  
  
    useEffect(()=>{
      data && setCreateNew({
        projectId:data?.projectId,
        leadPerson:data?.leadPerson[0]?.userId,
        teamName:data?.teamName,
      })
    },[data])
  
  
  
    async function getMemberList(projectId){
      if(projectId){
        await axiosInstance.get(`/DropDown/team_member/${projectId}`).then(res=>{
          console.log("test",res.data);
          let finalData = []
          let mapData = res?.data?.data||[]
          res?.data?.data && mapData.forEach(item=>{
            let innerData = {
              value:item.userId,
              label:item.Name
            }
            finalData.push(innerData)
          })
          setMemberList(finalData)
        }).catch(err=>{
          console.log("err",err);
        })
      }
    }
  
    return (
      <>
        {contextHolder}
        <Modal
          open={open}
          title={data ? "Edit Team" : "Create Team"}
          width={"25%"}
          className="my-modal"
          centered
          onCancel={onCancel}
          footer={[]}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex flex-wrap">
            <FormikSelectTwo
              label="Project"
              placeholder="Project"
              formik={formik}
              name="projectId"
              width="w-full"
              Options={projects}
              disabled 
              isRequired={true}
            />
              <NuInput  
               type="text"              
                label="Team Name"
                placeholder="Team Name"
                formik={formik}
                name="teamName"
                width="w-full"
                isRequired={true}
  
              />
              <FormikSelectTwo
                label="Lead"
                placeholder="Select Lead"
                formik={formik}
                name="leadPerson"
                width="w-full"
                Options={memberList}
                isRequired={true}
  
              />
              <button
                type="submit"
                // htmlType="submit"
                // size="large"
                className="w-11/12 bg-baseColor-primary py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
              >
                {data?"Update":"Create"} 
              </button>
            </div>
          </form>
        </Modal>
      </>
    );
  };

export default CreateTeam