import React from "react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../../../Components/Utils/Buttons";
// import Attachement from "./Attachement";
import Dasboard from "../../../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import { notification } from "antd";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { NuInput, NuLabel, NuSelect, NuTextArea } from "../../../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";



export default function CreateManPower({ data }) {
  const {projectId,officeId} = useParams();
  // const {value:Designation} = useFetchMataData(`/Metadata/clientPosition`)
  // const {value:Category} = useFetchMataData(`/Metadata/category`)
  const {value:Category ,getData:refManPowerCategory} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerCategory')
  const {value:Designation ,getData:refManPowerDesignation} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerDesignation')
  const {value:Type ,getData:refManPowerType} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerType')

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [projectList,setProjectList] =useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    projectId:"",
    designation:"",
    category:"",
    type:"",
    quantity:"",
    noOfDays:"",
    hours:"",
    comments:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
      designation: yup.string().required("Designation is Required"),
      quantity: yup.string().required("Quantity is Required"),
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      value.projectId = projectId;
      data
        ? axios
            .put(`/man_power_cost/${data?.id}`, value)
            .then((res) => {
              if (res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/man_power_cost/", value)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });


  // async function getProject() {
  //   await axios
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {

  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       (await res?.data?.data) &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);

  //       // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
  //       // console.log('====',res?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // useEffect(() => {
  //   getProject()
  // },[])

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
      <Breadcrumb items={[
               
                {
                    name:"Manpower",
                    link:`/project/costing/man-power/${officeId}/${projectId}`
                },
                {
                  name:"Create"
              },
            ]} />


        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[85%] bg-white rounded-[6px] mt-2"
          // style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Manpower" />
              {/* <BackButton /> */}
            </div>
            <div className="flex flex-wrap justify-start">

                {/* <NuSelect
                  label="Project"
                  options={projectList||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="projectId"
                  width="w-full md:w-1/3"
                  isRequired={true}
                /> */}
                <FormikSelectTwo
                  label="Designation"
                  Options={Designation||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="designation"
                  width="w-full md:w-1/3"
                  getFunc={refManPowerDesignation}
                displayProperty={"Designation"}
                propertyName={"ManPowerDesignation"}
                  isRequired={true}
                />
                <FormikSelectTwo
                  label="Category"
                  Options={Category}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full md:w-1/3"
                  getFunc={refManPowerCategory}
                displayProperty={"Category"}
                propertyName={"ManPowerCategory"}
                  isRequired={true}
                />
                <FormikSelectTwo
                  label="Type"
                  Options={Type}
                  formik={formik}
                  placeholder="Choose"
                  name="type"
                  width="w-full md:w-1/3"
                  getFunc={refManPowerType}
                displayProperty={"Type"}
                propertyName={"ManPowerType"}
                  isRequired={true}
                />
                <NuInput
                             type="number"

                 label="No. of Workers"
                 formik={formik}
                 placeholder="No. of Workers"
                 name="quantity"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />
                <NuInput
                             type="number"

                 label="No. Of Days"
                 formik={formik}
                 placeholder="No. Of Days"
                 name="noOfDays"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />      
                <NuInput
                             type="number"

                 label="Total Hours"
                 formik={formik}
                 placeholder="Total Hours"
                 name="hours"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />  
                <NuTextArea
                  label="Comments"
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full md:w-2/3"
                />



            </div>
            <div className="flex justify-between items-center">
              <div className="w-1/2">

              </div>

              <div
                className=" mr-2 rounded-md"
                style={{ border: "1px solid #C5C5C5" }}
              >

              </div>
              <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
            </div>

           
          </form>
         
        </div>
        
      </div>
    </div>
  );
}
