import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function CategoryManPower() {
    return (
        <SettingsMetaData propertyName={"ManPowerCategory"} viewPropertyName={"Category"} />
      );
}

export default CategoryManPower
