import React, { useEffect, useState } from 'react';
import DataTable from '../../../../Components/Others/DataTable';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import MoreOptions from '../../../../Components/Utils/MoreOptions';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import * as yup from 'yup';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { NuDate } from '../../../../Components/Utils/Input';
import Importdoc from './Importdoc';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import MultiEditDN from './MultiEditDN';
import { GetAccess } from '../../../../Components/Utils/roles';
import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';
import StoreLayout from '../StoreLayout';

function DeliveryNotesIndex() {
  const {officeId} =useParams();

  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const {value:DelivaryNoteDelivaryStatus} = useFetchMDIndex(`/dropdown/dropdown-details/`,'DelivaryNoteDelivaryStatus',showFilterDialog)
    const {value:DeliveryVehicle} = useFetchMDIndex(`/dropdown/dropdown-details/`,'DeliveryVehicle',showFilterDialog)
  
    const [filterProps, setFilterProps] = useState({
      deliveryStatus:"",
      deliveryVechicleType:"",
      deliveredOnFrom:"",
      deliveredOnTo:"",
      dateFrom:"",
      dateTo:"",
    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:searchQuery||"",

      deliveryStatus:"",
      deliveryVechicleType:"",
      deliveredOnFrom:"",
      deliveredOnTo:"",
      dateFrom:"",
      dateTo:"",
    });
  
    const clearFilter = ()=> {
      setParams({...params,deliveryStatus:"",
        deliveryVechicleType:"",
        deliveredOnFrom:"",
        deliveredOnTo:"",
        dateFrom:"",
        dateTo:"",})
        setFilter(false)
        formik.values.deliveryStatus=""
        formik.values.deliveryVechicleType=""
        formik.values.deliveredOnFrom=""
        formik.values.deliveredOnTo=""
        formik.values.dateFrom=""
        formik.values.dateTo=""
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,deliveryStatus:value.deliveryStatus, deliveryVechicleType:value.deliveryVechicleType,deliveredOnFrom:value.deliveredOnFrom,deliveredOnTo:value.deliveredOnTo,dateFrom:value.dateFrom,dateTo:value.dateTo})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          deliveryStatus:"",
          deliveryVechicleType:"",
          deliveredOnFrom:"",
          deliveredOnTo:"",
          dateFrom:"",
          dateTo:"",
        });
      }
    })
  
    const { data, totalCount, getData,dataLoading} = UseGetDataSearch('/delivery-notes/', params);
  
    const defaultHeader = [
      // {
      //   title: "ID",
      //   width: "5%",
      //   field: "deliveryNoteId",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "DN No.",
        width: "10%",
        isLink: true,
        id: "deliveryNoteId",
        link: `/main/${officeId}/store/delivery-note/view/`,
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "YPR",
        width: "10%",
        isLink: true,
        id: "deliveryNoteId",
        link: `/main/${officeId}/store/delivery-note/view/`,
        field: "prRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Customer",
        width: "10%",
        field: "customerName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "SO No.",
        width: "10%",
        field: "soRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Delivery Date",
        width: "10%",
        type:"date",
        field: "deliveredOn",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Status",
        width: "10%",
        field: "deliveryStatus",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
             contents:{
          // fetcher:getData,
          id:"deliveryNoteId",
          removeValue:"idNumber",
          url:`/delivery-notes/`,
          moduleName:'Delivery Note',
          from:`/main/${officeId}/store/delivery-note`,
          editLink:`/main/${officeId}/store/delivery-note/edit/`,
          viewLink:`/main/${officeId}/store/delivery-note/view/`,       
        }
      },
    ];
const availableHeaders=[
  // {
  //   title: "ID",
  //   width: "5%",
  //   field: "deliveryNoteId",
  //   textAlign: "center",
  //   rowAlign: "center",
  // },
  {
    title: "DN No.",
    width: "10%",
    isLink: true,
    id: "deliveryNoteId",
    link: `/main/${officeId}/store/delivery-note/view/`,
    field: "ref",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "YPR",
    width: "10%",
    isLink: true,
    id: "deliveryNoteId",
    link: `/main/${officeId}/store/delivery-note/view/`,
    field: "prRef",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Customer",
    width: "10%",
    field: "customerName",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "SO No.",
    width: "10%",
    field: "soRef",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Delivery Date",
    width: "10%",
    type:"date",
    field: "deliveredOn",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Status",
    width: "10%",
    field: "deliveryStatus",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Id Number",
    width: "10%",
    field: "idNumber",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Days Elapsed",
    width: "10%",
    field: "daysElapsed",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Date",
    width: "10%",
    field: "date",
    type:"date",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Delivery Person ",
    width: "10%",
    field: "deliveryPersonName",
    textAlign: "center",
    rowAlign: "center",
  },
 
   {
    title: "Mobile",
    width: "10%",
    field: "mobile.display",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Delivery Vechicle ",
    width: "10%",
    field: "deliveryVechicleType",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Id Type",
    width: "10%",
    field: "idType",
    textAlign: "center",
    rowAlign: "center",
  },
   {
    title: "WayBill No",
    width: "5%",
    field: "wayBillNo",
    textAlign: "center",
    rowAlign: "center",
  },
  {
    title: "Action",
    width: "5%",
    field: "action",
    textAlign: "center",
    rowAlign: "center",
         contents:{
      // fetcher:getData,
      id:"deliveryNoteId",
      removeValue:"idNumber",
      url:`/delivery-notes/`,
      moduleName:'Delivery Note',
      from:`/main/${officeId}/store/delivery-note`,
      editLink:`/main/${officeId}/store/delivery-note/edit/`,
      viewLink:`/main/${officeId}/store/delivery-note/view/`,     
    }
  },
]
useEffect(()=>{
  setParams({...params,page:page,per_page:pageCount})
 },[page,pageCount])
        const closeFilterDialog = () => {
          setShowFilterDialog(false);
        };

        const [showImportModal, setShowImportModal] = useState(false);

        const handleImportClick = () => {
          setShowImportModal(true);
        };
      
        const closeModal = () => {
          setShowImportModal(false);
        };

        const [showEdit, setShowEdit] = useState(false)
        const [showDelete, setShowDelete] = useState(false)
        const [listofNumbers, setListofNumbers] = useState([])
        const [fullList, setFullList] = useState([])
      
        const selectOneFunction = (value) =>{ 
          let NewId;
          if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
            NewId = listofNumbers.filter(Temp=> Temp !== value);
            setListofNumbers(NewId)
          }else{
            setListofNumbers([...listofNumbers,value])
          }
        }
      
        const selectMultipleFunction = (e) =>{ 
      console.log(e.target.checked);
      if(e.target.checked){
        setListofNumbers(fullList)
      }else{
        setListofNumbers([])
      }
      
        }
      
        useEffect(()=>{
          let tempArr = []
          data && data.forEach(i=>{
            console.log("I",i?.deliveryNoteId);
            tempArr.push(i?.deliveryNoteId)
          });
          setFullList(tempArr)
        },[data])
    
        const [valueOfSearch,setValuesofSearch] = useState("")
   function searchFunction(e) {
 
     
     setValuesofSearch(e?.target?.value)
     setParams({ ...params, search: e?.target?.value, page:1 });
     setPage(1)
   }
  
  return (
    <>
    <StoreLayout 
    //  setOpen={closeModal}
    searchFun={searchFunction}
    searchValue={valueOfSearch}
     getData={getData}
     isFilter={filter}
       setFilterOpen={setShowFilterDialog}
       ClearFilter={clearFilter}
       CreateAccess={GetAccess("delivery_notes_create")}
      //  ImportAccess={GetAccess("delivery_notes_create")}
       officeId={officeId}
       // projectId={projectId}
         >
      <div className='w-full h-full'>
      {/* <div className='w-full flex justify-between items-center '>
      <Breadcrumb 
        items={[
          { name: "Store" },
      
          { name: "Delivery Note",
           },        
         ]} />
      <div className=' flex gap-2'>
      <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />     <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}        */}
       {/* <CreateButton label='Import' handleClick={handleImportClick} /> */}
       {/* {GetAccess("delivery_notes_create") &&  <Link to={`/main/${officeId}/store/delivery-note/create`}><CreateButton label='Delivery Note'/></Link> }
      </div>
      </div> */}
  
      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-160px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Delivery-Note List'
        storageName="DeliveryNoteTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById='deliveryNoteId'
        editAccess="delivery_notes_edit"      
        deteleAccess="delivery_notes_delete" 
        dataLoading={dataLoading}
        searchQuery={params?.search!=""?params?.search:""}

        />
     </div>
     </StoreLayout>
     {showFilterDialog && (           
              <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Delivery Note</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Delivered On</p>
                    <div className="flex justify-between  w-full ">
             <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deliveredOnFrom"
              width="w-full "
            />
           <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deliveredOnTo"
              width="w-full "
            />
            </div>
            <p className="py-2 px-6 text-m"> Date</p>
                    <div className="flex justify-between  w-full ">
             <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dateFrom"
              width="w-full "
            />
           <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dateTo"
              width="w-full "
            />
            </div>
                    <FormikSelectTwo
              type="text"
              label="Delivery Vehicle Type"
              placeholder="Delivery Vehicle Type"
              Options={DeliveryVehicle}
              formik={formik}
              name="deliveryVehicleType"
              width="w-full "
            />
             <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={DelivaryNoteDelivaryStatus}
              formik={formik}
              name="deliveryStatus"
              width="w-full"
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )} 
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
        {showEdit && (<MultiEditDN data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from YPR List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </>
    )
  }

export default DeliveryNotesIndex