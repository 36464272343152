const logout = () => {
    window.localStorage.removeItem("nEkOtReSuEdArT");
    window.localStorage.removeItem("sLiAtEdReSuEdArT");
    window.localStorage.removeItem("sSeCcArEsUeDaRt");
    if(window.localStorage.getItem("EquipmentTable")){
      window.localStorage.removeItem("EquipmentTable");
    }
    if(window.localStorage.getItem("VehicleTable")){
      window.localStorage.removeItem("VehicleTable")
    }
    if(window.localStorage.getItem("AppoinmentTable")){
      window.localStorage.removeItem("AppoinmentTable")
    }
    if(window.localStorage.getItem("LeadsTable")){
      window.localStorage.removeItem("LeadsTable")
    }
    if(window.localStorage.getItem("PayableTable")){
      window.localStorage.removeItem("PayableTable")
    }
    if(window.localStorage.getItem("ReceivableTable")){
      window.localStorage.removeItem("ReceivableTable")
    }
    if(window.localStorage.getItem("RefundTable")){
      window.localStorage.removeItem("RefundTable")
    }
    if(window.localStorage.getItem("ContractTable")){
      window.localStorage.removeItem("ContractTable")
    }
    if(window.localStorage.getItem("COTable")){
      window.localStorage.removeItem("COTable")
    }
    if(window.localStorage.getItem("POTable")){
      window.localStorage.removeItem("POTable")
    }
    if(window.localStorage.getItem("PrTable")){
      window.localStorage.removeItem("PrTable")
    }
    if(window.localStorage.getItem("RfqTable")){
      window.localStorage.removeItem("RfqTable")
    }
    if(window.localStorage.getItem("SupplierQuotesTable")){
      window.localStorage.removeItem("SupplierQuotesTable")
    }
    if(window.localStorage.getItem("SupplierTable")){
      window.localStorage.removeItem("SupplierTable")
    }
    if(window.localStorage.getItem("ItemsTable")){
      window.localStorage.removeItem("ItemsTable")
    }
    if(window.localStorage.getItem("CustomerQuoteTable")){
      window.localStorage.removeItem("CustomerQuoteTable")
    }
    if(window.localStorage.getItem("CustomersTable")){
      window.localStorage.removeItem("CustomersTable")
    }
    if(window.localStorage.getItem("Invoice-IncomingTable")){
      window.localStorage.removeItem("Invoice-IncomingTable")
    }
    if(window.localStorage.getItem("Invoice-OutgoingTable")){
      window.localStorage.removeItem("Invoice-OutgoingTable")
    }
    if(window.localStorage.getItem("PromotionTable")){
      window.localStorage.removeItem("PromotionTable")
    }
    if(window.localStorage.getItem("SalesOrdersTable")){
      window.localStorage.removeItem("SalesOrdersTable")
    }
    if(window.localStorage.getItem("AgentsTable")){
      window.localStorage.removeItem("AgentsTable")
    }
    if(window.localStorage.getItem("ShipmentTrackingTable")){
      window.localStorage.removeItem("ShipmentTrackingTable")
    }
    if(window.localStorage.getItem("AssetTable")){
      window.localStorage.removeItem("AssetTable")
    }
    if(window.localStorage.getItem("DeliveryNoteTable")){
      window.localStorage.removeItem("DeliveryNoteTable")
    }
    if(window.localStorage.getItem("GrnTable")){
      window.localStorage.removeItem("GrnTable")
    }
    if(window.localStorage.getItem("InventoryTable")){
      window.localStorage.removeItem("InventoryTable")
    }
    if(window.localStorage.getItem("PackingListTable")){
      window.localStorage.removeItem("PackingListTable")
    }
    if(window.localStorage.getItem("RequestTable")){
      window.localStorage.removeItem("RequestTable")
    }
    if(window.localStorage.getItem("ReturnTable")){
      window.localStorage.removeItem("ReturnTable")
    }
    if(window.localStorage.getItem("PaymentRequestTable")){
      window.localStorage.removeItem("PaymentRequestTable")
    }
    
    window.location.replace("/auth/login");
  };
  
  export default logout;
  