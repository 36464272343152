import { Avatar, Popover, Tooltip } from 'antd';
// import Link from 'antd/es/typography/Link';
import React from 'react';
import { NavLink } from 'react-router-dom';

function NuAvatarGroupWithLink({ userdetails,link=false }) {
  console.log("userDetail",userdetails);
  return (
    <>
      <Avatar.Group
        maxCount={10}
        // maxPopoverTrigger='click'
        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer',textTransform: "capitalize" }}
      >
        {userdetails &&
          userdetails.map((item,index) => {
            return (
            <Popover  
            content={<>
              <div className=' w-full flex items-center gap-3'>
              <Avatar key={index} src={item.imageUrl||item.image||'#'} style={{ backgroundColor: '#2A475E',fontSize:'12px',fontWeight:600 }}>
                {item?.firstName?.charAt(0)||item?.name.charAt(0)||item?.userId}
                {/* {item?.userId} */}
              </Avatar>
              <p>{item?.firstName||item?.name||item?.userId}</p>
              </div>
            </>}             
            placement="top"
            // title="Detail"
            >
            <NavLink to={link?`/members/profile/${item.userId}`:"#"}>
              <Avatar key={index} src={item.imageUrl||item.image||'#'} style={{ backgroundColor: '#2A475E',fontSize:'12px',fontWeight:600 }}>
                {item?.firstName?.charAt(0)||item?.name.charAt(0)||item?.userId}
                {/* {item?.userId} */}
              </Avatar>
              </NavLink>
              </Popover>
            );
          })}
      </Avatar.Group>
    </>
  );
}

export default NuAvatarGroupWithLink;
