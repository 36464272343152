// import React, { useEffect, useMemo, useState } from "react";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
// import {
//   NuDate,
//   NuInput,
//   NuTextArea,
// } from "../../../../Components/Utils/Input";
// import FormikSelectTwo, {
//   FormikSelectWithAdd,
//   FormikSelectWithoutAdd,
// } from "../../../../Components/Utils/Select";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import { useFormik } from "formik";
// import { useLocation, useParams } from "react-router-dom";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import UseEditData from "../../../../Components/Hooks/useEditData";
// import * as yup from "yup";
// import Delete from "../../../../Components/Icons/Delete";
// import axiosInstance from "../../../../Services/axiosCommon";
// import useQuotProducts from "./Hooks/useMemo";
// import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import { TextMediumBase } from "../../../../Components/Text/MediumText";
// import NuAmount from "../../../../Components/Utils/NuAmount";
// import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
// import { CountryMIN } from "../../../../assets/CountryMini";
// import UseCustomerDropDownNew from "../../../../Components/Hooks/useCustomerSearch";

import { useLocation, useParams } from "react-router-dom";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import { useEffect, useMemo, useState } from "react";
import UseCustomerDropDownNew from "../../../../../../Components/Hooks/useCustomerSearch";
import UseDropDown from "../../../../../../Components/Hooks/useDropDown";
import { useFormik } from "formik";
import * as yup from "yup";
import useQuotProducts from "./Hooks/useMemo";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import { NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
import FormikSelectTwo, { FormikSelectWithoutAdd } from "../../../../../../Components/Utils/Select";
import NuDate from "../../../../../../Components/Utils/NuDate";
import DropDownAddMetaData from "../../../../../../Components/Utils/selectAddMetaData";
import { CountryMIN } from "../../../../../../assets/CountryMini";
import DeleteIcon from "../../../../../../Components/Icons/Delete";
import { TextMediumBase } from "../../../../../../Components/Text/MediumText";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import UseEditData from "../../../../../../Components/Hooks/useEditData";

function EditPo() {
  const {officeId,projectId} =useParams();

  const { value: paymentStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "paymentStatus_po"
  );
  const { value: Status, getData: refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_po"
  );
  const [searchAgent, setSearchAgent] = useState("");
  const { data: Agents, getData: getAgents } = UseCustomerDropDownNew("/agents/drop-down",searchAgent);
  const { value: ShippingMethod, getData: refShippingMethod } =
    useFetchMataData(`/dropdown/dropdown-details/`, "ShippingMethod");
  const { value: DeliveryTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerms_Supplierqoutes"
  );
  const { data: PR } = UseDropDown("/PR/drop-down");
  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  // console.log(data,"gg");
  const from = location.state.from;
  const [dataa, setData] = useState(data);
  // console.log(dataa,"hhh");
  const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);
  const [newItemData, setNewItemData] = useState({
    productId: "",
    requestedQty: "",
    orderedQty: "",
    quoteProductId: "",
    product_name: "",
    price: "",
    quantity: "",
    negotiatedPrice: "",
  });
  const [createNew, setCreateNew] = useState({
    prId: data.prId,
    prRrf: data.prRrf,

    refNumber: data.ref,
    date: data.date,
    vendorId: data.vendorId,
    totalPrice: data.totalPrice,
    vendorName: data.vendorName,
    paymentStatus: data.paymentStatus,
    comments: data.comments,
    requestedDeliveryDate: data.requestedDeliveryDate,
    requestedBy: data.requestedBy,
    approvedBy: data.approvedBy,
    products: data.products,
    quoteProductId: data.quoteProductId,
    deliveryAddress: data.deliveryAddress,
    billingAddress: data.billingAddress,
    productId: data.productId,
    requestedQty: data.requestedQty,
    status: data.status,
    deliveryTerms: data.deliveryTerms,
    paymentTerms: data.paymentTerms,
    sqId: data?.sqId,
    bookingEnabled: data?.shipmentDetails ? true : false,
    bookingDate: data?.shipmentDetails ? data?.shipmentDetails.bookingDate : "",
    trackingUrl: data?.shipmentDetails ? data?.shipmentDetails.trackingUrl : "",
    shipmentBill: data?.shipmentDetails
      ? data.shipmentDetails.shipmentBill
      : "",
    ETD: data?.shipmentDetails ? data?.shipmentDetails.ETD : "",
    ETA: data?.shipmentDetails ? data?.shipmentDetails.ETA : "",
    permitReq: data?.shipmentDetails ? data?.shipmentDetails.permitReq : "",
    agent: data?.shipmentDetails ? data?.shipmentDetails.agent : "",
    modeOfShipment: data?.shipmentDetails
      ? data?.shipmentDetails.modeOfShipment
      : "",
    ESC: data?.shipmentDetails ? data?.shipmentDetails.ESC : "",
    ASC: data?.shipmentDetails ? data?.shipmentDetails.ASC : "",
    billingAddressLine1: data?.billingAddressLine1 || "",
    billingAddressLine2: data?.billingAddressLine2 || "",
    billing_city: data?.billing_city || "",
    billing_country: data?.billing_country || "",
    billing_postal_code: data?.billing_postal_code || "",
    billing_state: data?.billing_state || "",
    deliveryAddressLine1: data?.deliveryAddressLine1 || "",
    deliveryAddressLine2: data?.deliveryAddressLine2 || "",
    delivery_city: data?.delivery_city || "",
    delivery_country: data?.delivery_country || "",
    delivery_postal_code: data?.delivery_postal_code || "",
    delivery_state: data?.delivery_state || "",
  });

  const handleDelete = (productId, list) => {
    const updatedProducts = dataa?.products.filter(
      (item) => item.productId !== productId
    );
    const deletedProduct = dataa?.products?.find(
      (item) => item.productId === productId
    );
    if (
      deletedProduct &&
      deletedProduct.negotiatedPrice &&
      deletedProduct.orderedQty
    ) {
      const priceToRemove = deletedProduct.negotiatedPrice || 0;
      const quantityToRemove = deletedProduct.orderedQty || 0;
      const reduction = priceToRemove * quantityToRemove;
      const newTotalPrice =
        updatedProducts.length > 0 ? formik.values.totalPrice - reduction : 0;
      setData((prevState) => ({
        ...prevState,
        products: updatedProducts,
        totalPrice: newTotalPrice,
      }));
      formik.setFieldValue("totalPrice", newTotalPrice);
      setRemove([...remove, productId]);
    }
  };

  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("YPR is required!"),
      sqId: yup.string().required("Supplier Quotes is required!"),
      vendorId: yup.string().required("Supplier is required!"),
    }),

    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (dataa.products.length === 0) {
        setError("Product table is empty");
        return;
      }
      const valueWithItems = {
        ...value,
        products: dataa.products,
        removedProducts: remove,
      };
      editData(`/PO/edit-PO/${data.poId}`, valueWithItems, resetForm, from);
      // setLoading(false)
    },
  });

  const quotProducts = useQuotProducts(
    formik.values.prId,
    formik.values.vendorId
  );

  console.log(quotProducts, "quotes");

  useMemo(() => {
    if (formik.values.quoteProductId !== "") {
      let tem_product = quotProducts
        ? quotProducts?.find(
            (i) => i.quoteProductId === formik.values.quoteProductId
          )
        : [];
      formik.values.price = tem_product && tem_product.price;
      formik.values.negotiatedPrice =
        tem_product && tem_product.negotiatedPrice;

      formik.values.productId = tem_product && tem_product.productId;
      formik.values.requestedQty = tem_product && tem_product.requestedQty;
      formik.values.product_name = tem_product && tem_product.productName;
      formik.values.orderedQty = tem_product && tem_product.orderedQty;

      console.log(tem_product);
    }
  }, [formik.values.quoteProductId]);

  const [vendors, setVendors] = useState([]);

  function getVendors(num) {
    axiosInstance
      .get(`/supplier-quotations/quote/drop-down/${num}`)
      .then((response) => {
        let tempData = [];
        if (response.data) {
          let x = response.data;

          x.map((item) => {
            let y = {
              value: item.id,
              label: item.name,
            };
            tempData.push(y);
          });

          setVendors(tempData);
        }
      })
      .catch((error) => {});
  }

  useMemo(() => {
    if (formik.values.prId) {
      getVendors(formik.values.prId);
    }
  }, [formik.values.prId]);

  const [product, setProducts] = useState(dataa?.products);

  useEffect(() => {
    if (formik.values.sqId) {
      axiosInstance
        .get(
          `/supplier-quotations/selected-products/drop-down/${formik.values.prId}/${formik.values.vendorId}`
        )
        .then((res) => {
          console.log("rrr", res?.data?.data?.products);
          let data = res?.data?.data?.products;
          let tPrice = 0.0;
          for (let i = 0; i < data?.length; i++) {
            tPrice =
              parseFloat(tPrice) +
              parseFloat(data[i].negotiatedPrice) *
                parseFloat(data[i].orderedQty);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // Handle errors here
        });
    }
  }, [formik.values.sqId]);

  useEffect(() => {
    const totalPrice = product?.reduce((total, item) => {
      return (
        total +
        parseFloat(item.negotiatedPrice) * parseFloat(item.orderedQty || 0)
      );
    }, 0);
    console.log(totalPrice, "kkk");

    formik.setFieldValue("totalPrice", totalPrice);
  }, [product]);

  const handleQuantityChange = (index, neworderedQty) => {
    const updatedItems = [...product];
    updatedItems[index].orderedQty = neworderedQty;
    setProducts(updatedItems);
  };

  const handleIncrement = (index) => {
    handleQuantityChange(index, product[index].orderedQty + 1);
  };

  const handleDecrement = (index) => {
    handleQuantityChange(index, Math.max(0, product[index].orderedQty - 1));
  };

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    const sanitizedValue =
      value === "" || value === "-" ? value : Math.max(0, Number(value));
    handleQuantityChange(index, sanitizedValue);
  };

  useEffect(() => {
    if (dataa.products?.length > 0) {
      setError("");
    }
  }, [dataa.products]);

  const handlenegotiatedPriceChange = (e, index) => {
    const updatedItems = [...product];
    updatedItems[index].negotiatedPrice = e.target.value;
    setProducts(updatedItems);
  };
  let { data: suppliers } = UseDropDown("/vendors/drop-down");

  const [quotes, setQuotes] = useState(dataa?.products);

  const getQuotes = (prId, vendorId) => {
    if (prId !== "" && vendorId !== "") {
      axiosInstance
        .get(`/supplier-quotations/quotes/drop-down/${prId}/${vendorId}`)
        .then((res) => {
          const data = res.data;
          const rfqOptions = data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setQuotes(rfqOptions);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.prId && formik.values.vendorId) {
      getQuotes(formik.values.prId, formik.values.vendorId);
    }
  }, [formik.values.prId, formik.values.vendorId]);

  const [showPopup, setShowPopup] = useState(false);

  const [newProduct, setNewProduct] = useState({
    customerType: "",
    companyName: "",
    email: "",
    phone: "",
    countryCode: "",
    website: "",
    // industry: "",
  });

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleCheckboxChange = (e) => {
    formik.setFieldValue("bookingEnabled", e.target.checked);
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        {/* <Breadcrumb
          items={[
            // { name: "Procurement" },

            { name: "Purchase Order", 
              link: `/project/procurement/parchase-order/${officeId}/${projectId}` },

            { name: "Edit" },
          ]}
        /> */}
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto pb-4 bg-white rounded-md ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Purchase Order :
                </p>
              </div>{" "}
               <NuInput
                           label="PR"
                           type="text"
                           formik={formik}
                           placeholder="PR"
                           name="prRrf"
                           isRequired={true}
              
                           width="w-full md:w-1/2 lg:w-1/3"
                           disabled={true}
                         />
              {/* <FormikSelectTwo
                type="text"
                label="YPR "
                placeholder="YPR "
                Options={PR}
                formik={formik}
                name="prId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              /> */}
               <NuInput
                           label="Supplier"
                           type="text"
                           formik={formik}
                           placeholder="Supplier"
                           name="vendorName"
                           isRequired={true}
              
                           width="w-full md:w-1/2 lg:w-1/3"
                           disabled={true}
                         />
              {/* <FormikSelectTwo
                type="text"
                label="Supplier "
                placeholder="Supplier "
                Options={suppliers}
                formik={formik}
                name="vendorId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              /> */}
              {formik.values.vendorId && (
                <FormikSelectTwo
                  type="text"
                  label="Supplier Quote"
                  placeholder="Choose"
                  Options={quotes}
                  formik={formik}
                  name="sqId"
                  disabled={true}
                  width="w-full md:w-1/2 lg:w-1/3"
                  FixPosition={false}
                  PortalTarget={false}
                />
              )}
              <NuDate
                label="Requested Delivery Date"
                placeholder="DD/MM/YYYY"
                formik={formik}
                name="requestedDeliveryDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Delivery Terms"
                placeholder="Choose"
                Options={DeliveryTerms}
                formik={formik}
                name="deliveryTerms"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
              <FormikSelectTwo
                type="text"
                label="Payment Status"
                placeholder="Payment Status"
                Options={paymentStatus}
                formik={formik}
                name="paymentStatus"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
              <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refStatus}
                displayProperty={"Status"}
                propertyName={"Status_po"}
              />
              <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
                new_width="w-full md:w-1/2 lg:w-2/3"

              />
              <div className="w-full">
                <div className="flex items-center mt-4 ml-6  w-[30%] text-sm">
                  <input
                    type="checkbox"
                    id="bookingEnabled "
                    name="bookingEnabled "
                    checked={formik.values.bookingEnabled}
                    onChange={handleCheckboxChange}
                    className="mr-2 w-4 h-4"
                  />
                  <label htmlFor="bookingEnabled ">Booking</label>
                </div>
              </div>
            </div>
          </div>
          {formik.values.bookingEnabled && (
            <>
              <div className="w-full mx-auto pb-4 bg-white rounded-md  mt-4 ">
                <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
                  <div className="w-full">
                    <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                      Booking Details :
                    </p>
                  </div>
                  <NuDate
                    label="Booking Date"
                    placeholder="DD/MM/YYYY"
                    formik={formik}
                    name="bookingDate"
                    isRequired={true}
                    width="w-full md:w-1/2 lg:w-1/3"
                  />

                  <FormikSelectWithoutAdd
                    type="text"
                    label="Agent "
                    placeholder="Agent"
                    Options={Agents}
                    formik={formik}
                    name="agent"
                    isRequired={true}
                    width="w-full md:w-1/2 lg:w-1/3"
                    px="0"
                    handleClick={togglePopup}
                    FixPosition={false}
                    PortalTarget={false}
                    setSearch={setSearchAgent}
                  />

                  <DropDownAddMetaData
                    label="Mode Of Shipment"
                    placeholder="Choose"
                    Options={ShippingMethod}
                    formik={formik}
                    name="modeOfShipment"
                    width="w-full md:w-1/2 lg:w-1/3"
                    getFunc={refShippingMethod}
                    displayProperty={"Mode Of Shipment"}
                    propertyName={"ShippingMethod"}
                  />
                  <FormikSelectTwo
                    type="text"
                    label="Permit Required?"
                    placeholder="Permit Required?"
                    Options={[
                      { value: false, label: "No" },
                      { value: true, label: "Yes" },
                    ]}
                    formik={formik}
                    name="permitReq"
                    width="w-full md:w-1/2 lg:w-1/3"
                    FixPosition={false}
                    PortalTarget={false}
                  />
                  <NuDate
                    label="ETD"
                    placeholder="DD/MM/YYYY"
                    formik={formik}
                    name="ETD"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />

                  <NuDate
                    label="ETA"
                    placeholder="DD/MM/YYYY"
                    formik={formik}
                    name="ETA"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <NuInput
                    type="number"
                    label="Estimated Shipping Cost"
                    placeholder="Estimated Shipping Cost"
                    formik={formik}
                    name="ESC"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <NuInput
                    type="text"
                    label="Actual Shipping Cost"
                    placeholder="Actual Shipping Cost"
                    formik={formik}
                    name="ASC"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <NuInput
                    type="text"
                    label="Shipment Bill Number"
                    placeholder="Shipment Bill Number"
                    formik={formik}
                    name="shipmentBill"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <NuInput
                    type="text"
                    label="Tracking Url"
                    placeholder="Tracking Url"
                    formik={formik}
                    name="trackingUrl"
                    width="w-full md:w-1/2 lg:w-2/3"
                  />
                </div>
              </div>
            </>
          )}

          <div className="w-full mx-auto pb-4 bg-white rounded-md mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Billing Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="billingAddressLine1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="billingAddressLine2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="billing_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="billing_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="billing_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="billing_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
            </div>
          </div>

          <div className="w-full mx-auto pb-4 bg-white rounded-md mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Delivery Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="deliveryAddressLine1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="deliveryAddressLine2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="delivery_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="delivery_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="delivery_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="delivery_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
            </div>
          </div>

          <div className="w-full mx-auto h-auto bg-white rounded-md overflow-hidden mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Products :
                </p>
              </div>

              <div className="w-full bg-white overflow-y-scroll mt-5">
                {error && (
                  <p className="text-xs font-semibold text-red-500">{error}</p>
                )}
                <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-4">
                  <p className="w-[5%]  text-left  text-white text-xs font-medium">
                    S.N.{" "}
                  </p>

                  <p className=" w-[40%] text-left text-white text-xs font-medium">
                    Product
                  </p>
                  <p className=" w-[10%] text-center text-white text-xs font-medium">
                    Unit
                  </p>
                  <p className=" w-[15%] text-center text-white text-xs font-medium">
                    Req.Qty
                  </p>
                  <p className=" w-[20%] text-center text-white text-xs font-medium">
                    Ordered Qty
                  </p>
                  <p className=" w-[15%] text-center text-white text-xs font-medium">
                    Price
                  </p>
                  <p className=" w-[15%] text-center text-white text-xs font-medium">
                    Neg.Price
                  </p>
                  <p className=" w-[10%] text-center text-white text-xs font-medium">
                    Total
                  </p>
                  <p className=" w-[5%] text-center text-white text-xs font-medium">
                    Action
                  </p>
                </div>
                {data?.products?.length > 0 ? (
                  <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                    {dataa?.products.map((List, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full py-2 flex justify-between px-4 odd:bg-white even:bg-slate-100"
                        >
                          <p className="w-[5%]  text-left   text-xs font-medium text-[#111537] py-1">
                            {index + 1}
                          </p>
                          <p className=" w-[40%] text-left  text-xs font-medium text-[#111537]">
                            {List?.product_name}
                          </p>
                          <p className=" w-[10%] text-center  text-xs font-medium text-[#111537]">
                            {List?.unit}
                          </p>
                          <p className=" w-[15%] text-center  text-xs font-medium text-[#111537]">
                            {List?.requestedQty}
                          </p>
                          <div className="w-[20%] text-center  text-xs font-medium text-[#111537]">
                            <button
                              type="button"
                              className="border rounded-full p-1 "
                              onClick={() => handleDecrement(index)}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              className="w-[20%] text-center border  text-xs font-medium text-[#111537]"
                              name="orderedQty"
                              value={List.orderedQty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <button
                              type="button"
                              className="border rounded-full p-1"
                              onClick={() => handleIncrement(index)}
                            >
                              +
                            </button>
                          </div>
                          <p className=" w-[15%] text-center  text-xs font-medium text-[#111537]">
                            {List?.price}
                          </p>

                          <div className="w-[15%] px-1">
                            <input
                              type="number"
                              className="w-[90%] py-1 text-center border  text-xs font-medium text-[#111537]"
                              name="negotiatedPrice"
                              formik={formik}
                              placeholder="Enter the Negotiated Price"
                              value={List.negotiatedPrice || ""}
                              onChange={(e) =>
                                handlenegotiatedPriceChange(e, index)
                              }
                            />
                          </div>
                          <p className="text-center w-[10%]  text-xs font-medium text-[#111537]">
                            {(
                              List.negotiatedPrice * List.orderedQty
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>

                          <div className="w-[5%] text-center">
                            <button
                              onClick={() => handleDelete(List?.productId)}
                              type="button"
                              className="text-red-500 cursor-pointer  ml-4 border-none"
                            >
                              <DeleteIcon color="red" height={4} />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full text-center text-[#93949f]">
                    No Items here
                  </div>
                )}
              </div>
              <div className="w-full my-6 flex justify-end items-center gap-4">
                <TextMediumBase content={"Total Price : "} />
                <p className="text-sm">
                  {String(data?.vendorCurrency).toUpperCase()}
                </p>
                <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                  <NuAmount
                    defaultCurrency={false}
                    value={formik?.values?.totalPrice || 0}
                  />
                </p>
              </div>
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default EditPo;
