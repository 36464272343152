// import React, { useEffect, useState } from "react";
// import SearchBar from "../../../../../../Components/Utils/SearchBar";
// import { Link, useLocation, useParams } from "react-router-dom";
// import axios from "../../../../../../Services/axiosCommon";
// import { useFormik } from "formik";
// import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
// import Pagination from "../../../../../../Components/Utils/Pagenation";
// import ViewMore from "./ViewMore";

// import NuAmount from "../../../../../../Components/Utils/NuAmount";
// import * as yup from 'yup';
// import { GetAccess } from "../../../../../../Components/Utils/roles";
// import { NuDataTime, NuDate, NuDatePicker, NuSelect } from "../../../../../../Components/Utils/Input";
// import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
// import CustomDataTable from "../../../../../../Components/Others/CustomDataTable";
// import axiosInstance from "../../../../../../Services/axiosCommon";
// import UseGetDataSearchByPro from "../../../../../../Components/Hooks/useFetchDataByPRO";
// import { useToast } from "../../../../../../Components/Others/ToastServices";
// import useFetchMDIndex from "../../../../../../Components/Hooks/useFetchMDIndex";
// import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";

// export default function ReceivedIndex(id) {
//   const { projectId } = useParams();
//   const toast = useToast();
//   const location = useLocation();
//   const {officeId , estmationId} = useParams();
//   console.log(officeId,estmationId,"estimationId");
  
//   const currentPage= location.state?.currentPage
//   const searchQuery= location.state?.searchQuery
//   const [page, setPage] = useState(currentPage||1)
//   const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
//   const [filter, setFilter] = useState(false);
//   const [showFilterDialog, setShowFilterDialog] = useState(false);
//   const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
//   const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
//   const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)

//   const [filterProps, setFilterProps] = useState({
//     category:"",
//     subCategory:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   });
//   const [params, setParams] = useState({
//     page: page,
//     per_page: pageCount,
//     search:searchQuery||"",
//     category:"",
//     subCategory:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   });
//   const clearFilter = ()=> {
//     setParams({...params, category:"",
//     subCategory:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   })
//   formik.values.category=""
//   formik.values.subCategory=""
//   formik.values.brand=""
//   formik.values.manufacturer=""
//   formik.values.underPromotion=""
//   formik.values.unit=""


//       setFilter(false)
//   }

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: filterProps,
//     validateOnChange: false,
//     validateOnBlur: false,
//     validationSchema: yup.object({
//       // email: yup.string().required('Email is required!'),
//     }),
//     onSubmit: async (value, { resetForm }) => {
//       console.log("ffff",value);
//       setParams({...params,category:value.category, subCategory:value.subCategory,brand:value.brand,manufacturer:value.manufacturer,underPromotion:value.underPromotion,unit:value.unit})
//       closeFilterDialog();
//       setFilter(true)
//       setFilterProps({
//         category:"",
//         subCategory:"",
//         brand:"",
//         manufacturer:"",
//         underPromotion:"",
//         unit:""
//       });
//     }
//   })

//   const { data, totalCount, getData,dataLoading } = UseGetDataSearchByPro( `/bidding/${officeId}/details`,params);
//   const defaultHeader = [
//     {
//       title: "Id",
//       width: "5%",
//       isLink: true,
//       // id: "serial_number",
//       // link: `/main/${officeId}/bidding/bids/view/`,  
//       field: "pettyCashId",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Project",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "project_name",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Requested By",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "requestedBy",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     // {
//     //   title: "Bid Due Date",
//     //   type:"date",
//     //   width: "15%",
//     //   field: "dueDate",
//     //   textAlign: "center",
//     //   rowAlign: "center",
//     // },
//     {
//       title: "Date",
//       width: "10%",
//       type:"date",
//       field: "date",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Requested Amt.",
//       width: "10%",
//       type:"date",
//       field: "requestedAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Approved Amt.",
//       width: "10%",
//       field: "approvedAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Balance Amt.",
//       width: "10%",
//       field: "balanceAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Purpose",
//       width: "10%",
//       field: "purpose",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Status",
//       width: "5%",
//       field: "status",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Action",
//       width: "5%",
//       field: "action",
//       textAlign: "center",
//       rowAlign: "center",
//       contents:{
//         // fetcher:getData,
//         // id:"biddingId",
//         removeValue:"pettyCashId",
//         url:`/received/`,
//         moduleName:"Received",
//         from:`/project/petty-cash/received/${officeId}/${projectId}`,
//         editLink:`/project/petty-cash/received/${officeId}/${projectId}/edit/`,
//         viewLink:`/project/petty-cash/received/${officeId}/${projectId}/view/`,
//         // projectLink:`boq`
//       }
//     },
//   ];
//   const availableHeaders = [
//     {
//       title: "Id",
//       width: "5%",
//       isLink: true,
//       // id: "serial_number",
//       // link: `/main/${officeId}/bidding/bids/view/`,  
//       field: "pettyCashId",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Project Name",
//       width: "10%",
//       isLink: true,
//       // id: "project_name",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "project_name",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Requested By",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,    
//       field: "requestedBy",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Date",
//       type:"date",
//       width: "15%",
//       field: "date",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Requested Amt.",
//       width: "10%",
//       type:"date",
//       field: "requestedAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Approved Amt.",
//       width: "10%",
//       field: "approvedAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Balance Amt.",
//       width: "10%",
//       field: "balanceAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Purpose",
//       width: "10%",
//       field: "purpose",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Status",
//       width: "5%",
//       field: "status",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Action",
//       width: "5%",
//       field: "action",
//       textAlign: "center",
//       rowAlign: "center",
//       contents:{
//         // fetcher:getData,
//         // id:"biddingId",
//         removeValue:"pettyCashId",
//         url:`/received/`,
//         moduleName:"Received",
//         from:`/project/petty-cash/received/${officeId}/${projectId}`,
//         editLink:`/project/petty-cash/received/${officeId}/${projectId}/edit/`,
//         viewLink:`/project/petty-cash/received/${officeId}/${projectId}/view/`,
//         projectLink:`boq`
//       }
//     },
//   ];

//   useEffect(()=>{
//     setParams({...params,page:page,per_page:pageCount})
//    },[page,pageCount])

//     const closeFilterDialog = () => {
//       setShowFilterDialog(false);
//     };
//        const [showImportModal, setShowImportModal] = useState(false);

//     const handleImportClick = () => {
//       setShowImportModal(true);
//     };
  
//     const closeModal = () => { 
//       setShowImportModal(false);
//     };


//     const [showEdit, setShowEdit] = useState(false)
//     const [showDelete, setShowDelete] = useState(false)
//     const [listofNumbers, setListofNumbers] = useState([])
//     const [fullList, setFullList] = useState([])
  
//     const selectOneFunction = (value) =>{ 
//       let NewId;
//       if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
//         NewId = listofNumbers.filter(Temp=> Temp !== value);
//         setListofNumbers(NewId)
//       }else{
//         setListofNumbers([...listofNumbers,value])
//       }
//     }
  
//     const selectMultipleFunction = (e) =>{ 
//   console.log(e.target.checked);
//   if(e.target.checked){
//     setListofNumbers(fullList)
//   }else{
//     setListofNumbers([])
//   }
  
//     }

//     const handleMultiDelete = (data) => {
//        axiosInstance.delete(`items/multi-delete`,{
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         data: {"ids" : data},
//       })
//        .then(res => {
//         if (res.data?.status === true) {
//           if(res?.data?.not_deleted_ids.length<=0){
//             toast.open({
//               type: "success",
//               message: "Success",
//               description: res.data?.msg || "Deleted Successfully."
//             });
//             getData();
//             setShowDelete(false)
//           }else{
//             toast.open({
//               type: "warning",
//               message: "Success",
//               description: `${res.data?.msg} \n Some Products has dependenies`|| "Deleted Successfully."
//             });
//             getData();
//             setShowDelete(false)
//           }

//         } else if (res.data?.status === false && res.data?.error) {
//           toast.open({
//             type: "error",
//             message: "Error",
//             description: res.data.error || "Delete UnSuccessfull"
//           });
//         } else {
//           toast.open({
//             type: "warning",
//             message: "Warning",
//             description: res.data?.msg || "Warning."
//           });
//         }
//       })
//       .catch(err => {
//         console.error(err);
//         toast.open({
//           type: "error",
//           message: "Error",
//           description: "Network Error."
//         });
//       });
//     }
  
//     useEffect(()=>{
//       let tempArr = []
//       data && data.forEach(i=>{
//         console.log("I",i?.productId);
//         tempArr.push(i?.productId)
//       });
//       setFullList(tempArr)
//     },[data])
//   return (
//     <div className='w-full h-full px-4 py-2'>
//       <div className='w-full flex justify-between items-center'>
//       <Breadcrumb
//           items={[
            
//             { name: "Petty-Cash" },
//             { name: "Received" },
//           ]} />
//         <div className=' flex gap-2'>
//         <SearchBar
//               handleChange={(e) =>{
//                 setParams({ ...params, search: e.target.value, page:1 });
//                 setPage(1)
//               }}
//               value={params.search||searchQuery}
//             />
//              <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
//           {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
//           {/* {GetRole() && <ImportButton handleClick={handleImportClick} />} */}
//           {/* {GetAccess("items_create") && */}
//           <Link to={`/project/petty-cash/received/${officeId}/${projectId}/create`}><CreateButton label='Received' /></Link>
//           {/* // } */}
//         </div>
//       </div>
//       <CustomDataTable 
//       fetcher={getData}
//         data={data} 
//         availableHeaders={availableHeaders} 
//         defaultHeader={defaultHeader}
//         bodyHeight='max-h-[calc(100vh-260px)]'
//         height='h-[calc(100vh-145px)]'
//         total={totalCount}
//         pageIndex={page}
//         setPageIndex={setPage}
//         dataCount={pageCount}
//         setDataCount={setPageCount}
//         title='Received List' 
//         storageName="receivedTable"
//         selectOneFunction={selectOneFunction}
//         setShowDelete={setShowDelete}
//         showDelete={showDelete}
//         setShowEdit={setShowEdit}
//         showEdit={showEdit}
//         listofNumbers={listofNumbers}
//         hasMultiEdit={true}
//         selectMultipleFunction={selectMultipleFunction}
//         fullList={fullList}
//         getById="biddingId"
//         showFieldFalse={false}
//         editAccess="items_edit"      
//         deteleAccess="items_delete"
//         dataLoading={dataLoading}   
//         searchQuery={params?.search!=""?params?.search:""}
//         />
//       {showFilterDialog && (
                   
                   
//                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
//                      <div className="animate-scale-in min-w-[50%] max-w-[70%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
//                        <h2 className="text-xl mb-4">Filter Received</h2>
//                        <form onSubmit={formik.handleSubmit}> 
//                           <div className="flex justify-start flex-wrap mb-4">
//                           <NuDate
//               label=" Date From"
//               // options={status}
//               formik={formik}
//               placeholder="Choose"
//               name="dateFrom"
//               width=" w-full"
//             />
//             <NuDate
//               label=" Date To"
//               // options={status}
//               formik={formik}
//               placeholder="Choose"
//               name="dateTo"
//               width=" w-full"
//             /> 
      
                  
//                   {/* <button
//                      type="submit"
//                     // size="large"
//                     className="w-[95%] bg-[#048178] text-white  mx-auto py-1 border-none outline-none rounded-md"
//                   >
//                     Filter 
//                   </button> */}
//                           </div>
//                          <div className="flex justify-end gap-2">
//                            <CancelButton handleClick={closeFilterDialog} />
//                            <SubmitButton name='Filter' />
//                          </div>
//                         </form> 
//                      </div>
//                    </div>
//                  )}
                  
//     </div>
//   )
// }


import React, { useEffect, useState } from "react";
import axios from "../../../../../../Services/axiosCommon";
import { Button, Modal } from "antd";
import { useFormik } from "formik";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import ViewMore from "./ViewMore";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import PettyCashLayout from "../PettyCashLayout";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import { GetAccess } from "../../../../../../Components/Utils/roles";
import { NuDate } from "../../../../../../Components/Utils/Input";

export default function ReceivedIndex(id) {
  const { officeId } = useParams();
  const { projectId } = useParams();
  const {value} = useFetchMataData(`/Metadata/noteStatus`)
  const [data, setData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [notFirst, setNotFirst] = useState(false);
  const [filterData, setFilterData] = useState({
    dateFrom:"",
    dateTo:"",
  });
  const [afterFilter, setAfterFilter] = useState({});

  async function getNotes() {
    await axios
      .get(`/petty_cash/?page=${pageIndex}&per_page=${dataCount}`)
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data?.pettyCashDetails);
        setTotalRows(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }


  const [open, setOpen] = useState(false);
  useEffect(() => {
    getNotes();
  }, []);

  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } })
        .then((res) => {
          res.data?.data && setData(res.data?.data?.pettyCashDetails);
        })
        .catch((err) => console.log(err));
    } else {
      getNotes();
    }
  }

  function SearchPageDate() {
    axios
      .get(
        `/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } }
      )
      .then((res) => {
        setData(res.data?.data?.pettyCashDetails);
        setTotalRows(res.data?.data?.totalRecords);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (searchQuery == "") {
      getNotes();
    }
  }, [searchQuery]);


  useEffect(() => {
      getNotes();
  }, [pageIndex,dataCount]);



    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterData,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // mobileNo: yup
        //   .string()
        //   // .required("Mobile Number is required")
        //   .matches(
        //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     "Enter Valid Mobile Number"
        //   )
        //   .min(10, "Invalid Mobile Number")
        //   .max(10, "Invalid Mobile Number"),
        // email: yup.string().required("Email is required"),
      }),
      onSubmit: async (value, { resetForm }) => {
        setAfterFilter(value);
        setIsFilter(true);
        axios
          .get(
            `/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
            {
              params: {
                // status: `${value.status}`,
                // SDF: `${value.SDF}`,
                // EDT: `${value.EDT}`,
                // priority: `${value.priority}`,
                dateFrom: `${value.dateFrom}`,
                dateTo: `${value.dateTo}`,
              },
            }
          )
          .then((res) => {
            setFilterOpen(false);
            setData(res?.data?.data?.pettyCashDetails);
            setTotalRows(res.data?.data?.totalRecords);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });
  
    function FilterPage() {
      axios
        .get(
          `/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
          {
            params: {
              // status: `${afterFilter.status}`,
              // SDF: `${afterFilter.SDF}`,
              dateFrom: `${afterFilter.dateFrom}`,
              dateTo: `${afterFilter.dateTo}`,
              // EDT: `${afterFilter.EDT}`,
              // priority: `${afterFilter.priority}`,
            },
          }
        )
        .then((res) => {
          setData(res?.data?.data?.pettyCashDetails);
          setTotalRows(res.data?.data?.totalRecords);
        })
        .catch((err) => console.log(err));
    }
  
    function ClearFilter() {
      formik.resetForm({});
      getNotes();
      setIsFilter(false);
    }
  
    useEffect(() => {
      if (isFilter) {
        FilterPage();
      } else if (searchQuery != "") {
        SearchPageDate();
      } else {
        getNotes();
      }
    }, [pageIndex, dataCount]);

  return (
    <>
       <PettyCashLayout       
       setOpen={setOpen}
       setSearchQuery={setSearchQuery}
       SearchData={SearchData}
       isFilter={isFilter}
       setFilterOpen={setFilterOpen}
       ClearFilter={ClearFilter}
       CreateAccess={GetAccess()}
       ImportAccess={GetAccess()}
       officeId={officeId}
       projectId={projectId}
       >

<div className=" w-full h-[calc(100vh-180px)] rounded-md overflow-y-auto">
            <table
              className="table-auto w-full px-3"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className=" w-full bg-white p-3 mt-4">
                <tr className="text-xs border-b  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-3 ${header.width} ${header?.isLeft?"text-left":"text-center"} font-medium text-xs 2xl:text-sm text-[#4F4768]`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead> 
              <tbody className="">
                {data && data.map((item, i) => (
                                   <tr className=" w-full " key={i}>
                                   <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">{item?.serial_number||item?.pettyCashId||"#"}</td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/petty-cash/received/view/${id}`} state={{data:item}}> {item?.projectName||"-"}</Link>
                                   </td>
                                   {/* <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.category||"-"}</td> */}
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.requestedBy||"-"}</td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.date !="" && item?.date !="None" && <NuDate value={item?.date} format="DD-MMM-YYYY" /> ||"-"}</td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <NuAmount value={item?.requestedAmount||0} />
                                   
                                    {/* {item?.amount||"0.0"} */}
                                   </td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <NuAmount value={item?.approvedAmount||0} />

                                    {/* {item?.receivedAmount||"0.0"} */}
                                   </td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <NuAmount value={item?.balanceAmount||0} />

                                    {/* {`${item?.amount - item?.receivedAmount}`||"0.0"} */}
                                    </td>
                                   <td className="py-2 px-1  text-xs text-left truncate font-normal text-mainColor-400">{item?.purpose||"-"}</td>
                                   <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                                     <div className=" w-full flex justify-center items-center">
                                     <ViewMore
                                       id={item?.pettyCashId}
                                       getData={getNotes}
                                       data={item}
                                      />
                                     </div>
                                   </td>
                                 </tr>
                ))}
              </tbody>
            </table>
          </div>
      
      <div className=" h-6 my-2">
        <Pagination
          total={totalRows}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>

       </PettyCashLayout>

       <Modal
        open={filterOpen}
        title={"Filter Received"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <NuSelect
              label="Priority"
              options={priority}
              formik={formik}
              placeholder="Choose"
              name="Priority"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />*/}
            <NuDate
              label=" Date From"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateFrom"
              width=" w-full"
            />
            <NuDate
              label=" Date To"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateTo"
              width=" w-full"
            /> 
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="filterStatus"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#048178] text-white  mx-auto"
            >
              Filter
            </Button>
          </div>
        </form>
      </Modal>

    </>
  );
}

const header = [
  {
    headone: "ID",
    width: "w-[3%]",
    // isLeft: true,
  }, 
  {
    headone: "Project",
    width: "w-[20%]",
    isLeft: true,
  },
  {
    headone: "Requeste By",
    width: "w-[10%]",
    isLeft: true,
  },
  // {
  //   headone: "Cost Per Hour",
  //   width: "w-[8%]",
  // },
  // {
  //   headone: "Invoice #",
  //   width: "w-[8%]",
  //   isLeft: true,

  // },
  {
    headone: "Date",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Requested Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Approved Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Balance Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Purpose",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];


