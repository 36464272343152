import React from "react";

function Store({ color = "#999999", width = 20, height = 20 }) {
  return (
    <svg
      fill={color}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 256 197"
      enable-background="new 0 0 256 197"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <path d="M2.415,34.671l19.668-19.981h-0.102V2.203h118.01v12.488h0.103l19.668,19.981H2.415z M144.362,91.165h20.434v85.409h-13.831 h-2.145c-0.62,0-1.128-0.395-1.241-1.015c-1.692-9.704-10.155-17.094-20.366-17.094s-18.674,7.39-20.367,17.094 c-0.113,0.564-0.62,1.015-1.241,1.015H82.271c-3.008,0-5.446-2.438-5.446-5.446v-13.451H2v-9.99h14.985v-84.96 C8.377,60.535,2,52.958,2,43.815v-4.773h157.347v4.71c0,9.143-6.53,16.783-14.985,18.974V91.165z M76.825,147.687V91.165h57.547 V62.574c-8.473-2.196-14.75-9.787-14.75-18.945c0,6.524-6.013,19.635-19.583,19.635c-15.019,0-19.646-12.735-19.646-19.635 c0,7.402-5.94,19.649-19.482,19.649c-15.592,0-19.81-15.605-19.81-19.649c0,9.159-6.276,16.75-14.75,18.945v85.113H76.825z M142.727,179.282c0,8.575-6.939,15.515-15.515,15.515c-8.575,0-15.515-6.939-15.515-15.515s6.939-15.515,15.515-15.515 C135.788,163.768,142.727,170.707,142.727,179.282z M133.155,179.282c0-3.257-2.685-5.943-5.943-5.943 c-3.257,0-5.943,2.685-5.943,5.943s2.685,5.943,5.943,5.943C130.47,185.225,133.155,182.54,133.155,179.282z M254,166.645v4.175 c0,3.159-2.539,5.755-5.755,5.755h-2.144c-0.621,0-1.128-0.395-1.241-1.015c-1.693-9.704-10.155-17.094-20.366-17.094 s-18.674,7.39-20.366,17.094c-0.113,0.564-0.621,1.015-1.241,1.015h-33.242V111.3h23.474c1.636,0.056,3.216,0.733,4.344,1.862 l20.092,27.08c0.226,0.226,0.564,0.395,0.903,0.395h22.003c5.021,0,9.027,4.006,9.027,8.97v15.853h3.272 C253.436,165.46,254,166.024,254,166.645z M206.441,138.493l-13.153-20.028c-0.282-0.226-0.621-0.395-0.959-0.395h-14.485 c-0.677,0-1.241,0.564-1.241,1.241v20.084c0,0.677,0.564,1.241,1.241,1.241h27.638C206.553,140.637,207.118,139.339,206.441,138.493 z M240.009,179.282c0,8.575-6.939,15.515-15.515,15.515s-15.515-6.939-15.515-15.515s6.939-15.515,15.515-15.515 S240.009,170.707,240.009,179.282z M230.437,179.282c0-3.257-2.685-5.943-5.943-5.943s-5.943,2.685-5.943,5.943 s2.685,5.943,5.943,5.943S230.437,182.54,230.437,179.282z" />{" "}
      </g>
    </svg>
  );
}

export default Store;
