// import React from 'react'
// import { useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import NuDate from '../../../../Components/Utils/NuDate';
// import FilesView from '../../../../Components/Utils/FilesView';
// import { useEffect } from 'react';

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NuDate } from "../../../../../../Components/Utils/Input";
import FilesView from "../../../../../../Components/Utils/FilesView";

function ViewOfRelatableGrn() {
    const location = useLocation();
    const navigate = useNavigate();
    const [data,setData] = useState(location.state.data);
    const [filePathList, setFilePathList] = useState([]);
    useEffect(()=>{
        if (
          data?.attachments !== null &&
          data?.attachments !== "None" &&
          data?.attachments !== "[]"
        ) {
          setFilePathList(data?.attachments);
          // console.log('image',createNew?.attachments);
          // setRemoveFilePathList(data?.productImage);
          // console.log('images',filePathList);
        }
    },[data])
    const onClose = () =>{
        navigate(-1);
    }
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
     <div className="animate-scale-in w-[90%] h-[90vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
        <div className='w-full flex justify-end mb-2'>
              <div className='w-5 h-5 rounded-sm flex justify-center items-center bg-red-400 text-white text-sm cursor-pointer' onClick={onClose}>X</div>
            </div>
        <div className="w-full mx-auto h-[calc(100vh-145px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-full  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
        <div className="grid  items-center ">
        <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>
              <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.createdAt ? (
    <NuDate value={data?.createdAt} format="DD-MMM-YYYY, hh:mm A"  /> 
  ) : (
    "N/A"
  )}
         </p>
         {/* <p className="text-xs text-[#111537]"> Created By : {data.preparedByName || 'None'}</p> */}
         </div>
         </div>
        
         <div className="grid gap-2  justify-end w-[20%]">
               <div className='pb-1 flex  justify-between'>
         {/* <p className='text-xs cursor-pointer text-white bg-[#6A9C89] rounded-md inline-flex w-auto h-5 px-2 mr-3 flex-shrink-0 items-center justify-center' onClick={handleOpenCommentsModal}>
          GRN
         </p> */}
            </div>
         {/* <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]">  {data.status || 'None'}</p> */}
         <div className="border rounded-md mr-3  text-xs inline-flex w-auto  flex-shrink-0  justify-center">
            <p className="  bg-white  text-black  p-1 rounded-l-md  ">
              Status  </p><p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md"> {data.status || 'None'}</p>
             
              </div>
         </div>
         </div>
    {/* <div className="flex justify-between items-center">
     
      <div className='flex gap-3 items-center'>
      <NormalHeading title={"GRN"} />   : <p className="text-xs grid pt-5"> {data.ref} 
              <span className="font-light text-[11px] ">{data?.receivedDate ? (
                  <NuDate value={data?.receivedDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</span> </p>   </div>
                 <p className=" p-2  bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.status || 'None'}
              </p></div> */}
          <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">

<div className=" w-full flex flex-wrap  border border-gary mt-2 py-2 rounded-lg mb-2">

          <div className="w-full grid grid-cols-2 gap-x-8 ">
            {/* <div className='w-full flex px-2'>
                     <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Purchase Order</p>
                  <p className='w-[65%] pl-2 text-xs  text-[#606279]'>: {data.poRef}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Supplier</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.vendorName}</p>
                </div>  */}
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Delivery Notes</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.dnRef}</p>
                </div> 
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Busy GRN No.</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.busyGrnNo}</p>
                </div> 
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Received Date</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.receivedDate  ? <NuDate value={data?.receivedDate} format="DD-MMM-YYYY hh:mm a" /> : '-'}</p>
                </div>  */}
              
              
                {data && (
                  <>
                  {data.approvedByName &&
                   <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Approved By</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.approvedByName}</p>
                </div> 
}
{data.reviewedByName &&
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Reviewed By</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.reviewedByName}</p>
                </div> }
                {data.verifiedByName &&
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Verified By</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.verifiedByName}</p>
                </div> }
                  </>
                )}
                
               
                 {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Approval Status</p>
                <p className='w-[65%]  py-2 pl-5 text-xs text-[#606279]'>: {data.approvalStatus}</p>
              </div>  */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Status </p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.status}</p>
                </div>  */}
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                  <p className='w-[65%]  py-2 pl-2 text-xs  text-[#606279]'>: {data.comments}</p>
                </div> 
                </div>
                </div>
                <div className="flex mt-4">
                {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 border border-gary p-4 rounded-lg w-[10%]">
              <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
         <div className="w-[50%] ">
          <p className="  text-xs font-medium text-[#111537] p-1">
          GRN Attachments
            </p>
            <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">

{/* <SmallHeading title={"GRN Attachement"} /> */}
{/* <div className="md:flex justify-between mt-5 px-3 w-full"> */}
<div className="w-full flex gap-2 items-center flex-wrap  ">
{data?.attachments &&
                                    filePathList.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                    <FilesView fileView={imgs} key={idx}/>

                                      //     <div
                                      //     className=" w-10 h-11 relative flex justify-center items-center rounded-lg"
                                      //     key={idx}
                                      //     style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                      // >
                                      //   <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" onClick={() => handleImageClick(imgs)}>
                                      //               {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                      //               <img src={imgs?.file} alt={imgs?.file} />
                                      //           </div>
                                      //           </div>
                                                  );
                                                })}
            
</div>
</div>
</div>
</div>
                {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
                <div className="w-full bg-white border border-gary  mt-2  rounded-lg ">

  <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-2">
  <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

  <p className="w-[40%]  text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Ordered Qty</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Received Qty</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Remaining Qty</p>

                <p className="w-[15%] text-center text-white text-xs font-medium">This Delivery</p>

             
             </div>
  {data?.products?.map((List, index) => {
    return (
      <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2">
        <p className="w-[5%] text-left  text-[#606279] text-xs font-light ">
                                    {index + 1}
                                </p>
        <p className="w-[40%]  text-left text-[#606279] text-xs font-light ">
          {List?.product_name}
        </p>
        <p className="w-[10%] text-center text-[#606279] text-xs font-light ">
          {List?.product_unit}
        </p>
        <p className="w-[15%] text-center text-[#606279] text-xs font-light ">
          {List?.orderedQty}
        </p>
        <p className="w-[15%] text-center text-[#606279] text-xs font-light ">
          {List?.receivedQty}
        </p>
       
        <p className="w-[15%] text-center text-[#606279] text-xs font-light ">
          {List?.remainingQty}
        </p>
        <p className="w-[15%] text-center text-[#606279] text-xs font-light ">
          {List?.thisDelivery}
        </p>
       
      </div>
    );
  })}
</div>
{/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

  
</div>
</div>
</div>
      </div>
    </div>
  )
}

export default ViewOfRelatableGrn