import React from 'react'
import { BackButton } from '../../../../Components/Utils/Buttons'
import ManPoweBudgetindex from './ManPowerBudget'
import MaterialBudgetindex from './MaterialBudget'
import OtherManPowerindex from './Others'
import IndexIncomeSettings from './IncomingFinance'
import IndexOutgoingSettings from './Outgoing'
import IndexOtherexp from './OtherExpenses'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import ManPowerProgressIndex from './ProgressManPower'
import MaterialProgressIndex from './ProgressMaterial'
import EquipmentProgressIndex from './ProgressEquipment'
import ProgressOthersIndex from './ProgressOthers'

function MainProjectSett() {
    return(
        <div className='w-full h-full px-4 py-2'>
          <div className='w-full flex justify-between items-center'>
          <Breadcrumb
            items={[
              {
                name: "Settings",
              },
              {
                name: "Project"
              }
            ]}
          />
          <BackButton/>
          </div>
          <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
                <div className="p-3"><ManPoweBudgetindex/></div>
                <div className="p-3"><MaterialBudgetindex/></div>
  
                <div className="p-3"><OtherManPowerindex/></div>
                <div className="p-3"><IndexIncomeSettings/></div>
                <div className="p-3"><IndexOutgoingSettings/></div>
                <div className="p-3"><IndexOtherexp/></div>
                <div className="p-3"><ManPowerProgressIndex/></div>
                <div className="p-3"><MaterialProgressIndex/></div>
                <div className="p-3"><EquipmentProgressIndex/></div>
                <div className="p-3"><ProgressOthersIndex/></div>


          </div>
          </div>
      )
}

export default MainProjectSett
