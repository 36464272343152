import { Layout } from 'antd';
import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { Navbar } from './Layouts/MainPage';

function Main() {
    let {Content} = Layout;
  return (
    <div className=" w-[100vw] h-screen">
    <Layout className=" w-full h-screen">
    <div className=" w-full h-[59px] bg-white z-50 shadow-lg">
<Navbar />
</div>
      <Layout className=' w-full h-[calc(100vh-59px)]'>
        <Content className=' py-2 px-6'>
          <div className=" w-60 my-3 bg-white rounded-full p-1 h-10 shadow-sm flex justify-between items-center">
            <NavLink to={"/main-page/dashboard"} className={({isActive})=> isActive?" bg-baseColor-primary rounded-full text-sm font-medium text-white px-6 py-[6px] hover:text-white":"rounded-full text-sm font-medium hover:text-baseColor-primary text-baseColor-primary px-6 py-[6px]"}>Dashboard</NavLink>
            <NavLink to={"/main-page/offices"} className={({isActive})=> isActive?" bg-baseColor-primary rounded-full text-sm font-medium text-white px-6 py-[6px] hover:text-white":"rounded-full text-sm font-medium hover:text-baseColor-primary text-baseColor-primary px-6 py-[6px]"}>Offices</NavLink>
          </div>
          <div className=" w-full h-auto overflow-y-auto">
          <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  </div>
  )
}

export default Main;
