

import React, { useEffect, useState } from 'react'
import SearchBar from "../../../../Components/Utils/SearchBar";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import axiosInstance from "../../../../Services/axiosCommon";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import { useFormik } from "formik";
import { useToast } from "../../../../Components/Others/ToastServices";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";
import * as yup from 'yup';
import { Link, useLocation, useParams } from "react-router-dom";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import { GetAccess, GetRole } from "../../../../Components/Utils/roles";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import ImportActivity from "./import";
import CreateActivity from './CreateActivity';
import UseGetDataSearchByPro from '../../../../Components/Hooks/useFetchDataByPRO';
import EditActivity from './EditActivity';
import ItemLayout from '../ItemLayout';
import ItemBidLayout from '../../Biddings/ItemBidLayout';

function IndexActivity() {
  
  const toast = useToast();
  const location = useLocation();
  const {officeId} = useParams();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
  const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
  const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)
  const [createModalOpen, setCreateModalOpen] = useState(false); // State to handle modal open/close
  const [projectList, setProjectList] = useState([]);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Edit modal state
  const [open, setOpen] = useState(false);

  const [filterProps, setFilterProps] = useState({
    category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    underPromotion:"",
    unit:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",
    category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    underPromotion:"",
    unit:""
  });
  const clearFilter = ()=> {
    setParams({...params, category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    underPromotion:"",
    unit:""
  })
  formik.values.category=""
  formik.values.subCategory=""
  formik.values.brand=""
  formik.values.manufacturer=""
  formik.values.underPromotion=""
  formik.values.unit=""


      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, subCategory:value.subCategory,brand:value.brand,manufacturer:value.manufacturer,underPromotion:value.underPromotion,unit:value.unit})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        category:"",
        subCategory:"",
        brand:"",
        manufacturer:"",
        underPromotion:"",
        unit:""
      });
    }
  })
  

  const { data, totalCount, getData,dataLoading } = UseGetDataSearchByPro( `/Activity/${officeId}`,params);
  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      
      field: "serial_number",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Item Code",
      width: "10%",
       
      field: "item_code",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Description",
      
      width: "15%",
      field: "description",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
     
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"item_code",
          url:`/Activity/`,
          moduleName:"Activity",
          from:`/main/${officeId}/item/activity`,
          editLink:`/main/${officeId}/item/activity/edit/`,
          viewLink:`/main/${officeId}/item/activity/view/`
        }    
      },
    
  ];
  const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      
      field: "serial_number",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Item Code",
      width: "10%",
       
      field: "item_code",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Description",
          width: "15%",
      field: "description",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
     
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"item_code",
          url:`/Activity/`,
          moduleName:"Activity",
          from:`/main/${officeId}/item/activity`,
          editLink:`/main/${officeId}/item/activity/edit/`,
          viewLink:`/main/${officeId}/item/activity/view/`
        }    
      },
    
  ];

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

    const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
      const [showImportModal, setShowImportModal] = useState(false);

    const handleImportClick = () => {
      setShowImportModal(true);
    };
  
    const closeModal = () => {
      setShowImportModal(false);
    };


    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [listofNumbers, setListofNumbers] = useState([])
    const [fullList, setFullList] = useState([])
  
    const selectOneFunction = (value) =>{ 
      let NewId;
      if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
        NewId = listofNumbers.filter(Temp=> Temp !== value);
        setListofNumbers(NewId)
      }else{
        setListofNumbers([...listofNumbers,value])
      }
    }
  
    const selectMultipleFunction = (e) =>{ 
  console.log(e.target.checked);
  if(e.target.checked){
    setListofNumbers(fullList)
  }else{
    setListofNumbers([])
  }
  
    }
   
    useEffect(()=>{
      let tempArr = []
      data && data.forEach(i=>{
       
        tempArr.push(i?.id)
      });
      setFullList(tempArr)
    },[data])

    const [valueOfSearch,setValuesofSearch] = useState("")
     function searchFunction(e) {  
       
       setValuesofSearch(e?.target?.value)
       setParams({ ...params, search: e?.target?.value, page:1 });
       setPage(1)
     }
    
  return (
     <>
      <ItemBidLayout
        setOpen={setOpen}
        searchFun={searchFunction}
        getData={getData}
        searchValue={valueOfSearch}
        
        CreateAccess={GetAccess("activity_item_create")}
        ImportAccess={GetAccess("activity_item_create")}
        // isFilter={isFilter}
        // setFilterOpen={setFilterOpen}
        // ClearFilter={ClearFilter}
        onCreateClick={() => setCreateModalOpen(true)} 
      >
    <div className='w-full h-full '>

      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)] '
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        // title='Activity List' 
        storageName="ActivityTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        // hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="id"
        showFieldFalse={false}
        // editAccess="items_edit"      
        // deteleAccess="items_delete"
        dataLoading={dataLoading}   
        searchQuery={params?.search!=""?params?.search:""}
        />
    </div>
       
           </ItemBidLayout>
           <ImportActivity
        open={open}
        onCancel={() => setOpen(false)}
        getData={getData}
        label="Import"
        officeId={officeId}
      />   
         <CreateActivity
          open={createModalOpen}
          onCancel={() => {
            setCreateModalOpen(false);
          }}
          projectList={projectList || []}
          getData={getData}
          // firstProject={firstProject}
        />
       
    </>
  )
}

export default IndexActivity