import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../../../Components/Utils/Buttons";
import { TextMediumBase, TextMediumSM } from "../../../../../../Components/Text/MediumText";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import { NuDate } from "../../../../../../Components/Utils/Input";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import { BackButton } from "../../../../Components/Utils/Buttons";
// import { useState } from "react";
// import { useEffect } from "react";
// import socketAxiosInstance from "../../../../Services/socketAxiosCommon";
// import { TextMediumBase, TextMediumSM, TextMediumXL } from "../../../../Components/Text/MediumText";
// import axiosInstance from "../../../../Services/axiosCommon";
// import NuDate from "../../../../Components/Utils/NuDate";
// import NuAmount from "../../../../Components/Utils/NuAmount";


const SupplierPriceListing = () =>{
    const { productId,supplierId , officeId,projectId} = useParams();
    console.log("Product ID:", productId, "Supplier Price View ID:", supplierId);
    const [data,setData] = useState([]);
    const getData = () =>{
        axiosInstance.get(`/supplier-quotations/supplier-price-listing/${productId}/${supplierId}`)
        .then((res)=>{
            console.log("zzz",res?.data);
            res?.data?.data && setData(res?.data?.data);
        })
    }
    useEffect(()=>{
       getData();
    },[])
    return(
        <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { 
                name: "Product Comparison",
                link: `/project/procurement/product-comparision/${officeId}/${projectId}`
            },
            { name: "Supplier Price List" },
          ]} />
        <BackButton/>
          </div>
          <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-4 rounded-md overflow-y-scroll">
          <TextMediumBase color="text-[#4F4768]" content={`Product Name: ${data?.productName}`}/>
          <TextMediumSM color="text-[#4F4768]" content={`Supplier Name: ${data?.supplierName}`} />
          <table className=' w-full max-h-[calc(100vh-200px)] overflow-y-auto mt-2 '>
       <thead>
       <tr className='border-b border-[#C4C2C2] text-[#4F4768]'>
          <th className=' w-[20%] text-left text-sm md:text-sm lg:text-xs xl:text-sm font-medium py-2 px-4'>Price</th>
          <th className=' w-[20%] text-left text-sm md:text-sm lg:text-xs xl:text-sm font-medium py-2 px-4'>Date</th>
        </tr>
       </thead>
       <tbody>
       {data?.quotes?.map((value,index)=>{
            return(
                <tr className='even:bg-white odd:bg-slate-50 ' >
                  <td className=' w-[30%] py-2 text-left text-xs px-4 text-[#606279]'>
                    <NuAmount value={value?.price} />
                  </td>
                  <td className=' w-[30%] py-2 text-left text-xs px-4 text-[#606279]'>
                    {value?.dateReceived && NuDate({value:value?.dateReceived,format:"DD-MMM-YYYY"})}
                  </td>
                </tr>
            )
          })}
       </tbody>
       </table>
      </div>
        </div>
    )
}

export default SupplierPriceListing;