
import * as yup from 'yup';
import { useFormik } from "formik";
import UseGetDataSearchByPro from "../../../Components/Hooks/useFetchDataByPRO";

import SearchBar from "../../../Components/Utils/SearchBar";
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from "../../../Components/Utils/Buttons";
import { GetRole } from "../../../Components/Utils/roles";
import CustomDataTable from "../../../Components/Others/CustomDataTable";
import EmployeeCard from "./EmployeeCard";
import Pagination from "../../../Components/Utils/Pagenation";
import ImportEmployee from "./import";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useFetchMetaStarIndex from "../../../Components/Hooks/useFetchMetaStarIndex";
import useFetchMDIndex from "../../../Components/Hooks/useFetchMDIndex";
import Breadcrumb from '../../../Components/Utils/Breadcrumb';
import List from '../../../Components/Icons/list';
import Card from '../../../Components/Icons/card';
import UseGetDataSearchByProUser from '../../../Components/Hooks/useFetchDataByPROUser';
import UseGetDataSearch from '../../../Components/Hooks/useFetchDataBySearch';


function Employeesindex() {
   const [open, setOpen] = useState(false);
   const location = useLocation();
   const state = location.state;
  const {officeId}=useParams();
   const currentPage= location.state?.currentPage
   const searchQuery= location.state?.searchQuery
   const [page, setPage] = useState(currentPage||1)
   const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
   const [filter, setFilter] = useState(state?.industry?true:false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filterProps, setFilterProps] = useState({
    country:"",
    category:"",
    paymentTerm:"",
    ratings:"",
    status:"",
    industry:"",
    customerType:"",
    approvalStatus:"",

  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    // search:searchQuery||"",

    // searchBy: "",
    // country:"",
    // // category:state?.category? state.category :"",

    // category:"",
    // paymentTerm:"",
    // ratings:"",
    // status:"",
    // industry:state?.industry? state.industry :"",
    // customerType:"",
    // approvalStatus:"",

  });
  const clearFilter = ()=> {
    setParams({...params, country:"",
    category:"",
    paymentTerm:"",
    ratings:"",
    status:"",
    industry:"",
    approvalStatus:"",

    customerType:""})
    formik.values.status=""
    formik.values.customerType=""
    formik.values.category=""
    formik.values.paymentTerm=""
    formik.values.industry=""
    formik.values.country=""
    formik.values.ratings=""
    formik.values.approvalStatus=""



      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,country:value.country, industry:value.industry,paymentTerm:value.paymentTerm,category:value.category,ratings:value.ratings,status:value.status,customerType:value.customerType,approvalStatus:value.approvalStatus})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        country:"",
        category:"",
        paymentTerm:"",
        ratings:"",
        status:"",
        industry:"",
        customerType:"",
        approvalStatus:""
      });
    }
  })

  
  const { data, totalCount, getData,dataLoading } = UseGetDataSearch( `/user/all-members`, params );
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      field: "userId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: " Name",
      width: "10%",
      isLink: true,
      id: "userId",
      link: `/main/${officeId}/employees/view/`,
      field: "name",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Email ",
      width: "10%",
      type: "email",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    
    {
      title: "Mobile ",
      width: "10%",
      field: "phone.display",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Division ",
      width: "10%",
      field: "division",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "City ",
      width: "10%",
      field: "city",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"userId",
        removeValue:"firstName",
        url:`/user/remove-user/`,
        moduleName:"Employee",
        from:`/main/${officeId}/employees/`,
        editLink:`/main/${officeId}/employees/edit/`,
        viewLink:`/main/${officeId}/employees/view/`

      }
    },
  ];
  const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "userId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: " Name",
      width: "10%",
      isLink: true,
      id: "userId",
      link: `/main/${officeId}/employees/view/`,
      field: "name",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Email ",
      width: "10%",
      type: "email",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    
    {
      title: "Mobile ",
      width: "10%",
      field: "phone.display",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Division ",
      width: "10%",
      field: "division",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status ",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Street ",
      width: "10%",
      field: "streetName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "City ",
      width: "10%",
      field: "city",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "District ",
      width: "10%",
      field: "district",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "State ",
      width: "10%",
      field: "state",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Country ",
      width: "10%",
      field: "country",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Postal Code ",
      width: "10%",
      field: "postalCode",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Position ",
      width: "10%",
      field: "position",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Monthly Tar. ",
    //   width: "10%",
    //   field: "monthlyTar",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Quarterly Tar.",
    //   width: "10%",
    //   field: "quarterlyTar",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "HalfYear Tar. ",
    //   width: "10%",
    //   field: "halfYearTar",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Yearly Tar ",
    //   width: "10%",
    //   field: "yearlyTar",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
      
   
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"userId",
        removeValue:"firstName",
        url:`/user/remove-user/`,
        moduleName:"Employee",
        from:`/main/${officeId}/employees/`,
        editLink:`/main/${officeId}/employees/edit/`,
        viewLink:`/main/${officeId}/employees/view/`

      }
    },
  ];
  useEffect(()=>{
    localStorage.setItem("viewMode", viewMode);
     setParams({...params,page:page,per_page:pageCount})
 },[page,pageCount,viewMode])

 const closeFilterDialog = () => {
  setShowFilterDialog(false);
};
const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-end items-center'>
        {/* <Breadcrumb
         items={[
          {
            name: "Employee",

          },
        

          ]} /> */}
        <div className=' flex gap-2'>
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />
          <div className='flex gap-2'>
            <div onClick={() => setViewMode("list")} className={viewMode === "list" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><List/></div>
            <div onClick={() => setViewMode("card")} className={viewMode === "card" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><Card/></div>
          </div>
          {/* <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
            <ImportButton handleClick={handleImportClick} />
             <Link to={`/main/${officeId}/employees/create`}><CreateButton label='Employee' /></Link>

        </div>
      </div>
      {viewMode === "list" ? (
      <CustomDataTable 
        fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={params.page}
        setPageIndex={setPage}
        dataCount={params.per_page}
        setDataCount={setPageCount}
        // title='Employee List'
        storageName="EmployeeTable"
        editAccess="employees_edit"      
        deteleAccess="employees_delete" 
        dataLoading={dataLoading}
        searchQuery={params?.search!=""?params?.search:""}

        />
      ) : (
        <>
        <div className='w-full h-[calc(100vh-190px)] rounded-md bg-white p-3 mt-4 relative overflow-y-scroll'>
       <div className='flex gap-40'>
      
        <p className='text-xs font-semibold text-[#111537] text-left'>Employee : {totalCount}</p>
        {/* <p className='text-sm'>Credit : {}</p>
         <p className='text-sm'>Advance : {}</p>
         */}

       
        </div>
          <div className='w-full flex justify-start flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4'>
            
            {data && data.map((item, idx) => (
              <EmployeeCard fetcher={getData} item={item} key={idx} />
            ))}
          </div>
        </div>
        <div className="w-full h-[40px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
        </div>
        </>
      )}
       {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter </h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                  
                     
                     
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          
          {showImportModal && (<ImportEmployee show={showImportModal} onClose={closeModal} getData={getData} />)}
          {/* <ImportEmployee
        open={open}
        onCancel={() => setOpen(false)}
        getData={getData}
        officeId={officeId}
        label="Import"
      />    */}
          </div>
  )
}
 
export default Employeesindex