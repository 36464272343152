import React, { useEffect, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  NuDate,
  NuInput,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement, {
  videotype,
} from "../../../../Components/Utils/CustomAttrachmentupload";
import DeleteIcon from "../../../../Components/Icons/Delete";
import CloseIcon from "../../../../Components/Icons/Close";
import Video from "../../../../Components/Icons/Video";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

function EditGRN() {
  const {officeId} =useParams();

  const { value: GrnStatus, getData: refGrnStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "GrnStatus"
  );
  const { data: PR } = UseDropDown("/PR/drop-down");
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [image, setImage] = useState([]);
  const { data: deliveryNotes } = UseDropDown("/delivery-notes/drop-down");
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [dataa, setData] = useState(data);
  const [fileRemoveList, setFileRemoveList] = useState([]);

  const [createNew, setCreateNew] = useState({
    prRef: data?.prRef,
    purchaseType: data?.purchaseType,
    receivedDate: data?.receivedDate,
    vendorId: data?.vendorId,
    poId: data?.poId,
    comments: data.comments,
    status: data.status,
    productId: data.productId,
    orderedQty: data.orderedQty,
    receivedQty: data.receivedQty,
    thisDelivery: data.thisDelivery,
    remainingQty: data.remainingQty,
    approvalStatus: data.approvalStatus,
    dnRef: data.dnRef,
    busyGrnNo: data.busyGrnNo,
    vendorName: data?.vendorName || "",
    poRef: data?.poRef || "",
  });
  const handleDelete = (productId) => {
    const updatedquoteProducts = dataa?.products.filter(
      (item) => item.productId !== productId
    );
    console.log(updatedquoteProducts, "pp");
    setData((prevState) => ({
      ...prevState,
      products: updatedquoteProducts,
    }));
    // setRemove([...remove,productId]);
  };

  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      poId: yup.string().required("Purchase Order is required!"),
      // busyGrnNo: yup.string().required('Busy GRN No. No is required!'),
      dnRef: yup.string().required("Delivery Note Ref. No is required!"),
      prRef: yup.string().required("YPR is required!"),
    }),

    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (dataa.products.length === 0) {
        setError("Product table is empty");
        return;
      }
      dataa.products.forEach(item =>{
        item.receivedQty  = item.orderedQty > item.thisDelivery ?  item.receivedQty + item.thisDelivery : item.receivedQty;
        item.remainingQty = item.remainingQty > item.thisDelivery?item.remainingQty - item.thisDelivery:0
      }
       )
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        products: dataa.products,

        filesToRemove: fileRemoveList,
      };

      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      editFormData(`/grn/edit-grn/${data.id}`, formData, resetForm, from);
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        tempArr.push(fileName);
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);

      setRemoveFilePathList(data?.attachments);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (dataa.products.length > 0) {
      setError("");
    }
  }, [dataa.products]);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      tempArr.push(fileName);
    });
    setImage(tempArr);
  };

  const handleIncrementDelivery = (index, field, ordered, recived) => {
    console.log("delein", index, field);
    console.log("de", ordered - recived);
    const updatedProducts = [...dataa.products];

    if (updatedProducts[index].thisDelivery < ordered - recived) {
      updatedProducts[index].thisDelivery += 1;
    }

    setData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleDecrementDelivery = (index) => {
    const updatedProducts = [...dataa.products];

    if (updatedProducts[index].thisDelivery > 0) {
      updatedProducts[index].thisDelivery -= 1;
    }

    setData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const [dataPro, setDataPro] = useState(data?.products);

  const handleInputChangeDelivery = (e, index) => {
    const { value } = e.target;
    setDataPro((prevList) => {
      const newList = [...prevList];
      newList[index].thisDelivery = value ? parseInt(value) : 0;
      return newList;
    });
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-end ">
        {/* <Breadcrumb
          items={[
            // { name: "Store" },

            {
              name: "GRN",
              link: `/main/${officeId}/store/grn`,
            },

            { name: "Edit" },
          ]}
        /> */}
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              type="text"
              label="YPR"
              placeholder="YPR"
              Options={PR}
              formik={formik}
              name="prRef"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              formik={formik}
              name="poRef"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              disabled={true}
            />
            <NuInput
              type="text"
              label="Supplier"
              placeholder="Supplier"
              formik={formik}
              name="vendorName"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              disabled={true}
            />
            <NuInput
              type="text"
              label="Delivery Notes Ref."
              placeholder="Reference"
              Options={deliveryNotes}
              formik={formik}
              name="dnRef"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              label="Busy GRN No."
              type="text"
              formik={formik}
              placeholder="Busy GRN No."
              name="busyGrnNo"
              width="w-full md:w-1/2 lg:w-1/3"
              // isRequired={true}
            />
            <NuDate
              label="Received Date "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={GrnStatus}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refGrnStatus}
              displayProperty={"Status"}
              propertyName={"GrnStatus"}
            />

            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              new_width="w-full md:w-2/3"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full">
              <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                GRN Attachement :
              </p>
            </div>
            <div className="md:flex justify-between  px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];

                    return (
                      <div
                        className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          <CloseIcon color="white" height="8" width="8" />
                        </div>
                        <div
                          className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          <div className=" w-8 h-8">
                            {videotype.includes(Ext) ? <Video /> : null}

                            {Ext === ("docx" || "doc" || "txt") ? (
                              <Doc />
                            ) : Ext === ("xlsx" || "xls") ? (
                              <Xls />
                            ) : Ext === "txt" ? (
                              <Txt />
                            ) : Ext === "pdf" ? (
                              <Pdf />
                            ) : Ext === "pptx" ? (
                              <>No file</>
                            ) : (
                              <img src={imgs?.file} alt="#" width="120%" />
                            )}
                          </div>
                          <p className=" w-[50px] text-[11px] truncate">
                            {imgs?.file_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                />
              </div>
            </div>
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-2"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              {error && (
                <p className="text-xs font-semibold text-red-500">{error}</p>
              )}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-4">
                <p className="w-[5%]  text-left  text-white text-xs font-medium">
                  S.N.{" "}
                </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className="w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Ordered Qty
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  Received Qty
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Remaining Qty
                </p>

                <p className="w-[20%] text-center text-white text-xs font-medium">
                  This Delivery
                </p>

                <p className=" w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {dataa?.products?.length > 0 ? (
                <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                  {dataa?.products.map((List, index) => (
                    <div
                      key={index}
                      className="w-full py-2 flex justify-between px-4 odd:bg-white even:bg-slate-100"
                    >
                      <p className="w-[5%]  text-left   text-xs font-light py-1">
                        {index + 1}
                      </p>
                      <p className="w-[40%] text-left  text-xs font-light">
                        {List?.product_name}
                      </p>
                      <p className="w-[10%] text-center  text-xs font-light">
                        {List?.product_unit}
                      </p>
                      <p className="w-[15%] text-center  text-xs font-light">
                        {List?.orderedQty}
                      </p>
                      <p className="w-[20%] text-center  text-xs font-light">
                        {List?.receivedQty}
                      </p>

                      <p className="w-[15%] text-center  text-xs font-light">
                        {" "}
                        {Number(List?.orderedQty || 0) -
                          Number(List?.receivedQty || 0)}
                      </p>
                      <div className="w-[20%] text-center  text-xs font-light">
                        <button
                          type="button"
                          className="border rounded-full p-1"
                          onClick={() =>
                            handleDecrementDelivery(index, "thisDelivery")
                          }
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="w-[20%] text-center border  text-xs font-light"
                          name="thisDelivery"
                          value={List.thisDelivery || 0}
                          onChange={(e) => handleInputChangeDelivery(e, index)}
                        />
                        <button
                          type="button"
                          className="border rounded-full p-1"
                          onClick={() =>
                            handleIncrementDelivery(
                              index,
                              "thisDelivery",
                              List?.orderedQty,
                              List?.receivedQty
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className="w-[5%] text-center">
                        <button
                          onClick={() => handleDelete(List?.productId)}
                          type="button"
                          className="text-red-500 cursor-pointer  ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full text-center text-[#93949f]">
                  No Items here
                </div>
              )}
            </div>

            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel();
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditGRN;
