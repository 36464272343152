import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function DesignationManPower() {
    return (
        <SettingsMetaData propertyName={"ManPowerDesignation"} viewPropertyName={"Designation"} />
      );
}

export default DesignationManPower
