import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import ViewFiles from './FileView'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Dashboard from '../../Dashboard';
import { BackButton } from '../../../../Components/Utils/Buttons';
import NuAmount from '../../../../Components/Utils/NuAmount';
import NuDate from '../../../../Components/Utils/NuDate';

function ViewBids() {
  const location = useLocation();
  const data = location.state?.data;
  return (
    <div className="w-full   px-4 py-2  ">
    <div className="md:flex justify-end w-full">
      {/* <Breadcrumb
        items={[
          // { name: "Procurement" },
          {
            name: "Bids",
            link: "/main/1/bidding/bids/",
          },

          { name: "View" },
        ]}
      /> */}
      <div className=" flex justify-between items-center float-right">
        <BackButton />
      </div>
    </div>
    <div className=" w-full  h-[calc(100vh-145px)] overflow-y-auto  mt-2 rounded-lg bg-white shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] p-4 ">
      <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
      <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Reference No </p>
          <p className=' text-xs  text-mainColor-400'>{data?.referenceNo||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Project </p>
          <p className=' text-xs  text-mainColor-400'>{data?.projectName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Bid Value </p>
          <p className=' text-xs  text-mainColor-400'> <NuAmount
           value={data?.bidValue||0}/></p>
        </div>

        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Final Value </p>
          <p className=' text-xs  text-mainColor-400'> <NuAmount value={data?.finalValue||0}/></p>
        </div>

        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submission Due Date </p>
          <p className=' text-xs  text-mainColor-400'>{data?.dueDate !="" && data?.dueDate !="None" && <NuDate value={data?.dueDate} format="DD-MMM-YYYY" /> ||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submitted On </p>
          <p className=' text-xs  text-mainColor-400'>{data?.submittedOn !="" && data?.submittedOn !="None" && <NuDate value={data?.submittedOn} format="DD-MMM-YYYY" /> ||"-"}</p>
        </div>
       
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Client Name </p>
          <p className=' text-xs  text-mainColor-400'>{data?.clientName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Category</p>
          <p className=' text-xs  text-mainColor-400'>{data?.category||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submitted By </p>
          <p className=' text-xs  text-mainColor-400'>{data?.submittedByName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Status </p>
          <p className=' text-xs  text-mainColor-400'>{data?.biddingStatus||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Scope Of Work </p>
          <p className=' text-xs  text-mainColor-400'>{data?.scopeOfWork||"-"}</p>
        </div>
        {/* <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Comments </p>
          <p className=' text-xs  text-mainColor-400'>{data?.comments||"-"}</p>
        </div> */}
      </div>
      <div className='w-full'>
         <p className=' text-[13px] font-medium text-mainColor-400'>Attachment View</p>
         <div className=" w-full flex gap-2 items-center flex-wrap mt-2">
                {data?.attachments.length >0  &&
                  data?.attachments?.map((imgs, idx) => {
                    console.log("img",imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{ border: "1px solid gray" }}
                      >
                        <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                        <ViewFiles data={imgs} key={idx} />
                        </div>
                      </div>
                    );
                  })}
            </div>
      </div>
    </div>
  </div>
  )
}

export default ViewBids;