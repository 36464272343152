import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Modal from '../../../../Components/Utils/AttachmentView';
import { useState } from 'react';
import NuDate from '../../../../Components/Utils/NuDate';
import FilesView from '../../../../Components/Utils/FilesView';

const ViewShipmentTracking = () => {
  // const { patientsViewId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  const pageIndex = location.state?.pageIndex;
  const searchQuery = location.state?.searchQuery;
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    function handleImageClick(img) {
      setSelectedImage(img);
      setIsModalOpen(true);
    }
  return (
    <div className="w-full h-full py-2 px-4 mb-3 relative">
      <div className="flex justify-end ">
        {/* <Breadcrumb
         items={
          [{
            name: "Shipment Tracking",
            link: "/shipping/shipment-tracking"
          },
          {
            name: "View Shipment Tracking"
          }]
         }
        /> */}
        <div className=" flex gap-2">
        <Link to={'/shipping/shipment-tracking'} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
          <Link to={`/shipping/shipment-tracking/edit/${data?.shippingId||""}`} state={{data: data, from:`/shipping/shipment-tracking/view/${data?.shippingId||""}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
       
       <div className='grid items-center px-2 w-[70%]'>
          <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data.ref}</p>
                      </div>
              <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.createdAt ? (
    <NuDate value={data?.createdAt} format="DD-MMM-YYYY, hh:mm A" /> 
  ) : (
    "N/A"
  )}
         </p>
               
         </div>
     
                
      </div>  
      <div className="grid gap-2  justify-end w-[40%]">
            
               <div className="border rounded-md mr-3 border-[#579BB1] text-xs inline-flex w-auto  flex-shrink-0  justify-center">
            <p className="  bg-white  text-black  p-1 rounded-l-md  ">
              Status  </p><p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md"> {data.shipmentStatus || 'None'}</p>
             
              </div>
       
       
       </div>  
       </div> 
       <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8">
       <p className='w-full   text-xs font-semibold text-[#111537]'>Booking Details</p>

<div className=" w-full flex flex-wrap   border border-gary mt-2 px-2 rounded-lg ">
<div className=" w-full grid grid-cols-2 gap-x-8 ">
       <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Booking Date</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279]'>: { data?.bookingDate && NuDate({value: data?.deliveryDatebyPO, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                   
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Delivery Date by PO</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.deliveryDatebyPO && NuDate({value: data?.deliveryDatebyPO, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipped On</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.shippedOn && NuDate({value: data?.shippedOn, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                   
                   
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipping Method</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.modeOfShipment ||"None"}</p>
                  </div>
                  {/*   <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipped On</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.shippedOn || '-'}</p>
                  </div> */}
                    {/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipment Status</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.shipmentStatus || '-'}</p>
                  </div> */}
       
                       
                   <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipment Bill Number</p>
                    <p className='w-1/2 py-2 text-xs   text-[#606279] '> : {data?.shipmentBill || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Permit Required?</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.permitReq === true ? "Yes" : "No" || '-'}</p>
                  </div>
                  
                  
                    
                  {/*   <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>insuracePolicyNumber</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.insuracePolicyNumber || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>insurance Provider</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.insuranceProvider || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Insurance Type</p>
                    <p className='w-1/2 py-2   text-xs  text-[#606279] '>: {data?.insuranceType || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Coverage Start Date</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.coverageStartDate || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Coverage End Date</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.coverageEndDate || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Coverage Amount</p>
                    <p className='w-1/2 py-2   text-xs  text-[#606279] '>: {data?.coverageAmount || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Insurance Premium</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.insurancePremium || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Claim Contact Info</p>
                    <p className='w-1/2 py-2 font-medium  text-xs  text-[#606279] '>: {data?.claimContactInfo || '-'}</p>
                  </div> */}
          </div>
        </div>
        <p className='w-full pt-4 3x-4  text-xs font-semibold text-[#111537]'>Tracking Details</p>

<div className='w-full flex flex-wrap   border border-gary mt-2 px-2 rounded-lg "'>
<div className=" w-full grid grid-cols-2 gap-x-8 ">
{/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Tracking Url</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279]  truncate'>: {data?.trackingUrl || '-'}</p>
                  </div> */}
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>ETD</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.ETD && NuDate({value: data?.ETD, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>ETA</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.ETA && NuDate({value: data?.ETA, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>

 <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Estimated Shipping Cost</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '>: {data?.ESC || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Actual Shipping Cost</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '> : {data?.ASC || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Actual Arrival Date</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '> : {data?.actualArrivalDate && NuDate({value: data?.actualArrivalDate, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Delivery Person Name</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '> : {data?.deliveryPersonName || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Identity Type</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '> : {data?.identityType || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Identity Number</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '> : {data?.identityNumber || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Vehicle Type</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '> : {data?.vehicleType || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Vehicle Number</p>
                    <p className='w-1/2 py-2  text-xs  text-[#606279] '> : {data?.vehicleNumber || '-'}</p>
                  </div>
</div>

</div>
<div className="flex gap-4 mt-4">
          <div className="w-full ">
        <p className="  text-xs font-medium text-[#111537] p-1">
        Tracking Url
            </p>
        <div className="  border border-gary  h-12 overflow-y-scroll rounded-lg items-center">
           
            <p className="  text-xs p-2 break-words text-[#606279]">
              {data?.trackingUrl || "-"}
            </p>
          </div>
          </div>
          </div>
      {/* <div className="flex gap-4 mt-4">
      <div className="w-[50%] ">
          <p className=" p-1 text-xs font-medium text-[#111537]">
          Shipment Tracking Attachments
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
     

          <div className="w-full flex gap-2 flex-wrap ">
          {data?.attachments &&
                                    data?.attachments.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                    <FilesView fileView={imgs} key={idx}/>

                                           
                                        );
                                    })}
                               
                            </div>
                          
                        </div> 
                      
          </div> 
          </div> */}
          </div>
          </div>
          <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">
         <div className="w-full grid  border border-gary   rounded-lg ">
         <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Shipping Reference </p>

            </div>
            <div className='w-full flex px-2'>
                    <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>YPR</p>
                    <p className='w-[65%] py-2 truncate text-xs  text-[#606279] '>: {data?.prRef || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>PO</p>
                    <p className='w-[65%] py-2 truncate  text-xs  text-[#606279] '>: {data?.poRef || '-'}</p>
                  </div>
                    <div className='w-full flex px-2'>
                    <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Agent</p>
                    <p className='w-[65%] py-2 truncate text-xs  text-[#606279] '>: {data?.agentName || '-'}</p>
                  </div>
                  </div>
                  <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Attachments </p>
           <div className=" w-full flex flex-wrap overflow-y-scroll h-24 gap-2 mx-1">
              {data?.attachments &&
                                    data?.attachments.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                    <FilesView fileView={imgs} key={idx}/>

                                           
                                        );
                                    })}
                               
                           
         </div>
         </div>
</div>
</div>
</div>
          <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
      </div>
  
  )
};
export default ViewShipmentTracking;