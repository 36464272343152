import { Link, Outlet, useLocation } from "react-router-dom"
import Dasboard from "../../../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../../../Components/Utils/SearchBar";
import { CreateButton } from "../../../../../Components/Utils/Buttons";
import { GetAccess } from "../../../../../Components/Utils/roles";
import Breadcrumb from "../../../../../Components/Utils/Breadcrumb";

const CostingLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= true,
        ImportAccess= true,
        setOpen=()=>{},
        children,
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        officeId,
        projectId
    }
) =>{
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/project/costing/man-power/${officeId}/${projectId}`){
            setModule("Manpower")
        }else if(pathname == `/project/costing/project/${officeId}/${projectId}`){
            setModule("Project")
        }else if(pathname == `/project/costing/material/${officeId}/${projectId}`){
            setModule("Material")
        }else if(pathname == `/project/costing/others/${officeId}/${projectId}`){
            setModule("Others")
        }else if(pathname == `/project/costing/man-hour/${officeId}/${projectId}`){
            setModule("Man Hour")
        }else if(pathname == `/project/costing/equipment/${officeId}/${projectId}`){
            setModule("Equipment")
        }
    },[pathname])

    let LinkItem = [
        // {
        //     label:"Project",
        //     link:`/project/costing/project/${officeId}/${projectId}`,
        //     access:true,
        // },
        // {
        //     label:"Man Hour",
        //     link:`/project/costing/man-hour/${officeId}/${projectId}`,
        //     access:true,
        // },
        {
            label:"Manpower",
            link:`/project/costing/man-power/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Material",
            link:`/project/costing/material/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Others",
            link:`/project/costing/others/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Equipments",
            link:`/project/costing/equipment/${officeId}/${projectId}`,
            access:GetAccess(),
        },
    ]
    
    return(
        <div className=" w-full  h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-end full ">
            <div className=" w-full h-8 flex items-center   ">
            {
                LinkItem && LinkItem.map((item, i) => {   
                    return(
                        <>
                         {
                            item.access && (
                                // <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                                                                <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                                
                            )
                         }
                        </>
                    )
                })
            }
        </div> 

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              {/* <CreateButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <CreateButton label='Clear Filter'  handleClick={ClearFilter} />} */}
              {/* {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />} */}

              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
           
            </div>
           
           
           
            <div className=" w-full h-[calc(100vh-140px)] bg-white rounded-md  mt-2 ">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default CostingLayout;