import React from 'react'
import { BarChart } from 'recharts'


function InvoiceInOut({actualData,plannedData}) {
  return (
    <div className="w-full h-[45vh] p-2 rounded-lg bg-white" style={{ border: "1px solid #E9E8EC" }}>
      <div className='grid grid-cols-3'>
      <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px]">
        Invoice
        </p>
        <div className=' flex justify-center items-center gap-2'>
          <div className=' flex justify-center items-center gap-2'>
            <div className=' w-4 h-4 rounded-full bg-[#C7C0FF]'></div>
            <p className=' text-xs font-medium'>Invoice-In</p>
          </div>
          <div className=' flex justify-center items-center gap-2'>
          <div className=' w-4 h-4 rounded-full bg-[#3D3394]'></div>
          <p className=' text-xs font-medium'>Invoice-Out</p>
          </div>
        </div>
      </div>
      <div className=' w-full h-[90%]'>
      <BarChart actualData={actualData||[]} plannedData={plannedData||[]} />

      </div>
    </div>
  )
}

export default InvoiceInOut