// import React, { useEffect, useState } from "react";
// import SearchBar from "../../../../../../Components/Utils/SearchBar";
// import { Link, useLocation, useParams } from "react-router-dom";
// import axios from "../../../../../../Services/axiosCommon";
// import { useFormik } from "formik";
// import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
// import Pagination from "../../../../../../Components/Utils/Pagenation";
// import ViewMore from "./ViewMore";

// import NuAmount from "../../../../../../Components/Utils/NuAmount";
// import * as yup from 'yup';
// import { GetAccess } from "../../../../../../Components/Utils/roles";
// import { NuDate, NuSelect } from "../../../../../../Components/Utils/Input";
// import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
// import CustomDataTable from "../../../../../../Components/Others/CustomDataTable";
// import axiosInstance from "../../../../../../Services/axiosCommon";
// import UseGetDataSearchByPro from "../../../../../../Components/Hooks/useFetchDataByPRO";
// import { useToast } from "../../../../../../Components/Others/ToastServices";
// import useFetchMDIndex from "../../../../../../Components/Hooks/useFetchMDIndex";
// import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
// import { Button } from "antd";

// function IssuedIndex(id) {
//   const { projectId } = useParams();
//   const toast = useToast();
//   const location = useLocation();
//   const {officeId , estmationId} = useParams();
//   console.log(officeId,estmationId,"estimationId");
  
//   const currentPage= location.state?.currentPage
//   const searchQuery= location.state?.searchQuery
//   const [page, setPage] = useState(currentPage||1)
//   const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
//   const [filter, setFilter] = useState(false);
//   const [showFilterDialog, setShowFilterDialog] = useState(false);
//   const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
//   const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
//   const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)

//   const [filterProps, setFilterProps] = useState({
//     category:"",
//     subCategory:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   });
//   const [params, setParams] = useState({
//     page: page,
//     per_page: pageCount,
//     search:searchQuery||"",
//     category:"",
//     subCategory:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   });
//   const clearFilter = ()=> {
//     setParams({...params, category:"",
//     subCategory:"",
//     brand:"",
//     manufacturer:"",
//     underPromotion:"",
//     unit:""
//   })
//   formik.values.category=""
//   formik.values.subCategory=""
//   formik.values.brand=""
//   formik.values.manufacturer=""
//   formik.values.underPromotion=""
//   formik.values.unit=""


//       setFilter(false)
//   }

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: filterProps,
//     validateOnChange: false,
//     validateOnBlur: false,
//     validationSchema: yup.object({
//       // email: yup.string().required('Email is required!'),
//     }),
//     onSubmit: async (value, { resetForm }) => {
//       console.log("ffff",value);
//       setParams({...params,category:value.category, subCategory:value.subCategory,brand:value.brand,manufacturer:value.manufacturer,underPromotion:value.underPromotion,unit:value.unit})
//       closeFilterDialog();
//       setFilter(true)
//       setFilterProps({
//         category:"",
//         subCategory:"",
//         brand:"",
//         manufacturer:"",
//         underPromotion:"",
//         unit:""
//       });
//     }
//   })

//   const { data, totalCount, getData,dataLoading } = UseGetDataSearchByPro( `/bidding/${officeId}/details`,params);
//   const defaultHeader = [
//     {
//       title: "Id",
//       width: "5%",
//       isLink: true,
//       // id: "serial_number",
//       // link: `/main/${officeId}/bidding/bids/view/`,  
//       field: "pettycashExpenseId",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Received Amt.",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "receivedAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
    
//     // {
//     //   title: "Bid Due Date",
//     //   type:"date",
//     //   width: "15%",
//     //   field: "dueDate",
//     //   textAlign: "center",
//     //   rowAlign: "center",
//     // },
//     {
//       title: "Date",
//       width: "10%",
//       type:"date",
//       field: "date",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Paid To",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "paidTo",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Bill Amt.",
//       width: "10%",
//       field: "billAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Balance Amt.",
//       width: "10%",
//       field: "balanceAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Balance Settled",
//       width: "5%",
//       field: "balanceSettled",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Action",
//       width: "5%",
//       field: "action",
//       textAlign: "center",
//       rowAlign: "center",
//       contents:{
//         // fetcher:getData,
//         // id:"biddingId",
//         removeValue:"pettycashExpenseId",
//         url:`/issued/`,
//         moduleName:"Issued",
//         from:`/project/petty-cash/issued/${officeId}/${projectId}`,
//         editLink:`/project/petty-cash/issued/${officeId}/${projectId}/edit/`,
//         viewLink:`/project/petty-cash/issued/${officeId}/${projectId}/view/`,
//         // projectLink:`boq`
//       }
//     },
//   ];
//   const availableHeaders = [
//     {
//       title: "Id",
//       width: "5%",
//       isLink: true,
//       // id: "serial_number",
//       // link: `/main/${officeId}/bidding/bids/view/`,  
//       field: "pettycashExpenseId",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Received Amt.",
//       width: "10%",
//       isLink: true,
//       // id: "project_name",
//       // link: `/main/${officeId}/bidding/bids/view/`,
    
//       field: "receivedAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
    
//     {
//       title: "Date",
//       type:"date",
//       width: "15%",
//       field: "date",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Paid To",
//       width: "10%",
//       isLink: true,
//       // id: "biddingId",
//       // link: `/main/${officeId}/bidding/bids/view/`,    
//       field: "paidTo",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Bill Amt.",
//       width: "10%",
//       type:"date",
//       field: "billAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Balance Amt.",
//       width: "10%",
//       field: "balanceAmount",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     // {
//     //   title: "Bill Amount",
//     //   width: "10%",
//     //   field: "billAmount",
//     //   textAlign: "center",
//     //   rowAlign: "center",
//     // },
//     {
//       title: "Balance Settled",
//       width: "5%",
//       field: "balanceSettled",
//       textAlign: "center",
//       rowAlign: "center",
//     },
//     {
//       title: "Action",
//       width: "5%",
//       field: "action",
//       textAlign: "center",
//       rowAlign: "center",
//       contents:{
//         // fetcher:getData,
//         // id:"biddingId",
//         removeValue:"pettycashExpenseId",
//         url:`/issued/`,
//         moduleName:"Issued",
//         from:`/project/petty-cash/issued/${officeId}/${projectId}`,
//         editLink:`/project/petty-cash/issued/${officeId}/${projectId}/edit/`,
//         viewLink:`/project/petty-cash/issued/${officeId}/${projectId}/view/`,
//         // projectLink:`boq`
//       }
//     },
//   ];

//   useEffect(()=>{
//     setParams({...params,page:page,per_page:pageCount})
//    },[page,pageCount])

//     const closeFilterDialog = () => {
//       setShowFilterDialog(false);
//     };
//        const [showImportModal, setShowImportModal] = useState(false);

//     const handleImportClick = () => {
//       setShowImportModal(true);
//     };
  
//     const closeModal = () => { 
//       setShowImportModal(false);
//     };


//     const [showEdit, setShowEdit] = useState(false)
//     const [showDelete, setShowDelete] = useState(false)
//     const [listofNumbers, setListofNumbers] = useState([])
//     const [fullList, setFullList] = useState([])
  
//     const selectOneFunction = (value) =>{ 
//       let NewId;
//       if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
//         NewId = listofNumbers.filter(Temp=> Temp !== value);
//         setListofNumbers(NewId)
//       }else{
//         setListofNumbers([...listofNumbers,value])
//       }
//     }
  
//     const selectMultipleFunction = (e) =>{ 
//   console.log(e.target.checked);
//   if(e.target.checked){
//     setListofNumbers(fullList)
//   }else{
//     setListofNumbers([])
//   }
  
//     }

//     const handleMultiDelete = (data) => {
//        axiosInstance.delete(`items/multi-delete`,{
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         data: {"ids" : data},
//       })
//        .then(res => {
//         if (res.data?.status === true) {
//           if(res?.data?.not_deleted_ids.length<=0){
//             toast.open({
//               type: "success",
//               message: "Success",
//               description: res.data?.msg || "Deleted Successfully."
//             });
//             getData();
//             setShowDelete(false)
//           }else{
//             toast.open({
//               type: "warning",
//               message: "Success",
//               description: `${res.data?.msg} \n Some Products has dependenies`|| "Deleted Successfully."
//             });
//             getData();
//             setShowDelete(false)
//           }

//         } else if (res.data?.status === false && res.data?.error) {
//           toast.open({
//             type: "error",
//             message: "Error",
//             description: res.data.error || "Delete UnSuccessfull"
//           });
//         } else {
//           toast.open({
//             type: "warning",
//             message: "Warning",
//             description: res.data?.msg || "Warning."
//           });
//         }
//       })
//       .catch(err => {
//         console.error(err);
//         toast.open({
//           type: "error",
//           message: "Error",
//           description: "Network Error."
//         });
//       });
//     }
  
//     useEffect(()=>{
//       let tempArr = []
//       data && data.forEach(i=>{
//         console.log("I",i?.productId);
//         tempArr.push(i?.productId)
//       });
//       setFullList(tempArr)
//     },[data])
//   return (
//     <div className='w-full h-full px-4 py-2'>
//       <div className='w-full flex justify-between items-center'>
//       <Breadcrumb
//           items={[
           
//             { name: "Project" },
//             { name: "Issued" },
//           ]} />
//         <div className=' flex gap-2'>
//         <SearchBar
//               handleChange={(e) =>{
//                 setParams({ ...params, search: e.target.value, page:1 });
//                 setPage(1)
//               }}
//               value={params.search||searchQuery}
//             />
//              <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
//           {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
//           {/* {GetRole() && <ImportButton handleClick={handleImportClick} />} */}
//           {/* {GetAccess("items_create") && */}
//           <Link to={`/project/petty-cash/issued/${officeId}/${projectId}/create`}><CreateButton label='Issued' /></Link>
//           {/* // } */}
//         </div>
//       </div>
//       <CustomDataTable 
//       fetcher={getData}
//         data={data} 
//         availableHeaders={availableHeaders} 
//         defaultHeader={defaultHeader}
//         bodyHeight='max-h-[calc(100vh-260px)]'
//         height='h-[calc(100vh-145px)]'
//         total={totalCount}
//         pageIndex={page}
//         setPageIndex={setPage}
//         dataCount={pageCount}
//         setDataCount={setPageCount}
//         title='Issued List' 
//         storageName="issuedTable"
//         selectOneFunction={selectOneFunction}
//         setShowDelete={setShowDelete}
//         showDelete={showDelete}
//         setShowEdit={setShowEdit}
//         showEdit={showEdit}
//         listofNumbers={listofNumbers}
//         hasMultiEdit={true}
//         selectMultipleFunction={selectMultipleFunction}
//         fullList={fullList}
//         getById="biddingId"
//         showFieldFalse={false}
//         editAccess="items_edit"      
//         deteleAccess="items_delete"
//         dataLoading={dataLoading}   
//         searchQuery={params?.search!=""?params?.search:""}
//         />
//         {showFilterDialog && (
                          
                          
//                           <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
//                             <div className="animate-scale-in min-w-[50%] max-w-[70%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
//                               <h2 className="text-xl mb-4">Filter Issued</h2>
//                               <form onSubmit={formik.handleSubmit}> 
//                                  <div className="flex justify-start flex-wrap mb-4">
//                                  <NuDate
//                      label=" Date From"
//                      // options={status}
//                      formik={formik}
//                      placeholder="Choose"
//                      name="dateFrom"
//                      width=" w-full"
//                    />
//                    <NuDate
//                      label=" Date To"
//                      // options={status}
//                      formik={formik}
//                      placeholder="Choose"
//                      name="dateTo"
//                      width=" w-full"
//                    /> 
             
                         
//                          {/* <button
//                             type="submit"
//                            // size="large"
//                            className="w-[95%] bg-[#048178] text-white  mx-auto py-1 border-none outline-none rounded-md"
//                          >
//                            Filter 
//                          </button> */}
//                                  </div>
//                                 <div className="flex justify-end gap-2">
//                                   <CancelButton handleClick={closeFilterDialog} />
//                                   <SubmitButton name='Filter' />
//                                 </div>
//                                </form> 
//                             </div>
//                           </div>
//                         )}
                  
//     </div>
//   )
// }

// export default IssuedIndex;






import React, { useEffect, useState } from "react";
import axios from "../../../../../../Services/axiosCommon";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import { Button, Modal, Select } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNumber } from "../../../../../../Context/ProjectIdContext";
// import ProgressLayout from "../ProgressLayout";
import ViewMore from "./ViewMore";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import PettyCashLayout from "../PettyCashLayout";
import { GetAccess } from "../../../../../../Components/Utils/roles";
import { NuDate } from "../../../../../../Components/Utils/Input";

function IssuedIndex(id) {
  const { officeId } = useParams();
  const { projectId } = useParams();
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  // const { number, updateNumber } = useNumber();
  const [percentOpen, setPercentOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [notFirst, setNotFirst] = useState(false);
  const [filterData, setFilterData] = useState({
    dateFrom:"",
    dateTo:"",
  });
  const [afterFilter, setAfterFilter] = useState({});
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function getProject() {
    await axios
      .get(`/Team/getUserProjectId`)
      .then(async (res) => {
        // if (number != 0) {
        //   setFirstProject(number);
        // } else if (projectTask) {
        //   setFirstProject(projectTask);
        // } else {
        //   res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
        //   updateNumber(res?.data?.data[0]?.ProjectId);
        // }
        // let list = res?.data?.data || [];
        // let finalData = [];
        // (await res?.data?.data) &&
        //   list.map((item, idx) => {
        //     finalData.push({
        //       value: item.ProjectId,
        //       label: item.ProjectName,
        //     });
        //   });
        // setProjectList(finalData);

        // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
        // console.log('====',res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getStatus = () => {
    axios
      .get(`/Metadata/taskstatus`)
      .then((res) => {
        let data = res.data?.data?.pettycashExpenseDetails?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.pettycashExpenseDetails?.keyValues && setStatus(tempData);
      })
      .catch((error) => {
        console.log("dddd");
      });
  };

  const getPriority = () => {
    axios
      .get(`/Metadata/taskpriority`)
      .then((res) => {
        let data = res.data?.data?.pettycashExpenseDetails?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.pettycashExpenseDetails?.keyValues && setPriority(tempData);
      })
      .catch((error) => {
        console.log("dddd");
      });
  };

  useEffect(() => {
    getStatus();
    getProject();
    getPriority();
  }, []);

  function SearchData(e) {
    setNotFirst(true);
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(
          `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data?.pettycashExpenseDetails);
          setTotalProject(res.data?.data?.totalRecords);
        })
        .catch((err) => console.log(err));
    } else {
      getTask(firstProject);
    }
  }

  function SearchPageDate() {
    axios
      .get(
        `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } }
      )
      .then((res) => {
        setData(res.data?.data?.pettycashExpenseDetails);
        setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((err) => console.log(err));
  }

  function handleProjectChange(e) {
    setFirstProject(e);
    // updateNumber(e);
  }

  function getTask() {
    axios
      .get(`/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data?.pettycashExpenseDetails);
        setTotalProject(res.data.data.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log("tas",totalTasks);

  useEffect(() => {
    if (searchQuery == "") {
      getTask();
    }
  }, [searchQuery]);



  useEffect(() => {
    // console.log("jj",projectNum);
    if (projectTask) {
      setFirstProject(projectTask);
      // console.log("gf", firstProject);
    }
  }, [projectTask]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // mobileNo: yup
      //   .string()
      //   // .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(10, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value);
      setIsFilter(true);
      axios
        .get(
          `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
          {
            params: {
              // status: `${value.status}`,
              // SDF: `${value.SDF}`,
              // EDT: `${value.EDT}`,
              // priority: `${value.priority}`,
              dateFrom: `${value.dateFrom}`,
              dateTo: `${value.dateTo}`,
            },
          }
        )
        .then((res) => {
          setFilterOpen(false);
          setData(res?.data?.data?.pettycashExpenseDetails);
          setTotalProject(res.data?.data?.totalRecords);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  function FilterPage() {
    axios
      .get(
        `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
        {
          params: {
            // status: `${afterFilter.status}`,
            // SDF: `${afterFilter.SDF}`,
            dateFrom: `${afterFilter.dateFrom}`,
            dateTo: `${afterFilter.dateTo}`,
            // EDT: `${afterFilter.EDT}`,
            // priority: `${afterFilter.priority}`,
          },
        }
      )
      .then((res) => {
        setData(res?.data?.data?.pettycashExpenseDetails);
        setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((err) => console.log(err));
  }

  function ClearFilter() {
    formik.resetForm({});
    getTask();
    setIsFilter(false);
  }

  useEffect(() => {
    if (isFilter) {
      FilterPage();
    } else if (searchQuery != "") {
      SearchPageDate();
    } else {
      getTask();
    }
  }, [pageIndex, dataCount]);

  return (
    <>
      <PettyCashLayout
        setOpen={setOpen}
        setSearchQuery={setSearchQuery}
        SearchData={SearchData}
        isFilter={isFilter}
        setFilterOpen={setFilterOpen}
        ClearFilter={ClearFilter}
        CreateAccess={GetAccess()}
        ImportAccess={GetAccess()}
        officeId={officeId}
        projectId={projectId}
      >
         {/* <div className="w-full h-[calc(100vh-200px)]"> */}
        <div className=" w-full h-[calc(100vh-200px)] rounded-md overflow-y-auto">
          <table
            className="table-auto w-full p-3"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <thead className=" w-full bg-white p-3 mt-4">
              <tr className="text-xs border-b  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-3 ${header.width} ${
                      header?.isLeft ? "text-left" : "text-center"
                    } font-medium text-xs 2xl:text-sm text-[#4F4768]`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {data &&
                data.map((item, i) => (
                  <tr className=" w-full " key={i}>
                    <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                      {item?.pettycashExpenseId || "#"}
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/petty-cash/issued/view/${id}`} state={{data:item}}>  <NuAmount value={item?.receivedAmount || 0} /></Link>
                   
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/petty-cash/issued/view/${id}`} state={{data:item}}>   {(item?.date != "" && item?.date != "None" && (
                        <NuDate value={item?.date} format="DD-MMM-YYYY" />
                      )) ||
                        "-"}</Link>

                    
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                     
                      {item?.paidTo || "-"}
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <NuAmount value={item?.billAmount || 0} />
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <NuAmount value={item?.balanceAmount || 0} />
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                      {item?.balanceSettled == true? "Yes" : "No" || "0.0"}
                    </td>
                    <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                      <div className=" w-full flex justify-center items-center">
                        <ViewMore id={item?.pettycashExpenseId} getData={getTask} data={item} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        

        <div className=" h-6 my-2">
          <Pagination
            total={totalTasks}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            dataCount={dataCount}
            setDataCount={setDataCount}
          />
        </div>
        {/* </div> */}
      </PettyCashLayout>
      <Modal
        open={filterOpen}
        title={"Filter Issued"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <NuSelect
              label="Priority"
              options={priority}
              formik={formik}
              placeholder="Choose"
              name="Priority"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />*/}
            <NuDate
              label=" Date From"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateFrom"
              width=" w-full"
            />
            <NuDate
              label=" Date To"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateTo"
              width=" w-full"
            /> 
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="filterStatus"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#048178] text-white  mx-auto"
            >
              Filter
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default IssuedIndex;

const header = [
  {
    headone: "ID",
    width: "w-[3%]",
    // isLeft: true,
  },
  {
    headone: "Amount",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Date",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Paid To",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Bill Amount",
    width: "w-[8%]",
    isLeft: true,
  },
  {
    headone: "Balance Amount",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Balance Settled",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];

