import { useEffect, useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import NuDate from "../../../../Components/Utils/NuDate";
import { useFormik } from "formik";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import Dasboard from "../../../../Components/Icons/Dasboard";
import { Link } from "react-router-dom";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import { Button, Modal } from "antd";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { NuSelect } from "../../../../Components/Utils/Input";



function TaskReport() {
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter] = useState(false)
  const [data,setData] = useState([])
  const [xlsData,setXlsData] = useState([])
  const [filterData,setFilterData]= useState({
    start_date:"",
    end_date:"",
    status: "",
    priority: "",
  });



  function getAllData(){
    axiosInstance.get(`/report/task`).then(res=>{
      let forMap = res?.data?.data;
      let tempArr = []
      forMap && forMap.forEach((item,index)=>{
      let tempData = {
        "Sl.No":index+1,
        "Task":item.projectName,
        "Assigned To":`${[...item?.assignedTo]}`,
        "Start Date":item?.start_date  && item?.start_date !="" && item?.start_date !="None" && NuDate({value:item?.start_date, format:"DD-MMM-YYYY"}),
        "Due Date":item?.end_date  && item?.end_date !="" && item?.end_date !="None" && NuDate({value:item?.end_date, format:"DD-MMM-YYYY"}),
        "Duration":`${((item?.start_date != "None" || item?.start_date != " ") && (item?.end_date != "None" || item?.end_date != " ") && DateDiff(item?.start_date, item?.end_date))  ||"0"} ${((item?.start_date != "None" || item?.start_date != " ") &&(item?.end_date != "None" || item?.end_date != " ") && DateDiff(item?.start_date, item?.end_date)>=2?" days":" day" )}`,
        "Priority":item?.priority,
        "Progress	":50,
        "Status":item?.status
      }
      tempArr.push(tempData)
      })
      setXlsData(tempArr);
      res.data?.data && setData(res?.data?.data)
    }).catch(err=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    getAllData()
  },[])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   mobileNo: yup
    //     .string()
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    // }),
    onSubmit: async (value, { resetForm }) => {
    
      setIsFilter(true)
      axiosInstance
      .get(`/report/task?start_date=${value.start_date}&end_date=${value.end_date}&status=${value.status}&priority=${value.priority}`)
      .then((res) => {
        let forMap = res?.data?.data;
      let tempArr = []
      forMap && forMap.forEach((item,index)=>{
      let tempData = {
        "Sl.No":index+1,
        "Task":item.projectName,
        "Assigned To":`${[...item?.assignedTo]}`,
        "Start Date":item?.start_date  && item?.start_date !="" && item?.start_date !="None" && NuDate({value:item?.start_date, format:"DD-MMM-YYYY"}),
        "Due Date":item?.end_date  && item?.end_date !="" && item?.end_date !="None" && NuDate({value:item?.end_date, format:"DD-MMM-YYYY"}),
        "Duration":`${((item?.start_date != "None" || item?.start_date != " ") && (item?.end_date != "None" || item?.end_date != " ") && DateDiff(item?.start_date, item?.end_date))  ||"0"} ${((item?.start_date != "None" || item?.start_date != " ") &&(item?.end_date != "None" || item?.end_date != " ") && DateDiff(item?.start_date, item?.end_date)>=2?" days":" day" )}`,
        "Priority":item?.priority,
        "Progress	":50,
        "Status":item?.status
      }
      tempArr.push(tempData)
      })
      setXlsData(tempArr);
        setFilterOpen(false)
        setData(res.data?.data);
        

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });


  const {value:Priority} = useFetchMataData(`/Metadata/taskpriority`)
  const {value:Status} = useFetchMataData(`/Metadata/taskstatus`)
  
  function ClearFilter(){
    formik.resetForm({})
    getAllData()
    setIsFilter(false)
  }


  function DateDiff(createDate, expiryDate) {
    createDate = new Date(createDate);
    expiryDate = new Date(expiryDate);
    let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays + 1;
  }

  return (
    <div className=" w-full h-[90vh] px-4 py-2 overflow-hidden">
      <div className="md:flex justify-between w-full">
      <Breadcrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<Link to='/reports'><p className=' text-[12px] '>Reports Lists</p></Link>)},{title:(<p className=' text-[12px] py-[1px]'>Tasks</p>)}]} />
      <div className='  flex justify-center items-center float-right'>
      <div className=" pb-2 w-full flex justify-center gap-2 items-center ">
      <div className=" flex justify-center items-center gap-3 md:mr-6">
      <CreateButton label="Filter" handleClick={()=>setFilterOpen(true)}   />
      {isFilter&& <CreateButton label='Clear Filter' handleClick={ClearFilter} />}
      {/* <ExportXls csvData={xlsData} fileName="Task Report"  />
      <CreateButton label="PDF" handleClick={()=>{}}   /> */}
      <BackButton/>
      </div>
      </div>
      </div>
      </div>

      <div className=" w-full h-[98%] pb-6  overflow-y-auto">
      <table className="table-auto w-full" style={{ border: 'none', borderCollapse: 'collapse' }}>
      <thead className=" w-full bg-[#2A475E]">
      <tr className="text-xs  font-medium   py-2 ">
      {header.map((header, i) => (
                <th className={`py-2 ${header.width} font-normal ${header?.isLeft?"text-left":"text-center"} text-xs px-2 text-white`} key={i} style={{ border: 'none', borderCollapse: 'collapse' }}>
                  {header.headone}
                </th>
              ))}
      </tr>
      </thead>
      <tbody>
        {
          data.map((item, i)=>{
            return (
              <tr className='`w-[100%] text-xs bg-[#F6F9FF]' key={i}>
              <td className=' px-2 py-2'>{i+1}</td>
              <td className=' px-2 py-2'>{item?.task_name||""}</td>
              <td className=' px-2 py-2'>{item?.assignedTo.map((i,indx)=>{
                return (<p key={indx}>{i}</p>)
              })||""}</td>
              <td className=' px-2 py-2'>{item?.start_date !="" && item?.start_date !="None" && <NuDate value={item?.start_date} format="DD-MMM-YYYY" />||""}</td>
              <td className=' px-2 py-2'>{item?.end_date !="" && item?.end_date !="None" && <NuDate value={item?.end_date} format="DD-MMM-YYYY" />||""}</td>
              <td className=' px-2 py-2'>
              {((item?.start_date != "None" || item?.start_date != " ") &&
            (item?.end_date != "None" || item?.end_date != " ") &&
            DateDiff(item?.start_date, item?.end_date))  ||
            "0"}
                      {((item?.start_date != "None" || item?.start_date != " ") &&
            (item?.end_date != "None" || item?.end_date != " ") &&
            DateDiff(item?.start_date, item?.end_date)>=2?" days":" day" )}
              </td>
              <td className=' px-2 py-2'>{item?.priority||""}</td>
              <td className=' px-2 py-2'>{item?.progress||""}</td>
              <td className=' px-2 py-2'>{item?.status||""}</td>
            </tr>
            )
          })
        }

      </tbody>
      </table>
      </div>
      <Modal
        open={filterOpen}
        title={"Filter All Tasks"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={()=>setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuDate
              label="Start Date"
              formik={formik}
              placeholder="Choose"
              name="start_date"
              width=" w-1/2"
            />
            <NuDate
              label="End Date"
              formik={formik}
              placeholder="Choose"
              name="end_date"
              width=" w-1/2"
            />

            <NuSelect
              label="Status"
              options={Status||[]}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
            />
            <NuSelect
              label="Priority"
              options={Priority||[]}
              formik={formik}
              placeholder="Choose"
              name="priority"
              width=" w-1/2"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default TaskReport;


const header = [
  {
    headone: "Sl.No",
    width: "w-[3%] ",
    isLeft: true,
  },
  {
    headone: "Task",
    width: "w-[13%] ",
    isLeft: true,
  },
  {
    headone: "Assigned To",
    width: "w-[15%]",
    isLeft: true,
  },
  {
    headone: "Start Date",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Due Date",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Duration",
    width: "w-[7%]",
    isLeft: true,

  },
  {
    headone: "Priority",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Progress",
    width: "w-[7%]",
    isLeft: true,

  },
  {
    headone: "Status",
    width: "w-[8%]",
    isLeft: true,

  },
];