import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/Others/DataTable";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link, useLocation, useParams } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import Dashboard from "../../Dashboard";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { NuDate } from "../../../../Components/Utils/Input";
import Importdoc from "./Importdoc";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditINC from "./MultiEditINC";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";
import SalesLayout from "../SalesLayout";
import { useNumber } from "../../../../Context/ProjectIdContext";

function InvoiceIncomingIndex() {
  const {officeId} =useParams();
  const {number} = useNumber()

  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:ModeOfPayment} = useFetchMDIndex(`/dropdown/dropdown-details/`,'ModeOfPayment_invoicesIncoming',showFilterDialog)
  const {value:Division} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Division_invoicesIncoming',showFilterDialog)
  const {value:Status} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Status_invoicesIncoming',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    division:"",
    status:"",
    modeOfPayment:"",
    fromDate:"",
toDate:"",
fromDueDate:"",
toDueDate:"",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",

    division:"",
    status:"",
    modeOfPayment:"",
fromDate:"",
toDate:"",
fromDueDate:"",
toDueDate:"",
  });
  const clearFilter = ()=> {
    setParams({...params,  division:"",
    status:"",
    modeOfPayment:"",
    fromDate:"",
    toDate:"",
    fromDueDate:"",
    toDueDate:"",})
    formik.values.status=""
    formik.values.division=""
    formik.values.modeOfPayment=""
    formik.values.fromDate=""
    formik.values.toDate=""
    formik.values.fromDueDate=""
    formik.values.toDueDate=""


      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,modeOfPayment:value.modeOfPayment, division:value.division,status:value.status,fromDate:value.fromDate,toDate:value.toDate,fromDueDate:value.fromDueDate,toDueDate:value.toDueDate})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        division:"",
        status:"",
        modeOfPayment:"",
        fromDate:"",
toDate:"",
fromDueDate:"",
toDueDate:"",
      });
    }
  })

  const { data, totalCount, getData,dataLoading } = UseGetDataSearch( `/invoices-incoming/${number}/project`,params);
  console.log(data, "dddd");

  const defaultHeader = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "invoiceInId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Reference  ",
      width: "9%",
      isLink: true,
      id: "invoiceInId",
      link: `/main/${officeId}/sales/invoice_incoming/view/`,
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "YPR",
      width: "9%",
      isLink: true,
      id: "invoiceInId",
      link: `/main/${officeId}/sales/invoice_incoming/view/`,
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "PO NO.",
      width: "9%",
      isLink: true,
      id: "invoiceInId",
      link: `/main/${officeId}/sales/invoice_incoming/view/`,
      field: "poref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Supplier",
      width: "9%",
      field: "supplierName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Invoice Date",
      width: "9%",
      type:"date",
      field: "date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Amount",
      width: "9%",
      field: "amount",
      type:"amount",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "invoiceInId",
        removeValue: "Invoices-Incoming",
        url: '/invoices-incoming/',
        moduleName: "invoice-incoming",
        from: `/main/${officeId}/sales/invoice_incoming`,

        editLink: `/main/${officeId}/sales/invoice_incoming/edit/`,
        viewLink: `/main/${officeId}/sales/invoice_incoming/view/`,
      },
    },
  ];

  let availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "invoiceInId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Reference  ",
      width: "9%",
      isLink: true,
      id: "invoiceInId",
      link: `/main/${officeId}/sales/invoice_incoming/view/`,
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "YPR",
      width: "9%",
      isLink: true,
      id: "invoiceInId",
      link: `/main/${officeId}/sales/invoice_incoming/view/`,
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "PO NO.",
      width: "9%",
      isLink: true,
      id: "invoiceInId",
      link: `/main/${officeId}/sales/invoice_incoming/view/`,
      field: "poref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Supplier ",
      width: "9%",
      field: "supplierName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Supplier Email",
      width: "9%",
      field: "supplierEmail",
      type: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Invoice Date",
      width: "9%",
      field: "date",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Amount",
      width: "9%",
      field: "amount",
      type:"amount",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Invoice Reference ",
      width: "9%",
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Recived Date",
    //   width: "9%",
    //   field: "date",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Tax",
      width: "9%",
      field: "tax",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Total Amount",
      width: "9%",
      field: "totalAmount",
      type:"amount",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Mode Of Payment",
      width: "9%",
      field: "modeOfPayment",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Payment DueDate",
      width: "9%",
      type:"date",
      field: "paymentDueDate",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Payment ReleasedOn",
      width: "9%",
      type:"date",
      field: "paymentReleasedOn",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Division",
    //   width: "9%",
    //   field: "division",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Comments",
    //   width: "9%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
  

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "invoiceInId",
        removeValue: "Invoices-Incoming",
        url: '/invoices-incoming/',
        moduleName: "invoice-incoming",
        from: `/main/${officeId}/sales/invoice_incoming`,
        editLink: `/main/${officeId}/sales/invoice_incoming/edit/`,
        viewLink: `/main/${officeId}/sales/invoice_incoming/view/`,
      },
    },
  ];

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

   const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }

  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.invoiceInId);
      tempArr.push(i?.invoiceInId)
    });
    setFullList(tempArr)
  },[data])

  const [valueOfSearch,setValuesofSearch] = useState("")
  function searchFunction(e) {

    
    setValuesofSearch(e?.target?.value)
    setParams({ ...params, search: e?.target?.value, page:1 });
    setPage(1)
  }

  return (
    <>
    <SalesLayout 
    //  setOpen={closeModal}
    searchFun={searchFunction}
    searchValue={valueOfSearch}
     getData={getData}
     isFilter={filter}
     setFilterOpen={setShowFilterDialog}
     ClearFilter={clearFilter}
     CreateAccess={GetAccess("customer_quote_create")}
    //  ImportAccess={GetAccess("customer_quote_create")}
     officeId={officeId}
     // projectId={projectId}
       >
    <div className="w-full h-full ">
      {/* <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Invoice-Incoming",
            },
          ]}
        />

        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
          {/* {GetAccess("suppliers_create") &&   <CreateButton label="Import"  handleClick={handleImportClick} />} */}
         {/* {GetAccess("invoice_in_create") &&  <Link to={`/main/${officeId}/sales/invoice_incoming/create/`}>
            <CreateButton label="Incoming" />
          </Link>}
        </div>
      </div> */}

      <CustomDataTable
        fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
       bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        // title="Invoice-Incoming List"
        storageName="Invoice-IncomingTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="invoiceInId"
        editAccess="invoice_in_edit"      
        deteleAccess="invoice_in_delete" 
        dataLoading={dataLoading}
        searchQuery={params?.search!=""?params?.search:""}

      />
      </div>
      </SalesLayout>
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Invoice-Incoming</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Date</p>
            <div className="flex justify-between  w-full ">
            
           <NuDate
                label="From "
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="fromDate"
                width="w-full "
              />
               <NuDate
                label="To "
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="toDate"
                width="w-full "
              />
              </div>
              <p className="py-2 px-6 text-m">Due Date</p>
              <div className="flex justify-between  w-full ">
               <NuDate
                label="From "
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="fromDueDate"
                width="w-full "
              />
               <NuDate
                label="To "
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="toDueDate"
                width="w-full "
              />
              </div>
                    <FormikSelectTwo
              label="Mode Of Payment"
              placeholder="Mode Of Payment"
              formik={formik}
              Options={ModeOfPayment}
              name="modeOfPayment"
              width="w-full "
            />

        {/* <FormikSelectTwo
              type="text"
              label="Division"
              placeholder="Choose"
              Options={Division}
              formik={formik}
              name="division"
              width="w-full "
            /> */}
           
            <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Choose"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full"
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
          {showEdit && (<MultiEditINC data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from YPR List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </>
  );
}
export default InvoiceIncomingIndex;
