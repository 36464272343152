import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function DivisionMaterial() {
    return (
        <SettingsMetaData propertyName={"MatrialDivision"} viewPropertyName={"Division"} />
      );
}

export default DivisionMaterial
