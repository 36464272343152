import React, { useEffect, useState } from 'react'
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';
import axiosInstance from '../../../../../Services/axiosCommon';

function Product() {
  // const [params, setParams] = useState({
  //   period:"monthly",
  // });

  const [selectedPeriod, setSelectedPeriod] = useState( 'Monthly');
 
    const { data, loading } = UseGetDashBoardWithParams(`/dashboard/orders-by-products`);
       
    // console.log(data,'poih');
    
    // const hanChange=(e)=>{
    //   setParams({
    //     period:e.target.value
    //   })
    //   setSelectedPeriod(e.target.value)
    // }
  
    const periods = [
      { value: 'monthly', option: 'Monthly' },
      { value: 'quarterly', option: 'Quarterly' },
      { value: 'halfyearly', option: 'Half Yearly' },
      { value: 'yearly', option: 'Yearly' },
    ];
    return (
        <div className="col-span-2 bg-white max-h-[40vh] w-full p-4 pb-10 rounded-lg drop-shadow-lg mb-4">
      <div className="flex justify-between items-center mb-1">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Orders by Product Category</p>
          {/* <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-sm text-[#ACACAC]  font-normal"
            >
              {periods?.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div> */}
        </div>
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>

        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-pulse space-y-4 w-full">
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
            </div>
          </div>
        ) : (
            <div className="w-full h-[96%] overflow-y-scroll pb-4">
            <table className="w-full">
            <thead className="sticky top-0 bg-white shadow-sm">
  
                <tr>
                  <th className="text-left px-2 py-1"><TextMediumSM content="Product" /></th>
                  <th className=" px-2 py-1 text-center"><TextMediumSM content="Count" /></th>
                  <th className="text-left px-2 py-1"><TextMediumSM content="Price" /></th>

                  
                </tr>
              </thead>
              <tbody>
               
                {data?.orders_by_products?.map((item, index) => (
                  <tr className="odd:bg-white even:bg-slate-50 " key={index}>
                    <td className="px-2 py-2"><TextMediumdS content={item?.name || "N/A"} /></td>
                    <td className="px-2 py-2 text-center"><TextMediumdS content={item?.count || "N/A"} /></td>
                    <td className="px-2 py-2">
  <TextMediumdS 
    content={item?.price 
      ? Number(item?.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
      : "N/A"} 
  />
</td>


                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
}

export default Product