import { TextSM } from "../../../../../Components/Text/NormalText";
import ViewMoreTeam from "./ViewMoreTeam";


function VendorCard({data,getData,subArrList,getCountData}) {
    const {type,scope} = data;
  return (
    <>
          <div className="shadow-[0px_2px_10px_rgba(38,48,75,0.15)] rounded-[10px] p-3" style={{border:'1px solid #dedede'}}>
        <div className=" flex justify-between ">
          <div className="">
            {/* <NavLink className="" to={`/teams/view`} state={{ data: data }}> */}
             <p className=" text-mainColor-400  line-clamp-1 font-semibold text-[12px] lg:text-[12px] xl:text-[12px]">{type[0]?.companyName||"-"}</p>
            {/* </NavLink> */}
            <span className=" my-1 flex items-center">
              <TextSM color="text-[#9DA3AA]" text={"Contact Person : "} />
              <span>&nbsp;&nbsp;</span>
              <TextSM
                      text={`${type[0]?.vendorname || ""}`}
                      
                    />

            </span>
          </div>
          <ViewMoreTeam
            data={data}
            getData={getData}
            subArrList={subArrList}
            projectId={data?.projectId}
            getCountData={getCountData}
          />
        </div>
        <div className=" w-full h-24 p-2 bg-[#F7F7FB] rounded-md overflow-hidden">
          <div className="">
          <TextSM color="text-[#9DA3AA]" text={" Scope of Work  : "} />
          <p className='text-mainColor-400 font-medium line-clamp-4 text-[12px] lg:text-[12px] xl:text-[12px]'>
           {scope|| ""}
          </p>
          </div>
          <div className=" flex items-end my-4">
           
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorCard