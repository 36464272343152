import React from 'react'
import TeamCard from '../teamCard'

function ProjectTeam({teamDetails}) {
  return (
    <div className="w-full bg-white  h-[45vh] overflow-y-auto p-2 rounded-lg" style={{ border: "1px solid #E9E8EC" }}>
     <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px] py-1">Teams : {teamDetails.length||0}</p>
     <div className=' w-full grid gap-4 grid-cols-2'>
     {
                teamDetails&&teamDetails.map((team, index) =>{
                    return (
                        <TeamCard key={index} data={team} />
                    )
                })
            }
     </div>
    </div>
  )
}

export default ProjectTeam