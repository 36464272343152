import { Link, Outlet, useLocation } from "react-router-dom"
import Dasboard from "../../../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../../../Components/Utils/SearchBar";
import { CreateButton, FilterButton } from "../../../../../Components/Utils/Buttons";
import { Select } from "antd";
import { GetAccess } from "../../../../../Components/Utils/roles";
import Breadcrumb from "../../../../../Components/Utils/Breadcrumb";


const ProgressLayout = (
    {
        SearchData=() => {},
        neededSearch=false,
        // setSearchQuery=()=>{},
        setSearchQuery=()=>{},
        CreateAccess= true,
        ImportAccess= true,
        setOpen=()=>{},
        children,
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        officeId,
        projectId,
        onCreateClick,
        searchFun=()=>{},
        searchValue="",
    }
) =>{
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    console.log("nnn",module,'off',officeId,'pro',projectId);
    

    useEffect(() =>{
        if (pathname == `/project/progress/man-power/${officeId}/${projectId}`) {
          setModule("Man Power");
        }else if(pathname == `/project/progress/material/${officeId}/${projectId}`){
            setModule("Material")
        }else if(pathname == `/project/progress/equipment/${officeId}/${projectId}`){
            setModule("Equipment")
        }
        else if(pathname == `/project/progress/others/${officeId}/${projectId}`){
            setModule("Others")
        }
        // if(pathname == `/project/progress/man-hour/${officeId}/${projectId}`){
        //     setModule("Man Hour")
        // }else if(pathname == `/project/progress/installation/${officeId}/${projectId}`){
        //     setModule("Installation")
        // }
        // else if(pathname == "/progress/material-expense"){
        //     setModule("Material & Others")
        // }else if(pathname == "/progress/petty-cash-expense"){
        //     setModule("Petty Cash Expense")
        // }
    },[pathname])

    let LinkItem = [
      {
        label: "Man Power",
        link: `/project/progress/man-power/${officeId}/${projectId}`,
        access: GetAccess(),
      },
      {
        label: "Material",
        link: `/project/progress/material/${officeId}/${projectId}`,
        access: GetAccess(),
      },
      {
        label: "Equipment",
        link: `/project/progress/equipment/${officeId}/${projectId}`,
        access: GetAccess(),
      },
      {
        label: "Others",
        link: `/project/progress/others/${officeId}/${projectId}`,
        access: GetAccess(),
      },
    //   {
    //       label:"Man Hour",
    //       link:`/project/progress/man-hour/${officeId}/${projectId}`,
    //       access:GetAccess(),
    //   },
    //   {
    //       label:"Installation",
    //       link:`/project/progress/installation/${officeId}/${projectId}`,
    //       access:GetAccess(),
    //   },
    //   {
    //       label:"Material & Others",
    //       link:"/progress/material-expense",
    //       access:true,
    //   },
    //   {
    //       label:"Petty Cash Expense",
    //       link:"/progress/petty-cash-expense",
    //       access:true,
    //   }
    ];
    
    return(
        <div className=" w-full h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-between w-full">
            {/* <Breadcrumb items={[
                
               {
                name:"Progress"
               },
                {
                    name:module
                }
            ]} /> */}
             <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                // <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                                <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>

                            )
                         }
                        </>
                    )
                })
            }
        </div>

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-2 mx-0" onSubmit={SearchData}>
            {neededSearch &&    <SearchBar handleChange={searchFun}  value={searchValue}/>}
           
              </form>
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <FilterButton label='Clear Filter'  handleClick={ClearFilter} />}
             
              {/* {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />} */}
              {CreateAccess && <CreateButton label={module} handleClick={onCreateClick} />}
                  {/* <BackButton/> */}
                </div>
            </div>
           
           
           
            <div className="bg-white w-full h-[calc(100vh-135px)] p-2 overflow-y-scroll rounded-md mt-2">
            {children} 
            </div>
            </div>
        </div>
    )
}


export default ProgressLayout;