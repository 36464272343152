import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useLocation, useParams } from "react-router-dom";
import ViewSwitcher from "./ViewSwitcher";
import { getStartEndDateForProject, initTasks } from "./helper";
// import { useNumber } from "../../../../../../Context/ProjectIdContext";
import NuDate from "../../../../../../Components/Utils/NuDate";
import axiosInstance from "../../../../../../Services/axiosCommon";
import PlanLayout from "../PlanLayout";
import { Gantt, ViewMode } from "gantt-task-react";

function TaskSchedule() {
  const { officeId } = useParams();
  const { projectId } = useParams();
  // const { number, updateNumber } = useNumber();
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState(initTasks());
  const [isChecked, setIsChecked] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [hasEdit, setHasEdit] = useState(false);

  let columnWidth;
  switch (view) {
    case ViewMode.Day:
      columnWidth = 65;
      break;
    case ViewMode.Week:
      columnWidth = 250;
      break;
    case ViewMode.Month:
      columnWidth = 300;
      break;
    case ViewMode.Year:
      columnWidth = 400;
      break;
    default:
      columnWidth = 65;
  }

  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t));
      }
    }
    setTasks(newTasks);
    if (task.taskType === "task") {
      UpdateTaskDate(task?.taskId, task);
    } else if (task.taskType === "sub-task") {
      UpdateSubTaskDate(task?.taskId, task);
    }
  };

  function UpdateTaskDate(taskId, value) {
    let start = NuDate({ value: value.start, format: "YYYY-MM-DD" });
    let end = NuDate({ value: value.end, format: "YYYY-MM-DD" });

    let dateValue = {
      startDate: start,
      endDate: end,
    };
    axiosInstance.put(`/Task/update_date/${taskId}`, dateValue).then().catch();
  }

  function UpdateSubTaskDate(subTaskId, value) {
    let start = NuDate({ value: value.start, format: "YYYY-MM-DD" });
    let end = NuDate({ value: value.end, format: "YYYY-MM-DD" });

    let dateValue = {
      startDate: start,
      endDate: end,
    };
    axiosInstance.put(`/SubTask/update_date/${subTaskId}`, dateValue).then().catch();
  }

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    if (task.taskType === "task") {
      UpdateTaskPercentage(task?.progress, task?.taskId);
    } else if (task.taskType === "sub-task") {
      UpdateSubTaskPercentage(task?.progress, task?.taskId);
    }
  };

  function UpdateTaskPercentage(value, taskId) {
    let completion = {
      taskCompletion: value,
    };
    axiosInstance.put(`/Task/${taskId}/task-completions`, completion).then().catch();
  }

  function UpdateSubTaskPercentage(value, subTaskId) {
    let completion = {
      subTaskCompletions: value,
    };
    axiosInstance.put(`/SubTask/${subTaskId}/subtask-completions`, completion).then().catch();
  }

  // async function getProject() {
  //   await axiosInstance
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {
  //       if (number != 0) {
  //         setFirstProject(number);
  //       } else if (projectTask) {
  //         setFirstProject(projectTask);
  //         updateNumber(projectTask);
  //       } else {
  //         res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
  //         updateNumber(res?.data?.data[0]?.ProjectId);
  //       }
  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       await res?.data?.data &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // function handleProjectChange(e) {
  //   setFirstProject(e);
  //   updateNumber(e);
  // }

  function getTitle(x) {
    axiosInstance
      .get(`/mytask/byUser/all_tasks/${x}`)
      .then((res) => {
        let listOfTitle = res.data?.data || [];
        let num = 1;
        let taskList = [];
        if (listOfTitle.length > 0) {
          listOfTitle &&
            listOfTitle.map((taskItem) => {
              let arr = taskItem?.taskDetails || [];
              if (arr.length > 0) {
                arr.map((item) => {
                  let taskName = `${item?.taskName}-${item?.taskId}`;
                  let StartDate = new Date(`${item?.startDate} 12:00:00`);
                  let EndDate = new Date(`${item?.endDate} 23:00:00`);

                  if (item?.subTaskDetails.length > 0) {
                    let tasks = {
                      start: new Date(StartDate),
                      end: new Date(EndDate),
                      name: item?.taskName,
                      id: `${item?.taskName}-${item?.taskId}`,
                      progress: item?.taskCompletion,
                      type: "project",
                      hideChildren: false,
                      displayOrder: num++,
                      taskId: item?.taskId,
                      taskType: "task",
                    };
                    taskList.push(tasks);

                    item?.subTaskDetails.map((subitem) => {
                      let Startdate = new Date(`${subitem?.startDate} 12:00:00`);
                      let Enddate = new Date(`${subitem?.endDate} 23:00:00`);

                      let subtasks = {
                        start: new Date(Startdate),
                        end: new Date(Enddate),
                        name: subitem?.subTaskName,
                        id: `${subitem?.subTaskName}-${subitem?.subTaskId}`,
                        progress: subitem?.subTaskCompletions,
                        type: "task",
                        project: taskName,
                        displayOrder: num++,
                        taskId: subitem?.subTaskId,
                        taskType: "sub-task",
                      };
                      taskList.push(subtasks);
                    });
                  } else {
                    let tasksNoSub = {
                      start: new Date(StartDate),
                      end: new Date(EndDate),
                      name: item?.taskName,
                      id: `${item?.taskName}-${item?.taskId}`,
                      progress: item?.taskCompletion,
                      type: "task",
                      hideChildren: false,
                      displayOrder: num++,
                      taskId: item?.taskId,
                      taskType: "task",
                    };
                    taskList.push(tasksNoSub);
                  }
                });
              } else {
                taskList = initTasks();
              }
            });
          setTasks(taskList);
        } else {
          setTasks(initTasks());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // useEffect(() => {
  //   if (number != 0) {
  //     getTitle(number);
  //   } else {
  //     getTitle(firstProject);
  //   }
  // }, [firstProject]);

  // useEffect(() => {
  //   if (projectTask) {
  //     setFirstProject(projectTask);
  //   }
  // }, [projectTask]);

  useEffect(() => {
    // getProject();
    getTitle(projectId);
  }, []);

  return (
    <>
      <PlanLayout
       officeId={officeId}
       projectId={projectId}
      >
        <div className="md:flex justify-end w-full">
          {/* <div className="w-full md:w-4/12 flex gap-2 items-center mb-3">
            <div className="hidden md:block font-medium text-xs">Project </div>
            <Select
              style={{ width: "60%" }}
              options={projectList}
              value={projectList.find((i) => i.value == firstProject) || ""}
              disabled={projectList.length >= 2 ? false : true}
              onChange={handleProjectChange}
            />
          </div> */}
          <ViewSwitcher
            onViewModeChange={(viewMode) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            viewMode={view}
            hasEdit={hasEdit}
            setHasEdit={setHasEdit}
          />
        </div>

        <div className="w-full h-[calc(100vh-190px)] overflow-y-auto">
          <Gantt
            tasks={tasks}
            viewMode={view}
            onDateChange={hasEdit && handleTaskChange}
            onProgressChange={hasEdit && handleProgressChange}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
            barBackgroundColor="#DDD"
            barProgressSelectedColor={"#87d068"}
            barBackgroundSelectedColor={"#B8B8B8"}
            barProgressColor={"#119DEE"}
            rowHeight={50}
            fontSize={12}
            barCornerRadius={7}
            barFill={35}
            TaskListHeader={CustomTaskListHeader}
            TaskListTable={(task)=>CustomTaskListTableRow(task)}
            TooltipContent={(task)=>ToolContent(task)}
          />
        </div>
      </PlanLayout>
    </>
  );
}

export default TaskSchedule;

function ToolContent (value){
  return(<div className=" max-w-[600px] text-xs bg-white rounded-lg p-1 shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] z-[9999]">
    <table>
      <tr><td className=" w-[70px] font-semibold py-1 ">Activity  </td><td>: {value?.task?.name||""}</td></tr>
      <tr><td className=" w-[70px] font-semibold py-1">Start Date  </td><td>: {value?.task?.start && <NuDate value={value?.task?.start} format="DD-MMM-YYYY" />||""}</td></tr>
      <tr><td className=" w-[70px] font-semibold py-1">End Date </td><td>: {value?.task?.end && <NuDate value={value?.task?.end} format="DD-MMM-YYYY" />||""}</td></tr>
      <tr><td className=" w-[70px] font-semibold py-1">Progress  </td><td>: {value?.task?.progress||0}%</td></tr>
    </table>
  </div>)
}

const CustomTaskListHeader = () => (
  <div className=" h-[50px] flex items-end" style={{border:"1px solid #ddd"}}>

<div className=" text-xs font-semibold flex justify-between px-[10px] h-[20px]" >
    <div style={{ width: '300px' }}>Activity</div>
    <div style={{ width: '90px' }}>From</div>
    <div style={{ width: '90px' }}>To</div>
  </div>
  </div>
);

const CustomTaskListTableRow = (value) => {
  // Destructure properties with default values
  console.log("sss",value);
  

  return (
    <>
    {
      value?.tasks && value?.tasks.map((task) => (
        <div className=" text-xs text-mainColor-400" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd',height:"50px", borderLeft: "1px solid #ddd", borderRight: "1px solid #ddd",}}>
        <div className=" w-[300px] h-[50px] overflow-hidden pr-2"><p className=" line-clamp-2 w-full ">{task?.name||""}</p></div>
        <div style={{ width: '90px' }}>{task?.start && <NuDate value={task?.start} format="DD-MMM-YYYY" />}</div>
        <div style={{ width: '90px' }}>{task?.end && <NuDate value={task?.end} format="DD-MMM-YYYY" />}</div>
      </div>
      ))
    }
    </>

  );
};

