import React from "react";

export  function TextBoldXS({
  color = "gray-800",
  truncate,
  wrap = "wrap",
  whitespace = "normal",
  content,
  align,
  otherStyles = ""
}) {
  return (
    <p
      className={` text-[10px] 2xl:text-xs text-${align} font-semibold text-${color} text-${wrap} whitespace-${whitespace} ${
        truncate ? "truncate" : ""
      } ${otherStyles}`}
    >
      {content}
    </p>
  );
}

export  function TextBoldSM({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align,
    otherStyles = ""

  }) {
    return (
      <p
        className={` text-xs 2xl:text-sm  text-${align} font-semibold text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextBoldBase({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-sm 2xl:text-base text-${align} font-semibold text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextBoldXL({
    color = "text-gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-base 2xl:text-xl text-${align} font-semibold ${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextBoldXXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-xl 2xl:text-2xl text-${align} font-semibold text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }


  export function TextBaseBold ({text,width="w-full",overflow}){
    return (
        <p className={` ${width} ${overflow?"truncate":''} text-mainColor-400 font-semibold text-[12px] lg:text-[12px] xl:text-[12px] `} >{text}</p>
    )
}

export function TextBold ({text}){
    return (
        <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px]">{text}</p>
    )
}

export function TextNormal ({text}){
    return (
        <p className=" text-mainColor-400  font-medium text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}


export function TextSmallBold ({text}){
    return (
        <p className=" text-mainColor-400  font-semibold text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}

export function TextSmallNormal ({text}){
    return (
        <p className=" text-textColor-300  truncate text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}

export function TextSmallMedium ({text}){
    return (
        <p className=" text-[#171A21] font-medium  truncate text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}

export function TextTinyNormal ({text,color="text-textColor-200"}){
    return (
        <p className={` ${color} truncate text-[12px] lg:text-[12px] xl:text-[12px]`}>{text}</p>
    )
}

export function TextTinyMedium ({text}){
    return (
        <p className=" text-mainColor-400 font-medium  truncate text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}