import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../../../Components/Utils/Buttons'
import { Link, useLocation, useParams } from 'react-router-dom'
import Dasboard from '../../../../../../Components/Icons/Dasboard'
import NuAmount from '../../../../../../Components/Utils/NuAmount'
import Breadcrumb from '../../../../../../Components/Utils/Breadcrumb'
import axiosInstance from '../../../../../../Services/axiosCommon'

function ViewOthers(id) {
  const location = useLocation();
   const [data,setData]=useState({})
  // const data = location.state?.data;
  const { projectId } = useParams();
      const {officeId , estmationId,otherViewId} = useParams();
    const Paydata = location.state.data;
    const pageIndex = location.state?.pageIndex;
    const searchQuery = location.state?.searchQuery;
    function getById (id){
      axiosInstance.get(`/others/${otherViewId}`)
      .then( async (res)=>{
      
        console.log('====================================');
        console.log(res.data);
        console.log('====================================');
        setData(res.data.data)
      })
      
      .catch((error)=>{
        console.log(error);
      })
      
        }
        useEffect(()=>{
              if (otherViewId){
               getById(otherViewId)
                
              }
            },[otherViewId])
  return (
    <div className="w-full h-[calc(100vh-60px)]  px-4 py-2 overflow-hidden ">
    <div className="md:flex justify-between w-full">
    <Breadcrumb 
      items={[

        { name: "Budget" },
           
        {
          name: "Others",
          link: `/project/costing/others/${officeId}/${projectId}`
        },

        { name: "View" },
      ]} 
            />
      {/* <div className=" flex justify-between items-center float-right">
        <BackButton />
      </div> */}
      <div className=" flex gap-2">
                      <Link to={`/project/costing/others/${officeId}/${projectId}`} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
                      <Link to={`/project/costing/others/${officeId}/${projectId}/edit/${data?.id}`} state={{data: data, from:`/project/costing/others/${officeId}/${projectId}/view/${data?.id}`}}><EditButton height='8'/></Link>
                    </div>
    </div>
    <div className=" w-full  h-[calc(100vh-120px)] overflow-y-auto mt-3 rounded-lg bg-white p-4 ">
      <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Project </p>
          <p className=' text-xs  text-mainColor-400'>{data?.projectId||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Activity </p>
          <p className=' text-xs  text-mainColor-400'>{data?.activity||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Unit </p>
          <p className=' text-xs  text-mainColor-400'>{data?.unit||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Quantity </p>
          <p className=' text-xs  text-mainColor-400'>{data?.quantity||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Division </p>
          <p className=' text-xs  text-mainColor-400'>{data?.division||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Category </p>
          <p className=' text-xs  text-mainColor-400'>{data?.category||0}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Amount </p>
          <p className=' text-xs  text-mainColor-400'><NuAmount value={data?.amount||0} /></p>
        </div>
        <div className=' my-2 row-start-4 col-start-1 col-span-3'>
          <p className=' text-[13px] font-medium text-mainColor-400 '>Comments </p>
          <p className=' text-xs  text-mainColor-400'>{data?.comments||"-"}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewOthers