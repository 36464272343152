import { Button, Modal, notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";

import axios from "../../../Services/axiosCommon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dasboard from "../../../Components/Icons/Dasboard";
import {
  BackButton,
} from "../../../Components/Utils/Buttons";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import { NuDate, NuInput, NuLabel, NuSelect, NuTextArea } from "../../../Components/Utils/Input";
import { TextBoldBase, TextBoldSM } from "../../../Components/Text/BoldText";
import { TextSM } from "../../../Components/Text/NormalText";
import MultiSelect from "../../../Components/Utils/MultiSelect";
import FormikSelectTwo from "../../../Components/Utils/Select";
const ViewProject = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let data = location?.state?.data||[];
  const [api, contextHolder] = notification.useNotification();
  const [proStatus, setProStatus] = useState([]);
  const [proManager, setProManager] = useState([]);
  const [proClient, setProClient] = useState([]);
  const [createNew, setCreateNew] = useState({
    projName: "",
    projScope: "",
    projCost: "",
    startDate: "",
    endDate: "",
    client: "",
    projTeam: [],
    awardedOnDate: "",
    noOFDays: "",
    noOFWeeks: "",
    assignedTo: [],
    comment: "",
    projStatus: "",
    revisedEndDate: "",
    reasonForDateRevised: "",
  });



  const getProjectStatus = async () => {
    await axios
      .get("/Metadata/projectstatus")
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data?.keyValues;
        status.forEach((item) => {
          let tempObject = { value: item, label: item };
          FinalData.push(tempObject);
        });
        response.data?.data && setProStatus(FinalData||[]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectManager = async () => {
    await axios
      .get("/Project/leadPersons")
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = {
            value: item.userId,
            label: `${item.firstName} ${item.lastName}`,
          };
          FinalData.push(tempObject);
        });
        response.data?.data && setProManager(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClients = async () => {
    await axios
      .get("/DropDown/client")
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = { value: item.clientId, label: item.clientName };
          FinalData.push(tempObject);
        });
        response.data?.data && setProClient(FinalData||[]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProjectStatus();
    getProjectManager();
    // getTeams();
    getClients();
    data &&
      setCreateNew({
        projId: data?.projId,
        projName: data?.projName,
        projScope: data?.projScope,
        projCost: data?.projCost,
        startDate: data?.startDate,
        endDate: data?.endDate,
        client: data?.client,
        projTeam: data?.projTeam,
        awardedOnDate: data?.awardedOnDate,
        noOFDays: data?.noOFDays,
        noOFWeeks: data?.noOFWeeks,
        assignedTo: data?.assignedTo,
        comment: data?.comment,
        projStatus: data?.projStatus,
        revisedEndDate: data?.revisedEndDate,
        reasonForDateRevised: data?.reasonForDateRevised,
      });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   name: yup
    //     .string()
    //     .required("Name Required")
    //     .matches(/^(?![0-9]*$)[a-zA-Z]+$/, "Enter Valid Name"),
    //   dob: yup
    //     .string()
    //     .max(new Date(Date.now() - 567648000000), "Must be at least 18 years")
    //     .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   city: yup.string().matches(/^[A-Za-z]+$/, "Enter Valid City Name"),
    //   state: yup.string().required("Place Select State"),
    //   pincode: yup
    //     .string()
    //     .required("Pincode is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Pincode"
    //     )
    //     .min(6, "Invalid Pincode")
    //     .max(6, "Invalid Pincode"),
    //   address: yup
    //     .string()
    //     .matches(
    //       /^(?![0-9]*$)(?![0-9\s]*$)[a-zA-Z0-9'\.\-\s\,\/\(\)\#]+$/,
    //       "Enter Valid Address"
    //     ),

    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   joinDate: yup.string().required("Join Date is required"),
    // }),
    onSubmit: async (value, { resetForm }) => {
      // let formData = new FormData();

      // formData.append('userData', JSON.stringify(value));
      // formData.append('userImage', image.raw);

      data
        ? axios
            .put(`/Project/${data?.projId}`, value)
            .then((res) => {
              if (res.data?.status) {
                // getData();
                api.success({
                  message: `Added successfully`,
                  description: res?.data?.msg || "Project Has been Added",
                  placement: "bottomRight",
                });
                navigate(-1);
                // onCancel();
              } else {
                api.error({
                  message: `Added failed`,
                  description: res?.data?.msg || "Project not Added",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              api.error({
                message: `Added failed`,
                description: err?.data?.msg || "Project not Added",
                placement: "bottomRight",
              });
            })
        : 
        axios
            .post("/Project/", value)
            .then((res) => {
              if (res.data?.status) {
                
                // getData();
                api.success({
                  message: `Added successfully`,
                  description: res?.data?.msg || "Project Has been Added",
                  placement: "bottomRight",
                });
                navigate(-1);
                // onCancel();
              } else {
                api.error({
                  message: `Added failed`,
                  description: res?.data?.msg || "Project not Added",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              api.error({
                message: `Added failed`,
                description: err?.data?.msg || "Project not Added",
                placement: "bottomRight",
              });
            });
    },
  });

  function diff_weeks(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    //  let result = Math.abs(Math.round(diff));
    return Math.abs(Math.round(diff));
  }

  function DateDiff(createDate, expiryDate) {
    let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays;
  }

  useMemo(() => {
    if (formik.values.startDate && formik.values.endDate) {
      let startDate = new Date(formik.values.startDate);
      let endDate = new Date(formik.values.endDate);
      formik.values.noOFWeeks = diff_weeks(startDate, endDate);
      formik.values.noOFDays = DateDiff(startDate, endDate);
    }
  }, [formik.values.startDate, formik.values.endDate]);


  function LeadPersion(arr){
    let list = [];
    arr && arr.map(id=>{
      let x = proManager.filter((item)=>item.value == id);
      console.log(x);
      list.push(x)
    })
    console.log(list);
    // return list.map((el,indx)=>(
    //   <TextSmallBold text={el}  key={indx}/>
    // ));
  }


  return (
    <div className=" w-full h-full px-4 py-2 overflow-hidden">
     <div className="md:flex justify-between w-full">
        <Breadcrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] "><Link to="/projects">Project</Link></p> },
            { title: <p className=" text-[12px] ">View</p> },
          ]}
        />
        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[86vh]">
      <div
            className=" w-full  rounded-lg my-2"
            style={{ border: "1px solid #ECEBF3" }}
          >
            <NuLabel label="Project Details" />
            <TextBoldBase text="Project Name :"  />
            <TextBoldSM text={createNew.projName||"-" } />
            
            <TextBoldSM text="Project Lead :"  />
            {LeadPersion(createNew.assignedTo)}
            <MultiSelect
              label="Project Manager/ Lead Person"
              options={proManager ? proManager : []}
              formik={formik}
              placeholder="Choose"
              isRequired={true}
              name="assignedTo"
              width="w-full lg:w-6/12"
            />
            <NuTextArea
              label="Project Scope"
              placeholder="Project Scope"
              formik={formik}
              name="projScope"
              width="w-full lg:w-6/12"
            />
            <FormikSelectTwo
              label="Project Status"
              Options={proStatus ? proStatus : []}
              formik={formik}
              placeholder="Choose"
              name="projStatus"
              width="w-full lg:w-6/12"
            />
            <div className=" w-1/2 flex flex-wrap  ">
              <NuInput
               type="text"
                label="Project Cost"
                placeholder="0"
                formik={formik}
                name="projCost"
                width="w-full lg:w-6/12"
              />
              <NuDate
                label="Awarded On"
                isRequired={true}
                formik={formik}
                name="awardedOnDate"
                width="w-full lg:w-6/12"
              />
            </div>
            <div className=" w-1/2 flex flex-wrap  ">
              <NuDate
                label="Start Date"
                isRequired={true}
                formik={formik}
                name="startDate"
                width="w-full lg:w-6/12"
              />
              <NuDate
                label="End Date"
                isRequired={true}
                formik={formik}
                name="endDate"
                width="w-full lg:w-6/12"
              />
              <NuInput
               type="number"
                label="No. Of Week"
                placeholder="0"
                disable={true}
                formik={formik}
                name="noOFWeeks"
                width="w-full lg:w-6/12"
              />
              <NuInput
               type="number"
                label="No. Of Days"
                placeholder="0"
                disable={true}
                formik={formik}
                name="noOFDays"
                width="w-full lg:w-6/12"
              />
            </div>

            {data && (
              <>
                <NuDate
                  label="Revised End Date"
                  formik={formik}
                  name="revisedEndDate"
                  width="w-full lg:w-6/12"
                />
                <NuTextArea
                  label="Reason for Date Revised"
                  placeholder="Type Here"
                  formik={formik}
                  name="reasonForDateRevised"
                  width="w-full lg:w-6/12"
                />
              </>
            )}
          </div>
      </div>
    </div>
  );
};

export default ViewProject;
