import React from "react";

const Budget = () => {
  // Dummy data
  const projects = [
    {
      name: "Nutrack",
      status: "In-Progress",
      allocated: 450500,
      spent: 230398,
      color: "bg-purple-500",
      lightColor: "bg-purple-200",
      percentage:"51.14%"
    },
    {
      name: "Louis Vuitton",
      status: "In-Progress",
      allocated: 450500,
      spent: 230398,
      color: "bg-blue-500",
      lightColor: "bg-blue-200",
      percentage:"51.14%"

    },
  ];

  // Helper function to render progress blocks
  const renderProgressBlocks = (percentage, color, lightColor) => {
    const totalBlocks = 10;
    const filledBlocks = Math.round((percentage / 100) * totalBlocks);

    return (
      <div className="flex items-center gap-0.5">
        {Array.from({ length: totalBlocks }).map((_, index) => (
          <div
            key={index}
            className={`w-2.5 h-2 rounded-full ${
              index < filledBlocks ? color : lightColor
            }`}
          ></div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white p-4 rounded-lg drop-shadow-lg h-[65vh]  2xl:h-[55vh] w-full">
    <div className="flex justify-between border-b">
      {/* <div className='border-r border-[1px] border-slate-200/60 border-solid'/> */}
      <h2 className="font-semibold text-[#4F4768] text-sm">Project Budget & Spending</h2>
      <p className="text-right text-sm mb-4 text-[#4F4768]">Currency : SAR</p>
      </div>
    
      <div className="overflow-auto">
        <table className="table-auto w-full text-left border-collapse">
          <thead>
            <tr className=" border-b">
              <th className="pb-2 font-normal text-sm">Project</th>
              <th className="pb-2 font-normal text-sm">Status</th>
              <th className="pb-2 font-normal text-sm">Allocated</th>
              <th className="pb-2 font-normal text-sm">Spent</th>
              <th className="pb-2 font-normal text-sm">Remaining</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => {
              const remaining = project.allocated - project.spent;
              const percentage = ((project.spent / project.allocated) * 100).toFixed(2);

              return (
                <tr
                  key={index}
                  className="text-sm text-gray-700 border-b last:border-b-0"
                >
                 
                  <td className="py-2">
                  
                      <p className="font-medium text-[#4F4768]">{project.name}</p>
                      <div className="flex gap-2">
                      {renderProgressBlocks(
                        percentage,
                        project.color,
                        project.lightColor
                      )}
                    

                      <p className="font-thin text-[#059E4B]">{project.percentage}</p>
                      </div>
                  </td>

                 
                  <td className="py-2 text-blue-500">{project.status}</td>

                
                  <td className="py-2 text-[#4F4768]">
                    {project.allocated.toLocaleString()}
                  </td>

                 
                  <td className="py-2 text-[#4F4768]">{project.spent.toLocaleString()}</td>

                 
                  <td className="py-2 text-[#4F4768]">{remaining.toLocaleString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Budget;
