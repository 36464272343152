// import React, { useEffect, useState } from "react";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
// import {
//   NuDate,
//   NuInput,
//   NuMobileNumber,
//   NuTextArea,
// } from "../../../../Components/Utils/Input";
// import FormikSelectTwo from "../../../../Components/Utils/Select";
// import { useFormik } from "formik";
// import { useLocation, useParams } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../../../Components/Hooks/useDropDown";
import useEditFormData from "../../../../../../Components/Hooks/useEditFormData";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useToast } from "../../../../../../Components/Others/ToastServices";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
import { NuDate, NuInput, NuMobileNumber, NuTextArea } from "../../../../../../Components/Utils/Input";
import DropDownAddMetaData from "../../../../../../Components/Utils/selectAddMetaData";
import CloseIcon from "../../../../../../Components/Icons/Close";
import { videotype } from "../../Tasks/Components/viewFiles";
import Video from "../../../../../../Components/Icons/Video";
import Doc from "../../../../../../Components/Icons/Doc";
import Xls from "../../../../../../Components/Icons/Xls";
import Txt from "../../../../../../Components/Icons/Txt";
import Pdf from "../../../../../../Components/Icons/Pdf";
import Attachement from "../../../../Notes/Attachement";
import DeleteIcon from "../../../../../../Components/Icons/Delete";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import useEditFormData from "../../../../Components/Hooks/useEditFormData";
// import DeleteIcon from "../../../../Components/Icons/Delete";
// import Attachement, {
//   videotype,
// } from "../../../../Components/Utils/CustomAttrachmentupload";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import { useToast } from "../../../../Components/Others/ToastServices";
// import Xls from "../../../../Components/Icons/Xls";
// import Txt from "../../../../Components/Icons/Txt";
// import Pdf from "../../../../Components/Icons/Pdf";
// import Doc from "../../../../Components/Icons/Doc";
// import CloseIcon from "../../../../Components/Icons/Close";
// import Video from "../../../../Components/Icons/Video";
// import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

function EditDeliveryNotes() {
  const {officeId,projectId} =useParams();

  const { value: DeliveryVehicle } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryVehicle"
  );
  const { value: DeliveryNoteIdType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryNoteIdType"
  );
  const {
    value: DelivaryNoteDelivaryStatus,
    getData: refDelivaryNoteDelivaryStatus,
  } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DelivaryNoteDelivaryStatus"
  );
  const { data: SO } = UseDropDown("/sales-order/drop-down");
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [image, setImage] = useState([]);
  const [dataa, setData] = useState(data);
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const [createNew, setCreateNew] = useState({
    idNumber: data.idNumber,
    countryCode: data.mobile.countryCode,
    mobile: data.mobile.number,
    deliveryVehicleType: data.deliveryVechicleType,
    soId: data.soId,
    daysElapsed: data.daysElapsed,
    date: data.date,
    idType: data.idType,
    deliveryPersonName: data.deliveryPersonName,
    wayBillNo: data.wayBillNo,
    deliveredOn: data.deliveredOn,
    comments: data.comments,
    deliveryStatus: data.deliveryStatus,
    products: [],
    filesToRemove: [],
    approvalStatus: data.approvalStatus,
  });

  const handleDelete = (productId) => {
    const updatedquoteProducts = dataa?.products.filter(
      (item) => item.productId !== productId
    );
    console.log(updatedquoteProducts, "pp");
    setData((prevState) => ({
      ...prevState,
      products: updatedquoteProducts,
    }));
    // setRemove([...remove,productId]);
  };
  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      soId: yup.string().required("Sales Order is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (dataa.products.length === 0) {
        setError("Product table is empty");
        return;
      }
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        products: dataa.products,

        filesToRemove: fileRemoveList,
      };
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      editFormData(
        `/delivery-notes/edit-delivery-note/${data.deliveryNoteId}`,
        formData,
        resetForm,
        from
      );
      // setLoading(false)
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        tempArr.push(fileName);
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);

      setRemoveFilePathList(data?.attachments);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }
  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProducts = [...dataa.products];
    const intValue = parseInt(value);
    if (name === "packedQty" && intValue <= updatedProducts[index].orderedQty) {
      updatedProducts[index][name] = intValue;
      updatedProducts[index].remainingQty =
        updatedProducts[index].orderedQty - updatedProducts[index].packedQty;
    }
    setData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleIncrement = (index, field) => {
    const updatedProducts = [...dataa.products];
    if (
      field === "packedQty" &&
      updatedProducts[index][field] < updatedProducts[index].orderedQty
    ) {
      updatedProducts[index][field] += 1;
      updatedProducts[index].remainingQty =
        updatedProducts[index].orderedQty - updatedProducts[index].packedQty;
    }
    setData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleDecrement = (index, field) => {
    const updatedProducts = [...dataa.products];
    if (updatedProducts[index][field] > 0) {
      updatedProducts[index][field] -= 1;
      updatedProducts[index].remainingQty =
        updatedProducts[index].orderedQty - updatedProducts[index].packedQty;
    }
    setData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  useEffect(() => {
    if (dataa.products.length > 0) {
      setError("");
    }
  }, [dataa.products]);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      tempArr.push(fileName);
    });
    setImage(tempArr);
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Delivery Note",
              link: `/project/store/delevery-notes/${officeId}/${projectId}`
            },

            { name: "Edit" },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <FormikSelectTwo
              type="text"
              label="Sales Order"
              placeholder="Sales Order"
              Options={SO}
              formik={formik}
              disabled={true}
              name="soId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              label="Id Number"
              type="text"
              formik={formik}
              placeholder="Id Number"
              name="idNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Delivery Vehicle Type"
              placeholder="Delivery Vehicle Type"
              Options={DeliveryVehicle}
              formik={formik}
              name="deliveryVehicleType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              label="Days Elapsed"
              type="number"
              formik={formik}
              placeholder="Days Elapsed"
              name="daysElapsed"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Id Type"
              placeholder="Id Type"
              Options={DeliveryNoteIdType}
              formik={formik}
              name="idType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              label="Delivery Person Name"
              type="text"
              formik={formik}
              placeholder="Delivery Person Name"
              name="deliveryPersonName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              label="WayBill No"
              type="number"
              formik={formik}
              placeholder="WayBill No"
              name="wayBillNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Delivered On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deliveredOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={DelivaryNoteDelivaryStatus}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refDelivaryNoteDelivaryStatus}
              displayProperty={"Status"}
              propertyName={"DelivaryNoteDelivaryStatus"}
            />
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full">
              <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                DN Attachement :
              </p>
            </div>
            <div className="md:flex justify-between  px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];
                    return (
                      <div
                        className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          <CloseIcon color="white" height="8" width="8" />
                        </div>
                        <div
                          className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          <div className=" w-8 h-8">
                            {videotype.includes(Ext) ? <Video /> : null}

                            {Ext === ("docx" || "doc" || "txt") ? (
                              <Doc />
                            ) : Ext === ("xlsx" || "xls") ? (
                              <Xls />
                            ) : Ext === "txt" ? (
                              <Txt />
                            ) : Ext === "pdf" ? (
                              <Pdf />
                            ) : Ext === "pptx" ? (
                              <>No file</>
                            ) : (
                              <img src={imgs?.file} alt="#" width="120%" />
                            )}
                          </div>
                          <p className=" w-[50px] text-[11px] truncate">
                            {imgs?.file_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                />
              </div>
            </div>
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              {error && (
                <p className="text-xs font-semibold text-red-500">{error}</p>
              )}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
                <p className="w-[5%]  text-left  text-white text-xs font-medium">
                  S.N.{" "}
                </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className="w-[5%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Ordered Quantity
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  Packed Quantity
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Remaining Quantity
                </p>
                <p className=" w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {dataa?.products?.length > 0 ? (
                <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                  {dataa?.products.map((List, index) => (
                    <div
                      key={index}
                      className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100"
                    >
                      <p className="w-[5%]  text-left   text-xs font-light py-1">
                        {index + 1}
                      </p>
                      <p className="w-[40%] text-left  text-xs font-light">
                        {List?.product_name}
                      </p>
                      <p className="w-[5%] text-center  text-xs font-light">
                        {List?.product_unit}
                      </p>
                      <p className="w-[15%] text-center  text-xs font-light">
                        {List?.orderedQty}
                      </p>

                      <div className="w-[20%] text-center  text-xs font-light">
                        <button
                          type="button"
                          className="border rounded-full p-1 "
                          onClick={() => handleDecrement(index, "packedQty")}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="w-[20%] text-center border  text-xs font-light"
                          name="packedQty"
                          value={List.packedQty}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                        <button
                          type="button"
                          className="border rounded-full p-1 "
                          onClick={() => handleIncrement(index, "packedQty")}
                        >
                          +
                        </button>
                      </div>
                      <p className="w-[15%] text-center  text-xs font-light">
                        {List?.remainingQty}
                      </p>
                      <div className="w-[5%] text-center">
                        <button
                          onClick={() => handleDelete(List?.productId)}
                          type="button"
                          className="text-red-500 cursor-pointer  ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full text-center text-[#93949f]">
                  No Items here
                </div>
              )}
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel();
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditDeliveryNotes;
