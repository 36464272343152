import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, {
  NuDataTime,
  NuDate,
  NuInput,
  NuSelect,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";
import UseEditData from "../../../../Components/Hooks/useEditData";
import NuDateDisplay from "../../../../Components/Utils/NuDate"
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";


const EditPayableAmount = () =>{
  const {value:PaymentMethod } = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_Recevable')
  // const [loading, setLoading] = useState(false);

const { amountReceivableEditId,officeId } = useParams();
  console.log("ddd",amountReceivableEditId);
  const location = useLocation();
  const data = location?.state?.data;
  const from = location?.state?.from;
  console.log("lkj",data);
  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const [createNew, setCreateNew] = useState({
    receivedAmount: data?.receivedAmount || "",
    paymentMethod: data?.paymentMethod || "",
    // receivedOn: (data?.receivedOn && NuDateDisplay({value:data?.receivedOn,format:"YYYY-MM-DD"}) )||"",
    receivedOn: (data?.receivedOn && NuDateDisplay({ value: data?.receivedOn, format: "YYYY-MM-DD HH:mm" })) || "",

    comments: data?.comments || "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      editData(`/receivable/edit-payment/${amountReceivableEditId}`, value, resetForm, from);
      // setLoading(false)
    },
  });

    return(
      <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
   
      <Breadcrumb
         items={
          [
            { name: "Payment",
            link: `/main/${officeId}/payments/receivables` }, 
            {
                name: "Receivables",
                link: `/main/${officeId}/payments/receivables`
              },
          {
            name: "View Receivables",
            // link: `/payments/payables/edit/${amountPayableId}`
          },
          {
            name: "Edit Receivables Amount"
          },
        ]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              type="number"
              label="Received Amount"
              placeholder="Received Amount"
              formik={formik}
              name="receivedAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              label="Payment Method"
              placeholder="Choose"
              Options={PaymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDataTime
              label="Received On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    )
}

export default EditPayableAmount;