import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function UnitMaterial() {
    return (
        <SettingsMetaData propertyName={"MaterialUnit"} viewPropertyName={"Unit"} />
      );
}

export default UnitMaterial
