import React, { useEffect, useState } from 'react'
import { CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import DeleteIcon from '../../../../Components/Icons/Delete';
import EditIcon from '../../../../Components/Icons/Edit';
import { FormikSelect, NuInput } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import axiosInstance from '../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useToast } from '../../../../Components/Others/ToastServices';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import * as yup from "yup";
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import MultiSelect from '../../../../Components/Utils/MultiSelect';

function PaymentRequestStatus() {
    const toast = useToast();
    const { value: PurchaseRequestApprovalStatus } = useFetchMataData(
      `/dropdown/dropdown-details/`,
      "ApprovalStatus_CQ"
    );
    const { data: user } = UseDropDown("/user/drop-down");
    const [data, setData] = useState([]);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const getData = () => {
      axiosInstance
        .get("/status-permission/by-module/payment_request")
        .then((res) => {
          console.log("ss", res);
          setData(res?.data?.data || []);
        })
        .catch(() => {
          toast.open({
            type: "error",
            message: "Error",
            description: "Failed to fetch data.",
          });
        });
    };
  
    useEffect(() => {
      getData();
    }, []);
    const [createNew, setCreateNew] = useState({
      module: "payment_request",
      status: "",
      users: [],
      approvalType: ""
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        status: yup.string().required("Status is requred!"),
        approvalType: yup.string().required("Approval Type is requred!"),

        users: yup.array().of(yup.string()).min(1, "At least one user is required!"),

      }),
      onSubmit: async (value, { resetForm }) => {
        axiosInstance.post("/status-permission/multi-add", value).then((res) => {
          if (res.data?.status === true) {
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully.",
            });
            resetForm();
            getData();
          } else if (res.data?.status === false && res.data?.error) {
            toast.open({
              type: "error",
              message: "Error",
              description: res.data.error || "Network error.",
            });
          } else {
            toast.open({
              type: "warning",
              message: "Warning",
              description: res.data?.msg || "Warning.",
            });
          }
        });
      },
    });
    const [editDataStatus,setEditDataStatus] = useState("");
    const [editApprovalType, setEditApprovalType] = useState("");
    const test = ()=>{
      let temp = []
      Object.keys(data).map((key) => {
        if (key === editDataStatus) {
        
          data[editDataStatus].map((item) => {
            console.log("value",item?.userId)
            temp.push(item?.userId)
          });
          // return temp;
        }
        // return null;
      })
      return temp
    }
    
    const [createNewEdit, setCreateNewEdit] = useState({
      module: "payment_request",
      status:"",
      users: "",
      approvalType: ""
    });
  
    useEffect(()=>{
      setCreateNewEdit({
        module: "payment_request",
        status: editDataStatus,
        users: test()||[],
        approvalType: editApprovalType || "",
      })
  
    },[editDataStatus])
  
      const formikEdit = useFormik({
        enableReinitialize: true,
        initialValues: createNewEdit,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          users: yup.array().of(yup.string()).min(1, "At least one user is required!"),
          approvalType: yup.string().required("Approval Type is requred!"),
             }),
        onSubmit: async (value, { resetForm }) => {
          const valueData = {
            ...value,
            module: "payment_request",
            status: editDataStatus,
          }
          axiosInstance.post("/status-permission/multi-add", valueData).then((res) => {
            if (res.data?.status === true) {
              toast.open({
                type: "success",
                message: "Success",
                description: res.data?.msg || "Created Successfully.",
              });
              resetForm();
              getData();
              closeFilterDialog();
            } else if (res.data?.status === false && res.data?.error) {
              toast.open({
                type: "error",
                message: "Error",
                description: res.data.error || "Network error.",
              });
            } else {
              toast.open({
                type: "warning",
                message: "Warning",
                description: res.data?.msg || "Warning.",
              });
            }
          });
        },
      });
  
    const getInitials = (name) => {
      const names = name.split(" ");
      if (names.length > 1) {
        return names[0][0] + names[1][0];
      }
      return names[0][0];
    };
    const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };


    function handleDelete(e){
      axiosInstance.delete(`/status-permission/multi-delete/payment_request/${e}`,).then(res=>{
  
        if(res?.data?.status == true){
          getData();
          toast.open({
            type: "success",
            message: "Success",
            description: res.data?.msg || "Created Successfully.",
          });
        }else{
          toast.open({
            type: "error",
            message: "Error",
            description: res.data.error || "Network error.",
          });
        }
        
      }).catch(err=>{
        toast.open({
          type: "error",
          message: "Error",
          description: err.response.data.error || "Delete failed.",
        });
      })
    }

    return (
      <>
        <style jsx>{`
          .circle-container .circle {
            position: relative;
            display: inline-block;
          }
  
          .circle-container .circle .circle-tooltip {
            visibility: hidden;
            width: auto;
            background-color: #fff;
            color: #555;
            text-align: center;
            border-radius: 5px;
            padding: 5px;
            position: absolute;
            z-index: 20;
            bottom: 100%; /* Position the tooltip above the circle */
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s;
            border: 1px solid #048178;
          }
  
          .circle-container .circle:hover .circle-tooltip {
            visibility: visible;
            opacity: 1;
          }
        `}</style>
        <div className="ml-5">
          <TextMediumSM content={"Payment Request"} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-start">
            <MultiSelect
              label="User Name"
              placeholder="Choose"
              Options={user}
              formik={formik}
              name="users"
              isRequired={true}

              width="w-full md:w-1/2 lg:w-1/4"
            />
            <FormikSelectTwo
              label="Status"
              placeholder="Choose"
              Options={[
                {value: "Verified", label:"Verified"},
                {value: "Approved", label:"Approved"}
              ]}
              formik={formik}
              isRequired={true}

              name="status"
              width="w-full md:w-1/2 lg:w-1/5"
              zindex={50}
            />
            <FormikSelectTwo
            label="Type"
            placeholder="Choose"
            Options={[
              { value: "seal", label: "Seal" },
              { value: "signature", label: "Signature" },
            ]}
            formik={formik}
            isRequired={true}

            name="approvalType"
            width="w-full md:w-1/2 lg:w-1/5"
            zindex={50}
          />
          <NuInput
                                  type="color"
                                   label="Color"
                                   placeholder="Choose"
                                   Options={user}
                                   formik={formik}
                                   name="users"
                                   isRequired={true}
                       
                                   width="w-full md:w-1/2 lg:w-1/4"
                                 />
            <div className="w-10 h-12 mt-11">
              <SubmitButton name="Add" />
            </div>
          </div>
        </form>
        <table className=" w-[75%] h-64 ml-5 overflow-y-auto mt-2 ">
          <thead>
            <tr className="border-b border-black">
              <th className=" w-[10%] text-left text-sm font-medium py-2 px-4">
                Status
              </th>
              <th className=" w-[10%] text-left text-sm font-medium py-2 px-4">
                Users
              </th>
              <th className=" w-[10%] text-left text-sm font-medium py-2 px-4">
                Type
              </th>
              <th className=" w-[10%] text-left text-sm font-medium py-2 px-4">
                Color
              </th>
              <th className=" w-[10%] text-left text-sm font-medium py-2 px-4">
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              Object.keys(data).map((value, index) => {
                console.log("ddddddddd", data[value]);
                return (
                  <tr className="even:bg-white odd:bg-slate-50" key={index}>
                    <td className="w-[30%] text-xs px-4">{value || "-"}</td>
                    <td className="w-[30%]">
                      <div className="w-1/2 py-3 pl-2 text-sm text-[#606279]">
                        <div
                          className="circle-container"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {Array.isArray(data[value]) &&
                          data[value].length > 0 ? (
                            data[value].map((name, index) => (
                              <div
                                key={index}
                                className="circle"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  backgroundColor: "#048178",
                                  color: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: "normal",
                                  marginLeft: index !== 0 ? "-10px" : "0", // Add overlap effect
                                  position: "relative",
                                  zIndex: 10 - index, // Stack circles on top of each other
                                  transition: "all 0.3s ease",
                                  cursor: "pointer",
                                  border:"1px solid #fff"
                                }}
                              >
                                {getInitials(name?.userName.charAt(0))}
                                <div className="circle-tooltip">
                                  {name?.userName}
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="w-[30%] text-xs px-4 pt-5">
                    {data[value][0].approvalType || "-"}
                  </td>
                    <td className="w-[10%]">
                    <div className=" w-full flex gap-1 justify-center items-center">
                      <div
                        className="flex justify-center cursor-pointer"
                        onClick={() => {setShowFilterDialog(true); setEditDataStatus(value); setEditApprovalType(data[value][0].approvalType); }}
                      >
                        <EditIcon color="green" />
                      </div>
                      <div
                      className="flex justify-center cursor-pointer"
                      onClick={() => {handleDelete(value)}}
                    >
                      <DeleteIcon/>
                    </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {showFilterDialog && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
            <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
              <h2 className="text-xl mb-4">Edit Permission</h2>
              <form onSubmit={formikEdit.handleSubmit}>
                <div className="flex flex-col mb-4">
                  <MultiSelect
                    label="User Name"
                    placeholder="Choose"
                    Options={user}
                    formik={formikEdit}
                    name="users"
                    width="w-full"
                  />
                  <FormikSelectTwo
            label="Type"
            placeholder="Choose"
            Options={[
              { value: "seal", label: "Seal" },
              { value: "signature", label: "Signature" },
            ]}
            formik={formikEdit}
            name="approvalType"
            width="w-full"
          />
                </div>
                <div className="flex justify-end gap-2">
                  <CancelButton handleClick={closeFilterDialog} />
                  <SubmitButton name="Submit" />
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    );
}

export default PaymentRequestStatus
