import React, { lazy } from "react";
import { Route } from "react-router-dom";
import MainProgress from "../Views/Web/Project/SubMenu/Progress/MainProgress";
// import MainInstallation from "../Views/Web/Project/SubMenu/Progress/Installation/MainInstallation";
// import Installation from "../Views/Web/Project/SubMenu/Progress/Installation";
// import CreateInstallation from "../Views/Web/Project/SubMenu/Progress/Installation/CreateInstallation";
// import EditInstallation from "../Views/Web/Project/SubMenu/Progress/Installation/EditInstalltion";
// import ViewInstallation from "../Views/Web/Project/SubMenu/Progress/Installation/ViewInstallation";
// import MainManHour from "../Views/Web/Project/SubMenu/Progress/ManHour/MainManHour";
// import ManHour from "../Views/Web/Project/SubMenu/Progress/ManHour";
// import CreateManHour from "../Views/Web/Project/SubMenu/Progress/ManHour/CreateManHour";
// import EditManHour from "../Views/Web/Project/SubMenu/Progress/ManHour/EditManHour";
// import ViewManHour from "../Views/Web/Project/SubMenu/Progress/ManHour/ViewManHour";
// import MainMaterialExpense from "../Views/Web/Project/SubMenu/Progress/MaterialExpense/MainMaterialExpense";
// import MaterialExpense from "../Views/Web/Project/SubMenu/Progress/MaterialExpense";
// import CreateMaterialExpense from "../Views/Web/Project/SubMenu/Progress/MaterialExpense/CreateMaterialExpense";
// import EditMaterialExpense from "../Views/Web/Project/SubMenu/Progress/MaterialExpense/EditMaterialExpense";
// import ViewMaterialExpense from "../Views/Web/Project/SubMenu/Progress/MaterialExpense/ViewMaterialExpense";
// import MainPattyCashExpense from "../Views/Web/Project/SubMenu/Progress/PattyCashExpense/MainPattyCashExpense";
// import PattyCashExpense from "../Views/Web/Project/SubMenu/Progress/PattyCashExpense";
// import CreatePattyCashExpense from "../Views/Web/Project/SubMenu/Progress/PattyCashExpense/CreatePattyCashExpense";
// import EditPattyCashExpense from "../Views/Web/Project/SubMenu/Progress/PattyCashExpense/EditPattyCashExpense";
// import ViewPattyCashExpense from "../Views/Web/Project/SubMenu/Progress/PattyCashExpense/ViewPattyCashExpense";
// import MainManPower from "../Views/Web/Project/SubMenu/Progress/ManPower/MainManPower";
// import ManPowerProgress from "../Views/Web/Project/SubMenu/Progress/ManPower";
// import CreateManPower from "../Views/Web/Project/SubMenu/Progress/ManPower/CreateManPower";
// import EditManPower from "../Views/Web/Project/SubMenu/Progress/ManPower/EditManPower";
// import ViewManPower from "../Views/Web/Project/SubMenu/Progress/ManPower/ViewManPower";
// import MainMaterialProgress from "../Views/Web/Project/SubMenu/Progress/Material/MainMaterial";
// import MaterialProgress from "../Views/Web/Project/SubMenu/Progress/Material";
// import CreateMaterialProgress from "../Views/Web/Project/SubMenu/Progress/Material/CreateMaterial";
// import EditMaterialProgress from "../Views/Web/Project/SubMenu/Progress/Material/EditMaterial";
// import ViewMaterialProgress from "../Views/Web/Project/SubMenu/Progress/Material/ViewMaterial";
// import MainOthersProgress from "../Views/Web/Project/SubMenu/Progress/Others/MainOthers";
// import OthersProgress from "../Views/Web/Project/SubMenu/Progress/Others";
// import CreateOthersProgress from "../Views/Web/Project/SubMenu/Progress/Others/CreateOthers";
// import EditOthersProgress from "../Views/Web/Project/SubMenu/Progress/Others/EditOthers";
// import ViewOthersProgress from "../Views/Web/Project/SubMenu/Progress/Others/ViewOthers";
// import MainEquipment from "../Views/Web/Project/SubMenu/Progress/Equipment/MainEquipment";
// import IndexEquipment from "../Views/Web/Project/SubMenu/Progress/Equipment/IndexEquipment";
// import CreateEquipment from "../Views/Web/Project/SubMenu/Progress/Equipment/CreateEquipment";
// import EditEquipment from "../Views/Web/Project/SubMenu/Progress/Equipment/EditEquipment";
// import ViewEquipment from "../Views/Web/Project/SubMenu/Progress/Equipment/ViewEquipment";
import { MainOthersProgress, OthersIndex } from '../Views/Web/Project/SubMenu/Progress/Others/index'
import { MainManPower, ManpowerIndex } from '../Views/Web/Project/SubMenu/Progress/ManPower/index'
import { MainMaterialProgress, MaterialIndex } from '../Views/Web/Project/SubMenu/Progress/Material/index'
import { MainEquipment, EquipmentIndex } from '../Views/Web/Project/SubMenu/Progress/Equipment/IndexEquipment'
import CreateManPowerTittlePro from "../Views/Web/Project/SubMenu/Progress/ManPower/CreateManPower";
import MultiEditManpowerPro from "../Views/Web/Project/SubMenu/Progress/ManPower/MultiEditManpowerpro";
import ViewManPower from "../Views/Web/Project/SubMenu/Progress/ManPower/ViewManPower";






function ProjectProgressRoutes() {
  return (
    <Route path="progress" element={<MainProgress />}>
      {/* <Route path="installation/:officeId/:projectId" element={<MainInstallation />}>
       <Route index element={<Installation/>}/>
       <Route path="create" element={<CreateInstallation/>} />
       <Route path="edit/:editInstallationId" element={<EditInstallation/>} />
       <Route path="view/:viewInstallationId" element={<ViewInstallation/>} />
      </Route> */}
      <Route path="man-power/:officeId/:projectId" element={<MainManPower />}>
      <Route index element={<ManpowerIndex/>}/>
       <Route path="create" element={<CreateManPowerTittlePro/>} />
       <Route path="edit/:editProgressManPowerId" element={<MultiEditManpowerPro/>} />
       <Route path="view/:viewProgressManPowerId" element={<ViewManPower/>} />
      </Route>
      <Route path="material/:officeId/:projectId" element={<MainMaterialProgress />}>
        <Route index element={<MaterialIndex />} />
        {/* <Route path="create" element={<CreateMaterialProgress />} />
        <Route path="edit/:materialProgressEditId" element={<EditMaterialProgress />} />
        <Route path="view/:materialProgressViewId" element={<ViewMaterialProgress />} /> */}
      </Route>
      <Route path="others/:officeId/:projectId" element={<MainOthersProgress />}>
        <Route index element={<OthersIndex />} />
        {/* <Route path="create" element={<CreateOthersProgress />} />
        <Route path="edit/:otherProgressEditId" element={<EditOthersProgress />} />
        <Route path="view/:otherProgressViewId" element={<ViewOthersProgress />} /> */}
      </Route>
      <Route path="equipment/:officeId/:projectId" element={<MainEquipment />}>
        <Route index element={<EquipmentIndex />} />
        {/* <Route path="create" element={<CreateEquipment />} />
        <Route path="edit/:equipmentProgressEditId" element={<EditEquipment />} />
        <Route path="view/:equipmentProgressViewId" element={<ViewEquipment />} /> */}
      </Route>
      {/* <Route path="material-expense/:officeId/:projectId" element={<MainMaterialExpense />}>
      <Route index element={<MaterialExpense/>}/>
       <Route path="create" element={<CreateMaterialExpense/>} />
       <Route path="edit/:editMaterialExpenseId" element={<EditMaterialExpense/>} />
       <Route path="view/:viewMaterialExpenseId" element={<ViewMaterialExpense/>} />
      </Route> */}
      {/* <Route path="petty-cash-expense/:officeId/:projectId" element={<MainPattyCashExpense />}>
      <Route index element={<PattyCashExpense/>}/>
       <Route path="create" element={<CreatePattyCashExpense/>} />
       <Route path="edit/:editPettyCashExpenseId" element={<EditPattyCashExpense/>} />
       <Route path="view/:viewPettyCashExpenseId" element={<ViewPattyCashExpense/>} />
      </Route> */}
    </Route>
  );
}

export default ProjectProgressRoutes;
