import React, { useEffect, useState } from 'react'
import ProgressLayout from '../ProgressLayout';
import TitleManpower from './TilteManpower';
import { useLocation, useParams } from 'react-router-dom';
import useFetchMataData from '../../../../../../Components/Hooks/useFetchMataData';
import { useNumber } from '../../../../../../Context/ProjectIdContext';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { EditButton, LongButton } from '../../../../../../Components/Utils/Buttons';
import Pagination from '../../../../../../Components/Utils/Pagenation';
import CreateManPowerTittlePro from './CreateManPower';
import MultiEditManpowerPro from './MultiEditManpowerpro';
import * as yup from 'yup';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { NuSelect } from '../../../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../../../Components/Utils/Select';

function ManpowerIndex() 
  {

    // const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const {value:Unit ,getData:refManPowerUnit} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerUnit')
  const location = useLocation();
  const searchQuery= location.state?.searchQuery

  
    const { officeId } = useParams();
    const { projectId } = useParams();
  const [data,setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter]= useState(false);
  const [filterData,setFilterData]= useState({
     unit: ""
  });
  
  const [afterFilter,setAfterFilter]= useState({})
  
  const [totalData, setTotalData] = useState(0);
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();
  const [multiEditOpen, setMultiEditOpen] = useState(false);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedExpandedItems, setSelectedExpandedItems] = useState([]);
  const [flatSelectedItemIds, setFlatSelectedItemIds] = useState([]);
  
  const handleMainCheckboxChange = (titleId, items) => {
    setSelectedTitles((prevSelectedTitles) =>
      prevSelectedTitles.includes(titleId)
        ? prevSelectedTitles.filter((id) => id !== titleId)
        : [...prevSelectedTitles, titleId]
    );
  
    setSelectedExpandedItems((prevSelectedExpandedItems) => ({
      ...prevSelectedExpandedItems,
      [titleId]: selectedTitles.includes(titleId)
        ? [] // Clear items if main checkbox is deselected
        : items.map(item => item.id), // Select all items if main checkbox is selected
    }));
  };
  
  const handleExpandedCheckboxChange = (titleId, itemId) => {
    setSelectedExpandedItems((prevSelectedExpandedItems) => ({
      ...prevSelectedExpandedItems,
      [titleId]: prevSelectedExpandedItems[titleId]?.includes(itemId)
        ? prevSelectedExpandedItems[titleId].filter((id) => id !== itemId)
        : [...(prevSelectedExpandedItems[titleId] || []), itemId],
    }));
  };
  
  useEffect(() => {
    const allSelectedIds = Object.values(selectedExpandedItems).flat();
    setFlatSelectedItemIds(allSelectedIds);
  }, [selectedExpandedItems]);
  
  const isTitleSelected = (titleId) => selectedTitles.includes(titleId);
  
  function getMaterial() {
    axiosInstance
      .get(`/pro_manpower/new/${projectId}?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalData(res.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(()=>{
    getMaterial();
   },[])
  
  //  function SearchData(e) {
  //   e.preventDefault();
  //   if (searchQuery != "") {
  //     axiosInstance
  //       .get(
  //         `/pro_manpower/new/${projectId}?page=${pageIndex}&per_page=${dataCount}`,
  //         { params: { search: `${searchQuery}` } }
  //       )
  //       .then((res) => {
  //         setData(res.data?.data);
  //         setTotalData(res.data?.total);
  //       })
  //       .catch((err) => console.log(err));
  //   } else {
  //     getMaterial();
  //   }
  // }
  
   const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      
    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axiosInstance
      .get(`/pro_manpower/new/${projectId}?page=${pageIndex}&per_page=${dataCount}`,{params:{unit:`${value.unit}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalData(res.data?.total)
  
      })
      .catch((error) => {
        console.log(error);
      });
    },
  });
  
  function FilterPage(){
    axiosInstance
      .get(`/pro_manpower/new/${projectId}?page=${pageIndex}&per_page=${dataCount}`,{params:{unit:`${afterFilter.unit}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalData(res.data?.total)
  
      }).catch(err=>console.log(err))
  }
  
  
  function ClearFilter(){
    formik.resetForm({})
    getMaterial();
    setIsFilter(false)
  }
  
  // useEffect(()=>{
  //  if(isFilter){
  //   FilterPage();
  //  }else if(searchQuery !=""){
  //     SearchData();
  //   }else{
  //   getMaterial()
  //   }
  // },[pageIndex,dataCount])
  
  // useEffect(() => {
  //   if (searchQuery == "") {
  //      getMaterial();
  //   }
  // }, [searchQuery]);
  
  const handleDelete = async(id) =>{
    confirm({
        title: `Do you Want to delete Material Items?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The Material Items will be removed from the Material`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/pro_manpower/multi-delete`, {
            headers: {
              'Content-Type': 'application/json',
            },
            data: { "ids": id } // Pass the payload as the `data` key in `delete`
          }).then((res) => {
            if(res.status === 201||res.status === 200) {
                getMaterial();
                api.success({
                  message: `Material Multi Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Material Multi Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Material Multi Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
  }
  
  
  const currentPage= location.state?.currentPage
   const [page, setPage] = useState(currentPage||1)
     const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
   const [params, setParams] = useState({
        page: page,
        per_page: pageCount,
        search:searchQuery||"",
    
        unit:"",
         });
  const [valueOfSearch,setValuesofSearch] = useState("")
     function searchFunction(e) {
   
       
       setValuesofSearch(e?.target?.value)
       setParams({ ...params, search: e?.target?.value, page:1 });
       setPage(1)
     }
  
  return (
    <>
    {contextHolder}
    <ProgressLayout
      setOpen={setOpen}
      onCreateClick={()=>{setCreateModalOpen(true)}}
      isFilter={isFilter}
      setFilterOpen={setFilterOpen}
      ClearFilter={ClearFilter}
      searchFun={searchFunction}
      searchValue={valueOfSearch}
      // SearchData={SearchData}
      // setSearchQuery={setSearchQuery}
      officeId={officeId}
      searchQuery={searchQuery} 
      neededSearch={true}
      projectId={projectId}
    >
                <div className="w-full h-[calc(100vh-153px)] p-3 overflow-hidden">
          <div className=" w-full h-[calc(100vh-195px)] overflow-y-scroll">
          {flatSelectedItemIds.length > 0 ? 
            <div className="w-full flex justify-end gap-2 py-2">
            <EditButton height='5' value="Multi Edit" handleClick={() => { setMultiEditOpen(true) }}/>
            <button
              type='button'
              className="px-2 h-5 cursor-pointer text-xs text-red-500 bg-white rounded-md flex items-center"
              style={{ border: "1px solid red" }}
              onClick={() => {handleDelete(flatSelectedItemIds)}}
            >
              Multi Delete
            </button>
          </div>
            :
            null
            }
            <table
              className="table-auto static top-0 w-full"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className=" w-full bg-white p-3 mt-4">
                <tr className="text-xs border-b  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-3 ${header.width}  ${
                        header?.isLeft ? "text-left" : "text-center"
                      } font-medium text-xs 2xl:text-sm text-[#4F4768]`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
        
            <div className={` w-full pt-0.5 ${flatSelectedItemIds.length > 0 ? "h-[calc(100vh-250px)]" : "h-[calc(100vh-243px)]"}  overflow-y-auto relative`}>
              <table
                className="table-auto  w-full"
                style={{ border: "none", borderCollapse: "collapse" }}
              >
                <tbody>
                  {data &&
                    data.map((item, i) => {
                      return (
                        <TitleManpower
                          data={item}
                          getData={getMaterial}
                          projectId={projectId}
                          selectedTitles={selectedTitles}
                          selectedExpandedItems={selectedExpandedItems}
                          onMainCheckboxChange={handleMainCheckboxChange}
                          onExpandedCheckboxChange={
                            handleExpandedCheckboxChange
                          }
                          isTitleSelected={isTitleSelected}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" my-[2px] px-3">
            <Pagination
              total={totalData}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              dataCount={dataCount}
              setDataCount={setDataCount}
            />
          </div>
        </div>
        <CreateManPowerTittlePro
          open={createModalOpen}
          onCancel={() => {
            setCreateModalOpen(false);
          }}
          getData={getMaterial}
        />
        <MultiEditManpowerPro
          open={multiEditOpen}
          onCancel={() => {
            setMultiEditOpen(false);
          }}
          getData={getMaterial}
          seletedIds={flatSelectedItemIds}
          projectId={projectId}
        />
    </ProgressLayout>
    {/* <ImportMaterial
        open={open}
        onCancel={() => setOpen(false)}
        getData={getMaterial}
        label="Import"
        estmationId={estmationId}
      /> */}
      <Modal
        open={filterOpen}
        title={"Filter Manpower"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
          <FormikSelectTwo
              label="Unit"
              Options={ Unit || [] }
              formik={formik}
              placeholder="Choose"
              name="unit"
              width="w-full"
              getFunc={refManPowerUnit}
              displayProperty={"Unit"}
              propertyName={"ManPowerUnit"}
              />
            <LongButton value='Filter'/>
          </div>
        </form>
      </Modal>
    </>
  )
  }
  
export default ManpowerIndex



const header = [
  // {
  //   headone: "ID",
  //   width: "w-[3%]",
  //   // isLeft: true,
  // },
  {
      headone: "Sl.No",
      width: "w-[5%]",
      isLeft: true,
    },
  {
    headone: "Description",
    width: "w-[10%]",
    isLeft: true,
  },

  {
    headone: "Unit",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Planned Days",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Actual Days",
    width: "w-[10%]",
    isLeft: true,
  },
  
  {
    headone: "Budget  Qty. ",
    width: "w-[10%]",
    isLeft: true,

  },

  {
    headone: "Budget Price",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Actual  Qty. ",
    width: "w-[10%]",
    isLeft: true,

  },

  {
    headone: "Actual Price",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Amt.",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[10%]",
  },
];