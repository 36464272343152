import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';
import { NuInput, NuTextArea } from '../../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../../Components/Utils/Select';

function MultiEditmanPower({open,onCancel,getData,seletedIds}) {
//   console.log("finding",estiId);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    role:"",
            type:"",
            quantity:"",
            days:"",
            
            amount:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
    //   name:yup.string().required("Tittle is Requried")
    }),
    onSubmit: (value,{resetForm}) => {    
      setIsLoading(true);
      const filteredValues = Object.fromEntries(
        Object.entries(value).filter(([_, v]) => v !== "")
      );
      const values = {
        ...filteredValues,
        ids: seletedIds
      }
      axiosInstance
      .put(`/manpower/multi-update`, values)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            setIsLoading(false);
            getData();
            resetForm();
            api.success({
              message: `T&C Multi Item Edit successfully`,
              description: res?.data?.msg || "Added In Tittle",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `T&C Multi Item Edit failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          api.error({
            message: `T&C Multi Item Edit failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    const totalPrice = formik?.values?.quantity * formik?.values?.unitPrice
    formik.setFieldValue("totalPrice", totalPrice);
  }, [formik?.values?.quantity,formik?.values?.unitPrice]);

  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Multi Edit Manpower Item"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
      <NuInput
              label="Role"
              placeholder="Role"
              formik={formik}
              name="role"
              width="w-full"
              isRequired={true}
          />
          <FormikSelectTwo
              label="Type"
              Options={[
                {value: 0, label:"Own"},
                {value: 1, label:"Rental"},
              ]}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full md:w-1/3"
          />
          <NuInput
           type="number"
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
           <NuInput
            type="number"
              label="No. of Days"
              placeholder="No. of Days"
              formik={formik}
              name="days"
              width="w-full md:w-1/3"
            />
            <NuInput
             type="number"
              label="Amount"
              placeholder="Amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/3"
            />
            <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
              <CancelButton handleClick={onCancel} />
              <SubmitButton isLoading={isLoading} />
            </div>
      </div>
      </form>
    </Modal>
    </>
  )
}

export default MultiEditmanPower;