import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { GetAccess } from "../../../Components/Utils/roles"
import Breadcrumb from "../../../Components/Utils/Breadcrumb"
import SearchBar from "../../../Components/Utils/SearchBar"
import { ClearFilterButton, CreateButton, FilterButton, ImportButton } from "../../../Components/Utils/Buttons"
import axiosInstance from "../../../Services/axiosCommon"
import { useNumber } from "../../../Context/ProjectIdContext"
import { Select } from "antd"

const StoreLayout = (
    {
        getData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        onCreateClick,
        searchFun=()=>{},
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        searchValue="",
        select=false


    }
) =>{
    let {pathname} = useLocation()
    let {officeId} = useParams()
    const [projectList,setProjectList]= useState([])

    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/main/${officeId}/store/shipment-tracking`){
            setModule("Shipment Status")
        }else if(pathname == `/main/${officeId}/store/grn`){
            setModule("GRN")
        }else if(pathname == `/main/${officeId}/store/inventory`){
            setModule("Inventory")
        }else if(pathname == `/main/${officeId}/store/assets`){
            setModule("Asset")
        }
        // else if(pathname == `/main/${officeId}/store/delivery-note`){
        //     setModule("Delivery Note")
        // }else if(pathname == `/main/${officeId}/store/packing-list`){
        //     setModule("Packing List")
        // }
        else if(pathname == `/main/${officeId}/store/returns`){
            setModule("Return")
        }else if(pathname == `/main/${officeId}/store/requests`){
            setModule("Request")
        }
    },[pathname])

    let LinkItem = [
        {
            label:"Shipment Status",
            link:`/main/${officeId}/store/shipment-tracking`,
            access:GetAccess(),
        },
        {
            label:"GRN",
            link:`/main/${officeId}/store/grn`,
            access:GetAccess(),
        },
        {
            label:"Inventory",
            link:`/main/${officeId}/store/inventory`,
            access:true,
        },
        {
            label:"Asset",
            link:`/main/${officeId}/store/assets`,
            access:GetAccess(),
        },
       
        // {
        //     label:"Delivery Note",
        //     link:`/main/${officeId}/store/delivery-note`,
        //     access:true,
        // },
       
        // {
        //     label:"Packing List",
        //     link:`/main/${officeId}/store/packing-list`,
        //     access:GetAccess(),
        // },
      
        {
            label:"Return",
            link:`/main/${officeId}/store/returns`,
            access:GetAccess(),
        },
        {
            label:"Request",
            link:`/main/${officeId}/store/requests`,
            access:GetAccess(),
        },
    ]
    
    const {number, updateNumber} = useNumber()

    function getProjectByOffice(id){
        axiosInstance.get(`/DropDown/${id}/getAllProjects`).then(res=>{
            let tempValue = res?.data?.data||[];
            let arr = []
            tempValue.map(i=>{
                let val = {
                    value:i?.projId||"",
                    label:i?.projName||""
                }
                arr.push(val)
            })
            if(number ==0){
                updateNumber(arr[0]?.value)
            }
            setProjectList(arr)
        }).catch((error) => {
            console.error("Error:", error);
              
          });
    }

    useEffect(()=>{
        if(number==0||pathname !="") getProjectByOffice(officeId)
    },[pathname])

    function handleProjectChange(e){
        console.log(e);
        updateNumber(e)
    }

    return(
        <div className=" w-full  h-full py-2 px-4 overflow-hidden">
            <div className="">
            <div className="md:flex justify-between w-full ">
            {/* <Breadcrumb items={[
               
                {
                    name:"Store"
                },
                { name: `${module}` },

            ]} /> */}
             <div className=" w-full  flex items-center gap-x-1 pt-2 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                                    <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                                     )
                         }
                        </>
                    )
                })
            }
        </div>

            <div className=" flex justify-end items-center gap-3 ">
            <div>
                
                 <SearchBar handleChange={searchFun}  value={searchValue}/>
                </div>
                {select && 
            <form className=" w-48 flex justify-end items-center xl:gap-3 mx-0" onSubmit={getData}>
          <Select
            style={{ width: '100%' }}
            options={projectList}
            value={number}
            disabled={projectList.length >= 2 ? false : true}
            // allowClear={formik.values[name]?true:false}
            // name={name}
            // placeholder={placeholder}
            onChange={handleProjectChange}
          />
            </form>}
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <ClearFilterButton label='Clear Filter'  handleClick={ClearFilter} />} 
               {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}

              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
           
           
            <div className=" w-full h-[calc(100vh-140px)]  ">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default StoreLayout;