import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function StatusIncoming() {
    return (
        <SettingsMetaData propertyName={"IncomingStatus"} viewPropertyName={"Status"} />
      );
}

export default StatusIncoming
