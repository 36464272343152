import React, { useState } from "react";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import ViewMore from "./ViewMore";


const ExpandableMaterial = ({data,getData, titleId, selectedExpandedItems, onExpandedCheckboxChange,projectId }) => {
  // console.log('====================================');
  // console.log(titleId);
  // console.log('====================================');
  return (
    <>
    {
        data.map((item, index) => (
            <tr className={`w-[100%] bg-white text-xs font-normal text-[#71797E]`} style={{borderBottom:'1px solid #c7dfe0'}} key={index}>
            <td className=" w-[5%] py-3 px-1">
                <div className=" w-full gap-1 flex">
                <input 
            type="checkbox" 
            checked={selectedExpandedItems[titleId]?.includes(item.id) || false}
            onChange={() => onExpandedCheckboxChange(titleId, item.id)}
            />
                {index+1}
                </div>
            </td>
            <td className=" w-[20%] py-3 px-1">{item?.description||"-"}</td>
            <td className=" w-[9%] py-3 px-1">{item?.unit||"-"}</td>
            <td className=" w-[9%] py-3 px-1">{item?.budget_quantity||"-"}</td>
            <td className=" w-[14%] py-3 px-1">{<NuAmount value={item?.budget_Price||0}/>}</td>
            <td className=" w-[9%] py-3 px-1">{item?.actual_quantity||"-"}</td>
            <td className=" w-[14%] py-3 px-1">{<NuAmount value={item?.actual_Price||0}/>}</td>
            <td className=" w-[15%] py-3 px-1">{<NuAmount value={item?.amount||0} />}</td>
            <td className=" w-[5%] py-3 pl-3">
                         <ViewMore 
                            id={titleId || ""}
                            getData={getData}
                            data={item}
                            projectId={projectId}
                            /> 
            </td>
           </tr>
        ))
    }
    </>
  );
};

export default ExpandableMaterial;
