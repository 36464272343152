import React from "react";

function Products({ color = "#999999", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M20 7L4 7"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />{" "}
        <path
          d="M15 12L4 12"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />{" "}
        <path
          d="M9 17H4"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />{" "}
      </g>
    </svg>
  );
}

export default Products;
