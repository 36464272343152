import React from 'react'
import StatusDiary from './Status'
import { TextMediumSM } from '../../../../Components/Text/MediumText'

function DiaryIndex() {
    return(
        <>
        {/* <TextMediumSM content="Progress" /> */}
        <TextMediumSM content="Status" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CategoryOthers/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DivisionOthers/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <StatusDiary/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <UnitMaterial/>
         </div> */}
         </div>
         </>
    )
}

export default DiaryIndex
