import { Link, useParams } from 'react-router-dom';
import More from '../../../../../../Components/Icons/More';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from "@ant-design/icons";
import { GetAccess } from '../../../../../../Components/Utils/roles';
import ViewIcon from '../../../../../../Components/Icons/ViewIcon';
import EditIcon from '../../../../../../Components/Icons/Edit';
import DeleteIcon from '../../../../../../Components/Icons/Delete';


const {confirm} = Modal


const ViewMore = ({ data,getData,id}) => {
  const {officeId,projectId} = useParams()
  const [api, contextHolder] = notification.useNotification();


  const handleDelete = async (id, removevalue) => {
    confirm({
      title: `Do you Want to delete ${removevalue}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `The ${removevalue} will be removed from the Manpower List`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        axiosInstance.delete(`/man_power_cost/${projectId}`).then((response) => {
            if (response.data?.status == true) {
              api.success({
                message: `${removevalue}  Deleted successfully`,
                // description:'',
                placement: "bottomRight",
              });
              getData();
            } else {
              api.error({
                message: `${removevalue} Deleted failed`,
                description: response?.data?.msg || "Network error",
                placement: "bottomRight",
              });
            }
          })
          .catch((error) => {
            api.error({
              message: `Project Costing  Deleted failed`,
              description: error?.response?.data?.msg || "Sever error (or) Network error",
              placement: "bottomRight",
            });
          });
      },
      onCancel() {},
    });
  };


  return (
    <>
        {contextHolder}

      {/* <div className='text-[#344767]  relative dropdown inline-block z-50'>
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className='pt-1 z-10'>
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne bg-red-400  text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
        <li className=' w-[100px] bg-black block m-0'>

             
            <Link
              className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium' 
             to={`/project/costing/man-power/${officeId}/${projectId}/view/${id}`} state={{data:data}}>View</Link>
          
          </li>
          {
            GetAccess()&& (
              <li className=' w-[100px] bg-black block m-0'>

              <Link 
                className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium' 
              to={`/project/costing/man-power/${officeId}/${projectId}/edit/${id}`} state={{data:data}}>Edit</Link>
              
            </li>
            )
          }
          {
            GetAccess()&& (
              <li className=''>
              <a
                className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                to='#'
                onClick={() => {
                  handleDelete(id,` ${data?.designation||""} ${data?.projectName||""}`);
                }}
              >
                Delete
              </a>
            </li>   
            )
          }
        </ul>
      </div> */}

      <div className='w-full flex justify-between items-center'>
        <div>  <Link
              
             to={`/project/costing/man-power/${officeId}/${projectId}/view/${id}`} state={{data:data}}><ViewIcon color="#048178" /></Link>              
        </div>
        <div>   
        <Link 
              to={`/project/costing/man-power/${officeId}/${projectId}/edit/${id}`} state={{data:data}}><EditIcon color="skyblue" width={"12"} height={"12"} /></Link>             
        </div>
        <div>     
        <a
                
                to='#'
                onClick={() => {
                  handleDelete(id,` ${data?.id||""} ${data?.serial_number||""}`);
                }}
              >
                <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
              </a>                 
        </div>

      </div>


    </>
  );
};

export default ViewMore;
