import React, { useState } from "react";
import NuAmount from "../../../../../Components/Utils/NuAmount";
import ViewMore from "./ViewMore";


const ExpandableOthers = ({data,getData, titleId, selectedExpandedItems, onExpandedCheckboxChange,estimationId }) => {
   
  return (
    <>
      {data.map((item, index) => (
        <tr
          className={`w-[100%] bg-white text-xs font-normal text-[#4f4768]`}
          // style={{ borderBottom: "1px solid #c7dfe0" }}
          key={index}
        >
          <td className=" w-[5%] py-3 px-1">
            <div className=" w-full gap-1 flex">
              <input
                type="checkbox"
                checked={
                  selectedExpandedItems[titleId]?.includes(item.id) || false
                }
                onChange={() => onExpandedCheckboxChange(titleId, item.id)}
              />
              {index + 1}
            </div>
          </td>
          <td className=" w-[35%] py-3 px-1 font-normal text-[#4f4768]">{item?.description || "-"}</td>
          <td className=" w-[15%] py-3 px-1 font-normal text-[#4f4768]">{item?.unit}</td>
          <td className=" w-[10%] py-3 px-1 font-normal text-[#4f4768]">{item?.quantity}</td>
          <td className=" w-[15%] py-3 px-1 font-normal text-[#4f4768]">
            <NuAmount value={item?.unitPrice || 0} />
          </td>
          <td className=" w-[15%] py-3 px-1 font-normal text-[#4f4768]">
            <NuAmount value={item?.amount || 0} />
          </td>
          <td className=" w-[5%] py-3 px-1">
          <ViewMore 
            id={titleId || ""}
            getData={getData}
            data={item}
            estimationId={estimationId}
          />
          </td>
        </tr>
      ))}
    </>
  );
};

export default ExpandableOthers;
