import { useEffect, useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import { useFormik } from "formik";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import Dasboard from "../../../../Components/Icons/Dasboard";
import { Link } from "react-router-dom";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import { Button, Modal } from "antd";
import NuDate from "../../../../Components/Utils/NuDate";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { NuSelect } from "../../../../Components/Utils/Input";



function ProjectReport() {
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter] = useState(false)
  const [data,setData] = useState([])
  const [xlsData,setXlsData] = useState([])
  const [filterData,setFilterData]= useState({
    leadBy: "",
    status: "",
    start_date:"",
    end_date:""
  });


  function DateDiff(createDate,expiryDate){
    let startDate = new Date(createDate)
    let endDate = new Date(expiryDate)
    let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays==0?diffDays+1:diffDays+1;
  }
  function DateRemaining(createDate,expiryDate){
    let startDate = ""
    let dateNow = new Date();
    let startDateTemp = new Date(createDate);
    let endDateTemp = new Date(expiryDate);
    if(dateNow.getTime()>=endDateTemp.getTime()){
      return '0'
    }else{
    if(dateNow.getTime() < startDateTemp.getTime()){
      startDate = startDateTemp;
    }else{
      startDate = dateNow
    }
    let endDate = new Date(expiryDate)
    let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays==0?diffDays+1:diffDays+1;
  }
  }

  function DateWorked(createDate,expiryDate){
    return DateDiff(createDate, expiryDate) - DateRemaining(createDate,expiryDate)
  }


  function getAllData(){
    axiosInstance.get(`/report/project`).then(res=>{
      console.log("ssss",res?.data?.data);
      let forMap = res?.data?.data;
      let tempArr = []
      forMap && forMap.forEach((item,index)=>{
      let tempData = {
        "Sl.No":index+1,
        "Project Name":item.projectName,
        // "Scope of Work":item.projectScrope,
        "Lead By": `${[...item?.LeadBy]}`,
        "Awarded on":item?.awardedOn  && item?.awardedOn !="" && item?.awardedOn !="None" && NuDate({value:item?.awardedOn, format:"DD-MMM-YYYY"}),
        "Start Date":item?.startDate  && item?.startDate !="" && item?.startDate !="None" && NuDate({value:item?.startDate, format:"DD-MMM-YYYY"}),
        "End Date":item?.endDate  && item?.endDate !="" && item?.endDate !="None" && NuDate({value:item?.endDate, format:"DD-MMM-YYYY"}),
        "Days Worked":`${DateWorked(item?.startDate,item?.endDate)||0} ${DateWorked(item?.startDate,item?.endDate)>=2?'days':'day'}`,
        "Remaining Days":`${DateRemaining(item?.startDate,item?.endDate)||0} ${DateRemaining(item?.startDate,item?.endDate)>=2?'days':'day'}`,
        "Progress	":item?.progress,
        "Status":item?.status
      }
      tempArr.push(tempData)
      })
      setXlsData(tempArr);
      res.data?.data && setData(res?.data?.data)
    }).catch(err=>{
      console.log(err);
    })
  }


  useEffect(()=>{
    getAllData()
  },[])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   mobileNo: yup
    //     .string()
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    // }),
    onSubmit: async (value, { resetForm }) => {
    
      setIsFilter(true)
      axiosInstance
      .get(`/report/project?leadBy=${value.leadBy}&status=${value.status}&start_date=${value.start_date}&end_date=${value.end_date}`)
      .then((res) => {
        setFilterOpen(false)
        let forMap = res?.data?.data;
        let tempArr = []
        forMap && forMap.forEach((item,index)=>{
        let tempData = {
          "Sl.No":index+1,
          "Project Name":item.projectName,
          // "Scope of Work":item.projectScrope,
          "Lead By": `${[...item?.LeadBy]}`,
          "Awarded on":item?.awardedOn  && item?.awardedOn !="" && item?.awardedOn !="None" && NuDate({value:item?.awardedOn, format:"DD-MMM-YYYY"}),
          "Start Date":item?.startDate  && item?.startDate !="" && item?.startDate !="None" && NuDate({value:item?.startDate, format:"DD-MMM-YYYY"}),
          "End Date":item?.endDate  && item?.endDate !="" && item?.endDate !="None" && NuDate({value:item?.endDate, format:"DD-MMM-YYYY"}),
          "Days Worked":`${DateWorked(item?.startDate,item?.endDate)||0} ${DateWorked(item?.startDate,item?.endDate)>=2?'days':'day'}`,
          "Remaining Days":`${DateRemaining(item?.startDate,item?.endDate)||0} ${DateRemaining(item?.startDate,item?.endDate)>=2?'days':'day'}`,
          "Progress	":item?.progress,
          "Status":item?.status
        }
        tempArr.push(tempData)
        })
        setXlsData(tempArr);
        res.data?.data && setData(res?.data?.data)
        

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });


  const {value} = useFetchMataData(`/Metadata/projectstatus`)
  
  function ClearFilter(){
    formik.resetForm({})
    getAllData()
    setIsFilter(false)
  }




  return (
    <div className=" w-full h-[90vh] px-4 py-2 overflow-hidden">
      <div className="md:flex justify-between w-full">
      <Breadcrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<Link to='/reports'><p className=' text-[12px] '>Reports Lists</p></Link>)},{title:(<p className=' text-[12px] py-[1px]'>Projects</p>)}]} />
      <div className='  flex justify-center items-center float-right'>
      <div className=" pb-2 w-full flex justify-center gap-2 items-center ">
      <div className=" flex justify-center items-center gap-3 md:mr-6">
      <CreateButton label="Filter" handleClick={()=>setFilterOpen(true)}   />
      {isFilter&& <CreateButton label='Clear Filter' handleClick={ClearFilter} />}
      {/* <CreateButton label="Excel" handleClick={()=>{}}   /> */}
      {/* <ExportXls csvData={xlsData} fileName="Project Report"  />
      <CreateButton label="PDF" handleClick={()=>{}}   /> */}
      <BackButton/>
      </div>
      </div>
      </div>
      </div>

      <div className=" w-full h-[98%]   overflow-y-auto">
      <table className="table-auto w-full" style={{ border: 'none', borderCollapse: 'collapse' }}>
      <thead className=" w-full bg-[#2A475E]">
      <tr className="text-xs  font-medium   py-2 ">
      {header.map((header, i) => (
                <th className={`py-2 ${header.width} font-normal ${header?.isLeft?"text-left":"text-center"} text-xs px-2 text-white`} key={i} style={{ border: 'none', borderCollapse: 'collapse' }}>
                  {header.headone}
                </th>
              ))}
      </tr>
      </thead>
      <tbody>
      {
        data && data.map((item,idx)=>{
          return(
            <tr className='`w-[100%] bg-[#F6F9FF] text-xs' key={idx}>
            <td className=' pl-2 py-2'>{item?.projectName||""}</td>
            <td className=' pl-2 py-2'>{
              item?.LeadBy && item?.LeadBy.length>0 && item?.LeadBy.map((i,idx)=>{
                return(
                  <div key={idx}>{i}</div>
                )
              })
            }</td>
            <td className=' pl-2 py-2 '>{ item?.awardedOn  && item?.awardedOn !="" && item?.awardedOn !="None" && <NuDate value={item?.awardedOn} format="DD-MMM-YYYY" />  ||""}</td>
            <td className=' pl-2 py-2 '>{ item?.startDate  && item?.startDate !="" && item?.startDate !="None" && <NuDate value={item?.startDate} format="DD-MMM-YYYY" />  ||""}</td>
            <td className=' pl-2 py-2 '>{ item?.endDate  && item?.endDate !="" && item?.endDate !="None" && <NuDate value={item?.endDate} format="DD-MMM-YYYY" />  ||""}</td>
            <td className=' pl-2 py-2 '>{DateWorked(item?.startDate,item?.endDate)||0} {DateWorked(item?.startDate,item?.endDate)>=2?'days':'day'}</td>
            <td className=' pl-2 py-2 '>{DateRemaining(item?.startDate,item?.endDate)||0} {DateRemaining(item?.startDate,item?.endDate)>=2?'days':'day'}</td>
            <td className=' pl-2 py-2 '>{item?.progress||0}</td>
            <td className=' pl-2 py-2 '>{item?.status||""}</td>
          </tr>
          )
        })
      }
      </tbody>
      </table>
      </div>
      <Modal
        open={filterOpen}
        title={"Filter All Tasks"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={()=>setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuDate
              label="Start Date"
              formik={formik}
              placeholder="Choose"
              name="start_date"
              width=" w-1/2"
            />
            <NuDate
              label="End Date"
              formik={formik}
              placeholder="Choose"
              name="end_date"
              width=" w-1/2"
            />

            <NuSelect
              label="Lead by"
              options={[]}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
            />
            <NuSelect
              label="Status"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default ProjectReport;


const header = [
  {
    headone: "Project Name",
    width: "w-[18%] ",
    isLeft: true,
  },

  {
    headone: "Lead by",
    width: "w-[13%]",
    isLeft: true,

  },
  {
    headone: "Awarded on",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Start Date",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Due Date",
    width: "w-[8%]",
    isLeft: true,
    
  },
  {
    headone: "Days Worked",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Remaining Days",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Progress",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Status",
    width: "w-[10%]",
    isLeft: true,

  },

];