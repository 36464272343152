import React, { useState } from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import axiosInstance from '../../../../../Services/axiosCommon';
import { Link, useParams } from 'react-router-dom';
import CreateTeam from './CreateTeam';
import ViewTeam from './ViewTeam';
import VendorForm from './VedorForm';

const { confirm } = Modal;

export default function ViewMoreTeam({teamId,data,getData,list,projectId,getCountData}){
  const {officeId}=useParams()
  console.log("data view",data,projectId);
    const [showMyModal, setShowMyModal] = useState(false);
    const handleOnClose = () => setShowMyModal(false);

    const handleDelete = async(id,removevalue) =>{
      confirm({
          title: `Do you Want to delete ${removevalue}?`,
          centered:true,
          icon: <ExclamationCircleFilled />,
          content: `The ${removevalue} will be removed from the Teams List`,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            axiosInstance.delete(`/Team/delete/${id}`)
            .then((res) => {
              console.log(res);
              getData();
              // onCancel();
          })
          .catch((err) => {
              console.log(err);
          });

          },
          onCancel() {
          },
        });
  }

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
let TeamEdit = userAccess
  ? userAccess.some(
      (permission) => permission.permissionName == "Team_edit"
    )
  : false;
  let TeamDelete = userAccess
  ? userAccess.some(
      (permission) => permission.permissionName == "Team_delete"
    )
  : false;

    return(
        <>
        <div className='text-[#344767]  relative dropdown inline-block z-50'>
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className='pt-1'><svg className='m-auto w-[4px]' viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="1.83391" cy="2.38902" rx="1.83" ry="1.82988" fill="#171A21"/>
        <ellipse cx="1.83391" cy="7.30914" rx="1.83" ry="1.82988" fill="#171A21"/>
        <ellipse cx="1.83391" cy="12.2293" rx="1.83" ry="1.82988" fill="#171A21"/>
        </svg></span>
        </div>
        <ul className='dropdown-menuOne bg-red-400  text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>

       {
        TeamEdit && <li className=''>
        <span
          class='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 text-black hover:text-black block whitespace-no-wrap text-xs font-medium'
          // to='/members'
          onClick={() => {
            setShowMyModal(true);
          }}
        >
          Edit
        </span>
      </li>
       } 

       {
        TeamEdit && <li className=''>
        <Link
          class='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
          to={`/project/team/${officeId}/${projectId}/edit`}
          state={{teamId:teamId,memberList:list||[],projectId:projectId}}
          // onClick={() => {
          //   handleDelete(data.userId);
          // }}
        >
          Edit Team
        </Link>
      </li>
       }


       {
        TeamDelete &&           <li className=''>
        <span
          class='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 text-black hover:text-black block whitespace-no-wrap text-xs font-medium'
          // to='/members'
          onClick={() => {
            handleDelete(data.teamId,data?.teamName);
          }}
        >
          Delete
        </span>
      </li>
       }



        </ul>
      </div>
       
      <VendorForm open={showMyModal} onCancel={handleOnClose} getData={getData} getCountData={getCountData}  data={data} projectId={projectId} />
      </>
    )
}