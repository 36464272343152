import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  ImportButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import Importdoc from "./Importdoc";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";
import StoreLayout from "../StoreLayout";

function AssetsIndex() {
  
  const {officeId} =useParams
  ();

  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
  const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
  const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)
 
  const [filterProps, setFilterProps] = useState({
    category:"",
subCategory:"",
brand:"",
manufacturer:"",
unit:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",
    category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    unit:""
  });

  const clearFilter = ()=> {
    setParams({...params, category:"",
      subCategory:"",
      brand:"",
      manufacturer:"",
      unit:""})
      setFilter(false)
      formik.values.category=""
      formik.values.brand=""
      formik.values.subCategory=""
      formik.values.manufacturer=""
      formik.values.unit=""

  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, subCategory:value.subCategory,brand:value.brand,manufacturer:value.manufacturer,unit:value.unit})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        category:"",
        subCategory:"",
        brand:"",
        manufacturer:"",
       
        unit:""
      });
    }
  })

  const { data, totalCount, getData,dataLoading } = UseGetDataSearch(`/assets/${officeId}/branch`,params);

  let defaultHeader= [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "stockId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Asset No.",
      width: "10%",
      isLink: true,
      id: "stockId",
      link: `/main/${officeId}/store/assets/view/`,
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "GRN No.",
      width: "10%",
      isLink: true,
      id: "stockId",
      link: `/main/${officeId}/store/assets/view/`,
      field: "grnRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Product",
      width: "10%",
      isLink: true,
      id: "stockId",
      link: `/main/${officeId}/store/assets/view/`,
      field: "productName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Unit",
      width: "10%",
      field: "unit",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Category",
      width: "10%",
      field: "category",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Stock Qty",
      width: "10%",
      field: "stockQty",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"stockId",
        removeValue:"name",
        url:`/assets/`,
        moduleName:"Asserts",
        from:`/main/${officeId}/store/assets`,
        editLink:`/main/${officeId}/store/assets/edit/`,
        viewLink:`/main/${officeId}/store/assets/view/`
      }
    },
  ]
  let availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "stockId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Asset No.",
      width: "10%",
      isLink: true,
      id: "stockId",
      link: `/main/${officeId}/store/assets/view/`,
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "GRN No.",
      width: "10%",
      isLink: true,
      id: "stockId",
      link: `/main/${officeId}/store/assets/view/`,
      field: "grnRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Product",
      width: "10%",
      isLink: true,
      id: "stockId",
      link: `/main/${officeId}/store/assets/view/`,
      field: "productName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Unit",
      width: "10%",
      field: "unit",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Supplier",
      width: "10%",
      field: "supplier",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Category",
      width: "10%",
      field: "category",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Sub Category",
      width: "10%",
      field: "subCategory",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Adjusted Qty",
      width: "10%",
      field: "adjustedQty",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Issued Qty",
      width: "10%",
      field: "issuedQty",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Received Qty",
      width: "10%",
      field: "receivedQty",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Stock Qty",
      width: "10%",
      field: "stockQty",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Barcode",
      width: "10%",
      field: "barcode",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Batch No",
      width: "10%",
      field: "batchNo",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Brand",
      width: "10%",
      field: "brand",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Calib Exp",
      width: "10%",
      field: "calibExp",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Calib Require",
      width: "10%",
      field: "calibReq",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Code",
    //   width: "10%",
    //   field: "code",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Condition",
      width: "10%",
      field: "condition",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Exp Date",
      width: "10%",
      field: "expDate",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Manufacturer",
      width: "10%",
      field: "manufacturer",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "MFD Date",
      width: "10%",
      field: "mfdDate",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Min Stock Level",
      width: "10%",
      field: "minStockLevel",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "MRP",
      width: "10%",
      field: "mrp",
      type: "amount",

      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Name",
    //   width: "10%",
    //   field: "name",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
  
    {
      title: "Purchased Date",
      width: "10%",
      field: "purchasedDate",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Quantity",
    //   width: "10%",
    //   field: "quantity",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Reason For Adjust",
      width: "10%",
      field: "reasonForAdjust",
      textAlign: "center",
      rowAlign: "center",
    },
  
    // {
    //   title: "SerialNo",
    //   width: "10%",
    //   field: "serialNo",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Stock Location",
      width: "10%",
      field: "stockLocation",
      textAlign: "center",
      rowAlign: "center",
    },
  
    
   
    // {
    //   title: "Unit Price",
    //   width: "10%",
    //   field: "unitPrice",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Warrenty Applicable",
      width: "10%",
      field: "warrentyApplicable",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Warrenty Duration",
    //   width: "10%",
    //   field: "warrentyDuration",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Warrenty Start Date",
      width: "10%",
      field: "warrentyStartDate",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Warrenty End Date",
      width: "10%",
      field: "warrentyEndDate",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"stockId",
        removeValue:"name",
        url:`/assets/`,
        moduleName:"Asserts",
        from:`/main/${officeId}/store/assets`,
        editLink:`/main/${officeId}/store/assets/edit/`,
        viewLink:`/main/${officeId}/store/assets/view/`
      }
    },
  ]
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  const { category: itemsCategory, subCategory: itemsSubCategory } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);

  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  const [valueOfSearch,setValuesofSearch] = useState("")
   function searchFunction(e) {
 
     
     setValuesofSearch(e?.target?.value)
     setParams({ ...params, search: e?.target?.value, page:1 });
     setPage(1)
   }
  
  return (
    <>
    <StoreLayout 
    //  setOpen={closeModal}
    searchFun={searchFunction}
    searchValue={valueOfSearch}
     getData={getData}
     isFilter={filter}
     setFilterOpen={setShowFilterDialog}
     ClearFilter={clearFilter}
     CreateAccess={GetAccess("assets_create")}
    //  ImportAccess={GetAccess("assets_create")}
     officeId={officeId}
     // projectId={projectId}
       >
    <div className="w-full h-full ">
      {/* <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Store",
              link:''
            },
            {
              name: "Assets",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />    <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
          {/* <ImportButton handleClick={handleImportClick} /> */}
          {/* {GetAccess("assets_create") &&  <Link to={`/main/${officeId}/store/assets/create`}>
            <CreateButton label="Assets" />
          </Link>}
        </div>
      </div> */}

      <CustomDataTable
      fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        // title="Asset List"
        storageName="AssetTable"
        getById="stockId"
        editAccess="assets_edit"      
        deteleAccess="assets_delete" 
        dataLoading={dataLoading}
        searchQuery={params?.search!=""?params?.search:""}

      />
      </div>
      </StoreLayout>
        {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Assets</h2>
                 <form onSubmit={formik.handleSubmit}> 
                 <div className="flex flex-col mb-4">
                      <FormikSelectTwo 
                        label="Category"
                        placeholder="Choose"
                        Options={itemsCategory}
                        formik={formik}
                        name="category"
                        width="w-full"
                      />
                       <FormikSelectTwo
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={itemsSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full "
            />
                      
              <FormikSelectTwo
                type="text"
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-full "
              />
             <FormikSelectTwo
                type="text"
                label="Manufaturer"
                placeholder="Choose"
                Options={Manufacture}
                formik={formik}
                name="manufaturer"
                width="w-full "
              />
               <FormikSelectTwo
                type="text"
                label="Brand"
                placeholder="Choose"
                Options={brand}
                formik={formik}
                name="brand"
                width="w-full "
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
    </>
  );
}

export default AssetsIndex;
