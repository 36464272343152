// import React, { useEffect, useState } from "react";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
// import axiosInstance from "../../../../Services/axiosCommon";
// import SearchBar from "../../../../Components/Utils/SearchBar";
// import Pagination from "../../../../Components/Utils/Pagenation";
// import capitalizeFirstLetter from "../../../../Components/Utils/Funcs";
// import FileIcon from "../../../../Components/Icons/FileIcon";
// import NuAmount from "../../../../Components/Utils/NuAmount";
// import { useLocation, useParams } from "react-router-dom";
// import ProcurementLayout from "../ProcurementLayout";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../../../../../Services/axiosCommon";
import ProcurementsubLayout from "../ProcurementLayout";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import capitalizeFirstLetter from "../../../../../../Components/Utils/Funcs";
import FileIcon from "../../../../../../Components/Icons/FileIcon";

const TeamPerformanceIndex = () => {
  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
 
  const [params, setParams] = useState({
    page:page,
    per_page: pageCount,
    search:searchQuery||"",

  });

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);

  async function getData() {
    setDataLoading(true);
    try {
      const res = await axiosInstance.get("/team-performance/", { params });
      setData(res?.data?.data || []);
      setTotalCount(res?.data?.total || 0);
      setDataLoading(false);
    } catch (error) {
     
      setDataLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [params]);

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

  const items = data?.items || [];
  const purchasers = data?.purchasers || [];
  const yprList = data?.ypr || [];

  const [valueOfSearch,setValuesofSearch] = useState("")
    function searchFunction(e) {
  
      
      setValuesofSearch(e?.target?.value)
      setParams({ ...params, search: e?.target?.value, page:1 });
      setPage(1)
    }
  const {officeId} =useParams()

  return (
    <>
    <ProcurementsubLayout 
    //  setOpen={closeModal}
    searchFun={searchFunction}
    searchValue={valueOfSearch}
    neededSearch={true}

     getData={getData}
     // isFilter={filter}
    //  setFilterOpen={setShowFilterDialog}
    //  ClearFilter={clearFilter}
    //  CreateAccess={GetAccess("purchase_request_create")}
    //  ImportAccess={GetAccess("purchase_request_create")}
     officeId={officeId}
     // projectId={projectId}
       >
    <div className="w-full h-full">
      {/* <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[{ name: "Procurement" }, { name: "Team Performance" }]}
        />
        <div className="flex gap-2">
          <SearchBar
            handleChange={(e) =>{
              setParams({ ...params, search: e.target.value, page:1 });
              setPage(1)
            }
            }
          />
        </div>
      </div> */}

      <div className="w-full h-[calc(96vh-170px)] bg-white mt-4 mr-4 pr-4 rounded-md overflow-hidden">
        {dataLoading ? (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <div className="flex flex-col items-center">
              <div className="flex gap-2 mb-2">
                {[0, -0.3, -0.5, -0.7].map((delay, index) => (
                  <div
                    key={index}
                    className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce"
                    style={{ animationDelay: `${delay}s` }}
                  />
                ))}
              </div>
              <p className="text-sm font-medium text-baseColor-secondary">
                Fetching Data...
              </p>
            </div>
          </div>
        ) : data.length === 0 ? (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <div className="flex flex-col items-center">
              <FileIcon  className="text-3xl text-baseColor-secondary" />
              <p className="font-medium text-sm text-baseColor-secondary mt-2">
                No Data Found
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`w-[calc(100vw-280px)] h-[calc(100vh-190px)] overflow-y-auto overflow-x-auto relative`}
          >
            <table className="table-auto relative  border-collapse bg-white mt-2">
              <thead className="">
                {/* <tr className="border-b text-[#4F4768] bg-white">
                  <th className="min-w-[150px] max-w-[151px]  text-left text-sm border-r font-semibold py-2 px-4  sticky left-0 bg-white z-10">
                    YPR
                  </th>
                  {purchasers.map((purchaser) => (
                    <th
                      key={purchaser.id}
                      className="text-center text-sm font-semibold py-2 px-4   min-w-[250px] max-w-[351px] truncate"
                    >
                      {capitalizeFirstLetter(purchaser.name)}
                    </th>
                  ))}
                </tr> */}
                <tr className="border-b text-[#4F4768] bg-white ">

  <th className="min-w-[150px] max-w-[151px] text-left text-sm border-r font-semibold py-2 px-4 sticky left-0 top-0 bg-white z-[3]">
    YPR
  </th>
  
  {purchasers.map((purchaser) => (
    <th
      key={purchaser.id}
      className="text-center text-sm font-semibold py-6 px-4 min-w-[250px] max-w-[351px] truncate  sticky top-0 bg-white z-[2]"
    >
      {capitalizeFirstLetter(purchaser.name)}
    </th>
  ))}
</tr>

              </thead>
              <tbody>
                {yprList.map((yprItem) => (
                  <tr
                    key={yprItem.id}
                    className="border-b bg-white hover:text-[#048178] hover:shadow-lg  h-10 scale-y-100"
                  >
                    <td className="min-w-[150px] max-w-[151px] sticky left-0 bg-white z-10 border-r py-2 px-4 text-xs ">
                      {yprItem.name}
                    </td>
                    {purchasers.map((purchaser) => {
                      const item = items.find(
                        (i) =>
                          i.ypr === yprItem.id && i.purchaser === purchaser.id
                      );
                      return (
                        <td
                          key={`${yprItem.id}-${purchaser.id}`}
                          className="py-2 px-2 text-xs min-w-[250px] max-w-[351px]  h-16 "
                        >
                          { item ? 
                            <tr className=" w-full text-center flex justify-center items-center ">
                              {
                                <td className="p-2 border-r border-gray-400 font-normal items-center text-center ">
                                  {" "}
                                  <NuAmount
                                    value={item?.quoted}
                                    defaultCurrency={false}
                                  />
                                  <div className="text-[10px] text-orange-400 font-thin ">
                                    Quoted
                                  </div>
                                </td>
                              }
                              {
                                <td className="p-2 border-r border-gray-400  font-normal items-center text-center">
                                  <NuAmount
                                    value={item?.ordered}
                                    defaultCurrency={false}
                                  />
                                  <div className="text-[10px] text-gray-400 font-thin ">
                                    Ordered
                                  </div>
                                </td>
                              }
                              {
                                <td className="p-2 font-normal items-center text-center">
                                  <NuAmount
                                    value={item?.final}
                                    defaultCurrency={false}
                                  />
                                  <div className="text-[10px] text-green-400 font-thin ">
                                    Final
                                  </div>
                                </td>
                              }
                            </tr>
                            :
                            <div className=" w-full flex justify-center items-center ">-</div>
                          }
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="w-full h-[30px] bg-white">
        <Pagination
          total={totalCount}
          pageIndex={page}
          setPageIndex={setPage}
          dataCount={pageCount}
          setDataCount={setPageCount}
        />
      </div>
      </div>
      </ProcurementsubLayout>
    </>
  );
};

export default TeamPerformanceIndex;
