import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function UnitOthers() {
    return (
        <SettingsMetaData propertyName={"OtherUnit"} viewPropertyName={"Unit"} />
      );
}

export default UnitOthers
