import React from 'react'
import { Route } from 'react-router-dom'
import MainStore from '../Views/Web/Project/SubMenu/Store/MainStore'
import MainGRN from '../Views/Web/Project/SubMenu/Store/GRN/MainGRN'
import GRNIndex from '../Views/Web/Project/SubMenu/Store/GRN'
import CreateGRN from '../Views/Web/Project/SubMenu/Store/GRN/CreateGRN'
import EditGRN from '../Views/Web/Project/SubMenu/Store/GRN/EditGRN'
import ViewGRN from '../Views/Web/Project/SubMenu/Store/GRN/ViewGRN'
import ViewOfRelatableGrn from '../Views/Web/Project/SubMenu/Store/GRN/ViewOfRelatableGrn'
import InventoryMain from '../Views/Web/Project/SubMenu/Store/Inventory/InventoryMain'
import InventoryIndex from '../Views/Web/Project/SubMenu/Store/Inventory/InventoryIndex'
import CreateInventory from '../Views/Web/Project/SubMenu/Store/Inventory/CreateInventory'
import EditInventory from '../Views/Web/Project/SubMenu/Store/Inventory/EditInventory'
import ViewInventory from '../Views/Web/Project/SubMenu/Store/Inventory/ViewInventory'
import AssetsMain from '../Views/Web/Project/SubMenu/Store/Assets/AssetsMain'
import AssetsIndex from '../Views/Web/Project/SubMenu/Store/Assets/AssetsIndex'
import CreateAssets from '../Views/Web/Project/SubMenu/Store/Assets/CreateAssets'
import EditAssets from '../Views/Web/Project/SubMenu/Store/Assets/EditAssets'
import ViewAssets from '../Views/Web/Project/SubMenu/Store/Assets/ViewAssets'
import MainDeliveryNotes from '../Views/Web/Project/SubMenu/Store/DeliveryNotes/MainDeliveryNotes'
import DeliveryNotesIndex from '../Views/Web/Project/SubMenu/Store/DeliveryNotes'
import CreateDeliveryNotes from '../Views/Web/Project/SubMenu/Store/DeliveryNotes/CreateDeliveryNotes'
import EditDeliveryNotes from '../Views/Web/Project/SubMenu/Store/DeliveryNotes/EditDeliveryNotes'
import ViewDeliveryNotes from '../Views/Web/Project/SubMenu/Store/DeliveryNotes/ViewDeliveryNotes'
import PackingListMain from '../Views/Web/Project/SubMenu/Store/PackingList/PackingListMain'
import PackingListIndex from '../Views/Web/Project/SubMenu/Store/PackingList/PackingListIndex'
import CreatePackingList from '../Views/Web/Project/SubMenu/Store/PackingList/CreatePackingList'
import EditPackingList from '../Views/Web/Project/SubMenu/Store/PackingList/EditPackingList'
import ViewPackingList from '../Views/Web/Project/SubMenu/Store/PackingList/ViewPackingList'
import ReturnsMain from '../Views/Web/Project/SubMenu/Store/Returns/ReturnsMain'
import ReturnsIndex from '../Views/Web/Project/SubMenu/Store/Returns/ReturnsIndex'
import CreateReturns from '../Views/Web/Project/SubMenu/Store/Returns/CreateReturns'
import EditReturns from '../Views/Web/Project/SubMenu/Store/Returns/EditReturns'
import ViewReturns from '../Views/Web/Project/SubMenu/Store/Returns/ViewReturns'
import RequestMain from '../Views/Web/Project/SubMenu/Store/Request/MainRequest'
import RequestIndex from '../Views/Web/Project/SubMenu/Store/Request'
import CreateRequest from '../Views/Web/Project/SubMenu/Store/Request/CreateRequest'
import EditRequest from '../Views/Web/Project/SubMenu/Store/Request/EditRequest'
import ViewRequest from '../Views/Web/Project/SubMenu/Store/Request/ViewRequest'
import MainShipmentStatus from '../Views/Web/Project/SubMenu/Store/Shipment Status/MainShipmentStatus'
import ShipmentStatusIndex from '../Views/Web/Project/SubMenu/Store/Shipment Status/ShipmentStatusIndex'
import CreateShipmentStatus from '../Views/Web/Project/SubMenu/Store/Shipment Status/CreateShipmentStatus'
import EditShipmentStatus from '../Views/Web/Project/SubMenu/Store/Shipment Status/EditShipmentStatus'
import ViewShipmentStatus from '../Views/Web/Project/SubMenu/Store/Shipment Status/ViewShipmentStatus'

function projectStoreRoutes() {
  return (
    <Route path="store" component={<MainStore/>}>
    <Route path="grn/:officeId/:projectId" element={<MainGRN />}>
      <Route index element={<GRNIndex/>} />
      <Route path="create" element={<CreateGRN />} />
      <Route path="editGRN/:GnrEditId" element={<EditGRN />} />
      <Route path="viewGRN/:GnrViewId" element={<ViewGRN />} />
      <Route path="viewrelatableGrn/:GnrViewId" element={<ViewOfRelatableGrn />} />
    </Route>
    <Route path="inventory/:officeId/:projectId" element={<InventoryMain />}>
      <Route index element={<InventoryIndex/>} />
      <Route path="create" element={<CreateInventory />} />
      <Route path="editInventory/:inventoryEditId" element={<EditInventory/>} />
      <Route path="viewInventory/:inventoryViewId" element={<ViewInventory />} />
      
    </Route>
    <Route path="assets/:officeId/:projectId" element={<AssetsMain />}>
      <Route index element={<AssetsIndex/>} />
      <Route path="create" element={<CreateAssets />} />
      <Route path="editAssets/:assetsEditId" element={<EditAssets/>} />
      <Route path="viewAssets/:assetsViewId" element={<ViewAssets />} />
      
    </Route>
    <Route path="delevery-notes/:officeId/:projectId" element={<MainDeliveryNotes />}>
      <Route index element={<DeliveryNotesIndex/>} />
      <Route path="create" element={<CreateDeliveryNotes />} />
      <Route path="editDeveleryNotes/:deleveryNotesEditId" element={<EditDeliveryNotes/>} />
      <Route path="viewDeleveryNotes/:deleveryNotesViewId" element={<ViewDeliveryNotes />} />
      
    </Route>
    <Route path="packing-list/:officeId/:projectId" element={<PackingListMain />}>
      <Route index element={<PackingListIndex/>} />
      <Route path="create" element={<CreatePackingList />} />
      <Route path="editPackingList/:packingListEditId" element={<EditPackingList/>} />
      <Route path="viewPackingList/:packingListViewId" element={<ViewPackingList />} />
      
    </Route>
    <Route path="returns/:officeId/:projectId" element={<ReturnsMain />}>
      <Route index element={<ReturnsIndex/>} />
      <Route path="create" element={<CreateReturns />} />
      <Route path="editReturns/:returnsEditId" element={<EditReturns/>} />
      <Route path="viewReturns/:returnsViewId" element={<ViewReturns />} />
  
    </Route>
    <Route path="request/:officeId/:projectId" element={<RequestMain />}>
      <Route index element={<RequestIndex/>} />
      <Route path="create" element={<CreateRequest />} />
      <Route path="editRequest/:requestEditId" element={<EditRequest/>} />
      <Route path="viewRequest/:requestViewId" element={<ViewRequest />} />
      
    </Route>
    <Route path="shipment-status/:officeId/:projectId" element={<MainShipmentStatus />}>
      <Route index element={<ShipmentStatusIndex/>} />
      <Route path="create" element={<CreateShipmentStatus />} />
      <Route path="editRequest/:requestEditId" element={<EditShipmentStatus/>} />
      <Route path="viewRequest/:requestViewId" element={<ViewShipmentStatus />} />
      
    </Route>
    </Route>
  )
}

export default projectStoreRoutes