import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import StatusOutgoing from './Status'

function IndexOutgoingSettings() {
    return(
        <>
        <TextMediumSM content="Finance" />
        <TextMediumSM content="Outcoming" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <StatusOutgoing/>
         </div>
         
         </div>
         </>
    )
}

export default IndexOutgoingSettings
