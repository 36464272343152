// import { useFormik } from "formik";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useToast } from "../../../../../../Components/Others/ToastServices";
import UseDropDownNew from "../../../../../../Components/Hooks/useDDNew";
import useAddFormData from "../../../../../../Components/Hooks/useAddFormData";
import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
import { NuDate, NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
import DeleteIcon from "../../../../../../Components/Icons/Delete";
import Attachement from "../../../../../../Components/Utils/CustomAttrachmentupload";

// import useAddFormData from "../../../../Components/Hooks/useAddFormData";
// import { useEffect, useMemo, useState } from "react";
// import {
//   NuDate,
//   NuInput,
//   NuTextArea,
// } from "../../../../Components/Utils/Input";
// import FormikSelectTwo from "../../../../Components/Utils/Select";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import axiosInstance from "../../../../Services/axiosCommon";
// import { useToast } from "../../../../Components/Others/ToastServices";
// import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
// import DeleteIcon from "../../../../Components/Icons/Delete";
// import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
// import { useParams } from "react-router-dom";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

const CreateReturns = () => {
  const {officeId,projectId} =useParams();

  const currentDate = new Date().toISOString().split("T")[0];
  const toast = useToast();
  const { data: Item, setData } = UseDropDownNew("/items/new-drop-down");
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [image, setImage] = useState([]);
  const [createNew, setCreateNew] = useState({
    orderId: "",
    returnToId: "",
    type: "",
    comments: "",
    returnDate: currentDate || "",
    dnRef: "",
    products: "",
  });
  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      orderId: yup.string().required("Purchase Order is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (returnlist.length === 0) {
        setError("Product table is empty");
        return;
      }
      const valueWithItems = {
        ...value,
        products: returnlist,
      };
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      // formData.append('attachments', image.raw);

      addFormData("/returns/new-return", formData, resetForm);
      // setLoading(false)
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size too big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    setImage(tempArr);
  }

  const [returnMember, setReturnMember] = useState([]);
  useMemo(() => {
    if (formik?.values?.type === "from_customer") {
      axiosInstance
        .get(`/customers/drop-down`)
        .then((res) => {
          let list = res.data;
          let array = [];
          list &&
            list.forEach((i) => {
              let num = { value: i?.id, label: i?.name };
              array.push(num);
            });
          res.data && setReturnMember(array);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    if (formik?.values?.type === "to_vendor") {
      axiosInstance
        .get(`/vendors/drop-down`)
        .then((res) => {
          let list = res.data;
          let array = [];
          list &&
            list.forEach((i) => {
              let num = { value: i?.id, label: i?.name };
              array.push(num);
            });
          res.data && setReturnMember(array);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formik?.values?.type]);
  const [deletedItem, setDeletedItem] = useState(null);

  const handleDeleteItem = (indexToDelete) => {
    const deletedItem = returnlist[indexToDelete];
    const updatedItems = returnlist.filter(
      (_, index) => index !== indexToDelete
    );
    setReturnlist(updatedItems);
    // Preserve the deleted item to be able to re-add it later with its original details
    setDeletedItem(deletedItem);
  };

  const handleQuantityChange = (index, newreturnQty) => {
    const updatedItems = [...returnlist];
    if (newreturnQty <= updatedItems[index].orderedQty) {
      updatedItems[index].returnQty = newreturnQty;
      updatedItems[index].remainingQty =
        updatedItems[index].orderedQty - newreturnQty;
    }
    setReturnlist(updatedItems);
  };

  const handleIncrement = (index) => {
    const currentreturnQty = returnlist[index].returnQty;
    handleQuantityChange(
      index,
      Math.min(returnlist[index].orderedQty, currentreturnQty + 1)
    );
  };

  const handleDecrement = (index) => {
    const currentreturnQty = returnlist[index].returnQty;
    handleQuantityChange(index, Math.max(0, currentreturnQty - 1));
  };

  const handleInputChange = (event, index) => {
    const value = Math.max(
      0,
      Math.min(Number(event.target.value), returnlist[index].orderedQty)
    );
    handleQuantityChange(index, value);
  };

  const handleReasonChange = (e, index) => {
    const updatedItems = [...returnlist];
    updatedItems[index].reason = e.target.value;
    setReturnlist(updatedItems);
  };
  const handleAdd = () => {
    let newItem;
    const selectedProduct = Item.find(
      (i) => i.value === formik.values.productId
    );

    if (selectedProduct) {
      if (deletedItem && deletedItem.productId === selectedProduct.value) {
        // If there's a deletedItem that matches the selectedProduct, restore it
        newItem = {
          ...deletedItem,
          returnQty: deletedItem.orderedQty, // Set returnQty to orderedQty or your desired default
        };
        setDeletedItem(null); // Clear deletedItem after restoring
      } else {
        // Otherwise, add the new product normally
        newItem = {
          productId: selectedProduct.value,
          product_name: selectedProduct.label,
          orderedQty: selectedProduct.orderedQty || 0,
          product_unit: selectedProduct.unit || "",
          returnQty: selectedProduct.orderedQty || 0,
          reason: "",
        };
      }

      setReturnlist([...returnlist, newItem]);
      setData(Item.filter((item) => item.value !== formik.values.productId));
      formik.setFieldValue("productId", "");
    }
  };

  const [order, setOrder] = useState([]);
  function getVendors(num) {
    axiosInstance
      .get(`/returns/order-drop-down/from_customer/${num}`)
      .then((response) => {
        let tempData = [];
        if (response.data) {
          let x = response.data;

          x.map((item) => {
            let y = {
              value: item.id,
              label: item.name,
            };
            tempData.push(y);
          });

          setOrder(tempData);
        }
      })
      .catch((error) => {});
  }

  useMemo(() => {
    if (formik.values.returnToId) {
      getVendors(formik.values.returnToId);
    }
  }, [formik.values.returnToId]);

  const [returnlist, setReturnlist] = useState([]);

  const getPr = (orderId) => {
    if (orderId !== "") {
      axiosInstance
        .get(`/returns/order-products/from_customer/${orderId}`)
        .then((res) => {
          let data = res?.data?.products;
          console.log("Fetched products:", data); // Debugging log
          setReturnlist(data || []); // Ensure default to empty array
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (formik.values.orderId !== "") {
      getPr(formik.values.orderId);
    }
  }, [formik.values.orderId]);

  const filteredProducts = useMemo(() => {
    return Item.filter(
      (item) =>
        !returnlist.some((listItem) => listItem.productId === item.value)
    );
  }, [Item, returnlist]);

  useEffect(() => {
    if (returnlist.length > 0) {
      setError("");
    }
  }, [returnlist]);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      tempArr.push(fileName);
    });
    setImage(tempArr);
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        {/* <Breadcrumb
          items={[
            {
              name: "Returns",
              link: `/project/store/returns/${officeId}/${projectId}`,
            },
            {
              name: "Create",
            },
          ]}
        /> */}
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-145px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full pt-2 mx-auto pb-4 bg-white rounded-md ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              <FormikSelectTwo
                label="Type"
                placeholder="choose"
                Options={[
                  { value: "from_customer", label: "From Customer" },
                  { value: "to_vendor", label: "To Supplier" },
                ]}
                formik={formik}
                name="type"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
              <FormikSelectTwo
                label="Return To"
                placeholder="choose"
                Options={returnMember}
                formik={formik}
                name="returnToId"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
              <FormikSelectTwo
                label="Order Reference"
                placeholder="choose"
                Options={order}
                formik={formik}
                name="orderId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
              <NuInput
                label="Delivery Notes"
                type="text"
                formik={formik}
                placeholder="Delivery Notes"
                name="dnRef"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuDate
                label="Return Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="returnDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Return Attachement :
                </p>
              </div>

              <Attachement
                handleChange={handleImageChange}
                preview={image || ""}
                width="w-full"
                label="Attach"
                multiple={true}
                removeImages={removeImages}
              />
            </div>
          </div>
          <div className="w-full mx-auto  bg-white rounded-md pb-4 mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Return Products :
                </p>
              </div>
              {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
              <div className="w-full mx-4 flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-start gap-3 border border-solid">
                <FormikSelectTwo
                  type="text"
                  label="Product "
                  placeholder="Product "
                  Options={filteredProducts}
                  formik={formik}
                  name="productId"
                  width="w-full md:w-1/2 lg:w-[80%] "
                />

                <button
                  onClick={handleAdd}
                  type="button"
                  className=" p-1 mt-11 m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-white text-xs font-semibold rounded-lg "
                >
                  Add
                </button>
              </div>
              <div className="w-full bg-white overflow-y-scroll mt-5">
                {error && (
                  <p className="text-xs font-semibold text-red-500">{error}</p>
                )}
                <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
                  <p className="w-[5%]  text-left  text-white text-xs font-medium">
                    S.No{" "}
                  </p>

                  <p className="w-[35%] text-left text-white text-xs font-medium">
                    Product
                  </p>
                  <p className="w-[10%] text-center text-white text-xs font-medium">
                    Unit
                  </p>
                  <p className="w-[15%] text-center text-white text-xs font-medium">
                    Ordered Qty
                  </p>
                  <p className="w-[20%] text-center text-white text-xs font-medium">
                    Return Qty
                  </p>
                  <p className="w-[20%] text-center text-white text-xs font-medium">
                    Reason
                  </p>

                  <p className=" w-[5%] text-center text-white text-xs font-medium">
                    Action
                  </p>
                </div>
                {returnlist?.length > 0 ? (
                  <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                    {returnlist?.map((List, index) => (
                      <div
                        key={index}
                        className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100"
                      >
                        <p className="w-[5%]  text-left   text-xs font-light py-1">
                          {index + 1}
                        </p>
                        <p className="w-[35%] text-left  text-xs font-light">
                          {List?.product_name}
                        </p>
                        <p className="w-[10%] text-center  text-xs font-light">
                          {List?.product_unit}
                        </p>
                        <p className="w-[15%] text-center  text-xs font-light">
                          {List?.orderedQty}
                        </p>

                        <div className="w-[20%] text-center  text-xs font-light">
                          <button
                            type="button"
                            className="border rounded-full p-1 "
                            onClick={() => handleDecrement(index)}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="w-[20%] text-center border  text-xs font-light"
                            name="returnQty"
                            value={List.returnQty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <button
                            type="button"
                            className="border rounded-full p-1 "
                            onClick={() => handleIncrement(index)}
                          >
                            +
                          </button>
                        </div>
                        <input
                          type="text"
                          className="w-[20%] py-1 text-center  text-xs font-light"
                          name="reason"
                          placeholder="Enter here..."
                          value={List.reason || ""}
                          onChange={(e) => handleReasonChange(e, index)}
                        />
                        <div className="w-[5%] text-center">
                          <button
                            onClick={() => handleDeleteItem(index)}
                            type="button"
                            className="text-red-500 cursor-pointer  ml-4 border-none"
                          >
                            <DeleteIcon color="red" height={4} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-full text-center text-[#93949f]">
                    No Items here
                  </div>
                )}
              </div>
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateReturns;
