import React, { useEffect } from 'react'
import { Route, useLocation, useNavigate } from 'react-router-dom'
import MainProcurements from '../Views/Web/Procurement/MainProcurement'
import MainSuppliers from '../Views/Web/Procurement/Suppliers/MainSuppliers'
import SuppliersIndex from '../Views/Web/Procurement/Suppliers'
import CreateSuppliers from '../Views/Web/Procurement/Suppliers/CreateSuppliers'
import EditSuppliers from '../Views/Web/Procurement/Suppliers/EditSuppliers'
import ViewSuppliers from '../Views/Web/Procurement/Suppliers/ViewSuppliers'
import EditPr from '../Views/Web/Procurement/PR/EditPr'
import ViewPr from '../Views/Web/Procurement/PR/ViewPr'
import CreatePr from '../Views/Web/Procurement/PR/CreatePr'
import MainPr from '../Views/Web/Procurement/PR/MainPr'
import PRIndex from '../Views/Web/Procurement/PR'
import EditRQF from '../Views/Web/Procurement/RFQ/EditRQF'
import CreateRFQ from '../Views/Web/Procurement/RFQ/CreateRFQ'
import ViewRFQ from '../Views/Web/Procurement/RFQ/ViewRFQ'
import MainRFQ from '../Views/Web/Procurement/RFQ/MainRFQ'
import RFQIndex from '../Views/Web/Procurement/RFQ'
import ViewSupplierQuotations from '../Views/Web/Procurement/SupplierQuotations/ViewSupplierQuotations'
import EditSupplierQuotations from '../Views/Web/Procurement/SupplierQuotations/EditSupplierQuotations'
import CreateSupplierQuotations from '../Views/Web/Procurement/SupplierQuotations/CreateSupplierQuotations'
import MainSupplierQuotations from '../Views/Web/Procurement/SupplierQuotations/MainSupplierQuotations'
import SupplierQuotesIndex from '../Views/Web/Procurement/SupplierQuotations'
import ViewPo from '../Views/Web/Procurement/Po/ViewPo'
import EditPo from '../Views/Web/Procurement/Po/EditPo'
import CreatePo from '../Views/Web/Procurement/Po/CreatePo'
import PoIndex from '../Views/Web/Procurement/Po'
import MainPo from '../Views/Web/Procurement/Po/MainPo'
import ContractsMain from '../Views/Web/Procurement/Contracts/MainContracts'
import ContractsIndex from '../Views/Web/Procurement/Contracts'
import CreateContracts from '../Views/Web/Procurement/Contracts/CreateContracts'
import EditContracts from '../Views/Web/Procurement/Contracts/EditContracts'
import ViewContracts from '../Views/Web/Procurement/Contracts/ViewContracts'
import ViewQuotes from '../Views/Web/Procurement/PR/ViewQuotes'
import { Wrapper } from '../Components/Utils/roles'
import ProductComparisonIndex from '../Views/Web/Procurement/ProductComparison'
import MainProductComparision from '../Views/Web/Procurement/ProductComparison/MainProductConparision'
import ViewProductSupplierList from '../Views/Web/Procurement/ProductComparison/ViewProductList'
import supplierPriceListing from '../Views/Web/Procurement/ProductComparison/SupplierPriceListing'
import QuoteComparisionIndex from '../Views/Web/Procurement/QuoteComparision'
import ViewCreate from '../Views/Web/Procurement/PR/ViewCreate'
// import ViewRevision from '../Views/Web/Procurement/PR/ViewRevision'
import ViewSQRevision from '../Views/Web/Procurement/SupplierQuotations/ViewRevision'
// import ViewSQCreate from '../Views/Web/Procurement/SupplierQuotations/ViewCreate'
// import ViewIdRevision from '../Views/Web/Procurement/PR/ViewIdRevision'
import ViewIdSQRevision from '../Views/Web/Procurement/SupplierQuotations/ViewIdSQRevision'
import QuotesViewFull from '../Views/Web/Procurement/QuoteComparision/quotesViewFull'
import RfqCreateSupplierQuotes from '../Views/Web/Procurement/RFQ/RfqCreateSupplierQuotes'
import RfqEditSupplierQuotes from '../Views/Web/Procurement/RFQ/RfqEditSupplierQuotes'
import RFQEditSQ from '../Views/Web/Procurement/RFQ/RFQEditSQ'
import RelatedGRNIndexInPo from '../Views/Web/Procurement/Po/RelatedGRNIndex'
import ViewOfRelatableGrnInPo from '../Views/Web/Procurement/Po/ViewOfRelatableGrn'
import TeamPerformanceIndex from '../Views/Web/Procurement/TeamPerformance/Index'
import MainTeamPerformance from '../Views/Web/Procurement/TeamPerformance/MainTeamPerformance'
import ViewSQCreate from '../Views/Web/Procurement/RFQ/ViewCreate'
import MainPaymentRequest from '../Views/Web/Procurement/PaymentRequest.jsx/MainPaymentRequest'
import PaymentRequestIndex from '../Views/Web/Procurement/PaymentRequest.jsx/Index'
import CreatePaymentRequest from '../Views/Web/Procurement/PaymentRequest.jsx/CreatePaymentRequest'
import EditPaymentRequest from '../Views/Web/Procurement/PaymentRequest.jsx/EditPaymentRequest'
import ViewPaymentRequest from '../Views/Web/Procurement/PaymentRequest.jsx/ViewPaymentRequest'


const ProcurementRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === "/procurement" ||
      location.pathname === "/procurement/") {
      navigate("/page-not-found")
    }
  },[location])
  return (
    <Route path='procurement' element={<MainProcurements/>}>
        {/* <Route path='suppliers' element={<MainSuppliers/>}>
          <Route index element={<Wrapper accessKey={"suppliers_view"}  Component={SuppliersIndex} />}/>
          <Route path='create' element={<Wrapper accessKey={"suppliers_create"}  Component={CreateSuppliers} />} />
          <Route path='edit/:suppliersEditId' element={<Wrapper accessKey={"suppliers_edit"}  Component={EditSuppliers} />}/>
          <Route path='view/:suppliersViewId'  element={<Wrapper accessKey={"suppliers_view"}  Component={ViewSuppliers} />} />
        </Route> */}
        <Route path='payment-request' element={<MainPaymentRequest/>}>
          <Route index element={<Wrapper accessKey={"purchase_request_view"}  Component={PaymentRequestIndex} />}/>
          <Route path='create' element={<Wrapper accessKey={"purchase_request_create"}  Component={CreatePaymentRequest} />} />
          <Route path='edit/:PaymentRequestEditId' element={<Wrapper accessKey={"purchase_request_edit"}  Component={EditPaymentRequest} />}/>
          <Route path='view/:paymentrequestViewId'  element={<Wrapper accessKey={"purchase_request_view"}  Component={ViewPaymentRequest} />} />
        </Route>
        <Route path='purchase-requests' element={<MainPr/>}>
          <Route index element={<Wrapper accessKey={"purchase_request_view"}  Component={PRIndex} />}/>
          <Route path='create' element={<Wrapper accessKey={"purchase_request_create"}  Component={CreatePr} />} />
          <Route path='create' element={<Wrapper accessKey={"rfq_create"}  Component={CreateRFQ} />} />
          <Route path='edit/:prEditId' element={<Wrapper accessKey={"purchase_request_edit"}  Component={EditPr} />}/>
          <Route path='view/:prViewId'  element={<Wrapper accessKey={"purchase_request_view"}  Component={ViewPr} />} />
          <Route path='viewquotes/:viewqoutesId' element={<Wrapper accessKey={"purchase_request_view"}  Component={ViewQuotes} />} />
          <Route path='viewcreate/:viewcreateId' element={<Wrapper accessKey={"purchase_request_create"}  Component={ViewCreate} />} />
          {/* <Route path='viewrevision/:viewrevisionId' element={<Wrapper accessKey={"purchase_request_view"}  Component={ViewRevision} />} />
          <Route path='viewidrevision/:viewidrevisionId' element={<Wrapper accessKey={"purchase_request_view"}  Component={ViewIdRevision} />} /> */}


        </Route>
        <Route path='rfq' element={<MainRFQ/>}>
          <Route index element={<Wrapper accessKey={"rfq_view"}  Component={RFQIndex} />}/>
          <Route path='create' element={<Wrapper accessKey={"rfq_create"}  Component={CreateRFQ} />} />
          <Route path='edit/:rfqEditId' element={<Wrapper accessKey={"rfq_edit"}  Component={EditRQF} />}/>
          <Route path='view/:rfqViewId'  element={<Wrapper accessKey={"rfq_view"}  Component={ViewRFQ} />} />
          <Route path='supplier-quotes/create'  element={<Wrapper accessKey={"rfq_create"}  Component={RfqCreateSupplierQuotes} />} />
          <Route path='supplier-quotes/edit/:sqEditId'  element={<Wrapper accessKey={"rfq_edit"}  Component={RFQEditSQ} />} />
          <Route path='sq-revision/:viewcreatesqId' element={<Wrapper accessKey={"rfq_create"}  Component={ViewSQCreate} />} />

        </Route>
        <Route path='supplier-quotes' element={<MainSupplierQuotations/>}>
          <Route index element={<Wrapper accessKey={"rfq_view"}  Component={SupplierQuotesIndex} />}/>
          <Route path='create' element={<Wrapper accessKey={"rfq_create"}  Component={CreateSupplierQuotations} />} />
          <Route path='edit/:supplierQuotationsEditId' element={<Wrapper accessKey={"rfq_edit"}  Component={EditSupplierQuotations} />}/>
          <Route path='view/:supplierQuotationsViewId'  element={<Wrapper accessKey={"rfq_create"}  Component={ViewSupplierQuotations} />} />
          {/* <Route path='viewsqcreate/:viewcreatesqId' element={<Wrapper accessKey={"rfq_create"}  Component={ViewSQCreate} />} /> */}
          <Route path='viewsqrevision/:viewrevisionsqId' element={<Wrapper accessKey={"rfq_create"}  Component={ViewSQRevision} />} />
          <Route path='viewsqIdrevision/:viewIdrevisionsqId' element={<Wrapper accessKey={"rfq_create"}  Component={ViewIdSQRevision} />} />

        </Route>
        <Route path='purchase-order' element={<MainPo/>}>
          <Route index element={<Wrapper accessKey={"purchase_order_view"}  Component={PoIndex} />}/>
          <Route path='create' element={<Wrapper accessKey={"purchase_order_create"}  Component={CreatePo} />} />
          <Route path='edit/:poEditId' element={<Wrapper accessKey={"purchase_order_edit"}  Component={EditPo} />}/>
          <Route path='view/:poViewId'  element={<Wrapper accessKey={"purchase_order_view"}  Component={ViewPo} />} />
          <Route path='viewGrnIndex/:poGrnIndexId'  element={<Wrapper accessKey={"purchase_order_view"}  Component={RelatedGRNIndexInPo} />} />
          <Route path='viewGrnProduct/:poGrnViewId'  element={<Wrapper accessKey={"purchase_order_view"}  Component={ViewOfRelatableGrnInPo} />} />
        </Route>
        <Route path='contracts' element={<ContractsMain/>}>
          <Route index element={<Wrapper accessKey={"contracts_view"}  Component={ContractsIndex} />}/>
          <Route path='create' element={<Wrapper accessKey={"contracts_create"}  Component={CreateContracts} />} />
          <Route path='edit/:contractsEditId' element={<Wrapper accessKey={"contracts_edit"}  Component={EditContracts} />}/>
          <Route path='view/:contractsViewId'  element={<Wrapper accessKey={"contracts_view"}  Component={ViewContracts} />} />
        </Route>
        <Route path='product-comparison' element={<MainProductComparision/>}>
          <Route index element={<Wrapper accessKey={"quote_compare_view"}  Component={ProductComparisonIndex} />}/>
          <Route path='view/:productComparisonViewId'  element={<Wrapper accessKey={"quote_compare_view"}  Component={ViewProductSupplierList} />} />
          <Route path='priceView/:productId/:supplierId'  element={<Wrapper accessKey={"quote_compare_view"}  Component={supplierPriceListing} />} />
        </Route>
        <Route path='team-performance' element={<MainTeamPerformance/>}>
          <Route index element={<Wrapper accessKey={true}  Component={TeamPerformanceIndex} />}/>
          {/* <Route path='view/:teamperformanceViewId'  element={<Wrapper accessKey={true}  Component={ViewProductSupplierList} />} /> */}
          {/* <Route path='priceView/:productId/:supplierId'  element={<Wrapper accessKey={true}  Component={supplierPriceListing} />} /> */}
        </Route>
        <Route path='quote-comparison' element={<QuoteComparisionIndex/>}>
          <Route index element={<Wrapper accessKey={"quote_compare_view"}  Component={QuoteComparisionIndex} />}/>
          <Route path='view/:ViewId' element={<>ffwnfkwfnk</>}/>
          {/* <Route path='view/:productComparisonViewId'  element={<Wrapper accessKey={true}  Component={ViewProductSupplierList} />} />
          <Route path='priceView/:productId/:supplierId'  element={<Wrapper accessKey={true}  Component={supplierPriceListing} />} /> */}
        </Route>
    </Route>
  )
}

export default ProcurementRoutes