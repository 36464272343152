import React, { useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';
import { useToast } from '../../../../Components/Others/ToastServices';
import DropDownAdd from '../../../../Components/Utils/selectAdd';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import DropDownAddSubCategory from '../../../../Components/Utils/selectAddSubCategory';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { useParams } from 'react-router-dom';

function CreateItems({ data }) {
  const { value:Unit, getData:refUnit } = useFetchMataData(`/dropdown/dropdown-details/`,'Unit_item')
  const { value:brand, getData:refBrand } = useFetchMataData(`/dropdown/dropdown-details/`,'brand_item')
  const toast = useToast();
  // const { data: vendor } = UseDropDown('/vendors/drop-down');

  // const { handleSendNotification } = useNotification();

  // useEffect(()=> {
  //   handleSendNotification({
  //   username: "Admin",
  //   receiver_id : [3],
  //   sender_id : 1,
  //   message : "Testing from frontend!!"
  // })
  // },[]);
const {officeId} =useParams();
  const [image, setImage] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [searchAddData,setSearchAddData] = useState('');
  const [final,setFinal] = useState()
  const [createNew, setCreateNew] = useState({

    productName: "",
    category: "",
    subCategory: "",
    itemCode: "",
    isPromotable: "",
    unit: "",
    manufaturer:"",
    brand:"",
    comments: "",
    productDescription: "",
    branchId : officeId,

  })
  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const handleChangeImg = (e) => {
    console.log(e,'multi');
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files,
      });
    }
  };
  
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  }

  const formik = useFormik
    ({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        productName: yup.string().required('Product is required!'),
        category: yup.string().required('Category is required!'),
        unit: yup.string().required('Unit is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        // setLoading(true); 
        let formData = new FormData();
        formData.append('json_data', JSON.stringify(value));
        // formData.append('itemImage', image.raw);
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            formData.append("itemImage", file);
          });
        }else{
          formData.append("itemImage",[])
        } 

        addFormData('/items/new-item', formData, resetForm);
        // setLoading(false)
      }
    });

  const { category: itemsCategory, subCategory: itemsSubCategory, getCategory, fetchSubCategory } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);
  //  const searchAddDataRef = useRef('');
  //  const handleInput = (inputValue) =>{
  //    setSearchAddData(inputValue);
  //    searchAddDataRef.current = inputValue
  //  }

  useMemo(()=>{
    console.log("cat",searchAddData);
    setFinal(searchAddData)
  },[searchAddData])

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = []
    List.map((item) =>{
      let fileName = item?.name
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    })
    setImage(tempArr)
  };


  
  

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-end ">
       {/* <Breadcrumb
          items={[
           
            {
              name: "Product",
              link: `/main/${officeId}/products/items`
            },

            { name: "Create" },
          ]} /> */}

        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden  mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">

            <NuInput
              label="Product"
              type="text"
              formik={formik}
              placeholder="Product"
              name="productName"
              width="w-full md:w-1/2 lg:w-2/3"
              isRequired={true}
            />
             <DropDownAddMetaData
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refUnit}
                displayProperty={"Add Unit"}
                propertyName={"Unit_item"}
                isRequired={true}
              />
            <DropDownAdd
              type="text"
              label="Category"
              placeholder="Category"
              Options={itemsCategory}
              formik={formik}
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={getCategory}
              categoryName={"ItemCategory"}
              displayProperty={"Add Category"}
              isRequired={true}
            />
            <DropDownAddSubCategory
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={itemsSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={fetchSubCategory}
              category={formik?.values?.category}
              module={"ItemCategory"}
              displayProperty={"Add Sub-Category"}
            />
            <DropDownAddMetaData
                label="Brand"
                placeholder="Choose"
                Options={brand}
                formik={formik}
                name="brand"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refBrand}
                displayProperty={"Add Brand"}
                propertyName={"brand_item"}
              />
            
            {/* <DropDownAddMetaData
                label="Manufaturer"
                placeholder="Choose"
                Options={Manufacture}
                formik={formik}
                name="manufaturer"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refManufacture}
                displayProperty={"Add Manufacture"}
                propertyName={"Manufacture_item"}
              /> */}
            {/* <NuInput
              label="Item Code"
              type="text"
              formik={formik}
              placeholder="Item Code"
              name="itemCode"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <FormikSelectTwo
              type="text"
              label="Under Promotion?"
              placeholder="Under Promotion?"
              Options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" }

              ]}
              formik={formik}
              name="isPromotable"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
              {/* {formik.values.isPromotable === true && (
              <FormikSelectTwo
                type="text"
                label="Supplier"
                placeholder="Choose"
                Options={vendor}
                formik={formik}
                name="vendorId"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )} */}
             {/* {formik.values.isPromotable === true && (
               <NuInput
               label="Price"
               type="text"
               formik={formik}
               placeholder="Price"
               name="price"
               width="w-full md:w-1/2 lg:w-1/3"
             />
            )} */}



           
            {/* <NuInput
              label="Product Description"
              type="text"
              formik={formik}
              placeholder="Product Description"
              name="productDescription"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-2/3"
              new_width="lg:w-2/3"
            />
              {/* <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Product Attachement :
  </p>
</div>      */}
<Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                  accept='image/*'

                />
                        {/* </div> */}
                        {/* </div> */}

                        <div className="w-full   md:block mx-a my-4 mr-6">
                        <div className="flex  justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  )
}

export default CreateItems