import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import UnitProgressMaterial from './Unit'

function MaterialProgressIndex() {
    return(
        <>
        <TextMediumSM content="Progress" />
        <TextMediumSM content="Material" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <UnitProgressMaterial/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <UnitManPower/>
         </div> */}
         
         </div>
         </>
    )
}

export default MaterialProgressIndex