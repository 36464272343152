import { Button, Modal, notification } from 'antd';
import React, { useState } from 'react'
import { useFormik } from 'formik';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { NuInput, NuSelect } from '../../../../../../Components/Utils/Input';

function TableData({item,allList,handleData,projectId,refreshData,currentDate}) {
  console.log("date",currentDate);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openWorkingHour, setOpenWorkingHour] = useState(false);
    const [openOT, setOpenOT] = useState(false);
    const [openAll, setOpenAll] = useState(false); 
    const [selectedUserId, setSelectedUserId] = useState(""); 
    console.log("ss",selectedUserId);
    const [createStatus,setCreateStatus] = useState({
      users: [],
      date: "",
      status: ""
    });
    
    const [createWorkingHour,setCreateWorkingHour] = useState({
      users: [],
      date: "",
      working_hours: ""
    });

    const [createOverTime,setCreateOverTime] = useState({
      users: [],
      date: "",
      over_time: ""
    });
     

    const formikStatus = useFormik({
        enableReinitialize: true,
        initialValues: createStatus,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (value, { resetForm }) => {
          const valueStatus={
            ...value,
            users: [selectedUserId],
            date: currentDate,
          }
          axiosInstance
          .put(`/Attendance/edit-status/${projectId}`,valueStatus)
          .then((res) => {
            if (res.status == 201 || res.data.status == true) {
              api.success({
                message: `Add successfully`,
                description: res?.data?.msg || " created",
                placement: "bottomRight",
              });
              setOpenStatus(false)
              resetForm();
              refreshData(projectId);
            } else {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: res?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        },
      });

      const formikWorkingHour = useFormik({
        enableReinitialize: true,
        initialValues: createWorkingHour,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (value, { resetForm }) => {
          const valueWorkingHours={
            ...value,
            users: [selectedUserId],
            date: currentDate,
          }
          axiosInstance
          .put(`/Attendance/edit-working-hours/${projectId}`,valueWorkingHours)
          .then((res) => {
            if (res.status == 201 || res.data.status == true) {
              api.success({
                message: `Add successfully`,
                description: res?.data?.msg || " created",
                placement: "bottomRight",
              });
              setOpenWorkingHour(false)
              resetForm();
              refreshData(projectId);
            } else {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: res?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        },
      });


      const formikOverTime = useFormik({
        enableReinitialize: true,
        initialValues: createOverTime,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (value, { resetForm }) => {
          const valueStatus={
            ...value,
            users: [selectedUserId],
            date: currentDate,
          }
          axiosInstance
          .put(`/Attendance/edit-OT/${projectId}`,valueStatus)
          .then((res) => {
            if (res.status == 201 || res.data.status == true) {
              api.success({
                message: `Add successfully`,
                description: res?.data?.msg || " created",
                placement: "bottomRight",
              });
              setOpenOT(false)
              resetForm();
              refreshData(projectId);
            } else {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: res?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        },
      });


      // const formilk = useFormik({
      //   enableReinitialize: true,
      //   initialValues: createNew,
      //   validateOnChange: false,
      //   validateOnBlur: false,
      //   onSubmit: async (value, { resetForm }) => {
      //     axiosInstance
      //     .get(``)
      //     .then((res) => {

      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      //   },
      // });



  return (
    <>
     <tr>
        <td className=' w-[3%] py-2 text-center'>
            <input 
              type='checkbox' 
              checked={allList.some(id=>id===item?.userId)}
              onChange={() => handleData(item?.userId)}
             />
        </td>
        <td className=' w-[29%] py-2 text-xs cursor-pointer' onClick={()=>setOpenAll(true)}>{item?.userName||"-"}</td>
        <td className=' w-[20%] py-2 text-xs'>{item?.userRole||"-"}</td>
        {/* <td className=' w-[15%] py-2 text-xs'>{item?.division||"-"}</td> */}
        <td className=' w-[14%] py-2 text-xs cursor-pointer' onClick={()=> {setOpenStatus(true); setSelectedUserId(item?.userId);}}>{
                                                                                                item?.status == "0" ? "-" :
                                                                                                item?.status == "1" ? "P" :
                                                                                                item?.status == "2" ? "A" :
                                                                                                "-"}</td>
        <td className=' w-[17%] py-2 text-xs cursor-pointer' onClick={()=>{ setOpenWorkingHour(true); setSelectedUserId(item?.userId); }}>{item?.working_hours||"-"}</td>
        <td className=' w-[17%] py-2 text-xs cursor-pointer' onClick={()=>{ setOpenOT(true); setSelectedUserId(item?.userId); }}>{item?.over_time||"-"}</td>
     </tr>


     <Modal
        open={openStatus}
        title={"Update Status "}
        width={"20%"}
        className="my-modal"
        centered
        onCancel={()=>setOpenStatus(false)}
        footer={[]}
      >
        <form onSubmit={formikStatus.handleSubmit}>
          <div className=" w-full flex flex-wrap">

            <NuSelect
              label="Status"
              options={
                [
                  {value: 1,label:"Present"}, 
                  {value: 2,label:"Absent"}, 
                ]
              }
              formik={formikStatus}
              placeholder="Choose"
              name="status"
              width=" w-full"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>


      <Modal
        open={openWorkingHour}
        title={"Update Working Hour "}
        width={"20%"}
        className="my-modal"
        centered
        onCancel={()=>setOpenWorkingHour(false)}
        footer={[]}
      >
        <form onSubmit={formikWorkingHour.handleSubmit}>
          <div className=" w-full flex flex-wrap">

          <NuInput
                       type="number"

              label="Working Hour"
              formik={formikWorkingHour}
              placeholder="Enter Working Hour"
              name="working_hours"
              width=" w-full"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>


      <Modal
        open={openOT}
        title={"Update Over Time "}
        width={"20%"}
        className="my-modal"
        centered
        onCancel={()=>setOpenOT(false)}
        footer={[]}
      >
        <form onSubmit={formikOverTime.handleSubmit}>
          <div className=" w-full flex flex-wrap">
        
          <NuInput
                       type="number"

              label="Working Hour"
              formik={formikOverTime}
              placeholder="Enter Working Hour"
              name="over_time"
              width=" w-full"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />

            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Submit 
            </Button>
          </div>
        </form>
      </Modal>
      {/* <Modal
        open={openAll}
        title={"Update All"}
        width={"20%"}
        className="my-modal"
        centered
        onCancel={()=>setOpenAll(false)}
        footer={[]}
      >
        <form onSubmit={formikOverTime.handleSubmit}>
          <div className=" w-full flex flex-wrap">

            <NuSelect
              label="Status"
              options={[]}
              formik={formikOverTime}
              placeholder="Choose"
              name="filterStatus"
              width=" w-full"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
              <NuInputNumber
              label="Working Hour"
              formik={formikOverTime}
              placeholder="Enter Working Hour"
              name="filterStatus"
              width=" w-full"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <NuInputNumber
              label="Over Time"
              formik={formikOverTime}
              placeholder="Enter Over Time"
              name="filterStatus"
              width=" w-full"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal> */}
    </>
  )
}

export default TableData