import React from 'react'
import { BackButton, EditButton } from '../../../../../../Components/Utils/Buttons'
import { Link, useLocation, useParams } from 'react-router-dom'
import Dasboard from '../../../../../../Components/Icons/Dasboard'
import NuDate from '../../../../../../Components/Utils/NuDate'
import Breadcrumb from '../../../../../../Components/Utils/Breadcrumb'

function ViewIssued(id) {
  const location = useLocation();
  const data = location.state?.data;
   const { projectId } = useParams();
          const {officeId , estmationId} = useParams();
        const Paydata = location.state.data;
        const pageIndex = location.state?.pageIndex;
        const searchQuery = location.state?.searchQuery;
  return (
    <div className="w-full h-[calc(100vh-60px)]  px-4 py-2 overflow-hidden ">
    <div className="md:flex justify-between w-full">
    <Breadcrumb 
      items={[

        { name: "Petty Cash" },
           
        {
          name: "Issued",
          link: `/project/petty-cash/issued/${officeId}/${projectId}`
        },

        { name: "View" },
      ]} 
            />
      {/* <div className=" flex justify-between items-center float-right">
        <BackButton />
      </div> */}
       <div className=" flex gap-2">
                                  <Link to={`/project/petty-cash/issued/${officeId}/${projectId}`} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
                                  <Link to={`/project/petty-cash/issued/${officeId}/${projectId}/edit/${id}`} state={{data: data, from:`/project/petty-cash/issued/${officeId}/${projectId}/view/${id}`}}><EditButton height='8'/></Link>
                                </div>
    </div>
    <div className=" w-full  h-[calc(100vh-120px)] overflow-y-auto bg-white mt-3 rounded-lg shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] p-4 ">
      <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
    
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Project </p>
          <p className=' text-xs  text-mainColor-400'>{data?.projectName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Date </p>
          <p className=' text-xs  text-mainColor-400'>{data?.date !="" && data?.date !="None" && <NuDate value={data?.date} format="DD-MMM-YYYY" /> ||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submitted On </p>
          <p className=' text-xs  text-mainColor-400'>{data?.submittedOn !="" && data?.submittedOn !="None" && <NuDate value={data?.submittedOn} format="DD-MMM-YYYY" /> ||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Expense </p>
          <p className=' text-xs  text-mainColor-400'>{data?.billAmount||"0.0"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Received Amount </p>
          <p className=' text-xs  text-mainColor-400'>{data?.receivedAmount||"0.0"}</p>
        </div>
        {/* <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Description </p>
          <p className=' text-xs  text-mainColor-400'>{data?.description||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Status </p>
          <p className=' text-xs  text-mainColor-400'>{data?.status||"-"}</p>
        </div> */}
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Comments </p>
          <p className=' text-xs  text-mainColor-400'>{data?.comments||"-"}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewIssued;