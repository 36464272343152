import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInput, NuSelect } from '../../../../../Components/Utils/Input';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../../Components/Utils/Select';


function EditSubItemsEquipment({open, getData, onCancel, data,id,estimationId }) {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    item: "",
    unit: "",
    quantity: "",
    unitPrice: "",
    totalPrice: "",
    type: "",
    no_of_days: ""
  });
  useEffect(() => {
    data&&setCreateNew({
    item: data?.item || "",
    unit: data?.unit || "",
    quantity: data?.quantity || "",
    unitPrice: data?.unitPrice || "",
    totalPrice: data?.totalPrice || "",
    estimationId: data?.estimationId || "",
    IT_Id: data?.IT_Id || "",
    type: data?.type || "",
    no_of_days: data?.no_of_days || ""
    })
  },[data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
       item:yup.string().required("Item Name is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
      axiosInstance
      .put(`/equipment/update/${estimationId}/${id}/${data?.id}`, value)
      .then((res) => {
          console.log("res",res.data.status);
          if (res.data.status) {
            getData(id);
            resetForm();
            api.success({
              message: `Equipment Item Update successfully`,
              description: res?.data?.msg || "Added In The Activity",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Equipment Item Upadate failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });
  useEffect(() => {
    const totalPrice = formik?.values?.quantity * formik?.values?.unitPrice
    formik.setFieldValue("totalPrice", totalPrice);
  }, [formik?.values?.quantity,formik?.values?.unitPrice]);
  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Edit Equipment Item"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
         <NuInput
          type="text"
              label="Item Name"
              placeholder="Item Name"
              formik={formik}
              name="item"
              width="w-full"
              isRequired={true}
          />
          <FormikSelectTwo
                  label="Type"
                  Options={[
                    {value: "Own", label:"Own"},
                    {value: "Rental", label:"Rental"},
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="type"
                  width="w-full md:w-1/3"
                />
          <FormikSelectTwo
                  label="Unit"
                  Options={[
                    {value: "kg", label:"Kg"},
                    {value: "lit", label:"lit"},
                    {value: "ton", label:"ton"},
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="unit"
                  width="w-full md:w-1/3"
                />
                <NuInput
                 type="number"
              label="No Of Days"
              placeholder="No Of Days"
              formik={formik}
              name="no_of_days"
              width="w-full md:w-1/3"
            />
           <NuInput
            type="number"
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
            <NuInput
             type="number"
              label="Unit Price"
              placeholder="Unit Price"
              formik={formik}
              name="unitPrice"
              width="w-full md:w-1/3"
            />
            <NuInput
             type="number"
              label="Total Price"
              placeholder="Total Price"
              formik={formik}
              name="totalPrice"
              width="w-full md:w-1/3"
              disabled={true}
            />
            <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
              <CancelButton handleClick={onCancel} />
              <SubmitButton isLoading={isLoading} />
            </div>
      </div>
      </form>
    </Modal>
    </>
  )
}

export default EditSubItemsEquipment;