import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';
import { NuInput, NuTextArea } from '../../../../../Components/Utils/Input';

function CreateSubItemsTC({open,onCancel,getData,estiId,itId}) {
  console.log("finding",estiId);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    description: "",
    item_code: "",
    estimationId: "",
    IT_Id: ""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      item_code:yup.string().required("Item Code is Requried")
    }),
    onSubmit: (value,{resetForm}) => {    
      setIsLoading(true);
      const values = {
        ...value,
        estimationId: Number(estiId),
        IT_Id: itId,
      }
      axiosInstance
      .post(`/estimation/single_terms-and-conditions`, values)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            setIsLoading(false);
            getData();
            resetForm();
            api.success({
              message: `T&C Item Creation successfully`,
              description: res?.data?.msg || "Added In Tittle",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `T&C Item  Creation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          api.error({
            message: `T&C Item Creation failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
          setIsLoading(false);
        });
    },
  });

  // useEffect(() => {
  //   const totalPrice = formik?.values?.quantity * formik?.values?.unitPrice
  //   formik.setFieldValue("totalPrice", totalPrice);
  // }, [formik?.values?.quantity,formik?.values?.unitPrice]);

  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Create TermsAndCondition Item"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
      <NuInput

       type="text"
              label="Item Code"
              placeholder="Item Code"
              formik={formik}
              name="item_code"
              width="w-full"
              isRequired={true}
            />
             <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full"
              new_width="w-full "
              
            />
            <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
              <CancelButton handleClick={onCancel} />
              <SubmitButton isLoading={isLoading} />
            </div>
      </div>
      </form>
    </Modal>
    </>
  )
}

export default CreateSubItemsTC