import React from 'react'
import NuAvatarGroupWithLink from '../../../Layouts/NuAvatarGroupWithLink'

function TeamCard({data}) {
  return (
    <div className="w-full my-2 bg-white shadow-[0px_2px_10px_rgba(38,48,75,0.15)] rounded-[10px] p-[6px]">
        <div className='p-1 rounded-[6px] bg-[#F2F3F7] font-medium text-[12px]'>{data?.teamName||"-"} : {data?.membersCount||0}</div>
        <div className=" flex items-end my-4">
            <NuAvatarGroupWithLink
              link={true}
              userdetails={data?.memberList||[]}
            />
          </div>
    </div>
  )
}

export default TeamCard