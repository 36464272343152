import React from 'react'
import { LineChart } from 'recharts'


function ProjectProgress({project}) {
  return (
    <div className="w-full h-[45vh] p-2 rounded-lg bg-white" style={{ border: "1px solid #E9E8EC" }}>
    <LineChart actualData={project?.tracker && project?.tracker[0]||[]} plannedData={project?.tracker && project?.tracker[1]||[]} />
    </div>
  )
}

export default ProjectProgress