import React from "react";
import NuDate from "../../../Components/Utils/NuDate";
import { Progress } from "antd";
import ViewMore from "./viewMore";
import { Link, useParams } from "react-router-dom";
import NuAmount from "../../../Components/Utils/NuAmount";
import { ActivatePill, CancelPill, CompletePill, HoldPill, OtherPill, ProgressPill, RejectedPill, StartPill } from "../../../Components/Utils/StatusPills";
import DoubleArrow from "../../../Components/Icons/DoubleArrow";
import { TextBoldSM } from "../../../Components/Text/BoldText";
import { TextBase, TextSM } from "../../../Components/Text/NormalText";
import ViewArrow from "../../../Components/Icons/ViewArrow";

function ProjectCard({ item, getProject }) {
  const {officeId} = useParams();
  let {
    projStatus,
    projName,
    projScope,
    projId,
    getProjectManager,
    startDate,
    endDate,
    projectCompletions,
    taskPercentage,
  } = item;
  function DateDiff(createDate, expiryDate) {
    let startDate = new Date(createDate);
    let endDate = new Date(expiryDate);
    let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays + 1;
  }
  function DateRemaining(createDate, expiryDate) {
    let startDate = "";
    let dateNow = new Date();
    let startDateTemp = new Date(createDate);
    let endDateTemp = new Date(expiryDate);
    if (dateNow.getTime() >= endDateTemp.getTime()) {
      return "0";
    } else {
      if (dateNow.getTime() < startDateTemp.getTime()) {
        startDate = startDateTemp;
      } else {
        startDate = dateNow;
      }
      let endDate = new Date(expiryDate);
      let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays == 0 ? diffDays + 1 : diffDays + 1;
    }
  }

  function DateWorked(createDate, expiryDate) {
    return (
      DateDiff(createDate, expiryDate) - DateRemaining(createDate, expiryDate)
    );
  }

  return (
    <div
      className="w-full text-[#6C6580] hover:shadow-xl rounded-lg "
      style={{ border: "1px solid #C0C0C0" }}
    >
      <div className="flex justify-between bg-[#ECEBF3]  rounded-t-lg items-center px-4 py-2">
        <p className=" w-8 h-5 bg-slate-300  flex justify-center font-medium text-mainColor-500 items-center text-[12px] rounded-lg">
          {projId || "#"}
        </p>
        {taskPercentage && projStatus.toLocaleLowerCase() != "yet to start" && (
          <p className=" text-[12px] font-medium ">
            {taskPercentage?.progress == 0
              ? `As per Plan ${
                  Math.floor(taskPercentage?.progressPercentage) || ""
                }`
              : taskPercentage.progress == 1
              ? `Ahead ${Math.floor(taskPercentage?.progressPercentage) || 0} %`
              : `Delayed by ${
                  Math.floor(taskPercentage?.progressPercentage) || 0
                } %`}
          </p>
        )}
        <div className=" flex gap-2">
          <div className="flex items-center gap-2 overflow-hidden">
            {projStatus &&
              projStatus != "" &&
              (projStatus.toLocaleLowerCase() == "active" ? (
                <ActivatePill />
              ) : projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start" ? (
                <StartPill
                 />
              ) : projStatus.toLocaleLowerCase() == "in-progress" ||
                projStatus.toLocaleLowerCase() == "in progress" ? (
                <ProgressPill />
              ) : projStatus.toLocaleLowerCase() == "on-hold" ||
                projStatus.toLocaleLowerCase() == "on hold" ? (
                <HoldPill />
              ) : projStatus.toLocaleLowerCase() == "cancel" ||
                projStatus.toLocaleLowerCase() == "canceled" ? (
                <CancelPill />
              ) : projStatus.toLocaleLowerCase() == "reject" ||
                projStatus.toLocaleLowerCase() == "rejected" ? (
                <RejectedPill />
              ) : projStatus.toLocaleLowerCase() == "completed" ||
                projStatus.toLocaleLowerCase() == "complete" ? (
                <CompletePill />
              ) : (
                <OtherPill text={projStatus} />
              ))}
          </div>
          <ViewMore id={projId} getProject={getProject} data={item} />
        </div>
      </div>
      <div className=" px-2 pb-2">
        <div className=" px-2 w-full h-8 mt-2 truncate  overflow-hidden">
          <Link
            className="hover:text-blue-600 text-mainColor-400 font-semibold text-[12px] lg:text-[12px] xl:text-[12px] w-full flex justify-between items-center"
            to={`/project/dashboard/${officeId}/${projId}`}
            state={{ data: item }}
          >
            {projName || "-"} <ViewArrow/>
          </Link>
        </div>
        <div className=" px-2 w-full h-[.6px] bg-[#C0C0C0] mx-auto"></div>
        <div className=" px-2 mt-2 py-[1px]">
          <p className="flex">
            <TextBoldSM text={"Lead by : "} />{" "}
            <span className="ml-3 text-xs font-bold text-[#3B2DB0]">
              {(getProjectManager &&
                getProjectManager.map((item, idx) => {
                  // console.log("ff",item ,idx);
                  return (
                    <span
                      className=" mr-3"
                      key={idx}
                    >{`${item.firstName}  `}</span>
                  );
                })) ||
                "-"}
            </span>
          </p>
        </div>

        <div
          className="w-full flex justify-between items-center flex-wrap gap-2 my-2 rounded-md p-2"
          style={{ border: "1px solid #C0C0C0" }}
        >
          <div className=" w-[58%]  flex gap-2 flex-wrap items-center">
            <div className=" w-[45%]">
              <TextSM content={"Start Date" || "-"} />
              <div className=" text-mainColor-500 text-xs   rounded-[60px] ">
                {(startDate != "None" && (
                  <NuDate value={startDate} format="DD-MMM-YY" />
                )) ||
                  "-"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM content={"Due Date" || "-"} />
              <div className=" text-mainColor-500 text-xs   rounded-[60px] ">
                {(endDate != "None" && (
                  <NuDate value={endDate} format="DD-MMM-YY" />
                )) ||
                  "-"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM content={"Duration" || "-"} />
            </div>
            <div className=" w-[45%] flex items-center">
              <div className="  px-2 pb-[3px] font-medium text-[12px] bg-[#119DEE] flex justify-center items-center rounded text-white">
                {DateDiff(startDate, endDate)}{" "}
                {DateDiff(startDate, endDate) >= 1 ? "days" : "day"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM content={"Worked" || "-"} />
              <div className=" text-mainColor-500 text-xs   rounded-[60px] ">
                {projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start"||
                projStatus.toLocaleLowerCase() == "on-hold" ||
                projStatus.toLocaleLowerCase() == "on hold" ||
                projStatus.toLocaleLowerCase() == "reject" ||
                projStatus.toLocaleLowerCase() == "rejected"
                  ? " 0 "
                  : DateWorked(startDate, endDate) || 0}
                {projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start" ||
                projStatus.toLocaleLowerCase() == "on-hold" ||
                projStatus.toLocaleLowerCase() == "on hold" ||
                projStatus.toLocaleLowerCase() == "reject" ||
                projStatus.toLocaleLowerCase() == "rejected"
                  ? " day"
                  : DateWorked(startDate, endDate) >= 2
                  ? " days"
                  : " day"}
              </div>
            </div>
            <div className=" w-[45%]">
              <TextSM content={"Remaining" || "-"} />
              <div className=" text-mainColor-500 text-xs  rounded-[60px] ">
                {projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start"
                  ? " 0 "
                  : DateRemaining(startDate, endDate) || 0}
                {projStatus.toLocaleLowerCase() == "yet-to-start" ||
                projStatus.toLocaleLowerCase() == "yet to start"
                  ? " day"
                  : DateRemaining(startDate, endDate) >= 2
                  ? " days"
                  : " day"}
              </div>
            </div>
          </div>
          <div className=" w-[38%] flex flex-col justify-center items-center">
            <div className=" lg:w-20 lg:h-20 mx-auto text-center">
              <Progress
                type="circle"
                size={80}
                strokeWidth={10}
                format={(percent) => (
                  <p
                    className={` ${
                      projectCompletions?.projectlevel > 95
                        ? "text-[#87d068]"
                        : projectCompletions?.projectlevel > 50
                        ? "text-[#108ee9]"
                        : "text-[#D2BD00]"
                    } text-2xl font-semibold`}
                  >
                    {percent}
                  </p>
                )}
                percent={Math.floor(projectCompletions?.projectlevel) || 0}
                strokeColor={
                  projectCompletions?.projectlevel > 95
                    ? {
                        "0%": "#87d068",
                        "100%": "#87d068",
                      }
                    : projectCompletions?.projectlevel > 50
                    ? {
                        "0%": "#108ee9",
                        "100%": "#108ee9",
                      }
                    : {
                        "0%": "#D2BD00",
                        "100%": "#D2BD00",
                      }
                }
              />
            </div>
            <div className=" my-1">
              <TextBase text={"Progress"} />
            </div>
          </div>
        </div>

        <div className=" mt-2 px-2 w-[80%] flex justify-between items-center ">
          <p className="text-xs font-medium text-[#171A21]">
            Task :{" "}
            <span className=" text-mainColor-500">
              {projectCompletions?.totalTask || 0}
            </span>
          </p>
          <p className="text-xs font-medium text-[#171A21]">
            Open :{" "}
            <span className=" text-mainColor-500">
              {projectCompletions?.totalTask -
                projectCompletions?.totalCompletions || 0}
            </span>
          </p>
          <p className="text-xs font-medium text-[#171A21] text-right">
            Closed :{" "}
            <span className=" text-mainColor-500">
              {projectCompletions?.totalCompletions || 0}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;

function Datevalue({ title, date, dateTwo }) {
  return (
    <div className=" w-full  py-1">
      <div className=" w-1/2">
        <TextBase text={title || "-"} />
      </div>
      <div className=" w-1/2">
        <span className=" text-mainColor-500 text-[12px] bg-violet-50 py-1 px-3  text-center rounded-[60px] ">
          {dateTwo
            ? dateTwo
            : (date != "None" && (
                <NuDate value={date} format="DD-MMM-YYYY" />
              )) ||
              "-"}
        </span>
      </div>
    </div>
  );
}
