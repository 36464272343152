
import { Outlet } from "react-router-dom"
const MainEquipment = () =>{
    return(
        <div className=" w-full h-full">
         <Outlet/>
        </div>
    )
}

export default MainEquipment;