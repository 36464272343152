import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import MainRentalManpower from '../Views/Web/Contacts/RentalManpower/main'
import RentalManpower from '../Views/Web/Contacts/RentalManpower'
import CreateRentalManpower from '../Views/Web/Contacts/RentalManpower/createRentalManpower'
import EditRentalManpower from '../Views/Web/Contacts/RentalManpower/editRentalManpower'
import RentalManpowerProfile from '../Views/Web/Contacts/RentalManpower/rentalManpowerProfile'
import { Wrapper } from '../Components/Utils/roles'
import Index from '../Views/Web/Contacts/RentalManpower'
import MainClient from '../Views/Web/Contacts/Clients/clientMain'
import MainContacts from '../Views/Web/Contacts/MainContacts'
import CreateClients from '../Views/Web/Contacts/Clients/createClient'
import EditClients from '../Views/Web/Contacts/Clients/editClient'
import ClientView from '../Views/Web/Contacts/Clients/clientView'
import Client from '../Views/Web/Contacts/Clients'
import MainVendor from '../Views/Web/Contacts/Vendor/vendorMain'
import Vendor from '../Views/Web/Contacts/Vendor'
import CreateVendor from '../Views/Web/Contacts/Vendor/createVendor'
import EditVendor from '../Views/Web/Contacts/Vendor/editVendor'
import VendorView from '../Views/Web/Contacts/Vendor/vendorView'
import SubCon from '../Views/Web/Contacts/SubCon'
import MainSubCon from '../Views/Web/Contacts/SubCon/subConMain'
import CreateSubCon from '../Views/Web/Contacts/SubCon/createSubCon'
import EditSubCon from '../Views/Web/Contacts/SubCon/editSubCon'
import SubConView from '../Views/Web/Contacts/SubCon/subConView'
import CustomersIndex from '../Views/Web/Contacts/Customers'
import CreateCustomers from '../Views/Web/Contacts/Customers/CreateCustomers'
import EditCustomers from '../Views/Web/Contacts/Customers/EditCustomers'
import ViewCustomers from '../Views/Web/Contacts/Customers/ViewCustomers'
import MainCustomers from '../Views/Web/Contacts/Customers/MainCustomers'
import MainSuppliers from '../Views/Web/Contacts/Suppliers/MainSuppliers'
import SuppliersIndex from '../Views/Web/Contacts/Suppliers'
import CreateSuppliers from '../Views/Web/Contacts/Suppliers/CreateSuppliers'
import EditSuppliers from '../Views/Web/Contacts/Suppliers/EditSuppliers'
import ViewSuppliers from '../Views/Web/Contacts/Suppliers/ViewSuppliers'
import AgentsMain from '../Views/Web/Contacts/Agents/AgentsMain'
import AgentIndex from '../Views/Web/Contacts/Agents'
import CreateAgents from '../Views/Web/Contacts/Agents/CreateAgents'
import EditAgents from '../Views/Web/Contacts/Agents/EditAgents'
import ViewAgents from '../Views/Web/Contacts/Agents/ViewAgents'




function ContactsRoutes() {
  return (
    <Route path="contacts" element={<MainContacts/>} >
     {/* <Route path="clients" element={<MainClient />}>
      <Route index element={< Wrapper accessKey={true}  Component={Client} /> } />
      <Route path="create" element={< Wrapper accessKey={true}  Component={CreateClients} /> } />
      <Route path="edit/:clientEditId" element={< Wrapper accessKey={true}  Component={EditClients} />} />
      <Route path="view/:clientViewId" element={< Wrapper accessKey={true}  Component={ClientView} /> } />
     </Route> */}
      <Route path="customers" element={<MainCustomers />}>
        <Route
          index
          element={<Wrapper accessKey={"customers_view"} Component={CustomersIndex} />}
        />
        <Route
          path="create"
          element={<Wrapper accessKey={"customers_create"} Component={CreateCustomers} />}
        />
        <Route
          path="edit/:customersEditId"
          element={<Wrapper accessKey={"customers_edit"} Component={EditCustomers} />}
        />
        <Route
          path="view/:customersViewId"
          element={<Wrapper accessKey={"customers_view"} Component={ViewCustomers} />}
        />
      </Route>
      <Route path='suppliers' element={<MainSuppliers />}>
                <Route index element={<Wrapper accessKey={"suppliers_view"}  Component={SuppliersIndex} />}/>
                <Route path='create' element={<Wrapper accessKey={"suppliers_create"}  Component={CreateSuppliers} />} />
                <Route path='edit/:suppliersEditId' element={<Wrapper accessKey={"suppliers_edit"}  Component={EditSuppliers} />}/>
                <Route path='view/:suppliersViewId'  element={<Wrapper accessKey={"suppliers_view"}  Component={ViewSuppliers} />} />
              </Route>
     {/* <Route path="vendor" element={<MainVendor />}>
    <Route index element={< Wrapper accessKey={true}  Component={Vendor} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateVendor} />} />
    <Route path="edit/:vendorEditId" element={< Wrapper accessKey={true}  Component={EditVendor} />} />
    <Route path="view/:vendorViewId" element={< Wrapper accessKey={true}  Component={VendorView} />} />
  </Route> */}
  <Route path="sub-con" element={<MainSubCon />}>
    <Route index element={< Wrapper accessKey={true}  Component={SubCon} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateSubCon} />} />
    <Route path="edit/:subConEditId" element={< Wrapper accessKey={true}  Component={EditSubCon} />} />
    <Route path="view/:subConViewId" element={< Wrapper accessKey={true}  Component={SubConView} />} />
  </Route>
  <Route path="rental-man" element={<MainRentalManpower />}>
    <Route index element={< Wrapper accessKey={true}  Component={Index} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateRentalManpower} />} />
    <Route path="profile/:rentalManpowerid" element={< Wrapper accessKey={true}  Component={RentalManpowerProfile} />} />
    <Route path="edit/:rentalManpowerEditId" element={< Wrapper accessKey={true}  Component={EditRentalManpower} />} />          
  </Route>
  <Route path="agents" element={<AgentsMain/>}>
                <Route index element={<Wrapper accessKey={"agents_view"}  Component={AgentIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"agents_create"}  Component={CreateAgents} />}/>
                <Route path="edit/:agentsEditId" element={<Wrapper accessKey={"agents_edit"}  Component={EditAgents} />}/>
                <Route path="view/:agentsViewId" element={<Wrapper accessKey={"agents_view"}  Component={ViewAgents} />}/>
            </Route>
  </Route>
  )
}

export default ContactsRoutes