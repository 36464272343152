import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function StatusOutgoing() {
    return (
        <SettingsMetaData propertyName={"OutgoingStatus"} viewPropertyName={"Status"} />
      );
}

export default StatusOutgoing
