import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as yup from 'yup';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { useParams } from 'react-router-dom';
import { LongButton } from '../../../../../../Components/Utils/Buttons';
import { NuInput } from '../../../../../../Components/Utils/Input';

function CreateMaterialTittlePro({open,onCancel,getData}) {
  const { projectId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    name: "",
    project_Id: projectId,
    type: 1,
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      name:yup.string().required("Tittle is Requried")
    }),
    onSubmit: (value,{resetForm}) => {    
      setIsLoading(true);
      axiosInstance
      .post("/title/create", value)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            getData();
            resetForm();
            setIsLoading(false);
            api.success({
              message: `Tittle Creation successfully`,
              description: res?.data?.msg || "Added In Tittle",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            setIsLoading(false);
            api.error({
              message: `Tittle Creation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          api.error({
            message: `Tittle Creation failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });
  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Create Material Title"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
         <NuInput
                      type="text"

              label="Tittle"
              placeholder="Tittle"
              formik={formik}
              name="name"
              width="w-full"
          />
          <LongButton isLoading={isLoading} />
      </div>
      </form>
    </Modal>
    </>
  )
}

export default CreateMaterialTittlePro;