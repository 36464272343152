import React from 'react'
import StatusDiary from './Status'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import { BackButton } from '../../../../Components/Utils/Buttons'
import DiaryIndex from '.'

function MainDiary() {
    return(
        <div className='w-full h-full px-4 py-2'>
          <div className='w-full flex justify-between items-center'>
          <Breadcrumb
            items={[
              {
                name: "Settings",
              },
              {
                name: "My Diary"
              }
            ]}
          />
          <BackButton/>
          </div>
          <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
                <div className="p-3"><DiaryIndex/></div>
                


          </div>
          </div>
      )
}

export default MainDiary
