import React, { useEffect, useState } from 'react'
import NuAmountPro from '../../../../Components/Utils/NuAmountPro';
import NuDate from '../../../../Components/Utils/NuDate';
import { Progress } from "antd";
import { Link, useParams } from "react-router-dom";
import axiosInstance from '../../../../Services/axiosCommon';

const ProjectSatus = () => {
  const [totalProject, setTotalProject] = useState([]);
  const {officeId,projId} = useParams();
  async function getTable() {
    await axiosInstance
      .get(`/Dashboard/all_project`)
      .then(async (res) => {
        (await res?.data?.data) && setTotalProject(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getTable()
  },[])
  return (
    <div className="bg-white p-4 rounded-lg drop-shadow-lg h-[45vh]  2xl:h-[35vh] w-full">
               <h2 className='text-left font-semibold text-[#4F4768] text-sm'>Project Status</h2> 
               <div className=" w-full overflow-y-scroll h-[29vh] mt-1">
              <table className="overflow-hidden text-center  text-xs border-collapse w-full overflow-y-scroll">
                <thead className="border-b  rounded-md overflow-hidden font-medium  text-xs">
                  <tr
                    className=""
                    style={{
                      borderTop: "1px solid #E9E8EC ",
                      borderBottom: "1px solid #E9E8EC ",
                    }}
                  >
                    <th
                      scope="col"
                      className="sticky py-2 w-[25%] text-start pl-2 font-semibold   text-[#6C6580] text-xs"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[10%]   text-[#6C6580] font-semibold text-xs"
                    >
                      Start Date
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[10%]   text-[#6C6580] font-semibold text-xs"
                    >
                      Due Date
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[15%]   text-[#6C6580] font-semibold text-xs"
                    >
                      Butget
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[15%]   text-[#6C6580] font-semibold text-xs"
                    >
                      Actual
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[15%]   text-[#6C6580] font-semibold text-xs"
                    >
                      Difference
                    </th>
                    <th
                      scope="col"
                      className="sticky py-2 w-[10%]   text-[#6C6580] font-semibold text-xs"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className=" px-2 ">
                  {totalProject &&
                    totalProject.map((Item, Index) => {
                      return (
                        <tr
                          key={Index}
                          className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-10"
                          style={{ borderBottom: "1px solid #E9E8EC " }}
                        >
                          <td className="pl-2 text-left text-[#4F4768]  text-xs">
                          <Link
            className="hover:text-blue-600 text-mainColor-400 font-semibold text-[12px] lg:text-[12px] xl:text-[12px] w-full flex justify-between items-center"
            to={`/project/dashboard/${officeId}/${projId}`}
            state={{ data: Item }}
          >
                              {Item.projName}
                              <br></br>
                              <div className="w-[80%]">
                                <Progress
                                  // percent={50}
                                  percent={
                                   Math.floor(Item?.projectCompletions?.projectlevel) ||
                                    "0"
                                  }
                                >
                                  {/* {Item?.projectCompletions?.projectlevel || 0} % */}
                                </Progress>
                              </div>
                            </Link>
                          </td>
                          <td className="text-[#4F4768] text-xs ">
                            {Item.startDate != "None" && (
                              <NuDate
                                value={Item.startDate || ""}
                                format="DD-MMM-YY"
                              />
                            )}
                          </td>
                          <td className=" text-[#4F4768]  text-xs">
                            {Item.endDate != "None" && (
                              <NuDate
                                value={Item.endDate || ""}
                                format="DD-MMM-YY"
                              />
                            )}
                          </td>
                          <td className=" text-[#4F4768]  text-xs">
                            <NuAmountPro value={Item.projectBudget[0].budgetCost||0} />
                          </td>
                          <td className=" text-[#4F4768]  text-xs">
                          <NuAmountPro value={Item.projectBudget[0].costAwarded||0} />
                            
                          </td>
                          <td className=" text-[#4F4768]  text-xs">
                          <NuAmountPro value={Item.projectBudget[0].difference||0} />

                          </td>
                          <td className=" text-[#4F4768]  text-xs">
                            {Item.projStatus}
                          </td>
                          {/* <td className=" text-xs">
                          {Item?.projectCompletions?.projectlevel || 0} %
                        </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div></div>
  )
}

export default ProjectSatus