

import React, { useEffect, useState } from 'react'
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import { GetAccess, GetRole } from '../../../../Components/Utils/roles';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';

import axiosInstance from '../../../../Services/axiosCommon';
import UseGetDataSearchByPro from '../../../../Components/Hooks/useFetchDataByPRO';
import { useToast } from '../../../../Components/Others/ToastServices';
import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import ItemBidLayout from '../ItemBidLayout';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function BidsIndex() {
  const toast = useToast();
  const location = useLocation();
  const {officeId , estmationId} = useParams();
  console.log(officeId,estmationId,"estimationId");
  
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const { value: biddingStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "biddingStatus"
  );
  const { value: biddingDivision } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "biddingDivision"
  );

  const [filterProps, setFilterProps] = useState({
    biddingStatus:"",
    category:"",
    division:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",
    biddingStatus:"",
    category:"",
    division:""
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, division:value.division,biddingStatus:value.biddingStatus})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        biddingStatus:"",
    category:"",
    division:""
      });
    }
  })
  const clearFilter = ()=> {
    setParams({...params, 
      biddingStatus:"",
    category:"",
    division:""
  })
  formik.values.biddingStatus=""
  formik.values.category=""
  formik.values.division=""
 


      setFilter(false)
  }

 
  const { category: itemsCategory, subCategory: itemsSubCategory } =
  UseCatSubCatDropDown(
    "/category/ItemCategory",
    "ItemCategory",
    formik?.values?.category
  );
  const { data, totalCount, getData,dataLoading } = UseGetDataSearchByPro( `/bidding/${officeId}/details`,params);
  const defaultHeader = [
    {
      title: "Ref.No",
      width: "5%",
      isLink: true,
      id: "biddingId",
      link: `/main/${officeId}/bidding/bids/view/`,  
      field: "serial_number",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Project",
      width: "10%",
      isLink: true,
      id: "biddingId",
      link: `/main/${officeId}/bidding/bids/view/`,
    
      field: "projectName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Client",
      width: "10%",
      isLink: true,
      id: "biddingId",
      link: `/main/${officeId}/bidding/bids/view/`,
    
      field: "clientName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Bid Due Date",
      type:"date",
      width: "15%",
      field: "dueDate",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Submitted on",
      width: "10%",
      type:"date",
      field: "submittedOn",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Value",
      width: "10%",
      field: "biddingStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "5%",
      field: "biddingStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"biddingId",
        removeValue:"projectName",
        url:`/bidding/delete_bidding/`,
        moduleName:"Bidding",
        from:`/main/${officeId}/bidding/bids/`,
        editLink:`/main/${officeId}/bidding/bids/edit/`,
        viewLink:`/main/${officeId}/bidding/bids/view/`,
        projectLink:`boq`
      }
    },
  ];
  const availableHeaders = [
    {
      title: "Ref.No",
      width: "5%",
      isLink: true,
      id: "biddingId",
      link: `/main/${officeId}/bidding/bids/view/`,  
      field: "serial_number",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Project",
      width: "10%",
      isLink: true,
      id: "biddingId",
      link: `/main/${officeId}/bidding/bids/view/`,
    
      field: "projectName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Client",
      width: "10%",
      isLink: true,
      id: "biddingId",
      link: `/main/${officeId}/bidding/bids/view/`,    
      field: "clientName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Bid Due Date",
      type:"date",
      width: "15%",
      field: "dueDate",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Submitted on",
      width: "10%",
      type:"date",
      field: "submittedOn",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Value",
      width: "10%",
      field: "biddingStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "5%",
      field: "biddingStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"biddingId",
        removeValue:"projectName",
        url:`/bidding/delete_bidding/`,
        moduleName:"Bidding",
        from:`/main/${officeId}/bidding/bids/`,
        editLink:`/main/${officeId}/bidding/bids/edit/`,
        viewLink:`/main/${officeId}/bidding/bids/view/`,
        projectLink:`boq`
      }
    },
  ];

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

    const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
       const [showImportModal, setShowImportModal] = useState(false);

    const handleImportClick = () => {
      setShowImportModal(true);
    };
  
    const closeModal = () => {
      setShowImportModal(false);
    };


    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [listofNumbers, setListofNumbers] = useState([])
    const [fullList, setFullList] = useState([])
  
    const selectOneFunction = (value) =>{ 
      let NewId;
      if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
        NewId = listofNumbers.filter(Temp=> Temp !== value);
        setListofNumbers(NewId)
      }else{
        setListofNumbers([...listofNumbers,value])
      }
    }
  
    const selectMultipleFunction = (e) =>{ 
  console.log(e.target.checked);
  if(e.target.checked){
    setListofNumbers(fullList)
  }else{
    setListofNumbers([])
  }
  
    }

    const handleMultiDelete = (data) => {
       axiosInstance.delete(`items/multi-delete`,{
        headers: {
          'Content-Type': 'application/json',
        },
        data: {"ids" : data},
      })
       .then(res => {
        if (res.data?.status === true) {
          if(res?.data?.not_deleted_ids.length<=0){
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Deleted Successfully."
            });
            getData();
            setShowDelete(false)
          }else{
            toast.open({
              type: "warning",
              message: "Success",
              description: `${res.data?.msg} \n Some Products has dependenies`|| "Deleted Successfully."
            });
            getData();
            setShowDelete(false)
          }

        } else if (res.data?.status === false && res.data?.error) {
          toast.open({
            type: "error",
            message: "Error",
            description: res.data.error || "Delete UnSuccessfull"
          });
        } else {
          toast.open({
            type: "warning",
            message: "Warning",
            description: res.data?.msg || "Warning."
          });
        }
      })
      .catch(err => {
        console.error(err);
        toast.open({
          type: "error",
          message: "Error",
          description: "Network Error."
        });
      });
    }
  
    useEffect(()=>{
      let tempArr = []
      data && data.forEach(i=>{
        console.log("I",i?.productId);
        tempArr.push(i?.productId)
      });
      setFullList(tempArr)
    },[data])

     const [valueOfSearch,setValuesofSearch] = useState("")
         function searchFunction(e) {  
           
           setValuesofSearch(e?.target?.value)
           setParams({ ...params, search: e?.target?.value, page:1 });
           setPage(1)
         }

         const navigate = useNavigate()
         const handleCreateClick = () => {
          // Navigate to the create path when the create button is clicked
          navigate("/main/officeId/bidding/bids/create"); // Replace `officeId` dynamically
        };
  return (
    <>
    <ItemBidLayout
      searchFun={searchFunction}
      getData={getData}
      searchValue={valueOfSearch}
      CreateAccess={GetAccess("activity_item_create")}
      onCreateClick={handleCreateClick} 
      isFilter={filter}
      newfilter={true}
      setFilterOpen={setShowFilterDialog}
      ClearFilter={clearFilter} 
         >
    <div className='w-full h-full '>
      {/* <div className='w-full flex justify-between items-center'>
      <Breadcrumb
          items={[
            { name: "Bids" },
          ]} />
        <div className=' flex gap-2'>
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            /> */}
             {/* <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
          {/* {GetRole() && <ImportButton handleClick={handleImportClick} />} */}
          {/* {GetAccess("items_create") && */}
          {/* <Link to={`/main/${officeId}/bidding/bids/create`}><CreateButton label='Bids' /></Link> */}
          {/* // } */}
        {/* </div>
      </div> */}
      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        // title='Bid List' 
        storageName="BidTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        // hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="biddingId"
        showFieldFalse={false}
        editAccess="items_edit"      
        deteleAccess="items_delete"
        dataLoading={dataLoading}   
        searchQuery={params?.search!=""?params?.search:""}
        />
         </div>
       
       </ItemBidLayout>
       {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[50%] max-w-[70%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Bidding</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex justify-start flex-wrap mb-4">
                    <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Category"
              Options={itemsCategory}
              formik={formik}
              name="category"
              width="w-full "
              // isRequired={true}
            />
                <FormikSelectTwo
                  label="Division"
                  Options={biddingDivision||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full "
                  // isRequired={true}
                />


                <FormikSelectTwo
                  label="Status"
                  Options={biddingStatus||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="biddingStatus"
                  width="w-full "
                  // isRequired={true}
                />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
                  
    </>
  )
}
export default BidsIndex