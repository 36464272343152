import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function StatusOtherExpense() {
    return (
        <SettingsMetaData propertyName={"OtherExpenseStatus"} viewPropertyName={"Status"} />
      );
}

export default StatusOtherExpense
