import { Link, Outlet, useLocation, useParams } from "react-router-dom"
import Dasboard from "../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../Components/Utils/SearchBar";
import { ClearFilterButton, CreateButton, FilterButton, ImportButton } from "../../../Components/Utils/Buttons";
import { Select } from "antd";
import { GetAccess } from "../../../Components/Utils/roles";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import List from "../../../Components/Icons/list";
import Card from "../../../Components/Icons/card";


const ContactsLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        handleSearch=()=>{},
        searchBy="all",
        isCard=false,
        searchFun=()=>{},
        searchValue="",
        setViewMode=()=>{},
        viewMode="",
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        newfilter=false

    }

) =>{
    let {pathname} = useLocation()
    let {officeId} = useParams()
    const [module,setModule] = useState("")
      
    
    
    let searchList = [
        { value: "all", label: "All" },
        { value: "services", label: "Services" },
        { value: "products", label: "Products" },
      ];

    useEffect(() =>{
        // if(pathname == "/contacts/members"){
        //     setModule("Employees")
        // }else 
        if(pathname == `/main/${officeId}/contacts/rental-man`){
            setModule("RM")
        }else if(pathname == `/main/${officeId}/contacts/customers`){
            setModule("Client")
        }else if(pathname == `/main/${officeId}/contacts/suppliers`){
            setModule("Suppliers")
        }else if(pathname == `/main/${officeId}/contacts/sub-con`){
            setModule("Sub-Cont.")
        }else if(pathname == `/main/${officeId}/contacts/agents`){
            setModule("Agents")
        }
    },[pathname])

    let LinkItem = [
        // {
        //     label:"Employees",
        //     link:"/contacts/members",
        //     access:GetAccess("employees_view"),
        // },
       
        {
            label:"Clients",
            link:`/main/${officeId}/contacts/customers`,
            access:GetAccess("customers_view"),
        },
        {
            label:"Sub-Cont.",
            link:`/main/${officeId}/contacts/sub-con`,
            access:GetAccess("sub_contractor_view"),
        },
        {
            label:"Suppliers",
            link:`/main/${officeId}/contacts/suppliers`,
            access:GetAccess("vendor_view"),
        },
        {
            label:"Rental Manpower",
            link:`/main/${officeId}/contacts/rental-man`,
            access:GetAccess("rental_manpower_view"),
        },
        {
            label:"Agents",
            link:`/main/${officeId}/contacts/agents`,
            access:GetAccess("agents_view"),
        },
       
    ]
    
    return(
        <div className=" w-full h-full py-2  px-4 overflow-hidden">
           
            <div className="md:flex justify-between w-full">
            {/* <Breadcrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Contact</p>)},
            {title:(<p className=' text-[12px] '></p>)}
            ]} /> */}
 {/* <Breadcrumb
          items={[
            { name: "Contact" },
           
            { name: `${module}` },

          ]} /> */}
           <div className=" w-full  flex items-center gap-x-1 pt-2">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                                       <Link to={item?.link} className={`mx-2 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                                      )
                         }
                        </>
                    )
                })
            }
        </div>
            <div className=" flex justify-end items-center gap-3 ">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
                {
                    module  && ( module == "Suppliers" || module == "Clients" ||  module == "Agents" || module == "Sub-Cont") && (
                        <Select
                        style={{ width: "105px", fontSize: "12px" }}
                        options={searchList}
                        value={searchList.find((i) => i.value == searchBy) || ""}
                        onChange={handleSearch}
                      />
                    )
                }
              <SearchBar handleChange={searchFun}  value={searchValue}/>
              </form>
              {isCard &&
              <div className='flex gap-2'>
            <div onClick={() => setViewMode("list")} className={viewMode === "list" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><List/></div>
            <div onClick={() => setViewMode("card")} className={viewMode === "card" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><Card/></div>
          </div>
}
 {newfilter && (
  <>
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <ClearFilterButton handleClick={ClearFilter} />} </>)}
              {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}
              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
           
           
            <div className=" w-full h-[calc(100vh-140px)] ">
               {children} 
            </div>
            </div>
       
    )
}


export default ContactsLayout;