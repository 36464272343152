import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function TypeManPower() {
    return (
        <SettingsMetaData propertyName={"ManPowerType"} viewPropertyName={"Type"} />
      );
}

export default TypeManPower
