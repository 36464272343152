import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../Services/axiosCommon';
import Dasboard from '../../../Components/Icons/Dasboard';
import {
    BackButton,
    CancelButton,
    SubmitButton,
  } from "../../../Components/Utils/Buttons";
import CustomImgUpload from '../../../Components/Utils/CustomImgUpload';
import NuDate from '../../../Components/Utils/NuDate';
import Breadcrumb from '../../../Components/Utils/Breadcrumb';
import { NuInput, NuMobileNumber, NuSelect } from '../../../Components/Utils/Input';
import MultiSelect from '../../../Components/Utils/MultiSelect';
import FormikSelectTwo from '../../../Components/Utils/Select';
import useEditFormData from '../../../Components/Hooks/useEditFormData';
import { CountryMIN } from '../../../assets/CountryMini';

function EditEmployee() {
    const {officeId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state.data;
  const from = location.state.from;

    const [projectList, setProjectList] = useState([]);
    const { Oncancel, editFormData, isLoading } =
    useEditFormData();
    const [image, setImage] = useState({ preview: "", raw: "" });
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    city: data?.city||"",
    address1: data?.address1||"",
    address2: data?.address2||"",

    streetName: data?.streetName||"",
    district: data?.district||"",
    state: data?.state||"",
    country: data?.country||"",
    postalCode: data?.postalCode||"",
    name: data?.name||"",
    designation: data?.designation||"",
    division: data?.division||"",
    email: data?.email||"",
    // password: data?.password||"",
    phone: data?.phone.number,
    countryCode: data?.phone.countryCode,
    status: data?.status||"",
    userImage: data?.userImage?.file,
    yearlyTar:data?.yearlyTar||"",
    halfYearTar:data?.halfYearTar||"",
    quarterlyTar:data?.quarterlyTar||"",
    monthlyTar:data?.monthlyTar||"", 
    currentProject: data?.currentProject||[],
    projectWorked: data?.projectWorked||[],

   });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
    email: yup.string().required("Email is required!"),
        name: yup.string().required("Name  is required!"),
         }),
         onSubmit: async (value, { resetForm }) => {
          const withBranchId = {
            ...value,
            branch: officeId
          }
          let formData = new FormData();
          formData.append("json_data", JSON.stringify(withBranchId));
          formData.append("userImage", image.raw);
          editFormData(
            `/user/edit-user-details/${data.userId}`,
            formData,
            resetForm,
            from
          );
        },
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = ()=>{
    setImage({
      preview: "",
      raw: "",
    });
  }


  function getAllProject() {
    axiosInstance.get(`/DropDown/${officeId}/getAllProjects`).then((res) => {
      let allProject = res.data?.data;
      let finalData = [];
      res.data?.data &&
        allProject.forEach((i) => {
          let x = {
            value: i.projId,
            label: i.projName,
          };
          finalData.push(x);
        });
      setProjectList(finalData);
    }).catch((error) => {
      console.error("Error fetching projects:", error);
        
    });
  }

  useEffect(()=>{
    getAllProject()
  },[])


  function getHOF() {
    let cDate = new Date();
    return cDate.setFullYear(cDate.getFullYear() - 18);
  }
  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, userImage: "" }))
    formik.values.userImage = ''
  }

  return (
    <div className=" w-full h-full py-2 px-4 ">
        {/* <div className=""> */}
        <div className="md:flex justify-end w-full">
        {/* <Breadcrumb
          items={[
            // { name: "Sales" },

            {
              name: "Employee",
              link: `/main/${officeId}/employees`,
            },

            { name: "Edit" },
          ]}
        /> */}
           
            <BackButton/>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap py-2 px-4  overflow-y-auto">
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Personal Info :
  </p>
</div>  
<CustomImgUpload
            borderStyle="dashed"
            handleChange={handleChangeImg}
            label={'User Profile'}
            preview={image.preview || createNew.userImage || ""}
            removeFunction={handleDeleteImg}
          />
            <NuInput
              type="text"
              label="Name"
              placeholder="Enter Name"
              formik={formik}
              name="name"
              isRequired={true}
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              isRequired={true}
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/3 md:-mt-2"
            />
            {/* <NuUpload
              label="User Image"
              width="w-full md:w-1/3"
              onChange={handleChangeImg}
            /> */}
              <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Address :
  </p></div>
            <NuInput
                            type="text"
                            label="Street Address Line 1"
                            placeholder="Street Address Line 1"
                            formik={formik}
                            name="address1"
                            width="w-full md:w-1/2 lg:w-1/3"
                          />
            
                          <NuInput
                            type="text"
                            label="Street Address Line 2"
                            placeholder="Street Address Line 2"
                            formik={formik}
                            name="address2"
                            width="w-full md:w-1/2 lg:w-1/3"
                          />
            <NuInput
              type="text"
              label="Building / Street Name"
              placeholder="Type Here"
              formik={formik}
              name="streetName"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="Enter City"
              formik={formik}
              name="city"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="Enter District"
              formik={formik}
              name="district"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="Enter State"
              formik={formik}
              name="state"
              width="w-full md:w-1/3 md:-mt-2"
            />
           <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="country"
                width="w-full md:w-1/3 md:-mt-2"
                FixPosition={true}
                PortalTarget={true}
              />

            <NuInput
              type="number"
              label="Zip Code"
              placeholder="Type Here"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/3 md:-mt-2"
            /> 


          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Employee Roll :
  </p></div>
  <FormikSelectTwo
                type="text"
                label="Position"
                placeholder="Choose"
                Options={[]}
                formik={formik}
                name="position"
                width="w-full md:w-1/3 md:-mt-2"
              />
            <FormikSelectTwo
              type="text"
              label="Division"
              placeholder="Choose Division"
              Options={[
                {
                  value: "Management",
                  label: "Management",
                },
                {
                  value: "Coordination",
                  label: "Coordination",
                },
                {
                  value: "Procurement",
                  label: "Procurement",
                },
                {
                  value: "Warehouse ",
                  label: "Warehouse ",
                },
                {
                  value: "Customer ",
                  label: "Customer ",
                },
                {
                  value: "Logistics ",
                  label: "Logistics ",
                },
                {
                  value: "Sales ",
                  label: "Sales ",
                },
                {
                  value: "Finance ",
                  label: "Finance ",
                },

              ]}
              formik={formik}
              name="division"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <FormikSelectTwo
              type="text"
              label="Designation"
              placeholder="Choose Designation"
              Options={[
                {
                  value: "gen_manager",
                  label: "Gen. Manager",
                },
                {
                  value: "manager",
                  label: "Manager",
                },
                {
                  value: "coordinator",
                  label: "Coordinator",
                },
                {
                  value: "localPurchaser",
                  label: "Local Purchaser",
                }, {
                  value: "salesman",
                  label: "Salesman",
                }, {
                  value: "storeKeeper",
                  label: "Store Keeper",
                }, {
                  value: "accountant",
                  label: "Accountant",
                },
                {
                  value: "buyer",
                  label: "Buyer",
                },
                 {
                  value: "supportPersonnel",
                  label: "Support Personnel",
                },
               

              ]}
              formik={formik}
              name="designation"
              width="w-full md:w-1/3 md:-mt-2"
            />
              <MultiSelect
              label="Assigned Project"
              Options={projectList}
              formik={formik}
              placeholder="Choose"
              // isRequired={true}
              name="currentProject"
              width="w-full md:w-1/3 md:-mt-2"
            />
             {/* <MultiSelect
              label="Assigned Project"
              options={projectList}
              formik={formik}
              placeholder="Choose"
              // isRequired={true}
              name="projectWorked"
              width="w-full md:w-1/3 md:-mt-2"
            /> */}
 
            {/* {data?.designation === "salesman" && (
              <>
                  <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Traget :
  </p></div>
                  <NuInput
              type="text"
              label="Monthly"
              placeholder="Monthly"
              formik={formik}
              name="monthlyTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
             <NuInput
              type="text"
              label="Quarterly"
              placeholder="Quarterly"
              formik={formik}
              name="quarterlyTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
             <NuInput
              type="text"
              label="Half Year"
              placeholder="Half Year"
              formik={formik}
              name="halfYearTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
             <NuInput
              type="text"
              label="Yearly"
              placeholder="Yearly"
              formik={formik}
              name="yearlyTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
              </>
            )} */}
            <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose Status"
              Options={[
                {
                    value: 0,
                    label: "In-Active",
                  },
                {
                  value: 1,
                  label: "Active",
                },
              ]}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
            </form>
    </div>
  )
}

export default EditEmployee