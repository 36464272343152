import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../Utils/Pagenation";
import { TextMediumSM } from "../Text/MediumText";
import ModalContainer from "./ModalContainer";
import MoreOptions from "../Utils/MoreOptions";
import NuDate from "../Utils/NuDate";
import NuAmount from "../Utils/NuAmount";
import { Link } from "react-router-dom";
import FileIcon from "../Icons/FileIcon";
import { GetAccess, GetRole } from "../Utils/roles";
import Date from "../Icons/Date";
import { CancelButton } from "../Utils/Buttons";

// Table component

const DynamicTable = ({
  fetcher,
  data = [],
  headers,
  bodyHeight,
  total,
  hasMultiEdit,
  hasMultiDelete,
  listofNumbers,
  selectOne = () => {},
  selectMultiple = () => {},
  fullList,
  getById,
  DeteleAccess,
  EditAccess,
  dataLoading,
  pageIndex,
  searchQuery,
  dateShow,
}) => {
  const { t, i18n } = useTranslation();

  const RenderCell = ({ header, row }) => {
   if(header && row){
    if (header?.field === "prDesc") {
      return (
        <p className="line-clamp-2 text-[11px] text-gray-700 text-center">
          {row.prDesc || "No Description"}
        </p>
      );
    }
    if (header?.hasTwoFields) {
      return (
        <div className="text-center">
        
          <p className="text-[12px] text-[#606279] font-medium">
            {row[header?.fieldOne] || "N/A"}
          </p>
          
          <p
        className={`text-[10px] font-medium italic ${
          row[header?.fieldTwo] === "Public"
            ? "text-green-600" 
            : "text-purple-600"  
        }`}
      >{row[header?.fieldTwo] || "N/A"}
      </p>
        </div>
      );
    }
    
    if (header?.field === "vendorNameOrPurchaserName") {
      const vendorName = row.vendorName;
      const purchaserName = row.purchaserName;

      if (vendorName) {
        return (
          <p className="text-[11px] text-red-700 text-center">
            {vendorName}
          </p>
        );
      } else if (purchaserName) {
        return (
          <p className="text-[11px] text-green-700 text-center">
            {purchaserName}
          </p>
        );
      } else {
        return <p className="text-[11px] text-gray-400 text-center">No Data</p>;
      }
    }
    if (header?.isLink) {
      return (
        <Link to={header.link + row[header.id]} state={{ data: row,pageIndex:pageIndex,searchQuery:searchQuery }}>
          {getField(row, header?.field, header?.type)}
        </Link>
      );
    } else {
      if (header?.hasTwo === true) {
        return (
          <div>
            <p className=" text-[11px] text-green-400">
              <NuAmount value={row[header?.fieldOne] || 0} /> {` (P)`}
            </p>
            <p className=" text-[10px] text-gray-400">
              <NuAmount value={row[header?.fieldTwo] || 0} /> {` (E)`}
            </p>
          </div>
        );
      } else if (
        header?.field === "action" &&
        header?.title === "Action" &&
        header?.contents
      ) {
        return (
          <MoreOptions
            data={row}
            fetcher={fetcher}
            id={row[header.contents?.id] || ""}
            removevalue={row[header.contents?.removeValue] || "undefined"}
            url={header.contents?.url}
            moduleName={header.contents?.moduleName}
            from={header.contents?.from}
            editLink={header.contents?.editLink}
            viewLink={header.contents?.viewLink}
            projectLink={header.contents?.projectLink}
            curentPage={pageIndex}
            DeteleAccess={DeteleAccess}
            EditAccess={EditAccess}
            EmailRequired={header.contents?.EmailRequired}
            isEmailSent={row.isEmailSent}
            emailEnable={row.emailEnable}
            emailType={header.contents?.emailType}
            adminPermmision={header.contents?.adminPermmision}
            searchQuery={searchQuery}
            // Edit={row.edit}
            // View={row.view}
          />
        );
      } else if (header?.isCondition) {
        return row[header.field] ? "Yes" : "No";
      } else {
        return getField(row, header?.field, header?.type);
      }
    }
   }
  };

  function getField(row, field, typeValue) {
    
    if(field){
      let splitValue = field.split(".");
      let value = "";
      if (splitValue.length >= 2) {
        if (splitValue.length == 3) {
          value = row[splitValue[0]][splitValue[1]][splitValue[2]];
        } else {
          value = row[splitValue[0]] ? row[splitValue[0]][splitValue[1]] : "";
        }
      } else {
        value = row[splitValue[0]];
      }
  
      if (typeValue === "date-time") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="DD-MMM-YYYY hh:mm a" />
          )) ||
          ""
        );
      } else if (typeValue === "date") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="DD-MMM-YYYY" />
          )) ||
          ""
        );
      } else if (typeValue === "time") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="hh:mm a" />
          )) ||
          ""
        );
      } else if (typeValue === "amount") {
        return <NuAmount currency={row["vendorCurrency"]||row["currency"]} value={value} /> || "";
      } else if (Array.isArray(value)) {
        return value.join(", ");
      }else if (typeValue === "email") { 
        return (<p className=" max-w-[130px] text-center overflow-x-hidden truncate">{value}</p>)
      }else {
        return value;
      }
    }
  }

  function getHeaderId(row) {
    return row[getById] || 0;
  }

  const [DateNewShow, setDateNewShow] = useState(false);

  const handleDateIconClick = () => {
      setDateNewShow(!DateNewShow); 
  };

  const closedate = () => {
      setDateNewShow(false);
  };

  

  return (
    <div className="w-full h-[90%] overflow-hidden">
      <div className="w-full relative max-h-[calc(100vh-145px)] overflow-y-auto">
        <div className=" w-full sticky top-0">
          <table className="w-full" dir={i18n.language == "ar" ? "rtl" : ""}>
            <thead className=" w-full">
              <tr className="text-xs   text-[#4F4768] bg-white z-[9999]">
                {(hasMultiEdit || hasMultiDelete) && (
                  <th className="w-[0.3%] pl-2 bg-white z-[9999]">
                    <input
                      type="checkbox"
                      onChange={(e) => selectMultiple(e)}
                      checked={
                        fullList.length > 0 &&
                        listofNumbers.length === fullList.length
                      }
                    />
                  </th>
                )}
                {headers.map((header, index) => (
                  header.mult===true? <th
                    key={index}
                    className="py-2  truncate text-[12px] 2xl:text-sm font-medium bg-white z-50"
                    style={{ width: header.width, textAlign: header.rowAlign }}
                  >
                 <span className="w-1 h-1 bg-red-600 rounded-full inline-block "></span>   {t(header.title) || ""} <span className="py-2 truncate text-[12px] 2xl:text-sm font-thin text-gray-700">/</span><span className="w-1 h-1 bg-green-600 rounded-full inline-block "></span> <span className="py-2 pr-[1px] truncate text-[12px] 2xl:text-sm font-medium ">{t(header.title2) || ""}</span>
                  </th> : <th
                    key={index}
                    className="py-2  truncate text-[12px] 2xl:text-sm font-medium bg-white z-50"
                    style={{ width: header.width, textAlign: header.rowAlign }}
                  >
                    {t(header.title) || ""}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2]  mx-auto"></div>
        </div>
        <div className={` h-auto z-10 pb-4 mb-16`}>
          <table className="w-full" dir={i18n.language == "ar" ? "rtl" : ""}>
            <tbody>
              {dataLoading == true ? (
                <>
                  <div className=" w-full h-[50vh] flex justify-center items-center">
                    <div className=" flex flex-col justify-center items-center">
                      <div className="flex flex-row gap-2">
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce" />
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.3s]" />
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.5s]" />
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.7s]" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {data.length > 0 ? (
                    <>
                      {data.map((row, rowIndex) => (
                        <tr
                          className=" hover:text-baseColor-primary hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]  text-[#606279]"
                          key={rowIndex}
                        >
                          {(hasMultiEdit || hasMultiDelete) && (
                            <td className="w-[0.3%] pl-2 ">
                              <input
                                type="checkbox"
                                checked={listofNumbers.some(
                                  (id) => id === getHeaderId(row)
                                )}
                                onChange={() => selectOne(getHeaderId(row))}
                              />
                            </td>
                          )}
                            {dateShow && (
                              <td className="w-[0.1%] cursor-pointer pb-0.5  " onClick={handleDateIconClick} >
                              
                            <Date/>
                           
                            </td>
                          )}
                        
                          {headers.map((header, colIndex) => {
                            return (
                              <td
                                className="py-3   text-xs text-center overflow-x-hidden"
                                key={colIndex}
                                style={{
                                  width: header.width,
                                  textAlign: header.textAlign,
                                }}
                              >
                                <div className=" w-full flex justify-center">
                                {RenderCell({ header, row })}
                                </div>
                              </td>
                            );
                          })}
                        
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className=" w-full h-[50vh] flex justify-center items-center">
                        <div className=" flex flex-col justify-center items-center">
                          <FileIcon />
                          <p className=" font-medium text-sm 2xl:text-base text-baseColor-secondary my-2">
                            No Data found
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {DateNewShow && (
                        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
                        <div className="animate-scale-in min-w-[70%] max-w-[95%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                         <h2 className="text-center font-semibold mb-4" >Order Flow</h2>
                         <div className="flex items-center justify-center ">
                          <div>
                      <div className="flex w-[220px]">
      <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div>
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left">YPR Created</p>
      <p className="text-xs text-left">15-Dec-2024</p>
        </div>

                        </div>
                        <div>
                      <div className="flex w-[220px]">
      <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div>
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left">RFQ Floated</p>
    <p className="text-xs text-left">15-Dec-2024 (1 day)</p>

        </div>

                        </div>
                        <div>
                      <div className="flex w-[220px]">
      <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div>
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left">Supplier Price Received</p>
    <p className="text-xs text-left">17-Dec-2024 (2 days)</p>
        </div>

                        </div>
                        <div>
                      <div className="flex w-[220px]">
      <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div>
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left">Customer Quote Approved</p>
    <p className="text-xs text-left">18-Dec-2024 (3 days)</p>
        </div>

                        </div>
                        <div>
                      <div className="flex w-[220px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}
                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left text-gray-500">Quote Submitted to Customer</p>
      <p className="text-xs text-left">&nbsp;</p>


        </div>

                        </div>
                      
                        </div>
                        <div className="flex items-center justify-center py-4">
                        <div>
                      <div className="flex w-[220px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}
   <div className="flex-1 border-t border-gray-300 mt-2 "></div>

                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-center text-gray-500">Order Shipped</p>
      <p className="text-xs text-center">&nbsp;</p>


        </div>

                        </div>
                        <div>
                      <div className="flex w-[220px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}
                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left text-gray-500">PO placed with Supplier</p>
      <p className="text-xs text-left">&nbsp;</p>


        </div>

                        </div>
                        <div>
                      <div className="flex w-[220px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}
                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left text-gray-500">Po to Supplier Approved</p>
      <p className="text-xs text-left">&nbsp;</p>


        </div>

                        </div>
                        <div>
                      <div className="flex w-[300px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}

                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-left text-gray-500">Sales Order Released</p>
      <p className="text-xs text-left">&nbsp;</p>


        </div>

                        </div>
                        <div>
                      <div className="flex w-[220px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}

                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
        
      <p className="text-xs text-left text-gray-500">Lost the Order/Po Received </p>
      <p className="text-xs text-left text-gray-500">from customer</p>


        </div>

                        </div>
                     
                                  
                              
                        </div>
                  
                        <div className="flex items-center  py-4">
                        <div>
                      <div className="flex w-[220px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}
   <div className="flex-1 border-t border-gray-300 mt-2 "></div>

                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-center text-gray-500">Order Under Customers </p>
      <p className="text-xs text-center text-gray-500">clearance</p>


        </div>

                        </div>
           
                        <div>
                      <div className="flex w-[300px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}
   <div className="flex-1 border-t border-gray-300 mt-2 "></div>

                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div>
      <p className="text-xs text-center text-gray-500">Order Received at Warehouse</p>
      <p className="text-xs text-center">&nbsp;</p>


        </div>

                        </div>
                        <div>
                      <div className="flex w-[300px]">
                         {/* {hasQuoteSubmittedToCustomerData ? ( */}
     {/* <div className="rounded-full bg-green-500 w-4 h-4 mb-2"></div> */}
   {/* ) : ( */}
   <div className="flex-1 border-t border-gray-300 mt-2 "></div>

                      <div className="rounded-full border-green-500 border-2 w-4 h-4 mb-2 bg-gray-200"></div>
 {/* )} */}
      <div className="flex-1 border-t border-gray-300 mt-2 "></div>
      </div>
      <div className="text-center">
      <p className="text-xs text-center text-gray-500">Delivered to Customer</p>
      <p className="text-xs text-center">&nbsp;</p>


        </div>

                        </div>
                      
                        </div>

           <div className="flex justify-end gap-2">
           <button
      className={`m-3 px-4 py-[6px] cursor-pointer text-xs 2xl:text-sm  text-textColor-main bg-[#E6F2F2] hover:bg-gray-300
      rounded-md flex items-center`}
      style={{ border: "none" }}
      onClick={closedate}
      type="button"
    >
      Cancel
    </button>
                             </div>
        </div>
                            </div>
      )}  
    </div>
  );
};


// Example usage
const CustomDataTable = ({
  fetcher,
  data,
  availableHeaders,
  defaultHeader,
  total,
  total_records,
  pageIndex,
  setPageIndex,
  dataCount,
  setDataCount,
  height = "h-[calc(100vh-145px)]",
  bodyHeight = "max-h-[calc(100vh-200px)]",
  title = "",
  storageName = "",
  hasMultiEdit = false,
  hasMultiDelete = false,
  selectMultipleFunction = () => {},
  selectOneFunction = () => {},
  listofNumbers = [],
  setShowEdit = () => {},
  showEdit = false,
  setShowDelete = () => {},
  showDelete = false,
  fullList = [],
  getById = "",
  showFieldFalse,
  editAccess = "",
  deteleAccess = "",
  dataLoading = false,
  searchQuery = "",
  dateShow=false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  // Retrieve headers from local storage or set default headers
  const storedHeaders = JSON.parse(localStorage.getItem(storageName));
  const defaultHeaders = storedHeaders || defaultHeader || availableHeaders;

  // State to track table headers and remaining headers
  const [headersState, setHeadersState] = useState(defaultHeaders);

  useEffect(() => {
    localStorage.setItem(storageName, JSON.stringify(headersState));
  }, [headersState]);

  const toggleHeader = (header) => {
    if (headersState.some((h) => h.field === header.field)) {
      // Remove the header from headersState
      setHeadersState((prevHeaders) =>
        prevHeaders.filter((h) => h.field !== header.field)
      );
    } else {
      // Find the index of the toggled header in availableHeaders
      const headerIndex = availableHeaders.findIndex(
        (h) => h.field === header.field
      );
      // Insert the header into headersState at the same index
      setHeadersState((prevHeaders) => [
        ...prevHeaders.slice(0, headerIndex),
        header,
        ...prevHeaders.slice(headerIndex),
      ]);
    }
  };

  

  return (
    <div className={`w-full ${height} rounded-md bg-white p-3 mt-4 relative`}>
      <div className=" flex justify-between items-center ">
        {/* <TextMediumSM content={title} color="text-[#4F4768]"/>  */}
        <p className="font-medium text-xs 2xl:text-sm text-[#4F4768]">
          {title} 
          {/* {data.length > 0 && `: ${total} `} */}
        </p>
        <div className=" flex gap-3 items-center">
          {hasMultiEdit && listofNumbers.length > 0 && (
            <button
              className=" bg-[#048178] px-3 py-[3px] text-white text-xs font-medium rounded-md "
              onClick={() => setShowEdit(!showEdit)}
            >
              {title === "Supplier" ? "Edit Access" : "Multi Edit"}
            </button>
          )}
          {hasMultiDelete && listofNumbers.length > 0 && (
            <button
              className=" bg-[#048178] px-3 py-[3px] text-white text-xs font-medium rounded-md "
              onClick={() => setShowDelete(!showDelete)}
            >
              Delete
            </button>
          )}
        </div>
        {/* {showFieldFalse == false ? null : (
          <>
            {GetRole() && (
              <div
                className=" rounded-lg  bg-slate-300  px-3 py-1 text-xs  cursor-pointer text-[#4F4768]"
                onClick={() => setIsModalOpen(true)}
              >
                {t("Show Feilds")}
              </div>
            )}
          </>
        )} */}

      </div>

      <DynamicTable
        fetcher={fetcher}
        data={data}
        headers={headersState}
        bodyHeight={bodyHeight}
        hasMultiEdit={hasMultiEdit}
        hasMultiDelete={hasMultiDelete}
        listofNumbers={listofNumbers}
        selectOne={selectOneFunction}
        selectMultiple={selectMultipleFunction}
        fullList={fullList}
        getById={getById}
        EditAccess={editAccess}
        DeteleAccess={deteleAccess}
        dataLoading={dataLoading}
        total={total}
        total_records={total_records}

        pageIndex={pageIndex}
        searchQuery={searchQuery}
        dateShow={dateShow}
      />
      <div className=" w-full h-[40px] text-[#4f4768]">
        <Pagination
          total={total}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Table Headers"
        content={
          <div>
            {availableHeaders.map((header, index) => (
              <div
                key={index}
                className=" flex justify-start items-center gap-3 py-1"
                dir={i18n.language == "ar" ? "rtl" : ""}
              >
                <input
                  type="checkbox"
                  id={`header-${index}`}
                  checked={headersState.some((h) => h.field === header.field)}
                  onChange={() => toggleHeader(header)}
                />

                <label className=" font-medium" htmlFor={`header-${index}`}>
                  {t(header.title)}
                </label>
              </div>
            ))}
          </div>
        }
        handleClick={() => setIsModalOpen(false)}
        closeModal={() => setIsModalOpen(false)}
      />
    
   

    </div>
  );
};

export default CustomDataTable;
