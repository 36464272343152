import { lazy } from 'react'
import { Route } from 'react-router-dom'
import ProjectReportMain from '../Views/Web/Reports/Project/ProjectReportMain'
import ReportsMain from '../Views/Web/Reports/Main'
import ReportIndex from '../Views/Web/Reports'
import ProjectReport from '../Views/Web/Reports/Project'
import TaskReport from '../Views/Web/Reports/Tasks'
import ClientsReport from '../Views/Web/Reports/Clients'
import MembersReport from '../Views/Web/Reports/Members'

// const ReportsMain =lazy(()=>import('../Pages/Web/Reports/Main')) 
// const ReportIndex =lazy(()=>import('../Pages/Web/Reports/')) 
// const ProjectReport =lazy(()=>import('../Pages/Web/Reports/Project')) 
// const TaskReport =lazy(()=>import('../Pages/Web/Reports/Tasks')) 
// const ClientsReport =lazy(()=>import('../Pages/Web/Reports/Clients')) 
// const MembersReport =lazy(()=>import('../Views/Web/Reports/Members')) 

function ReportsRoutess() {
  return (
    <Route path="reports" element={<ReportsMain/>}>
    <Route index element={<ReportIndex/>} />
    <Route path="projects" element={<ProjectReport/>}/>
    <Route path="tasks" element={<TaskReport/>}/>
    <Route path="clients" element={<ClientsReport/>}/>
    <Route path="members" element={<MembersReport/>}/>
    <Route path='project' element={<ProjectReportMain/>}>
      <Route path="daily-progress-report" element={<ClientsReport/>}/> 
    </Route>
  </Route>
  )
}

export default ReportsRoutess