import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Priority = () => {
  return (
    <SettingsMetaData propertyName={"priority_RFQ"} viewPropertyName={"Priority "} />

  )
}

export default Priority