import { Link, Outlet, useLocation } from "react-router-dom"
import Dasboard from "../../../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../../../Components/Utils/SearchBar";
import { CreateButton } from "../../../../../Components/Utils/Buttons";
import { Select } from "antd";
import { GetAccess } from "../../../../../Components/Utils/roles";
import Breadcrumb from "../../../../../Components/Utils/Breadcrumb";


const TimesheetLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= true,
        ImportAccess= true,
        setOpen=()=>{},
        setOpenSub=()=>{},
        children,
        officeId,
        projectId,
        hasCreate=false
    }
) =>{
    let {pathname} = useLocation()
    console.log("path",pathname);
    const [module,setModule] = useState("")
    console.log("hhhh",module);

    useEffect(() =>{
        if(pathname == `/project/hr/attendance/${officeId}/${projectId}`){
            setModule("Attendance")
        }else if(pathname == `/project/hr/timesheets/${officeId}/${projectId}`){
            setModule("Timesheets")
        }
        else if(pathname == `/project/hr/team/${officeId}/${projectId}`){
            setModule("team")
        }
        // else if(pathname == "/progress/petty-cash-expense"){
        //     setModule("Petty Cash Expense")
        // }
    },[pathname])

    let LinkItem = [
        {
            label:"Attendance",
            link:`/project/hr/attendance/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Timesheets",
            link:`/project/hr/timesheets/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Team",
            link:`/project/hr/team/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        // {
        //     label:"Petty Cash Expense",
        //     link:"/progress/petty-cash-expense",
        //     access:true,
        // }
    ]
    
    return(
        <div className=" w-full h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-between w-full">
            <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                // <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                                <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>

                            )
                         }
                        </>
                    )
                })
            }
        </div>

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              {/* {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />} */}
              {/* {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>} */}
                  {/* <BackButton/> */}
                {hasCreate &&(
                    <div className=" flex items-center gap-2">
                  <CreateButton label="Team" handleClick={() => setOpen(true)} />
                  <CreateButton label="Sub-Con" handleClick={() => setOpenSub(true)} />
                </div>
                )

                }
                  
                </div>
            </div>
           
           
           
            <div className=" rounded-md w-full h-[calc(100vh-170px)] bg-white mt-2">
               {children} 
            </div>
            </div>
        </div>
    )
}

export default TimesheetLayout;