import { notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../Services/axiosCommon";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dasboard from "../../../Components/Icons/Dasboard";
import { SecondHeading } from "../../../Components/Utils/Heading";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../Components/Utils/Buttons";

import useFetchMataData from "../../../Components/Hooks/useFetchMataData";
import { NuDate, NuInput, NuLabel, NuSelect, NuSelectwithSearch, NuTextArea } from "../../../Components/Utils/Input";
import MultiSelect from "../../../Components/Utils/MultiSelect";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import FormikSelectTwo from "../../../Components/Utils/Select";
import UseCustomerDropDownNew from "../../../Components/Hooks/useCustomerSearch";

const CreateProject = ({}) => {
  const {officeId} = useParams();
  // const {value} = useFetchMataData(`/Metadata/projectstatus`)
  const { value: projectStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "projectStatus"
  );
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  // const [proStatus, setProStatus] = useState([]);
  const [proManager, setProManager] = useState([]);
  const [proClient, setProClient] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    projName: "",
    projScope: "",
    projCost: 0,
    startDate: "",
    endDate: "",
    client: "",
    projTeam: [],
    awardedOnDate: "",
    noOFDays: "",
    noOFWeeks: "",
    assignedTo: [],
    comment: "",
    projStatus: "",
    revisedEndDate: "",
    reasonForDateRevised: "",
    branchId:""
  });

  // const getProjectStatus = async () => {
  //   await axios
  //     .get("/Metadata/projectstatus")
  //     .then((response) => {
  //       let FinalData = [];
  //       let status = response.data?.data?.keyValues;
  //       status.forEach((item) => {
  //         let tempObject = { value: item, label: item };
  //         FinalData.push(tempObject);
  //       });
  //       response.data?.data && setProStatus(FinalData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getProjectManager = async () => {
    await axios
      .get(`/DropDown/team_member/${officeId}`)
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = {
            value: item.userId,
            label: `${item.Name}`,
          };
          FinalData.push(tempObject);
        });
        response.data?.data && setProManager(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClients = async () => {
    await axios
      .get(`/DropDown/${officeId}/client`)
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = { value: item.clientId, label: item.companyName };
          FinalData.push(tempObject);
        });
        response.data?.data && setProClient(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getProjectStatus();
    getProjectManager();
    // getClients();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      projName: yup.string().required("Project Name Required"),
      client: yup.string().required(" Client Name Required"),
      awardedOnDate: yup.string().required("Awarded On Date is required"),
      startDate: yup.string().required("Start Date is required"),
      endDate: yup.string().required("End Date is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
      value.branchId = officeId;
      let currentDate = new Date();
      let startDate = new Date(value.startDate);
      if(value.projStatus =="In progress" || value.projStatus =="Completed") {
        if(currentDate>=startDate){     
          axios
          .post("/Project/", value)
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false); // Set loading state to true
              resetForm();
              api.success({
                message: `Added successfully`,
                description: res?.data?.msg || "Project Has been Added",
                placement: "bottomRight",
              });
              navigate(-1); // Go back
              // onCancel();
            } else {
              setIsLoading(false); // Set loading state to true
              api.error({
                message: `Added failed`,
                description: res?.data?.msg || "Project not Added",
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            setIsLoading(false); // Set loading state to true
            api.error({
              message: `Added failed`,
              description: err?.data?.msg || "Project not Added",
              placement: "bottomRight",
            });
          });
        }else{
          setIsLoading(false); // Set loading state to true
          api.error({
            message: `Added failed`,
            description:"Please change Project Status or Start date  ",
            placement: "bottomRight",
          });
        }

      }else{
        axios
        .post("/Project/", value)
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false); // Set loading state to true
            resetForm();
            api.success({
              message: `Added successfully`,
              description: res?.data?.msg || "Project Has been Added",
              placement: "bottomRight",
            });
            navigate(-1); // Go back
            // onCancel();
          } else {
            setIsLoading(false); // Set loading state to true
            api.error({
              message: `Added failed`,
              description: res?.data?.msg || "Project not Added",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false); // Set loading state to true
          api.error({
            message: `Added failed`,
            description: err?.data?.msg || "Project not Added",
            placement: "bottomRight",
          });
        });
      }
    },
  });

  function diff_weeks(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    //  let result = Math.abs(Math.round(diff));
    return Math.abs(Math.round(diff));
  }

  function DateDiff(createDate, expiryDate) {
    let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays + 1;
  }

  useMemo(() => {
    if (formik.values.startDate && formik.values.endDate) {
      let startDate = new Date(formik.values.startDate);
      let endDate = new Date(formik.values.endDate);
      formik.values.noOFWeeks = diff_weeks(startDate, endDate);
      formik.values.noOFDays = DateDiff(startDate, endDate);
    }
  }, [formik.values.startDate, formik.values.endDate]);

  const [searchCustomer, setSearchCustomer] = useState("");
 
  const { data: customers, setDataCustomer } = UseCustomerDropDownNew(
    `/customers/drop-down/${officeId}/branch`,
    searchCustomer
  );

  return (
    <div className="  w-full h-full px-4 py-2 overflow-hidden ">
      {contextHolder}
      <div className="md:flex justify-end w-full">
        {/* <Breadcrumb
          items={[
           
            { name:"Project",
              link:`/main/${officeId}/projects`
             },
            { name:"Create" },
          ]}
        /> */}
        <div className=" flex gap-2 items-center ">
          <BackButton />
        </div>
      </div>
      <div
          className=" w-full mx-auto h-[calc(100vh-145px)] rounded-md mt-2 overflow-y-scroll bg-white"
          // style={{ border: "1px solid #C5C5C5" }}
        >     <form onSubmit={formik.handleSubmit}>
          <div className=' w-full flex flex-wrap rounded-lg my-2'
          // style={{ border: "1px solid #ECEBF3" }}
          >
             <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                Project Details :
                </p>
              </div>
            <NuInput
             type="text"
              label="Project Name"
              isRequired={true}
              placeholder="Name"
              formik={formik}
              name="projName"
              width="w-full lg:w-6/12"
            />
            <MultiSelect
              label="Managed/ Lead By"
              Options={proManager ? proManager : []}
              formik={formik}
              placeholder="Choose"
              // isRequired={true}
              name="assignedTo"
              width="w-full lg:w-6/12"
            />
            <NuTextArea
              label="Project Scope"
              // isRequired={true}
              placeholder="Project Scope"
              formik={formik}
              name="projScope"
              width="w-full lg:w-6/12"
            />
            <div className="w-full lg:w-[50%]">
              <FormikSelectTwo
                label="Project Status"
                Options={projectStatus}
                formik={formik}
                placeholder="Choose"
                isRequired={true}
                name="projStatus"
                width="w-full   "
              />
              <div className=" w-full flex flex-wrap  ">
                <NuDate
                  label="Awarded On"
                  isRequired={true}
                  formik={formik}
                  name="awardedOnDate"
                  width="w-full md:w-[50%]  "
                  max={NuDate({value:formik.values.endDate, format: "yyyy-MM-DD"})}
                />
                <NuDate
                  label="Start Date"
                  isRequired={true}
                  formik={formik}
                  name="startDate"
                  width="w-full md:w-[50%] "
                  min={NuDate({value:formik.values.awardedOnDate, format: "yyyy-MM-DD"}) }
                  max={NuDate({value:formik.values.endDate, format: "yyyy-MM-DD"})}
                />
              </div>
            </div>
            <div className=" w-full flex flex-wrap justify-between ">
              {/* <NuSelectwithSearch
                label="Client Name"
                isRequired={true}
                options={proClient ? proClient : []}
                formik={formik}
                placeholder="Choose"
                name="client"
                width="w-full md:w-[50%] lg:w-6/12"
              /> */}
               <FormikSelectTwo
                type="text"
                label="Client"
                placeholder="Client"
                Options={customers}
                formik={formik}
                name="client"
                width="w-full md:w-1/2"
                isRequired={true}
                // px="0"
                // handleClick={togglePopup}
                setSearch={setSearchCustomer}
                FixPosition={true}
                PortalTarget={true}
              />

              <NuDate
                label="End Date"
                isRequired={true}
                formik={formik}
                name="endDate"
                width="w-full md:w-[50%] lg:w-[25%] "
                min={NuDate({value:formik.values.startDate, format: "yyyy-MM-DD"}) }
              />

              <NuInput
               type="text"
                label="No. Of Days"
                placeholder="0"
                disabled={true}
                formik={formik}
                name="noOFDays"
                width="w-full md:w-[50%] lg:w-[25%] "
              />
            </div>
          </div>
          <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
            <CancelButton handleClick={() => navigate(-1)} />
            <SubmitButton isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProject;
