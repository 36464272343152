import React from 'react'
import { Route } from 'react-router-dom';
import MainProcurements from '../Views/Web/Project/SubMenu/Procurement/MainProcurement';
import MainPr from '../Views/Web/Project/SubMenu/Procurement/PR/MainPr';
import PRIndex from '../Views/Web/Project/SubMenu/Procurement/PR';
import CreatePr from '../Views/Web/Project/SubMenu/Procurement/PR/CreatePr';
import EditPr from '../Views/Web/Project/SubMenu/Procurement/PR/EditPr';
import ViewPr from '../Views/Web/Project/SubMenu/Procurement/PR/ViewPr';
import MainRFQ from '../Views/Web/Project/SubMenu/Procurement/RFQ/MainRFQ';
import RFQIndex from '../Views/Web/Project/SubMenu/Procurement/RFQ';
import CreateRFQ from '../Views/Web/Project/SubMenu/Procurement/RFQ/CreateRFQ';
import EditRQF from '../Views/Web/Project/SubMenu/Procurement/RFQ/EditRQF';
import ViewRFQ from '../Views/Web/Project/SubMenu/Procurement/RFQ/ViewRFQ';
// import MainQuoteComparision from '../Views/Web/Project/SubMenu/Procurement/QuoteComparision/MainQuoteComparision';
import ViewQuotesByPr from '../Views/Web/Project/SubMenu/Procurement/QuoteComparision/quotView';
import MainQuoteComparision from '../Views/Web/Project/SubMenu/Procurement/QuoteComparision';
import MainPo from '../Views/Web/Project/SubMenu/Procurement/Po/MainPo';
import PoIndex from '../Views/Web/Project/SubMenu/Procurement/Po';
import CreatePo from '../Views/Web/Project/SubMenu/Procurement/Po/CreatePo';
import EditPo from '../Views/Web/Project/SubMenu/Procurement/Po/EditPo';
import ViewPo from '../Views/Web/Project/SubMenu/Procurement/Po/ViewPo';
import MainPaymentRequest from '../Views/Web/Project/SubMenu/Procurement/PaymentRequest.jsx/MainPaymentRequest';
import PaymentRequestIndex from '../Views/Web/Project/SubMenu/Procurement/PaymentRequest.jsx/Index';
import CreatePaymentRequest from '../Views/Web/Project/SubMenu/Procurement/PaymentRequest.jsx/CreatePaymentRequest';
import EditPaymentRequest from '../Views/Web/Project/SubMenu/Procurement/PaymentRequest.jsx/EditPaymentRequest';
import ViewPaymentRequest from '../Views/Web/Project/SubMenu/Procurement/PaymentRequest.jsx/ViewPaymentRequest';
import MainProductComparision from '../Views/Web/Project/SubMenu/Procurement/ProductComparison/MainProductConparision';

import ViewProductSupplierList from '../Views/Web/Project/SubMenu/Procurement/ProductComparison/ViewProductList';
import SupplierPriceListing from '../Views/Web/Project/SubMenu/Procurement/ProductComparison/SupplierPriceListing';
import MainTeamPerformance from '../Views/Web/Project/SubMenu/Procurement/TeamPerformance/MainTeamPerformance';
import TeamPerformanceIndex from '../Views/Web/Project/SubMenu/Procurement/TeamPerformance/Index';
import ProductComparisonIndex from '../Views/Web/Project/SubMenu/Procurement/ProductComparison';
import MainSupplierQuotations from '../Views/Web/Project/SubMenu/Procurement/SupplierQuotations/MainSupplierQuotations';
import SupplierQuotesIndex from '../Views/Web/Project/SubMenu/Procurement/SupplierQuotations';
import CreateSupplierQuotations from '../Views/Web/Project/SubMenu/Procurement/SupplierQuotations/CreateSupplierQuotations';
import EditSupplierQuotations from '../Views/Web/Project/SubMenu/Procurement/SupplierQuotations/EditSupplierQuotations';
import ViewSupplierQuotations from '../Views/Web/Project/SubMenu/Procurement/SupplierQuotations/ViewSupplierQuotations';

function ProjectProcurementroutes() {
  return (
    <Route path="procurement" component={<MainProcurements/>}>
    <Route path="ypr/:officeId/:projectId" element={<MainPr />}>
      <Route index element={<PRIndex />} />
      <Route path="create" element={<CreatePr />} />
      <Route path="edit/:projectYprEditId" element={<EditPr />} />
      <Route path="view/:projectyprViewId" element={<ViewPr />} />
    </Route>

    <Route path="rfq/:officeId/:projectId" element={<MainRFQ />}>
      <Route index element={<RFQIndex />} />
      <Route path="create" element={<CreateRFQ />} />
      <Route path="edit/:rfqEditId" element={<EditRQF />} />
      <Route path="view/:rfqViewId" element={<ViewRFQ />} />
    </Route>

     <Route path="quote-comparison/:officeId/:projectId" element={<MainQuoteComparision />}>
      <Route index element={<MainQuoteComparision />} />
      {/* <Route path="create" element={<CreateMaterial />} /> */}
      {/* <Route path="edit/:materialEditId" element={<EditMaterial />} /> */}
      {/* <Route path="view/:quote-comparisonViewId" element={<ViewQuotesByPr />} /> */}
    </Route>
    
   <Route path="parchase-order/:officeId/:projectId" element={<MainPo />}>
      <Route index element={<PoIndex />} />
      <Route path="create" element={<CreatePo />} />
      <Route path="edit/:EditIdpo" element={<EditPo />} />
      <Route path="view/:ViewIdpo" element={<ViewPo />} />
    </Route>

    <Route path="payment-request/:officeId/:projectId" element={<MainPaymentRequest />}>
      <Route index element={<PaymentRequestIndex />} />
      <Route path="create" element={<CreatePaymentRequest />} />
      <Route path="edit/:paymentReqEditId" element={<EditPaymentRequest />} />
      <Route path="view/:paymentReqViewId" element={<ViewPaymentRequest />} />
    </Route>

    <Route path="product-comparision/:officeId/:projectId" element={<MainProductComparision />}>
      <Route index element={< ProductComparisonIndex/>} />
      {/* <Route path="create" element={<CreatePaymentRequest />} /> */}
      <Route path="view/:paymentReqEditId" element={<ViewProductSupplierList />} />
      <Route path="listing/:paymentReqViewId" element={<SupplierPriceListing />} />
    </Route>


    <Route path="team-performance/:officeId/:projectId" element={<MainTeamPerformance />}>
      <Route index element={<TeamPerformanceIndex />} />
     
    </Route>
    <Route path="supplier-quotes/:officeId/:projectId" element={<MainSupplierQuotations />}>
      <Route index element={<SupplierQuotesIndex />} />
      <Route path="create" element={<CreateSupplierQuotations />} />
      <Route path="edit/:paymentReqEditId" element={<EditSupplierQuotations />} />
      <Route path="view/:paymentReqViewId" element={<ViewSupplierQuotations />} />
    </Route>
  </Route>
  
  )
}

export default ProjectProcurementroutes;