import React from 'react'
import EquipmentCategory from './Category'
import EquipmentItem from './Item'
import EquipmentDivision from './division'
import EquipmentUnit from './Unit'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'

function EquipmentProgressIndex() {
    return(
        <>
        <TextMediumSM content="Progress" />
        <TextMediumSM content="Equipment" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <EquipmentCategory/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <EquipmentDivision/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <EquipmentItem/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <EquipmentUnit/>
         </div>
         
         </div>
         </>
    )
}

export default EquipmentProgressIndex