import React, { useEffect, useState } from 'react'
import { NormalHeading } from '../../../../Components/Utils/Heading';
import { BackButton, CancelButton, CreateButton, EditButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import SideModal from '../../../../Components/Others/SideModel';
import DefaultDownloadLink from './pdf/DefaultDownloadLink';
import NuDate from '../../../../Components/Utils/NuDate';
import ViewPdf from './ViewPdf';
import Comments from './CommentsPr';
import CommonComments from '../../../../Components/Utils/commonComments';
import ApprovalComponent from '../../../../Components/Utils/approvalComponent';
import axiosInstance from '../../../../Services/axiosCommon';
import NuAmount from '../../../../Components/Utils/NuAmount';
import POPdfOther from '../../ViewPdfTemplate/POPdfOther';
import POPdfNu from '../../ViewPdfTemplate/POPdfNu';
import PDFViewer from '../../../../Components/Utils/PDFViewer';
import { GetAccess, GetRole } from '../../../../Components/Utils/roles';

function ViewPo(poId) {
  const { poViewId , officeId} = useParams();
  const location = useLocation();

  const pageIndex = location.state?.pageIndex;
  const searchQuery = location.state?.searchQuery;
  
  const poData = location?.state?.data


  const [data,setData] = useState(poData? poData:null);
 
  

  function getView() {
    axiosInstance
      .get(`/PO/${poViewId}`)
      .then((res) => {
        setData(res.data?.data);       
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    if (!poData) {
      getView();
    }
  }, [poViewId]);

  const [showPdfComponent, setShowPdfComponent] = useState(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [grnData, setGrnData] = useState(null); 
  const [loading, setLoading] = useState(false); 

  const handleOpenCommentsModal = async () => {
    setIsCommentsModalOpen(true);
    setLoading(true);

    try {
      const response = await axiosInstance.get(`/grn/${poViewId}/history`); 
      if (response.data.status) {
        setGrnData(response.data.data); 
      }
    } catch (error) {
      console.error('Error fetching GRN data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
    setGrnData(null);
  };
  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };

  const id = poViewId; 
  const getUrl = `/PO/get-status`;
  const putUrl = `/PO/edit-status`; 
  const statusUrl = '/PO/all-status';
  const undoUrl = `/PO/undo-status`; 
  const editUrl = '/PO/edit-reason';



  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/PO/download-pdf/${poViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};

const [viewPDF,setViewPDF] = useState(null);

const ViewPDF = async () => {
  try {
      const response = await axiosInstance.get(`/PO/download-pdf/${poViewId}`, {
          responseType: 'blob' // Important to specify the response type as blob
      });

      // Create a Blob from the response
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element
      let Viewlink = window.URL.createObjectURL(pdfBlob);
      setViewPDF(Viewlink);

  } catch (error) {
      console.error('Error downloading the PDF:', error);
  }
};
console.log(data?.shipmentDetails?.ETA,"kjhgc");

useEffect(() => {
  ViewPDF()
},[poViewId,data])

const EditAccess = "purchase_order_edit"
return (
  <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-end ">
        {/* <Breadcrumb 
       items={[
        { name: "Purchase Order",
            link:`/main/${officeId}/procurement/purchase-order`},
            
          { name: "View"},
  ]} /> */}
       <div className=" flex gap-2">
        
          <Link to={'/procurement/purchase-order'}state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
         
          <CreateButton svg={false} handleClick={downloadPDF} label="Download as PDF" />
          {/* <DefaultDownloadLink
            data={data}
            fileName={`Purchase Order_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          /> */}
          {data?.editable === false ? (
            <>
            {GetRole() && (
          <Link to={`/main/${officeId}/procurement/purchase-orderedit/${poViewId}`} 
          state={{data: data, from:`/main/${officeId}/procurement/purchase-orderview/${poViewId}`}}
          ><EditButton height='8'/></Link>
          )}
          </>
        ) : (
        <>
        {GetAccess(EditAccess) && (
          <Link to={`/main/${officeId}/procurement/purchase-orderedit/${poViewId}`} 
          state={{data: data, from:`/main/${officeId}/procurement/purchase-orderview/${poViewId}`}}
          ><EditButton height='8'/></Link>
          )}
        </>
        )
      }
                 {/* <button className={` cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`} onClick={handleOpenCommentsModal}>Comments</button> */}

        </div>
    </div> 
    <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
       
       <div className='grid items-center px-2 w-[70%]'>
          <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data?.ref}</p>
                      </div>
              <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.createdAt ? (
    <NuDate value={data?.createdAt} format="DD-MMM-YYYY, hh:mm A" /> 
  ) : (
    "N/A"
  )}
         </p>
         <p className="text-xs text-[#111537]"> Created By : {data?.preparedByName || 'None'}</p>
       
         </div>
     
                
      </div>
      
               <div className="grid   justify-end w-[30%]">
               <div className='pb-1 flex  justify-end'>
               <Link to={`/main/${officeId}/procurement/purchase-orderviewGrnIndex/${poViewId}`} state={{data: data, from:`/procurement/purchase-order/view/${poViewId}`}} >
         <p className='text-xs cursor-pointer text-white bg-[#6A9C89] rounded-md inline-flex w-auto h-5 px-2 mr-1 flex-shrink-0 items-center justify-center'
          // onClick={handleOpenCommentsModal}
          >
          GRN History
         </p>
         </Link>
            </div>
               <div className="border rounded-md mr-1 border-[#579BB1] text-xs inline-flex w-auto  flex-shrink-0  justify-center">
            <p className="  bg-white  text-black  p-1 rounded-l-md  ">
              Status  </p><p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md"> {data?.status || 'None'}</p>
             
              </div>
       
       
       </div>  
              </div>
          <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8">

          <div className=" w-full flex flex-wrap   border border-gary mt-2 px-2 rounded-lg ">
          <div className=" w-full grid grid-cols-2 gap-x-8 ">
              
                 <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Req. Delivery Date</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data?.requestedDeliveryDate? (
                  <NuDate value={data?.requestedDeliveryDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
                </div>
               
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Delivery Terms</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.deliveryTerms}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Payment Status</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data?.paymentStatus}</p>
                </div>
              
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Currency</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {String(data?.vendorCurrency).toUpperCase()}</p>
                </div>
                {data?.isEmailSent===true && (
                  <>
           <div className='w-full flex px-2'>
  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Email Sent Date & Time</p>
  <p className='w-[65%] py-2 text-xs text-[#606279]'>
    : {data?.emailSentDateTime ? (
      <NuDate value={data?.emailSentDateTime} format="DD-MMM-YYYY, hh:mm A" /> 
    ) : (
      "N/A"
    )}
  </p>
</div>

           
            </>
            )}
  
            
              </div>
              
              </div>
              {data?.shipmentDetails && (
                <>
<p className='w-full pt-4 3x-4  text-xs font-semibold text-[#111537]'>Booking Details</p>

<div className='w-full flex flex-wrap   border border-gary mt-2 px-2 rounded-lg "'>
<div className=" w-full grid grid-cols-2 gap-x-8 ">
 
{data?.shipmentDetails?.bookingDate   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Booking Date</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.bookingDate? (
                  <NuDate value={data?.shipmentDetails?.bookingDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
</div>
)}
{data?.shipmentDetails?.agent   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Agent</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.agentName}</p>
</div>
)}
{data?.shipmentDetails?.modeOfShipment   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Mode Of Shipment</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.modeOfShipment}</p>
</div>
)}
{data?.shipmentDetails?.permitReq   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Permit Required?</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.permitReq  ?  data?.shipmentDetails?.permitReq ? "Yes" : "No" : '-'}</p>
</div>
)}
{data?.shipmentDetails?.ETD   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> ETD </p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.ETD? (
                  <NuDate value={data?.shipmentDetails?.ETD} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
</div>
)}
{data?.shipmentDetails?.ETA   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> ETA</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.ETA? (
                  <NuDate value={data?.shipmentDetails?.ETA} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
</div>
)}

{data?.shipmentDetails?.ESC   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Estimated Shipping Cost</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.ESC}</p></div>
)}

{data?.shipmentDetails?.ASC   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Actual Shipping Cost </p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.ASC}</p></div>
)}

{data?.shipmentDetails?.shipmentBill   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Shipment Bill No.</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.shipmentBill}</p></div>
)}
{data?.shipmentDetails?.trackingUrl   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Tracking Url</p>
 <p className='w-[65%] py-2 text-xs font-medium truncate text-[#606279]'>: {data?.shipmentDetails?.trackingUrl}</p></div>
)}
</div>
</div>
</>
)}
              <div className="flex gap-4 mt-4">
          <div className="w-[50%] ">
        <p className="  text-xs font-semibold text-[#111537] p-1">
        Billing Address
            </p>
        <div className="  border border-gary p-2 h-28 overflow-y-scroll rounded-lg items-center">
           <div className='flex'>
           {  data?.billingAddressLine1 &&(
            <p className="   text-xs  break-words text-[#606279]">
              {data?.billingAddressLine1|| "" } ,
            </p>
            ) }
              {data?.billingAddressLine2 && (
            <p className="   text-xs  break-words text-[#606279]">
              {data?.billingAddressLine2  || ""},
            </p>)}
            </div>
            <div className='flex'>
           
            {data?.billing_city && (
            <p className="   text-xs  break-words text-[#606279]">
              {data?.billing_city || ""} ,
            </p>)}
            {data?.billing_state &&(
            <p className="   text-xs  break-words text-[#606279]">
              {data?.billing_state || ""},
            </p>)}
            </div>
            <div className='flex'>
           
            {data?.billing_country &&(
            <p className="   text-xs  break-words text-[#606279]">
              {data?.billing_country || ""} ,
            </p>)}
            {data?.billing_postal_code &&(
            <p className="   text-xs  break-words text-[#606279]">
              pin-{data?.billing_postal_code || 0}
            </p>)}
            </div>
          </div>
         </div>
          <div className="w-[50%] ">
          <p className=" p-1 text-xs font-semibold text-[#111537]">
          Delivery Address
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
          <div className='flex'>
           {  data?.deliveryAddressLine1 &&(
            <p className="   text-xs  break-words text-[#606279]">
              {data?.deliveryAddressLine1 || ""} ,
            </p>
            ) }
              {data?.deliveryAddressLine2 && (
            <p className="   text-xs  break-words text-[#606279]">
              {data?.deliveryAddressLine2 || ""},
            </p>)}
            </div>
            <div className='flex'>
           
            {data?.delivery_city && (
            <p className="   text-xs  break-words text-[#606279]">
              {data.delivery_city || ""} ,
            </p>)}
            {data?.delivery_state &&(
            <p className="   text-xs  break-words text-[#606279]">
              {data?.delivery_state|| "" },
            </p>)}
            </div>
            <div className='flex'>
           
            {data?.delivery_country &&(
            <p className="   text-xs  break-words text-[#606279]">
              {data.delivery_country || "" } ,
            </p>)}
            {data?.delivery_postal_code &&(
            <p className="   text-xs  break-words text-[#606279]">
              pin-{data?.delivery_postal_code  || 0}
            </p>)}
            </div>
    </div>
    </div>
    </div>
            

           
            <div className="w-full bg-white border border-gary  mt-4 rounded-lg ">
            <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-2">
                     <p className="w-[5%]  text-center   text-white text-xs font-medium p-1">S.N. </p>

            <p className=" w-[50%] text-left  text-white text-xs font-medium p-1"> Product </p>
            <p className=" w-[5%] text-center text-white text-xs font-medium p-1"> Unit</p>
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">  Req.Qty</p>
            
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1"> Ord. Qty </p>
         
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">  Price</p>
           
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">  Neg.Price</p>
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">
                Total
                </p>
                       </div>
            {data?.products?.length>0 && data?.products?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2">
                  
                 <p className="w-[5%]  text-center pl-1  text-xs font-medium text-[#111537] py-1">
                                    {index + 1}
                                </p>
                 <p className=" w-[50%] text-left   text-xs font-medium text-[#111537] py-1">
                  {List?.product_name}
                </p>
                <p className=" w-[5%] text-center  text-xs font-medium text-[#111537] py-1">
                  {List?.unit}
                </p>
              
                <p className=" w-[10%] text-center  text-xs font-medium text-[#111537] py-1">
                  {List?.requestedQty}
                </p> 
                <p className=" w-[10%] text-center  text-xs font-medium text-[#111537] py-1">
                  {List?.orderedQty}
                </p>
                <p className="w-[10%] text-center  text-xs font-medium text-[#111537] py-1">
  {(Number(List?.price) || 0).toLocaleString(undefined, { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  })}
</p>

                <p className="w-[10%] text-center  text-xs font-medium text-[#111537] py-1">
  {(Number(List?.negotiatedPrice) || 0).toLocaleString(undefined, { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  })}
</p>


              
                <p className="text-center w-[10%]  text-xs font-medium text-[#111537] py-1">
  {(List.negotiatedPrice * List.orderedQty).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</p>
                </div>
              );
            })}
            <div className="w-full flex px-4 py-2 justify-end">
          <div className="flex">
            <p className=" py-3 text-xs font-medium text-[#111537]">
              Total Price 
            </p>
            <p className=" py-3 text-xs font-medium ">
             : <NuAmount currency={String(data?.vendorCurrency).toUpperCase()} value= {data?.totalPrice} />
            </p>
          </div>
        </div>
          </div>
          {/* <div className="w-full flex px-2 py-2">
          <div className="w-1/4 flex">
            <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
              Total Price :
            </p>
            <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
              {data.totalPrice}
            </p>
          </div>
          </div> */}
          </div>
          </div>
        
         <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">
      
<ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl}  undoUrl={undoUrl} editUrl={editUrl} refSetData={setData}  data={data} type={"PO"} preUrlName={"PO"}/>

         <div className="w-full grid  border border-gary mt-4  rounded-lg">
                   <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Supplier Detail</p>

            </div>
          <div className='w-full flex px-2 justify-between'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>YPR</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279] truncate'><p>: {data?.prRrf} </p>  <p className="pl-1.5"> {data?.prDate? (
                      <NuDate
                        value={data?.prDate}
                        format="DD-MMM-YYYY, hh:mm A"
                      />
                    ) : (
                      " "
                    )}</p></p>
                </div>
            <div className='w-full flex px-2 justify-between'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Supplier</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279] text-wrap truncate'>: {data?.vendorName}</p>
                </div>
           </div>
           <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className=" w-full flex flex-wrap overflow-y-scroll h-16">
         

        <div onClick={handlePdfIconClick} className="cursor-pointer m-1 w-auto h-[25px] flex items-center  justify-between  rounded p-[2px] border ">
          <svg
            width="20"
            height="20"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
          <p className=' w-auto  text-[9px]'>{data?.ref}</p>
        </div>
        </div>
        </div>
           <div className="w-full h-auto  flex  border border-gary mt-4 overflow-hidden rounded-lg">
         {/* <CommonComments
            visible={true}
            // closeModal={handleCloseCommentsModal}
            title="Comments"
            mainId={poViewId}
            addurl={'/po-comments/new-comment'}
            geturl={`/po-comments/by-PO/${poViewId}`}
          /> */}
            <CommonComments
                visible={true}
                // closeModal={handleCloseCommentsModal}
                title="Comments"
                mainId={data?.prId}
                addurl={"/pr-comments/new-comment"}
                geturl={`/pr-comments/by-PR/${data?.prId}`}
              />
         </div>
          </div>
         </div>
            
      </div>
      {showPdfComponent && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
  <div className="animate-scale-in relative w-[98%] h-[98%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
    <button
              className="absolute top-2 right-2 text-white bg-red-600"
              onClick={() => setShowPdfComponent(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <PDFViewer pdfFile={viewPDF}/>
      
     
      {/* <div className='border border-solid-black'> */}
      {/* <POPdfOther data={data} /> */}
      {/* <POPdfNu/> */}
      {/* <div className="flex justify-end gap-2 w-[50%] mx-auto mt-4"> */}
                {/* <CancelButton handleClick={closeFilterDialog} /> */}
                {/* <SubmitButton name="Apply" /> */}
              {/* </div> */}
              {/* </div> */}
    </div>
  </div>
)}
     
     {isCommentsModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-lg font-semibold mb-4">GRN Modal</h2>
            
            {/* {loading ? (
              <div>Loading...</div>
            ) : ( */}
              <div>
             
                {grnData && grnData.length > 0 ? (
                  <table className="min-w-full table-auto border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-100 text-sm text-left">
                      <th className="border border-gray-300 p-2">YPR</th>
                        <th className="border border-gray-300 p-2">GRN No.</th>
                        <th className="border border-gray-300 p-2">PO No.</th>
                        <th className="border border-gray-300 p-2">DN No.</th>
                        {/* <th className="border border-gray-300 p-2">Busy Grn</th> */}

                        <th className="border border-gray-300 p-2">Received Date</th>
                        <th className="border border-gray-300 p-2"> Approval Status</th>

                        <th className="border border-gray-300 p-2">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {grnData.map((item, index) => (
                        <tr key={index} className="text-sm">
                          <td className="border border-gray-300 p-2">{item.prRef}</td>
                          <td className="border border-gray-300 p-2">{item.ref}</td>

                          <td className="border border-gray-300 p-2">{item.poRef}</td>

                          <td className="border border-gray-300 p-2">{item.dnRef}</td>
                          {/* <td className="border border-gray-300 p-2">{item.busyGrnNo}</td> */}

                          <td className="border border-gray-300 p-2">{item.receivedDate}</td>
                          <td className="border border-gray-300 p-2">{item.approvalStatus}</td>

                          <td className="border border-gray-300 p-2">{item.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No data available</p>
                )}
                              </div>
            
{/* )} */}
            <div className="flex justify-end mt-4">
              <button
                className="px-5 py-[9px] cursor-pointer text-xs bg-[#E6F2F2] hover:bg-gray-300 rounded-md flex items-center mr-2 text-textColor-main"
                onClick={handleCloseCommentsModal}
              >
                Close
              </button>
              <button
                type="submit"
                className="bg-[#048178] px-5 py-[8px] cursor-pointer outline-none border-none text-white rounded-md text-xs flex items-center"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

  </div>
)
};
export default ViewPo