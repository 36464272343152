import React from 'react'
import SettingsMetaData from '../../../../Components/Hooks/useSettingsMetaData';

function StatusDiary() {
    return (
        <SettingsMetaData propertyName={"MyDiaryStatus"} viewPropertyName={"Diary Status"} />
      );
    
}

export default StatusDiary
