import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dasboard from '../../../../../Components/Icons/Dasboard';
import axiosInstance from '../../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { notification } from 'antd';
import Breadcrumb from '../../../../../Components/Utils/Breadcrumb';

const ExitTeam = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.state);
    let projectId =location.state.projectId
    let teamId = location.state.teamId;
    let memberFilterList = location.state.memberList;
    const [createNew, setCreateNew] = useState({
      // teamId:"",
      memberList: [],
    });
  
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] =useState(false)
    const [memberList, setMemberList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
  
    useEffect(() => {
      setSelectedRows(memberFilterList)
    }, []);
  
  
    async function getMember() {
      await axiosInstance
        .get(`/DropDown/team_member/${projectId}`)
        .then(async (res) => {
          console.log(res?.data?.data);
          // let finalData = []
          // let mapData = res?.data?.data||[]
          // res?.data?.data && mapData.forEach(item=>{
          //   let innerData = {
          //     value:item.userId,
          //     label:item.Name
          //   }
          //   finalData.push(innerData)
          // })
          (await res?.data?.data) && setMemberList(res?.data?.data);
          // filterByReference(res.data.data.userDetails,[]);
           
        })
        .catch((error) => {
          console.log(error);
        });
    }
    useEffect(() => {
      getMember();
    }, []);
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: (value) => {
        value.teamId = teamId;
        value.memberList = selectedRows;
        console.log("Test",value);
        setIsLoading(true)
        axiosInstance
          .put(`/Team/assign/member/${teamId}`, value)
          .then( async (res) => {
            console.log(res);
            if(res.status === 200 || res.data.status == true){
              api.success({
                message: `Team Member Added successfully`,
                description:res?.data?.msg || 'Added In The Team',
                placement: 'bottomRight',
              });
              setIsLoading(false)   // Set loading state to true
              setCreateNew({ memberList: [] });
              // getData();
              // onCancel();
              navigate(-1);
            }else{
              setIsLoading(false)   // Set loading state to true
              api.error({
                message: `Team Member Added failed`,
                description: res?.data?.msg || 'Network error',
                placement: 'bottomRight',
              });
            }
          })
          .catch((err) => {
            api.error({
              message: `Team Member Added failed`,
              description: err?.data?.msg || 'Sever error (or) Network error',
              placement: 'bottomRight',
            });
          });
      },
    });
  
    const selectRow = ( id) => {
      let testUser;
      if (selectedRows && selectedRows.some((Userid) => Userid === id)) {
        testUser = selectedRows.filter((roleId) => roleId !== id);
        setSelectedRows(testUser);
      } else {
          setSelectedRows([...selectedRows, id]);
      }
      // console.log('id',selectedRows);
  
    };
  
    function selectAll(event){
      if(event.target.checked){
        setSelectedRows([]);
        let all =[]
        memberList.forEach((user) => {
          // console.log("yy",user.userId);
          all.push(user.userId);
        })
        setSelectedRows(all);
      }else{
        setSelectedRows([]);
      }
  
    }
  
    return (
      <div className=' px-4 py-2'>
      {contextHolder}
      <div className='w-full mb-2 flex justify-between items-start'>
              <Breadcrumb items={[{title:(
               <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
              )},{title:(<p className=' text-[12px] '>Teams</p>)},{title:(<p className=' text-[12px] '>Edit Members</p>)}]} />
                <BackButton />
              </div>
          <div className=''>
          <form onSubmit={formik.handleSubmit}>
            <div className='w-full lg:w-[85%] mx-auto flex flex-col shadow-xl p-2 bg-white'>
              <div className=' w-full flex justify-end items-center'>
              <p className='text-[12px] text-[#3267ff] float-right pr-4 py-2 '>
              <input
                          type='checkbox'
                          className='px-3 mr-2 py-2 accent-main-blue'
                          // name={user.name}
                          // checked={selectedRows.some((id) => id === i.userId)}
                          onChange={(e) => selectAll(e)}
                        />
                Select All
              </p>
              </div>
              <div className=' overflow-y-scroll h-[68vh]'>
              <table className=' border-collapse table-auto w-full'>
  <thead className='  bg-slate-200 '>
    <tr className=''>
      <th className=' w-[55%] py-2 text-xs  font-semibold text-left pl-10'>Name</th>
      <th className=' w-[25%] py-2 text-xs font-semibold'>Job Role</th>
      <th className=' w-[15%] py-2 text-xs font-semibold'>Action</th>
    </tr>
  </thead>
  <tbody>
  
  {memberList &&
                memberList.map((i, idx) => {
                  console.log("fdfdfdfd",i.imageUrl);
                  return (
                    <tr className='' key={idx} style={{borderBottom:'1px solid #F5F5F5'}}>
                      <td className='py-2  w-[55%]'>
                      <div className='flex items-center gap-3'>
                        <div className='ml-2 w-8 h-8 bg-baseColor-primary capitalize flex justify-center items-center font-medium text-white rounded-full overflow-hidden'>
                         { i.imageUrl !=null? <img src={i.imageUrl} width='50px' alt={`${i.Name}`} />:`${i.Name.charAt(0)}`}
                        </div> 
                          <p className='text-xs text-[#606A79]'>
                            {i.Name}
                            {/* {i.lastName} */}
                          </p>
                        </div>
                      </td>
                      <td className=' w-[25%] text-center'>
                       <p className='text-xs text-[#606A79]'>{i.position}</p>
                      </td>
                      <td className=' w-[15%] text-center'>
                      <input
                          type='checkbox'
                          className='px-3 mr-2 py-2 accent-main-blue'
                          // name={user.name}
                          checked={selectedRows.some((id) => id === i.userId)}
                          onChange={() => selectRow(i.userId)}
                        />
                      </td>
                    </tr>
                  );
                })}
  
  </tbody>
  </table>
              </div>
              {/* <DataTable columns={columns} data={memberList} selectableRows pagination /> */}
              <div className='w-full flex gap-3 justify-end items-center'>
                <CancelButton handleClick={()=>navigate(-1)} />
                <SubmitButton isLoading={isLoading}/>
  
              </div>
            </div>
          </form>
          </div>
        {/* </Modal> */}
      </div>
    );
  };
  

export default ExitTeam