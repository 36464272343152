
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../../Services/axiosCommon';
import More from '../../../../../Components/Icons/More';
import { Link } from 'react-router-dom';
// import EditCondition from './EditCondition';
import { useState } from 'react';
// import ViewCondition from './ViewCondition';
import { GetAccess } from '../../../../../Components/Utils/roles';
import ViewEquipmentItems from './ViewEquipmentItems';
import EditSubItemsEquipment from './EditSubItemsEquipment';


const ViewMore = ({ data, getData,id,estimationId }) => {
  const { confirm } = Modal;
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);


  const [api, contextHolder] = notification.useNotification();
  const handleDelete = async(id,removevalue) =>{
    confirm({
        title: `Do you Want to delete ${removevalue}?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The ${removevalue} will be removed from the Equipment`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/equipment/delete/${id}`).then((res) => {
            if(res.status === 201||res.status === 200) {
                getData();
                api.success({
                  message: `Equipment Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Equipment Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Equipment Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}




  return (
    <>
    {contextHolder}
      <div className='text-[#344767]  relative dropdown inline-block z-1 group'>
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full'>
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne   text-[#344767] text-left  rounded-lg absolute -left-[80px] -top-3 overflow-hidden hidden  z-50 shadow-lg group-hover:block'>
        {/* <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              // onClick={() => setOpen(true)}
            >
              <Link className=' text-black hover:text-black' to={`/item/teamandcondition/view/${id}`} state={{ data: data }} >View</Link>
            </span>
          </li> */}
           <li className='w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              onClick={() => setViewOpen(true)}
            >
              View
            </span>
          </li>
          {GetAccess(true) && <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              onClick={() => setOpen(true)}
            >
              Edit
            </span>
          </li>}
          {
            GetAccess(true) &&
            <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              onClick={
                () => {
                  handleDelete(data.id,data?.item);
                }
              }
            >
              Delete
            </span>
          </li>
          }
        </ul>
      </div>
       <EditSubItemsEquipment
            data={data}
open={open} onCancel={() => { setOpen(false) }} getData={getData}
estimationId={estimationId}
id={id}
      />
      <Modal
        open={viewOpen}
        onCancel={()=>setViewOpen(false)}
        title={"View BOQ Item"}
        width="50%"
        centered
           className="my-modal"
     
        footer={[]}
      >
        <ViewEquipmentItems data={data} open={viewOpen} onCancel={() => setViewOpen(false)} />
      </Modal>
    </>
  );
};

export default ViewMore;

