import React, { useEffect, useState } from 'react'
import BidLayout from '../../BidLayout'
import TitleTC from './TilteTC';
import { Modal, notification } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import axiosInstance from '../../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from '@ant-design/icons';
import * as yup from 'yup';
import Pagination from '../../../../../Components/Utils/Pagenation';
import { CancelButton, EditButton, LongButton, SubmitButton } from '../../../../../Components/Utils/Buttons';
import CreateTCTittle from './CreateTCTittle';
import MultiEditTC from './MultiEdit';
import ImportTC from './import';
import { NuInput, NuInputText } from '../../../../../Components/Utils/Input';
import UseGetDataSearchByPro from '../../../../../Components/Hooks/useFetchDataByPRO';

function TCIndex() {
  const { estmationId } = useParams();
  // const [data,setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter]= useState(false);
  const [filterData,setFilterData]= useState({
    item_code: ""
  });
  const [afterFilter,setAfterFilter]= useState({})
  // const [searchQuery, setSearchQuery] = useState("");
  const [totalData, setTotalData] = useState(0);
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();
  const [multiEditOpen, setMultiEditOpen] = useState(false);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedExpandedItems, setSelectedExpandedItems] = useState([]);
  const [flatSelectedItemIds, setFlatSelectedItemIds] = useState([]);

  const handleMainCheckboxChange = (titleId, items) => {
    setSelectedTitles((prevSelectedTitles) =>
      prevSelectedTitles.includes(titleId)
        ? prevSelectedTitles.filter((id) => id !== titleId)
        : [...prevSelectedTitles, titleId]
    );

    setSelectedExpandedItems((prevSelectedExpandedItems) => ({
      ...prevSelectedExpandedItems,
      [titleId]: selectedTitles.includes(titleId)
        ? [] // Clear items if main checkbox is deselected
        : items.map(item => item.id), // Select all items if main checkbox is selected
    }));
  };

  const handleExpandedCheckboxChange = (titleId, itemId) => {
    setSelectedExpandedItems((prevSelectedExpandedItems) => ({
      ...prevSelectedExpandedItems,
      [titleId]: prevSelectedExpandedItems[titleId]?.includes(itemId)
        ? prevSelectedExpandedItems[titleId].filter((id) => id !== itemId)
        : [...(prevSelectedExpandedItems[titleId] || []), itemId],
    }));
  };

  useEffect(() => {
    const allSelectedIds = Object.values(selectedExpandedItems).flat();
    setFlatSelectedItemIds(allSelectedIds);
  }, [selectedExpandedItems]);

  const isTitleSelected = (titleId) => selectedTitles.includes(titleId);

  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery

 const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
 

 const [showFilterDialog, setShowFilterDialog] = useState(false);
 const [filter, setFilter] = useState(false);

  const [filterProps, setFilterProps] = useState({
  
    item_code:"",
    
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",

    item_code:"",
     });

  const clearFilter = ()=> {
    setParams({...params, 
      item_code:"",
     })
   
    formik.values.item_code=""   
      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      setParams({...params, item_code:value.item_code})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        item_code:"",
   
      });
    }
  })

  const { data, totalCount, getData,dataLoading } = UseGetDataSearchByPro( `/estimation/TC-IT/${estmationId}`,params);

 useEffect(()=>{
     setParams({...params,page:page,per_page:pageCount})
    },[page,pageCount])
 
    const closeFilterDialog = () => {
     setShowFilterDialog(false);
   };


  // function getTC() {
  //   axiosInstance
  //     .get(`/estimation/TC-IT/${estmationId}?page=${pageIndex}&per_page=${dataCount}`)
  //     .then((res) => {
  //       setData(res.data?.data);
  //       setTotalData(res.data?.total);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  // useEffect(()=>{
  //   getTC();
  //  },[])

  //  function SearchData(e) {
  //   e.preventDefault();
  //   if (searchQuery != "") {
  //     axiosInstance
  //       .get(
  //         `/estimation/TC-IT/${estmationId}?page=${pageIndex}&per_page=${dataCount}`,
  //         { params: { search: `${searchQuery}` } }
  //       )
  //       .then((res) => {
  //         setData(res.data?.data);
  //         setTotalData(res.data?.total);
  //       })
  //       .catch((err) => console.log(err));
  //   } else {
  //     getTC();
  //   }
  // }

  //  const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: filterData,
  //   validateOnChange: false,
  //   validateOnBlur: false,
  //   validationSchema: yup.object({
      
  //   }),
  //   onSubmit: async (value, { resetForm }) => {
  //     setAfterFilter(value)
  //     setIsFilter(true)
  //     axiosInstance
  //     .get(`/estimation/TC-IT/${estmationId}?page=${pageIndex}&per_page=${dataCount}`,{params:{item_code:`${value.item_code}`}})
  //     .then((res) => {
  //       setFilterOpen(false)
  //       setData(res.data?.data);
  //       setTotalData(res.data?.total)

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   },
  // });

  // function FilterPage(){
  //   axiosInstance
  //     .get(`/estimation/TC-IT/${estmationId}?page=${pageIndex}&per_page=${dataCount}`,{params:{item_code:`${afterFilter.item_code}`}})
  //     .then((res) => {
  //       setData(res.data?.data);
  //       setTotalData(res.data?.total)

  //     }).catch(err=>console.log(err))
  // }


  // function ClearFilter(){
  //   formik.resetForm({})
  //   getTC();
  //   setIsFilter(false)
  // }

  // useEffect(()=>{
  //  if(isFilter){
  //   FilterPage();
  //  }else if(searchQuery !=""){
  //     SearchData();
  //   }else{
  //   getTC()
  //   }
  // },[pageIndex,dataCount])

  // useEffect(() => {
  //   if (searchQuery == "") {
  //      getTC();
  //   }
  // }, [searchQuery]);

  const handleDelete = async(id) =>{
    confirm({
        title: `Do you Want to delete T&C Items?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The T&C Items will be removed from the T&C`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/estimation/multi-TC/delete`, {
            headers: {
              'Content-Type': 'application/json',
            },
            data: { "ids": id } 
          }).then((res) => {
            if(res.status === 201||res.status === 200) {
                getData();
                api.success({
                  message: `T&C Multi Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `T&C Multi Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `T&C Multi Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}

const [valueOfSearch,setValuesofSearch] = useState("")
   function searchFunction(e) {
 
     
     setValuesofSearch(e?.target?.value)
     setParams({ ...params, search: e?.target?.value, page:1 });
     setPage(1)
   }

  return (
    <>
    {contextHolder}
    <BidLayout
        setOpen={setOpen}
        onCreateClick={() => {
          setCreateModalOpen(true);
        }}
        isFilter={filter}
        setFilterOpen={setShowFilterDialog}
        ClearFilter={clearFilter}
        searchFun={searchFunction}
     searchValue={valueOfSearch}
        searchQuery={searchQuery} 
        // newfilter={true}
        neededSearch={true}
        CreateAccess= {true}
        ImportAccess= {true}
    >
      <div className="w-full h-[calc(100vh-140px)] p-3 overflow-hidden">
          <div className=" w-full h-[calc(100vh-200px)] overflow-y-scroll">
               {/* <div className='flex w-full justify-between'> */}
          {/* <p className="font-medium text-xs 2xl:text-sm text-[#4F4768]">
          T & C
        </p> */}
          {flatSelectedItemIds.length > 0 ? 
            <div className=" flex justify-end gap-2 ">
            <EditButton  height='5' value="Multi Edit" handleClick={() => { setMultiEditOpen(true) }}/>
            <button
              type='button'
              className="px-2 h-5 cursor-pointer text-xs text-red-500 bg-white rounded-md flex items-center"
              style={{ border: "1px solid red" }}
              onClick={() => {handleDelete(flatSelectedItemIds)}}
            >
              Multi Delete
            </button>
          </div>
            :
            null
            }
            {/* </div> */}
            <table
              className="table-auto static top-0 w-full"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className=" w-full ">
                <tr className="text-xs 2xl:text-sm text-[#4F4768]  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} px-1 font-medium ${
                        header?.isLeft ? "text-left" : "text-center"
                      } text-xs 2xl:text-sm`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2]  mx-auto"></div>

            <div className={` w-full pt-0.5 ${flatSelectedItemIds.length > 0 ? "h-[calc(100vh-250px)]" : "h-[calc(100vh-240px)]"}  overflow-y-auto relative`}>
              <table
                className="table-auto  w-full"
                style={{ border: "none", borderCollapse: "collapse" }}
              >
                <tbody>
                  {data &&
                    data.map((item, i) => {
                      return (
                        <TitleTC
                          data={item}
                          getData={getData}
                          estiId={estmationId}
                          selectedTitles={selectedTitles}
                          selectedExpandedItems={selectedExpandedItems}
                          onMainCheckboxChange={handleMainCheckboxChange}
                          onExpandedCheckboxChange={
                            handleExpandedCheckboxChange
                          }
                          isTitleSelected={isTitleSelected}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" h-7 my-[2px] px-3">
            <Pagination
              total={totalData}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              dataCount={dataCount}
              setDataCount={setDataCount}
            />
          </div>
        </div>
        <CreateTCTittle
          open={createModalOpen}
          onCancel={() => {
            setCreateModalOpen(false);
          }}
          getData={getData}
        />
        <MultiEditTC
          open={multiEditOpen}
          onCancel={() => {
            setMultiEditOpen(false);
          }}
          getData={getData}
          seletedIds={flatSelectedItemIds}
        />
    </BidLayout>
    <ImportTC
        open={open}
        onCancel={() => setOpen(false)}
        getData={getData}
        label="Import"
        estmationId={estmationId}
      />
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Equipment</h2>
                 <form onSubmit={formik.handleSubmit}> 
                <div className=" w-full flex flex-wrap">
                       <NuInput
             type="text"
              label="Item Code"
              placeholder="Item Code"
              formik={formik}
              name="item_code"
              width="w-full"
            />
            </div>
                              <div className="flex justify-end gap-2">
                                                  <CancelButton handleClick={closeFilterDialog} />
                                                  <SubmitButton name='Apply' />
                                                </div>
                                               </form> 
                                            </div>
                                          </div>
                                        )}
    </>
  )
}

export default TCIndex


const header = [
  // {
  //   headone: "ID",
  //   width: "w-[3%]",
  //   // isLeft: true,
  // },
  {
      headone: "Sl.No",
      width: "w-[5%]",
      isLeft: true,
    },
    {
      headone: "Item Code",
      width: "w-[10%]",
      isLeft: true,
    },
  {
    headone: "Description",
    width: "w-[80%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];