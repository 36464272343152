import React from "react";

function Bidding({ color = "#999999", width = 14, height = 14 }) {
  return (
    <svg
      fill={color}
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M478.148,183.586l-18.099-18.099c-10.001-10.001-26.206-10.001-36.207,0l-90.513-90.505 c10.001-10.001,10.001-26.206,0-36.207L315.23,20.676c-10.001-10.001-26.206-10.001-36.207,0l-90.505,90.505 c-10.001,10.001-10.001,26.206,0,36.207l18.099,18.099c10.001,10.001,26.206,10.001,36.207,0l18.099,18.099L7.501,437.018 c-10.001,10.001-10.001,26.206,0,36.207L25.6,491.324c10.001,10.001,26.206,10.001,36.207,0l253.431-253.423L333.329,256 c-10.001,10.001-10.001,26.206,0,36.207l18.099,18.099c10.001,10.001,26.206,10.001,36.207,0l90.513-90.513 C488.149,209.792,488.149,193.587,478.148,183.586z M260.924,147.388l-18.099-18.099l-18.099,18.099l-18.099-18.099l90.505-90.513 l18.099,18.099l-18.099,18.108l18.099,18.099L260.924,147.388z M43.699,473.225L25.6,455.125l253.423-253.432l18.099,18.099 L43.699,473.225z M333.329,219.793l-54.306-54.306l54.306-54.306l54.306,54.306L333.329,219.793z M369.536,292.207l-18.099-18.099 L369.536,256l-18.099-18.099l54.306-54.306l18.099,18.099l18.099-18.099l18.099,18.099L369.536,292.207z" />{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M499.2,473.225h-12.8v-51.2c0-14.14-11.46-25.6-25.6-25.6H281.6c-14.14,0-25.6,11.46-25.6,25.6v51.2h-12.8 c-7.074,0-12.8,5.726-12.8,12.8c0,7.074,5.726,12.8,12.8,12.8h38.4h179.2h38.4c7.074,0,12.8-5.726,12.8-12.8 C512,478.95,506.274,473.225,499.2,473.225z M460.8,473.225H281.6v-51.2h179.2V473.225z" />{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}

export default Bidding;
