import { useEffect, useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import { useFormik } from "formik";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import Dasboard from "../../../../Components/Icons/Dasboard";
import { Link } from "react-router-dom";
import { CreateButton } from "../../../../Components/Utils/Buttons";
import { Button, Modal } from "antd";
import { NuSelect } from "../../../../Components/Utils/Input";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";



function MembersReport() {
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter] = useState(false)
  const [xlsData,setXlsData] = useState([])
  const [data,setData] = useState([])
  const [filterData,setFilterData]= useState({
    job_role: "",
    assigned_project: ""
  });
  const [projectlist,setProjectList] = useState([])


  function getAllData(){
    axiosInstance.get(`/report/member`).then(res=>{
      console.log("ssss",res?.data?.data);
      let forMap = res?.data?.data;
      let tempArr = []
      forMap && forMap.forEach(item=>{
      let tempData = {
        "Name":item.name||"",
        "Job Role":item?.job_role||"",
        "Mobile":item?.mobile||"",
        "Email":item?.email||"",
      }
      tempArr.push(tempData)
      })
      setXlsData(tempArr);
      res.data?.data && setData(res?.data?.data)
    }).catch(err=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    getAllData()
    getProject()
  },[])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (value, { resetForm }) => {
    
      setIsFilter(true)
      axiosInstance.get(`/report/member?job_role=${value.job_role}&assigned_project=${value.assigned_project}`).then(res=>{
        setFilterOpen(false)
        let forMap = res?.data?.data;
        let tempArr = []
        forMap && forMap.forEach(item=>{
        let tempData = {
          "Name":item.name||"",
          "Job Role":item?.job_role||"",
          "Mobile":item?.mobile||"",
          "Email":item?.email||"",
        }
        tempArr.push(tempData)
        })
        setXlsData(tempArr);
        res.data?.data && setData(res?.data?.data)
      }).catch(err=>{
        console.log(err);
        setFilterOpen(false)

      })
  }  
});



  const {value} = useFetchMataData(`/Metadata/clientPosition`)
  
  function ClearFilter(){
    formik.resetForm({})
    // getTask(firstProject);
    getAllData()
    setIsFilter(false)
  }


  async function getProject() {
    await axiosInstance
      .get(`/Team/getUserProjectId`)
      .then(async (res) => {
        let list = res?.data?.data || [];
        let finalData = [];
        await res?.data?.data && list.map((item, idx) => {
          finalData.push({
            value: item.ProjectId,
            label: item.ProjectName,
          });

        })
        setProjectList(finalData);

        // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
        // console.log('====',res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  return (
    <div className=" w-full h-[90vh] px-4 py-2 overflow-hidden">
      <div className="md:flex justify-between w-full">
      <Breadcrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<Link to='/reports'><p className=' text-[12px] '>Reports Lists</p></Link>)},{title:(<p className=' text-[12px] py-[1px]'>Members</p>)}]} />
      <div className='  flex justify-center items-center float-right'>
      <div className=" pb-2 w-full flex justify-center gap-2 items-center ">
      <div className=" flex justify-center items-center gap-3 md:mr-6">
      <CreateButton label="Filter" handleClick={()=>setFilterOpen(true)}   />
      {isFilter&& <CreateButton label='Clear Filter' handleClick={ClearFilter} />}
      {/* <ExportXls csvData={xlsData} fileName="Members Report"  />
      <CreateButton label="PDF" handleClick={()=>{}}   />
      <BackButton/> */}
      </div>
      </div>
      </div>
      </div>

      <div className=" w-full h-[98%]   overflow-y-auto">
      <table className="table-auto w-full mb-5" style={{ border: 'none', borderCollapse: 'collapse' }}>
      <thead className=" w-full bg-[#2A475E]">
      <tr className="text-xs  font-medium   py-2 ">
      {header.map((header, i) => (
                <th className={`py-2 ${header.width} font-normal ${header?.isLeft?"text-left":"text-center"} text-xs px-2 text-white`} key={i} style={{ border: 'none', borderCollapse: 'collapse' }}>
                  {header.headone}
                </th>
              ))}
      </tr>
      </thead>
      <tbody>
        {
          data && data.map((item,idx)=>{
            return(
              <tr className='`w-[100%] bg-[#F6F9FF] text-xs' key={idx}>
              <td className=' pl-2 py-2'>{idx+1}</td>
              <td className=' pl-2 py-2 '>{item.name||""}</td>
              <td className=' pl-2 py-2 '>{item?.job_role||""}</td>
              <td className=' pl-2 py-2 '>{item?.assigned_project && item?.assigned_project.length>0 && item?.assigned_project.map((project,indx)=>{
                return(
                  <div key={idx}>{project}</div>
                )
              }) }</td>
              <td className=' pl-2 py-2 '>{item?.mobile||""}</td>
              <td className=' pl-2 py-2 '>{item?.email||""}</td>
    
            </tr>
            )
          })
        }
      </tbody>
      </table>
      </div>
      <Modal
        open={filterOpen}
        title={"Filter Members"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={()=>setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuSelect

              label="Project"
              options={projectlist}
              formik={formik}
              placeholder="Choose"
              name="assigned_project"
              width=" w-full"
            />
            <NuSelect
              label="Job Role"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="job_role"
              width=" w-full"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default MembersReport;


const header = [
  {
    headone: "Sl.no",
    width: "w-[3%] ",
    // isLeft: true,
  },
  {
    headone: "Name",
    width: "w-[32%] ",
    isLeft: true,
  },
  {
    headone: "Job Role",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Assigned Project",
    width: "w-[20%]",
    isLeft: true,

  },
  {
    headone: "Mobile",
    width: "w-[15%]",
    isLeft: true,

  },
  {
    headone: "Email",
    width: "w-[20%]",
    isLeft: true,

  },
];