import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import CategoryManPower from './Category'
import DesignationManPower from './Designation'
import TypeManPower from './Type'

function ManPoweBudgetindex() {
    return(
        <>
        <TextMediumSM content="Budget" />
        <TextMediumSM content="Man Power" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CategoryManPower/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DesignationManPower/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <TypeManPower/>
         </div>
         </div>
         </>
    )
}

export default ManPoweBudgetindex
