import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import MainProject from '../Views/Web/Project/projectMain'
import AllProject from '../Views/Web/Project'
import CreateProject from '../Views/Web/Project/createProject'
import EditProject from '../Views/Web/Project/editProject'
import ViewProject from '../Views/Web/Project/viewProject'


const ProjectRoutes = () => {
  return (
    <Route path="projects" element={<MainProject />}>
    <Route index element={<AllProject />} />
    <Route path="create" element={<CreateProject />} />
    <Route path="edit/:projectEditId" element={<EditProject />} />
    <Route path="view/:projId" element={<ViewProject />} />
  </Route>
  )
}

export default ProjectRoutes