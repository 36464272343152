import React from 'react'
import PurchaseRequestStatus from './PurchaseRequestStatus'
import { BackButton } from '../../../../Components/Utils/Buttons'
import PurchaseOrderStatus from './PurchaseOrderStatus'
import PaymentRequestStatus from './PaymentRequestStatus'
import CustomerQuotesStatus from './CustomerQuotesStatus'
import GrnStatus from './GrnStatus'
import DeliveryNotesStatus from './DeliveryNotesStatus'
import InvoiceIncomingStatus from './InvoiceIncomingStatus'
import InvoiceOutgoingStatus from './InvoiceOutgoingStatus'
import SupplierStatus from './SupplierStatus'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'

function ApprovalStatusMain() {
  return(
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <Breadcrumb
            items={[
              {
                name: "Settings",
              },
              {
                name: "Approval Permission"
              }
            ]}
          />
      <BackButton/>
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
      <div className="p-3">
        <PurchaseRequestStatus/>
      </div>
      <div className="p-3">
        <PurchaseOrderStatus/>
      </div>
      <div className="p-3">
        <PaymentRequestStatus/>
      </div>
      <div className="p-3">
        <CustomerQuotesStatus/>
      </div>
      <div className="p-3">
      <GrnStatus/>
        
      </div>
      <div className="p-3">
      <DeliveryNotesStatus/>

      </div>
      <div className="p-3">
        <InvoiceIncomingStatus/>
      </div>
      <div className="p-3">
        <InvoiceOutgoingStatus/>
      </div>
      <div className="p-3">
        <SupplierStatus/>
      </div>
      {/* <div className="p-3">
        <SupplierQuotesPermission/>
      </div>
      <div className="p-3">
        <CustomerOrderPermmision/>

      </div> */}
      
      
      </div>
      </div>
  )
}

export default ApprovalStatusMain