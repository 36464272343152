import React from 'react'
import NuDate from '../../../../Components/Utils/NuDate';
import NuAmount from '../../../../Components/Utils/NuAmount';

function BudgetVSActual({data=[]}) {
  return (
    <div className=" w-full h-full p-2 rounded-lg bg-white" style={{ border: "1px solid #E9E8EC" }}>
    <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px] ">Budget Vs Actual</p>
          <div className=" w-full overflow-y-scroll h-[calc(45vh-40px)] ">
             <table className="overflow-hidden text-center  text-xs border-collapse w-full overflow-y-scroll">
               <thead className="border-b  rounded-md overflow-hidden font-medium  text-xs">
                 <tr
                  className=""
                  style={{
                    borderTop: "1px solid #E9E8EC ",
                    borderBottom: "1px solid #E9E8EC ",
                  }}
                >
                  <th
                    scope="col"
                    className="sticky py-2 w-[40%] font-semibold text-[#6C6580] text-xs text-start pl-2 "
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="sticky py-2 w-[20%] text-left font-semibold text-[#6C6580] text-xs"
                  >
                    Budget
                  </th>
                  <th
                    scope="col"
                    className="sticky py-2 w-[20%] text-left font-semibold text-[#6C6580] text-xs"
                  >
                    Actual
                  </th>
                  <th
                    scope="col"
                    className="sticky py-2 w-[20%] text-left   font-semibold text-[#6C6580] text-xs"
                  >
                    Difference
                  </th>
                </tr>
              </thead>
              <tbody className=" px-2 ">
                {data &&
                  data.map((Item, Index) => {
                    return (
                      <>
                      <tr
                        key={Index}
                        className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-10"
                        style={{ borderBottom: "1px solid #E9E8EC " }}
                      >
                        <td className="pl-2 text-left text-[#4F4768]  w-[40%] h-full  text-xs">
                          <div className=" w-full h-full items-center line-clamp-2">
                          {"Man Power"}
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768]  w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center line-clamp-2">
                          <NuAmount value={Item?.manpowerBudget || 0}/>
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768]  w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center line-clamp-2">
                         
                          <NuAmount value={Item?.manpowerActual || 0}/>
                          </div>
                        </td>
                        <td className="pl-2 text-left  text-[#4F4768]  w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center line-clamp-2">
                          <NuAmount value={Item?.manpowerDiff || 0}/>

                          </div>
                        </td>
                      </tr>
                      <tr
                        key={Index}
                        className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-10"
                        style={{ borderBottom: "1px solid #E9E8EC " }}
                      >
                        <td className="pl-2 text-left text-[#4F4768] l w-[40%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          {"Material"}
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          <NuAmount value={Item?.materialBudget || 0}/>
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          <NuAmount value={Item?.materialActual || 0}/>
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          <NuAmount value={Item?.materialDiff || 0}/>
                          </div>
                        </td>
                      </tr>
                      <tr
                        key={Index}
                        className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-10"
                        style={{ borderBottom: "1px solid #E9E8EC " }}
                      >
                        <td className="pl-2 text-left text-[#4F4768] l w-[40%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          {"Other"}
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          <NuAmount value={Item?.otherBudget || 0}/>
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          <NuAmount value={Item?.otherActual || 0}/>
                          </div>
                        </td>
                        <td className="pl-2 text-left text-[#4F4768] l w-[20%] h-full  text-xs">
                          <div className=" w-full h-full items-center ine-clamp-2">
                          <NuAmount value={Item?.otherDiff || 0}/>

                          </div>
                        </td>
                      </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
  </div>
  )
}

export default BudgetVSActual