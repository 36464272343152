import React from 'react'
import { Route } from 'react-router-dom';
import InvoiceProjectIncomingIndex from '../Views/Web/Project/SubMenu/Sales/InvoicesIncoming/Index';
import MainInvoicesProjectIncoming from '../Views/Web/Project/SubMenu/Sales/InvoicesIncoming/MainInvoicesIncoming';
import CreateInvoiceProjectIncoming from '../Views/Web/Project/SubMenu/Sales/InvoicesIncoming/CreateInvoiceIncoming';
import EditInvoiceProjectIncoming from '../Views/Web/Project/SubMenu/Sales/InvoicesIncoming/EditInvoiceIncoming ';
import ViewInvoiceProjectIncoming from '../Views/Web/Project/SubMenu/Sales/InvoicesIncoming/ViewInvoiceIncoming ';
import EditInvoiceProjectOutgoing from '../Views/Web/Project/SubMenu/Sales/InvoicesOutgoing/EditInvoiceOutgoing ';
import CreateInvoiceProjectOutgoing from '../Views/Web/Project/SubMenu/Sales/InvoicesOutgoing/CreateInvoiceOutgoing';
import InvoiceOutgoingProjectIndex from '../Views/Web/Project/SubMenu/Sales/InvoicesOutgoing/Index';
import MainInvoicesProjectOutgoing from '../Views/Web/Project/SubMenu/Sales/InvoicesOutgoing/MainInvoicesOutgoing';
import ViewInvoiceProjectOutgoing from '../Views/Web/Project/SubMenu/Sales/InvoicesOutgoing/ViewInvoiceOutgoing ';
import MainSalesProject from '../Views/Web/Project/SubMenu/Sales/MainSales';

function ProjectSalesroutes() {
  return (
    <Route path="sales" component={<MainSalesProject/>}>
    {/* <Route path="customer/:officeId/:projectId" element={<MainCustomersProject />}>
      <Route index element={<CustomersProjectIndex />} />
      <Route path="create" element={<CreateCustomersProject />} />
      <Route path="edit/:projectCustomerEditId" element={<EditCustomersProject />} />
      <Route path="view/:projectCustomerViewId" element={<ViewCustomersProject />} />
    </Route>

    <Route path="quote/:officeId/:projectId" element={<CustomerQuotationProjectMain />}>
      <Route index element={<CustomerQuotationProjectIndex />} />
      <Route path="create" element={<CreateCustomerQuotesProject />} />
      <Route path="edit/:rfqEditId" element={<EditCustomerQuotesProject />} />
      <Route path="view/:rfqViewId" element={<ViewCustomerQuotesProject />} />
    </Route> */}

     {/* <Route path="quote-comparison/:officeId/:projectId" element={<MainQuoteComparision />}>
      <Route index element={<MainQuoteComparision />} />
      {/* <Route path="create" element={<CreateMaterial />} /> */}
      {/* <Route path="edit/:materialEditId" element={<EditMaterial />} /> */}
      {/* <Route path="view/:quote-comparisonViewId" element={<ViewQuotesByPr />} /> */}
    {/* </Route>  */}
    
   {/* <Route path="sales-order/:officeId/:projectId" element={<MainSalesProjectOrders />}>
      <Route index element={<SalesProjectOrdersIndex />} />
      <Route path="create" element={<CreateSalesProjectOrders />} />
      <Route path="edit/:EditIdpo" element={<EditSalesProjectOrders />} />
      <Route path="view/:ViewIdpo" element={<ViewSalesProjectOrders />} />
    </Route> */}

    <Route path="invoice_incoming/:officeId/:projectId" element={<MainInvoicesProjectIncoming />}>
      <Route index element={<InvoiceProjectIncomingIndex />} />
      <Route path="create" element={<CreateInvoiceProjectIncoming />} />
      <Route path="edit/:paymentReqEditId" element={<EditInvoiceProjectIncoming />} />
      <Route path="view/:paymentReqViewId" element={<ViewInvoiceProjectIncoming />} />
    </Route>
    <Route path="invoice_outgoing/:officeId/:projectId" element={<MainInvoicesProjectOutgoing />}>
      <Route index element={<InvoiceOutgoingProjectIndex />} />
      <Route path="create" element={<CreateInvoiceProjectOutgoing />} />
      <Route path="edit/:paymentReqEditId" element={<EditInvoiceProjectOutgoing />} />
      <Route path="view/:paymentReqViewId" element={<ViewInvoiceProjectOutgoing />} />
    </Route>
  </Route>
  )
}

export default ProjectSalesroutes;