import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { GetAccess } from "../../../Components/Utils/roles"
import Breadcrumb from "../../../Components/Utils/Breadcrumb"
import SearchBar from "../../../Components/Utils/SearchBar"
import { ClearFilterButton, CreateButton, FilterButton, ImportButton } from "../../../Components/Utils/Buttons"
import { useNumber } from "../../../Context/ProjectIdContext"
import axiosInstance from "../../../Services/axiosCommon"
import { Select } from "antd"

const SalesLayout = (
    {
        getData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        onCreateClick,
        searchFun=()=>{},
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        searchValue="",
    }
) =>{
    let {pathname} = useLocation()
    let {officeId} = useParams()
    
    const [module,setModule] = useState("")
    const [projectList,setProjectList]= useState([])
    
    useEffect(() =>{
        // if(pathname == `/main/${officeId}/sales/quotes`){
        //     setModule("Quote")
        // }else if(pathname == `/main/${officeId}/sales/sales-orders`){
        //     setModule("Sales Order")
        // }else
         if(pathname == `/main/${officeId}/sales/invoice_incoming`){
            setModule("Incoming")
        }else if(pathname == `/main/${officeId}/sales/invoice_outgoing`){
            setModule("Outgoing")
        }
    },[pathname])

    let LinkItem = [
        // {
        //     label:"Quote",
        //     link:`/main/${officeId}/sales/quotes`,
        //     access:GetAccess(),
        // },
        // {
        //     label:"Sales Order",
        //     link:`/main/${officeId}/sales/sales-orders`,
        //     access:true,
        // },
        {
            label:" Incoming",
            link:`/main/${officeId}/sales/invoice_incoming`,
            access:GetAccess(),
        },
       
        {
            label:" Outgoing",
            link:`/main/${officeId}/sales/invoice_outgoing`,
            access:true,
        },
       
       
          ]

          const {number, updateNumber} = useNumber()

          function getProjectByOffice(id){
              axiosInstance.get(`/DropDown/${id}/getAllProjects`).then(res=>{
                  let tempValue = res?.data?.data||[];
                  let arr = []
                  tempValue.map(i=>{
                      let val = {
                          value:i?.projId||"",
                          label:i?.projName||""
                      }
                      arr.push(val)
                  })
                  if(number ==0){
                      updateNumber(arr[0]?.value)
                  }
                  setProjectList(arr)
              }).catch((error) => {
                  console.error("Error:", error);
                    
                });
          }
      
          useEffect(()=>{
              if(number==0||pathname !="") getProjectByOffice(officeId)
          },[pathname])
      
          function handleProjectChange(e){
              console.log(e);
              updateNumber(e)
          }
    
    return(
        <div className=" w-full  h-full pt-2 px-4 overflow-hidden">
            <div className="">
            <div className="md:flex justify-between w-full ">
            {/* <Breadcrumb items={[
               
                {
                    name:"Sales"
                },
                { name: `${module}` },

            ]} /> */}
            <div className=" w-full  flex items-center gap-x-1 pt-2">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                                    <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                                     )
                         }
                        </>
                    )
                })
            }
        </div>

            <div className=" flex justify-end items-center gap-3 ">
            <div>
               
                 <SearchBar handleChange={searchFun}  value={searchValue}/>
                </div>
            <form className=" w-48 flex justify-end items-center xl:gap-3 mx-0" onSubmit={getData}>
          <Select
            style={{ width: '100%' }}
            options={projectList}
            value={number}
            disabled={projectList.length >= 2 ? false : true}
            // allowClear={formik.values[name]?true:false}
            // name={name}
            // placeholder={placeholder}
            onChange={handleProjectChange}
          />
            </form>
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <ClearFilterButton label='Clear Filter'  handleClick={ClearFilter} />} 
               {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}

              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
            
           
            <div className=" w-full h-[calc(100vh-140px)]  ">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default SalesLayout;