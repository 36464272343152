import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../Services/axiosCommon";
import { Modal, notification } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import More from "../../../../Components/Icons/More";
import { GetAccess } from "../../../../Components/Utils/roles";

// import CreateClients from './createClient';
// import ClientView from './clientView';

const ViewMore = ({ data, getClients, id }) => {
  const {officeId} = useParams()
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  const handleDelete = async (id, removevalue) => {
    confirm({
      title: `Do you Want to delete ${removevalue}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `The ${removevalue} will be removed from the Sub-Contracts List`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        axiosInstance
          .delete(`/Vendor/${id}`)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              getClients();
              api.success({
                message: `Client has deleted successfully`,
                description: response.data.msg || " ",
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Client has delete failed`,
                description: response.data.msg || "Network error",
                placement: "bottomRight",
              });
            }
          })
          .catch((error) => {
            api.error({
              message: `Client has delete failed`,
              description: error.data.msg || "Sever error (or) Network error",
              placement: "bottomRight",
            });
          });
      },
      onCancel() {},
    });
  };


  return (
    <>
      {contextHolder}
      <div className="text-[#344767]  relative dropdown inline-block z-50">
        <div className=" cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center">
          <span className="pt-1">
            {/* <svg
              width="6"
              height="18"
              viewBox="0 0 4 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.6875 7.72339C0.859072 7.72339 0.1875 7.05182 0.1875 6.22339C0.1875 5.39496 0.859072 4.72339 1.6875 4.72339C2.51593 4.72339 3.1875 5.39496 3.1875 6.22339C3.1875 7.05182 2.51593 7.72339 1.6875 7.72339Z"
                fill="#BDC1C6"
              />
              <path
                d="M0.1875 1.72339C0.1875 2.55182 0.859073 3.22339 1.6875 3.22339C2.51593 3.22339 3.1875 2.55182 3.1875 1.72339C3.1875 0.894962 2.51593 0.223389 1.6875 0.223389C0.859073 0.223389 0.1875 0.894961 0.1875 1.72339Z"
                fill="#BDC1C6"
              />
              <path
                d="M1.6875 12.2234C0.859072 12.2234 0.1875 11.5518 0.1875 10.7234C0.1875 9.89496 0.859072 9.22339 1.6875 9.22339C2.51593 9.22339 3.1875 9.89496 3.1875 10.7234C3.1875 11.5518 2.51593 12.2234 1.6875 12.2234Z"
                fill="#BDC1C6"
              />
            </svg> */}
            <More/>
          </span>
        </div>
        <ul className="dropdown-menuOne  text-[#344767] text-left  rounded-lg absolute -left-[80px] -top-4 overflow-hidden hidden  z-50 shadow-lg">
          {GetAccess("sub_contractor_edit") && (
            <li className=" w-[100px] bg-black block m-0">

                <Link className="bg-white hover:bg-gray-100 py-2  text-black hover:text-black px-2 block whitespace-no-wrap text-xs font-medium" to={`/main/${officeId}/contacts/sub-con/edit/${id}`} state={{ data: data }}>
                  Edit
                </Link>
            
            </li>
          )}
          {GetAccess("sub_contractor_delete") && (
            <li className="">
              <Link
                className="bg-white hover:bg-gray-100 py-2  text-black hover:text-black px-2 block whitespace-no-wrap text-xs font-medium"
                to="#"
                onClick={() => {
                  handleDelete(data.vendorId, data.companyName);
                }}
              >
                Delete
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ViewMore;
