import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function EquipmentItem() {
    return (
        <SettingsMetaData propertyName={"EquipmentItem"} viewPropertyName={"Item"} />
      );
}

export default EquipmentItem
