import React from "react";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../../Services/axiosCommon";
import Dasboard from "../../../Components/Icons/Dasboard";
import { BackButton } from "../../../Components/Utils/Buttons";
import useFetchMataData from "../../../Components/Hooks/useFetchMataData";
import Dough from "../../../Components/Utils/DoughnutTwo";
import BudgetVSActual from "./Components/BudgetVSActual";
import Activities from "./Components/Activities";
import InvoiceInOut from "./Components/InvoiceInOut";
import ProjectProgress from "./Components/ProjectProgress";
import ProgressByDivision from "./Components/ProgressByDivision";
import Manhour from "./Components/Manhour";
import UpComingActivities from "./Components/UpComingActivities";
import DelayedActivities from "./Components/DelayedActivities";
import ProjectTeam from "./Components/ProjectTeam";
import HeadCount from "./Components/HeadCount";
import Details from "./Components/Details";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";

function ViewProject() {
  const { projectId ,officeId } = useParams();
  const [project, setProject] = useState([]);
  const [teamDetails,setTeamDetils] = useState([]);
  const [data, setData] = useState([]);
  const [totalProject, setTotalProject] = useState([]);
  const [manHour, setManHour] = useState([]);
  const [manPowerCount, setManPowerCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [activity, setActivity] = useState([]);
  const [planvsActual, setPlanvsActual] = useState([]);
  const [budjectvsActual, setBudgetvsActual] = useState([]);
  const [headCount,setHeadCount] = useState([]);
  const [upCommingActivities,setUpCommingActivity] = useState([]);


  const getProjectDetails = async () => {
    await axios
      .get(`/Project/${projectId}`)
      .then(async (res) => {
        // console.log(" project Details", res?.data?.data);
        (await res?.data?.data) && setProject(res?.data?.data);
      })
      .catch((error) => console.error(error));
  };


  const getTeamDetails = async () => {
    await axios
      .get(`/Team/byProjectId/${projectId}`)
      .then(async (res) => {
        // console.log(" Team Details", res?.data?.data);
        (await res?.data?.data?.teamsList) && setTeamDetils(res?.data?.data?.teamsList);
      })
      .catch((error) => console.error(error));
  };


  async function getDashboard() {
    await axios
      .get(`/Dashboard/project/status/count`)
      .then(async (res) => {
        (await res?.data?.totalProjects) &&
          setTotalCount(res?.data?.totalProjects);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getTable() {
    await axios
      .get(`/Dashboard/all_project`)
      .then(async (res) => {
        (await res?.data?.data) && setTotalProject(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllManHour() {
    await axios
      .get(`/Project/man-hour/${projectId}`)
      .then(async (res) => {
        (await res?.data?.data) && setManHour(res?.data?.data);
        // (await res?.data?.userCount) && setManPowerCount(res?.data?.userCount);
        // setTotalRows(res.data?.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllActivities() {
    await axios
      .get(`/Project/activities/${projectId}`)
      .then(async (res) => {
        (await res?.data?.data) && setActivity(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPlanVsActival() {
    axios
      .get(`/Dashboard/actual_plan`)
      .then((res) => {
        res?.data?.projects && setPlanvsActual(res?.data?.projects);
      })
      .catch((err) => console.log(err));
  }

  async function getBudgetVsActival() {
    axios
      .get(`/Project/budget/${projectId}`)
      .then((res) => {
        res?.data?.data && setBudgetvsActual(res?.data?.data);
      })
      .catch((error)=>{
        console.log(error);
      });
  }

  async function getHeadCount() {
    axios
      .get(`/Project/head-count/${projectId}`)
      .then((res) => {
        res?.data?.data && setHeadCount(res?.data?.data);
      })
      .catch((error)=>{
        console.log(error);
      });
  }

  async function getUpcommingActivity() {
    axios
      .get(`/Project/upcomming-activities/${projectId}`)
      .then((res) => {
        res?.data?.data && setUpCommingActivity(res?.data?.data);
      })
      .catch((error)=>{
        console.log(error);
      });
  }

  async function getUpcommingActivity() {
    axios
      .get(`/Project/upcomming-activities/${projectId}`)
      .then((res) => {
        res?.data?.data && setUpCommingActivity(res?.data?.data);
      })
      .catch((error)=>{
        console.log(error);
      });
  }

  const [incommingData,setIncommingData] = useState([])
  const [outgoingData,setOutgoingData] = useState([])

  async function getInoviceInOut() {
    axios
      .get(`/Project/invoice/${projectId}`)
      .then((res) => {
        console.log(res?.data?.data);
        let Tempdata = res?.data?.data ||[]
        let IncomingData = []
        let OutgoingData = []
        Tempdata && Tempdata.map((item,indx) => {
          
          Object.keys(item).forEach((key) => {
          
            IncomingData.push(item[key]?.incomming)
            OutgoingData.push(item[key]?.outgoing)
            
          })
        })
        setIncommingData(IncomingData);
        setOutgoingData(OutgoingData);
        
      })
      .catch((error)=>{
        console.log(error);
      });
  }

  console.log("head",manHour);

  useEffect(() => {
    getDashboard();
    getTable();
    getAllManHour();
    getAllActivities();
    getPlanVsActival();
    getBudgetVsActival();
    getHeadCount();
    getUpcommingActivity();


    getProjectDetails();
    getTeamDetails();
    getInoviceInOut();
  }, []);

  function getStatus(data) {
    let returnValue = {
      label: [],
      data: [],
    };
    let X = [];
    let Y = [];
    data &&
      data.forEach((item) => {
        X.push(item.statusName);
        Y.push(item.statusCount);
      });

    returnValue.label = X;
    returnValue.data = Y;
    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    // return sum;
    if (sum != 0) {
      return (
        <div className=" cursor-pointer">
          <Dough dataWithLabel={returnValue} />
        </div>
      );
    } else {
      return <Dough />;
    }
  }

  const statusOrder = [
    "Yet to start",
    "On hold",
    "In progress",
    "Cancelled",
    "Completed",
  ];

  // const { value } = useFetchMataData(`/Metadata/projectstatus`);
  const { value: projectStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "projectStatus"
  );

  projectStatus.sort(function (a, b) {
    let statusA = Object.values(a)[0];
    let statusB = Object.values(b)[0];

    return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
  });

  const item = {
    projStatus: "yet-to-start",
  };

  const Budget = {
    project_name: "data",
  };




  

  return (
    <div className="px-4 py-2 w-full h-full overflow-hidden">
      <div className=" md:flex justify-between w-full">
        <Breadcrumb
          items={[
           
            {
              name: "Project",
              link:`/main/${officeId}/projects`

            },
            { name: "View" },
          ]}
        />
        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div className=" w-full h-[calc(100vh-105px)] mt-[5px] overflow-y-auto pb-4">
        
<div className="grid grid-cols-1 xl:grid-cols-6 xl:grid-rows-6 gap-4">
    <div className="xl:col-span-3">
    <Details 
           projName={project?.projName || "-"} 
           getProjectManager={project?.getProjectManager||[]} 
           startDate={project?.startDate||""}
           endDate={project?.endDate||""}
           taskPercentage={project?.taskPercentage||0}
           projectCompletions={project?.projectCompletions||[]}
           projectBudget={project?.projectBudget||[]}
           projStatus={project?.projStatus||""}
          />
    </div>
    <div className="xl:col-span-3 xl:col-start-4">
    <BudgetVSActual data={[budjectvsActual]} />
    </div>
    <div className="xl:col-span-3 xl:row-start-2">
    <HeadCount data={headCount} />
    </div>
    <div className="xl:col-span-3 xl:col-start-4 xl:row-start-2">
    <Activities data={activity} />
    </div>
    <div className="xl:col-span-3 xl:row-start-3">
    <InvoiceInOut actualData={incommingData} plannedData={outgoingData} />
    </div>
    <div className="xl:col-span-3 xl:col-start-4 xl:row-start-3">
    <ProjectProgress project={project} />
    </div>
    <div className="xl:col-span-3 xl:row-start-4">
    <ProgressByDivision data={[]} />
    </div>
    <div className="xl:col-span-3 xl:col-start-4 xl:row-start-4">
    <Manhour data={manHour} />
    </div>
    <div className="xl:col-span-3 xl:row-start-5">
    <UpComingActivities data={upCommingActivities} />
    </div>
    <div className="xl:col-span-3 xl:col-start-4 xl:row-start-5">
    <DelayedActivities data={[]} />
    </div>
    <div className="xl:col-span-3 xl:row-start-6">
    <ProjectTeam teamDetails={teamDetails} />
    </div>
</div>
    
      </div>
    </div>
  );
}

export default ViewProject;
