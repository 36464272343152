import { Modal, notification } from "antd";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import axiosInstance from "../../../../../../Services/axiosCommon";
import { CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import { NuInput, NuSelect } from "../../../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";

function CreateSubManpowerPro({open,onCancel,getData,projectId,itId}) {
    // console.log("finding",estiId);
    // const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const {value:Role ,getData:refManPowerRole} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerRole')

  const {value:Unit ,getData:refManPowerUnit} = useFetchMataData(`/dropdown/dropdown-details/`,'ManPowerUnit')

    const [api, contextHolder] = notification.useNotification();
    const [isLoading,setIsLoading] = useState(false);
    const [createNew, setCreateNew] = useState({
      project_Id: "",
      description: "",
      IT_Id: "",
      unit: "",
      budget_quantity: "",
      actual_quantity: "",
      budget_Price: "",
      actual_Price: "",
      amount: "",     
              Planned_days:"",
              actual_days:"",
              role:""
             
    });
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema:yup.object({
        description:yup.string().required("Item Name is Requried")
      }),
      onSubmit: (value,{resetForm}) => {    
        setIsLoading(true);
        const values = {
          ...value,
          projectId: Number(projectId),
          IT_Id: itId,
        }
        axiosInstance
        .post(`/pro_manpower/single-manpower`, values)
          .then((res) => {
            console.log(res);
            if (res.status === 201 || res.data.status == true) {
              setIsLoading(false);
              getData();
              resetForm();
              api.success({
                message: `Material Item Creation successfully`,
                description: res?.data?.msg || "Added In Tittle",
                placement: "bottomRight",
              });
              onCancel();
            } else {
              api.error({
                message: `Material Item  Creation failed`,
                description: res?.data?.msg || "Network error",
                placement: "bottomRight",
              });
              setIsLoading(false);
            }
          })
          .catch((err) => {
            api.error({
              message: `Material Item Creation failed`,
              description: err?.data?.msg || "Sever error (or) Network error",
              placement: "bottomRight",
            });
            setIsLoading(false);
          });
      },
    });
  
    useEffect(() => {
      const amount = formik?.values?.actual_quantity * formik?.values?.actual_Price
      formik.setFieldValue("amount", amount);
    }, [formik?.values?.actual_quantity,formik?.values?.actual_Price]);
  
    return (
      <>
      {contextHolder}
      <Modal
        open={open}
        title={"Create Material Item"}
        width={"50%"}
        className="my-modal"
        centered
        footer={[]}
        onCancel={onCancel}
      >
      <form onSubmit={formik.handleSubmit} >
        <div className=" w-full flex flex-wrap">
           <NuInput
                        type="text"

                label="Description"
                placeholder="Description"
                formik={formik}
                name="description"
                width="w-full"
                isRequired={true}
            />
            <FormikSelectTwo
              label="Unit"
              Options={ Unit || [] }
              formik={formik}
              placeholder="Choose"
              name="unit"
              width="w-full md:w-1/3"
              getFunc={refManPowerUnit}
              displayProperty={"Unit"}
              propertyName={"ManPowerUnit"}
              />
               <FormikSelectTwo
                  label="Role"
                  Options={Role}
                  formik={formik}
                  placeholder="Choose"
                  name="role"
                  width="w-full md:w-1/3"
                  getFunc={refManPowerRole}
                  displayProperty={"Role"}
                  propertyName={"ManPowerRole"}
                />
               <NuInput
                            type="number"

                label="Planned Days"
                placeholder="Planned Days "
                formik={formik}
                name="Planned_days"
                width="w-full md:w-1/3"
              />
               <NuInput
                            type="number"

                label="Actual Days"
                placeholder="Actual Days "
                formik={formik}
                name="actual_days"
                width="w-full md:w-1/3"
              />
             <NuInput
                          type="number"

                label="Budget Quantity"
                placeholder="Budget Quantity"
                formik={formik}
                name="budget_quantity"
                width="w-full md:w-1/3"
              />
              <NuInput
                           type="number"

                label="Budget Price"
                placeholder="Budget Price"
                formik={formik}
                name="budget_Price"
                width="w-full md:w-1/3"
              />
              <NuInput
                           type="number"

                label="Actual Quantity"
                placeholder="Actual Quantity"
                formik={formik}
                name="actual_quantity"
                width="w-full md:w-1/3"
              />
              <NuInput
                           type="number"

                label="Actual Price"
                placeholder="Actual Price"
                formik={formik}
                name="actual_Price"
                width="w-full md:w-1/3"
              />           
              <NuInput
                           type="number"

                label="Actual Total Price"
                placeholder="Actual Total Price"
                formik={formik}
                name="amount"
                width="w-full md:w-1/3"
                disabled={true}
              />
              <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
                <CancelButton handleClick={onCancel} />
                <SubmitButton isLoading={isLoading} />
              </div>
        </div>
        </form>
      </Modal>
      </>
    )
  }
  
  export default CreateSubManpowerPro;