import { Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../../../../Services/axiosCommon";
import { NuInput } from "../../../../../../Components/Utils/Input";

const EditTitle = ({ open, getData, onCancel, data,firstProject }) => {
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    projectId:"",
    title_name:""
  });

  useEffect(() => {
    data&&setCreateNew({
      projectId:data.projectId,
      title_name:data.title_name,
    })
  },[data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      title_name:yup.string().required("Title is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
      // formik.resetForm({ values: "" });
      axiosInstance.put(`/title/${data?.titleId}`, value)
      .then((res) => {
          console.log("res",res.data.status);
          if (res.data.status) {
            getData(firstProject);
            resetForm();
            api.success({
              message: `Title Update successfully`,
              description: res?.data?.msg || "Added In The Title",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Title Upadate failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title={data ? "Edit Title" : "Create Title"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuInput
                         type="text"

              label="Title"
              placeholder="Title Name"
              formik={formik}
              name="title_name"
              width="w-full"
            />
<button
              type="submit"
              // htmlType="submit"
              // size="large"
              className="w-11/12 bg-baseColor-primary py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
            >
              Update
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditTitle;
