import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function DivisionOthers() {
    return (
        <SettingsMetaData propertyName={"OtherDivision"} viewPropertyName={"Division"} />
      );
}

export default DivisionOthers
