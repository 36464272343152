import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, {
  NuInput,
  NuMobileNumber,
  NuDate,
  NuSelect,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo, { FormikSelectWithAdd } from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import axiosInstance from "../../../../Services/axiosCommon";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import { useToast } from "../../../../Components/Others/ToastServices";
import { useNumber } from "../../../../Context/ProjectIdContext";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

const CreateShipmentTracking = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  // const { value: Consignee, getData: refConsignee } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "Consignee"
  // );
  // const {
  //   value: CustomerClearanceStatus,
  //   getData: refCustomerClearanceStatus,
  // } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "CustomerClearanceStatus"
  // );
  // const { value: FinalDestination, getData: refFinalDestination } =
  //   useFetchMataData(`/dropdown/dropdown-details/`, "FinalDestination");
  // const { value: InsurancePremium, getData: refInsurancePremium } =
  //   useFetchMataData(`/dropdown/dropdown-details/`, "InsurancePremium");
  // const { value: InsuranceProvider, getData: refInsuranceProvider } =
  //   useFetchMataData(`/dropdown/dropdown-details/`, "InsuranceProvider");
  // const { value: IssuedCarrier, getData: refIssuedCarrier } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "IssuedCarrier"
  // );
  // const { value: PortOfDischarge, getData: refPortOfDischarge } =
  //   useFetchMataData(`/dropdown/dropdown-details/`, "PortOfDischarge");
  // const { value: PortOfLoading, getData: refPortOfLoading } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "PortOfLoading"
  // );
  // const { value: Shipper, getData: refShipper } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "Shipper"
  // );
  const { value: ShippingMethod, getData: refShippingMethod } =
    useFetchMataData(`/dropdown/dropdown-details/`, "ShippingMethod");
  const { value: VesselName, getData: refVesselName } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "VesselName"
  );
  const { value: ShippingStatus, getData: refShippingStatus } =
    useFetchMataData(`/dropdown/dropdown-details/`, "ShippingStatus");
  const { value: IdentityType, getData: refIdentityType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ShipIdentityType"
  );
  const { value: VehicleType, getData: refVehicleType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ShipVehicleType"
  );
  const toast = useToast();
  const { data: PR } = UseDropDown("/PR/drop-down");
  const { data: Agents, getData: getAgents } = UseDropDown("/agents/drop-down");
  const { value: Type, getData: refType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "AgentType"
  );
  // const { data: SO } = UseDropDown('/SO/drop-down');
  // const { data: DN } = UseDropDown('/DN/drop-down');
  // const [loading, setLoading] = useState(false);

  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [image, setImage] = useState([]);
  // const [multiImage, setMultiImage] = useState([]);
  const {number} = useNumber()

  const [createNew, setCreateNew] = useState({
    // ref: "",
    prId: "",
    agent: "",
    poId: "",
    ETD: currentDate || "",
    ETA: currentDate || "",
    permitReq: false,
    modeOfShipment: "",
    bookingDate: currentDate || "",
    deliveryDatebyPO: currentDate || "",
    // shippedOn: currentDate || "",
    // estimatedArrivalDate: "",
    actualArrivalDate: currentDate || "",
    shipmentBill: "",
    shippingMethod: "",
    deliveryPersonName: "",
    identityType: "",
    identityNumber: "",
    vehicleType: "",
    vehicleNumber: "",
    shippingStatus: "",
    trackingUrl:"",
    ESC: "",
    ASC: "",
    projectId:number

    
  });

  // const handleChangeImg = (e) => {
  //   if (e.target.files.length) {
  //     setImage({
  //       preview: URL.createObjectURL(e.target.files[0]),
  //       raw: e.target.files[0],
  //     });
  //   }
  // };

  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files?.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files?.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("YPR is required!"),
      poId: yup.string().required("Purchase Order is required!"),
      agent: yup.string().required("Agent is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      let formData = new FormData();
      formData.append("shippingDetails", JSON.stringify(value));
      // formData.append('proofOfDelivery', image.raw);
      formData.append("insuranceCertificate", image.raw);
      // formData.append('attachments', image.raw);
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }

      addFormData("/shipping/new-shipping", formData, resetForm);
      // setLoading(false)
    },
  });

  const [PO, setPO] = useState([]);
  const [DN, setDN] = useState([]);
  const getPOByPR = () => {
    axiosInstance
      .get(`/grn/PO/drop-down/${formik?.values?.prId}`)
      .then((res) => {
        console.log("v", res?.data);
        let data = res.data;
        console.log(data, "dddd");
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item.id, label: item.name };
          tempData.push(tempObject);
        });
        res.data && setPO(tempData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDNBySO = () => {
    axiosInstance
      .get(`/delivery-notes/drop-down/by-SO/${formik?.values?.prId}`)
      .then((res) => {
        console.log("v", res?.data);
        let data = res.data;
        console.log(data, "dddd");
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item.id, label: item.name };
          tempData.push(tempObject);
        });
        res.data && setDN(tempData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const handlePopupSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // formData.append('customerImage', JSON.stringify(newProduct));
    formData.append(
      "agentDetails",
      JSON.stringify({
        type: formik.values.type || "",
        companyName: formik.values.companyName || "",
        email: formik.values.email || "",
        phone: formik.values.phone || "",
        countryCode: formik.values.countryCode || "",
        website: formik.values.website || "",
        // industry: formik.values.industry || "",
      })
    );
    try {
      axiosInstance
        .post("/agents/new-agent", formData)
        .then((res) => {
          if (res?.data?.status == true) {
            formik.resetForm();
            getAgents();
            formik.setValues((prevValues) => ({
              ...prevValues,
              // Reset fields related to the new vendor
              companyName: "",
              email: "",
              phone: "",
              countryCode: "",
              website: "",
              // industry: "",
              customerType: "",
            }));
            setShowPopup(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error("Error creating new customer:", error);
    }
  };

  useEffect(() => {
    if (formik?.values?.prId) {
      getPOByPR();
    }
    if (formik?.values?.poId) {
      getDNBySO();
    }
  }, [formik?.values?.prId, formik?.values?.poId]);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = []
    List.map((item) =>{
      let fileName = item?.name
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    })
    setImage(tempArr)
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-end mb-4">
        {/* <Breadcrumb
          items={[
            {
              name: "Shipment Tracking",
              link: "/shipping/shipment-tracking",
            },
            {
              name: "Create Shipment Tracking",
            },
          ]}
        /> */}
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">

      <form onSubmit={formik.handleSubmit}>

      <div className="w-full mx-auto  bg-white rounded-md pb-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
          
                   <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Booking Details :
  </p>
</div>     {/* <NuInput
              type="text"
              label="Reference No"
              placeholder="Reference No"
              formik={formik}
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Booking Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="bookingDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="YPR "
              placeholder="YPR "
              Options={PR}
              formik={formik}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={PO}
              formik={formik}
              name="poId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuDate
              label="Delivery Date by PO"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deliveryDatebyPO"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <div className="relative px-6 py-2 w-full md:w-1/2 lg:w-1/3"> */}
            {/* <div className=" flex justify-between items-center w-full"> */}
              <FormikSelectWithAdd
                type="text"
                label="Agent"
                placeholder="Choose"
                Options={Agents}
                formik={formik}
                name="agent"
                width="w-full md:w-1/2 lg:w-1/3"
                px="0"
                isRequired={true}
              handleClick={togglePopup}

              />
              {/* <button
                onClick={togglePopup}
                type="button"
                className="w-[13%] p-1 text-center h-10 mt-7 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
              >
                {" "}
                +{" "}
              </button>
            </div> */}
            {/* </div> */}
            {/* <NuDate
              label="Shipped On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="shippedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
              type="text"
              label="Shipment Bill Number"
              placeholder="Shipment Bill Number"
              formik={formik}
              name="shipmentBill"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <DropDownAddMetaData
              label="Mode of Shipment"
              placeholder="Choose"
              Options={ShippingMethod}
              formik={formik}
              name="modeOfShipment"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refShippingMethod}
              displayProperty={"Shipping Method"}
              propertyName={"ShippingMethod"}
            />

            <FormikSelectTwo
              label="Permit Required"
              placeholder="Choose"
              Options={[
                {value: true, label:"Yes"},
                {value: false, label:"No"}
              ]}
              formik={formik}
              name="permitReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
           </div>
           </div>
           <div className="w-full mx-auto  bg-white rounded-md pb-4 mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
       
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Tracking Details :
  </p></div>   
  <NuInput
              type="text"
              label="Tracking Url"
              placeholder="Tracking Url"
              formik={formik}
              name="trackingUrl"
              width="w-full md:w-1/2 lg:w-2/3"
              />
  <NuDate
              label="Estimated Time of Departure(ETD)"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETD"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Estimated Time of Arrival(ETA)"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETA"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Actual Arrival Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="actualArrivalDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Estimated Shipping Cost"
              placeholder="Estimated Shipping Cost"
              formik={formik}
              name="ESC"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Actual Shipping Cost"
              placeholder="Actual Shipping Cost"
              formik={formik}
              name="ASC"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Shipment Status"
              placeholder="Choose"
              Options={ShippingStatus}
              formik={formik}
              name="shipmentStatus"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refShippingStatus}
              displayProperty={"Shipping Status"}
              propertyName={"ShippingStatus"}
            />
            <NuInput
              type="text"
              label="Delivery Person "
              placeholder="Delivery Person "
              formik={formik}
              name="deliveryPersonName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Identity Type"
              placeholder="Choose"
              Options={IdentityType}
              formik={formik}
              name="identityType"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refIdentityType}
              displayProperty={"Identity Type"}
              propertyName={"ShipIdentityType"}
            />
            <NuInput
              type="text"
              label="Identity Number"
              placeholder="Identity Number"
              formik={formik}
              name="identityNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Vehicle Type"
              placeholder="Choose"
              Options={VehicleType}
              formik={formik}
              name="vehicleType"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refVehicleType}
              displayProperty={"Vehicle Type"}
              propertyName={"ShipVehicleType"}
            />
            <NuInput
              type="text"
              label="Vehicle Number"
              placeholder="Vehicle Number"
              formik={formik}
              name="vehicleNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Attachement :
  </p></div>
            {/* <div className="w-full "> */}
              {/* <div className=" w-[15%] "> */}
              {/* <p className="w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]">
                Attachment
              </p>
              <div className="border-t border-[1px] border-slate-200/60 border-solid w-full px-4"></div> */}
              <Attachement
                handleChange={handleImageChange}
                preview={image || ""}
                width="w-full"
                label="Attach"
                multiple={true}
                removeImages={removeImages}

              />
              {/* </div> */}
            {/* </div> */}

            {/* <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Insurance Details</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
            <NuInput
              type="text"
              label="Insurace Policy Number"
              placeholder="Insurace Policy Number"
              formik={formik}
              name="insuracePolicyNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Insurance Provider"
                placeholder="Choose"
                Options={InsuranceProvider}
                formik={formik}
                name="insuranceProvider"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refInsuranceProvider}
                displayProperty={"Insurance Provider"}
                propertyName={"InsuranceProvider"}
              />           
              <FormikSelectTwo
              label="Insurance Type"
              placeholder="Insurance Type"
              Options={[
                { value: "Personal", label: "Personal" },
                { value: "Mutual", label: "Mutual" },
              ]}
              formik={formik}
              name="insuranceType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Coverage Start Date"
              placeholder="Coverage Start Date"
              formik={formik}
              name="coverageStartDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Coverage End Date"
              placeholder="Coverage End Date"
              formik={formik}
              name="coverageEndDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Coverage Amount"
              placeholder="Coverage Amount"
              formik={formik}
              name="coverageAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Insurance Premium"
                placeholder="Choose"
                Options={InsurancePremium}
                formik={formik}
                name="insurancePremium"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refInsurancePremium}
                displayProperty={"Insurance Premium"}
                propertyName={"InsurancePremium"}
              />
            
            <NuInput
              type="text"
              label="Claim Contact Info"
              placeholder="Claim Contact Info"
              formik={formik}
              name="claimContactInfo"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuUpload
              label="Proof Of Delivery"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
            {/* <NuUpload
              label="Insurance Certificate"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}

            <div className="w-full hidden md:block mx-auto my-4 mt-8">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>

      </form>
      </div>

      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-normal text-[12px]">Add Agent</h2>
            <form onSubmit={handlePopupSubmit}>
              <div className="mb-4 flex flex-wrap">
                <DropDownAddMetaData
                  label="Agent Type"
                  placeholder="Choose"
                  Options={Type}
                  formik={formik}
                  name="type"
                  width="w-full lg:w-1/2"
                  getFunc={refType}
                  displayProperty={"Agent Type"}
                  propertyName={"AgentType"}
                />
                <NuInput
                  type="text"
                  label="Company Name"
                  placeholder="Company Name"
                  formik={formik}
                  name="companyName"
                  width="w-full md:w-1/2 lg:w-1/2"
                />
                <NuMobileNumber
                  nunCode="countryCode"
                  label="Phone"
                  placeholder="Phone"
                  formik={formik}
                  name="phone"
                  width="w-full md:w-1/2 lg:w-1/2"
                />
                <NuInput
                  type="email"
                  label="Email"
                  placeholder="Email"
                  formik={formik}
                  name="email"
                  width="w-full md:w-1/2 lg:w-1/2"
                  isRequired={true}
                />
                <NuInput
                  type="text"
                  label="Website"
                  placeholder="website"
                  formik={formik}
                  name="website"
                  width="w-full md:w-1/2 lg:w-1/2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePopup}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateShipmentTracking;
