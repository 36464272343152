import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../../../Components/Utils/Buttons'
import { Link, useLocation, useParams } from 'react-router-dom'
import Dasboard from '../../../../../../Components/Icons/Dasboard'
import NuAmount from '../../../../../../Components/Utils/NuAmount'
import Breadcrumb from '../../../../../../Components/Utils/Breadcrumb'
import axiosInstance from '../../../../../../Services/axiosCommon'

function ViewManPower(id) {
    const { projectId } = useParams();
    const {officeId , estmationId,manPowerViewId} = useParams();
     const location = useLocation();
      const [data,setData]=useState({})
  
  // const location = useLocation();
  // const data = location.state?.data;
  const Paydata = location.state.data;
  const pageIndex = location.state?.pageIndex;
  const searchQuery = location.state?.searchQuery;
  function getById (id){
    axiosInstance.get(`/man_power_cost/${manPowerViewId}`)
    .then( async (res)=>{
    
      console.log('====================================');
      console.log(res.data);
      console.log('====================================');
      setData(res.data.data)
    })
    
    .catch((error)=>{
      console.log(error);
    })
    
      }
      useEffect(()=>{
            if (manPowerViewId){
             getById(manPowerViewId)
              
            }
          },[manPowerViewId])
  return (
    <div className="w-full h-[calc(100vh-60px)]  px-4 py-2 overflow-hidden ">
    <div className="md:flex justify-between w-full">
      {/* <Breadcrumb
        items={[
          {
            title: (
              <Link to="/dashboard">
                <Dasboard color="gray" width={10} height={10} />{" "}
              </Link>
            ),
          },
          // { title: <p className=" text-[12px] ">Costing</p> },
          { title: <p className=" text-[12px] ">Manpower </p> },
          { title: <p className=" text-[12px] ">View</p> },
        ]}
      /> */}
      <Breadcrumb
      items={[

        { name: "Budget" },
           
        {
          name: "Man Power",
          link: `/project/costing/man-power/${officeId}/${projectId}`
        },

        { name: "View" },
      ]} 
            />
      {/* <div className=" flex justify-between items-center float-right">
        <BackButton />
      </div> */}
      <div className=" flex gap-2">
          <Link to={`/project/costing/man-power/${officeId}/${projectId}`} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
          <Link to={`/project/costing/man-power/${officeId}/${projectId}/edit/${data?.id}`} state={{data: data, from:`/project/costing/man-power/${officeId}/${projectId}/view/${data?.id}`}}><EditButton height='8'/></Link>
        </div>
    </div>
    <div className=" w-full  h-[calc(100vh-150px)] overflow-y-auto mt-3 bg-white rounded-lg  p-4 ">
      <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3 ">
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Project </p>
          <p className=' text-xs  text-mainColor-400'>{data?.projectName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>	Designation </p>
          <p className=' text-xs  text-mainColor-400'>{data?.designation||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Category </p>
          <p className=' text-xs  text-mainColor-400'>{data?.category||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>No. Of Workers  </p>
          <p className=' text-xs  text-mainColor-400'>{data?.quantity||"0"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>No. Of Days </p>
          <p className=' text-xs  text-mainColor-400'>
            {/* <NuAmount value={data?.noOfDays||0}/> */}
            {data?.noOfDays||0}
          </p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'> Total	Hours </p>
          <p className=' text-xs  text-mainColor-400'>
            {/* <NuAmount value={data?.hours||0}/> */}
            {data?.hours||0}
          </p>
        </div>
        <div className=' my-2 col-span-3'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Comments </p>
          <p className=' text-xs  text-mainColor-400'>{data?.comments||"-"}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewManPower