// import React, { useEffect, useState } from 'react'
// import MoreOptions from '../../../../Components/Utils/MoreOptions';
// import DataTable from '../../../../Components/Others/DataTable';
// import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
// import { Link, useLocation, useParams } from 'react-router-dom';
// import SearchBar from '../../../../Components/Utils/SearchBar';
// import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
// import UseGetData from '../../../../Components/Hooks/useFetchData';
// import CustomDataTable from '../../../../Components/Others/CustomDataTable';
// import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
// import FormikSelectTwo from '../../../../Components/Utils/Select';
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
// import { NuDate } from '../../../../Components/Utils/Input';
// import ModalContainer from '../../../../Components/Others/ModalContainer';
// import MultiEditPO from './MultiEditPO';
// import { GetAccess, GetNotUseRole } from '../../../../Components/Utils/roles';
// import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';
// import ProcurementLayout from '../ProcurementLayout';

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useFetchMDIndex from "../../../../../../Components/Hooks/useFetchMDIndex";
import { useFormik } from "formik";
import * as yup from 'yup';
import UseGetDataSearch from "../../../../../../Components/Hooks/useFetchDataBySearch";
import { GetAccess, GetNotUseRole } from "../../../../../../Components/Utils/roles";
import ProcurementsubLayout from "../ProcurementLayout";
import NuDate from "../../../../../../Components/Utils/NuDate";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
import { CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import MultiEditPo from "../../../../Procurement/Po/MultiEditPO";
import ModalContainer from "../../../../../../Components/Others/ModalContainer";
import CustomDataTable from "../../../../../../Components/Others/CustomDataTable";

function PoIndex() {
  const {officeId,projectId} =useParams();

  const location = useLocation();
  const state = location.state;

  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
    const [filter, setFilter] = useState(state?.approvalStatus?true:false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const {value:paymentTerms} = useFetchMDIndex(`/dropdown/dropdown-details/`,'PaymentTerms_po',showFilterDialog)
    // const {value:deliveryTerms} = useFetchMDIndex(`/dropdown/dropdown-details/`,'deliveryTerms_po',showFilterDialog)
    const {value:paymentStatus} = useFetchMDIndex(`/dropdown/dropdown-details/`,'paymentStatus_po',showFilterDialog)
    const {value:Status} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Status_po',showFilterDialog)
    const { value: deliveryTerms, getData: refdeliveryTerms } = useFetchMDIndex(
      `/dropdown/dropdown-details/`,
      "DeliveryTerms_Supplierqoutes",showFilterDialog
    );
    const [filterProps, setFilterProps] = useState({
      status:"",
      requestedDeliveryDateFrom:"",
      requestedDeliveryDateTo:"",
      paymentStatus:"",
      fromDate:"",
      toDate:"",
      paymentTerms:"",
      deliveryTerms:"",
      approvalStatus:"",


    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:searchQuery||"",

      status:"",
      approvalStatus:state?.approvalStatus? state.approvalStatus :"",

    requestedDeliveryDateFrom:"",
    requestedDeliveryDateTo:"",
    paymentStatus:"",
    fromDate:"",
    toDate:"",
    paymentTerms:"",
    deliveryTerms:""
    });
    const clearFilter = ()=> {
      setParams({...params, 
        status:"",
        requestedDeliveryDateFrom:"",
        requestedDeliveryDateTo:"",
        approvalStatus:"",

        paymentStatus:"",
        fromDate:"",
        toDate:"",
        paymentTerms:"",
        deliveryTerms:""})
        setFilter(false)
        formik.values.status=""
        formik.values.requestedDeliveryDateFrom=""
        formik.values.requestedDeliveryDateTo=""
        formik.values.paymentStatus=""
        formik.values.fromDate=""
        formik.values.toDate=""
        formik.values.paymentTerms=""
        formik.values.deliveryTerms=""
        formik.values.approvalStatus=""


    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,status:value.status, deliveryTerms:value.deliveryTerms,paymentTerms:value.paymentTerms,toDate:value.toDate,fromDate:value.fromDate,paymentStatus:value.paymentStatus,requestedDeliveryDateTo:value.requestedDeliveryDateTo,requestedDeliveryDateFrom:value.requestedDeliveryDateFrom,approvalStatus:value.approvalStatus})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          status:"",
          requestedDeliveryDateFrom:"",
          requestedDeliveryDateTo:"",
          paymentStatus:"",
          fromDate:"",
          toDate:"",
          paymentTerms:"",
          deliveryTerms:"",
          approvalStatus:""
        });
      }
    })
  
    const { data, totalCount, getData,dataLoading } = UseGetDataSearch( '/PO/',params);
    const defaultHeader = [
      // {
      //   title: "ID",
      //   width: "5%",
      //   field: "poId",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "PR",
        width: "10%",
        isLink: true,
        id: "poId",
        link: `/project/procurement/parchase-order/${officeId}/${projectId}/view/`,
      
        field: "prRrf",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "PO NO. ",
        width: "10%",
        isLink: true,
        id: "poId",
        link: `/project/procurement/parchase-order/${officeId}/${projectId}/view/`,
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
     
     
      {
        title: "Date",
        type: "date",
        width: "10%",
        field: "date",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Purchasers",
      //   width: "10%",
      //       field: "purchasers",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Supplier ",
        width: "10%",
        field: "vendorName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Amount",
        width: "10%",
        type:"amount",
        field: "totalPrice",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Payment Status",
        width: "10%",
        field: "paymentStatus",
        textAlign: "center",
        rowAlign: "center",
      },
      ...(GetNotUseRole("procurement")
      ? [
          {
            title: "Purchaser",
            width: "10%",
            field: "purchaser.name",
            textAlign: "center",
            rowAlign: "center",
          },
        ]
      : [
        {
          title: "Prepared By ",
          width: "10%",
          field: "preparedByName",
          textAlign: "center",
          rowAlign: "center",
        },
      ]),
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"poId",
          removeValue:"ref",
          url:`/PO/`,
          moduleName:"Purchase Order",
          from:`/project/procurement/parchase-order/${officeId}/${projectId}`,
          editLink:`/project/procurement/parchase-order/${officeId}/${projectId}/edit/`,
          viewLink:`/project/procurement/parchase-order/${officeId}/${projectId}/view/`,
          EmailRequired: true,
  
        }
      },
    ];
    const availableHeaders = [
      // {
      //   title: "ID",
      //   width: "5%",
      //   field: "poId",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "PR",
        width: "10%",
        isLink: true,
        id: "poId",
        link: `/project/procurement/parchase-order/${officeId}/${projectId}/view/`,
      
        field: "prRrf",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "PO NO. ",
        width: "10%",
        isLink: true,
        id: "poId",
        link: `/project/procurement/parchase-order/${officeId}/${projectId}/view/`,
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
     
     
      {
        title: "Date",
        type: "date",
        width: "10%",
        field: "date",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Purchasers",
      //   width: "10%",
      //       field: "purchasers",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Supplier ",
        width: "10%",
        field: "vendorName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Amount",
        width: "10%",
        type:"amount",
        field: "totalPrice",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Payment Status",
        width: "10%",
        field: "paymentStatus",
        textAlign: "center",
        rowAlign: "center",
      },
      ...(GetNotUseRole("procurement")
      ? [
          {
            title: "Purchaser",
            width: "10%",
            field: "purchaser.name",
            textAlign: "center",
            rowAlign: "center",
          },
        ]
      : [
        {
          title: "Prepared By ",
          width: "10%",
          field: "preparedByName",
          textAlign: "center",
          rowAlign: "center",
        },
      ]),
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
     
      
      {
        title: "Requested Delivery",
        type: "date",
        width: "10%",
        field: "requestedDeliveryDate",
        textAlign: "center",
        rowAlign: "center",
      },
     
      // {
      //   title: "Payment Terms",
      //   width: "10%",
      //   field: "paymentTerms",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Delivery Terms",
        width: "10%",
        field: "deliveryTerms",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Requested By",
        width: "10%",
        field: "requestedBy",
        textAlign: "center",
        rowAlign: "center",
      },
     
      {
        title: "Approved By",
        width: "10%",
        field: "approvedBy",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Comments",
      //   width: "10%",
      //   field: "comments",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"poId",
          removeValue:"ref",
          url:`/PO/`,
          moduleName:"Purchase Order",
          from:`/project/procurement/parchase-order/${officeId}/${projectId}`,
          editLink:`/project/procurement/parchase-order/${officeId}/${projectId}/edit/`,
          viewLink:`/project/procurement/parchase-order/${officeId}/${projectId}/view/`,
          EmailRequired: true,
        }
       
      },
    ];
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
  
     const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [listofNumbers, setListofNumbers] = useState([])
    const [fullList, setFullList] = useState([])
  
    const selectOneFunction = (value) =>{ 
      let NewId;
      if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
        NewId = listofNumbers.filter(Temp=> Temp !== value);
        setListofNumbers(NewId)
      }else{
        setListofNumbers([...listofNumbers,value])
      }
    }
  
    const selectMultipleFunction = (e) =>{ 
  console.log(e.target.checked);
  if(e.target.checked){
    setListofNumbers(fullList)
  }else{
    setListofNumbers([])
  }
  
    }
  
    useEffect(()=>{
      let tempArr = []
      data && data.forEach(i=>{
        console.log("I",i?.poId);
        tempArr.push(i?.poId)
      });
      setFullList(tempArr)
    },[data])

   const [valueOfSearch,setValuesofSearch] = useState("")
     function searchFunction(e) {
   
       
       setValuesofSearch(e?.target?.value)
       setParams({ ...params, search: e?.target?.value, page:1 });
       setPage(1)
     }
   

    return (
      <>
      <ProcurementsubLayout 
      //  setOpen={closeModal}
      searchFun={searchFunction}
      searchValue={valueOfSearch}
       getData={getData}
       isFilter={filter}
       setFilterOpen={setShowFilterDialog}
       ClearFilter={clearFilter}
       CreateAccess={GetAccess("purchase_order_create")}
      //  ImportAccess={GetAccess("purchase_order_create")}
       officeId={officeId}
       neededSearch={true}
       newfilter={true}

       // projectId={projectId}
         >
      <div className='w-full h-full '>
        {/* <div className='w-full flex justify-between items-center'>
          <Breadcrumb items={[
            { name: "Procurement" },
  
            { name: "Purchase Order" },
          ]} />
          <div className=' flex gap-2'>
          <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />   <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
            {/* <CreateButton label='Import' /> */}
            {/* {GetAccess
            ("purchase_order_create") && <Link to="/main/${officeId}/procurement/purchase-order/create"><CreateButton label='Purchase Order' /></Link>}
          </div>
        </div> */}
  
        <CustomDataTable 
        fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-160px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Purchase Order List'
        storageName="POTProjectTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="poId"
        editAccess="purchase_order_edit"      
        deteleAccess="purchase_order_delete" 
        dataLoading={dataLoading}
        searchQuery={params?.search!=""?params?.search:""}

        />
        </div>
        </ProcurementsubLayout>
     {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Purchase Order</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Requested Delivery Date</p>
                    <div className="flex justify-between  w-full ">
                <NuDate
                label="From"
                placeholder="DD/MM/YYYY"
                formik={formik}
                name="requestedDeliveryDateFrom"
                width="w-full"
              />
               <NuDate
                label="To"
                placeholder="DD/MM/YYYY"
                formik={formik}
                name="requestedDeliveryDateTo"
                width="w-full"
              />
              </div>
              <p className="py-2 px-6 text-m">Date</p>
              <div className="flex justify-between  w-full ">
               <NuDate
                label="From "
                placeholder="DD/MM/YYYY"
                formik={formik}
                name="fromDate"
                width="w-full"
              />
               <NuDate
                label="To "
                placeholder="DD/MM/YYYY"
                formik={formik}
                name="toDate"
                width="w-full"
              />
              </div>
              <FormikSelectTwo
              type="text"
              label="Delivery Terms"
              placeholder="Choose"
              Options={deliveryTerms}
              formik={formik}
              name="deliveryTerms"
              width="w-full"
            /> 
             <FormikSelectTwo
              type="text"
              label="Payment Terms"
              placeholder="Choose"
              Options={paymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full"
            />
              <FormikSelectTwo
                type="text"
                label="Payment Status"
                placeholder="Payment Status"
                Options={paymentStatus}
                formik={formik}
                name="paymentStatus"
                width="w-full"
              />
             <FormikSelectTwo
                type="text"
                label=" Status"
                placeholder=" Status"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full"
              />
                <FormikSelectTwo
              type="text"
              label="Approval Status"
              placeholder="Approval Status"
              Options={[
                {
                  value: "Pending",
                  label: "Pending",
                },
                {
                  value: "Reviewed",
                  label: "Reviewed",
                },
                {
                  value: "Verified",
                  label: "Verified",
                },
                {
                  value: "Approved",
                  label: "Approved",
                },
                            

              ]}
              formik={formik}
              name="approvalStatus"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
                   {showEdit && (<MultiEditPo data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from YPR List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
     
      </>
    )
  }

export default PoIndex