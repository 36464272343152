import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";



function BarChartView({data}) {

  const CustomTooltip = ({ active, payload, label}) => {  
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[350px] bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-[5px]">
          <p className=" text-slate-600 font-semibold text-xs">{label} :</p>
          <table className=" w-full border-collapse ">
            {payload &&
            payload.map((p, index) => (
              <tr key={index} className=' text-xs'>
              <td style={{color:p?.fill}} className={` w-[70px] py-[2px]`}>{p?.name || "-"}</td>
              <td>
                : <span style={{color:p?.fill}} className=" font-semibold">{p.value||0}</span>
              </td>
            </tr>
            ))}
          </table>
        </div>
      );
    }
  
    return null;
  };
    
  return (
    <ResponsiveContainer width="100%" height="90%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 10,
        }}
        
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="month" fontSize={12} fontWeight={500}  />
        {/* <YAxis /> */}
        <Tooltip content={<CustomTooltip/>} cursor={{fill:"none"}} />
        <Legend fontSize={8} verticalAlign="top" height={26} iconSize={12} iconType="circle" wrapperStyle={{ fontSize: '12px' }} />
        <Bar
          dataKey="In-Coming"
          fill="#FF2929"
          barSize={10}
          radius={[10,10,0,0]}
        />
        <Bar
          dataKey="Out-Going"
          fill="#0D92F4"
          barSize={10}
          radius={[10,10,0,0]}

        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartView;
