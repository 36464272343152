import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import { useFormik } from "formik";
import * as yup from 'yup';
import FormikSelectTwo from "../../../../Components/Utils/Select";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { NuDate } from "../../../../Components/Utils/Input";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditReturn from "./MultiEditReturn";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";
import StoreLayout from "../StoreLayout";
import { useNumber } from "../../../../Context/ProjectIdContext";

function ReturnsIndex() {
  const {officeId} =useParams();
  const {number} = useNumber()

  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:ReturnStatus} = useFetchMDIndex(`/dropdown/dropdown-details/`,'ReturnStatus',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    status:"",
    returnedDateFrom:"",
    returnedDateTo:"",
    type:"",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",

    status:"",
    returnedDateFrom:"",
    returnedDateTo:"",
    type:"",
  });

  const clearFilter = ()=> {
    setParams({...params, status:"",
      returnedDateFrom:"",
      returnedDateTo:"",
      type:"",})
      setFilter(false)
      formik.values.status=""
      formik.values.returnedDateFrom=""
      formik.values.returnedDateTo=""
      formik.values.type=""

  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,status:value.status, returnedDateFrom:value.returnedDateFrom,returnedDateTo:value.returnedDateTo,type:value.type})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        status:"",
        returnedDateFrom:"",
        returnedDateTo:"",
        type:"",
      });
    }
  })

  const { data, totalCount, getData, dataLoading } = UseGetDataSearch("/returns/",params);

  const defaultHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "returnId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: " Return No.",
      width: "10%",
      isLink: true,
      id: "returnId",
      link: `/main/${officeId}/store/returns/view/`,
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "DN No.",
      width: "10%",
      isLink: true,
      id: "returnId",
      link: `/main/${officeId}/store/returns/view/`,
      field: "dnRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Type",
      width: "10%",
      field: "type",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Return Date",
      width: "10%",
      field: "returnDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Return To",
      width: "10%",
      field: "returnToName",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"returnId",
        removeValue:"dnRef",
        url:`/returns/`,
        moduleName:"Returns",
        from:`/main/${officeId}/store/returns`,
        editLink:`/main/${officeId}/store/returns/edit/`,
        viewLink:`/main/${officeId}/store/returns/view/`
      }
    },
  ];
  const availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "returnId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: " Return No.",
      width: "10%",
      isLink: true,
      id: "returnId",
      link: `/main/${officeId}/store/returns/view/`,
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "DN No.",
      width: "10%",
      isLink: true,
      id: "returnId",
      link: `/main/${officeId}/store/returns/view/`,
      field: "dnRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Type",
      width: "10%",
      field: "type",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Return Date",
      width: "10%",
      field: "returnDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Return To",
      width: "10%",
      field: "returnToName",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    
    {
      title: "Order Id",
      width: "10%",
      field: "orderRef",
      textAlign: "center",
      rowAlign: "center",
    },
   
    
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"returnId",
        removeValue:"dnRef",
        url:`/returns/`,
        moduleName:"Returns",
        from:`/main/${officeId}/store/returns`,
        editLink:`/main/${officeId}/store/returns/edit/`,
        viewLink:`/main/${officeId}/store/returns/view/`
      }
    },
  ];
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
   
  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }

  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.returnId);
      tempArr.push(i?.returnId)
    });
    setFullList(tempArr)
  },[data])

  const [valueOfSearch,setValuesofSearch] = useState("")
  function searchFunction(e) {

    
    setValuesofSearch(e?.target?.value)
    setParams({ ...params, search: e?.target?.value, page:1 });
    setPage(1)
  }
 
 return (
   <>
   <StoreLayout 
   //  setOpen={closeModal}
   searchFun={searchFunction}
   searchValue={valueOfSearch}
    getData={getData}
    isFilter={filter}
    select={true}

     setFilterOpen={setShowFilterDialog}
     ClearFilter={clearFilter}
     CreateAccess={GetAccess("returns_create")}
    //  ImportAccess={GetAccess("returns_create")}
     officeId={officeId}
     // projectId={projectId}
       >
    <div className="w-full h-full ">
      {/* <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Store",
             
            },
            {
              name: "Returns",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />    <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
          {/* <CreateButton label="Import" /> */}
          {/* {GetAccess("returns_create") && <Link to="/main/${officeId}/store/returns/create">
            <CreateButton label="Returns Items" />
          </Link>}
        </div>
      </div> */}

      <CustomDataTable
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeaders}
         bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        // title='Returns List'
        storageName="ReturnTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="returnId"
        editAccess="returns_edit"      
        deteleAccess="returns_delete" 
        dataLoading={dataLoading}
        searchQuery={params?.search!=""?params?.search:""}

        />
        </div>
        </StoreLayout>
          {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Returns</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Returned Date</p>
                    <div className="flex justify-between  w-full ">
             <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="returnedDateFrom"
              width="w-full "
            />
           <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="returnedDateTo"
              width="w-full "
            />
            </div>
            {/* <FormikSelectTwo
              label="Type"
              placeholder="choose"
              Options={[
                { value: "from_customer", label: "From Customer" },
                { value: "to_vendor", label: "To Supplier" },
              ]}
              formik={formik}
              name="type"
              width="w-full"
            /> */}
                       <FormikSelectTwo
              label="Status"
              placeholder="Choose"
              Options={ReturnStatus}
              formik={formik}
              name="status"
              width="w-full"
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
                              {showEdit && (<MultiEditReturn data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from YPR List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </>
  );
}

export default ReturnsIndex;
