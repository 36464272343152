import React, { useEffect, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import {
  NuDataTime,
  NuDate,
  NuInput,
  NuInputWithError,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo, {
  FormikSelectWithAdd,
  SelectProductWithError,
  SelectWithError,
} from "../../../../Components/Utils/Select";
import { Link, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import UseEditData from "../../../../Components/Hooks/useEditData";
import * as yup from "yup";
import Dashboard from "../../../../Components/Icons/Dasboard";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Delete from "../../../../Components/Icons/Delete";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement, {
  videotype,
} from "../../../../Components/Utils/CustomAttrachmentupload";
import Modal from "../../../../Components/Utils/AttachmentView";
import MultiSelect from "../../../../Components/Utils/MultiSelect";
import EditIcon from "../../../../Components/Icons/Edit";
import Video from "../../../../Components/Icons/Video";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import CloseIcon from "../../../../Components/Icons/Close";
import DropDownAddMetaData, {
  DropDownAddUnitWithError,
} from "../../../../Components/Utils/selectAddMetaData";
import UseProductDropDownNew from "../../../../Components/Hooks/useProductSearch";
import Select from "react-select";
import { GetNotUseRole } from "../../../../Components/Utils/roles";
import DropDownAddCurrency from "../../../../Components/Utils/selectAddCurrencyDropDown";
import UseSupplierDropDownNew from "../../../../Components/Hooks/useSupplierSearch";
import UseFetchCurrency from "../../../../Components/Hooks/useFetchCurrencyData";
import axiosInstance from "../../../../Services/axiosCommon";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "PDF",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function EditPaymentRequest() {
 
  const {officeId} =useParams();

 const { value: Status,getData:refStatus } = useFetchMataData(
  `/dropdown/dropdown-details/`,
  "Status_invoicesIncoming"
);
  
const { value: currency } = UseFetchCurrency("/currency/drop-down");

  const { value: ModeOfPayment, getData: refModeOfPayment } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ModeOfPayment_invoicesIncoming"
  );
  const { data: PR } = UseDropDown('/PR/drop-down');
  const { value: priorityRFQ } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "priority_RFQ"
  );
    const [image, setImage] = useState([]);
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  console.log(location.state, "state");
  const [dataa, setDatas] = useState(data);
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);
   const [combinedItems, setCombinedItems] = useState([]);
  // console.log(items, "kk");
  // console.log(data, "pp");
 
  const [createNew, setCreateNew] = useState({
    bankCharges:data.bankCharges,
    comments:data.comments,
    currency:data.currency,
    prId:data.prId,
    poId:data.poId,
    invoiceId:data.invoiceId,
    totalAmount:data.totalAmount,
    invoiceAmount:data.invoiceAmount,
    modeOfPayment:data.modeOfPayment,
    paidOn:data.paidOn,
    priority:data.priority,
    invoiceRef:data.invoiceRef,
    prRef:data.prRef,
    poRef:data.poRef,
    vendorName:data.vendorName,
    customerName:data.customerName,
    status:data.status,


  });
 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
       prId: yup.string().required("YPR is requred!"),
                 poId: yup.string().required("PO is requred!"),
                 invoiceId: yup.string().required("Invoice Incoming is requred!"),
             }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
    
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        productList: combinedItems,
        removedProducts: remove,
        filesToRemove: fileRemoveList,
      };

      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      editFormData(`/payment-request/${data.id}`, formData, resetForm, from);
      // setLoading(false)
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileExtention, setFileExtention] = useState("");
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);

      setRemoveFilePathList(data?.attachments);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    let Ext = img?.file_name.split(".").reverse()[0];
    setSelectedImage(img);
    setFileExtention(Ext);
    setIsModalOpen(true);
  } 

  const [purchaseOrder,setPurchaseOrder] = useState([]);

  const getPOByPR = () =>{
    axiosInstance
    .get(`/grn/PO/drop-down/${formik?.values?.prId}`)
    .then(res => {
         console.log("v",res?.data);
         let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setPurchaseOrder(tempData);
    }).catch((err)=>{
      console.log(err);
    })
  }
 useEffect(() => {
    if (formik.values.prId) {
      getPOByPR();
    }
    
  }, [formik.values.poId,formik?.values?.prId]);

   useEffect(() => {
      const invoiceAmount = parseFloat(formik.values.invoiceAmount) || 0; 
      const bankCharges = parseFloat(formik.values.bankCharges) || 0;
      const total = invoiceAmount + bankCharges;
      formik.setFieldValue('totalAmount', total.toFixed(2)); 
    }, [formik.values.invoiceAmount, formik.values.bankCharges]);
    

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-end mb-4">
        {/* <Breadcrumb
          items={[
           
            {
              name: "Payment Request",
              link: `/main/${officeId}/procurement/payment-request`,
            },

            { name: "Edit" },
          ]}
        /> */}
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className="w-full h-[calc(100vh-145px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto bg-white rounded-md pb-4 pt-2">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
            {/* <FormikSelectTwo
                type="text"
                label="YPR"
                placeholder="YPR "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                disabled={true}
              /> */}
              {/* <FormikSelectTwo
                type="text"
                label="Purchase Order"
                placeholder="Purchase Order"
                Options={purchaseOrder}
                formik={formik}
                name="poId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                disabled={true}

              /> */}
             
             <NuInput
                             type="text"
                             label="YPR "
                             placeholder=" YPR"
                             formik={formik}
                             name="prRef"
                             width="w-full md:w-1/2 lg:w-1/3"
                             isRequired={true}
                disabled={true}

                           />
                             <NuInput
                             type="text"
                             label="Purchase Order "
                             placeholder=" Purchase Order"
                             formik={formik}
                             name="poRef"
                             width="w-full md:w-1/2 lg:w-1/3"
                             isRequired={true}
                disabled={true}

                           />


              <NuInput
                             type="text"
                             label="Invoice Incoming "
                             placeholder=" Invoice Incoming"
                             formik={formik}
                             name="invoiceRef"
                             width="w-full md:w-1/2 lg:w-1/3"
                             isRequired={true}
                disabled={true}

                           />

                            <NuInput
                                           type="text"
                                           label="Currency "
                                           placeholder=" Currency"
                                           formik={formik}
                                           name="currency"
                                           width="w-full md:w-1/2 lg:w-1/3"
                                           disabled={true}
                                          
                                         />
              {/* <FormikSelectTwo
                Options={currency}
                label="Currency"
                placeholder="Currency"
                formik={formik}
                name="currency"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              <NuInput
                             type="number"
                             label="Invoice Amount"
                             placeholder=" Invoice Amount"
                             formik={formik}
                             name="invoiceAmount"
                             width="w-full md:w-1/2 lg:w-1/3"
                           />
                           <NuInput
                             type="number"
                             label="Bank Charges"
                             placeholder=" Bank Charges"
                             formik={formik}
                             name="bankCharges"
                             width="w-full md:w-1/2 lg:w-1/3"
                           />
                           <NuInput
                             type="number"
                             label="Total Amount"
                             placeholder="Total Amount"
                             formik={formik}
                             name="totalAmount"
                             width="w-full md:w-1/2 lg:w-1/3"
                             disabled={true}
                           />
              <FormikSelectTwo
                label="Mode of Payment"
                placeholder="Choose"
                Options={ModeOfPayment}
                formik={formik}
                name="modeOfPayment"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refModeOfPayment}
                displayProperty={"Mode of Payment"}
                propertyName={"ModeOfPayment_invoicesIncoming"}
              />
            
              <NuDataTime
                label="Paid On"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="paidOn"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Priority"
                placeholder="Choose"
                // disabled={true}
                Options={priorityRFQ}
                formik={formik}
                name="priority"
                width="w-full md:w-1/2 lg:w-1/3"
              />
                <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refStatus}
                displayProperty={"Status"}
                propertyName={"Status_po"}
              />
              <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                width="w-full md:w-1/2 "
              />
<div className="w-full">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Payment Request Attachement :
                </p>
              </div>
              <div className="md:flex justify-between mt-5 px-3 w-full">
                <div className="w-full flex gap-2 items-center flex-wrap">
                  {data?.attachments &&
                    filePathList?.map((imgs, idx) => {
                      console.log("img", imgs);
                      let Ext = imgs?.file_name.split(".").reverse()[0];
                      return (
                        <div
                          className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                          key={idx}
                          style={{
                            border: "1px solid gray",
                            borderColor:
                              "rgb(148 163 184 / var(--tw-bg-opacity))",
                          }}
                        >
                          <div
                            className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                            onClick={() => UpdateAttachmentList(imgs?.file)}
                          >
                            <CloseIcon color="white" height="8" width="8" />
                          </div>
                          <div
                            className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                            onClick={() => handleImageClick(imgs)}
                          >
                            <div className=" w-8 h-8">
                              {videotype.includes(Ext) ? <Video /> : null}

                              {Ext === ("docx" || "doc" || "txt") ? (
                                <Doc />
                              ) : Ext === ("xlsx" || "xls") ? (
                                <Xls />
                              ) : Ext === "txt" ? (
                                <Txt />
                              ) : Ext === "pdf" ? (
                                <Pdf />
                              ) : Ext === "PDF" ? (
                                <Pdf />
                              ) : Ext === "pptx" ? (
                                <>No file</>
                              ) : (
                                <img src={imgs?.file} alt="#" width="120%" />
                              )}
                            </div>
                            <p className=" w-[50px] text-[11px] truncate">
                              {imgs?.file_name}
                            </p>
                          </div>
                        </div>
                      );
                    })}

                  <Attachement
                    handleChange={handleImageChange}
                    preview={image || ""}
                    width="w-full md:w-1/2"
                    label="Attach"
                    multiple={true}
                  />
                </div>
                </div>
                {formik.values.prId  && formik.values.customerName && (
  <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
    <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
      Customer Name
    </p>
    <p className="w-1/2 py-3 pl-5 text-xs text-[#606279]">
      {data?.customerName}
    </p>
  </div>
)}

              {formik.values.poId && formik.values.vendorName &&(
              <>
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Supplier Name
              </p>
              <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                {data?.vendorName}
              </p>
            </div>
            </>)}
              </div>
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                            <div className="md:flex justify-end gap-4">
                              <CancelButton
                                handleClick={() => {
                                  Oncancel(-1);
                                }}
                              />
                              <SubmitButton isLoading={isLoading}/>
                            </div>
                          </div>
            </div>
          </div>

        
        </form>
        <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
      </div>
      
    </div>
  );
}

export default EditPaymentRequest;
