import { Modal, notification } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import axiosInstance from "../../../../../Services/axiosCommon";
import { NuInputfile, NuUpload } from "../../../../../Components/Utils/Input";


const ImportTC = ({ open, onCancel,getData,estmationId}) => {
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [isLoading, setIsLoading] = useState(false);



  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: "",
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true)
      let formData = new FormData();
      // formik.resetForm({ values: "" });
      formData.append("file", image.raw);
      axiosInstance
        .post(`/estimation/TC/${estmationId}/import`,formData)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            setIsLoading(false);
            getData();
            resetForm();
            api.success({
              message: `Import successfully`,
              description: res?.data?.msg || "Added in the list",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            setIsLoading(false);
            api.error({
              message: `Import failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          api.error({
            message: `Import failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });


  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title={"Import TC"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full">
                                 <NuUpload
                                 label="Attachment"
                                 width="w-full "
                                 onChange={handleChangeImg}
                               /> 
                             <div className='flex gap-4 justify-center items-baseline'>
                         <button
                           type="submit"
                           className={ `px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm  rounded-lg mt-4 text-center ${isLoading ? 'bg-red-300 cursor-wait' : 'bg-baseColor-primary'} text-white`}
                           disabled={isLoading}
                         >
                           {isLoading ? 'Importing...' : 'Import'}
                         </button>
                                   <button
                     type="button"
                     onClick={() => { console.log("Cancel button clicked"); onCancel(); }}
                     className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm  text-textColor-main bg-[#E6F2F2] hover:bg-gray-300
                     rounded-md flex items-center`"
                   >
                     Cancel
                   </button> 
                    </div>
                                 </div>
                  
        </form>
      </Modal>
    </>
  );
};

export default ImportTC;
