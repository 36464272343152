import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import Contract from "./Contracts";
import CustomerOrder from "./CustomerOrder";
import PaymentRequest from "./PaymentRequest";
import PurchaseOrder from "./PO";
import PurchaseRequest from "./PurchaseRequest";
import RFQ from "./RFQ";
import Supplier from "./Supplier";
import SupplierQuote from "./SupplierQuotation";


  
  
  const MainProcurementSettings = () =>{
    return(
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Procurement"
            }
          ]}
        />
        <BackButton/>
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
              <div className="p-3"><PurchaseRequest/></div>
              <div className="p-3"><RFQ/></div>

              {/* <div className="p-3"><CustomerOrder/></div> */}
              <div className="p-3"><SupplierQuote/></div>
              <div className="p-3"><PurchaseOrder/></div>
              <div className="p-3"><PaymentRequest/></div>
              <div className="p-3"><Supplier/></div>
              {/* <div className="p-3"><Contract/></div> */}
        </div>
        </div>
    )
  }

  export default MainProcurementSettings;