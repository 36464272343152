import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function EquipmentUnit() {
    return (
        <SettingsMetaData propertyName={"EquipmentUnit"} viewPropertyName={"Unit"} />
      );
}

export default EquipmentUnit
