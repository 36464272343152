import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ViewMore from "../Icons/ViewMore";
import { useDeleteData } from "../Hooks/useDeleteData";
import ModalContainer from "../Others/ModalContainer";
import EditIcon from "../Icons/Edit";
import DeleteIcon from "../Icons/Delete";
import CommentsIcon from "../Icons/comments";
import ViewIcon from "../Icons/ViewIcon";
import Chat from "../Icons/Chat";
import { GetAccess, GetRole } from "./roles";
import Email from "../Icons/Email";
import EmailModal from "./EmailModel";
import ModalEmail from "../Others/ModalForEmail";
import { FaCheckCircle, FaClipboardCheck, FaEye, FaHourglassHalf, FaRegFileAlt, FaRegThumbsUp, FaTimesCircle } from "react-icons/fa";
import EditActivity from "../../Views/Web/Items/Activity/EditActivity";
import { Modal } from "antd";
import ViewActivity from "../../Views/Web/Items/Activity/ViewActivity";
import ViewCondition from "../../Views/Web/Items/TeamandCondition/ViewCondition";
import Project from "../Icons/Project";
import EditTermsCondition from "../../Views/Web/Items/TeamandCondition/EditCondition";

const MoreOptions = ({
  data,
  fetcher,
  id,
  removevalue,
  url,
  moduleName,
  from,
  viewLink,
  editLink,
  projectLink,
  EmailRequired,
  emailEnable,
  isEmailSent,
  EditAccess = "",
  DeteleAccess = "",
  emailType,
  EditNew,
  View,
  adminPermmision,
  curentPage,
  searchQuery,
  getData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
const {officeId} =useParams();
  const { handle } = useDeleteData(fetcher);
  const handleDelete = async () => handle(url + `${id}`, setIsModalOpen);
  let Edit = editLink ? true : null;
  let Comment = editLink ? false : null;

  // let Project = projectLink ? true : null;
  // let projectCondition = projectLink?.includes("{id}") ? true : false;
  // let proLink = projectCondition ? projectLink.replace("{id}", `${id}`) : "";


  let EditCondition = editLink?.includes("{id}") ? true : false;
  let editableLink = EditCondition ? editLink.replace("{id}", `${id}`) : "";

  let Delete = true;
  

  const getStatusIcon = () => {
    if (data?.approvalStatus?.toLowerCase() === "approved") {
      return <FaCheckCircle className="text-green-500" />;
    }
    if (data?.approvalStatus?.toLowerCase() === "pending") {
      return <FaHourglassHalf className="text-yellow-500" />;
    }
    if (data?.approvalStatus?.toLowerCase() === "rejected" || data?.approvalStatus?.toLowerCase() === "declined") {
      return <FaTimesCircle className="text-red-500" />;
    }
    if (data?.approvalStatus?.toLowerCase() === "verified") {
      return <FaClipboardCheck className="text-purple-500 " />;
    }
    if (data?.approvalStatus?.toLowerCase() === "reviewed") {
      return <FaRegFileAlt className="text-indigo-500" />;
    }
    
    
    
    
    return null;
  };
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // View modal state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Edit modal state

  const [isViewConditionModalOpen, setisViewConditionModalOpen] = useState(false); // View modal state
  const [IsEditConditionModalOpen, setIsEditConditionModalOpen] = useState(false); // Edit modal state

  
  const openEditModal = () => {
    setIsEditModalOpen(true);
   
  };

  const openViewModal = () => {
    setIsViewModalOpen(true);
   
  };
  const openConditionEditModal = () => {
  
    setIsEditConditionModalOpen(true)
  };

  const openConditionViewModal = () => {
   
    setisViewConditionModalOpen(true)
  };

  return (
    <>
      <div className=" w-full flex  justify-start items-center">
        <div className=" w-full flex justify-start ">
          
        {moduleName !== "Sales-Orders" && (
  <div className="w-[20%] overflow-hidden flex justify-center items-center">
    {getStatusIcon()}
  </div>
)}

          {EmailRequired && !isEmailSent && !data?.purchaser && emailEnable && (
            <div
              className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <Email height={"3"} width={"3"} color="#BF2EF0" />
            </div>
          )}

{moduleName === "Activity" && (
          <>
            {/* View Icon */}
            <div
              className="w-[25%] overflow-hidden flex justify-center items-center cursor-pointer"
              onClick={openViewModal}
            >
              <ViewIcon color="#048178" />
            </div>

           
              <div
                className="w-[25%] overflow-hidden flex justify-center items-center cursor-pointer"
                onClick={openEditModal}
              >
                <EditIcon color="skyblue" width={"12"} height={"12"} />
              </div>
            
          </>
        )}   
        {moduleName ===  "Terms and Condition" && (
          <>
            {/* View Icon */}
            <div
              className="w-[25%] overflow-hidden flex justify-center items-center cursor-pointer"
              onClick={openConditionViewModal}
            >
              <ViewIcon color="#048178" />
            </div>

           
              <div
                className="w-[25%] overflow-hidden flex justify-center items-center cursor-pointer"
                onClick={openConditionEditModal}
              >
                <EditIcon color="skyblue" width={"12"} height={"12"} />
              </div>
            
          </>
        )}      
         
         {(moduleName !== "Activity" && moduleName !== "Terms and Condition") && (

          <>
                       {moduleName === "Bidding" && (
                                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={`/main/${officeId}/bidding/estimation/${id}/` + projectLink }
                        state={{ data: data, from: from }}
                      >
                        <Project color="red" width={"12"} height={"15"} />
                      </Link>
                    </div>
              )}
          <div className=" w-[25%] overflow-hidden flex justify-center items-center">
            <Link
              className=" text-black hover:text-black border-none"
              to={viewLink + `${id}`}
              state={{ 
                data: data,
                pageIndex:curentPage,
                searchQuery: searchQuery!==""?searchQuery:searchQuery
               }}
            >
              <ViewIcon color="#048178" />
            </Link>
          </div>
          {Edit && (
            <>
              {data?.approvalStatus?.toLowerCase() == "verified" ||
              data?.approvalStatus?.toLowerCase() == "approved" ? (
                <>
                  {GetRole() && (
                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={EditCondition ? editableLink : editLink + `${id}`}
                        state={{ data: data, from: from }}
                      >
                        <EditIcon color="skyblue" width={"12"} height={"12"} />
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {GetAccess(EditAccess) && (
                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={EditCondition ? editableLink : editLink + `${id}`}
                        state={{ data: data, from: from }}
                      >
                        <EditIcon color="skyblue" width={"12"} height={"12"} />
                      </Link>
                    </div>
                  )}
                </>
              )}
            </>
          )}
           

          </>
        )}
        
        
          {Delete && (
            <>
              {data?.approvalStatus?.toLowerCase() == "verified" ||
              data?.approvalStatus?.toLowerCase() == "approved" ? (
                <>
                  {GetRole() && (
                    <div
                      className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {GetAccess(DeteleAccess) && (
                    <div
                      className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {Comment && (
            <div className=" w-[25%] overflow-hidden flex justify-center items-center  cursor-pointer ">
              <Chat width={"11"} height={"11"} />
            </div>
          )}
        </div>
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Warning"
        content={`Are you sure, ${removevalue} will removed from ${moduleName}!`}
        handleClick={() => handleDelete()}
        closeModal={() => setIsModalOpen(false)}
      />
      <ModalEmail
        visiable={modalOpen}
        title=""
        hasContent={true}
        content={
          <>
            <EmailModal
              fetcher={fetcher}
              vendorId={data?.vendor || data?.vendorId}
              id={data?.id}
              url={url}
              idd={id}
              onClose={() => setModalOpen(false)}
              emailType={emailType}
            />
          </>
        }
        // handleClick={() => {}}
        hasButton={false}
        // outSideClick={false}
        closeModal={() => setModalOpen(false)}
      />

      {isEditModalOpen&&(
       <EditActivity
        open={isEditModalOpen}
        data={data}
        onCancel={() => setIsEditModalOpen(false)}
        getData={fetcher}
        firstProject={id}       
      />
    )}


     
      {isViewModalOpen && (       
        <Modal
        open={isViewModalOpen}
        onCancel={()=>setIsViewModalOpen(false)}
        title={"View Activity"}
        width="50%"
        centered
           className="my-modal"
     
        footer={[]}
      >
        {/* <ViewActivity/> */}
        <ViewActivity data={data} open={isViewModalOpen} onCancel={() => setIsViewModalOpen(false)} />
      </Modal>
      )}


{IsEditConditionModalOpen && (
       
       <EditTermsCondition
        open={IsEditConditionModalOpen}
        data={data}
        onCancel={() => setIsEditConditionModalOpen(false)}
        getData={fetcher}
        firstProject={id}       
      />
    )}

{isViewConditionModalOpen && (
<Modal
        open={isViewConditionModalOpen}
        onCancel={()=>setisViewConditionModalOpen(false)}
        title={"View Terms & Conditions"}
        width="50%"
        centered
           className="my-modal"
     
        footer={[]}
      >
        <ViewCondition data={data} open={isViewConditionModalOpen} onCancel={() => setisViewConditionModalOpen(false)} />
      </Modal>
      
    )}
    </>

  );
};
export default MoreOptions;
