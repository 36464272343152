import { useEffect, useState } from 'react'
import axiosInstance from '../../Services/axiosCommon';

function useFetchMetaStarIndex(url,prop,enable) {
    const [value,setValue] = useState([])
    function getData(){
        axiosInstance.get(url+prop).then(res=>{
            let data = res.data?.data[prop];
            let tempData = [];
            function generateStars(value) {
                return '★'.repeat(value) 
              }  
            data.forEach((item) => {
              let tempObject = { value: item?.value, label: generateStars(item?.value) };
              tempData.push(tempObject);
            });
            res.data?.data[prop] && setValue(tempData);
        }).catch(err=>{
            console.log("err");
        })
    }
useEffect(()=>{
    if(enable){
        getData();
    }
},[enable])

return {value,getData}||[]
}

export default useFetchMetaStarIndex;