// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import UseDropDown from "../../../../../../Components/Hooks/useDropDown";
import useAddFormData from "../../../../../../Components/Hooks/useAddFormData";
import { useToast } from "../../../../../../Components/Others/ToastServices";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { filetype } from "../../Tasks/Components/viewFiles";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
import { NuDate, NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
import DeleteIcon from "../../../../../../Components/Icons/Delete";
import Attachement from "../../../../../../Components/Utils/CustomAttrachmentupload";
// import FormikSelectTwo from "../../../../Components/Utils/Select";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
// import {
//   NuDate,
//   NuInput,
//   NuTextArea,
// } from "../../../../Components/Utils/Input";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import axiosInstance from "../../../../Services/axiosCommon";
// import useAddFormData from "../../../../Components/Hooks/useAddFormData";
// import { useToast } from "../../../../Components/Others/ToastServices";
// import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
// import DeleteIcon from "../../../../Components/Icons/Delete";
// const filetype = [
//   "docx",
//   "doc",
//   "xlsx",
//   "xls",
//   "pdf",
//   "txt",
//   "pptx",
//   "mp4",
//   "mov",
//   "wmv",
//   "avi",
//   "flv",
//   "mkv",
// ];

function CreateGRN() {
  const {officeId,projectId} =useParams();

  const currentDate = new Date().toISOString().split("T")[0];
  const { data: deliveryNotes } = UseDropDown("/delivery-notes/drop-down");
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const { data: PR } = UseDropDown("/PR/drop-down");
  const toast = useToast();
  const [image, setImage] = useState([]);
  const [createNew, setCreateNew] = useState({
    prId: "",
    purchaseType: "",
    receivedDate: currentDate || "",
    poId: "",
    vendorId: "",
    products: "",
    comments: "",
    ref: "",
    dnRef: "",
    busyGrnNo: "",
  });

  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("YPR is required!"),
      poId: yup.string().required("Purchase Order is required!"),
      dnRef: yup.string().required("Delivery Note Ref. No is required!"),
      // busyGrnNo: yup.string().required('Busy GRN No. No is required!')
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (polist.length === 0) {
        setError("Product table is empty");
        return;
      }
      polist.forEach(item =>{
        item.receivedQty += item.thisDelivery
        item.remainingQty -= item.thisDelivery
      }
       )
    
      
      value.products = polist;
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(value));
      // formData.append('itemImage', image.raw);
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }

      addFormData("/grn/new-grn", formData, resetForm);
    },
  });

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = polist.filter((_, index) => index !== indexToDelete);
    setPolist(updatedItems);
  };
  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }
  const [purchaseOrder, setPurchaseOrder] = useState([]);

  const getPOByPR = () => {
    axiosInstance
      .get(`/grn/PO/drop-down/${formik?.values?.prId}`)
      .then((res) => {
        console.log("v", res?.data);
        let data = res.data;
        console.log(data, "dddd");
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item.id, label: item.name };
          tempData.push(tempObject);
        });
        res.data && setPurchaseOrder(tempData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [polist, setPolist] = useState([]);

  const getPr = (poId) => {
    if (poId !== "") {
      axiosInstance
        .get(`/grn/po-products/${poId}`)
        .then((res) => {
          let data = res?.data?.products;
          setPolist(data);
          formik.setFieldValue("vendorId", res?.data?.vendorId || "");
          formik.setFieldValue("vendorName", res?.data?.vendorName || "");
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.prId) {
      getPOByPR();
    }
    if (formik.values.poId) {
      getPr(formik.values.poId);
    }
  }, [formik.values.poId, formik?.values?.prId]);

  const handleQuantityChange = (index, newReceivedQty) => {
    const updatedItems = [...polist];
    if (newReceivedQty <= updatedItems[index].orderedQty) {
      updatedItems[index].receivedQty = newReceivedQty;
      updatedItems[index].remainingQty =
        updatedItems[index].orderedQty - newReceivedQty;
    }
    setPolist(updatedItems);
  };

  useEffect(() => {
    if (polist.length > 0) {
      setError("");
    }
  }, [polist]);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      tempArr.push(fileName);
    });
    setImage(tempArr);
  };

  const handleIncrementDelivery = (index, field, ordered, recived) => {
    console.log("delein", index, field);
    console.log("de", ordered - recived);
    setPolist((prevList) => {
      const newList = [...prevList];
      const currentValue = newList[index][field] || 0;

      // Check if incrementing the value exceeds the limit
      if (currentValue < ordered - recived) {
        newList[index][field] = currentValue + 1;
      }
      return newList;
    });
  };

  const handleDecrementDelivery = (index, field) => {
    setPolist((prevList) => {
      const newList = [...prevList];

      if (newList[index][field] > 0) {
        newList[index][field] = (newList[index][field] || 0) - 1;
      }
      return newList;
    });
  };

  const handleInputChangeDelivery = (e, index) => {
    const { value } = e.target;
    setPolist((prevList) => {
      const newList = [...prevList];
      newList[index].thisDelivery = value ? parseInt(value) : 0;
      return newList;
    });
  };

  return (
    <div className="w-full h-full py-2 px-4">
      <div className="flex justify-between">
        {/* <Breadcrumb
          items={[{ name: "GRN", link: `/project/store/grn/${officeId}/${projectId}` }, { name: "Create" }]}
        /> */}
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className="w-full h-full flex justify-start content-start flex-wrap overflow-y-auto">
            <FormikSelectTwo
              type="text"
              label="YPR"
              placeholder="YPR "
              Options={PR}
              formik={formik}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              FixPosition={false}
              PortalTarget={false}
            />
            <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={purchaseOrder}
              formik={formik}
              name="poId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              FixPosition={false}
              PortalTarget={false}
            />
            <NuInput
              type="text"
              label="Supplier"
              placeholder="Supplier"
              formik={formik}
              name="vendorName"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              disabled={true}
            />

            <NuInput
              type="text"
              label="Delivery Notes Ref."
              placeholder="Reference"
              Options={deliveryNotes}
              formik={formik}
              name="dnRef"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <NuInput
              label="Busy GRN No."
              type="text"
              formik={formik}
              placeholder="Busy GRN No."
              name="busyGrnNo"
              width="w-full md:w-1/2 lg:w-1/3"
              // isRequired={true}
            />

            <NuDate
              label="Received Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full">
              <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                GRN Attachement :
              </p>
            </div>

            <Attachement
              handleChange={handleImageChange}
              preview={image || ""}
              width="w-full"
              label="Attach"
              multiple={true}
              removeImages={removeImages}
            />
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-2"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              {error && (
                <p className="text-xs font-semibold text-red-500">{error}</p>
              )}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-4">
                <p className="w-[5%]  text-left  text-white text-xs font-medium">
                  S.N.{" "}
                </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className="w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Ordered Qty
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  Received Qty
                </p>

                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Remaining Qty
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  This Delivery
                </p>
                <p className=" w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {polist?.length > 0 ? (
                <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                  {polist.map((List, index) => (
                    <div
                      key={index}
                      className="w-full py-2 flex justify-between px-4 odd:bg-white even:bg-slate-100"
                    >
                      <p className="w-[5%]  text-left   text-xs font-light py-1">
                        {index + 1}
                      </p>
                      <p className="w-[40%] text-left  text-xs font-light">
                        {List?.product_name}
                      </p>
                      <p className="w-[10%] text-center  text-xs font-light">
                        {List?.product_unit}
                      </p>
                      <p className="w-[15%] text-center  text-xs font-light">
                        {List?.orderedQty}
                      </p>
                      <p className="w-[20%] text-center  text-xs font-light">
                        {List?.receivedQty}
                      </p>

                      <p className="w-[15%] text-center  text-xs font-light">
                        {Number(List?.remainingQty || 0) -
                          Number(List?.thisDelivery || 0)}
                      </p>
                      <div className="w-[20%] text-center  text-xs font-light">
                        <button
                          type="button"
                          className="border rounded-full p-1"
                          onClick={() =>
                            handleDecrementDelivery(index, "thisDelivery")
                          }
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="w-[20%] text-center border  text-xs font-light"
                          name="thisDelivery"
                          value={List.thisDelivery || 0} // Ensure you're accessing the correct value
                          onChange={(e) => handleInputChangeDelivery(e, index)}
                        />
                        <button
                          type="button"
                          className="border rounded-full p-1"
                          onClick={() =>
                            handleIncrementDelivery(
                              index,
                              "thisDelivery",
                              List?.orderedQty,
                              List?.receivedQty
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className="w-[5%] text-center">
                        <button
                          onClick={() => handleDeleteItem(index)}
                          type="button"
                          className="text-red-500 cursor-pointer  ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full text-center text-[#93949f]">
                  No Items here
                </div>
              )}
            </div>
            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateGRN;
