import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import RoleManPower from './Role'
import UnitManPower from './Unit'


function ManPowerProgressIndex() {
    return(
        <>
        <TextMediumSM content="Progress" />
        <TextMediumSM content="Man Power" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <RoleManPower/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <UnitManPower/>
         </div>
         
         </div>
         </>
    )
}

export default ManPowerProgressIndex
