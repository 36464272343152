import React from 'react'
import { Link } from 'react-router-dom'

function OfficeCard({Name,status}) {
  return (
    <div className=' w-full p-2 h-32 2xl:h-48 bg-white rounded-lg shadow-lg border'>
        <div className=' flex justify-between items-center'>
            <p className=' w-[75%] truncate text-sm font-medium'>{Name||"-"}</p>
            <p className={`${status=="Active" ? "bg-green-500":"bg-red-500"} w-[23%] text-center rounded-xl  text-[11px] font-medium text-white px-2 py-[2px]`}>{status}</p>
        </div>
        <div></div>
        <div></div>
    </div>
  )
}

export default OfficeCard