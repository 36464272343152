import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ClearFilterButton, CreateButton, FilterButton, ImportButton } from '../../../Components/Utils/Buttons'
import SearchBar from '../../../Components/Utils/SearchBar'
import Dasboard from '../../../Components/Icons/Dasboard'

import { GetAccess } from '../../../Components/Utils/roles'
import Breadcrumb from '../../../Components/Utils/Breadcrumb'

const ItemBidLayout = (  {
    getData=() => {},
    setSearchQuery=()=>{},
    CreateAccess= false,
    ImportAccess= false,
    setOpen=()=>{},
    children,
    onCreateClick,
    searchFun=()=>{},
    searchValue="",
    isFilter=false,
        setFilterOpen=()=>{},
        newfilter=false,
        ClearFilter=()=>{},
}
) =>{
let {pathname} = useLocation()
let {officeId} = useParams()
const [module,setModule] = useState("")
const navigate = useNavigate();

useEffect(() =>{
    if(pathname == `/main/${officeId}/bidding/bids`){
        setModule("Bidding")
    }else if(pathname == `/main/${officeId}/item/activity`){
        setModule("Activity")
    }else if(pathname == `/main/${officeId}/item/teamandcondition`){
        setModule("Terms & Condition")
    }
    
},[pathname])

let LinkItem = [
    {
        label:"Bidding",
        link:`/main/${officeId}/bidding/bids`,
        access:GetAccess("bids_view"),
    },
    {
        label:"Activity",
        link:`/main/${officeId}/item/activity`,
        access:GetAccess("activity_item_view"),
    },
    {
        label:"Terms & Conditions",
        link:`/main/${officeId}/item/teamandcondition`,
        access:GetAccess("terms_and_condition_view"),
    },
]

const handleCreateClick = () => {
    if (module === "Bidding") {
      navigate(`${pathname}/create`); 
    } else {
      onCreateClick && onCreateClick(); 
    }
  };

return(
    <div className=" w-full h-full py-2 px-4 overflow-hidden">
            
    <div className="md:flex justify-between w-full">
     {/* <Breadcrumb items={[{name:`${module}`}
        ]} /> */}
 <div className=" w-full  flex items-center gap-x-1 mt-1">
        {
            LinkItem && LinkItem.map((item, i) => {
                return(
                    <>
                     {
                        item.access && (
                        <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                            
                            // <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                        )
                     }
                    </>
                )
            })
        }
    </div>
        <div className=" flex justify-end items-center gap-3 ">
        <form className="flex items-center gap-3 mx-0" onSubmit={getData}>
          <SearchBar handleChange={searchFun} value={searchValue} />
          </form>
           {newfilter && (
            <>
                        <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
                        {isFilter&& <ClearFilterButton handleClick={ClearFilter} />} </>)}
                      
          {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}
          {/* {CreateAccess && <Link to={`${pathname}/create`}><CreateButton handleClick={onCreateClick} label={module}/></Link>} */}
              {/* <BackButton/> */}
            {CreateAccess && 
           <CreateButton
           handleClick={handleCreateClick}
           label={module}
           className="create-activity-button"
         />}  
          {/* {module === "Bidding" &&(<>  {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}</>)} */}

            </div>
        </div>
      <div className="w-[100%] h-[calc(100vh-200px)]  ">
       
       
       
        <div className=" w-full h-[calc(100vh-140px)]">
           {children} 
        </div>
        </div>
        </div>
   
)
}
export default ItemBidLayout