// import { useFormik } from "formik";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import UseSearchDropDownNew from "../../../../../../Components/Hooks/UseSearchDropDown";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import useAddFormData from "../../../../../../Components/Hooks/useAddFormData";
import { useFormik } from "formik";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo, { FormikSelectWithoutAdd } from "../../../../../../Components/Utils/Select";
import { NuDate, NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
import DropDownAddMetaData from "../../../../../../Components/Utils/selectAddMetaData";
// import useAddFormData from "../../../../Components/Hooks/useAddFormData";
// import { useMemo, useState } from "react";
// import  { NuDate, NuInput, NuTextArea, } from "../../../../Components/Utils/Input";
// import FormikSelectTwo, { FormikSelectWithoutAdd } from "../../../../Components/Utils/Select";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
// import axiosInstance from "../../../../Services/axiosCommon";
// import UseSearchDropDownNew from "../../../../Components/Hooks/UseSearchDropDown";
// import { useParams } from "react-router-dom";

const CreateAssets = () => {
  const {officeId,projectId} =useParams();

  const currentDate = new Date().toISOString().split('T')[0];
  const [search, setSearch] = useState("")  
  const { data: grn } = UseSearchDropDownNew('/grn/drop-down',search);
  const {value:AssertCondition ,getData:refAssertCondition} = useFetchMataData(`/dropdown/dropdown-details/`,'AssertCondition')
  const {value:AssertStorageLocation ,getData:refAssertStorageLocation} = useFetchMataData(`/dropdown/dropdown-details/`,'AssertStorageLocation')

  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    grnId:"",
    productId:"",
    supplier: "",
    batchNo: "",
     // unit: "",
    warrentyDuration: "",//need
    warrentyApplicable: "",
    warrentyStartDate: currentDate || "",
    warrentyEndDate: currentDate || "",
    condition: "",
    quantity: "",//need=n
    mfdDate: currentDate || "",
    expDate: currentDate || "",
    mrp: "",
    movable:"",
    unitPrice: "",//needn
    purchasedDate: currentDate || "",
    stockLocation: "",
    minStockLevel: "",
    barcode: "",
    issuedQty: "",
    receivedQty: "",
    stockQty: "",
    adjustedQty: "",//need
    reasonForAdjust: "",//need
    calibReq: "",
    calibExp: "",
    comments: ""
    
  
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      grnId: yup.string().required('GRN is required!'),
      productId: yup.string().required('Product is required!'),
      mfdDate:yup.string().required('Manufacture Date is required!'),
      expDate:yup.string().required('Expiry Date is required!')

    }),
    onSubmit: async (value, { resetForm }) => {
      addFormData("/assets/new", value, resetForm);
    },
  });
  const [grnlist, setGrnlist] = useState([]);

  function getGrn(num){
    axiosInstance.get(`/stocks/grn-products/${num}`)
    .then((response) => {
      let tempData =[]
      if(response.data.products){
        let x = response.data.products

        x.map((item) => {
        let y = {
          value: item.productId,
          label: item.product_name,
        }
        tempData.push(y)
        })
          formik.setFieldValue('supplier', response?.data?.supplier || '');

        setGrnlist(tempData)
      }
    }).catch((error) => {})
   }

   useMemo(()=>{
    if (formik.values.grnId !== '') {
      getGrn(formik.values.grnId);
    }
  }, [formik.values.grnId]);




  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        {/* <Breadcrumb
          items={
            [{
              name: "Assets",
              link: `/project/store/assets/${officeId}/${projectId}`
            },
            {
              name: "Create"
            }]
          }
        /> */}
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
           
            <FormikSelectWithoutAdd
              type="text"
              label="GRN"
              placeholder="Choose"
              Options={grn}
              formik={formik}
              name="grnId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              setSearch={setSearch}
              FixPosition={false}
              PortalTarget={false}
            />
             <FormikSelectTwo
              type="text"
              label="Product"
              placeholder="Choose"
              Options={grnlist}
              formik={formik}
              name="productId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                FixPosition={false}
                PortalTarget={false}
            />
             <NuInput
              type="text"
              label="Supplier"
              placeholder="Supplier"
              formik={formik}
              disabled={true}
              name="supplier"
               width="w-full md:w-1/2 lg:w-1/3"
            />
                      
            <NuInput
              type="number"
              label="Batch No"
              placeholder="Batch No"
              formik={formik}
              name="batchNo"
               width="w-full md:w-1/2 lg:w-1/3"
            />

              <FormikSelectTwo
              label="Warrenty Applicable"
              placeholder="Warrenty Applicable"
              Options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              formik={formik}
              name="warrentyApplicable"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
            />
            {formik.values.warrentyApplicable === true && (
              <NuDate
              label="Warrenty Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyStartDate"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            )}
            {formik.values.warrentyApplicable ===true && (
              <NuDate
              label="Warrenty End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyEndDate"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            )}

             <DropDownAddMetaData
                label="Condition"
                placeholder="Choose"
                Options={AssertCondition}
                formik={formik}
                name="condition"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refAssertCondition}
                displayProperty={"Condition"}
                propertyName={"AssertCondition"}
              />
                      
            <NuDate
              label="Manufacture Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="mfdDate"
              isRequired={true}
               width="w-full md:w-1/2 lg:w-1/3" 
            />
            <NuDate
              label="Expiry Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="expDate"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="MRP"
              placeholder="MRP"
              formik={formik}
              name="mrp"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Movable"
              placeholder="Choose"
              Options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              formik={formik}
              name="movable"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
            />
          
            <NuDate
              label="Purchased Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="purchasedDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Stock Location"
                placeholder="Choose"
                Options={AssertStorageLocation}
                formik={formik}
                name="stockLocation"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refAssertStorageLocation}
                displayProperty={"Stock Location"}
                propertyName={"AssertStorageLocation"}
              />

            <NuInput
              type="number"
              label="Min Stock Level"
              placeholder="Min Stock Level"
              formik={formik}
              name="minStockLevel"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Barcode"
              placeholder="Barcode"
              formik={formik}
              name="barcode"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Issued Quantity"
              placeholder="Issued Qty"
              formik={formik}
              name="issuedQty"
                width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuInput
              type="number"
              label="Received Quantity"
              placeholder="Received Qty"
              formik={formik}
              name="receivedQty"
               width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Stock Quantity"
              placeholder="Stock Qty"
              formik={formik}
              name="stockQty"
                width="w-full md:w-1/2 lg:w-1/3"
            />           
            <FormikSelectTwo
              label="Calibration Reqiure"
              placeholder="Choose"
              Options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              formik={formik}
              name="calibReq"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            {formik.values.calibReq === true && (
              <NuDate
              label="Calibration Expiry"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="calibExp"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            )}
                     
             <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
            />

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAssets;
