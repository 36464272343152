import React, { useEffect, useState } from 'react'
import { Dough } from '../../../../Components/Utils/Doughnut';
import DoughnutTwo from '../../../../Components/Utils/DoughnutTwo';
import { TextXL } from '../../../../Components/Text/NormalText';
import axiosInstance from '../../../../Services/axiosCommon';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

const Project = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  
  async function getDashboard() {
    await axiosInstance
      .get(`/Dashboard/project/status/count`)
      .then(async (res) => {
        (await res?.data?.totalProjects) &&
          setTotalCount(res?.data?.totalProjects);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getDashboard();
   
  }, []);
    function getStatus(data) {
        let returnValue = {
          label: [],
          data: [],
        };
        let X = [];
        let Y = [];
        data &&
          data.forEach((item) => {
            X.push(item.statusName);
            Y.push(item.statusCount);
          });
    
        returnValue.label = X;
        returnValue.data = Y;
        let sum = Y.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        // return sum;
        if (sum != 0) {
          return (
            <div className=" cursor-pointer">
              <Dough dataWithLabel={returnValue} />
            </div>
          );
        } else {
          return <DoughnutTwo />;
        }
      }
      const statusOrder = [
        "Yet to start",
        "On hold",
        "In progress",
        "Cancelled",
        "Completed"
      ];

   const { value: projectStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "projectStatus"
  );
  projectStatus.sort(function(a, b) {
    let statusA = Object.values(a)[0];
    let statusB = Object.values(b)[0];
  
    return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
  });

  return (
    <div className="bg-white p-4 rounded-lg drop-shadow-lg h-[45vh]  2xl:h-[35vh] w-full">
        <div className='flex justify-around'>
 <div
              className=" text-center  "
            //   style={{ borderRight: "1px solid #ECEBF3", paddingRight: "5px" }}
            >
              <h2 className='text-center font-semibold text-[#4F4768] text-sm'>Project</h2> 
              <p className="text-[48px] font-semibold leading-none mt-4 text-[#3B2DB2]">
                {totalCount || "0"}
              </p>
              {/* <p className='text-[15px]  font-medium text-[#555E6C]'></p> */}
            </div>
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

            <div className=" h-full flex justify-center items-center  mt-1">
              <div className=" mx-auto w-[50%]">{getStatus(data)}</div>
            </div>
            </div>
            <div className=" w-full col-span-2">
              <div className=" w-full grid grid-cols-2 gap-x-4 gap-y-1">
                {projectStatus &&
                  projectStatus.map((item, index) => {
                    let value = data.find(i=>i.statusName == item.label)
                    // console.log("ss",value?.statusCount);
                    return (
                      <TempComponent
                        title={item?.label || "-"}
                        value={value?.statusCount || 0}
                        bgColor={
                          item?.label.toLowerCase() == "yet to start" ?"bg-[#FFEAB5]"
                          : item?.label.toLowerCase() == "on-hold" ||
                          item?.label.toLowerCase() == "on-holed" ||
                          item?.label.toLowerCase() == "on hold" ||
                          item?.label.toLowerCase() == "on holded"
                        ? "bg-[#F101011A]"

                            : item?.label.toLowerCase() == "completed" ||
                              item?.label.toLowerCase() == "complete" ||
                              item?.label.toLowerCase() == "finish" ||
                              item?.label.toLowerCase() == "finished"
                            ? "bg-[#00CC5E1A]"
                            :
                            item?.label.toLowerCase() == "in progress" ||
                            item?.label.toLowerCase() == "in-progress" ||
                            item?.label.toLowerCase() == "on-going" ||
                            item?.label.toLowerCase() == "on-going" ||
                            item?.label.toLowerCase() == "active"
                              ? "bg-[#007BEC1A]"
                            : item?.label.toLowerCase() == "cancelled" ||
                              item?.label.toLowerCase() == "cancel" ||
                              item?.label.toLowerCase() == "rejected" ||
                              item?.label.toLowerCase() == "reject"
                            ? "bg-[#F35B041A]"
                            : "bg-[#007BEC1A]"
                        }
                        textColor={
                          
                          item?.label.toLowerCase() == "yet to start" ?"text-[#E8A700]"
                          :
                          item?.label.toLowerCase() == "in progress" ||
                          item?.label.toLowerCase() == "in-progress" ||
                          item?.label.toLowerCase() == "on-going" ||
                          item?.label.toLowerCase() == "on-going" ||
                          item?.label.toLowerCase() == "active"
                            ? "text-[#007BEC]"
                            : item?.label.toLowerCase() == "completed" ||
                              item?.label.toLowerCase() == "complete" ||
                              item?.label.toLowerCase() == "finish" ||
                              item?.label.toLowerCase() == "finished"
                            ? "text-[#00CC5E]"
                            : item?.label.toLowerCase() == "on-hold" ||
                              item?.label.toLowerCase() == "on-holed" ||
                              item?.label.toLowerCase() == "on hold" ||
                              item?.label.toLowerCase() == "on holded"
                            ? "text-[#F10101]"
                            : item?.label.toLowerCase() == "cancelled" ||
                              item?.label.toLowerCase() == "cancel" ||
                              item?.label.toLowerCase() == "rejected" ||
                              item?.label.toLowerCase() == "reject"
                            ? "text-[#F35B04]"
                            : "text-[#007BEC]"
                        }
                        key={index}
                      />
                    );
                  })}
              </div>
            </div></div>
  )
}

export default Project

function TempComponent({ bgColor, textColor, value, title }) {
    return (
      <div
        className={`w-full ${bgColor} rounded-[4px] flex justify-between item-center py-[2px] px-2`}
      >
        <div className="text-[13px] text-[#4F4768] flex items-center">{title}</div>
        <div className={`text-[20px] font-semibold ${textColor} flex items-center`}>
          {value || "0"}
        </div>
      </div>
    );
  }