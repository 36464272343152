import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Mainroutes from './main.routes';
import ToastProvider from './Components/Others/ToastProvider';
import "./i18n"
import { CurrencyProvider } from './Context/CurrencyContext';
import { ToastContainer } from 'react-toastify';
import { SocketProvider } from './Context/SocketContext';
import { NumberProvider } from './Context/ProjectIdContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <SocketProvider>
  <ToastProvider>
      <ToastContainer position="top-right" autoClose={3000} stacked />
    <BrowserRouter>
    <CurrencyProvider>
      <NumberProvider>
      <Mainroutes />
      </NumberProvider>
    </CurrencyProvider>
    </BrowserRouter>
    </ToastProvider>
  </SocketProvider>
  </>
  // <React.StrictMode>
  // <ToastContainer position="top-right" stacked >
    // </ToastContainer>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
