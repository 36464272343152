import React, { useEffect, useState } from 'react'
import ViewMoreTeam from './ViewMoreTeam';
import { Link, NavLink, useParams } from 'react-router-dom';
import { CreateButton } from '../../../../../Components/Utils/Buttons';
import Dasboard from '../../../../../Components/Icons/Dasboard';
import axiosInstance from '../../../../../Services/axiosCommon';
import CreateTeam from './CreateTeam';

import VendorForm from './VedorForm';
import VendorCard from './VendorCardnew';
import Breadcrumb from '../../../../../Components/Utils/Breadcrumb';
import { TextSM } from '../../../../../Components/Text/NormalText';
import NuAvatarGroupWithOutLink from '../../../../../Layouts/NuAvatarGroupWithoutLink';


const Index = () => {
  const {officeId}=useParams();
    const [open, setOpen] = useState(false);
    const [openSub, setOpenSub] = useState(false);
    const [teamData, setTeamData] = useState([]);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [overAllCount, setOverAllCount] = useState(0);
    const [loading, setLoading] = useState(true);
  
    const [subConList,setSubConList] = useState([])
  
    async function getTeamData() {
      await axiosInstance
        .get(`/Team/`)
        .then(async (res) => {
          console.log("Teams", res?.data?.data);
          (await res?.data?.data) && setTeamData(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    // async function getCountData() {
    //   await axiosInstance
    //     .get("/Team/all-counts")
    //     .then((response) => {
    //       let apiData = response?.data?.data || [];
    //       // console.log("apiData", apiData["teamDetails"]);
    //       setAllData(apiData["teamDetails"]);
    //       const teamCountKey = "team_count";
    //       const teamCountValue = apiData[teamCountKey];
    //       setOverAllCount(teamCountValue);
    //       const otherKeys = Object.keys(apiData["teamDetails"]).filter(
    //         (key) => key !== teamCountKey
    //       );
    //       setData(otherKeys);
    //       // console.log("ccc", otherKeys);
    //       setLoading(false);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching data:", error);
    //       setLoading(false);
    //     });
    // }


async function getCountData() {
  try {
    const response = await axiosInstance.get("/Team/all-counts");
    const apiData = response?.data?.data || {};
    const teamCount = apiData?.teamCount || 0; 
    setOverAllCount(teamCount); 
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

  
    useEffect(() => {
      // getTeamData();
      // getCountData();
      // // getCountData();
      getAllSubContract();
      getAllData();
    }, []);
    
    function getAllData() {
      getTeamData();
      getCountData();
    }
  
  function getAllSubContract(){
    axiosInstance.get('/DropDown/all_sub_contracts').then((res)=>{
      console.log("data",res?.data);
      let data = res?.data?.data||[]
      let finalArr = []
      res?.data?.data && data.forEach(item=>{
        let temp = {
          value:item?.vendorId,
          label:item?.companyName
        }
        finalArr.push(temp)
      })
      setSubConList(finalArr)
    }).catch(err=>{
      console.log("error",err);
    })
  }
  
    return (
      <div className="w-full h-[89vh] px-4 py-2 overflow-hidden">
        <div className="md:flex justify-between w-full">
        <Breadcrumb
          items={[
            // {
            //   name: "Office",
            // },
            {
                name: "Team",
              },

          ]}
        />
  
          <div className=" pb-1 flex justify-end items-center ">
            <div className=" flex justify-end items-center gap-3 mr-6">
              {/* {TeamsCreateAccess && ( */}
                <div className=" flex items-center gap-2">
                  <CreateButton label="Team" handleClick={() => setOpen(true)} />
                  <CreateButton label="Sub-Con" handleClick={() => setOpenSub(true)} />
                </div>
              {/* )} */}
              {/* <BackButton /> */}
            </div>
          </div>
        </div>
  
  <div className=" w-full h-[86vh] overflow-y-auto"> 
  
  <div className="  ">
          <p className="text-[#231942] font-semibold">Over All</p>
          <div className=" w-full  overflow-hidden p-2">
            <div className="flex flex-wrap gap-4 h-full">
              <div className="bg-baseColor-primary w-[128px] h-[80px] mt-3 rounded-[7px] shadow-lg shadow-[0px 4px 10px 0px]">
                <p className="text-[14px] font-semibold text-white pt-1 text-center">
                  Team Count
                </p>
                <p className=" text-[24px] font-semibold text-center text-white mt-2">
                  {overAllCount || 0}
                </p>
              </div>
              {data &&
                data.map((key, index) => (
                  <div
                    key={index}
                    className=" w-[128px] h-[80px] mt-3 px-2  rounded-[7px] shadow-lg shadow-[0px 4px 10px 0px]"
                  >
                    <p className="text-[12px] text-[#4F4768] line-clamp-1 text-center font-semibold pt-1">
                      {key.charAt(0).toUpperCase() +
                        key.slice(1).replace("_", " ")}
                    </p>
                    <p className="text-[20px] text-[#4F4768] text-center font-semibold  mt-2">
                      {allData[key]}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="w-full flex justify-between h-full  items-center  ">
            {/* <h4 className="text-base font-bold">Team</h4> */}
            <p className="text-[#231942] font-semibold">Teams</p>
  
            <CreateTeam
              open={open}
              onCancel={() => {
                setOpen(false);
              }}
              getData={getTeamData}
              getCountData={getCountData}
            />
            <VendorForm
              open={openSub}
              onCancel={() => {
                setOpenSub(false);
              }}
              getData={getTeamData}
              getCountData={getCountData}
              subArrList = {subConList}
            />
          </div>
        </div>
        <div className=" w-full h-full">
          {teamData &&
            teamData.map((i, idx) => {
              console.log("dd", i);
              return (
                <div key={i.idx}>
                  <h3 className="text-[#4F4768] text-[12px] my-3 font-semibold">
                   Project : {i?.projectName || "-"}
                  </h3>
                  {i?.teamList && i?.teamList.length > 0 && <p className=" text-xs font-medium my-2">Teams : {i?.teamList.length||0}</p>}
                  
                  <div className="mb-4 pb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3  ">
                    {i?.teamList &&
                      i?.teamList.map((items, idx) => {
                        // console.log("ddd",items);
                        return (
                          <TeamBox data={items} getData={getAllData} getCountData={getCountData} key={idx} />
                        );
                      })}
                  </div>
                  {i?.vendorList && i?.vendorList.length >0 && <p className=" text-xs font-medium my-2">Sub-Contract : {i?.vendorList.length||0}</p>}
                  
                  <div className="mb-4 pb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3  ">
                    {i?.vendorList &&
                      i?.vendorList.map((items, idx) => {
                        // console.log("ddd",items);
                        return (
                          <VendorCard data={items} subArrList={subConList} getData={getAllData} getCountData={getCountData} key={idx} />
                          // <TeamBox data={items} getData={getAllData} getCountData={getCountData} key={idx} />
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
  </div>
      </div>
    );
  };
  
  

export default Index

function TeamBox({ data, getData,getCountData }) {
    // console.log("data",data);
    // const [openMember, setOpenMember] = useState(false);
  const {officeId}=useParams();
  
  let list =
      data?.memberList.map((i) => {
        return i.userId;
      }) || [];
    let teamId = data?.teamId;
    let projectId = data?.projectId;
    
  
    console.log("d",projectId);
  
    return (
      <>
        {/* {data?.data?.map((data) => ( */}
  
        <div className="shadow-[0px_2px_10px_rgba(38,48,75,0.15)] rounded-[10px] p-3 bg-white" style={{border:'1px solid #dedede'}}>
          <div className=" flex justify-between ">
            <div className=" w-[95%]">
              <div className=" w-full flex justify-between items-center">
              <NavLink className="" to={`/project/team/${officeId}/${projectId}/view`} state={{ data: data }}>
               <p className=" text-mainColor-400  line-clamp-1 font-semibold text-[12px] lg:text-[12px] xl:text-[12px]">{data?.teamName||"-"}</p>
              </NavLink>
  
              <div className=" flex justify-center items-center font-medium w-6 h-5 bg-slate-300 text-xs rounded-md">
              {data?.membersCount || 0}
              </div>
              </div>
              <span className=" my-1 flex items-center">
                <TextSM color="text-[#9DA3AA]" text={"Lead : "} />
                <span>&nbsp;&nbsp;</span>
                {data?.leadPerson &&
                  data?.leadPerson.map((name, idx) => {
                    return (
                      <TextSM
                        text={`${name?.firstName || ""} ${name?.lastName || ""}`}
                        key={idx}
                      />
                    );
                  })}
              </span>
            </div>
            <ViewMoreTeam
              data={data}
              getData={getData}
              teamId={teamId}
              list={list || []}
              projectId={data?.projectId}
              getCountData={getCountData}
            />
          </div>
          <div className=" w-full h-24 p-2 bg-gray-100 rounded-md">
            <div className=" flex justify-end">
              {/* <TextTinyNormal
                color="text-[#9DA3AA]"
                text={`${1} Lead ${data?.membersCount || 0}  Employees `}
              /> */}
              {/* {TeamsEditAccess && ( */}
                <span
                  // onClick={() => setOpenMember(true)}
                  className="text-[12px] rounded-lg font-bold cursor-pointer text-mainColor-500 "
                >
                  <Link
                    to={`/project/team/${officeId}/${projectId}/create`}
                    state={{
                      teamId: teamId,
                      memberList: list,
                      projectId: projectId,
                    }}
                  >
                    + Member
                  </Link>
                </span>
              {/* )} */}
            </div>
            <div className=" flex items-end my-4">
              <NuAvatarGroupWithOutLink link={true} userdetails={data.memberList} />
            </div>
          </div>
        </div>
      </>
    );
  }
  