import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Navbar, SideBar } from "./Layouts/MainPage";
import { SidebarItem } from "./Layouts/MainPage/SideBar";
import { useTranslation } from "react-i18next";
import Dashboard from "./Components/Icons/Dasboard";
import Task from "./Components/Icons/Task";
import Maintanance from "./Components/Icons/Maintanance";
import Products from "./Components/Icons/Products";
import Marketing from "./Components/Icons/Marketing";
import Procurement from "./Components/Icons/Procurement";
import Team from "./Components/Icons/Team";
import Report from "./Components/Icons/Report";
import Chat from "./Components/Icons/Chat";
import Payments from "./Components/Icons/Payments";
import Sales from "./Components/Icons/Sales";
import Store from "./Components/Icons/Store";
import Shipping from "./Components/Icons/Shipping";
import { GetAccess, GetDashboardRole, GetMultipleAccess, GetRole } from "./Components/Utils/roles";
import DashboardSide from "./Components/Icons/DasboardSide";
import Items from "./Components/Icons/Items";
import Bidding from "./Components/Icons/Bidding";
import Project from "./Components/Icons/Project";
import Notes from "./Components/Icons/Notes";
import Members from "./Components/Icons/Member";
import Contacts from "./Components/Icons/Contacts";

const App = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {officeId} = useParams();


    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
      if(event.key === "Escape") {
        navigate(-1);
      }
    }, []);
  
    useEffect(() => {
      // attach the event listener
      document.addEventListener('keydown', handleKeyPress);
  
      // remove the event listener
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [handleKeyPress]);


  function isActiveFun(x){
    let actualPath = location.pathname.split("/")[1]
    let linkPath = ""
    if(x?.hasSubMenu){
      if(x?.subMenu){
         let y = x.subMenu[0].link;
         let z = y.split("/")[0]
         linkPath = z
      }
    }else{
      linkPath = x?.link;
    }
    if(actualPath == linkPath) {
      return true;
    }else{
      return false;
    }
  }


  const navItem = [
    {
      icon: <DashboardSide/>,
      title: "Dashboard",
      hasSubMenu: false,
      hasAccess:true,
      link: GetDashboardRole("")?"dashboard":GetDashboardRole("sales")?`/main/${officeId}/dashboard/sales`:GetDashboardRole("procurement")?`/main/${officeId}/dashboard/procurement`:GetDashboardRole("store")?`/main/${officeId}/dashboard/stores`:`/main/${officeId}/dashboard`,
    },
    // {
    //   /*dashboard*/
    //   link: "/main/dashboard",
    //   icon: (
    //     <>
    //       <Dashboard />
    //     </>
    //   ),
    //   title: "Dashboard",
    //   access: true,
    //   hasSubMenu: false,
    //   subMenu: [],
    // },
     {
      icon: <Products/>,
      title: "Product",
      link: `/main/${officeId}/products/items`,
      hasSubMenu: false,
      // hasAccess:["catalogue_view","items_view"]
      subMenu: [
        // {
        //   name: "Catalogues",
        //   link: "products/catalogues",
        // },
        // {
        //   name: "Items",
        //   link: "products/items",
        // },
      ],
    },
    {
      link:GetAccess("bids_view")?`/main/${officeId}/bidding/bids`:GetAccess("estimation_view")?`/main/${officeId}/bidding/estimation`:GetAccess("activity_item_view")?`/main/${officeId}/item/activity`:GetAccess("terms_and_condition_view")?`/main/${officeId}/item/teamandcondition`:"#" ,
      icon: (
        <>
          <Bidding />
        </>
      ),
      title: "Bidding",
      access: GetAccess("bidding_module"),
      hasSubMenu: false,
      subMenu: [],
    },
    // {
    //   // link: GetAccess("activity_item_view")?`/main/${officeId}/item/activity`:GetAccess("terms_and_condition_view")?`/main/${officeId}/item/teamandcondition`:"#",
    //   icon:<Items />,
    //   title: "Item",
    //   hasAccess: ["terms_and_condition_view","activity_item_view"],
    //   hasSubMenu: false,
    //   link: `/main/${officeId}/item/activity`,
    //   subMenu: [
        // {
        //   name: "Activity",
        //   link: ``,
        //   hasAccess:'activity_item_view'
        // },
        // {
        //   name: "Terms & Condition",
        //   link: `/main/${officeId}/item/teamandcondition`,
        //   hasAccess:'terms_and_condition_view'

        // },
    //   ],
    // },
   
    {
      link:GetAccess("project_view") ?`/main/${officeId}/projects`:"#",
      icon: (
        <>
          <Project />
        </>
      ),
      title: "Projects",
      access: GetAccess("project_module"),
      hasSubMenu: false,
    },
    {
      link: GetAccess("my_diary_view")?`/main/${officeId}/mainNotes`:"#",
      icon: (
        <>
          <Notes />
        </>
      ),
      title: "My Diary",
      access: GetAccess("my_diary_module"),
      hasSubMenu: false,
      subMenu: [],
    },
   
    {
      icon: <Procurement/>,
      // link:GetAccess("purchase_request_view")?`/main/${officeId}/procurement/purchase-requests`:GetAccess("rfq_view")?`/main/${officeId}/procurement/rfq`:GetAccess("quote_compare_view")?`/main/${officeId}/procurement/quote-comparison`:GetAccess("purchase_order_view")?`/main/${officeId}/procurement/purchase-order`:GetAccess("payment_request_view")?`/main/${officeId}/procurement/payment-request`:GetAccess("suppliers_view")?`/main/${officeId}/procurement/product-comparison`:GetAccess(GetDashboardRole("management"))?`/main/${officeId}/procurement/team-performance`:"#",
      link:GetAccess("purchase_request_view")?`/main/${officeId}/procurement/purchase-requests`:GetAccess("suppliers_view")?`/main/${officeId}/procurement/product-comparison`:GetAccess("rfq_view")?`/main/${officeId}/procurement/rfq`:GetAccess("purchase_order_view")?`/main/${officeId}/procurement/purchase-order`:GetAccess("payment_request_view")?`/main/${officeId}/procurement/payment-request`:"#",
      title: "Procurement",
      hasAccess:["purchase_request_view","rfq_view","supplier_quote_view","quote_compare_view","purchase_order_view","suppliers_view"],
      hasSubMenu: false,
      subMenu: [
        // {
        //   name: "YPR",
        //   link: `/main/${officeId}/procurement/purchase-requests`,
        //   hasAccess:'purchase_request_view'
        // },
        // {
        //   name: "RFQ",
        //   link: `/main/${officeId}/procurement/rfq`,
        //   hasAccess:'rfq_view'

        // },
        // {
        //   name: "Quote Comparison",
        //   link: `/main/${officeId}/procurement/quote-comparison`,
        //   hasAccess:'quote_compare_view'

        // },
        // {
        //   name: "Purchase Order",
        //   link: `/main/${officeId}/procurement/purchase-order`,
        //   hasAccess:'purchase_order_view'

        // },
        // {
        //   name: "Contracts",
        //   link: "procurement/contracts",
        // },
        // {
        //   name: "Supplier",
        //   link: `/main/${officeId}/procurement/suppliers`,
        //   hasAccess:'suppliers_view'

        // },
        // {
        //   name: "Payment Request",
        //   link: `/main/${officeId}/procurement/payment-request`,
        //   hasAccess:'payment_request_view'
        // },
        // {
        //   name: "Product Comparison",
        //   link: `/main/${officeId}/procurement/product-comparison`,
        //   hasAccess:'suppliers_view'

        // },
        // {
        //   name: "Team Performance",
        //   link: `/main/${officeId}/procurement/team-performance`,
        //   hasAccess: GetDashboardRole("management")

        // }
      ],
    },
   
    {
      icon: <Sales/>,
      // link:GetAccess("customer_quote_view")?`/main/${officeId}/sales/quotes`:GetAccess("sales_order_view")?`/main/${officeId}/sales/sales-orders`:GetAccess("invoice_in_view")?`/main/${officeId}/sales/invoice_incoming`:GetAccess("invoice_out_view")?`/main/${officeId}/sales/invoice_outgoing`:"#",
      link:GetAccess("invoice_in_view")?`/main/${officeId}/sales/invoice_incoming`:GetAccess("invoice_out_view")?`/main/${officeId}/sales/invoice_outgoing`:"#",

      title: "Invoices",
      hasAccess:["customers_view","customer_quote_view","customer_order_view","sales_order_view","invoice_in_view","invoice_out_view","promotions_view"],
      hasSubMenu: false,
      subMenu: [
        // {
        //   name: "Customer",
        //   link: `/main/${officeId}/sales/customers`,
        //   hasAccess:'customers_view'

        // },
        // {
        //   name: "Quote",
        //   link: `/main/${officeId}/sales/quotes`,
        //   hasAccess:'customer_quote_view'

        // },
        // {
        //   name: "Customer Order",
        //   link: "sales/customer-order",
        //   hasAccess:'customer_order_view'

        // },
        // {
        //   name: "Sales Order",
        //   link: `/main/${officeId}/sales/sales-orders`,
        //   hasAccess:'sales_order_view'

        // },
        // {
        //   name: "Invoices Incoming",
        //   link: `/main/${officeId}/sales/invoice_incoming`,
        //   hasAccess:'invoice_in_view'

        // },
        // {
        //   name: "Invoices Outgoing",
        //   link: `/main/${officeId}/sales/invoice_outgoing`,
        //   hasAccess:'invoice_out_view'

        // },
        // {
        //   name: "Promotion",
        //   link: "sales/promotions",
        //   hasAccess:'promotions_view'

        // },
      ],
    },
    // {
    //   icon: <Payments/>,
    //   link:GetAccess("payables_view")?`/main/${officeId}/payments/payables`:GetAccess("receivables_view")?`/main/${officeId}/payments/receivables`:"#",
    //   title: "Payment",
    //   hasAccess:["payables_view","receivables_view"],
    //   hasSubMenu: false,
    //   subMenu: [
        // {
        //   name: "Payable",
        //   link: `/main/${officeId}/payments/payables`,
        //   hasAccess:'payables_view'

        // },
        // {
        //   name: "Receivable",
        //   link: `/main/${officeId}/payments/receivables`,
        //   hasAccess:'receivables_view'

        // },
        // {
        //   name: "Refunds",
        //   link: "payments/refunds",
        // },
    //   ],
    // },
    // {
    //   icon: <Task/>,
    //   title: "Tasks",
    //   hasSubMenu: true,
    //   subMenu: [
    //     {
    //       name: "My Tasks",
    //       link: "tasks/all-tasks",
    //     },
    //     {
    //       name: "All Tasks",
    //       link: "tasks/my-tasks",
    //     },
    //   ],
    // },
    // {
    //   icon: <Store/>,
    //   title: "GRN",
    //   hasSubMenu: false,
    //   link: "store/grn",
    //   hasAccess: ["grn_view",]
    // },
    {
      icon: <Store/>,
      title: "Store",
      // link:GetAccess("grn_view")?`/main/${officeId}/store/grn`:GetAccess("inventory_view")?`/main/${officeId}/store/inventory`:GetAccess("assets_view")?`/main/${officeId}/store/assets`:GetAccess("delivery_notes_view")?`/main/${officeId}/store/delivery-note`:GetAccess("packing_list_view")?`/main/${officeId}/store/packing-list`:GetAccess("returns_view")?`/main/${officeId}/store/returns`:GetAccess("requests_view")?`/main/${officeId}/store/requests`:"#",
      link:GetAccess("shipment_tracking_view")?`/main/${officeId}/store/shipment-tracking`:GetAccess("grn_view")?`/main/${officeId}/store/grn`:GetAccess("inventory_view")?`/main/${officeId}/store/inventory`:GetAccess("assets_view")?`/main/${officeId}/store/assets`:GetAccess("returns_view")?`/main/${officeId}/store/returns`:GetAccess("requests_view")?`/main/${officeId}/store/requests`:"#",

      hasAccess:["shipment_tracking_view","grn_view","inventory_view","assets_view","delivery_notes_view","packing_list_view","returns_view","requests_view"],
      hasSubMenu: false,
      subMenu: [
        // {
        //   name: "GRN",
        //   link: `/main/${officeId}/store/grn`,
        //   hasAccess:'grn_view'

        // },
        // {
        //   name: "Inventory",
        //   link: `/main/${officeId}/store/inventory`,
        //   hasAccess:'inventory_view'

        // },
        // {
        //   name: "Asset",
        //   link: `/main/${officeId}/store/assets`,
        //   hasAccess:'assets_view'

        // },       
        // {
        //   name: "Delivery Note",
        //   link: `/main/${officeId}/store/delivery-note`,
        //   hasAccess:'delivery_notes_view'

        // },
        // {
        //   name: "Packing List",
        //   link: `/main/${officeId}/store/packing-list`,
        //   hasAccess:'packing_list_view'

        // },
        // {
        //   name: "Return",
        //   link: `/main/${officeId}/store/returns`,
        //   hasAccess:'returns_view'

        // },
        // {
        //   name: "Request",
        //   link: `/main/${officeId}/store/requests`,
        //   hasAccess:'requests_view'

        // },
      ],
    },
    {
      link:GetAccess("employees_view")?`/main/${officeId}/employees`:"#", // GetAccess("rental_manpower_view")?"/main/contacts/rental-man":GetAccess("vendor_view")?"/main/contacts/vendor":GetAccess("client_view")?"/main/contacts/clients":GetAccess("sub_contractor_view")?"/main/contacts/sub-con":"#",
      icon: (
        <>
          <Members />
        </>
      ),
      title: "Employees",
      access: GetAccess("contacts_module"),
      hasSubMenu: false,
      subMenu: [],
      
    },
    {
      // link:GetAccess("rental_manpower_view")?`/main/${officeId}/contacts/rental-man`:GetAccess("vendor_view")?`/main/${officeId}/contacts/suppliers`:GetAccess("customers_view")?`/main/${officeId}/contacts/customers`:GetAccess("sub_contractor_view")?`/main/${officeId}/contacts/sub-con`:"#",
      link:GetAccess("customers_view")?`/main/${officeId}/contacts/customers`:GetAccess("sub_contractor_view")?`/main/${officeId}/contacts/sub-con`:GetAccess("vendor_view")?`/main/${officeId}/contacts/suppliers`:GetAccess("rental_manpower_view")?`/main/${officeId}/contacts/rental-man`:GetAccess("agents_view")?`/main/${officeId}/contacts/agents`:"#",
    
      icon: <Contacts />,
      title: "Contacts",
      hasAccess:["contacts_module","rental_manpower_view","vendor_view","customers_view","sub_contractor_view","agents_view"],
      hasSubMenu: false,
      // link: `/main/${officeId}/contacts/rental-man`,

      subMenu: [
        // {
        //   name: "Rental Manpower",
        //   link: `/main/${officeId}/contacts/rental-man`,
        //   hasAccess:'rental_manpower_view'

        // },
        // {
        //   name: "Vendor",
        //   link: `/main/${officeId}/contacts/vendor`,
        //   hasAccess:'vendor_view'

        // },
        //  {
        //   name: "Supplier",
        //   link: `/main/${officeId}/contacts/suppliers`,
        //   hasAccess:'vendor_view'

        // },
        // {
        //   name: "Client",
        //   link: `/main/${officeId}/contacts/customers`,
        //   hasAccess:'customers_view'

        // },
        // {
        //   name: "Client",
        //   link: `/main/${officeId}/contacts/clients`,
        //   hasAccess:'client_view'

        // },
        // {
        //   name: "Sub Contractor",
        //   link: `/main/${officeId}/contacts/sub-con`,
        //   hasAccess:'sub_contractor_view'

        // },
      ],
    },
   
    {
      icon: <Report/>,
      title: "Report",
      hasAccess:["report_view"],
      hasSubMenu: false,
      link: `/main/${officeId}/reports`,
    },
  ];

  return (
    <div className=" w-full h-screen bg-slate-100 flex justify-start items-center overflow-hidden">
      <SideBar>
        {navItem && 
          navItem.map((item,idx) => {
            
            return (
              <>
              {
                (item?.hasAccess == true||GetMultipleAccess(item?.hasAccess||[]) )&&(<SidebarItem
                    icon={item?.icon}
                    text={t(item?.title)}
                    hasSubMenu={item?.hasSubMenu}
                    subItem={item?.subMenu}
                    link={item?.link}
                    active={isActiveFun(item)}
                    key={idx}
                  />
                )
              }
              </>
            );
          })}
      </SideBar>

      <div className=" w-full h-[100vh]  ">
        <div className=" w-full h-[59px] bg-white z-50 shadow-lg">
          <Navbar />
        </div>
        <div className=" w-full h-[calc(100vh-87px)]  overflow-y-auto">
          <Outlet />
        </div>
        {/* <div className=" w-full h-7 ">
          <p className=' font-semibold text-center text-s text-slate-400'>
            <a
             href="https://numota.in"
             target="_blank"
            >
              &#169;  numota
            </a>
            </p>
        </div> */}
      </div>
    </div>
  );
};

export default App;
