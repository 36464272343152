import { Route } from "react-router-dom";
import MainTeam from "../Views/Web/Project/SubMenu/Team/MainTeam";
import Index from "../Views/Web/Project/SubMenu/Team/Index";
import CreateTeam from "../Views/Web/Project/SubMenu/Team/CreateTeam";
import ExitTeam from "../Views/Web/Project/SubMenu/Team/ExitTeam";
import ViewTeam from "../Views/Web/Project/SubMenu/Team/ViewTeam";
import AddTeam from "../Views/Web/Project/SubMenu/Team/AddTeam";

function ProjectTeamRoutes() {
    return (
      
        <Route path="team/:officeId/:projectId" element={<MainTeam />}>
          <Route index element={<Index />} />
          <Route path="create" element={<AddTeam />} />
          {/* <Route path="edit/:receivedEditId" element={<ExitTeam />} />
          <Route path="view/:receivedViewId" element={<ViewTeam />} /> */}
          <Route path="edit" element={<ExitTeam />} />
          <Route path="view" element={<ViewTeam />} />
       
        </Route>
       
     
    );
  }
  
  export default ProjectTeamRoutes;