import { useEffect, useState } from "react";
import axiosInstance from "../../../../../Services/axiosCommon";
import { useFormik } from "formik";
import { Modal, notification } from "antd";
import * as yup from 'yup';
import { NuSelect, NuTextArea } from "../../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../../Components/Utils/Select";

const VendorForm = ({ open, getData, onCancel, data, getCountData, projectId,subArrList }) => {
    console.log("data",data,projectId);
    const [api, contextHolder] = notification.useNotification();
    const [projects, setProjects] = useState([])
    const [memberList, setMemberList] = useState([])
    const [createNew, setCreateNew] = useState({
      scope: "",
      vendor_id: "",
      project_id: ""
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema:yup.object({
        scope:yup.string().required("Enter Scope"),
        vendor_id:yup.string().required("Please Select Vendor"),
        project_id:yup.string().required("Please Select Project")
      }),
      onSubmit: (value,{resetForm}) => {
  
        data?
        axiosInstance
        .put(`/Vendor_scopes/update/${data?.scopeId}`, value)
        .then((res) => {
          if (res.status === 201 || res.data.status == true) {
            setCreateNew({ memberList: [] });
            getData();
            getCountData();
            //   getCount();
            resetForm()
            console.log('countData', res?.data);
  
            api.success({
              message: `Team Updated successfully`,
  
              description: res?.data?.msg || "Added In The Team",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Team Updation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Team Updation failed`,
            description: err?.response?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        })
        :
        axiosInstance
          .post("Vendor_scopes/add", value)
          .then((res) => {
            console.log("name", res);
  
            if (res.status === 201 || res.data.status == true) {
              setCreateNew({ memberList: [] });
              getData();
              getCountData();
              //   getCount();
              resetForm()
              console.log('countData', res?.data);
  
              api.success({
                message: `Team Creation successfully`,
  
                description: res?.data?.msg || "Added In The Team",
                placement: "bottomRight",
              });
              onCancel();
            } else {
              api.error({
                message: `Team Creation failed`,
                description: res?.data?.msg || "Network error",
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            api.error({
              message: `Team Creation failed`,
              description: err?.response?.data?.msg || "Sever error (or) Network error",
              placement: "bottomRight",
            });
          });
      },
    });
  
    useEffect(() => {
      axiosInstance.get(`/Team/getUserProjectId`).then((res) => {
  
        let list = res?.data?.data || [];
        let finalData = [];
       res?.data?.data && list.map((item, idx) => {
          finalData.push({
            value: item.ProjectId,
            label: item.ProjectName,
          });
  
        })
        setProjects(finalData);
      });
    }, [])
  
  
  
  
    useEffect(()=>{
      data && setCreateNew({
        project_id:data?.project_id,
        vendor_id:data?.vendorId,
        scope:data?.scope,
      })
    },[data])
  
  
    return (
      <>
        {contextHolder}
        <Modal
          open={open}
          title={data ? "Edit Sub-Contract" : "Create Sub-Contract"}
          width={"25%"}
          className="my-modal"
          centered
          onCancel={onCancel}
          footer={[]}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex flex-wrap">
            <FormikSelectTwo
                label="Project"
                placeholder="Select Lead"
                formik={formik}
                name="project_id"
                width="w-full"
                Options={projects}
                disabled={data?true:false}
                isRequired={true}
              />
             <FormikSelectTwo
                label="Sub-Contract"
                placeholder="Select Sub-Contract"
                formik={formik}
                name="vendor_id"
                width="w-full"
                Options={subArrList}
                isRequired={true}
  
              />
              <NuTextArea
                label="Scope of Work"
                placeholder="Scope of Work"
                formik={formik}
                name="scope"
                width="w-full"
                isRequired={true}
  
              />
  
  <button
                type="submit"
                // htmlType="submit"
                // size="large"
                className="w-11/12 bg-baseColor-primary py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
              >
                {data?"Update":"Create"} 
              </button>
            </div>
          </form>
        </Modal>
      </>
    );
  };
  
  export default VendorForm;