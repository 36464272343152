// import { Link, useLocation, useParams } from "react-router-dom";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import { TextMediumBase, TextMediumSM, TextMediumXL, TextMediumXS } from "../../../../Components/Text/MediumText";
// import { useEffect, useState } from "react";
// import axiosInstance from "../../../../Services/axiosCommon";
// import { BackButton } from "../../../../Components/Utils/Buttons";
// import NuDate from "../../../../Components/Utils/NuDate";
// import NuAmount from "../../../../Components/Utils/NuAmount";

import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../../../Components/Utils/Buttons";
import { TextMediumBase, TextMediumSM } from "../../../../../../Components/Text/MediumText";
import { NuDate } from "../../../../../../Components/Utils/Input";


const ViewProductSupplierList = () =>{
    const { productComparisonViewId , officeId,projectId} = useParams();
    const location = useLocation
    ();
 
  const pageIndex = location.state?.pageIndex;
  const searchQuery = location.state?.searchQuery;
  
    const [data,setData] = useState(location.state?.data);
    const getData = () =>{
        axiosInstance.get(`/supplier-quotations/price-listing/${productComparisonViewId}`)
        .then((res)=>{
            console.log("zzz",res?.data?.data);
            res?.data?.data && setData(res?.data?.data);
        })
    }
    useEffect(()=>{
       getData();
    },[])
    return(
        <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { 
                name: "Product Comparison",
                link: `/project/procurement/product-comparision/${officeId}/${projectId}`
            },
            { name: "Product Supplier List" },
          ]} />

        <Link to={`/main/${officeId}/procurement/product-comparison`} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}>
                   <BackButton  link={true} />
                 </Link>
          </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-4 rounded-md overflow-y-scroll">
          <TextMediumBase color="text-[#4F4768]" content={`Product Name: ${data?.productName} `}/>
          <TextMediumSM color="text-[#4F4768]" content={`Unit: ${data?.unit}`} />
          <table className=' w-full max-h-[calc(100vh-200px)] overflow-y-auto mt-2 '>
       <thead>
       <tr className='border-b border-[#C4C2C2] text-[#4F4768]'>
          <th className=' w-[30%] text-left text-sm font-medium py-2 px-4'>Vendor Name</th>
          <th className=' w-[20%] text-left text-sm font-medium py-2 px-4'>Price</th>
          <th className=' w-[20%] text-left text-sm font-medium py-2 px-4'>Date</th>
        </tr>
       </thead>
       <tbody>
       {data?.quotes?.map((value,index)=>{
            return(
                <tr className='even:bg-white odd:bg-slate-50 ' >
                  <td className=' w-[30%] py-2 text-left text-xs px-4 text-[#606279]'>{value?.vendorName}
                    {/* <TextMediumXS content={value?.vendorName}/> */}
                  </td>
                 <td className='w-[30%] py-2 text-left text-xs px-4 text-[#606279]'>
  {value?.currency}{" "}
  {value?.price
    ? new Intl.NumberFormat('en-US', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      }).format(value.price)
    : "0.00"}
</td>
        {/* <TextMediumXS content={value?.price}/> */}
                 
                  <td className=' w-[30%] py-2 text-left text-xs px-4 text-[#606279]'>{value?.date && NuDate({value:value?.date,format:"DD-MMM-YYYY"})}
                    {/* <TextMediumXS content={value?.date && NuDate({value:value?.date,format:"DD-MM-YYYY"})}/> */}
                  </td>
                </tr>
            )
          })}
       </tbody>
       </table>
      </div>
          </div>
    )
}

export default ViewProductSupplierList;