import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import CategoryMaterial from './Category'
import DivisionMaterial from './Division'
import ItemMaterial from './item'
import UnitMaterial from './Unit'

function MaterialBudgetindex() {
    return(
        <>
        <TextMediumSM content="Budget" />
        <TextMediumSM content="Material" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CategoryMaterial/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DivisionMaterial/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ItemMaterial/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <UnitMaterial/>
         </div>
         </div>
         </>
    )
}

export default MaterialBudgetindex
