import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { GetAccess } from "../../../Components/Utils/roles"
import Breadcrumb from "../../../Components/Utils/Breadcrumb"
import SearchBar from "../../../Components/Utils/SearchBar"
import { ClearFilterButton, CreateButton, FilterButton, ImportButton } from "../../../Components/Utils/Buttons"
import { Select } from "antd"
import axiosInstance from "../../../Services/axiosCommon"
import { useNumber } from "../../../Context/ProjectIdContext"

const ProcurementLayout = (
    {
        getData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        onCreateClick,
        searchValue="",
        searchFun=()=>{},
        isFilter=false,
        setFilterOpen=()=>{},
        newfilter=false,
        ClearFilter=()=>{},
        neededSearch=false,
    }
) =>{
    let {pathname} = useLocation()
    let {officeId} = useParams()
    const [projectList,setProjectList]= useState([])
    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/main/${officeId}/procurement/purchase-requests`){
            setModule("PR")
        }else if(pathname == `/main/${officeId}/procurement/product-comparison`){
            setModule("Product Comp.")
        }else if(pathname == `/main/${officeId}/procurement/rfq`){
            setModule("RFQ")
        }else if(pathname == `/main/${officeId}/procurement/purchase-order`){
            setModule("PO")
        }else if(pathname == `/main/${officeId}/procurement/payment-request`){
            setModule("Pymt. Reqt.")
        }
        // else if(pathname == `/main/${officeId}/procurement/quote-comparison`){
        //     setModule("Quote Comparison")
        // }
        // else if(pathname == `/main/${officeId}/procurement/team-performance`){
        //     setModule("Team Performance")
        // }
    },[pathname])

    let LinkItem = [
        {
            label:"PR",
            link:`/main/${officeId}/procurement/purchase-requests`,
            access:GetAccess(),
        },
        {
            label:"Product Comp.",
            link:`/main/${officeId}/procurement/product-comparison`,
            access:GetAccess(),
        },
        {
            label:"RFQ",
            link:`/main/${officeId}/procurement/rfq`,
            access:true,
        },
        // {
        //     label:"Quote Comparison",
        //     link:`/main/${officeId}/procurement/quote-comparison`,
        //     access:GetAccess(),
        // },
        {
            label:"PO",
            link:`/main/${officeId}/procurement/purchase-order`,
            access:GetAccess(),
        },
        {
            label:"Pymt. Reqt.",
            link:`/main/${officeId}/procurement/payment-request`,
            access:true,
        },
       
       
      
       
        // {
        //     label:"Team Performance",
        //     link:`/main/${officeId}/procurement/team-performance`,
        //     access:GetAccess(),
        // },
    ];

   const {number, updateNumber} = useNumber()

    function getProjectByOffice(id){
        axiosInstance.get(`/DropDown/${id}/getAllProjects`).then(res=>{
            let tempValue = res?.data?.data||[];
            let arr = []
            tempValue.map(i=>{
                let val = {
                    value:i?.projId||"",
                    label:i?.projName||""
                }
                arr.push(val)
            })
            if(number ==0){
                updateNumber(arr[0]?.value)
            }
            setProjectList(arr)
        }).catch((error) => {
            console.error("Error:", error);
              
          });
    }

    useEffect(()=>{
        if(number==0||pathname !="") getProjectByOffice(officeId)
    },[pathname])

    function handleProjectChange(e){
        console.log(e);
        updateNumber(e)
    }
    
    return(
        <div className=" w-full  h-full py-2 px-4 overflow-hidden">
            <div className="">
            <div className="md:flex justify-between w-full ">
 <div className=" w-full  flex items-center gap-x-1 pt-2">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}`}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>


            <div className=" flex justify-end items-center gap-1 xl:gap-3 ">
                <div>
                {neededSearch && 
                 <SearchBar handleChange={searchFun}  value={searchValue}/>}
                </div>
            <form className=" w-48 flex justify-end items-center xl:gap-3 mx-0" onSubmit={getData}>
          <Select
            style={{ width: '100%' }}
            options={projectList}
            value={number}
            disabled={projectList.length >= 2 ? false : true}
            // allowClear={formik.values[name]?true:false}
            // name={name}
            // placeholder={placeholder}
            onChange={handleProjectChange}
          />
            </form>
            {newfilter && (
  <>
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <ClearFilterButton handleClick={ClearFilter} />} </>)}
               {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}

              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
           
           
            <div className=" w-full h-[calc(100vh-140px)]  ">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default ProcurementLayout;