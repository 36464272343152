import React, { lazy } from "react";
import { Route } from "react-router-dom";

import MainCostings from "../Views/Web/Project/SubMenu/Costing/MainCosting";
import MainProjectCosting from "../Views/Web/Project/SubMenu/Costing/ProjectCosting/MainProjectCosting";
import ProjectCosting from "../Views/Web/Project/SubMenu/Costing/ProjectCosting";
import CreateProjectCosting from "../Views/Web/Project/SubMenu/Costing/ProjectCosting/CreateProjectCosting";
import EditProjectCosting from "../Views/Web/Project/SubMenu/Costing/ProjectCosting/EditProjectCosting";
import ViewProjectCosting from "../Views/Web/Project/SubMenu/Costing/ProjectCosting/ViewProjectCosting";
import MainManPower from "../Views/Web/Project/SubMenu/Costing/ManPower/MainManPower";
import ManPowerCosting from "../Views/Web/Project/SubMenu/Costing/ManPower";
import CreateManPower from "../Views/Web/Project/SubMenu/Costing/ManPower/CreateManPower";
import EditManPower from "../Views/Web/Project/SubMenu/Costing/ManPower/EditManPower";
import ViewManPower from "../Views/Web/Project/SubMenu/Costing/ManPower/ViewManPower";
import MainMaterial from "../Views/Web/Project/SubMenu/Costing/Material/MainMaterial";
import Material from "../Views/Web/Project/SubMenu/Costing/Material";
import CreateMaterial from "../Views/Web/Project/SubMenu/Costing/Material/CreateMaterial";
import EditMaterial from "../Views/Web/Project/SubMenu/Costing/Material/EditMaterial";
import ViewMaterial from "../Views/Web/Project/SubMenu/Costing/Material/ViewMaterial";
import MainOthers from "../Views/Web/Project/SubMenu/Costing/Others/MainOthers";
import Others from "../Views/Web/Project/SubMenu/Costing/Others";
import CreateOthers from "../Views/Web/Project/SubMenu/Costing/Others/CreateOthers";
import EditOthers from "../Views/Web/Project/SubMenu/Costing/Others/EditOthers";
import ViewOthers from "../Views/Web/Project/SubMenu/Costing/Others/ViewOthers";
import MainManHour from "../Views/Web/Project/SubMenu/Costing/ManHour/MainManHour";
import ManHourIndex from "../Views/Web/Project/SubMenu/Costing/ManHour";
import CreateManHour from "../Views/Web/Project/SubMenu/Costing/ManHour/CreateManHour";
import EditManHour from "../Views/Web/Project/SubMenu/Costing/ManHour/EditManHour";
import ViewManHour from "../Views/Web/Project/SubMenu/Costing/ManHour/ViewManHour";
import MainEquipment from "../Views/Web/Project/SubMenu/Costing/Equipment/MainEquipment";
import Equipmentindex from "../Views/Web/Project/SubMenu/Costing/Equipment";
import CreateEquipment from "../Views/Web/Project/SubMenu/Costing/Equipment/CreateEquipment";
import EditEquipment from "../Views/Web/Project/SubMenu/Costing/Equipment/EditEquipment";
import ViewEquipment from "../Views/Web/Project/SubMenu/Costing/Equipment/ViewEquipment";




const ProjectCostingRoutes = () => {
  return (
    <Route path="costing" component={<MainCostings/>}>
      <Route path="project/:officeId/:projectId" element={<MainProjectCosting />}>
        <Route index element={<ProjectCosting />} />
        <Route path="create" element={<CreateProjectCosting />} />
        <Route path="edit/:projectCostingEditId" element={<EditProjectCosting />} />
        <Route path="view/:projectCostingViewId" element={<ViewProjectCosting />} />
      </Route>

      <Route path="man-power/:officeId/:projectId" element={<MainManPower />}>
        <Route index element={<ManPowerCosting />} />
        <Route path="create" element={<CreateManPower />} />
        <Route path="edit/:manPowerEditId" element={<EditManPower />} />
        <Route path="view/:manPowerViewId" element={<ViewManPower />} />
      </Route>

      <Route path="material/:officeId/:projectId" element={<MainMaterial />}>
        <Route index element={<Material />} />
        <Route path="create" element={<CreateMaterial />} />
        <Route path="edit/:materialEditId" element={<EditMaterial />} />
        <Route path="view/:materialViewId" element={<ViewMaterial />} />
      </Route>
      
      <Route path="others/:officeId/:projectId" element={<MainOthers />}>
        <Route index element={<Others />} />
        <Route path="create" element={<CreateOthers />} />
        <Route path="edit/:otherEditId" element={<EditOthers />} />
        <Route path="view/:otherViewId" element={<ViewOthers />} />
      </Route>

      <Route path="man-hour/:officeId/:projectId" element={<MainManHour />}>
        <Route index element={<ManHourIndex />} />
        <Route path="create" element={<CreateManHour />} />
        <Route path="edit/:manHourEditId" element={<EditManHour />} />
        <Route path="view/:manHourViewId" element={<ViewManHour />} />
      </Route>
      <Route path="equipment/:officeId/:projectId" element={<MainEquipment />}>
        <Route index element={<Equipmentindex/>} />
        <Route path="create" element={<CreateEquipment />} />
        <Route path="edit/:EquipmentEditId" element={<EditEquipment />} />
        <Route path="view/:EquipmentViewId" element={<ViewEquipment />} />
      </Route>
    </Route>
  );
};

export default ProjectCostingRoutes;
