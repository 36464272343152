import { useEffect } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import MainItem from "../Views/Web/Items/MainItem";
import ViewActivity from "../Views/Web/Items/Activity/ViewActivity";
import IndexActivity from "../Views/Web/Items/Activity/IndexActivity";
import MainActivity from "../Views/Web/Items/Activity/MainActivity";
import { Wrapper } from "../Components/Utils/roles";
import MainCondition from "../Views/Web/Items/TeamandCondition/MainCondition";
import IndexTeamCondition from "../Views/Web/Items/TeamandCondition/IndexTeamCondition";
import CreateCondition from "../Views/Web/Items/TeamandCondition/CreateCondition";
import EditCondition from "../Views/Web/Items/TeamandCondition/EditCondition";
import ViewCondition from "../Views/Web/Items/TeamandCondition/ViewCondition";
// import ItemLayout from "../Views/Web/Items/ItemLayout";


function ItemRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === "/item" ||
      location.pathname === "/item/") {
      navigate("/page-not-found")
    }
  },[location])
  return(
      <Route path="item" element={<MainItem/>}>
        <Route path="activity" element={<MainActivity/>}>
            <Route index element={<Wrapper accessKey={"activity_item_view"}  Component={IndexActivity} />}/>
             <Route path='view/:activityViewId' element={<Wrapper accessKey={"activity_item_view"}  Component={ViewActivity} />} />
        </Route>
        <Route path="teamandcondition" element={<MainCondition/>}>
            <Route index element={<Wrapper accessKey={"terms_and_condition_view"}  Component={IndexTeamCondition} />}/>
            <Route path="create" element={<Wrapper accessKey={"terms_and_condition_create"}  Component={CreateCondition} />}/>
            <Route path='edit/:teamandconditionEditId' element={<Wrapper accessKey={"terms_and_condition_edit"}  Component={EditCondition} />} />
            <Route path='view/:teamandconditionViewId' element={<Wrapper accessKey={"terms_and_condition_view"}  Component={ViewCondition} />} />
        </Route>
        </Route>
  )
   
}

export default ItemRoutes