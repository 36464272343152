import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function ItemMaterial() {
    return (
        <SettingsMetaData propertyName={"MatrialItem"} viewPropertyName={"Item"} />
      );
}

export default ItemMaterial
