import { useParams } from "react-router-dom";
import { Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import axiosInstance from "../../../../Services/axiosCommon";
import { NuInput, NuInputText, NuTextArea } from "../../../../Components/Utils/Input";


const EditTermsCondition = ({open, getData, onCancel, data,projectList,firstProject,id }) => 
  {
  const {officeId} = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    item_code: '',
      description: '',
  });
  useEffect(() => {
    data&&setCreateNew({
      item_code:data.item_code,
      description:data.description,
    })
  },[data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      description:yup.string().required("Description is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
      const withBranchId = {
        ...value,
        branch: officeId
      }
      axiosInstance.put(`/terms-and-condition/${data?.id}`, withBranchId)
      .then((res) => {
          console.log("res",res.data.status);
          if (res.data.status) {
            getData(id);
            resetForm();
            api.success({
              message: `Activity Update successfully`,
              description: res?.data?.msg || "Added In The Activity",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Activity Upadate failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });

 

  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={data ? "Edit  Terms & Conditions" : "Create  Terms & Conditions"}
      width={"50%"}
      className="my-modal"
      centered
      onCancel={onCancel}
      footer={[]}
    >
    <form onSubmit={formik.handleSubmit} className="create-activity-form">
     <div className=" w-full flex flex-wrap">
            <NuInput
                        type="text"
 
              label="Item Code"
              placeholder="Item Code"
              formik={formik}
              name="item_code"
              width="w-full"
            />
             <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full"
              new_width='w-full'
            />
           
           <button
              type="submit"              
              className="w-11/12 bg-[#389d98] py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
            >
              Update
            </button>
          </div>
     
    </form>
    </Modal>
    </>
  );
};

export default EditTermsCondition;