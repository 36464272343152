import React, { useEffect, useState } from "react";
import {
  TextMediumSM,
  TextMediumXS,
} from "../../../../../Components/Text/MediumText";
import UseGetDashBoardData from "../../../../../Components/Hooks/useDashboardData";
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import axiosInstance from "../../../../../Services/axiosCommon";
import { Navigate, useNavigate } from "react-router-dom";

export default function PoSupplier() {
  // const { data, loading } = UseGetDashBoardData(`/dashboard/PO-to-supplier`);
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  async function getData() {
    setLoading(true);
    try {
      await axiosInstance.get('/dashboard/PO-to-supplier').then(res=>{
        setLoading(false);
        setData(res?.data || []) ;
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);
 console.log(data,'jhg');
 
  
  const chartData = [
    { name: "Received", value: data?.received ,status: 'Received' },
    { name: "Not Yet Shipped", value: data?.not_yet_shipped ,status: 'Not Yet Shipped'},
    { name: "Under Shipment", value: data?.under_shipment,status: 'Under Shipment' },
    { name: "Under Clearance", value: data?.under_clearance ,status: 'Under Clearance'},
  ];
  const handleSliceClick = (status) => {
       const path = '/shipping/shipment-tracking';
    console.log('Status',status);
    
    navigate(path , {state: { shipmentStatus : status } });
  };
  const COLORS = ['#987D9A', '#6C946F', '#FFB200', '#77E4C8'];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="max-w-[350px] bg-white shadow-md rounded-lg p-[5px]">
          <p className="text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="col-span-2 bg-white h-[40vh] p-4  rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4">
        <TextMediumSM
          content={"Purchase Order to Supplier "}
          color="text-[#4F4768]"
        />
        <div className="bg-[#95D2B3] px-2 py-0.5 rounded-md">
          <p className="text-[#333333] text-[12px] font-semibold ">
            {data?.total || 0.00}
          </p>
        </div>
           </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
      <p className='pl-4 items-center text-xs 2xl:text-sm'>This month : <span className='text-[#048178] '>{data?.this_month || "0"}</span>
      </p>
      {loading ? (
  <LoadingSkeleton />
) : (
  <div className="h-[98%] overflow-y-scroll">
    <div className="flex">
      {chartData.some((item) => item.value > 0) ? (
        <div className="w-[40%] flex justify-center items-center">
          <ResponsiveContainer width="100%" height={150}>
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={30}
                outerRadius={50}
                fill="#8884d8"
                dataKey="value"
                style={{ cursor: 'pointer' }}
                onClick={(e) => handleSliceClick(e.status)}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onFocus={(e) => e.target.blur()}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NODataChart />
      )}
      <div className="w-[60%] flex flex-col justify-center">
        {chartData.map((item, index) => (
          <div
            key={index}
            className="flex flex-wrap items-center text-xs mb-1"
          >
            <span
              className="inline-block w-2 h-2 rounded-full mr-2"
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            ></span>
            <p className="w-[45%] font-extralight">{item.name}</p>
            <p className="w-[15%] font-extralight">: {item.value}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
)}
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <div className="flex w-full  h-full space-y-4 animate-pulse">
      <div className="w-[40%] flex justify-center">
      <NODataChart/>
      </div>
      <div className="w-[60%] flex flex-col items-centermt-4 space-y-2 ">
        <div className="w-1/2 h-4 bg-slate-200 rounded"></div>
        <div className="w-1/3 h-4 bg-slate-200 rounded"></div>
        <div className="w-1/2 h-4 bg-slate-200 rounded"></div>
        <div className="w-1/3 h-4 bg-slate-200 rounded"></div>
      </div>
    </div>
  );
}




export function NODataChart() {
  return (
    <div className=" w-[40%]">
      {/* <Doughnut data={chartData} /> */}
      <div className="w-full flex justify-center">
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              data={[
                {
                  name: "No Data",
                  value: 100,
                },
              ]}
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={50}
              fill="#E8E8E8"
              dataKey="value"
              // paddingAngle={0}
            >
              {/* <Label
                value={0}
                position="center"
                className="text-center"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              /> */}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}