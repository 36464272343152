import React, { useState } from 'react'
import { SearchButton } from './Buttons'

function SearchBar({handleChange,value}) {
  const [showSearch,setShowSearch] = useState(false);
  return (
    <div className=' relative h-[34px]'>
    {
      showSearch == true &&
      <div className=" w-64 md:w-80 h-[34px] shadow-[0_8px_30px_rgb(0,0,0,0.12)] absolute right-0 top-0 rounded-md p-1 flex justify-between bg-white items-center" style={{border:'0.5px solid #EAEBED'}}>
      <input type="text" value={value||""} onChange={handleChange} placeholder="Search here" className="placeholder-gray-400 w-[90%] text-sm px-2 outline-none border-none" />      
      <span onClick={()=>setShowSearch(false)}>
      <SearchButton/>
      </span>
    </div> 
    }
    
    <div 
     className={`${showSearch == true && 'hidden'} rounded-md px-1 bg-white w-[34px] h-[34px] flex justify-center items-center`} 
     style={{border:'0.5px solid #EAEBED'}} 
     onClick={()=>setShowSearch(true)}
     title="Search"
     >
     <SearchButton/>
    </div>
    </div>
  )
}

export default SearchBar;