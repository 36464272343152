import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../Services/axiosCommon';
import {
    BackButton,
    CancelButton,
    SubmitButton,
  } from "../../../Components/Utils/Buttons";
import CustomImgUpload from '../../../Components/Utils/CustomImgUpload';
import Breadcrumb from '../../../Components/Utils/Breadcrumb';
import Dashboard from '../Dashboard';
import { NuDate, NuInput, NuMobileNumber, NuSelect } from '../../../Components/Utils/Input';
import MultiSelect from '../../../Components/Utils/MultiSelect';
import FormikSelectTwo, { SelectCountry } from '../../../Components/Utils/Select';
import useAddFormData from '../../../Components/Hooks/useAddFormData';
import { TextMediumSM } from '../../../Components/Text/MediumText';
import { CountryMIN } from '../../../assets/CountryMini';

function CreateEmployee() {
    const {officeId} = useParams();
    const navigate = useNavigate();
  const { Oncancel, addFormData, isLoading } = useAddFormData();

    const [image, setImage] = useState({ preview: "", raw: "" });
    const [projectList, setProjectList] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [createNew, setCreateNew] = useState({
      city:"",
    streetName:"",
    district:"",
    state:"",
    country:"",
    address1:"",
    address2:"",
    postalCode:"",
    name:"",
    designation:"",
    division:"",
    email:"",
    phone:"",
position:"",
    currentProject:[],
    projectWorked:[],
    branchId: officeId,
    monthlyTar:"",
    quarterlyTar:"",
    halfYearTar:"",
    yearlyTar:"",

  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
          email: yup.string().required("Email is required!"),
          name: yup.string().required("Name  is required!"),
           }),
           onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append("json_data", JSON.stringify(value));
            formData.append("userImage", image.raw);
            addFormData("/user/create-user", formData, resetForm);
          },
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = ()=>{
    setImage({
      preview: "",
      raw: "",
    });
  }
  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, userImage: "" }))
    formik.values.userImage = ''
  }

  function getAllProject() {
    axiosInstance.get(`/DropDown/${officeId}/getAllProjects`)
    .then((res) => {
      let allProject = res.data?.data;
      let finalData = [];
      res.data?.data &&
        allProject.forEach((i) => {
          let x = {
            value: i.projId,
            label: i.projName,
          };
          finalData.push(x);
        });
      setProjectList(finalData);
    }).catch((error) => {
      console.error("Error fetching projects:", error);
        
    });
  }

  useEffect(()=>{
    getAllProject()
  },[])

  return (
    <div className=" w-full h-full py-2 px-4 ">
        {/* <div className=""> */}
        <div className="md:flex justify-end w-full">
        {/* <Breadcrumb
          items={[
            // { name: "Sales" },

            {
              name: "Employee",
              link: `/main/${officeId}/employees`,
            },

            { name: "Edit" },
          ]}
        /> */}
            <BackButton/>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap py-2 px-4  overflow-y-auto">
           
                   <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Personal Info :
  </p>
</div>  
<CustomImgUpload
            borderStyle="dashed"
            handleChange={handleChangeImg}
            label={'User Profile'}
            preview={image.preview || createNew.userImage || ""}
            removeFunction={handleDeleteImg}
          />
            <NuInput
              type="text"
              label="Name"
              placeholder="Enter Name"
              formik={formik}
              name="name"
              isRequired={true}
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              isRequired={true}
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/3 md:-mt-2"
            />
            
            {/* <NuUpload
              label="User Image"
              width="w-full md:w-1/3"
              onChange={handleChangeImg}
            /> */}
            <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Address :
  </p></div>
            <NuInput
                            type="text"
                            label="Street Address Line 1"
                            placeholder="Street Address Line 1"
                            formik={formik}
                            name="address1"
                            width="w-full md:w-1/2 lg:w-1/3"
                          />
            
                          <NuInput
                            type="text"
                            label="Street Address Line 2"
                            placeholder="Street Address Line 2"
                            formik={formik}
                            name="address2"
                            width="w-full md:w-1/2 lg:w-1/3"
                          />
            <NuInput
              type="text"
              label="Building / Street Name"
              placeholder="Type Here"
              formik={formik}
              name="streetName"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="Enter City"
              formik={formik}
              name="city"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="Enter District"
              formik={formik}
              name="district"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="Enter State"
              formik={formik}
              name="state"
              width="w-full md:w-1/3 md:-mt-2"
            />
           <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="country"
                width="w-full md:w-1/3 md:-mt-2"
                FixPosition={true}
                PortalTarget={true}
              />

            <NuInput
              type="number"
              label="Zip Code"
              placeholder="Type Here"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/3 md:-mt-2"
            /> 


          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Employee Roll :
  </p></div>
  <FormikSelectTwo
                type="text"
                label="Position"
                placeholder="Choose"
                Options={[]}
                formik={formik}
                name="position"
                width="w-full md:w-1/3 md:-mt-2"
              />
            <FormikSelectTwo
              type="text"
              label="Division"
              placeholder="Choose Division"
              Options={[
                {
                  value: "Management",
                  label: "Management",
                },
                {
                  value: "Coordination",
                  label: "Coordination",
                },
                {
                  value: "Procurement",
                  label: "Procurement",
                },
                {
                  value: "Warehouse ",
                  label: "Warehouse ",
                },
                {
                  value: "Customer ",
                  label: "Customer ",
                },
                {
                  value: "Logistics ",
                  label: "Logistics ",
                },
                {
                  value: "Sales ",
                  label: "Sales ",
                },
                {
                  value: "Finance ",
                  label: "Finance ",
                },

              ]}
              formik={formik}
              name="division"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <FormikSelectTwo
              type="text"
              label="Designation"
              placeholder="Choose Designation"
              Options={[
                {
                  value: "gen_manager",
                  label: "Gen. Manager",
                },
                {
                  value: "manager",
                  label: "Manager",
                },
                {
                  value: "coordinator",
                  label: "Coordinator",
                },
                {
                  value: "localPurchaser",
                  label: "Local Purchaser",
                }, {
                  value: "salesman",
                  label: "Salesman",
                }, {
                  value: "storeKeeper",
                  label: "Store Keeper",
                }, {
                  value: "accountant",
                  label: "Accountant",
                },
                {
                  value: "buyer",
                  label: "Buyer",
                },
                 {
                  value: "supportPersonnel",
                  label: "Support Personnel",
                },
               

              ]}
              formik={formik}
              name="designation"
              width="w-full md:w-1/3 md:-mt-2"
            />
              <MultiSelect
              label="Assigned Project"
              Options={projectList}
              formik={formik}
              placeholder="Choose"
              // isRequired={true}
              name="currentProject"
              width="w-full md:w-1/3 md:-mt-2"
            />
             {/* <MultiSelect
              label="Assigned Project"
              options={projectList}
              formik={formik}
              placeholder="Choose"
              // isRequired={true}
              name="projectWorked"
              width="w-full md:w-1/3 md:-mt-2"
            /> */}
            {/* {formik.values.designation === "salesman" && (
              <>
               <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Traget :
  </p></div>
               <NuInput
              type="text"
              label="Monthly"
              placeholder="Monthly"
              formik={formik}
              name="monthlyTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
             <NuInput
              type="text"
              label="Quarterly"
              placeholder="Quarterly"
              formik={formik}
              name="quarterlyTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
             <NuInput
              type="text"
              label="Half Year"
              placeholder="Half Year"
              formik={formik}
              name="halfYearTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
             <NuInput
              type="text"
              label="Yearly"
              placeholder="Yearly"
              formik={formik}
              name="yearlyTar"
              width="w-full md:w-1/3 md:-mt-2"
            />
               
              </>
            )} */}
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                                 handleClick={() => {
                                   navigate(-1)
                                 }}
                               />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
            </form>
    </div>
  )
}

export default CreateEmployee