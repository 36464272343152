import React from "react";

function DashboardSide({ color = "#999999", width = 18, height = 18 }) {
  return (
    <svg
      fill={color}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        <title>dashboard</title>

        <rect x="24" y="21" width="2" height="5" />

        <rect x="20" y="16" width="2" height="10" />

        <path d="M11,26a5.0059,5.0059,0,0,1-5-5H8a3,3,0,1,0,3-3V16a5,5,0,0,1,0,10Z" />

        <path d="M28,2H4A2.002,2.002,0,0,0,2,4V28a2.0023,2.0023,0,0,0,2,2H28a2.0027,2.0027,0,0,0,2-2V4A2.0023,2.0023,0,0,0,28,2Zm0,9H14V4H28ZM12,4v7H4V4ZM4,28V13H28.0007l.0013,15Z" />

        <rect
          id="_Transparent_Rectangle_"
          dataName="&lt;Transparent Rectangle&gt;"
          style={{ fill: "none" }}
          width="32"
          height="32"
        />
      </g>
    </svg>
  );
}

export default DashboardSide;
