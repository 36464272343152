import React from 'react'

function Finance({color = "#999999", width = 18, height = 18 }) {
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
         fill="#e5cccc"
          d="M282.615,286.663c0,40.894-25.644,70.359-57.278,70.359s-57.278-29.467-57.278-70.359 c0-40.892,25.644-70.359,57.278-70.359S282.615,245.77,282.615,286.663z"
        />{" "}
        <path
          fill={color}
          d="M225.337,371.107c-19.75,0-37.982-8.854-51.336-24.93c-12.914-15.547-20.027-36.682-20.027-59.513 c0-22.831,7.113-43.968,20.027-59.515c13.354-16.076,31.586-24.93,51.336-24.93s37.981,8.854,51.336,24.93 c12.914,15.547,20.027,36.684,20.027,59.515c0,22.831-7.113,43.967-20.027,59.513C263.319,362.253,245.088,371.107,225.337,371.107z M225.337,230.388c-24.624,0-43.194,24.193-43.194,56.275c0,32.081,18.569,56.274,43.194,56.274s43.194-24.193,43.194-56.274 C268.53,254.581,249.961,230.388,225.337,230.388z"
        />{" "}
        <polygon
         fill="#e5cccc"
          points="75.409,96.465 75.409,157.791 436.591,157.791 436.591,354.211 497.915,354.211 497.915,96.465 "
        />{" "}
        <g>
          {" "}
          <path
            fill={color}
            d="M497.915,82.38H75.409c-7.779,0-14.085,6.307-14.085,14.085v47.241h-47.24 C6.306,143.706,0,150.013,0,157.791v257.744c0,7.778,6.306,14.085,14.085,14.085h211.252c7.779,0,14.085-6.307,14.085-14.085 c0-7.778-6.306-14.085-14.085-14.085H28.169V171.876h47.24h347.096v182.333v47.241H309.845c-7.778,0-14.085,6.307-14.085,14.085 c0,7.778,6.307,14.085,14.085,14.085h126.745c7.778,0,14.085-6.307,14.085-14.085v-47.242h47.24 c7.778,0,14.085-6.307,14.085-14.085V96.465C512,88.687,505.694,82.38,497.915,82.38z M483.831,340.124h-33.155V157.791 c0-7.778-6.307-14.085-14.085-14.085H89.494v-33.157h394.336V340.124z"
          />{" "}
          <path
            fill={color}
            d="M59.156,262.018c-7.779,0-14.085-6.307-14.085-14.085c0-7.778,6.306-14.085,14.085-14.085 c17.086,0,30.986-13.9,30.986-30.986c0-7.778,6.306-14.085,14.085-14.085s14.085,6.307,14.085,14.085 C118.311,235.481,91.774,262.018,59.156,262.018z"
          />{" "}
          <path
            fill={color}
            d="M391.519,262.018c-32.619,0-59.156-26.537-59.156-59.156c0-7.778,6.307-14.085,14.085-14.085 s14.085,6.307,14.085,14.085c0,17.086,13.9,30.986,30.986,30.986c7.778,0,14.085,6.307,14.085,14.085 C405.604,255.711,399.298,262.018,391.519,262.018z"
          />{" "}
          <path
            fill={color}
            d="M104.227,384.549c-7.779,0-14.085-6.307-14.085-14.085c0-17.086-13.9-30.986-30.986-30.986 c-7.779,0-14.085-6.307-14.085-14.085c0-7.778,6.306-14.085,14.085-14.085c32.619,0,59.156,26.537,59.156,59.156 C118.311,378.243,112.006,384.549,104.227,384.549z"
          />{" "}
          <path
            fill={color}
            d="M346.448,384.549c-7.778,0-14.085-6.307-14.085-14.085c0-32.619,26.537-59.156,59.156-59.156 c7.778,0,14.085,6.307,14.085,14.085c0,7.778-6.307,14.085-14.085,14.085c-17.086,0-30.986,13.9-30.986,30.986 C360.533,378.243,354.227,384.549,346.448,384.549z"
          />{" "}
          <path
            fill={color}
            d="M346.448,216.947h-36.603c-7.778,0-14.085-6.307-14.085-14.085c0-7.778,6.307-14.085,14.085-14.085 h36.603c7.778,0,14.085,6.307,14.085,14.085C360.533,210.64,354.227,216.947,346.448,216.947z"
          />{" "}
          <path
            fill={color}
            d="M140.829,216.947h-36.602c-7.779,0-14.085-6.307-14.085-14.085c0-7.778,6.306-14.085,14.085-14.085 h36.602c7.779,0,14.085,6.307,14.085,14.085C154.913,210.64,148.608,216.947,140.829,216.947z"
          />{" "}
          <path
            fill={color}
            d="M346.448,384.549h-36.603c-7.778,0-14.085-6.307-14.085-14.085c0-7.778,6.307-14.085,14.085-14.085 h36.603c7.778,0,14.085,6.307,14.085,14.085C360.533,378.242,354.227,384.549,346.448,384.549z"
          />{" "}
          <path
            fill={color}
            d="M140.829,384.549h-36.602c-7.779,0-14.085-6.307-14.085-14.085c0-7.778,6.306-14.085,14.085-14.085 h36.602c7.779,0,14.085,6.307,14.085,14.085C154.913,378.242,148.608,384.549,140.829,384.549z"
          />{" "}
        </g>{" "}
      </g>
    </svg>
  );
}

export default Finance