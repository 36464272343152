// import { useEffect, useState } from "react"
// import { Link, useLocation, useParams } from "react-router-dom"
// import { GetAccess } from "../../../Components/Utils/roles"
// import Breadcrumb from "../../../Components/Utils/Breadcrumb"
// import SearchBar from "../../../Components/Utils/SearchBar"
// import { CreateButton, FilterButton, ImportButton } from "../../../Components/Utils/Buttons"
// import { Select } from "antd"

import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { GetAccess } from "../../../../../Components/Utils/roles"
import Breadcrumb from "../../../../../Components/Utils/Breadcrumb"
import { Select } from "antd"
import SearchBar from "../../../../../Components/Utils/SearchBar"
import { CreateButton, FilterButton, ImportButton } from "../../../../../Components/Utils/Buttons"

const ProcurementsubLayout = (
    {
        getData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        onCreateClick,
        searchFun=()=>{},
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        searchValue="",
        neededSearch=false,
        newfilter=false
    }
) =>{
    let {pathname} = useLocation()
    let {officeId,projectId} = useParams()
    
    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/project/procurement/ypr/${officeId}/${projectId}`){
            setModule("PR")
        }else if(pathname == `/project/procurement/rfq/${officeId}/${projectId}`){
            setModule("RFQ")
        }else if(pathname == `/project/procurement/quote-comparison/${officeId}/${projectId}`){
            setModule("Quote Comparison")
        
        }else if(pathname == `/project/procurement/payment-request/${officeId}/${projectId}`){
            setModule("Payment Request")
        }else if(pathname == `/project/procurement/parchase-order/${officeId}/${projectId}`){
            setModule("PO")
        }else if(pathname == `/project/procurement/product-comparision/${officeId}/${projectId}`){
            setModule("Product Comparison")
        }else if(pathname == `project/procurement/team-performance/${officeId}/${projectId}`){
            setModule("Team Performance")
        }
        // else if(pathname == `/project/procurement/supplier-quotes/${officeId}/${projectId}`){
        //     setModule("Supplier Quotes")
        // }
    },[pathname])

    let LinkItem = [
        {
            label:"PR",
            link:`/project/procurement/ypr/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Product Comparison",
            link:`/project/procurement/product-comparision/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"RFQ",
            link:`/project/procurement/rfq/${officeId}/${projectId}`,
            access:true,
        },
        
        // {
        //     label:"Quote Comparison",
        //     link:`/project/procurement/quote-comparison/${officeId}/${projectId}`,
        //     access:GetAccess(),
        // },
        // {
        //     label:"Supplier Quotes",
        //     link:`/project/procurement/supplier-quotes/${officeId}/${projectId}`,
        //     access:GetAccess(),
        // },
        {
            label:"Purchase Order",
            link:`/project/procurement/parchase-order/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Payment Request",
            link:`/project/procurement/payment-request/${officeId}/${projectId}`,
            access:true,
        },
       
       
      
        
        // {
        //     label:"Team Performance",
        //     link:`/project/procurement/team-performance/${officeId}/${projectId}`,
        //     access:GetAccess(),
        // },
    ]
    
    return(
        <div className=" w-full  h-full py-2 px-4 overflow-hidden">
            <div className="">
            <div className="md:flex justify-between w-full ">
            {/* <Breadcrumb items={[
               
                {
                    name:"Procurment"
                },
                { name: `${module}` },

            ]} /> */}

<div className=" w-full  flex items-center gap-x-1 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                                    <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathname ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                                     )
                         }
                        </>
                    )
                })
            }
        </div>



            <div className=" flex justify-end items-center gap-3 ">
            <form className="flex items-center gap-3 mx-0" onSubmit={getData}>
            {/* <div className="hidden md:block font-medium text-sm">Project </div> */}
          {/* <Select
            style={{ width: '70%' }}
            options={[]}
            value={""}
            disabled={projectList.length >= 2 ? false : true}
            allowClear={formik.values[name]?true:false}
            name={name}
            placeholder={placeholder}
            onChange={handleProjectChange}
          /> */}
           {neededSearch &&  <SearchBar handleChange={searchFun}  value={searchValue}/>}

            </form>
            {newfilter && (
  <>
              <FilterButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <FilterButton label='Clear Filter'  handleClick={ClearFilter} />} </>)}
               {ImportAccess && <ImportButton label="Import" handleClick={()=>setOpen(true)}    />}

              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
           
           
            <div className=" w-full h-[calc(100vh-140px)]  ">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default ProcurementsubLayout;