import { Link, useLocation, useParams } from "react-router-dom";
import Dasboard from "../../../../Components/Icons/Dasboard";
import {
  MainHeading,
  NormalHeading,
  SecondHeading,
} from "../../../../Components/Utils/Heading";
import { BackButton, EditButton } from "../../../../Components/Utils/Buttons";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { GetRole } from "../../../../Components/Utils/roles";
import ViewIcon from "../../../../Components/Icons/ViewIcon";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";

const SubConView = ({ openModel, closeModel }) => {
  const { subConViewId,officeId} = useParams();
    const location = useLocation();
   
    const pageIndex = location.state?.pageIndex;
    const searchQuery = location.state?.searchQuery;
    
  const stateData = location?.state?.data;
  const [store,setStore]=useState(stateData? stateData:null)
  const initialProducts = stateData?.products ? stateData.products.split(", ").map((p) => p.trim()) : [];
  const [productList, setProductList] = useState(initialProducts);
  const initialEmails = stateData?.emails ? stateData.emails.split(", ").map((p) => p.trim()) : [];
  const [emailsList, setEmailsList] = useState(initialEmails);
  const initialServices = stateData?.services ? stateData.services.split(", ").map((p) => p.trim()) : [];
  const [servicesList, setServicesList] = useState(initialServices);
  const [showValidation, setShowValidation] = useState(false);

  const [data,setData] = useState(stateData? stateData:null); 
 
  

  function getViewnew() {
    axiosInstance
      .get(`/vendors/${subConViewId}`)
      .then((res) => {
        let response = res.data?.data;
        setData(response);     
        setStore(response);  
        setProductList(response?.products ? response.products.split(", ").map((p) => p.trim()) : []);     
        setEmailsList(response?.emails ? response.emails.split(", ").map((p) => p.trim()) : []);     
        setServicesList(response?.services ? response.services.split(", ").map((p) => p.trim()) : []);     

      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    if (!stateData) {
      getViewnew();
    }
  }, [subConViewId]);


  const [approval, setApproval] = useState(false);
  const [approvedStatus, setApprovedStatus] = useState(""); 
  const [showPopup, setShowPopup] = useState(false); 
const [reason, setReason] = useState("");   
 
  function getView() {
    axiosInstance
      .get(`/vendors/can-approve`)
      .then((res) => {
        setApproval(res.data?.data || false);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
 
  function updateApprovalStatus(approvalStatus, rejectionReason = "") {
    const payload = { approvalStatus };
    if (rejectionReason) {
      payload.reason = rejectionReason;
    }
  
    axiosInstance
      .put(`/vendors/edit-approval/${subConViewId}`, payload)
      .then((res) => {
        if(res.data.status === true){
          setStore({...store,approvalStatus:approvalStatus,reason:res.data.data.reason})

        }
        setApprovedStatus(approvalStatus); 
        setShowPopup(false); 
        setReason(""); 
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  useEffect(() => {
    getView();
  }, []);
  
  useEffect(() => {
    if (subConViewId) {
      setApprovedStatus(data?.approvalStatus || "");
    }
  }, [subConViewId, data]);

  const [showConfirm, setShowConfirm] = useState(false);

  const handleApproveClick = () => {
    setShowConfirm(true);
  };

  const confirmApproval = () => {
    // Call the approval function here
    updateApprovalStatus("approved");
    setShowConfirm(false);
  };

  const cancelApproval = () => {
    setShowConfirm(false);
  };

  const [isPopupOpenAccess, setIsPopupOpenAccess] = useState(false);

  const togglePopupAccess = () => {
    setIsPopupOpenAccess(!isPopupOpenAccess);
  };

return (
  <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-end ">
      {/* <Breadcrumb
        items={[
          {
            name: "sub-con",
            link: "/procurement/sub-con",
          },
          {
            name: "View sub-con",
          },
        ]}
      /> */}
      <div className=" flex gap-2">
      
 

        <Link to={`/main/${officeId}/contacts/sub-con`} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}>
          <BackButton link={true} />
        </Link>
        <Link
          to={`/main/${officeId}/contacts/sub-con/edit/${subConViewId}`}
          state={{
            data: data,
            from: `/main/${officeId}/contacts/sub-con/view/${subConViewId}`,
          }}
        >
          <EditButton height="8" />
        </Link>
      </div>
    </div>
    <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-y-scroll p-3">
 <div className='flex justify-between'>

      <NormalHeading title={data?.companyName} />
{/* <div className='flex gap-2' >
  <div className=' border border-[#048178]  overflow-hidden rounded-md  text-xs 2xl:text-sm flex items-center'>
 <p className='px-2 py-[5px] text-[#048178]'>Supplier Type :</p> <p className='bg-[#048178] text-white px-2 py-[5px]'>{store?.type||"-"}</p> 
  </div>
{GetRole() && (
<div>
<button
        className="bg-[#048178] px-2 py-[6px] cursor-pointer outline-none border-none text-white rounded-md  text-xs 2xl:text-sm flex items-center"
        onClick={togglePopupAccess}
      >
        Accessible User
      </button>
     

      
                </div>
)}

      <div
      className={` bg-white flex gap-1  text-xs 2xl:text-sm border rounded-md ${
        store?.approvalStatus === "pending"
          ? "border-yellow-500 text-yellow-500"
          : store?.approvalStatus === "approved"
          ? "border-green-500 text-green-500"
          : store?.approvalStatus === "rejected"
          ? "border-red-500 text-red-500"
          : "border-gray-500 text-gray-500"
      }`}
    >
      <div className="flex items-center">
        <p className="bg-white p-1 rounded-l-md flex gap-1">
          Status : {" "}
          {store?.approvalStatus === "rejected" && (
      <div className="relative group text-xs ">
        <ViewIcon color="red" />
        <div className="absolute right-0 transform -translate-y-2 border bg-white min-w-[8rem] max-w-[12rem] border-gray-300 text-gray-700 text-xs px-3 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          {store?.reason || "No reason provided"}
        </div>
      </div>
    )}
        </p>
        <p
          className={`${
            store?.approvalStatus === "pending"
              ? "bg-yellow-500 text-white"
              : store?.approvalStatus === "approved"
              ? "bg-green-500 text-white"
              : store?.approvalStatus === "rejected"
              ? "bg-red-500 text-white"
              : "bg-gray-500 text-white"
          } py-[5px] px-3 items-center rounded-r-md`}
        >
           {store?.approvalStatus?.charAt(0).toUpperCase() + store?.approvalStatus?.slice(1)}

        </p>
      </div>
     
      
    </div>
    
    
     

    {(approval || GetRole()) && (
  <div>
    {approval && store?.approvalStatus === "pending" && (
      <div className="flex justify-between items-center gap-x-2">
        <button
          className="text-xs 2xl:text-sm text-white bg-baseColor-primary rounded-md py-[6px] px-3"
          onClick={handleApproveClick}
        >
          Approve
        </button>
        <button
          className="text-xs 2xl:text-sm text-white bg-red-400 rounded-md py-[6px] px-3"
          onClick={() => setShowPopup(true)} 
        >
          Reject
        </button>
      </div>
    )}

    {approval && approvedStatus === "approved" && (
      <div className="flex justify-between items-center gap-x-2">
        <button
          className="text-xs 2xl:text-sm text-white bg-red-400 rounded-md py-[6px] px-3"
          onClick={() => setShowPopup(true)} 
        >
          Reject
        </button>
      </div>
    )}

    {approval && approvedStatus === "rejected" && (
      <div className="flex justify-between items-center gap-x-2">
        <button
          className="text-xs 2xl:text-sm text-white bg-baseColor-primary rounded-md py-[6px] px-3"
          onClick={handleApproveClick}
        >
          Approve
        </button>
      </div>
    )}
 
</div>
    )}
     </div> */}
      </div>
      <div className="flex">
        <div className="w-full  flex  ">
          <div className="w-full rounded-[20px]  bg-white py-4  flex  flex-col  ">
           
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Category
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.category}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Type
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.vendorType}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Phone
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.phoneWithCC}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Email
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]  break-words">{data?.email}</p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Website
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.website}
              </p>
            </div>
            {/* <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Is General?
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {" "}
                {data?.isGeneral == true ? "true" : "false"}
              </p>
            </div> */}
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                TaxId
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">{data?.taxId}</p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Contact Person{" "}
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.contactPerson}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Designation
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.designation}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Contact Person Email
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.contactPersonEmail}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Contact Person Mobile
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.cPMobileWithCC}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Performance Ratings
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.performanceRating}
              </p>
            </div>
          </div>
        </div>
        <div className="border-r border-[1px] border-slate-200/60 border-solid" />

        <div className="w-full  flex gap-3 ">
          <div className="w-full rounded-[20px]  bg-white py-4  flex  flex-col  ">
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Payment Terms
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.paymentTerms}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Delivery Terms
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.deliverTerms}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Street Address Line 1
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.address1 || ""}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Street Address Line 1
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.address2 || ""}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                City
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">{data?.city}</p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                State / Province
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">{data?.state}</p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Postal / Zip Code
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.postalCode}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Country
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.country}
              </p>
            </div>
          
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Comments
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.comments}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="w-full  pb-2 pt-4 px-4 text-sm font-medium text-[#111537]">
        Products , Services & Emails
      </p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full"></div>

      <div className="flex flex-col md:flex-row justify-start gap-2">
        <div className="w-full flex flex-col ">
          {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
          <p className="w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]">
            Products
          </p>
          <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
            {productList?.map((word, index) => (
              <span
                key={index}
                className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
              >
                {word}
                {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
              </span>
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col ">
          {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
          <p className="w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]">
            Services
          </p>
          <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
            {servicesList?.map((word, index) => (
              <span
                key={index}
                className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
              >
                {word}
                {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
              </span>
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col ">
          {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
          <p className="w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]">
            Emails
          </p>
          <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
            {emailsList?.map((word, index) => (
              <span
                key={index}
                className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
              >
                {word}
                {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
   
    {showPopup && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
    <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
      <h2 className="text-sm font-medium mb-3">Reject Action</h2>
      <p className="text-xs mb-4">
        Are you sure you want to <strong className="text-red-500">Reject</strong>?
      </p>
      <label htmlFor="reason" className="text-sm font-medium mb-2 block">
        Reason: <span className="text-red-500">*</span>
      </label>
      <textarea
        id="reason"
        className={`w-full border rounded-md p-2 mb-1 text-xs ${
          reason.trim() === "" && showValidation ? "border-red-500" : "border-gray-300"
        }`}
        rows="4"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="Please provide a reason"
      ></textarea>
      {reason.trim() === "" && showValidation && (
        <p className="text-red-500 text-xs mb-3">Reason is required!</p>
      )}
      <div className="flex justify-end gap-x-2">
        <button
          className="px-4 py-[7px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2] hover:bg-gray-300 rounded-md flex items-center mr-2 text-textColor-main"
          onClick={() => setShowPopup(false)} 
        >
          Cancel
        </button>
        <button
          className="bg-[#048178] px-4 py-[7px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
          onClick={() => {
            if (reason.trim()) {
              setShowValidation(false);
              updateApprovalStatus("rejected", reason); 
            } else {
              setShowValidation(true);
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  </div>
)}

{showConfirm && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
        <div className="animate-scale-in min-w-[25%] max-w-[50%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
          <h2 className="text-sm font-medium mb-3">Alert</h2>
          <p className="text-xs mb-4">Are you sure! you want to <span className='font-semibold'> Approve? </span></p>
            <div className="flex justify-end space-x-4">
              <button
                         className="px-4 py-[7px] cursor-pointer text-xs  bg-[#E6F2F2] hover:bg-gray-300 rounded-md flex items-center mr-2 text-textColor-main"

                onClick={cancelApproval}
              >
                Cancel
              </button>
              <button
                         className="bg-[#048178] px-4 py-[7px] cursor-pointer outline-none border-none text-white rounded-md text-xs flex items-center"

                onClick={confirmApproval}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
  {isPopupOpenAccess && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9998] flex justify-center items-center">
    <div className="bg-white rounded-md shadow-lg p-6 w-[40%] max-h-[80vh] overflow-y-auto relative">
      <div className="border-b border-gray-300 pb-2 mb-4">
        <h2 className="text-sm font-medium">Accessible Users: {data.users?.length || 0}</h2>
      </div>
      <button
        className="absolute top-2 right-2 text-white bg-red-600 w-6 h-6 flex items-center justify-center "
        onClick={togglePopupAccess}
      >
        ✕
      </button>

      {data.users?.length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          {data.users.map((user, index) => (
            <div
              key={user.userId}
              className="bg-gray-100 rounded-md shadow p-3 flex items-center justify-center text-xs font-medium"
            >
              {user.userName}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-xs text-gray-500">No users available</p>
      )}
    </div>
  </div>
)}

  </div>
);
};
export default SubConView;

function SubComponent({ label, value }) {
  return (
    <div className={` my-3 px-2`}>
      <div className=" w-full mx-auto">
        {/* <TextBold text={value} /> */}
        <h3 className="text-mainColor-400  line-clamp-2 font-semibold text-xs">
          {label || "-"}
        </h3>
        <h3 className="text-[#9A95A8] capitalize font-medium text-xs">
          {value || "-"}
        </h3>
        {/* <SmallHeading title={label} /> */}
      </div>
    </div>
  );
}
