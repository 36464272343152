import React from "react";
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend);



export default function DoughnutTwo() {
  const data = {
    labels:[],
      datasets: [
        {
          // label: [' '],
          data:[100],
          backgroundColor: [
            'rgba(220,220,220.5)',
          ],
          borderColor: [
            'rgba(0,255,255)',
        ],
        borderWidth: 0,
      },
      //  {
      //     data:dataWithLabel?.data||[],
      //     backgroundColor: [
      //       'rgba(75, 192, 192, 0.5)',
      //       'rgba(34, 139, 230, 0.3)',
      //       'rgba(34, 139, 230, 0.3)',
      //       'rgba(34, 139, 230, 0.3)',
      //     ],
      //     borderColor: [
      //       'rgba(255,255,255)',
      //       'rgba(255,255,255)',
      //       'rgba(255,255,255)',
      //       'rgba(255,255,255)',
      //   ],
      //   borderWidth: 1,
      // },
      
    ],
  };
  
  return(
      
    <Doughnut data={data}
        options={{
          plugins:{
            legend:false,
              title:{
                display:false
              },
              tooltip:{
                backgroundColor:'transparent',
              
               bodyFont:{
                 size:0
               },
               bodyColor:'transparent'
              }
            }
        }}
      />
    
  )
  
  
}