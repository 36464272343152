import React from "react";
import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import * as yup from "yup";
import { notification } from "antd";
import ViewFiles from "./FileView";
import Attachements from "./Attachements";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { BackButton, CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import { NuDataTime, NuDate, NuInput, NuLabel, NuSelect, NuTextArea } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import UseCustomerDropDownNew from "../../../../Components/Hooks/useCustomerSearch";


const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
// export const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];

export default function EditBids() {
  const {officeId} = useParams();
  // let {value:Category} = useFetchMataData("/Metadata/category")
  // let {value:Division} = useFetchMataData("/Metadata/division")
  // let {value:BiddingStatus} = useFetchMataData("/Metadata/biddingstatus")
  const { value: biddingStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "biddingStatus"
  );
  const [searchCustomer, setSearchCustomer] = useState("");
 
  const { data: customers, setDataCustomer } = UseCustomerDropDownNew(
    `/customers/drop-down/${officeId}/branch`,

    searchCustomer
  );
  const { value: biddingDivision } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "biddingDivision"
  );
  const location = useLocation();
  const data = location.state?.data;

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const AttachementArr = data?.attachments ? data?.attachments: [];
  const [image, setImage] = useState([]);
  const [status, setStatus] = useState([]);
  const [createNew, setCreateNew] = useState({
    projectName:"",
    clientId:"",
    referenceNo:"",
    dueDate:null,
    submittedOn:null ,
    submittedBy:null,
    submittedTo:null,
    comments:"",
    category:"",
    revision:"",
    bidValue:"",
    finalValue:"",
    biddingStatus:"",
    scopeOfWork:"",
    division:"",
    branchId:"",
  });


  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [proClient, setProClient] = useState([]);


  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    setImage(tempArr)
  }

  // const getStatus = () => {
  //   axios
  //     .get(`/Metadata/noteStatus`)
  //     .then((res) => {
  //       let data = res.data?.data?.keyValues;
  //       let tempData = [];
  //       data.forEach((item) => {
  //         let tempObject = { value: item, label: item };
  //         tempData.push(tempObject);
  //       });
  //       res.data?.data?.keyValues && setStatus(tempData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getClients = async () => {
    await axiosInstance
      .get(`/DropDown/${officeId}/client`)
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = { value: item.clientId, label: item.companyName };
          FinalData.push(tempObject);
        });
        response.data?.data && setProClient(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  // useEffect(() => {
  //   // getStatus();
  //   getClients()
  // }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
        projectName: yup.string().required("Project Name is Required"),
        // clientId: yup.string().required("Client "),
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      let formData = new FormData();

      // formData.append("attachments", image.raw);
      if (removefilePathList) {
        let tempArr = [];
        let finalArr = removefilePathList.filter(
          (items) => !filePathList.includes(items)
        );
        finalArr && finalArr.map(fileName=>{
          tempArr.push(fileName)
        })
        console.log("ccc",tempArr);
        value.files_to_remove = tempArr;
      } else {
        value.files_to_remove = [];
      }
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      formData.append("details", JSON.stringify(value));
      data
        ? axiosInstance
            .put(`/bidding/update_bidding/${data?.biddingId}`, formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage([]);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axiosInstance
            .post("/bidding/create_bidding", formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage([]);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });



  useEffect(() => {
    setCreateNew({
      projectName:data?.projectName||"",
      clientId:data?.clientId||"",
      referenceNo:data?.referenceNo||"",
      dueDate:data?.dueDate  ||null,
      submittedOn:data?.submittedOn ||null ,
      submittedBy:data?.submittedBy||null,
      submittedTo:data?.submittedTo||null,
      comments:data?.comments||"",
      category:data?.category||"",
      revision:data?.revision||"",
      bidValue:data?.bidValue||"",
      finalValue:data?.finalValue||"",
      biddingStatus:data?.biddingStatus||"",
      scopeOfWork:data?.scopeOfWork||"",
      division:data?.division||"",
      branchId:data?.branchId||"",
    })

    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]" &&
      data?.attachments.length > 0
    ) {
      setFilePathList(data?.attachments);
      setRemoveFilePathList(data?.attachments);
    }
  },[data])


  function UpdateAttachmentList(item) {
    console.log("rrr", item);
    
    let filterList = filePathList.filter((itemList) => itemList !== item);
    setFilePathList(filterList);
  }

  const { category: itemsCategory, subCategory: itemsSubCategory } =
  UseCatSubCatDropDown(
    "/category/ItemCategory",
    "ItemCategory",
    formik?.values?.category
  );

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-end w-full">
      {/* <Breadcrumb
          items={[
           
            { name:"Bidding",
              link:`/main/${officeId}/bidding/bids`
             },
            { name:"Edit" },
          ]}
        /> */}

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
      <div
          className=" w-full mx-auto h-[calc(100vh-145px)] rounded-md mt-2 overflow-y-scroll bg-white"
          // style={{ border: "1px solid #C5C5C5" }}
        >     <form onSubmit={formik.handleSubmit}>
          <div className=' w-full flex flex-wrap rounded-lg my-2'
          // style={{ border: "1px solid #ECEBF3" }}
          >
             <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                Bidding :
                </p>
              </div>
             
            <NuInput
 type="text"
                  label="Project Name"
                  isRequired={true}
                  placeholder="Project Name"
                  formik={formik}
                  name="projectName"
                  width="w-full md:w-1/2"
                />

                <NuInput
                 type="text"
                  label="Reference No"
                  isRequired={true}
                  placeholder="Reference No"
                  formik={formik}
                  name="referenceNo"
                  width="w-full md:w-1/4"
                />

                <NuInput
                 type="number"
                  label="Project Bid Value"
                  // isRequired={true}
                  placeholder="Project Bid Value"
                  formik={formik}
                  name="bidValue"
                  width="w-full md:w-1/4"
                />


                <NuInput
                 type="number"
                  label="Final Bid Value"
                  // isRequired={true}
                  placeholder="Final Bid Value"
                  formik={formik}
                  name="finalValue"
                  width="w-full md:w-1/4"
                />


                {/* <FormikSelectTwo
                  label="Client"
                  Options={proClient}
                  formik={formik}
                  placeholder="Choose"
                  name="clientId"
                  width="w-full md:w-1/4"
                  isRequired={true}
                /> */}

<FormikSelectTwo
                  disabled={true}
                  type="text"
                  label="Client "
                  placeholder="Client "
                  Options={customers}
                  formik={formik}
                  name="clientId"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />
                <NuDate 
                  label="Bidding Due Date"
                  formik={formik}
                  name="dueDate"
                //   min={NuDate({
                //     value: Date.now(),
                //     format: "yyyy-MM-DD",
                //   })}
                  width=" w-full md:w-1/4"
                />

                <NuDataTime
                  label="Submitted On"
                  formik={formik}
                  name="submittedOn"
                //   min={NuDate({
                //     value: Date.now(),
                //     format: "yyyy-MM-DD",
                //   })}
                  width=" w-full md:w-1/4"
                />

                {/* <NuInput
                 type="text"
                  label="Revision"
                  formik={formik}
                  placeholder="Revision"
                  name="revision"
                  width="w-full md:w-1/4"
                  // isRequired={true}
                /> */}

{/* <FormikSelectTwo
                  label="Category"
                  Options={Category||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full md:w-1/4"
                  // isRequired={true}
                /> */}
 <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Category"
              Options={itemsCategory}
              formik={formik}
              name="category"
              width="w-full md:w-1/4"
              // isRequired={true}
            />
                <FormikSelectTwo
                  label="Division"
                  Options={biddingDivision||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full md:w-1/4"
                  // isRequired={true}
                />


                <FormikSelectTwo
                  label="Status"
                  Options={biddingStatus||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="biddingStatus"
                  width="w-full md:w-1/4"
                  // isRequired={true}
                />

                <NuTextArea
                  label="Scope Of Work"
                  placeholder="Scope Of Work"
                  formik={formik}
                  name="scopeOfWork"
                  width="w-full md:w-1/2"
                />

                {/* <NuTextArea
                  label="Comments"
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full md:w-1/2"
                /> */}



<div className=" w-1/2 flex gap-2 items-center">
                {AttachementArr.length >0  &&
                  filePathList.map((imgs, idx) => {
                    console.log("img",imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg m-2"
                        key={idx}
                        style={{ border: "1px solid gray" }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs)}
                        >
                          x
                        </div>
                        <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                        <ViewFiles data={imgs} key={idx} />
                        </div>
                      </div>
                    );
                  })}

                <Attachements
                  handleChange={handleImageChange}
                  preview={image || data?.image || ""}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
              </div>

            </div>


            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
