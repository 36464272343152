import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData';

function UnitProgressMaterial() {
    return (
        <SettingsMetaData propertyName={"MaterialProgressUnit"} viewPropertyName={"Unit"} />
      );
}

export default UnitProgressMaterial
