import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { NormalHeading } from '../../../Components/Utils/Heading';
import Dasboard from '../../../Components/Icons/Dasboard';
import NuDate from '../../../Components/Utils/NuDate';
import Breadcrumb from '../../../Components/Utils/Breadcrumb';

function ViewEmployee() {
  const {officeId} = useParams()

    const location = useLocation();
    const data = location.state.data;
  return (
    <div className=" w-full h-full py-2 px-4 ">
        {/* <div className=""> */}
        <div className="md:flex justify-end w-full">
            {/* <Breadcrumb items={[{name:"Employees",
              link:`/main/${data?.branchId}/employees`
            },
            {name:"View Employess"}
            ]} /> */}
            <div className='flex justify-end gap-2'>
             <Link to={`/main/${officeId}/employees/${data?.userId}`}/><BackButton/><Link/>
             {/* <Link to={`/main/${officeId}/employees/edit/${data?.userId}`} state={{data: data ,  from: `/main/${officeId}/employees/view/${data?.userId}`,}}>
                <EditButton height='8'/>
                </Link> */}
            </div>
        </div>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll p-3 mt-4">
      <NormalHeading title={"Employee Details"} />
    <div className='flex'> 
         <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'> Name</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.name || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Email</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.email || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Phone</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.phone.display || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Gender</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.gender || '-'}</p>
                  </div>
                   
                
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Position</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.position || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Division</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.division}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Designation</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.designation || '-'}</p>
                  </div>         
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Current Project</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.currentProject || '-'}</p>
                  </div>
                  {/* {data?.designation === "salesman" && (
                    <>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Monthly Target</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.monthlyTar || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Quarterly Target</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.quarterlyTar || '-'}</p>
                  </div>
                 
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>HalfYear Target</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.halfYearTar || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Yearly Target</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.yearlyTar || '-'}</p>
                  </div>
                  </>
                )} */}
                 
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>   
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Street Address Line 1</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.address1 || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Street Address Line 2</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.address2 || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Building / Street Name</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.streetName || '-'} </p>
                  </div>
                 
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>City</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.city || '-'} </p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>District</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.district || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>State</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.state || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Country</p>
                    <p className='w-1/2 py-2  pl-5 text-xs  text-[#606279]'>{data?.country || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Zip Code</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.postalCode || '-'} </p>
                  </div>     
                
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.isActive == true ? "Active" :data?.isActive == false ? "In-Active" : "-"}</p>
                  </div>
                 
          </div>
        </div>
      </div>
              
          </div>
    </div>
  )
}

export default ViewEmployee