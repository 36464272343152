import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GetDashboardRole } from "./roles";

function Breadcrumb({ items }) {
  const {t} = useTranslation();
  const hasMutipleOffice = localStorage.getItem("hasmutiple")?JSON.parse(localStorage.getItem("hasmutiple")):false;
  const branchId = localStorage.getItem("branchId")?JSON.parse(localStorage.getItem("branchId")):1;

  return (
    <>
      <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li class="inline-flex items-center">
            <Link
              to={
              hasMutipleOffice ==true?`/main-page/dashboard`:
                              GetDashboardRole("")
                                ? `/main/${branchId}/dashboard`
                                : GetDashboardRole("sales")
                                ? `/main/${branchId}/dashboard/sales`
                                : GetDashboardRole("procurement")
                                ? `/main/${branchId}/dashboard/procurement`
                                : GetDashboardRole("store")
                                ? `/main/${branchId}/dashboard/stores`
                                : `/main/${branchId}/dashboard`
              }
              class="inline-flex items-center text-[11px]  text-[#4F4768] hover:text-blue-600"
            >
              <svg
                class="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              {t("Dashboard")}
            </Link>
          </li>
          {items && items.map((item, index, row) => {
            if (index + 1 === row.length) {
              return (
                <li key={index}>
                <div class="flex items-center">
                  <svg
                    class="rtl:rotate-180 w-[10px] h-[10px] text-[#4F4768] mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <p
                    class="ms-1 text-[11px] font-medium text-[#4F4768]  md:ms-2 cursor-not-allowed"
                  >
                    <p className=' text-[12px] '>{t(item.name) || ''}</p>
                  </p>
                </div>
              </li>

              );
            } else {
              return (
                <li key={index}>
                  <div class="flex items-center">
                    <svg
                      class="rtl:rotate-180 w-[10px] h-[10px] text-[#4F4768] mx-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <Link
                      to={item?.link||"#"}
                      class="ms-1 text-[11px]  text-[#4F4768] hover:text-blue-600 md:ms-2"
                    >
                      <p className=' text-[12px] '>{t(item.name) || ''}</p>
                    </Link>
                  </div>
                </li>
              );
            }
          })}
        </ol>
      </nav>
    </>
  );
}

export default Breadcrumb;
