import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import NuDate from '../../../../Components/Utils/NuDate';

const ViewAppoinment = () => {
  // const { patientsViewId } = useParams();
   const location = useLocation();
    const data = location.state.data;
    const pageIndex = location.state?.pageIndex;
    const searchQuery = location.state?.searchQuery;
    
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb
         items={
          [
            {
              name: "Marketing",
              link: "/marketing/appointments",
            },  
          {
            name: "Appointment",
            link: "/marketing/appointments"
          },
          {
            name: "View Appointment"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/marketing/appointments'} state={{currentPage:pageIndex,searchQuery:searchQuery!=""?searchQuery:""}}><BackButton link={true}/></Link>
          <Link to={`/marketing/appointments/edit/${data?.appointmentId}`} state={{data: data, from:`/marketing/appointments/view/${data.appointmentId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll mt-4 p-3'>
    {/* <h2 className='text-[14px] font-semibold text-[#111537] '>{data?.title}</h2> */}
    <NormalHeading title={"Appointment Detail"} />
    <div className='flex'> 
         <div className='w-full  flex '>
   
    <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
                {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Appoinment ID</p>
                    <p className='w-1/2 py-2 truncate pl-5  text-xs  text-[#606279]'>{data?.appointmentId || '-'}</p>
                  </div> */}
                   <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Title</p>
                     <p className='w-1/2 py-2  truncate pl-5 text-xs  text-[#606279]'>{data?.title || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.visitPurpose || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Company Name</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.visitPurpose || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Industry</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.industry || '-'}</p>
                  </div>
                   <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Visit Purpose</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.visitPurpose || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Appoinment Date </p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.dateTime ? <NuDate value={data?.dateTime} format="DD-MMM-YYYY hh:mm a" /> : '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Location</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.Location || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Priority</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.Priority|| '-'}</p>
                  </div>
                   
                 
                  </div>
</div>
              <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


<div className='w-full  flex  '>
<div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>

                 
<div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Reminder</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.reminder ? <NuDate value={data?.reminder} format="DD-MMM-YYYY hh:mm a" /> : '-'}</p>
                  </div>

                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Holder Type</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.holderType || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.status || '-'}</p>
                  </div>
                   <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                     <p className='w-1/2 py-2 truncate pl-5 text-xs  text-[#606279]'>{data?.comments || '-'}</p>
                  </div>
                  </div>
                  </div>
                  </div>
          </div>
          
      </div>
  
  )
};
export default ViewAppoinment;