import React, { useEffect, useState } from "react";
import TimesheetLayout from "../TimesheetLayout";
import { Popover, Select } from "antd";
import axiosInstance from "../../../../../../Services/axiosCommon";
import { useNumber } from "../../../../../../Context/ProjectIdContext";
import { useLocation, useParams } from "react-router-dom";
import { GetAccess } from "../../../../../../Components/Utils/roles";
import ArrowLeft from "../../../../../../Components/Icons/ArrowLeft";
import ArrowRight from "../../../../../../Components/Icons/ArrowRight";

function TimesheetsIndex() {
  const { officeId } = useParams();
  const { projectId } = useParams();
  const [projectList, setProjectList] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  // const { number, updateNumber } = useNumber();
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [monName, setMonName] = useState("");
  const [days, setDays] = useState([]);
  const [employeeAttendes,setEmployeeAttendes] = useState([]);
  console.log("bbb",currentMonth);

  const monthList = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  function daysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  function getMonth(value = "") {
    let displayMonth = monthList[value !== "" ? value : new Date().getMonth()];
    setMonName(displayMonth);
  }

  function getDayName(year, month, day) {
    const date = new Date(year, month, day);
    const dayName = date.toLocaleDateString("en-US", { weekday: "short" }); // 'short' for "Mon", "Tue", etc.
    return dayName;
  }

  useEffect(() => {
    getMonth(currentMonth);

    const daysInCurrentMonth = daysInMonth(currentYear, currentMonth);
    const daysArray = Array.from({ length: daysInCurrentMonth }, (_, i) => {
      return {
        monthName: monName,
        dateNum: `${i + 1}`,
        dayName: getDayName(currentYear, currentMonth, i + 1),
      };
    });
    setDays(daysArray);
  }, [currentMonth, currentYear, monName]);

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => {
      if (prevMonth === 0) {
        setCurrentYear((prevYear) => prevYear - 1);
        return 11;
      }
      return prevMonth - 1;
    });
  };

  const handleNextMonth = () => {
    setCurrentMonth((nextMonth) => {
      if (nextMonth === 11) {
        setCurrentYear((nextYear) => nextYear + 1);
        return 0;
      }
      return nextMonth + 1;
    });
  };

  const handleYearChange = (event) => {
    setCurrentYear(parseInt(event.target.value, 10));
  };

  const handleMonthChange = (event) => {
    setCurrentMonth(parseInt(event.target.value, 10));
  };

  // async function getProject() {
  //   await axiosInstance
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {
  //       if (number != 0) {
  //         setFirstProject(number);
  //       } else if (projectTask) {
  //         console.log("DD", projectTask);
  //         setFirstProject(projectTask);
  //         updateNumber(projectTask);
  //       } else {
  //         res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
  //         updateNumber(res?.data?.data[0]?.ProjectId);
  //         console.log("dd", res?.data?.data[0]?.ProjectId);
  //       }
  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       (await res?.data?.data) &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);

  //       // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
  //       // console.log('====',res?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  async function getMembersByProject(projectId) {
    console.log("fgfd",projectId);
    await axiosInstance
      .get(`/Attendance/project/${projectId}/month/${currentMonth + 1}/year/${currentYear}`)
      .then((res)=>{
        console.log("find",res?.data?.data);
        res?.data?.data && setEmployeeAttendes(res?.data?.data)
      }).catch((error)=>{
        if (error.response && error.response.status === 404) {
          setEmployeeAttendes([]);
        }
        console.log(error);
      })
  }

  // function handleProjectChange(e) {
  //   setFirstProject(e);
  //   updateNumber(e);
  // }

  // useEffect(() => {
  //   getProject();
  // }, []);

  useEffect(() => {
    // if (firstProject) {
      getMembersByProject(projectId);
    // }
  }, [currentMonth,currentYear]);

  // useEffect(() => {
  //   // console.log("jj",projectNum);
  //   if (projectTask) {
  //     setFirstProject(projectTask);
  //   }
  // }, [projectTask]);

  // const status = ["A", "P"];
  // const employees = [
  //   {
  //     name: "Priti Jani",
  //     role: "UI/UX Designer",
  //     attendance: Array.from({ length: daysInMonth(currentYear, currentMonth) }, () => status[Math.floor(Math.random() * status.length)])
  //   },
  //   {
  //     name: "John ",
  //     role: "React Developer",
  //     attendance: Array.from({ length: daysInMonth(currentYear, currentMonth) }, () => status[Math.floor(Math.random() * status.length)])
  //   },
  //   {
  //     name: "David",
  //     role: "React Developer",
  //     attendance: Array.from({ length: daysInMonth(currentYear, currentMonth) }, () => status[Math.floor(Math.random() * status.length)])
  //   },
  //   {
  //     name: "Mary",
  //     role: "Manager",
  //     attendance: Array.from({ length: daysInMonth(currentYear, currentMonth) }, () => status[Math.floor(Math.random() * status.length)])
  //   },
  // ];

  return (
    <>
      <TimesheetLayout CreateAccess={GetAccess()} ImportAccess={GetAccess()} officeId={officeId} projectId={projectId}>
        <div className=" w-full rounded-lg flex justify-between items-center my-2">
          <div className="flex justify-start gap-2 ml-3">
            <p className=" text-xs font-semibold">
              Employees : {employeeAttendes.length}
            </p>

          </div>
          {/* <div className=" w-3/12 2xl:w-2/12">
          <Select
              style={{ width: "100%" }}
              options={projectList}
              value={projectList.find((i) => i.value == firstProject) || ""}
              disabled={projectList.length >= 2 ? false : true}
              // allowClear={formik.values[name]?true:false}
              // name={name}
              // placeholder={placeholder}
              onChange={handleProjectChange}
            />
          </div> */}

          <div className=" flex gap-2 items-center mt-1">
            <button
              onClick={handlePrevMonth}
              className=" outline-none border-none bg-white w-7 h-7 rounded-lg cursor-pointer flex justify-center items-center"
              style={{ border: "1px solid #d6d3d1" }}
            >
              <ArrowLeft color="#d6d3d1" />
            </button>
            <span className=" text-xs font-medium mx-1 py-[6px] px-3 rounded-lg bg-gray-100 ">
              <select
                className=" px-2 outline-none border-none text-xs font-medium bg-gray-100"
                value={currentMonth}
                onChange={handleMonthChange}
              >
                {monthList.map((month, index) => (
                  <option
                    className="text-xs font-medium bg-white"
                    key={index}
                    value={index}
                  >
                    {month}
                  </option>
                ))}
              </select>
              <select
                className=" px-2 outline-none border-none text-xs font-medium bg-gray-100"
                value={currentYear}
                onChange={handleYearChange}
              >
                {Array.from({ length: 21 }, (_, i) => currentYear - 10 + i).map(
                  (year) => (
                    <option
                      className="text-xs font-medium bg-white"
                      key={year}
                      value={year}
                    >
                      {year}
                    </option>
                  )
                )}
              </select>
            </span>
            <button
              onClick={handleNextMonth}
              className=" outline-none border-none w-7 h-7 bg-white rounded-lg cursor-pointer flex justify-center items-center"
              style={{ border: "1px solid #d6d3d1" }}
            >
              <ArrowRight color="#d6d3d1" />
            </button>
          </div>

          <div className=" flex gap-2 items-center mr-3">
            <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-red-600 font-semibold"
              style={{ border: "1px solid #fca5a5" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-red-300 rounded text-red-600 flex justify-center items-center">
                A
              </div>
              Absent
            </div>
            <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-green-600 font-semibold"
              style={{ border: "1px solid #86efac" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-green-300 rounded text-green-600 flex justify-center items-center">
                P
              </div>
              Present
            </div>
            {/* <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-violet-600 font-semibold"
              style={{ border: "1px solid #c4b5fd" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-violet-300 rounded text-violet-600 flex justify-center items-center">
                OT
              </div>
              Over Time
            </div> */}
            {/* <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-teal-600 font-semibold"
              style={{ border: "1px solid #5eead4" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-teal-200 rounded text-teal-600 flex justify-center items-center">
                Wo
              </div>
              Week Off
            </div>
            <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-blue-600 font-semibold"
              style={{ border: "1px solid #93c5fd" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-blue-300 rounded text-blue-600 flex justify-center items-center">
                PL
              </div>
              Paid Leave
            </div>
            <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-yellow-600 font-semibold"
              style={{ border: "1px solid #fef08a" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-yellow-200 rounded text-yellow-600 flex justify-center items-center">
                H
              </div>
              Holiday
            </div>
            <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-sky-600 font-semibold"
              style={{ border: "1px solid #bae6fd" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-sky-200 rounded text-sky-600 flex justify-center items-center">
                HD
              </div>
              Half Day
            </div>
            <div
              className=" p-1 rounded-lg text-[11px] flex items-center gap-2 text-stone-600 font-semibold"
              style={{ border: "1px solid #d6d3d1" }}
            >
              <div className=" w-5 h-5 text-[11px] font-medium bg-stone-300 rounded text-stone-600 flex justify-center items-center">
                UL
              </div>
              Unpaid Leave
            </div> */}
          </div>
        </div>

        <div className=" w-full h-[calc(100vh-215px)] overflow-y-auto">
          <table
            width={"100%"}
            className=" border-collapse auto sticky top-0 z-[99]"
            style={{ border: "1px solid #d6d3d1" }}
          >
            <thead className=" bg-gray-100">
              <tr>
                <th className=" w-[14%] text-left font-medium text-xs pl-2">
                  Name
                </th>
                {days.map((day, i) => (
                  <React.Fragment key={i}>
                    <th
                      className=" min-w-[20px] max-w-[20px] p-1"
                      style={{
                        borderLeft: "1px solid #d6d3d1",
                        borderRight: "1px solid #d6d3d1",
                      }}
                    >
                      <div>
                        <p className=" text-[11px] font-normal">
                          {day?.monthName || "-"}
                        </p>
                        <p className=" text-xs font-semibold">
                          {day?.dateNum || "#"}
                        </p>
                        <p className=" text-[11px] font-semibold">
                          {day?.dayName || "-"}
                        </p>
                      </div>
                    </th>
                  </React.Fragment>
                ))}
                <th className=" min-w-[20px] max-w-[20px] p-1"
                      style={{
                        borderLeft: "1px solid #d6d3d1",
                        borderRight: "1px solid #d6d3d1",
                      }}>
                  <p className=" text-[12px] font-medium">Total</p> 
                </th>
              </tr>
            </thead>
          </table>

          <table
            width={"100%"}
            className=" border-collapse auto"
            style={{ border: "1px solid #d6d3d1" }}
          >
            <tbody className="">
              {employeeAttendes.map((employee, index) => 
                // console.log("atttttaaa",employee?.attendances)
              (
                <tr
                  key={index}
                  style={{
                    borderTop: "1px solid #d6d3d1",
                    borderBottom: "1px solid #d6d3d1",
                  }}
                >
                  <td className="w-[14%] text-left text-xs py-2 pl-2">
                    <div className="w-full h-full flex items-center gap-2">
                      <div className=" w-8 h-8 bg-slate-500 rounded-full"></div>
                      <div>
                        <p className=" font-medium">{employee?.userName}</p>
                        <p className=" font-normal text-[11px]">
                          {employee?.userRole}
                        </p>
                      </div>
                    </div>
                  </td>
                  {employee.attendances.map((status, idx) => 
                  // console.log("sss",status?.status)
                  (
                    <td
                      className=" min-w-[20px] max-w-[20px] p-1"
                      style={{
                        borderLeft: "1px solid #d6d3d1",
                        borderRight: "1px solid #d6d3d1",
                      }}
                      key={idx}
                    >
                      {status?.status == 1? (
                        <Popover content={<>
                           <div className=" w-36">
                          <p className=" text-[13px] font-medium">Working Details :</p>
                          <p className=" text-xs ">Working Hour: {status?.working_hours}</p>
                          <p className=" text-xs ">Over Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {status?.over_time}</p>
                           </div>
                          </>}>
                          <div
                            className={`w-full h-full ${
                                status?.status == 2
                                ? "bg-red-300 text-red-600"
                                : status?.status == 1
                                ? "bg-green-300 text-green-600"
                                : status?.status == 0
                                ? "bg-violet-300 rounded text-violet-600"
                                : ""
                            } rounded flex cursor-pointer justify-center items-center text-xs font-medium py-2`}
                          >
                            {status?.status == 2
                                ? <p>A</p>
                                : status?.status == 1
                                ? <p>P</p>
                                : status?.status === 0
                                ? <p>-</p>
                                : ""}
                          </div>
                        </Popover>
                      ) : (
                        <div
                          className={`w-full h-full 
                          ${
                            status?.status == 2
                              ? "bg-red-300 text-red-600"
                              : status?.status == 1
                              ? "bg-green-300 text-green-600"
                              : status?.status == 0
                              ? "bg-violet-300 rounded text-violet-600"
                              : ""
                          } 
                          rounded flex justify-center items-center text-xs font-medium py-2`}
                        >
                          {status?.status == 2
                                ? <p>A</p>
                                : status?.status == 1
                                ? <p>P</p>
                                : status?.status === 0
                                ? <p>-</p>
                                : ""}
                        </div>
                      )}
                    </td>
                  ))}
                  <td  className=" min-w-[20px] max-w-[20px] p-1 text-xs "
                      style={{
                        borderLeft: "1px solid #d6d3d1",
                        borderRight: "1px solid #d6d3d1",
                      }}>
                        {`${employee?.working_days}/${employee?.attendances.length}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </TimesheetLayout>
    </>
  );
}

export default TimesheetsIndex;
