import React, { useEffect, useState } from 'react';
import { CancelButton, CreateButton, ExportButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import MoreOptions from '../../../../Components/Utils/MoreOptions';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import Card from '../../../../Components/Icons/card';
import List from '../../../../Components/Icons/list';
import Select from 'react-select'
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import SupplierCard from './SupplierCard';
import Pagination from '../../../../Components/Utils/Pagenation';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NuInput } from '../../../../Components/Utils/Input';
import useFetchMetaStar from '../../../../Components/Hooks/useFetchMetaStar';
import { CountryMIN } from '../../../../assets/CountryMini';
import Importdoc from './Importdoc';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { GetAccess, GetRole } from '../../../../Components/Utils/roles';
import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';
import useFetchMetaStarIndex from '../../../../Components/Hooks/useFetchMetaStarIndex';
import MultiEdit from './MultiEdit';
import axiosInstance from '../../../../Services/axiosCommon';
import CloseIcon from '../../../../Components/Icons/Close';
import DataFormat from "../../../../Components/Utils/NuDate"
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { usePDF } from '@react-pdf/renderer';
import ContactsLayout from '../ContactsLayout';

function SuppliersIndex() {
   const {officeId} =useParams();
  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
  const [searchBy, setSearchBy] = useState("all");
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const { data: user } = UseDropDown("/user/drop-down");
  const { value:vendorRating } = useFetchMetaStarIndex(`/dropdown/dropdown-details/`,'vendorRating_supplier',showFilterDialog ||showExport)
  const { value:vendorStatus } = useFetchMDIndex(`/dropdown/dropdown-details/`,'status_supplier',showFilterDialog ||showExport)
  const {value:vendorType } = useFetchMDIndex(`/dropdown/dropdown-details/`,'SupplierType',showFilterDialog ||showExport)
  const {value:supplierCat } = useFetchMDIndex(`/dropdown/dropdown-details/`,'SupplierCategory',showFilterDialog ||showExport)
  const [showEdit, setShowEdit] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const [filterProps, setFilterProps] = useState({
    country:"",
    createdBy:"",
    isGeneral:"",
    vendorStatus:"",
    performanceRating:"",
    vendorType:"",
    category:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",

    searchBy: "",
    country:"",
    createdBy:"",
    isGeneral:"",
    vendorStatus:"",
    performanceRating:"",
    category:"",
    vendorType:""
  });
  const clearFilter = ()=> {
    setParams({...params, country:"",
    vendorStatus:"",
    createdBy:"",
    isGeneral:"",
    performanceRating:"",category:"",vendorType:""})
    formik.values.performanceRating=""
    formik.values.vendorStatus=""
    formik.values.country=""
    formik.values.createdBy=""
    formik.values.isGeneral=""

    formik.values.vendorType=""
    formik.values.category=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,performanceRating:value.performanceRating, vendorStatus:value.vendorStatus,country:value.country,createdBy:value.createdBy,isGeneral:value.isGeneral,category:value.category,vendorType:value.vendorType})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        country:"",
        vendorStatus:"",
        createdBy:"",
        isGeneral:"",
        performanceRating:"",
        vendorType:"",
        category:""
      });
    }
  })

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 

if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }



 
  const { data, totalCount, getData, credit, advance,dataLoading } = UseGetDataSearch(`/vendors/search/${officeId}/branch/1/type`, params );
  
  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{     
      tempArr.push(i?.vendorId)
    });
    setFullList(tempArr)
  },[data])


  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      field: "vendorId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company ",
      width: "10%",
      isLink: true,
      id: "vendorId",
      link: `/main/${officeId}/contacts/suppliers/view/`,
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Location",
      width: "10%",
      field: "city",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person",
      width: "10%",
      field: "contactPerson",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Mobile",
      width: "10%",
      field: "phoneWithCC",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Email",
      width: "10%",
      field: "email",
      type: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Created By",
      width: "10%",
      field: "createdByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Private / Public",
      width: "10%",
      field: "type",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        id: "vendorId",
        removeValue: "Vendors",
        url: "/vendors/",
        moduleName: "Suppliers",
        from: `/main/${officeId}/contacts/suppliers`,
        editLink: `/main/${officeId}/contacts/suppliers/edit/`,
        viewLink: `/main/${officeId}/contacts/suppliers/view/`,
      },
    },
  ]

  

  let availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "vendorId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company ",
      width: "15%",
      isLink: true,
      id: "vendorId",
      link: `/main/${officeId}/contacts/suppliers/view/`,
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Location",
      width: "10%",
      field: "city",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person",
      width: "10%",
      field: "contactPerson",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Mobile",
      width: "10%",
      field: "phoneWithCC",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Email",
      width: "10%",
      field: "email",
  type: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Created By",
      width: "10%",
      field: "createdByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Website",
      width: "10%",
      field: "website",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Designation",
      width: "10%",
      field: "designation",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Building No",
      width: "10%",
      field: "buildingNo",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person Email",
      width: "10%",
      field: "contactPersonEmail",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person No.",
      width: "10%",
      field: "contactPersonMobile.display",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Tax",
      width: "10%",
      field: "taxId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Street",
      width: "10%",
      field: "streetName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Country",
      width: "10%",
      field: "country",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "State",
      width: "10%",
      field: "state",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "District",
      width: "10%",
      field: "district",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Postal Code",
      width: "10%",
      field: "postalCode",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "vendorId",
        removeValue: "Vendors",
        url: "/vendors/",
        moduleName: "Suppliers",
        from: `/main/${officeId}/contacts/suppliers`,
        editLink: `/main/${officeId}/contacts/suppliers/edit/`,
        viewLink: `/main/${officeId}/contacts/suppliers/view/`,
      },
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '33px',
      height: '33px',
      fontSize: '12px',
      minWidth: '100px',
      width: '100px', 
      borderRadius: '8px',
      border: "none" 
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '4px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '4px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
  };

  let searchList = [
    { value: "all", label: "All" },
    { value: "services", label: "Services" },
    { value: "products", label: "Products" },
  ];

  function handleSearch(e) {
    setSearchBy(e.value);
    setParams({...params,searchBy:e.value})
  }

  useEffect(() => {
    setParams({...params,page:page,per_page:pageCount})
    localStorage.setItem("viewMode", viewMode);
  }, [page,pageCount,viewMode]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  

  const closeFilterDialogebox = () => {
    setShowExport(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

   const [exportValue, setExportValue] = useState({
    country:"",
    vendorStatus:"",
    performanceRating:"",
    vendorType:"",
    category:""
    });

  const ExportFormik = useFormik({
    enableReinitialize: true,
    initialValues: exportValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (value, { resetForm }) => {

      try {
        const response = await axiosInstance.get(`/customer-quotations/export-to-excel?approvalStatus=${value.approvalStatus}&salesPerson=${value.salesPerson}&status=${value.status}&leadTime=${value.leadTime}&validity=${value.validity}&availability=${value.availability}&paymentMethod=${value.paymentMethod}&paymentTerms=${value.paymentTerms}&deliveryTerms=${value.deliveryTerms}&fromDate=${value.fromDate}&toDate=${value.toDate}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element
       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `CQ Report ${DataFormat({value:new Date(), format:"DD-MMM-YYYY"})}.xlsx`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
    },
  });
  const [valueOfSearch,setValuesofSearch] = useState("")
  function searchFunction(e) {

    
    setValuesofSearch(e?.target?.value)
    setParams({ ...params, search: e?.target?.value, page:1 });
    setPage(1)
  }

  return (
    <>
    <ContactsLayout 
      setOpen={setShowImportModal}
      searchFun={searchFunction}
      searchValue={valueOfSearch}

      getData={getData}
      isCard={true}
      isFilter={filter}
      newfilter={true}
     setFilterOpen={setShowFilterDialog}
     ClearFilter={clearFilter}
  
      CreateAccess={GetAccess("suppliers_create")}
      ImportAccess={GetAccess("suppliers_create")}
      officeId={officeId}
      setViewMode={setViewMode}
      searchBy={searchBy}
      viewMode={viewMode}
      handleSearch={handleSearch}
      // projectId={projectId}
        >
    <div className='w-full h-full '>
      {/* <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Supplier",
            },

          ]}
        />
        <div className=' flex gap-2'> */}
        {/* <form
                className="flex items-center gap-3 mx-0 text-xs"
                onSubmit={SearchData}
        > */}
        {/* <Select
            options={searchList}
            value={searchList.find((i) => i.value == searchBy) || ""}
            styles={customStyles}
            className={`text-xs capitalize 2xl:text-sm font-medium `}
            onChange={handleSearch}
          />
 <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery} 
            /> */}
             {/* </form> */}
          {/* <div className='flex gap-2'>
            <div onClick={() => setViewMode("list")} className={viewMode === "list" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><List/></div>
            <div onClick={() => setViewMode("card")} className={viewMode === "card" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><Card/></div>
          </div>
         
          <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
          {/* {GetRole() && (
                      <ExportButton handleClick={() => setShowExport(true)} />
                    )} */}
          {/* {GetRole() &&  <ImportButton handleClick={handleImportClick} />}
          {GetAccess("suppliers_create") && <Link to={`/main/${officeId}/contacts/suppliers/create/`}><CreateButton label='Supplier' /></Link>}
        </div>
      </div> */}

      {viewMode === "list" ? (
        <CustomDataTable
        fetcher={getData}
          data={data}
          //  title='Supplier'
          availableHeaders={availableHeaders}
          defaultHeader={defaultHeader}
          bodyHeight="max-h-[calc(100vh-260px)]"
          height="h-[calc(100vh-160px)]"
          total={totalCount}
          pageIndex={params.page}
          setPageIndex={setPage}
          dataCount={params.per_page}
          setDataCount={setPageCount}
          selectOneFunction={selectOneFunction}
          setShowEdit={setShowEdit}
          showEdit={showEdit}
          listofNumbers={listofNumbers}
          hasMultiEdit={GetRole()?true:false}
          selectMultipleFunction={selectMultipleFunction}
          fullList={fullList}
           getById="vendorId"
          storageName='SupplierTable'
          editAccess="suppliers_edit"      
          deteleAccess="suppliers_delete" 
          dataLoading={dataLoading}
          searchQuery={params?.search!=""?params?.search:""}

        />
      ) : (
        <>
        <div className='w-full h-[calc(100vh-190px)] rounded-md bg-white p-3 mt-4 relative overflow-y-scroll'>
          <div className='flex gap-10 '>
        {/* {data && data.map((item, idx) => ( */}
         <p className='text-xs font-semibold text-[#111537] text-left'>Suppliers : {totalCount}</p>
         <p className='text-xs font-semibold text-[#111537]'>Credit : {credit}</p>
         <p className='text-xs font-semibold text-[#111537]'>Advance : {advance}</p>
        

        {/* ))} */}
        </div>
          <div className='w-full flex  flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4'>
            {data && data.map((item, idx) => (
            
              <SupplierCard fetcher={getData} item={item} key={idx} />
            ))}
          </div>
        </div>
        <div className="w-full h-[40px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
        </div>
        </>
      )}
      </div>
      </ContactsLayout>
        {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Supplier</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
                type="text"
                label="Type"
                placeholder="Choose"
                Options={vendorType}
                formik={formik}
                name="vendorType"
                  width="w-full "

              />  <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Choose"
              Options={supplierCat}
              formik={formik}
              name="category"
                width="w-full "

            />
                    <FormikSelectTwo
              Options={vendorRating}
              label="Performance Ratings"
              placeholder="performanceRatings"
              formik={formik}
              name="performanceRating"
              width="w-full "
            />
            <FormikSelectTwo
              Options={CountryMIN}
              label="Country"
              placeholder="Country"
              formik={formik}
              name="country"
              width="w-full"
            />
                       
                <FormikSelectTwo
              Options={user}
              label="Created By"
              placeholder="Created By"
              formik={formik}
              name="createdBy"
              width="w-full "
            />
             <FormikSelectTwo
              label="Access"
              placeholder="Access"
              Options={[
                {value:"public" , label:"Public"},
                {value:"private" , label:"Private"

                }
              ]}

              formik={formik}
              name="isGeneral"
              width="w-full "
            />
              <FormikSelectTwo
              Options={vendorStatus}
              label="Status"
              placeholder="Status"
              formik={formik}
              name="vendorStatus"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}

{showExport && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[50%] 2xl:min-w-[40%] max-w-[50%] 2xl:max-w-[40%] max-h-[80vh] relative bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <div className=" w-full flex justify-between items-center  bg-white">
              <h2 className=" text-sm 2xl:text-base font-medium ">
                Export Data
              </h2>
              <div
                className=" cursor-pointer "
                onClick={() => setShowExport(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <form onSubmit={ExportFormik.handleSubmit}>
              <div className=" w-full flex flex-wrap">
                <div className=" w-full flex ">
                  <div className=" w-1/2 flex items-center px-6 py-3 ">
                    <input
                      id="default-radio-1"
                      type="radio"
                      value="all"
                      name="type"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={ExportFormik.handleChange}
                      checked={ExportFormik.values.type =="all"}
                    />
                    <label
                      htmlFor="default-radio-1"
                      className="ms-2 text-xs 2xl:text-sm  text-gray-900 "
                    >
                      All Data
                    </label>
                  </div>

                  <div className="w-1/2 flex items-center px-6 py-3">
                    <input
                      id="default-radio-2"
                      type="radio"
                      value="filter"
                      name="type"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={ExportFormik.handleChange}
                      checked={ExportFormik.values.type == "filter"}
                    />
                    <label
                      htmlFor="default-radio-2"
                      className="ms-2 text-xs 2xl:text-sm  text-gray-900"
                    >
                      Filter Data
                    </label>
                  </div>
                </div>
                {ExportFormik.values.type === "filter" && (
                  <>
                       <FormikSelectTwo
                type="text"
                label="Type"
                placeholder="Choose"
                Options={vendorType}
                formik={ExportFormik}
                name="vendorType"
                  width="w-full "

              />  <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Choose"
              Options={supplierCat}
              formik={ExportFormik}
              name="category"
                width="w-full "

            />
                    <FormikSelectTwo
              Options={vendorRating}
              label="Performance Ratings"
              placeholder="performanceRatings"
              formik={ExportFormik}
              name="performanceRating"
              width="w-full "
            />
            <FormikSelectTwo
              Options={CountryMIN}
              label="Country"
              placeholder="Country"
              formik={ExportFormik}
              name="country"
              width="w-full"
            />
             <FormikSelectTwo
              Options={user}
              label="Created By"
              placeholder="Created By"
              formik={ExportFormik}
              name="createdBy"
              width="w-full "
            />
             <FormikSelectTwo
              label="Access"
              placeholder="Access"
              Options={[
                {value:"public" , label:"Public"},
                {value:"private" , label:"Private"

                }
              ]}

              formik={ExportFormik}
              name="isGeneral"
              width="w-full "
            />
                       
                <FormikSelectTwo
              Options={vendorStatus}
              label="Status"
              placeholder="Status"
              formik={ExportFormik}
              name="vendorStatus"
              width="w-full "
            />
                  </>
                )}
                <div className="flex w-full justify-end gap-2">
                  <CancelButton handleClick={closeFilterDialogebox} />
                  <SubmitButton name="Export" />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
        {showEdit && (<MultiEdit data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
 
  </>
  )
}
export default SuppliersIndex