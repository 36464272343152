// import { useFormik } from "formik";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
import { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import UseDropDown from "../../../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import UseEditData from "../../../../../../Components/Hooks/useEditData";
import { useMemo, useState } from "react";
// import {useMemo, useState } from "react";
// import { NuDate, NuInput, NuTextArea,} from "../../../../Components/Utils/Input";
// import UseEditData from "../../../../Components/Hooks/useEditData";
// import { useLocation, useParams } from "react-router-dom";
// import FormikSelectTwo from "../../../../Components/Utils/Select";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import NuDateDisplay from "../../../../Components/Utils/NuDate";
import { useFormik } from "formik";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
// import {   NuDate, NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
// import NuDateDisplay from "../../../../Components/Utils/NuDate";
import { NuDate, NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
// import NuDate from "../../../../../../Components/Utils/NuDate";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import axiosInstance from "../../../../Services/axiosCommon";


const EditInventory = () => {
  const {officeId,projectId} =useParams();

  const { data: grn } = UseDropDown('/grn/drop-down');
  const {value:InventoryCondition ,getData:refInventoryCondition} = useFetchMataData(`/dropdown/dropdown-details/`,'InventoryCondition')
  const {value:StockLocation ,getData:refStockLocation} = useFetchMataData(`/dropdown/dropdown-details/`,'StockLocation')
  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;

  const [createNew, setCreateNew] = useState({
    grnId: data?.grnId || "",
    productId: data?.productId || "",
    supplier: data?.supplier || "",
    batchNo: data?.batchNo || "",
    // unit: data?.unit || "",
    warrentyDuration: data?.warrentyDuration || "",
    warrentyEndDate: (data?.warrentyEndDate && NuDate({value:data?.warrentyEndDate,format:"YYYY-MM-DD"}) )|| "",
    condition: data?.condition || "",
    quantity: data?.quantity || "",
    mfdDate: (data?.mfdDate && NuDate({value:data?.mfdDate,format:"YYYY-MM-DD"}) )|| "",
    expDate: (data?.expDate && NuDate({value:data?.expDate,format:"YYYY-MM-DD"}) )|| "",
    mrp: data?.mrp || "",
    unitPrice: data?.unitPrice || "",
    purchasedDate: (data?.purchasedDate && NuDate({value:data?.purchasedDate,format:"YYYY-MM-DD"}) )|| "",
    stockLocation: data?.stockLocation || "",
    minStockLevel: data?.minStockLevel || "",
    barcode: data?.barcode || "",
    stockQty: data?.stockQty || "",
    adjustedQty: data?.adjustedQty || "",
    reasonForAdjust: data?.reasonForAdjust || "",
    warrentyApplicable: data?.warrentyApplicable || "",
    warrentyStartDate: (data?.warrentyStartDate && NuDate({value:data?.warrentyStartDate,format:"YYYY-MM-DD"}) )|| "",
    calibReq: data?.calibReq || "",
    calibExp: data?.calibExp || "",
    receivedQty: data?.receivedQty || "",
    issuedQty: data?.issuedQty || "",
    comments: data?.comments || ""
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      productId: yup.string().required('Product is required!'),
      grnId: yup.string().required('GRN Reference is required!')
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      editData(`/stocks/edit/${data.stockId}`, value, resetForm, from)
      // setLoading(false)

    },
  });

  const [grnlist, setGrnlist] = useState([]);

  function getGrn(num){
    axiosInstance.get(`/stocks/grn-products/${num}`)
    .then((response) => {
      let tempData =[]
      if(response.data.products){
        let x = response.data.products

        x.map((item) => {
        let y = {
          value: item.productId,
          label: item.product_name,
        }
        tempData.push(y)
        })
          formik.setFieldValue('supplier', response?.data?.supplier || '');

        setGrnlist(tempData)
      }
    }).catch((error) => {})
   }

   useMemo (()=>{
    if (formik.values.grnId !== '') {
      getGrn(formik.values.grnId);
    }
  }, [formik.values.grnId]);

  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
     {/* <Breadcrumb
          items={
            [{
              name: "Inventory",
              link: `/project/store/inventory/${officeId}/${projectId}`
            },
            {
              name: "Edit"
            }]
          }
        /> */}
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
        <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
           <FormikSelectTwo
              type="text"
              label="GRN"
              placeholder="Choose"
              Options={grn}
              formik={formik}
              name="grnId"
              disabled={true}
              isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              type="text"
              label="Product"
              placeholder="Choose"
              Options={grnlist}
              formik={formik}
              name="productId"
              isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
            />
            <NuInput
              type="text"
              label="Supplier"
              placeholder="Supplier"
              formik={formik}
              disabled={true}
              name="supplier"
               width="w-full md:w-1/2 lg:w-1/3"
            />
           
            <NuInput
              type="number"
              label="Batch No"
              placeholder="Batch No"
              formik={formik}
              name="batchNo"
               width="w-full md:w-1/2 lg:w-1/3"
            />

              <FormikSelectTwo
              label="Warrenty Applicable"
              placeholder="WarrentyApplicable"
              Options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              formik={formik}
              name="warrentyApplicable"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
            />
            {formik.values.warrentyApplicable === true && (
              <NuDate
              label="Warrenty Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyStartDate"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            )}
            {formik.values.warrentyApplicable === true && (
              <NuDate
              label="Warrenty End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyEndDate"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            )}
             <FormikSelectTwo
              type="text"
              label="Condition"
              placeholder="Choose"
              Options={InventoryCondition}
              formik={formik}
              name="condition"
                width="w-full md:w-1/2 lg:w-1/3"
            />                      
            <NuDate
              label="Manufacture Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="mfdDate"
               width="w-full md:w-1/2 lg:w-1/3" 
            />
            <NuDate
              label="Expiry Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="expDate"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="MRP"
              placeholder="MRP"
              formik={formik}
              name="mrp"
                width="w-full md:w-1/2 lg:w-1/3"
            />           

            <NuDate
              label="Purchased Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="purchasedDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              type="text"
              label="Stock Location"
              placeholder="Choose"
              Options={StockLocation}
              formik={formik}
              name="stockLocation"
                width="w-full md:w-1/2 lg:w-1/3"
            />            

            <NuInput
              type="number"
              label="Min Stock Level"
              placeholder="Min Stock Level"
              formik={formik}
              name="minStockLevel"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Barcode"
              placeholder="Barcode"
              formik={formik}
              name="barcode"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Issued Quantity"
              placeholder="Issued Qty"
              formik={formik}
              name="issuedQty"
                width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuInput
              type="number"
              label="Received Quantity"
              placeholder="Received Qty"
              formik={formik}
              name="receivedQty"
               width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Stock Quantity"
              placeholder="Stock Qty"
              formik={formik}
              name="stockQty"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Adjusted Quantity"
              placeholder="Adjusted Qty"
              formik={formik}
              name="adjustedQty"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Reason For Adjust"
              placeholder="Reason For Adjust"
              formik={formik}
              name="reasonForAdjust"
                width="w-full md:w-1/2 lg:w-1/3"
            />         
            <FormikSelectTwo
              label="Calibration Reqiure"
              placeholder="Choose"
              Options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              formik={formik}
              name="calibReq"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            {formik.values.calibReq === true && (
              <NuDate
              label="Calibration Expiry"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="calibExp"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            )}                    
             <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              new_width="w-full md:w-2/3"

                width="w-full md:w-1/2 lg:w-1/3"
            />       
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditInventory;
