import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import CategoryOthers from './Category'
import DivisionOthers from './Division'
import UnitOthers from './Unit'

function OtherManPowerindex() {
    return(
        <>
        <TextMediumSM content="Budget" />
        <TextMediumSM content="Others" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CategoryOthers/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DivisionOthers/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <UnitOthers/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <UnitMaterial/>
         </div> */}
         </div>
         </>
    )
}

export default OtherManPowerindex
