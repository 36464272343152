import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import App from "./App";
import Dashboard from "./Views/Web/Dashboard";
import Auth from "./Views/Main/MainAuth";
import Login from "./Views/Web/Auth/login";
import PageNotFound from "./Components/Utils/PageNotFound";
import PrivateRoute from "./Services/PrivateRouter";
import {
  DashboardRoutes,
  MaintenanceRoutes,
  MarketingRoutes,
  PaymentRoutes,
  ProcurementRoutes,
  ProductRoutes,
  ReportRoutes,
  SalesRoutes,
  SettingsRoutes,
  ShippingRoutes,
  StoreRoutes,
  TaskRoutes,
} from "./Routes";
import MainChat from "./Views/Web/chat/chatMain";
import ChatProfile from "./Views/Web/chat/chatProfile";
import ChatIndex from "./Views/Web/chat";
import ChangePassword from "./Views/Web/Auth/login/changePassword";
import AnotherLogin from "./Views/Web/Auth/login/AnotherIndex";
import DashboardLayout from "./Layouts/MainPage/Dashboard";
import UserProfile from "./Views/Web/Profile/userProfile";
import EditUserprofile from "./Views/Web/Profile/EditUserprofile";
import MainProfile from "./Views/Web/Profile/MainProfile";
import ChangeUserPassword from "./Views/Web/Profile/ChangeUserPassword";
import NotificationProfile from "./Views/Web/Profile/NotificationProfile";
import ItemRoutes from "./Routes/item.routes";
import BidRoutes from "./Routes/bid.routes";
import ProjectRoutes from "./Routes/project.routes";
import MembersRoutes from "./Routes/members.routes";
import NoteRoutes from "./Routes/note.routes";
import ContactsRoutes from "./Routes/contacts.routes";
import ReportsRoutes from "./Routes/Reports.routes";
import ReportsRoutess from "./Routes/Reports.routes";
import ProjectCostingRoutes from "./Routes/project.costing.routes";
import ProjectProgressRoutes from "./Routes/project.progress.routes";
import ProjectTimeRoutes from "./Routes/project.timesheet.routes";
import ProjectPettyCashRoutes from "./Routes/project.pettycash.routes";
import ProjectFinanceRoutes from "./Routes/project.finance.routes";
import ProjectTaskRoutes from "./Routes/project.task.routes";
import ProjectTeamRoutes from "./Routes/Projject.team.routes";
import ViewStart from "./ViewStart";
import Main from "./Main";
// import ViewProject from "./Views/Web/Project/viewProject";
import MainProjectPage from "./Views/Main/MainProject";
import OfficeIndex from "./Views/Web/Offices";
import ViewProject from "./Views/Web/Project/ProjectView";
import ProjectProcurementroutes from "./Routes/project.procurement.routes";
import projectStoreRoutes from "./Routes/project.store .routes";
import ProjectSalesroutes from "./Routes/project.sales.routes";
import { GetDashboardRole } from "./Components/Utils/roles";
// import projectStoreRoutes from "./Routes/project.store .routes";
// import ChatIndexScreen from "./Views/Web/chat/ChatIndexScreen";
// import ChatProfileScreen from "./Views/Web/chat/ChatProfileScreen";

function Mainroutes() {
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === ""  ||
      location.pathname === "main-page"
    ) {
      const hasMutipleOffice = localStorage.getItem("hasmutiple")?JSON.parse(localStorage.getItem("hasmutiple")):false;
      const branchId = localStorage.getItem("branchId")?JSON.parse(localStorage.getItem("branchId")):1;
      window.location.replace(
        hasMutipleOffice == true ? "/main-page/dashboard" 
        : GetDashboardRole("")
        ? `/main/${branchId}/dashboard`
        : GetDashboardRole("sales")
        ? `/main/${branchId}/dashboard/sales`
        : GetDashboardRole("procurement")
        ? `/main/${branchId}/dashboard/procurement`
        : GetDashboardRole("store")
        ? `/main/${branchId}/dashboard/stores`
        : `/main/${branchId}/dashboard`
      );
    }
    if (location.pathname === "/auth") {
      window.location.replace("/auth/login");
    }
    if(location.pathname === "/chat"){
      navigate("/chat/view")
    }
  }, [location]);



  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <ViewStart />
          </PrivateRoute>
        }
      >
        <Route path="main-page" element={<Main />}>
          <Route path="dashboard" element={<>Dashboard</>}/>
          <Route path="offices" element={<OfficeIndex/>}/>
        </Route>
        <Route path="main/:officeId" element={<App />}>
          {DashboardRoutes()}
          {ProductRoutes()}
          {ItemRoutes()}
          {BidRoutes()}
          {ProcurementRoutes()}
          {ProjectRoutes()}
          {StoreRoutes()}
          {SalesRoutes()}
          {PaymentRoutes()}
          {MembersRoutes()}
          {NoteRoutes()}
        {ContactsRoutes()}

        </Route>
        {ReportsRoutess()}
        {ProjectCostingRoutes()}
        {ProjectProgressRoutes()}
        {ProjectTimeRoutes()}
        {ProjectPettyCashRoutes()}
        {ProjectFinanceRoutes()}
        {ProjectTaskRoutes()}
        {ProjectTeamRoutes()}
        {ShippingRoutes()}
        {MarketingRoutes()}
        {/* {MaintenanceRoutes()} */}
        {/* {TaskRoutes()} */}
        {ReportsRoutess()}
        <Route path="/project" element={       
              <MainProjectPage />
        }>
         <Route path="dashboard/:officeId/:projectId" element={
              <ViewProject/>
          }>
          </Route>
          {ProjectCostingRoutes()}
          {ProjectProgressRoutes()}
          {ProjectTimeRoutes()}
          {ProjectPettyCashRoutes()}
          {ProjectFinanceRoutes()}
          {ProjectTaskRoutes()}
          {ProjectTeamRoutes()}
          {ProjectProcurementroutes()}
          {projectStoreRoutes()}
          {projectStoreRoutes()}
          {ProjectSalesroutes()}



        </Route>
        <Route path="chat" element={<MainChat />}>
          <Route path="view" element={<ChatIndex />} >
           <Route path="chatProfile/:chatID" element={<ChatProfile />} />
          </Route>
        </Route>
        {/* <Route path="chat-screen" element={<MainChat/>} >
          <Route path="" element={<ChatIndexScreen/>} >
             <Route path="chat-profile/:chatProfileId" element={<ChatProfileScreen/>}/>
          </Route>
        </Route> */}
         <Route path="/profile" element={<MainProfile  />}>
          <Route index element={<UserProfile />} />
          <Route path="editProfile/:profileid" element={<EditUserprofile />} />
          <Route path="PasswordProfile/:Passwordid" element={<ChangeUserPassword />} />
          <Route path="notificationProfile/:userId" element={<NotificationProfile />} />
        </Route>
      </Route>
      <Route path="/auth" element={<Auth />}>
        <Route path="login" element={<AnotherLogin />} />
        <Route path="forget-password" element={<ChangePassword />} />
      </Route>
      {SettingsRoutes()}
      <Route path="*" element={<PageNotFound />} />
      <Route path="page-not-found" element={<PageNotFound backbutton={false} />} />
    </Routes>
  );
}

export default Mainroutes;
