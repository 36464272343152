import * as yup from 'yup';
import useFetchMataData from '../../../../../../Components/Hooks/useFetchMataData';
import { Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { CancelButton, SubmitButton } from '../../../../../../Components/Utils/Buttons';
import { NuInput, NuSelect } from '../../../../../../Components/Utils/Input';

function MultiEditManpowerPro({open,onCancel,getData,seletedIds,projectId}) {
    //   console.log("finding",estiId);
      const {value:Unit} = useFetchMataData
      (`/Metadata/unit`)
      const [api, contextHolder] = notification.useNotification();
      const [isLoading,setIsLoading] = useState(false);
      const [createNew, setCreateNew] = useState({
        description: "",
        unit: "",
        budget_quantity: "",
        actual_quantity: "",
        budget_Price: "",
        actual_Price: "",
        amount: "",
        Planned_days:"",
        actual_days:"",
        role:""
      });
    
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema:yup.object({
        //   name:yup.string().required("Tittle is Requried")
        }),
        onSubmit: (value,{resetForm}) => {    
          setIsLoading(true);
          const values = {
            ...value,
            ids: seletedIds
          }
          axiosInstance
          .put(`/pro_manpower/multi-update`, values)
            .then((res) => {
              console.log(res);
              if (res.status === 201 || res.data.status == true) {
                setIsLoading(false);
                getData();
                resetForm();
                api.success({
                  message: `Material Multi Item Edit successfully`,
                  description: res?.data?.msg || "Added In Tittle",
                  placement: "bottomRight",
                });
                onCancel();
              } else {
                api.error({
                  message: `Material Multi Item Edit failed`,
                  description: res?.data?.msg || "Network error",
                  placement: "bottomRight",
                });
                setIsLoading(false);
              }
            })
            .catch((err) => {
              api.error({
                message: `Material Multi Item Edit failed`,
                description: err?.data?.msg || "Sever error (or) Network error",
                placement: "bottomRight",
              });
              setIsLoading(false);
            });
        },
      });
    
      useEffect(() => {
        const totalPrice = formik?.values?.actual_quantity * formik?.values?.actual_Price
        formik.setFieldValue("totalPrice", totalPrice);
      }, [formik?.values?.actual_quantity,formik?.values?.actual_Price]);
    
      return (
        <>
        {contextHolder}
        <Modal
          open={open}
          title={"Multi Edit Material Item"}
          width={"50%"}
          className="my-modal"
          centered
          footer={[]}
          onCancel={onCancel}
        >
        <form onSubmit={formik.handleSubmit} >
          <div className=" w-full flex flex-wrap">
             {/* <NuInputText
                  label="Item Name"
                  placeholder="Item Name"
                  formik={formik}
                  name="item"
                  width="w-full"
              /> */}
              <NuSelect
                      label="Unit"
                      options={ Unit || [] }
                      formik={formik}
                      placeholder="Choose"
                      name="unit"
                      width="w-full md:w-1/3"
                    />
                    <NuSelect
                  label="Role"
                  options={[
                    {value: "Electrician", label:"Electrician"},
                    {value: "Plumber", label:"Plumber"},
                    // {value: "ton", label:"ton"},
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="role"
                  width="w-full md:w-1/3"
                />
                      <NuInput
                                   type="number"

                label="Planned Days"
                placeholder="Planned Days "
                formik={formik}
                name="Planned_days"
                width="w-full md:w-1/3"
              />
               <NuInput
                            type="number"

                label="Actual Days"
                placeholder="Actual Days "
                formik={formik}
                name="actual_days"
                width="w-full md:w-1/3"
              />
               <NuInput
                            type="number"

                  label="Budget Quantity"
                  placeholder="Budget Quantity"
                  formik={formik}
                  name="budget_quantity"
                  width="w-full md:w-1/3"
                />
                <NuInput
                             type="number"

                  label="Budget Price"
                  placeholder="Budget Price"
                  formik={formik}
                  name="budget_Price"
                  width="w-full md:w-1/3"
                />
                <NuInput
                             type="number"

                  label="Actual Quantity"
                  placeholder="Actual Quantity"
                  formik={formik}
                  name="actual_quantity"
                  width="w-full md:w-1/3"
                />
                <NuInput
                             type="number"

                  label="Actual Price"
                  placeholder="Actual Price"
                  formik={formik}
                  name="actual_Price"
                  width="w-full md:w-1/3"
                />
                {/* <NuInput
                             type="number"

                  label="Total Price"
                  placeholder="Total Price"
                  formik={formik}
                  name="totalPrice"
                  width="w-full md:w-1/3"
                  disable={true}
                /> */}
                <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
                  <CancelButton handleClick={onCancel} />
                  <SubmitButton isLoading={isLoading} />
                </div>
          </div>
          </form>
        </Modal>
        </>
      )
    }
    
    export default MultiEditManpowerPro;