import React from 'react'

const Bids = () => {
  return (
    <div className="bg-white p-4 rounded-lg drop-shadow-lg h-[45vh]  2xl:h-[35vh] w-full">
      <div className='text-center'>
                       <h2 className=' font-semibold text-[#4F4768] text-sm'>Bids</h2>
                       <p className='text-[#3B2DB2] font-semibold'>138</p>
                       </div>
                       <div className='flex w-full my-2 text-xs justify-between'>
                        <p className='text-[#4F4768] '>Submitted</p>
                        <p className='text-[#007BEC] font-medium'>5</p>

                       </div>
                       <div className='flex w-full my-2 text-xs justify-between'>
                        <p className='text-[#4F4768] '>In-Process</p>
                        <p className='text-[#007BEC] font-medium'>5</p>

                       </div>
                       <div className='flex w-full my-2 text-xs justify-between'>
                        <p className='text-[#4F4768] '>Awarded</p>
                        <p className='text-[#007BEC] font-medium'>5</p>

                       </div>
                       <div className='flex w-full my-2 text-xs justify-between'>
                        <p className='text-[#4F4768] '>Not-Awarded</p>
                        <p className='text-[#007BEC] font-medium'>5</p>

                       </div>
                       <div className='flex w-full my-2 text-xs justify-between'>
                        <p className='text-[#4F4768] '>Closed</p>
                        <p className='text-[#007BEC] font-medium'>5</p>

                       </div>
 
               </div>
  )
}

export default Bids