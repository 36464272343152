// import React, { useEffect, useMemo, useState } from "react";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
// import { Link, useParams } from "react-router-dom";
// import Dashboard from "../../../../Components/Icons/Dasboard";
// import { useFormik } from "formik";
// import useAddFormData from "../../../../Components/Hooks/useAddFormData";
// import * as yup from "yup";
// import {
//   NuDate,
//   NuInput,
//   NuMobileNumber,
//   NuSelect,
//   NuTextArea,
// } from "../../../../Components/Utils/Input";
// import FormikSelectTwo from "../../../../Components/Utils/Select";
// import axiosInstance from "../../../../Services/axiosCommon";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
// import { TextMediumBase } from "../../../../Components/Text/MediumText";
// import { useToast } from "../../../../Components/Others/ToastServices";
// import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";

import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../../../../../Components/Others/ToastServices";
import * as yup from "yup";
import { useFormik } from "formik";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
import { NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
import NuDate from "../../../../../../Components/Utils/NuDate";
import useAddFormData from "../../../../../../Components/Hooks/useAddFormData";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../../../Components/Hooks/useDropDown";
import axiosInstance from "../../../../../../Services/axiosCommon";
import DropDownAddMetaData from "../../../../../../Components/Utils/selectAddMetaData";
import Attachement from "../../../../../../Components/Utils/CustomAttrachmentupload";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];
function CreateInvoiceProjectIncoming() {
  const {officeId,projectId} =useParams();

  const currentDate = new Date().toISOString().split('T')[0];
  const {value:Tax ,getData:refTax} = useFetchMataData(`/dropdown/dropdown-details/`,'Tax_invoicesIncoming')
  const {value:ModeOfPayment ,getData:refModeOfPayment} = useFetchMataData(`/dropdown/dropdown-details/`,'ModeOfPayment_invoicesIncoming')
  const {value:Division ,getData:refDivision} = useFetchMataData(`/dropdown/dropdown-details/`,'Division_invoicesIncoming')
  const { data: PR } = UseDropDown('/PR/drop-down');
  // const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);

  const { data: PO } = UseDropDown('/PO/drop-down');
  const toast = useToast();

  // const [image, setImage] = useState({ preview: "", raw: "" });
  const [ supplierName, setSupName ] = useState(null);
  const [ supplierEmail, setSupEmail ] = useState(null);
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const { data: customer } = UseDropDown("/customers/drop-down");
  const [createNew, setCreateNew] = useState
  ({
    date: currentDate || "",
    amount: "",
    tax: 0,
    totalAmount: "",
    modeOfPayment: "",
    paymentDueDate: currentDate || "",
    paymentReleasedOn: currentDate || "",
    daysElapsed: "",
    comments: "",
    customerId: "",
    division: "",
    ref: "",
    poId:"",
    bankCharges:"",
    

    
    
  });
  const [selectedFile, setSelectedFile] = useState(null);
 
   function handleImageChange(e) {
     const maxFileSize = 200000000; // 200 MB
     const files = Array.from(e.target.files);
     let fileArr = [];
     files.map((item) => {
       if (item.size < maxFileSize) {
         fileArr.push(item);
       } else {
         toast.open({
           type: "warning",
           message: `File Size to big`,
           description: `${item.name} is Removed and not Uploaded`,
           // placement: "bottomRight",
         });
       }
     });
     let tempArr = [];
     setSelectedFile(fileArr);
     files.map((item) => {
       if (item.size < maxFileSize) {
         let fileName = item?.name;
         console.log("fname", fileName);
         let fileExtention = fileName.split(".").reverse()[0];
         if (filetype?.includes(fileExtention)) {
           tempArr.push(fileName);
         } else {
           let x = URL.createObjectURL(item);
           tempArr.push(x);
         }
       }
     });
     console.log(tempArr, "temp");
     setImage(tempArr);
   }
 
   const removeImages = (value) => {
     console.log("value", value);
     let List = selectedFile.filter((item) => item?.name !== value);
     console.log("LIst", List);
     setSelectedFile([...List]);
     let tempArr = [];
     List.map((item) => {
       let fileName = item?.name;
       // console.log('fname',fileName);
       // let fileExtention =  fileName.split(".").reverse()[0]
       // if(filetype?.includes(fileExtention)){
       tempArr.push(fileName);
       // }else{
       //   let x = URL.createObjectURL(item)
       //   tempArr.push(x)
       // }
     });
     setImage(tempArr);
   };

  const [error,setError] = useState('')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required('YPR is requred!'),

      poId: yup.string().required('Purchase Order is requred!'),
      ref: yup.string().required('Invoice Reference is requred!')
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
       let formData = new FormData();
            const valueWithItems = {
              ...value,
             
            };
            formData.append("json_data", JSON.stringify(valueWithItems));
            if (selectedFile !== null) {
              selectedFile.forEach((file) => {
                formData.append("attachments", file);
              });
            } else {
              formData.append("attachments", []);
            }
      addFormData("/invoices-incoming/new-invoice", formData, resetForm);
      // setLoading(false)
    },
  });

  useMemo(() => {
    if (formik.values.tax != 0||formik.values.tax != "") {
      let finalAmount = (formik.values.amount / 100) * formik.values.tax;
      formik.values.totalAmount = formik.values.amount + finalAmount;
    } else {
      formik.values.totalAmount = formik.values.amount;
    }
  }, [formik.values.amount, formik.values.tax]);
  const [purchaseOrder,setPurchaseOrder] = useState([]);

  const getPOByPR = () =>{
    axiosInstance
    .get(`/grn/PO/drop-down/${formik?.values?.prId}`)
    .then(res => {
         console.log("v",res?.data);
         let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setPurchaseOrder(tempData);
    }).catch((err)=>{
      console.log(err);
    })
  }

  const [polist, setPolist] = useState([]);


  const getPo = (poId) => {
    if (poId !== "") {
      axiosInstance
        .get(`/PO/po-products/${poId}`)
        .then(res => {
          let data = res.data.data;
          let name = res.data?.supplierName;
          let email = res.data?.supplierEmail;
          let total = res?.data?.totalPrice.toFixed(2)
          setPolist(data);
          name && setSupName(name);
          email && setSupEmail(email);
          total && formik.setFieldValue('amount',total)
              formik.setFieldValue('totalAmount',total)

        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.prId) {
      getPOByPR();
    }
    if (formik.values.poId) {
      getPo(formik.values.poId);
    }
  }, [formik.values.poId,formik?.values?.prId]);

  useEffect(() => {
    const amount = parseFloat(formik.values.amount || 0);
    const tax = parseFloat(formik.values.tax || 0);
    
    const taxAmount = ((amount)* tax/100);  
    
    formik.setFieldValue("taxAmount", taxAmount.toFixed(2));
    formik.setFieldValue("totalAmount", (amount + taxAmount).toFixed(2));
  }, [formik.values.amount, formik.values.tax]);
  

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        {/* <Breadcrumb
          items={[
            {
              name: "Invoice-Incoming",
              link: `/project/sales/invoice_incoming/${officeId}/${projectId}`,
            },
            {
              name: "Create",
            },
          ]}
        /> */}
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="PR"
                placeholder="PR "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
            <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={purchaseOrder}
              formik={formik}
              name="poId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {/* <FormikSelectTwo
              type="text"
              label="Customer"
              placeholder="Customer"
              Options={customer}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
              type="text"
              label="Invoice Reference "
              placeholder=" Invoice Reference"
              formik={formik}
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuDate
              label="Invoice Date"
              placeholder=" Invoice Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuDate
              label="Received Date"
              placeholder=" Received Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
 <NuInput
                          type="number"
                          label="Bank Charges"
                          placeholder="Bank Charges"
                          formik={formik}
                          name="bankCharges"
                          width="w-full md:w-1/2 lg:w-1/3"
                        />
            <NuInput
              type="number"
              label="Amount"
              placeholder="Amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Tax (%)"
              placeholder="Choose"
              Options={Tax}
              formik={formik}
              name="tax"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refTax}
              displayProperty={"Tax (%)"}
              propertyName={"Tax_invoicesIncoming"}
            />
  <NuInput
  type="text"
  label="Tax Amount"
  placeholder="Tax Amount"
  formik={formik}
  name="taxAmount"
  width="w-full md:w-1/2 lg:w-1/3"
  disabled={true}
/>
            <NuInput
              type="number"
              label="Total Amount"
              placeholder="Total Amount"
              formik={formik}
              name="totalAmount"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={true}
            />

            <NuDate
              label="Payment DueDate"
              placeholder="Payment DueDate"
              formik={formik}
              name="paymentDueDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuDate
              label="Payment ReleasedOn"
              placeholder="Payment ReleasedOn"
              formik={formik}
              name="paymentReleasedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <DropDownAddMetaData
              label="Mode of Payment"
              placeholder="Choose"
              Options={ModeOfPayment}
              formik={formik}
              name="modeOfPayment"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refModeOfPayment}
              displayProperty={"Mode of Payment"}
              propertyName={"ModeOfPayment_invoicesIncoming"}
            />

            {/* <DropDownAddMetaData
              label="Division"
              placeholder="Choose"
              Options={Division}
              formik={formik}
              name="division"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refDivision}
              displayProperty={"Division"}
              propertyName={"Division_invoicesIncoming"}
            /> */}

            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              new_width="w-full md:w-2/3"

              width="w-full md:w-1/2 lg:w-1/3"
            />
             <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                Invoice Incoming Attachement :
                </p>
              </div>
              <Attachement
                handleChange={handleImageChange}
                preview={image || ""}
                width="w-full"
                label="Attach"
                multiple={true}
                removeImages={removeImages}
              />
           
             {formik.values.poId && (
              <>
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Supplier Name
              </p>
              <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                {supplierName}
              </p>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Supplier Email
              </p>
              <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                {supplierEmail}
              </p>
            </div>
            </>)}
            {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className="w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  Ordered Qty
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Total
                </p>
              </div>
              {polist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {polist.map((List, index) => (
                <div
                  key={index}
                  className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
                >
                   <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[40%] text-left text-[#606279] text-xs font-light">
                    {List?.product_name}
                  </p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.product_unit}
                  </p>
                  <p className="w-[20%] text-center text-[#606279] text-xs font-light">
                    {List?.orderedQty}
                  </p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">
                    {List?.price}
                  </p>
                  <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.orderedQty ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>

                </div>
              ))}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div> */}
            {/* <div className='w-full my-6 flex justify-end items-center gap-4'> */}
            {/* <TextMediumBase content={"Total Price : "} />
              <NuInput
                label=""
                type="number"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
           
              />
            </div> */}
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateInvoiceProjectIncoming;
