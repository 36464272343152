import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardSide from "../../Components/Icons/DasboardSide";
import { GetAccess, GetDashboardRole, GetMultipleAccess } from "../../Components/Utils/roles";
import Items from "../../Components/Icons/Items";
import Products from "../../Components/Icons/Products";
import Bidding from "../../Components/Icons/Bidding";
import Procurement from "../../Components/Icons/Procurement";
import Project from "../../Components/Icons/Project";
import Sales from "../../Components/Icons/Sales";
import Payments from "../../Components/Icons/Payments";
import Store from "../../Components/Icons/Store";
import Members from "../../Components/Icons/Member";
import { Navbar, SideBar } from "../../Layouts/MainPage";
import { SidebarItem } from "../../Layouts/MainPage/SideBar";
import Costing from "../../Components/Icons/Budget";
import Progress from "../../Components/Icons/progress";
import PettyCash from "../../Components/Icons/PettyCash";
import Timesheet from "../../Components/Icons/TimeSheet";
import PlanTask from "../../Components/Icons/Planning";
import Task from "../../Components/Icons/Task";
import SubContracts from "../../Components/Icons/SubContracts";
import Report from "../../Components/Icons/Report";
import Finance from "../../Components/Icons/Finance";


const MainProjectPage = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {officeId} = useParams();
  const{projectId}=useParams();


    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
      if(event.key === "Escape") {
        navigate(-1);
      }
    }, []);
  
    useEffect(() => {
      // attach the event listener
      document.addEventListener('keydown', handleKeyPress);
  
      // remove the event listener
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [handleKeyPress]);


  function isActiveFun(x){
    let actualPath = location.pathname.split("/")[1]
    let linkPath = ""
    if(x?.hasSubMenu){
      if(x?.subMenu){
         let y = x.subMenu[0].link;
         let z = y.split("/")[0]
         linkPath = z
      }
    }else{
      linkPath = x?.link;
    }
    if(actualPath == linkPath) {
      return true;
    }else{
      return false;
    }
  }


  const navItem = [
    {
      icon: <DashboardSide/>,
      title: "Dashboard",
      hasSubMenu: false,
      hasAccess:true,
      link: `/project/dashboard/${officeId}/${projectId}`,
    },
    {
      icon:<Costing />,
      title: "Budget",
      link: `/project/costing/man-power/${officeId}/${projectId}`,
      hasAccess: ["man_power_view","material","others"],
      hasSubMenu: false,
      // subMenu: [
      //   {
      //     name: "Man Power",
      //     link: `/project/costing/man-power/${officeId}/${projectId}`,
      //     hasAccess:'man_power_view'
      //   },
      //   {
      //     name: "Material",
      //     link: `/project/costing/material/${officeId}/${projectId}`,
      //     hasAccess:'material'

      //   },
      //   {
      //     name: "Others",
      //     link: `/project/costing/others/${officeId}/${projectId}`,
      //     hasAccess:'others'

      //   },
      // ],
    },
    {
      icon: <PlanTask/>,
      title: "Planing",
      link: `/project/tasks/task-plan/${officeId}/${projectId}`,
      hasSubMenu: false,
      hasAccess:["activities","time_line"],
      // subMenu: [
      //   {
      //     name: "Activities",
      //     link: `/project/tasks/task-plan/${officeId}/${projectId}`,
      //     hasAccess:'activities'
      //   },
      //   {
      //     name: "Time line",
      //     link: `/project/tasks/task-schedule/${officeId}/${projectId}`,
      //     hasAccess:'time_line'

      //   },
      
        
       
      // ],
    },
    {
      icon:<Task />,
      title: "Task",
      hasAccess: ["my_task_view","all_task_view"],
      hasSubMenu: false,
      link: `/project/tasks/my-task/${officeId}/${projectId}`,
      subMenu: [
        // {
        //   name: "My Task",
        //   link: `/project/tasks/my-task/${officeId}/${projectId}`,
        //   hasAccess:'my_task_view'
        // },
        // {
        //   name: "All Task",
        //   link: `/project/tasks/all-task/${officeId}/${projectId}`,
        //   hasAccess:'all_task_view'

        // },
      ],
    },
    {
      icon:<SubContracts />,
      title: "Sub Contracts",
      hasAccess: ["my_task_view","all_task_view"],
      hasSubMenu: false,
      link: `/project/report/${officeId}/${projectId}`,
      subMenu: [
        // {
        //   name: "My Task",
        //   link: `/project/tasks/my-task/${officeId}/${projectId}`,
        //   hasAccess:'my_task_view'
        // },
        // {
        //   name: "All Task",
        //   link: `/project/tasks/all-task/${officeId}/${projectId}`,
        //   hasAccess:'all_task_view'

        // },
      ],
    },
    {
      icon: <Progress/>,
      title: "Progress",
      link: `/project/progress/man-power/${officeId}/${projectId}`,
      hasSubMenu: false,
      hasAccess:["man_power","material","Equipment","others"],
      // subMenu: [
      //   {
      //     name: "Man Power",
      //     link: `/project/progress/man-power/${officeId}/${projectId}`,
      //     hasAccess:'man_power_view'
      //   },
      //   {
      //     name: "Material",
      //     link: `/project/progress/material/${officeId}/${projectId}`,
      //     hasAccess:'material'

      //   },
        
      //   {
      //     name: "Equipment",
      //     link: `/project/progress/equipment/${officeId}/${projectId}`,
      //     hasAccess:'Equipment'

      //   },
      //   {
      //     name: "Others",
      //     link: `/project/progress/others/${officeId}/${projectId}`,
      //     hasAccess:'others'

      //   },
      // ],
    },
    {
      icon: <Timesheet/>,
      title: "HR",
      link: `/project/hr/attendance/${officeId}/${projectId}`,
      hasSubMenu: false,
      hasAccess:["attendance","timesheets","team"],
      // subMenu: [
      //   {
      //     name: "Attendance",
      //     link: `/project/timesheet/attendance/${officeId}/${projectId}`,
      //     hasAccess:'attendance'
      //   },
      //   {
      //     name: "Timesheets",
      //     link: `/project/timesheet/timesheets/${officeId}/${projectId}`,
      //     hasAccess:'timesheets'

      //   },
        
       
      // ],
    },
    {
      icon: <Procurement/>,
      title: "Procurement",
      hasAccess:["purchase_request_view","rfq_view","supplier_quote_view","quote_compare_view","purchase_order_view","suppliers_view"],
      hasSubMenu: false,
      link: `/project/procurement/ypr/${officeId}/${projectId}`,
      subMenu: [
        {
          name: "YPR",
          link: `/project/procurement/ypr/${officeId}/${projectId}`,
          hasAccess:'purchase_request_view'
        },
        {
          name: "RFQ",
          link: `/main/${officeId}/procurement/rfq`,
          hasAccess:'rfq_view'

        },
        {
          name: "Quote Comparison",
          link: `/main/${officeId}/procurement/quote-comparison`,
          hasAccess:'quote_compare_view'

        },
        {
          name: "Purchase Order",
          link: `/main/${officeId}/procurement/purchase-order`,
          hasAccess:'purchase_order_view'

        },
        {
          name: "Supplier",
          link: `/main/${officeId}/procurement/suppliers`,
          hasAccess:'suppliers_view'

        },
        {
          name: "Payment Request",
          link: `/main/${officeId}/procurement/payment-request`,
          hasAccess:'payment_request_view'
        },
        {
          name: "Product Comparison",
          link: `/main/${officeId}/procurement/product-comparison`,
          hasAccess:'suppliers_view'

        },
        {
          name: "Team Performance",
          link: `/main/${officeId}/procurement/team-performance`,
          hasAccess: GetDashboardRole("management")

        }
      ],
    },
    {
      icon: <Sales/>,
      title: "Invoices",
      link: `/project/sales/invoice_incoming/${officeId}/${projectId}`,
      hasAccess:["customers_view","customer_quote_view","customer_order_view","sales_order_view","invoice_in_view","invoice_out_view","promotions_view"],
      hasSubMenu: false,
      subMenu: [
        {
          name: "Customer",
          link: `/main/${officeId}/sales/customers`,
          hasAccess:'customers_view'

        },
        {
          name: "Quote",
          link: `/main/${officeId}/sales/quotes`,
          hasAccess:'customer_quote_view'

        },
        {
          name: "Customer Order",
          link: "sales/customer-order",
          hasAccess:'customer_order_view'

        },
        {
          name: "Sales Order",
          link: `/main/${officeId}/sales/sales-orders`,
          hasAccess:'sales_order_view'

        },
        {
          name: "Invoices Incoming",
          link: `/main/${officeId}/sales/invoice_incoming`,
          hasAccess:'invoice_in_view'

        },
        {
          name: "Invoices Outgoing",
          link: `/main/${officeId}/sales/invoice_outgoing`,
          hasAccess:'invoice_out_view'

        },
      ],
    },
 
    {
      icon: <Store/>,
      title: "Store",
      hasAccess:["grn_view","inventory_view","assets_view","delivery_notes_view","packing_list_view","returns_view","requests_view","shipment_status_view"],
      hasSubMenu: false,
      link:`/project/store/shipment-status/${officeId}/${projectId}`,
      subMenu: [
        {
          name: "GRN",
          link: "store/grn",
          hasAccess:'grn_view'

        },
        {
          name: "Inventory",
          link: "store/inventory",
          hasAccess:'inventory_view'

        },
        {
          name: "Asset",
          link: "store/assets",
          hasAccess:'assets_view'

        },       
        {
          name: "Delivery Note",
          link: "store/delivery-note",
          hasAccess:'delivery_notes_view'

        },
        {
          name: "Packing List",
          link: "store/packing-list",
          hasAccess:'packing_list_view'

        },
        {
          name: "Return",
          link: "store/returns",
          hasAccess:'returns_view'

        },
        {
          name: "Request",
          link: "store/requests",
          hasAccess:'requests_view'

        },
      ],
    },
    {
      icon: <PettyCash/>,
      title: "Petty Cash",
      link: `/project/petty-cash/received/${officeId}/${projectId}`,
      hasSubMenu: false,
      hasAccess:["received","issued"],
      // subMenu: [
      //   {
      //     name: "Received",
      //     link: `/project/petty-cash/received/${officeId}/${projectId}`,
      //     hasAccess:'received'
      //   },
      //   {
      //     name: "Issued",
      //     link: `/project/petty-cash/issued/${officeId}/${projectId}`,
      //     hasAccess:'issued'

      //   },
        
       
      // ],
    },
    
    {
      icon: <Finance/>,
      title: "Finance",
      link: `/project/payments/invoice-in/${officeId}/${projectId}`,
      hasSubMenu: false,
      hasAccess:["incoming","outgoing","other_expenses"],
      // subMenu: [
      //   {
      //     name: "Incoming",
      //     link: `/project/payments/invoice-in/${officeId}/${projectId}`,
      //     hasAccess:'incoming'
      //   },
      //   {
      //     name: "Outgoing",
      //     link: `/project/payments/invoice-out/${officeId}/${projectId}`,
      //     hasAccess:'outgoing'

      //   },
      //   {
      //     name: "Other expenses",
      //     link: `/project/payments/other-expenses/${officeId}/${projectId}`,
      //     hasAccess:'other_expenses'

      //   },
        
       
      // ],
    },
    
  
  
   
   
  
    

    // {
    //   icon: <Task/>,
    //   title: "Task",
    //   hasAccess:["my_task_view","all_task_view",],
    //   hasSubMenu: true,
    //   subMenu: [
    //     {
    //       name: "My Task",
    //       link: `tasks/my-task/${officeId}/${projectId}`,
    //       hasAccess:'my_task_view'

    //     },
    //     {
    //       name: "All Task",
    //       link: `tasks/all-task/${officeId}/${projectId}`,
    //       hasAccess:'all_task_view'

    //     },
        
    //   ],
    // },

   

    {
      icon:<Report />,
      title: "Report",
      hasAccess: ["my_task_view","all_task_view"],
      hasSubMenu: false,
      link: `/project/report/${officeId}/${projectId}`,
      subMenu: [
        // {
        //   name: "My Task",
        //   link: `/project/tasks/my-task/${officeId}/${projectId}`,
        //   hasAccess:'my_task_view'
        // },
        // {
        //   name: "All Task",
        //   link: `/project/tasks/all-task/${officeId}/${projectId}`,
        //   hasAccess:'all_task_view'

        // },
      ],
    },
   

    // {

    //   link: GetAccess("team_view")?`/project/team/${officeId}/${projectId}`:"#",
    //   icon: (
    //     <>
    //       <Members />
    //     </>
    //   ),
    //   title: "Team",
    //   access: GetAccess("team_module"),
    //   hasSubMenu: false,
    //   subMenu:[],
    // },

    // {
    //   icon: <Members/>,
    //   title: "Team",
    //   link: `team/${officeId}/${projectId}`,
    //   hasSubMenu: false,
    //   // hasAccess:["activities","time_line"],
    //   // subMenu: [],
    // },
  
   
  ];

  return (
    <div className=" w-full h-screen bg-slate-100 flex justify-start items-center overflow-hidden">
      <SideBar>
        {navItem && 
          navItem.map((item,idx) => {
            
            return (
              <>
              {
                (item?.hasAccess == true||GetMultipleAccess(item?.hasAccess||[]) )&&(<SidebarItem
                    icon={item?.icon}
                    text={t(item?.title)}
                    hasSubMenu={item?.hasSubMenu}
                    subItem={item?.subMenu}
                    link={item?.link}
                    active={isActiveFun(item)}
                    key={idx}
                  />
                )
              }
              </>
            );
          })}
      </SideBar>

      <div className=" w-full h-[100vh]  ">
        <div className=" w-full h-[59px] bg-white z-50 shadow-lg">
          <Navbar />
        </div>
        <div className=" w-full h-[calc(100vh-87px)]  overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainProjectPage;
