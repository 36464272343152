import { Modal, notification } from "antd";
import React, { useState } from "react";

import { ExclamationCircleFilled } from '@ant-design/icons';
import axiosInstance from "../../../../../Services/axiosCommon";
import DeleteIcon from "../../../../../Components/Icons/Delete";
import Project from "../../../../../Components/Icons/Project";


function ViewAttachments({ item,getData,estid,titleList,titleSelect,index }) {
  const { confirm } = Modal;

  const [show, setShow] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const handleDelete = async(removevalue,file) =>{
    confirm({
        title: `Do you Want to delete ${removevalue}?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The ${removevalue} will be removed from the Attachments`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
            let value ={
                estimationId:Number(estid),
                filesToRemove:[file]
            }
          axiosInstance
          .put(`/estimation/attachments`,value).then((res) => {
            if(res.status === 201||res.status === 200) {
                getData();
                api.success({
                  message: `Material Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Material Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Material Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}

  return (
    <>
    {contextHolder}
      <tr
        className={`w-[100%] bg-white text-xs text-[#4f4768] font-normal`}
        // style={{ borderBottom: "1px solid #c7dfe0" }}
      >
        <td className=" w-[5%] py-3 px-1">
          <div className=" w-full gap-1 flex">
            <input
              type="checkbox"
              checked={titleList.some(i=>i==item?.path)}
              onChange={()=>titleSelect(item?.path)}
            />
            {index}
          </div>
        </td>
        <td className=" w-[70%] py-3 px-1 font-normal text-[#4f4768] truncate">
          <span className=" cursor-pointer" onClick={() => setShow(true)}>
            {item?.name || "-"}
          </span>
        </td>
        <td className=" w-[10%] py-3 px-1 font-normal text-[#4f4768]">{item?.format || "-"}</td>
        <td className=" w-[10%] py-3 px-1 font-normal text-[#4f4768]">{ `${(item?.size||0).toFixed(2)} KB `|| "-"}</td>
        <td className=" w-[5%] py-3 px-1 flex justify-center items-center">
            <div className=" w-full flex items-center gap-2">
                <div className="cursor-pointer" onClick={()=>setShow(true)}><Project color='green'/></div>
                <div className="cursor-pointer" onClick={()=>handleDelete(item?.name,item?.path)}><DeleteIcon/></div>
            </div>
        </td>
      </tr>
      <Modal
        open={show}
        title={"View Attachment"}
        width={"95%"}
        className="my-modal"
        centered
        onCancel={() => setShow(false)}
        footer={[]}
      >
        <div className=" w-full h-[calc(100vh-100px)] overflow-hidden flex justify-center items-center">
            {
                <img src={item?.path||""} alt="#" style={{width:"auto",height:'calc(100% - 10px)'}} />
            }
        </div>
      </Modal>
    </>
  );
}

export default ViewAttachments;
