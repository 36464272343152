import React, { useEffect, useState } from "react";
import BarChartView from "../../../../Components/Charts/BarChart";

const Invoice = () => {
  const currentYear = new Date().getFullYear();
    const [data, setData] = useState([]);
  
 const [params, setParams] = useState({
    year: currentYear,
  });
   const [salesData, setSalesData] = useState([]);
  
    const years = Array.from(
      new Array(10),
      (val, index) => currentYear - 5 + index
    );
     useEffect(() => {
        if (data && data.data) {
          const formattedData = data.data.map((item) => ({
            month: new Date(0, item.month - 1).toLocaleString("default", {
              month: "short",
            }),
            amount: item.price,
            monthIndex: item.month - 1,
          }));
          setSalesData(formattedData);
        }
      }, [data]);
      const handleYearChange = (e) => {
        const newYear = e.target.value;
        setParams({ year: newYear });
        setSelectedYear(newYear);
      };
    
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const dummyData = [
    { month: "Jan", "In-Coming": 5000, "Out-Going": 3000 },
    { month: "Feb", "In-Coming": 7000, "Out-Going": 4000 },
    { month: "March", "In-Coming": 8000, "Out-Going": 4500 },
    { month: "April", "In-Coming": 10000, "Out-Going": 5000 },
    { month: "May", "In-Coming": 9000, "Out-Going": 4800 },
    { month: "June", "In-Coming": 11000, "Out-Going": 5100 },
    { month: "July", "In-Coming": 7000, "Out-Going": 4900 },
    { month: "Aug", "In-Coming": 8500, "Out-Going": 6000 },
    { month: "Sept", "In-Coming": 9500, "Out-Going": 7000 },
    { month: "Oct", "In-Coming": 12000, "Out-Going": 8000 },
    { month: "Nov", "In-Coming": 6000, "Out-Going": 6500 },
    { month: "Dec", "In-Coming": 7500, "Out-Going": 7800 },
  ];

  return (
    <div className="bg-white p-4 rounded-lg drop-shadow-lg h-[65vh]  2xl:h-[55vh] w-full">
    
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-semibold text-[#4F4768] text-sm">Invoice Overview</h2>
        <div className="flex items-center">
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="border rounded-md p-1 text-xs mb-1 text-[#ACACAC] font-normal"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      
      <div className="w-full h-[300px]">
        <BarChartView data={dummyData} />
      </div>
    </div>
  );
};

export default Invoice;
