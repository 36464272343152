// import React, { useEffect, useState } from 'react'
// import DataTable from '../../../../Components/Others/DataTable';
// import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
// import { Link, useLocation, useParams } from 'react-router-dom';
// import SearchBar from '../../../../Components/Utils/SearchBar';
// import UseGetData from '../../../../Components/Hooks/useFetchData';
// import MoreOptions from '../../../../Components/Utils/MoreOptions';
// import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
// import CustomDataTable from '../../../../Components/Others/CustomDataTable';
// import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
// import { useFormik } from 'formik';
// import FormikSelectTwo from '../../../../Components/Utils/Select';
// import { NuDate } from '../../../../Components/Utils/Input';
// import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
// import UseDropDown from '../../../../Components/Hooks/useDropDown';
// import * as yup from 'yup';

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import UseFetchCurrency from "../../../../../../Components/Hooks/useFetchCurrencyData";
import { useFormik } from "formik";
import UseGetDataSearch from "../../../../../../Components/Hooks/useFetchDataBySearch";
import ProcurementLayout from "../../../../Procurement/ProcurementLayout";
import { GetAccess } from "../../../../../../Components/Utils/roles";
import CustomDataTable from "../../../../../../Components/Others/CustomDataTable";
import NuDate from "../../../../../../Components/Utils/NuDate";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
import { CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import ModalContainer from "../../../../../../Components/Others/ModalContainer";
import * as yup from 'yup';
import ProcurementsubLayout from "../ProcurementLayout";

// import ModalContainer from '../../../../Components/Others/ModalContainer';
// import MultiSelect from '../../../../Components/Utils/MultiSelect';
// import { GetAccess } from '../../../../Components/Utils/roles';
// import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';
// import UseFetchCurrency from '../../../../Components/Hooks/useFetchCurrencyData';
// import ProcurementLayout from '../ProcurementLayout';

function PaymentRequestIndex() {
  const {officeId,projectId} =useParams();

 const location = useLocation();
   const currentPage= location.state?.currentPage
   const searchQuery= location.state?.searchQuery
   const [page, setPage] = useState(currentPage||1)
   const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  
  const state = location.state;
  
  
  const [filter, setFilter] = useState(
    state?.approvalStatus || state?.category ? true : false
  );
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  
  const { value: status } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_invoicesIncoming",showFilterDialog
  ); 
  const { value: priorityRFQ } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "priority_RFQ",showFilterDialog
  );
  let { value: currency } = UseFetchCurrency(
    "/currency/drop-down"
  );
  const { value: ModeOfPayment, getData: refModeOfPayment } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ModeOfPayment_invoicesIncoming",showFilterDialog
  );

  const [filterProps, setFilterProps] = useState({
  
    modeOfPayment:"",
    status:"",
    
    currency:"",
    priority:"",
    approvalStatus:"",
    dateFrom:"",
    dateTo:"",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:searchQuery||"",

    modeOfPayment:"",
    status:"",
    
    currency:"",
    priority:"",
    approvalStatus:"",
    dateFrom:"",
    dateTo:"",  });

  const clearFilter = ()=> {
    setParams({...params, 
      modeOfPayment:"",
      status:"",      
      currency:"",
      priority:"",
      approvalStatus:"",
      dateFrom:"",
      dateTo:"",})
    formik.values.status=""
    formik.values.modeOfPayment=""
    formik.values.dateTo=""
    formik.values.dateFrom=""
    formik.values.priority=""
    formik.values.approvalStatus=""
    formik.values.currency=""



      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      setParams({...params,status:value.status, modeOfPayment:value.modeOfPayment, currency:value.currency, priority:value.priority, dateFrom:value.dateFrom,approvalStatus:value.approvalStatus,dateTo:value.dateTo})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        modeOfPayment:"",
    status:"",    
    currency:"",
    priority:"",
    approvalStatus:"",
    dateFrom:"",
    dateTo:"",
      });
    }
  })
  const { data, totalCount, getData,dataLoading } = UseGetDataSearch( '/payment-request/',params);


  const defaultHeader = [
   
    {
      title: "PR",
      width: "10%",
      isLink: true,
      id: "id",
      link: `/project/procurement/payment-request/${officeId}/${projectId}/view/`,
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "PO No.",
      width: "10%",
      isLink: true,
      id: "id",
      link: `/project/procurement/payment-request/${officeId}/${projectId}/view/`,
      field: "poRef",
      textAlign: "center",
      rowAlign: "center",
    },
    
    {
      title: "Invoice In.",
      width: "10%",
      field: "invoiceRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Currency",
      width: "10%",
      field: "currency",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Invoice Amount",
      width: "10%",
      field: "invoiceAmount",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"id",
        removeValue:"ref",
        url:`/payment-request/`,
        moduleName:"Payment Request",
        from:`/project/procurement/payment-request/${officeId}/${projectId}`,
        editLink:`/project/procurement/payment-request/${officeId}/${projectId}/edit/`,
        viewLink:`/project/procurement/payment-request/${officeId}/${projectId}/view/`

      }
    },
  ];
  const availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "PR",
      width: "10%",
      isLink: true,
      id: "id",
      link: `/project/procurement/payment-request/${officeId}/${projectId}/view/`,
   
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "PO No.",
      width: "10%",
         isLink: true,
      id: "id",
      link: `/project/procurement/payment-request/${officeId}/${projectId}/view/`,
      field: "poRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Invoice In.",
      width: "10%",
      field: "invoiceRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Currency",
      width: "10%",
      field: "currency",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Invoice Amount",
      width: "10%",
      field: "invoiceAmount",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Bank Charges",
      width: "10%",
      field: "bankCharges",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Total Amount ",
      width: "10%",
      field: "totalAmount",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Mode of Pamt.",
      width: "10%",
      field: "modeOfPayment",
      textAlign: "center",
      rowAlign: "center",
    },
   
   
    // {
    //   title: "Paid Amount",
    //   width: "5%",
    //   field: "paidAmount",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Paid On",
      width: "5%",
      field: "paidOn",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Priority",
      width: "5%",
      field: "priority",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"id",
        removeValue:"ref",
        url:`/payment-request/`,
        moduleName:"Payment Request",
        from:`/project/procurement/payment-request/${officeId}/${projectId}`,
        editLink:`/project/procurement/payment-request/${officeId}/${projectId}/edit/`,
        viewLink:`/project/procurement/payment-request/${officeId}/${projectId}/view/`

      }
    },
  ];
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

   const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);


  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  // hasMultiEdit=true,
  // selectMultipleFunction=()=>{},
  // selectOneFunction=()=>{},
  // listofNumbers=[1,2],
  // setShowEdit=()=>{},
  // showEdit=false,
  // setShowDelete=()=>{},
  // showDelete=false,

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 

if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }



  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
     
      tempArr.push(i?.id)
    });
    setFullList(tempArr)
  },[data])
  
  const [valueOfSearch,setValuesofSearch] = useState("")
   function searchFunction(e) {
 
     
     setValuesofSearch(e?.target?.value)
     setParams({ ...params, search: e?.target?.value, page:1 });
     setPage(1)
   }
 

  

  return (
    <>
    <ProcurementsubLayout 
     searchFun={searchFunction}
     searchValue={valueOfSearch}
     getData={getData}
     isFilter={filter}

     setFilterOpen={setShowFilterDialog}
     ClearFilter={clearFilter}
     CreateAccess={GetAccess("purchase_request_create")}
    //  ImportAccess={GetAccess("purchase_request_create")}
     officeId={officeId}
     neededSearch={true}
       newfilter={true}
     // projectId={projectId}
       >
    <div className='w-full h-full '>
      {/* <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { name: "Procurement" },
            { name: "Payment Request" },
          ]} />
        <div className=' flex gap-2'>
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
          {/* {GetAccess("payment_request_create") && <ImportButton handleClick={handleImportClick} />} */}
          {/* {GetAccess("payment_request_create") && <Link to="/main/${officeId}/procurement/payment-request/create"><CreateButton label='Payment Request' /></Link>}
        </div>
      </div> */}

      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-160px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Payment Request List'
        storageName="PaymentRequestProjectTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        // hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="id"
        editAccess="payment_request_edit"      
        deteleAccess="payment_request_delete" 
        dataLoading={dataLoading}
        searchQuery={params?.search!=""?params?.search:""}

        />
        </div>
        </ProcurementsubLayout>
         {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Payment Request</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Date</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label=" From"
              placeholder="DD/MM/YYYY"
              formik={formik}
              name="dateFrom"
              width="w-full"
            />
              <NuDate
              label=" To"
              placeholder="DD/MM/YYYY"
              formik={formik}
              name="dateTo"
              width="w-full"
            />
            </div>
            <FormikSelectTwo
                Options={currency}
                label="Currency"
                placeholder="Currency"
                formik={formik}
                name="currency"
                width="w-full "
              />
              <FormikSelectTwo
                label="Mode of Payment"
                placeholder="Choose"
                Options={ModeOfPayment}
                formik={formik}
                name="modeOfPayment"
                width="w-full "
                getFunc={refModeOfPayment}
                displayProperty={"Mode of Payment"}
                propertyName={"ModeOfPayment_invoicesIncoming"}
              />
             <FormikSelectTwo
                type="text"
                label="Priority"
                placeholder="Choose"
                // disabled={true}
                Options={priorityRFQ}
                formik={formik}
                name="priority"
                width="w-full "
              />
             <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={status}
              formik={formik}
              name="status"
              width="w-full"
            />
              <FormikSelectTwo
              type="text"
              label="Approval Status"
              placeholder="Approval Status"
              Options={[
                {
                  value: "pending",
                  label: "pending",
                },
                {
                  value: "Verified",
                  label: "Verified",
                },
                {
                  value: "Approved",
                  label: "Approved",
                },
                                         

              ]}
              formik={formik}
              name="approvalStatus"
              width="w-full "
            />
                     
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        {/* {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
        {showEdit && (<MultiEditPr data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)} */}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from YPR List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </>
  )
}

export default PaymentRequestIndex