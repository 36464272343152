// import React, { useEffect, useMemo, useState } from "react";
// import {
//   BackButton,
//   CancelButton,
//   SubmitButton,
// } from "../../../../Components/Utils/Buttons";
// import Dashboard from "../../../../Components/Icons/Dasboard";
// import { Link, useLocation, useParams } from "react-router-dom";
// import { useFormik } from "formik";
// import useEditFormData from "../../../../Components/Hooks/useEditFormData";
// import * as yup from "yup";
// import {
//   NuDate,
//   NuInput,
//   NuMobileNumber,
//   NuTextArea,
// } from "../../../../Components/Utils/Input";
// import FormikSelectTwo from "../../../../Components/Utils/Select";
// import axiosInstance from "../../../../Services/axiosCommon";
// import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
// import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
// import Modal from "../../../../Components/Utils/AttachmentView";
// import Attachement, { videotype } from "../../../../Components/Utils/CustomAttrachmentupload";
// import Doc from "../../../../Components/Icons/Doc";
// import Xls from "../../../../Components/Icons/Xls";
// import Txt from "../../../../Components/Icons/Txt";
// import Pdf from "../../../../Components/Icons/Pdf";
// import Video from "../../../../Components/Icons/Video";
// import CloseIcon from "../../../../Components/Icons/Close";
// import { useToast } from "../../../../Components/Others/ToastServices";



import React, { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../../../Components/Hooks/useDropDown";
import useEditFormData from "../../../../../../Components/Hooks/useEditFormData";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "../../../../../../Components/Others/ToastServices";
import axiosInstance from "../../../../../../Services/axiosCommon";
import Breadcrumb from "../../../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../../../Components/Utils/Select";
import { NuDate, NuInput, NuTextArea } from "../../../../../../Components/Utils/Input";
import DropDownAddMetaData from "../../../../../../Components/Utils/selectAddMetaData";
import CloseIcon from "../../../../../../Components/Icons/Close";
import Video from "../../../../../../Components/Icons/Video";
import Doc from "../../../../../../Components/Icons/Doc";
import Xls from "../../../../../../Components/Icons/Xls";
import Txt from "../../../../../../Components/Icons/Txt";
import Pdf from "../../../../../../Components/Icons/Pdf";
import Attachement, { videotype } from "../../../../../../Components/Utils/CustomAttrachmentupload";
import { Modal } from "antd";
import { useFormik } from "formik";

const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];
function EditInvoiceProjectIncoming() {
  const {officeId,projectId} =useParams();

  const { value: Tax } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Tax_invoicesIncoming"
  );
  const { value: ModeOfPayment } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ModeOfPayment_invoicesIncoming"
  );
  const { value: Division } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Division_invoicesIncoming"
  );
  const { value: Status,getData:refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_invoicesIncoming"
  );
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_incoming')
  const { data: PR } = UseDropDown('/PR/drop-down');

  const { data: PO } = UseDropDown("/PO/drop-down");

  const [image, setImage] = useState([]);
  const [fileRemoveList, setFileRemoveList] = useState([]);
   const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  // console.log(location.state,'state');
  // const [division, setDivision] = useState([]);
  const [supplierName, setSupName] = useState(null);
  const [supplierEmail, setSupEmail] = useState(null);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const { data: customer } = UseDropDown("/customers/drop-down");
  // const [loading, setLoading] = useState(false);

  const [createNew, setCreateNew] = useState({
    date: data?.date,
    poId: data?.poId,
    amount: data?.amount,
    tax: String(data?.tax),
    totalAmount: data?.totalAmount,
    modeOfPayment: data?.modeOfPayment,
    paymentReleasedOn: data?.paymentReleasedOn,
    daysElapsed: data?.daysElapsed,
    comments: data?.comments,
    customerId: data?.customerId,
    division: data?.division,
    ref: data?.ref,
    paymentDueDate: data?.paymentDueDate,
    approvalStatus: data?.approvalStatus || "",
    status: data?.status,
    taxAmount:data?.taxAmount,
    bankCharges:data?.bankCharges,
    filesToRemove: [],
    prId:data?.prId
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileExtention, setFileExtention] = useState("");
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);

      setRemoveFilePathList(data?.attachments);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    let Ext = img?.file_name.split(".").reverse()[0];
    setSelectedImage(img);
    setFileExtention(Ext);
    setIsModalOpen(true);
  } 


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      poId: yup.string().required('Purchase Order is requred!'),
      ref: yup.string().required('Invoice Reference is requred!')
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      let formData = new FormData();

      const valueWithItems = {
        ...value,
       
        filesToRemove: fileRemoveList,
       

      };
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      editFormData(
        `/invoices-incoming/edit-invoice/${data.invoiceInId}`,
        formData,
        resetForm,
        
      );
      // setLoading(false)
    },
  });

  useMemo(() => {
    if (formik.values.tax != 0 || formik.values.tax != "") {
      let finalAmount = (formik.values.amount / 100) * formik.values.tax;
      formik.values.totalAmount = formik.values.amount + finalAmount;
    } else {
      formik.values.totalAmount = formik.values.amount;
    }
  }, [formik.values.amount, formik.values.tax]);

  const [polist, setPolist] = useState([]);

  const getPo = (poId) => {
    if (poId !== "") {
      axiosInstance
        .get(`/PO/po-products/${poId}`)
        .then((res) => {
          let data = res.data.data;
          let name = res.data?.supplierName;
          let email = res.data?.supplierEmail;
          setPolist(data);
          name && setSupName(name);
          email && setSupEmail(email);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.poId) {
      getPo(formik.values.poId);
    }
  }, [formik.values.poId]);

  useEffect(() => {
    const amount = parseFloat(formik.values.amount || 0);
    const tax = parseFloat(formik.values.tax || 0);
    
    const taxAmount = ((amount)* tax/100);  
    
    formik.setFieldValue("taxAmount", taxAmount.toFixed(2));
    formik.setFieldValue("totalAmount", (amount + taxAmount).toFixed(2));
  }, [formik.values.amount, formik.values.tax]);
  


  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        {/* <Breadcrumb
          items={[
            {
              name: "Invoice-Incoming",
              link: `/project/sales/invoice_incoming/${officeId}/${projectId}`,
            },
            {
              name: "Edit",
            },
          ]}
        /> */}

        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="PR"
                placeholder="PR "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                disabled={true}
              />
            <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={PO}
              formik={formik}
              name="poId"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {/* <FormikSelectTwo
              type="text"
              label="Customer"
              placeholder="Customer"
              Options={customer}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
              type="text"
              label="Invoice Reference "
              placeholder="Invoice Reference"
              formik={formik}
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuDate
              label="Invoice Date"
              placeholder="Invoice Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuDate
              label="Received Date"
              placeholder=" Received Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
                          type="number"
                          label="Bank Charges"
                          placeholder="Bank Charges"
                          formik={formik}
                          name="bankCharges"
                          width="w-full md:w-1/2 lg:w-1/3"
                        />
            <NuInput
              type="number"
              label="Amount"
              placeholder="Amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Tax (%)"
              placeholder="Choose"
              Options={Tax}
              formik={formik}
              name="tax"
              width="w-full md:w-1/2 lg:w-1/3"
            />
<NuInput
  type="text"
  label="Tax Amount"
  placeholder="Tax Amount"
  formik={formik}
  name="taxAmount"
  width="w-full md:w-1/2 lg:w-1/3"
  disabled={true}
/>
            <NuInput
              type="number"
              label="Total Amount"
              placeholder="Total Amount"
              formik={formik}
              name="totalAmount"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuDate
              label="Payment DueDate"
              placeholder="Payment DueDate"
              formik={formik}
              name="paymentDueDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Payment Releasedon"
              placeholder="Payment Releasedon"
              formik={formik}
              name="paymentReleasedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Mode of Payment"
              placeholder="Mode of Payment"
              formik={formik}
              Options={ModeOfPayment}
              name="modeOfPayment"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            {/* <FormikSelectTwo
              type="text"
              label="Division"
              placeholder="Choose"
              Options={Division}
              formik={formik}
              name="division"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
  {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          /> */}
           <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refStatus}
                displayProperty={"Status"}
                propertyName={"Status_invoicesIncoming"}
              />
            {/* <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
              Invoice Incoming Attachement :
                </p>
              </div>
              <div className="md:flex justify-between mt-5 px-3 w-full">
                <div className="w-full flex gap-2 items-center flex-wrap">
                  {data?.attachments &&
                    filePathList?.map((imgs, idx) => {
                      console.log("img", imgs);
                      let Ext = imgs?.file_name.split(".").reverse()[0];
                      return (
                        <div
                          className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                          key={idx}
                          style={{
                            border: "1px solid gray",
                            borderColor:
                              "rgb(148 163 184 / var(--tw-bg-opacity))",
                          }}
                        >
                          <div
                            className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                            onClick={() => UpdateAttachmentList(imgs?.file)}
                          >
                            <CloseIcon color="white" height="8" width="8" />
                          </div>
                          <div
                            className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                            onClick={() => handleImageClick(imgs)}
                          >
                            <div className=" w-8 h-8">
                              {videotype.includes(Ext) ? <Video /> : null}

                              {Ext === ("docx" || "doc" || "txt") ? (
                                <Doc />
                              ) : Ext === ("xlsx" || "xls") ? (
                                <Xls />
                              ) : Ext === "txt" ? (
                                <Txt />
                              ) : Ext === "pdf" ? (
                                <Pdf />
                              ) : Ext === "PDF" ? (
                                <Pdf />
                              ) : Ext === "pptx" ? (
                                <>No file</>
                              ) : (
                                <img src={imgs?.file} alt="#" width="120%" />
                              )}
                            </div>
                            <p className=" w-[50px] text-[11px] truncate">
                              {imgs?.file_name}
                            </p>
                          </div>
                        </div>
                      );
                    })}

                  <Attachement
                    handleChange={handleImageChange}
                    preview={image || ""}
                    width="w-full md:w-1/2"
                    label="Attach"
                    multiple={true}
                  />
                </div>
              </div>

             {formik.values.poId && (
              <div className="w-full flex justify-start">
               <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Supplier Name
              </p>
              <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                {supplierName}
              </p>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Supplier Email
              </p>
              <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                {supplierEmail}
              </p>
            </div>
            </div>)}
            
            {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className="w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  Ordered Qty
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Total
                </p>
              </div>
              {polist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {polist.map((List, index) => (
                <div
                  key={index}
                  className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
                >
                   <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[40%] text-left text-[#606279] text-xs font-light">
                    {List?.product_name}
                  </p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.product_unit}
                  </p>
                  <p className="w-[20%] text-center text-[#606279] text-xs font-light">
                    {List?.orderedQty}
                  </p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">
                    {List?.price}
                  </p>
                  <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.orderedQty ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
                </div>
              ))}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div> */}
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
    </div>
  );
}

export default EditInvoiceProjectIncoming;
