import { useEffect, useState } from "react";
import axios from "../../../Services/axiosCommon";
import Pagination from "../../../Components/Utils/Pagenation";
import { Link, useLocation, useParams } from "react-router-dom";
import ProjectCard from "./projectCard";
import Dasboard from "../../../Components/Icons/Dasboard";
import { SecondHeading } from "../../../Components/Utils/Heading";
import SearchBar from "../../../Components/Utils/SearchBar";
import {CancelButton, ClearFilterButton, CreateButton, FilterButton, ImportButton, SubmitButton } from "../../../Components/Utils/Buttons";
import ImportProject from "./Import";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import UseGetDataSearchByPro from "../../../Components/Hooks/useFetchDataByPRO";
import { useFormik } from "formik";
import * as yup from 'yup';
import FormikSelectTwo from "../../../Components/Utils/Select";
import useFetchMataData from "../../../Components/Hooks/useFetchMataData";

const AllProject = () => {
  const { officeId } = useParams();
  const location = useLocation();
  const currentPage= location.state?.currentPage
  const searchQuery= location.state?.searchQuery
  const [page, setPage] = useState(currentPage||1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
   const [showFilterDialog, setShowFilterDialog] = useState(false);
   const { value: projectStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "projectStatus"
  );
  // const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [proTeam, setProTeam] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalProject,setTotalProject] = useState(0);
  // const [searchQuery, setSearchQuery] = useState("");
  const [notFirst,setNotFirst]=useState(false)
 const [filterProps, setFilterProps] = useState({
   
    projStatus:"",
  
  });
  const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:searchQuery||"",
      projStatus:""
    });

    const clearFilter = ()=> {
      setParams({...params,
      projStatus:"",
     
    })
    
    formik.values.projStatus=""
    
  
  
        setFilter(false)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterProps,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params,projStatus:value.projStatus})
          closeFilterDialog();
          setFilter(true)
          setFilterProps({
          
            projStatus:"",
           
          });
        }
      })
  
  const { data, totalPro,totalCount, getData,dataLoading } = UseGetDataSearchByPro( `/Project/${officeId}/details`,params);
  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  // async function getProject() {
  //   await axios
  //     .get(`/Project/${officeId}/details?page=${pageCount}&per_page=${dataCount}`,params)
  //     .then(async (res) => {
  //       await res?.data?.data && setData(res?.data?.data);
  //       await res?.data?.totalProject && setTotalProject(res?.data?.totalProject);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }


  // useEffect(()=>{
  //   getProject()
  // },[pageCount,dataCount])



  // function SearchData(e) {
  //   e.preventDefault();
  //   setNotFirst(true)
  //   if (searchQuery != "") {
  //     axios
  //       .get(`/Search/project/${searchQuery}`)
  //       .then((res) => {
  //         res?.data && setTotalProject(res?.data?.total_records);
  //         res.data?.data && setData(res.data?.data);
  //         console.log("search", res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   } else {
  //     console.log("Seacrch Data");
  //     getProject();
  //   }
  // }

  // useEffect(() => {
  //   if (searchQuery == "" && notFirst) {
  //     getProject();
  //   }
  // }, [searchQuery]);

  
      
  // function SearchData() {
  //   if (searchQuery !== "") {
  //     setNotFirst(true);
  //     axios
  //       .get(`/Search/project/${searchQuery}`)
  //       .then((res) => {
  //         res?.data && setTotalProject(res?.data?.total_records);
  //         res.data?.data && setData(res.data?.data);
  //         console.log("Search Results:", res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   } else {
  //     console.log("Search Data is empty, fetching all projects...");
  //     getProject();
  //   }
  // }

  // useEffect(() => {
    
  //   if (notFirst) {
  //     SearchData();
  //   }
  // }, [searchQuery]);


  
  return (
    <div className="w-full h-[calc(100vh-90px)] px-4 py-2 overflow-hidden ">
      <div className="md:flex justify-end w-full">
      {/* <Breadcrumb 
      items={[{
        name:"Project"
        }]} /> */}
            <div className='  flex justify-center items-center float-right'>
              <div className=" pb-2 w-full flex justify-center gap-2 items-center ">
                <div className=" flex justify-center items-center gap-3 ">
                <form className="flex items-center mx-0" onSubmit={getData}>
                <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
              value={params.search||searchQuery}
            />
              </form>
                <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
                        {filter && <ClearFilterButton   handleClick={()=>clearFilter()} label='Clear'/>}
                      
               <ImportButton label="Import" handleClick={()=>setOpen(true)}   />
               <Link to={`/main/${officeId}/projects/create`}><CreateButton label="Project"/></Link>
                </div>
              </div>
            </div>
      </div>
      <div className="bg-white rounded-md h-[calc(100vh-140px)] overflow-hidden pb-5">
            <p className="text-xs font-semibold text-[#111537] text-left  px-2 pt-1 "> Project : {totalPro} </p>
            <div className=" w-full h-[calc(100vh-200px)] overflow-hidden">
            <div className=" w-full max-h-[calc(100vh-200px)]  p-4  grid grid-cols-1 md:grid-cols-3  2xl:grid-cols-3 gap-7 overflow-y-auto">
        {data &&
          data.map((project, index) => {
            return (
              <div className="" key={index}>
                <ProjectCard item={project} getProject={getData} />
              </div>
            );
          })}
      </div>
            </div>
      <div className="w-full h-6 my-2 mx-auto">
      <Pagination
          total={totalCount}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>
      </div>
      <ImportProject  open={open} onCancel={()=>setOpen(false)} getData={getData}  label='Add Title' officeId={officeId}/>
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[50%] max-w-[70%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Project</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex justify-start flex-wrap mb-4">
                    <FormikSelectTwo
                label="Project Status"
                Options={projectStatus}
                formik={formik}
                placeholder="Choose"
                isRequired={true}
                name="projStatus"
                width="w-full   "
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
    </div>
  );
};

export default AllProject;
