// import React, { useEffect, useState } from 'react'
// // import ViewMoreSupplierQuotations from './ViewMoreSupplierQuotations';
// import DataTable from '../../../../Components/Others/DataTable';
// import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
// import SearchBar from '../../../../Components/Utils/SearchBar';
// import { Link } from 'react-router-dom';
// import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
// import UseGetData from '../../../../Components/Hooks/useFetchData';
// import MoreOptions from '../../../../Components/Utils/MoreOptions';
// import CustomDataTable from '../../../../Components/Others/CustomDataTable';
// import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
// import { useFormik } from 'formik';
// import FormikSelectTwo from '../../../../Components/Utils/Select';
// import * as yup from 'yup';
// import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
// import ModalContainer from '../../../../Components/Others/ModalContainer';
// import MultiEditSQ from './MultiEditSQ';
// import { GetAccess } from '../../../../Components/Utils/roles';
// import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';

import { useEffect, useState } from 'react';
import * as yup from 'yup';
import useFetchMDIndex from '../../../../../../Components/Hooks/useFetchMDIndex';
import { useFormik } from 'formik';
import UseGetDataSearch from '../../../../../../Components/Hooks/useFetchDataBySearch';
import Breadcrumb from '../../../../../../Components/Utils/Breadcrumb';
import SearchBar from '../../../../../../Components/Utils/SearchBar';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../../../Components/Utils/Buttons';
import CustomDataTable from '../../../../../../Components/Others/CustomDataTable';
import FormikSelectTwo from '../../../../../../Components/Utils/Select';
import ModalContainer from '../../../../../../Components/Others/ModalContainer';
import { Link, useParams } from 'react-router-dom';
import { GetAccess } from '../../../../../../Components/Utils/roles';
import ProcurementsubLayout from '../ProcurementLayout';
import MultiEditSQ from './MultiEditSQ';


function SupplierQuotesIndex() {
   const {officeId,projectId} =useParams();
 
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(localStorage.getItem("perPageView")||20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const { value: Validity } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'Validity_Supplierquotes',showFilterDialog);
    const { value: PaymentTerms } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'PaymentTerms_Supplierqoutes',showFilterDialog);
    const { value: LeadTime } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'LeadTime_Supplierqoutes',showFilterDialog);
    const { value: DeliveryTerms } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'DeliveryTerms_Supplierqoutes',showFilterDialog);
    const { value: Availability } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'Availability_Supplierqoutes',showFilterDialog);
    const { value: paymentMethod } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'paymentMethod_supplierquotes',showFilterDialog);
    const { value: Status } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'Status_Supplierqoutes',showFilterDialog);
  
    const [filterProps, setFilterProps] = useState({
     
      status:"",
      paymentMethod:"",
      leadTime:"",
      validity:"",
      availability:"",
      paymentTerms:"",
      deliveryTerms:""
    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:"",
      status:"",
paymentMethod:"",
leadTime:"",
validity:"",
availability:"",
paymentTerms:"",
deliveryTerms:""
    });

    const clearFilter = ()=> {
      setParams({...params,status:"",
        paymentMethod:"",
        leadTime:"",
        validity:"",
        availability:"",
        paymentTerms:"",
        deliveryTerms:""})
      formik.values.status=""
      formik.values.paymentMethod=""
      formik.values.leadTime=""
      formik.values.validity=""
      formik.values.availability=""
      formik.values.paymentTerms=""
      formik.values.deliveryTerms=""


        setFilter(false)
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,status:value.status, paymentMethod:value.paymentMethod,leadTime:value.leadTime,validity:value.validity,availability:value.availability,paymentTerms:value.paymentTerms,deliveryTerms:value.deliveryTerms})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          status:"",
          paymentMethod:"",
          leadTime:"",
          validity:"",
          availability:"",
          paymentTerms:"",
          deliveryTerms:""
        });
      }
    })
    
    const { data, totalCount, getData } = UseGetDataSearch( '/supplier-quotations/',params);
  
    const defaultHeader = [
      // {
      //   title: "ID",
      //   width: "5%",

      //   field: "id",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
     
      {
        title: "Reference",
        width: "10%",
        isLink: true,
        id: "id",
        link: `/project/procurement/supplier-quotes/${officeId}/${projectId}/view/`,
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Purchase Requests",
        width: "10%",
        isLink: true,
        id: "id",
        link: `/project/procurement/supplier-quotes/${officeId}/${projectId}/view/`,
      
        field: "prRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Quotation Received",
        width: "10%",
        field: "dateReceived",
        type: "date",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Supplier",
        width: "10%",
        field: "vendorName",
        textAlign: "center",
        rowAlign: "center",
      },
    
      {
        title: "RFQ ",
        width: "10%",
        field: "rfqRef",
        textAlign: "center",
        rowAlign: "center",
      },
     
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"ref",
          url:`/supplier-quotations/`,
          moduleName:"Supplier Quotes",
          from:`/project/procurement/supplier-quotes/${officeId}/${projectId}`,
          editLink:`/project/procurement/supplier-quotes/${officeId}/${projectId}/edit/`,
          viewLink:`/project/procurement/supplier-quotes/${officeId}/${projectId}/view/`
  
        }
      },
    ];
    const availableHeaders = [
      // {
      //   title: "ID",
      //   width: "5%",
      //   field: "id",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Quotation Reference",
        width: "10%",
        isLink: true,
        id: "id",
        link: `/project/procurement/supplier-quotes/${officeId}/${projectId}/view/`,
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Purchase Requests",
        width: "10%",
        isLink: true,
        id: "id",
        link: `/project/procurement/supplier-quotes/${officeId}/${projectId}/view/`,
      
        field: "prRef",
        textAlign: "center",
        rowAlign: "center",
      },
     
      {
        title: "Supplier",
        width: "10%",
        field: "vendorName",
        textAlign: "center",
        rowAlign: "center",
      },
    
      {
        title: "RFQ ",
        width: "10%",
        field: "rfqRef",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Quote Date",
      //   width: "10%",
      //   field: "quoteDate",
      //   type: "date",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Quotation Received",
        width: "10%",
        field: "dateReceived",
        type: "date",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
        
      //   title: "Landing Cost",
      //   width: "10%",
      //   field: "landingCost",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        
        title: "Total Price",
        width: "10%",
        field: "totalPrice",
        textAlign: "center",
        rowAlign: "center",
      },
     
      // {
      //   title: "Product Availability",
      //   width: "10%",
      //   field: "availability",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Quotation Validity",
        width: "10%",
        field: "validity",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Special Notes",
      //   width: "10%",
      //   field: "specialNotes",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      // {
      //   title: "Lead Time",
      //   width: "10%",
      //   field: "leadTime",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Deliver Terms",
        width: "10%",
        field: "deliverTerms",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Payment Terms",
        width: "10%",
        field: "paymentTerms",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Payment Method",
        width: "10%",
        field: "paymentMethod",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Prepared By",
        width: "10%",
        field: "preparedByName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Reviewed By",
        width: "10%",
        field: "reviewedByName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Approved By",
        width: "10%",
        field: "approvedByName",
        textAlign: "center",
        rowAlign: "center",
      },
    
      // {
      //   title: "Comments",
      //   width: "10%",
      //   field: "comments",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"ref",
          url:`/supplier-quotations/`,
          moduleName:"Supplier Quotes",
          from:`/project/procurement/supplier-quotes/${officeId}/${projectId}`,
          editLink:`/project/procurement/supplier-quotes/${officeId}/${projectId}/edit/`,
          viewLink:`/project/procurement/supplier-quotes/${officeId}/${projectId}/view/`
  
        }
      },
    ];
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
      
     const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

  const [showImportModal, setShowImportModal] = useState(false);


    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [listofNumbers, setListofNumbers] = useState([])
    const [fullList, setFullList] = useState([])
  
    const selectOneFunction = (value) =>{ 
      let NewId;
      if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
        NewId = listofNumbers.filter(Temp=> Temp !== value);
        setListofNumbers(NewId)
      }else{
        setListofNumbers([...listofNumbers,value])
      }
    }
  
    const selectMultipleFunction = (e) =>{ 
  console.log(e.target.checked);
  if(e.target.checked){
    setListofNumbers(fullList)
  }else{
    setListofNumbers([])
  }
  
    }
  
    const closeModal = () => {
      setShowImportModal(false);
    };

    const [valueOfSearch,setValuesofSearch] = useState("")
       function searchFunction(e) {
     
         
         setValuesofSearch(e?.target?.value)
         setParams({ ...params, search: e?.target?.value, page:1 });
         setPage(1)
       }
  
    useEffect(()=>{
      let tempArr = []
      data && data.forEach(i=>{
        console.log("I",i?.id);
        tempArr.push(i?.id)
      });
      setFullList(tempArr)
    },[data])

    return (
      <div className='w-full h-full'>
      {/* <div className='w-full flex justify-between items-center'>
      <Breadcrumb 
       items={[
        { name: "Procurement" },

        { name: "Supplier Quote"},
          
      
      
  ]} />
      <div className=' flex gap-2'>
      <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
       
       {/* <CreateButton label='Import' /> */}
       {/* {GetAccess
       ("supplier_quote_create") && <Link to="/procurement/supplier-quotes/create"><CreateButton label='Supplier Quote'/></Link> }
      </div>
      </div>  */}

<ProcurementsubLayout 
     setOpen={closeModal}
     searchFun={searchFunction}
      searchValue={valueOfSearch}
     getData={getData}
     isFilter={filter}
     newfilter={true}
     neededSearch={true}

     setFilterOpen={setShowFilterDialog}
     ClearFilter={clearFilter}
     CreateAccess={GetAccess("rfq_create")}
    //  ImportAccess={GetAccess("rfq_create")}
     officeId={officeId}
     // projectId={projectId}
       >
  
      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Supplier Quotes List'
        storageName="SupplierQuotesProjectTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="id"
        editAccess="supplier_quote_edit"      
        deteleAccess="supplier_quote_delete" 

        />

</ProcurementsubLayout>
         {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Supplier Quotes</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              type="text"
              label="Quotation Validity"
              placeholder="Validity"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full"
            />
            {/* <FormikSelectTwo
              type="text"
              label="Product Availability"
              placeholder="Availability"
              Options={Availability}
              formik={formik}
              name="availability"
              width="w-full"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Delivery Terms"
              placeholder="Delivery Terms"
              Options={DeliveryTerms}
              formik={formik}
              name="deliverTerms"
              width="w-full"
            />
            <FormikSelectTwo
              type="text"
              label="Payment Terms"
              placeholder="Payment Terms"
              Options={PaymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full "
            />
            <FormikSelectTwo
              type="text"
              label="Lead Time"
              placeholder="Lead Time"
              Options={LeadTime}
              formik={formik}
              name="leadTime"
              width="w-full "
            />
             <FormikSelectTwo
              type="text"
              label="Payment Method"
              placeholder="Payment Method"
              Options={paymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full"
            />
         <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          {/* {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)} */}

                   {showEdit && (<MultiEditSQ data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
    )
  }

export default SupplierQuotesIndex