import React from "react";
import {
  CustomersDashboard,
  PurchaseOrdersfromCustomers,
  PurchaseOrderstoSuppliers,
  PurchaseRequestDashborad,
  QuotestoCustomersDashboard,
  SuppliersDashboard,
} from "./Components";
import Approvals from "./Components/Approval";
import AlertDashborad from "./Components/AlertDashborad";
import SalesOrderSales from "./Sales/Components/SalesOrderSales";
import Project from "./NuPro/Project";
import ProjectSatus from "./NuPro/ProjectSatus";
import Bids from "./NuPro/Bids";
import Budget from "./NuPro/Budget";
import Invoice from "./NuPro/Invoice";
import Due from "./NuPro/Due";
import Manpower from "./NuPro/Manpower";

function Dashboard() {
  return (
    <div className="w-[100%] h-[calc(100vh-145px)] py-2  ">
      {/* <div className="grid grid-cols-3 grid-rows-2 gap-4 w-full max-h-[44vh] 2xl:max-h-[33vh] mb-4">
        <div>
          <CustomersDashboard />
        </div>
        <div className="">
          <SuppliersDashboard />
        </div>
        <div className="row-span-2">
          <AlertDashborad />
        </div>
        <div className="">
          <PurchaseOrderstoSuppliers />
        </div>
        <div className="">
          <SalesOrderSales />
        </div>
      </div>
      <div className="w-full grid grid-cols-4 gap-4 ">
        <Approvals />
      </div>
      <div className="w-full grid grid-cols-3 gap-4 ">
        <PurchaseRequestDashborad />
        <QuotestoCustomersDashboard />
        <PurchaseOrdersfromCustomers />
      </div> */}
      
<div className="grid grid-cols-8 grid-rows-8 gap-4">
    <div className="col-span-2 row-span-2"><Project/></div>
    <div className="col-span-5 row-span-2 col-start-3"><ProjectSatus/></div>
    <div className="row-span-2 col-start-8"><Bids/></div>
    <div className="col-span-4 row-span-3 row-start-3"><Budget/></div>
    <div className="col-span-4 row-span-3 col-start-5 row-start-3"><Invoice/></div>
    <div className="col-span-5 row-span-3 row-start-6"><Due/></div>
    <div className="col-span-3 row-span-3 col-start-6 row-start-6"><Manpower/></div>
</div>
    
    </div>
  );
}

export default Dashboard;
